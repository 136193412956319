import template from './contact-new.pug';

export const ContactNewComponent = {
  template,
  bindings: {
    resolve: '<',
    modalInstance: '<',
  },
  controller: class ContactNewComponent {
    constructor(Contact, CustomField, ConnectedRecord, AlertBox, $async, $log) {
      'ngInject';
      this.Contact = Contact;
      this.CustomField = CustomField;
      this.ConnectedRecord = ConnectedRecord;
      this.AlertBox = AlertBox;
      this.$async = $async;
      this.$log = $log;
      this.loadCustomFields = $async(this.loadCustomFields.bind(this));
      this.save = $async(this.save.bind(this));
    }

    $onInit() {
      this.loadCustomFields();
      if (this.resolve.contact) {
        this.contact = this.resolve.contact;
      } else {
        this.contact = {
          custom_fields: {}
        };
      }
    }

    async loadCustomFields() {
      try {
        this.cfGroups = await this.CustomField.loadGroups({ module: 'contacts' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    close() {
      this.modalInstance.close(void(0));
    }

    async save({ contact }) {
      try {
        this.contactAttributes = this.Contact.serialize(contact);
        const newConnectedRecords = angular.copy(contact['new_connected_records']);
        const resp = await this.Contact.create(this.contactAttributes);
        await this.ConnectedRecord.batchCreate(resp.id, newConnectedRecords);

        this.AlertBox.addMessage('alert.created', { type: 'success' });
        this.modalInstance.close(resp);
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
