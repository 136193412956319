import { PortalSettingModule } from './portal/portal-setting.module';
import { CustomFieldSettingsModule } from './custom-field/custom-field-settings.module';
import { TrashbinSettingsModule } from './trashbin/trashbin-settings.module';
import { RoleSettingsModule } from './role/role-settings.module';
import { TodoSettingModule } from './todo/todo-setting.module';
import { FinanceSettingModule } from './finance/finance-setting.module';
import { DocumentSettingModule } from './document/document-setting.module';
import { NotificationSettingsModule } from './notification/notification-settings.module';
import { RecordStatusSettingsModule } from './record-status/record-status-setting.module';
import { WorkspaceSettingModule } from './workspace/workspace-setting.module';

export const SettingModule = angular.module('topbroker.setting', [
  TrashbinSettingsModule,
  CustomFieldSettingsModule,
  RoleSettingsModule,
  TodoSettingModule,
  FinanceSettingModule,
  DocumentSettingModule,
  PortalSettingModule,
  NotificationSettingsModule,
  RecordStatusSettingsModule,
  WorkspaceSettingModule
])
  .name;
