import template from './finance-new.pug';

export const FinancesNewModalComponent = {
  template,
  bindings:{
    resolve: '<',
    modalInstance: '<',
  },
  controller: class FinancesNewModalComponent {
    constructor($async, $log, Finance, ConnectedRecord,  AlertBox) {
      'ngInject';

      this.Finance = Finance;
      this.ConnectedRecord = ConnectedRecord;
      this.$log = $log;
      this.AlertBox = AlertBox;
      this.save = $async(this.save.bind(this));
    }

    $onInit() {
      this.record = this.resolve.record;
    }

    close() {
      this.modalInstance.close(void(0));
    }

    async save({ finance }) {
      try {
        this.AlertBox.addMessage('alert.created', { type: 'success' });
        this.modalInstance.close(finance);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

  }
};
