import template from './password-strength.pug';

export const PasswordStrengthComponent = {
  template,
  bindings: {
    password: '<',
    onTest: '&'
  },
  controller: class PasswordStrengthComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
      this.passwordTests = [
        { test: /^.{7,}$/, message: 'validation_messages.auth.password_validation.minumum_length', passed: false },
        { test: /[0-9]/, message: 'validation_messages.auth.password_validation.must_have_digits', passed: false },
        { test: /[a-z]/, message: 'validation_messages.auth.password_validation.must_have_lowercase', passed: false },
        { test: /[A-Z]/, message: 'validation_messages.auth.password_validation.must_have_uppercase', passed: false },
        { test: /[^A-Z-0-9]/i, message: 'validation_messages.auth.password_validation.must_have_special_char', passed: false }];
      this.passwordStrength = 0;
    }

    $onChanges() {
      this.testPassword();
    }

    testPassword() {
      this.passwordStrength = 0;

      if (this.password == null) {
        this.passwordStrength = 0;
        return;
      }

      this.passwordTests.forEach( (passwordTest) => {
        passwordTest.passed = false;
        if (passwordTest.test.test(this.password)) {
          passwordTest.passed = true;
          this.passwordStrength += 1;
        }
      });
      this.onTest({ $event: { passwordStrength: this.passwordStrength } });
    }


  }

};
