// base component
import { ButtonComponent } from './button.component';

// components
import { SubmitComponent } from './submit/submit.component';
import { PrimaryComponent } from './primary/primary.component';
import { ButtonDangerComponent } from './danger/button-danger.component';
import { ButtonGoogleComponent } from './google/button-google.component';
import { CreateButtonComponent } from './create/create-button.component';

import { UploadComponent } from './upload/upload.component';

export const ButtonModule = angular.module('topbroker.buttons', [])
  .component('fnButton', ButtonComponent)
  .component('submitBtn', SubmitComponent)
  .component('primaryBtn', PrimaryComponent)
  .component('dangerBtn', ButtonDangerComponent)
  .component('googleBtn', ButtonGoogleComponent)
  .component('uploadBtn', UploadComponent)
  .component('createBtn', CreateButtonComponent)
  .name;
