
import template from './show.pug';

export const ShowComponent = {
  template,
  bindings: {
    onEdit: '&?',
    onDelete: '&?',
    onMultiDelete: '&?',
    onCopy: '&?',
    onInfo: '&?',
    onReexport: '&?',
    onProposal: '&?',
    onMoveToArchive: '&?',
    onUploadPhoto: '&?',
    multiDeleteShow: '<',
    inline: '<?',
    title: '@?',
    showIf: '<?',
  },
  transclude : true,
  controller: class ShowComponent {
    constructor(AlertBox, $async) {
      'ngInject';
      this.inline = false;
      this.showIf = true;
      this.AlertBox = AlertBox;
      this.triggerOnDelete = $async(this.triggerOnDelete.bind(this));
    }

    async triggerOnDelete($event) {
      const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });
      if (result && result.dismiss) return false;

      this.onDelete($event);
    }

    edit() {
      this.onEdit({
        $event: { state: 'edit' }
      });
    }


  }
};


