import template from './user-table.pug';

export const UserTableComponent = {
  template,

  bindings: {
    filter: '<',
    gridOptions: '<?',
    onFilterChange: '&',
    onPageChange: '&',
    onCheck: '&?',
    disabledIds: '<?',
    isModal: '<?',
  },

  controller: class UserTableComponent {
    constructor(User, $async, $log, $scope, $state, $stateParams, AlertBox, Table) {
      'ngInject';
      this.User = User;
      this.$log = $log;
      this.gridApi = Table.gridApi;
      this.$scope = $scope;
      this.$state = $state;
      this.$stateParams = $stateParams;
      this.fetchUsers = $async(this.fetchUsers.bind(this));
      this.AlertBox = AlertBox;
      this.checkedIds = [];
      this.loadings = {};
      this.filterOptions = ['active', 'invited', 'archived'];

    }

    $onInit() {
      this.filter = this.filter || { page: 1 };
      this.loader = true;
      this.cuserId = this.User.currentId();
      this.filter.state = this.$stateParams.state || 'active';
      this.fetchUsers();
    }

    async fetchUsers() {
      try {
        this.loader = true;
        // const users = await this.User.getList(this.filter);
        const { data, meta } = await this.User.getFullList(this.filter);
        let mappedData = data.map(x => x.attributes);
        mappedData = mappedData.map(elm => elm = {
          user: elm,
          id: elm.id,
          search: [elm.name, elm.email, elm.phone].join(' '),
          state: elm.state
        });
        this.records = mappedData;
        this.totalCount = meta.count;
        this.totalPages = meta.pages;
        if (this.disabledIds) {
          this.records = this.records.filter(elm => !this.disabledIds.includes(elm.id));
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    applyState(state) {
      this.filter.state = state;
      this.fetchUsers();
      this.$state.go('main.settings.users.list', { state: state });
    }

    updatePage({ page }) {
      if (parseInt(page) > 0) {
        this.filter.page = page;
        this.fetchUsers();
        this.checkIds([]);
      }
    }

    updateFilter(filter) {
      this.filter = filter;
      this.fetchUsers();
    }


    applyFilter({ filter }) {
      this.filter = {
        ...this.filter,
        ...filter
      };
      this.fetchUsers();
    }


    checkIds({ checkedIds }) {
      this.checkedIds = checkedIds;
      this.onCheck({
        $event: { checkedIds }
      });
    }

    getName(user) {
      if (user == undefined) return '';

      if (user.name == undefined) {
        return user.email;
      }

      return user.name;
    }

  }
};

