class User {
  constructor(Settings, $translate, $auth, $q, $state, $rootScope, Http, Modal, Role, $localStorage, Workspace) {
    'ngInject';
    this.Http = Http;
    this.Modal = Modal;
    this.Workspace = Workspace;
    this.Role = Role;
    this.Settings = Settings;
    this.$auth = $auth;
    this.$state = $state;
    this.$translate = $translate;
    this.$localStorage = $localStorage;
    this.$q = $q;
    this.$rootScope = $rootScope;
  }

  assignInvites() {
    return this.Modal.open({
      size: 'md',
      component: 'userNew',
      resolve: {
        roles: () => this.Role.getList()
      }
    });
  }

  sendInvites(emails, role) {
    return this.Http.post('/users', { emails, role });
  }

  createUser(new_user) {
    return this.Http.post('/users/new_user', new_user);
  }

  navigateToShow(id) {
    this.$state.go('main.users.show', { id });
  }

  track() {

  }

  getList(filter = {}) {

    if (filter['state'] === undefined) {
      filter['state'] = 'active';
    }
    return this.Http.get('/users', filter);
  }

  getFullList(filter) {
    return this.Http.get('/users/full', filter);
  }

  find(user_id, params = {}) {
    return this.Http.get('/users/' + user_id, params);
  }

  cardInfo(id) {
    return this.Http.get(`/users/${id}/card_info`);
  }

  relogin() {
    return this.Http.get('/users/relogin');
  }

  photo(user, photo) {
    return this.Http.post('/users/' + user.id + '/photo', { photo: photo });
  }

  changeRole(user_id, role) {
    return this.Http.put('/users/' + user_id, { admin: role });
  }

  colors() {
    return this.Http.get('/users/colors');
  }

  updateColor(user_id, color) {
    return this.Http.put('/users/' + user_id, { color: color });
  }

  update(id, params) {
    return this.Http.put('/users/' + id, params);
  }

  changeOwners(owner_id, object_ids, obj_class) {
    return this.Http.put('/' + obj_class + '/change_owners', { ids: object_ids, user_id: owner_id });
  }

  passwordChange(user) {
    return this.Modal.open({
      component: 'userPasswordModal',
      size: 'md',
      resolve: {
        user: () => {
          return user;
        }
      }
    });
  }

  currentId() {
    return this.$auth.getPayload().user.id;
  }

  accountId() {
    return this.$auth.getPayload().user.account_id;
  }

  getUserSetting(module, key) {
    return this.Http.get('/settings/user', { module, key });
  }

  getUserSettings() {
    return this.$localStorage.settings;
  }

  resendInvite(id, updatedEmail = null) {
    return this.Http.post('/users/' + id + '/resend_invite', { updated_email: updatedEmail });
  }

  remindPassword(email) {
    return this.Http.post('/auth/remind_password', { email: email });
  }

  changePassword(user) {
    if (user.token) {
      return this.Http.post('/auth/change_password', { token: user.token, password: user.password });
    }

    if (user.id) {
      return this.Http.put('/users/'+ user.id +'/change_password', { password: user.password });
    }

  }

  getTokenByToken(token) {
    return this.Http.post('/auth/auth_token', { token });
  }

  googleLoginByToken(token) {
    return this.Http.post('/auth/google/login', { token });
  }

  googleRegisterByToken(token) {
    return this.Http.post('/auth/google/register', { token });
  }

  enableGoogleService() {
    return this.Http.get('/auth/google/enable_service', { host: window.location.host });
  }

  registerGoogleService(user_id, params) {
    return this.Http.post('/users/' + user_id + '/google/register_service', { token: params.token, host: window.location.host });
  }

  resendInviteModal(user) {
    return this.Modal.open({
      component: 'resendUserInviteModal',
      size: 'md',
      resolve: { user: () => user }
    });
  }

  archiveModal(user) {
    return this.Modal.open({
      component: 'userModalArchive',
      size: 'md',
      resolve: {
        user: () => {
          return user;
        }
      }
    });
  }

  moveToArchive(user_id, archive_type, archive_to_user_id) {
    return this.Http.put('/users/' + user_id + '/archive', {
      archive_type: archive_type,
      archive_to_user_id: archive_to_user_id
    });
  }

  moveToActive(user_id) {
    return this.Http.put('/users/' + user_id + '/activate');
  }

  registerByInvite(user) {
    return this.Http.post('/auth/user', user);
  }

  confirmByToken(token) {
    return this.Http.post('/auth/account_user_confirm', { token: token });
  }

  getPortforlioStats(user_id) {
    return this.Http.get('/users/' + user_id + '/portfolio_stats', { workspace_id: this.Workspace.currentWorkspaceId() });
  }

  addGoogleAccessToken(params) {
    return this.Http.post('/auth/google/access_token', { token_data: params });
  }


  assignUsers(disableSelection) {
    return this.Modal.open({
      component: 'userAssignmentModal',
      size: 'lg',
      resolve: {
        disableSelection: () => disableSelection
      }
    });
  }

  count(params = {}) {
    return this.Http.get('/users/count', params);
  }

  attachToGoogleProfile(user_id, google_token_id) {
    return this.Http.post('/users/' + user_id + '/google-token', { google_token_id: google_token_id });
  }

  removeGoogleAccount(user_id) {
    return this.Http.delete('/users/' + user_id + '/google-token');
  }

  serialize(user, selected = []) {
    let user_attributes = { ...user };

    function pick(obj, keys) {
      return keys.map(k => (k in obj ? { [k]: obj[k] } : {})).reduce((res, o) => Object.assign(res, o), {});
    }

    if (selected.length > 0) user_attributes = pick(user_attributes, selected);

    return user_attributes;
  }
}
export default User;
