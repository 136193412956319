import template from './estate-modal-preview.pug';

export const EstateModalPreviewModalComponent = {
  template,
  bindings:{
    resolve: '<',
    modalInstance: '<',
  },
  controller: class EstateModalPreviewModalComponent {
    constructor(Estate, AlertBox, CustomField, $async, $log) {
      'ngInject';

      this.Estate = Estate;
      this.$log = $log;
      this.AlertBox = AlertBox;
      this.CustomField = CustomField;
      this.moveToTrash = $async(this.moveToTrash.bind(this));
      this.update = $async(this.update.bind(this));
      this.saveOwner = $async(this.saveOwner.bind(this));
      this.loadCustomFields = $async(this.loadCustomFields.bind(this));
      this.reloadEstate = $async(this.reloadEstate.bind(this));

      this.formState = 'show';
    }

    $onInit() {
      this.record = this.resolve.record;
      this.loadCustomFields();
    }

    close() {
      this.modalInstance.close(void(0));
    }

    navigateToRecord() {
      if (this.record.id) {
        this.Estate.navigateToShow( this.record.id);
        this.close();
      }
    }

    changeFormState(state) {
      if (state == 'edit') {
        this.record = angular.copy(this.record);
      }
      this.formState = state;
    }

    async loadCustomFields() {
      try {
        this.cfGroups = await this.CustomField.loadGroups({ module: 'estates' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async saveOwner({ record, userId }) {
      try {
        if (record && record.id) {
          this.record = await this.Estate.changeOwner(record.id, userId);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async moveToTrash() {
      try {
        this.AlertBox.addMessage('alert.deleted', { type: 'success' });
        this.close();
      } catch (e) {
        this.$log.error(e);
      }
    }

    updateList({ estate, record } = {}) {
      this.reloadEstate(estate || record);
    }

    async reloadEstate(estate) {
      try {
        let id = this.record.id;
        if (estate) {
          this.record = angular.copy(estate);
        } else {
          let record = await this.Estate.find(id);
          this.record = angular.copy(record);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    saveCustomFields({ record }) {
      const params = {
        custom_fields: record.custom_fields
      };
      this.update(record.id, params);
    }

    async update(id, params) {
      try {
        if (!id) return;
        const resp = await this.Estate.update(id, params);
        if (resp && resp.id) {
          this.record = resp;
          this.AlertBox.addMessage('alert.updated', { type: 'success' });
          // this.changeFormState('show');
        }
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
