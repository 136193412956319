import template from './info.pug';

export const InfoComponent = {
  template,
  bindings: {
    type: '@?'
  },
  transclude : true,
  controller: class InfoComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
      this.type = this.type || 'alert-success';
    }
  }
};
