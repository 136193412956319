import template from './chronology-item-header.pug';

export const ChronologyItemHeaderComponent = {
  template,
  transclude: true,
  bindings: {
    item: '<',
    onVisabilityToggle: '&',
    disableToggle: '<?'
  },
  controller: class ChronologyItemHeaderComponent {
    constructor() {
      'ngInject';
      this.visible = false;
    }

    $onInit() {
      if (this.disableToggle == undefined) {
        this.disableToggle = false;
      }
      this.shouldHide = this.hideToggle();
    }

    toggleVisibility() {
      this.visible = !this.visible;
      this.onVisabilityToggle({
        $event: { visible: this.visible }
      });
    }

    hideToggle() {
      return this.firstChronologyRecord() || this.disableToggle;
    }

    firstChronologyRecord() {
      return (['Contact', 'Estate', 'Inquiry'].indexOf(this.item.record_type) !== -1);
    }

  }
};
