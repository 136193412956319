import template from './custom-field-group-form.pug';

export const CustomFieldGroupFormComponent = {
  template,
  bindings:{
    group: '<?',
    onSave: '&?',
    onCancel: '&?',
    loading: '<?'
  },
  controller: class CustomFieldGroupFormComponent {
    constructor() {
      'ngInject';

    }

    $onInit() {
    }

    save() {
      let group = {
        title: this.group.title,
        description: this.group.description,
        generic_module: this.group.generic_module
      };
      if (this.group.id) {
        group = { ...group, ...{ id: this.group.id } };
      }
      this.onSave({
        $event: { group }
      });
    }

    cancel() {
      this.onCancel();
    }

  }
};
