import template from './contact-table.pug';

export const ContactTableComponent = {
  template,
  bindings: {
    filter: '<',
    onFilterChange: '&',
    onPageChange: '&',
    onCheck: '&?',
    isModal: '<?'
  },
  controller: class ContactTableComponent {
    constructor($async, $log, $stateParams, $localStorage, Contact, TableEdit, AlertBox, Table, RecordStatus) {
      'ngInject';
      this.Contact = Contact;
      this.TableEdit = TableEdit;
      this.AlertBox = AlertBox;
      this.$async = $async;
      this.$log = $log;
      this.$localStorage = $localStorage;
      this.$stateParams = $stateParams;
      this.Table = Table;
      this.getContacts = $async(this.getContacts.bind(this));
      this.checkedIds = [];
      this.totalPages = 0;
      this.totalCount = 0;
      this.setedOptions = false;
      this.Table.activeFilters.loading = true;
      this.RecordStatus = RecordStatus;
    }

    $onInit() {
      this.gridOptions = this.TableEdit.gridOptions(this.isModal);
      this.gridOptions.columnDefs = this.TableEdit.resolveColumns('contact', 'contacts', this.isModal);
      this.selectedStatusId = this.RecordStatus.returnLastStatusId('contact');
    }

    $onChanges({ filter } = {}) {
      if (filter && filter.currentValue) {
        this.checkedIds = [];
        if (filter.page) {
          this.filter.page = filter.page;
        }
        this.getContacts();
      }
    }

    changeRecordStatus({ status }) {
      this.RecordStatus.setLastStatusId('contact', status.id);
      this.applyFilter({ filter: { ...this.filter, page: 1 }, statusId: status.id, searchId: this.$stateParams.searchId });
    }

    updatePage({ page }) {
      if (parseInt(page) > 0) {
        this.onPageChange({
          $event: { page }
        });
        this.checkedContacts([]);
      }
    }

    applyFilter({ filter, searchId, statusId }) {
      this.onFilterChange({
        $event: { filter: { ...this.filter, ...filter }, searchId, statusId }
      });
    }

    async getContacts() {
      try {
        if (!this.filter) return;
        if (this.Table.activeFilters.loading && !this.isModal) return;
        let searchId = this.$stateParams.searchId || -1;
        const apiFilter = this.filter;
        this.loader = true;

        if (this.isModal) {
          searchId = -1;
        }

        const { data, meta } = await this.Contact.getListBySearchIdNew(searchId, apiFilter);
        this.records = data;
        this.totalCount = meta.total_count;
        this.totalPages = meta.pages;
        this.recordStatuses = meta.record_statuses;

        if (meta.cf_names && meta.cf_names.length) {
          let settings = await this.$localStorage.settings['contact']['custom_table_fields'];
          settings.cf_names = meta.cf_names;
          const resp = this.TableEdit.recheckNames(settings, 'contact');
          if (resp.update) {
            this.gridOptions.columnDefs = this.TableEdit.resolveColumns(
              'contact',
              'contacts',
              (this.isModal = 'false')
            );
          }
        }
        meta.options.forEach(element => {
          let field = this.gridOptions.columnDefs.find(elm => elm.field_key === element.technical_name);
          if (!field) return;

          if (element.field_type && element.field_type == 'connected_record') {
            field.filter.validations = element.validations;
          }

          if (element.options.length) {
            field.filter.selectOptions = element.options;
          }
        });

      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    checkedContacts({ checkedIds }) {
      this.checkedIds = checkedIds;
      if (this.onCheck) {
        this.onCheck({
          $event: { checkedIds }
        });
      }
    }
  }
};
