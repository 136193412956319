import template from './textarea-show.pug';

export const TextareaShowComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    model: '<',
  },
  controller: class TextareaShowComponent {
    constructor() {
      'ngInject';
    }
    $onInit() {
     
    }

  }
};