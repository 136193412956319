import template from './record-modal.pug';
import { debounce as _debounce, isEmpty } from 'lodash';
import { delay as _delay } from 'lodash';
import { isLength as _isLength } from 'lodash';
import { isEmpty as _isEmpty } from 'lodash';

export const InquiryRecordModalComponent = {
  template,
  bindings: {
    inquiry: '<?',
    step: '<?',
    onClose: '&?',
    onUpdate: '&?',
    isVisible: '<?'
  },
  controller: class InquiryRecordModalComponent {
    constructor(Inquiry, Estate, Contact, CustomField, Assignment, $translate, $async, $log, $state, $scope, $window) {
      'ngInject';
      this.Inquiry = Inquiry;
      this.Estate = Estate;
      this.Contact = Contact;
      this.CustomField = CustomField;
      this.Assignment = Assignment;
      this.$log = $log;
      this.$scope = $scope;
      this.$state = $state;
      this.$translate = $translate;
      this.estateTypes = Estate.types();
      this.isSidebarOpened = true;
      this.currency = $window.CURRENCY;
      this.loadCustomFields = $async(this.loadCustomFields.bind(this));
      this.estateTypeSelected = $async(this.estateTypeSelected.bind(this));
      this.loadEstateTypes = $async(this.loadEstateTypes.bind(this));
      this.getAreaUnits = $async(this.getAreaUnits.bind(this));
      this.searchModal = $async(this.searchModal.bind(this));
      this.loadCustomer = $async(this.loadCustomer.bind(this));
      this.updateInquiry = _debounce($async(this.updateInquiry.bind(this)), 850);
      this.estateTypeGroups = [];
      this.cfGroups = null;
      this.contact = null;
      this.areaUnits = this.Estate.areaUnitsByMetricSystem();
      this.loader = false;
      this.saved = false;
      this.estate_medias = [];
    }


    $onInit() {

      this.loadEstateTypes();
      if (this.inquiry) {
        this.inquiry = angular.copy(this.inquiry);
        this.loadCustomer();
        this.loadCustomFields();
        this.getAreaUnits();
      } else {
        this.inquiry = {
          notes: '',
          operation_type: 'for_sale',
          area_unit: this.areaUnits[0].id,
        };
      }

      if (this.step == undefined) {
        this.step = (this.inquiry.id == undefined) ? 'estate_type' : 'area_and_price';
      }
    }

    async loadCustomer() {
      if (!this.inquiry.contact_id) {
        return;
      }

      try {
        this.contact = await this.Contact.cardInfo(this.inquiry.contact_id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async searchModal() {
      try {
        let params = {
          type: 'Contact',
          multiple: false
        };

        if (this.contact) {
          params.alreadySelectedItems = [this.contact];
        }

        const response = await this.Assignment.selectModal(params);

        if (response) {
          this.inquiry.contact_id = response.$event.id;
          this.contact = response.$event;
          this.updateInquiry();
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    removeContact() {
      this.contact = null;
      this.inquiry.contact_id = null;
      this.updateInquiry();
    }

    async updateInquiry() {
      this.loader = true;
      this.saved = false;
      try {
        this.inquiry = await this.Inquiry.update(this.inquiry.id, this.inquiry);
        if (this.onUpdate) {
          this.onUpdate({ $event: { record: this.inquiry } });
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.finishLoader();
      }
    }

    resolveAreaUnit() {
      const area_unit = this.areaUnits.find((unit) => unit.id === this.inquiry.area_unit_id);
      if (!this.inquiry.area_unit_id || area_unit == undefined) {
        this.inquiry.area_unit_id = this.areaUnits[0].id;
        return this.areaUnits[0].symbol;
      }
      return area_unit.symbol;
    }

    async getAreaUnits() {
      try {
        const resp = await this.Estate.getAreaUnits(this.inquiry.estate_type_id);
        if (resp) {
          this.areaUnits = resp;
        }
      } catch (error) {
        this.$log.error(error);
      }
    }

    updateDescription(html) {
      this.inquiry.notes = html;
      this.updateInquiry();
    }

    locationUpdated(location) {
      this.inquiry.location_id = location.id;
      this.updateInquiry();
      this.inquiry.location = location;
    }

    stepTitle() {
      if (!this.cfGroups) return;

      const match = this.step.match(/cf(\d+)/);
      if (match) {
        const cfIndex = this.cfGroups.findIndex(group => group.id === parseInt(match[1]));
        return this.cfGroups[cfIndex].title;
      } else {
        return this.$translate.instant('inquiry.steps.' + this.step);
      }
    }

    finishLoader() {
      const $this = this;
      _delay(function() {
        $this.loader = false;
        $this.saved = true;
      }, 500);
    }

    async estateTypeSelected(estateType) {
      try {
        if (!this.inquiry.id) {
          this.inquiry = await this.Inquiry.create({ estate_type_id: estateType.id });
        } else {
          this.inquiry = await this.Inquiry.update(this.inquiry.id, { estate_type_id: estateType.id });
          this.notifyUpdate();
        }
        this.loadCustomFields();
      } catch (e) {
        this.$log.error(e);
      }
    }

    notifyUpdate(inquiry) {
      if (this.onUpdate) {
        this.onUpdate({ $event: { record: inquiry || this.inquiry } });
      }
    }

    async loadEstateTypes() {
      try {
        this.estateTypeGroups = await this.Estate.getEstateTypeGroups({ with_children: true });
        if (this.inquiry.estate_type_id) {
          this.estateTypeGroup = this.estateTypeGroups.find(group => group.estate_types.find(type => type.id === this.inquiry.estate_type_id));
        } else {
          this.selectEstateTypeGroup(this.estateTypeGroups[0]);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    selectEstateTypeGroup(group) {
      this.estateTypeGroup = group;
    }

    async loadCustomFields() {
      try {
        const groups = await this.CustomField.loadGroups({ module: 'inquiries' });
        this.cfGroups = groups.filter(group => group.custom_fields && group.custom_fields.length);
      } catch (e) {
        this.$log.error(e);
      }
    }

    isValidCFG(group) {
      if (isEmpty(this.inquiry.custom_fields)) return false;

      let valid = false;
      group.custom_fields.forEach(field => {
        if (_isLength(this.inquiry.custom_fields[field.technical_name]) || !_isEmpty(this.inquiry.custom_fields[field.technical_name])) {
          valid = true;
        }
      });
      return valid;
    }

    currentStepTitle() {
      if (!this.cfGroups) return;

      const match = this.step.match(/cf(\d+)/);
      if (match) {
        const cfIndex = this.cfGroups.findIndex(group => group.id === parseInt(match[1]));
        return this.cfGroups[cfIndex].title;
      } else {
        return this.$translate.instant('inquiry.' + this.step);
      }
    }

    isLastCfGroup(group) {
      if (!this.cfGroups) return;
      return this.cfGroups.indexOf(group) === this.cfGroups.length - 1;
    }

    cfGroupDescription() {
      if (!this.cfGroups) return;
      const match = this.step.match(/cf(\d+)/);
      if (match) {
        const cfIndex = this.cfGroups.findIndex(group => group.id === parseInt(match[1]));
        return this.cfGroups[cfIndex].description;
      }
    }

    switchStepCustomField(step) {
      if (this.isLastCfGroup()) {
        this.close();

        return true;
      }

      let cfId = this.cfGroups[0].id;

      const match = this.step.match(/cf(\d+)/);
      if (match) {
        const cfIndex = this.cfGroups.findIndex(group => group.id === parseInt(match[1]));
        if (step == 'next') {
          if (cfIndex + 1 < this.cfGroups.length) {
            cfId = this.cfGroups[cfIndex + 1].id;
          } else {
            this.close();
          }
        } else {
          if (cfIndex - 1 >= 0) {
            cfId = this.cfGroups[cfIndex - 1].id;
          } else {
            this.switchStep('contact');
          }
        }
      }
      this.switchStep('cf' + cfId);
    }

    switchStep(step) {
      this.saved = false;
      if (this.inquiry && !this.inquiry.estate_type_id) return;
      this.step = step;
      if (step == 'area_and_price') {
        this.getAreaUnits();
      }
      this.$state.go('.', { step: step }, { notify: false, reload: false  });
    }


    close() {
      this.$state.go('.', { step: '' }, { notify: false, reload: false  });
      if (this.onClose) {
        this.onClose({ $event: { record: this.inquiry } });
      }
    }

    save(inquiry) {

      this.onSave({
        $event: { inquiry }
      });
    }

  }
};
