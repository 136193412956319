class Geocoder {

  constructor($localStorage, $q, $timeout) {
    'ngInject';
    this.$localStorage = $localStorage;
    this.$q = $q;
    this.$timeout = $timeout;
    if (this.$localStorage.locationsV1) {
      this.locations = this.$localStorage.locationsV1;
    } else {
      this.$localStorage.locationsV1 = {};
      this.locations = {};
    }
    this.queue = [];
    this.queryPause = 500;
  }

  executeNext() {
    const geocoder = new window.google.maps.Geocoder();
    const task = this.queue[0];


    geocoder.geocode({ 'address': task.address }, (result, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        this.locations[task.address] = {
          lat: result[0].geometry.location.lat(),
          lng: result[0].geometry.location.lng()
        };
        if ( !isNaN(Number(task.address.trim()[0]))) {
          this.$localStorage.locationsV1[task.address] = this.locations[task.address];
        }
        task.q.resolve(this.locations[task.address]);
        this.queue.shift();
      } else if (status === window.google.maps.GeocoderStatus.ZERO_RESULTS) {
        this.queue.shift();
        task.q.reject({
          type: 'zero',
          message: 'Zero results for geocoding address ' + task.address
        });
      } else if (status === window.google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
        this.queryPause += 500;
        this.$timeout(this.executeNext, this.queryPause);
      } else if (status === window.google.maps.GeocoderStatus.REQUEST_DENIED) {
        this.queue.shift();
        task.q.reject({
          type: 'denied',
          message: 'Request denied for geocoding address ' + task.address
        });
        return task.q.promise;
      } else if (status === window.google.maps.GeocoderStatus.INVALID_REQUEST) {
        task.q.reject({
          type: 'invalid',
          message: 'Invalid request for geocoding address ' + task.address
        });
        this.queue.shift();
      }
    });
    return task.q.promise;
  }

  latLngForAddress (address) {
    this.q = this.$q.defer();
    if (!address) {
      this.q.reject(new Error('undefined addres'));
      return this.q.promise;
    }
    if (this.locations[address]) {
      this.q.resolve(this.locations[address]);
      return this.q.promise;
    } else {
      this.queue.push({
        address: address,
        q: this.q,
      });
      if (this.queue.length) {
        const a = this.executeNext();
        return a;
      }
    }
  }
}
export default Geocoder;
