import template from './contact-modal-preview.pug';

export const ContactModalPreviewModalComponent = {
  template,
  bindings:{
    resolve: '<',
    modalInstance: '<',
  },
  controller: class ContactModalPreviewModalComponent {
    constructor(Contact, AlertBox, CustomField, $async, $log) {
      'ngInject';

      this.Contact = Contact;
      this.$log = $log;
      this.AlertBox = AlertBox;
      this.CustomField = CustomField;
      this.update = $async(this.update.bind(this));
      this.loadCustomFields = $async(this.loadCustomFields.bind(this));

      this.formState = 'show';
    }

    $onInit() {
      this.record = this.resolve.record;
      this.loadCustomFields();
    }

    close() {
      this.modalInstance.close(void(0));
    }

    navigateToRecord() {
      if (this.record.id) {
        this.Contact.navigateToShow(this.record.id);
        this.close();
      }
    }

    changeFormState(state) {
      if (state == 'edit') {
        this.record = angular.copy(this.record);
      }
      this.formState = state;
    }

    async loadCustomFields() {
      try {
        this.cfGroups = await this.CustomField.loadGroups({ module: 'contacts' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async update({ contact }) {
      try {
        let attributes = this.Contact.serialize(contact);
        const resp = await this.Contact.update(contact.id, attributes);

        if (resp && resp.id) {
          this.record = resp;
          this.AlertBox.addMessage('alert.updated', { type: 'success' });
          // this.changeFormState('show');
        }
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
