import template from './inquiries-trashbin.pug';

export const InquiriesTrashbinComponent = {
  template,
  bindings: {
    filter: '<',
    onFilterChange: '&',
    onPageChange: '&',
  },
  controller: class InquiriesTrashbinComponent {
    constructor(Inquiry, $log, $async,$stateParams ) {
      'ngInject';
      this.Inquiry = Inquiry;
      this.$stateParams = $stateParams;
      this.$log = $log;
      this.getInquiries = $async(this.getInquiries.bind(this));
      this.checkedIds = [];
      this.gridOptions = this.Inquiry.gridOptions();
    }

    $onChanges(changesObj) {
      if (changesObj.filter && !(changesObj.filter.length === 0)) {
        this.getInquiries();
      }
    }

    $onInit() {
    }

    async getInquiries() {
      this.filter.only_deleted = true;
      try {
        this.loader = true;
        let searchId = this.filter.searchId || -1;
        const { data, meta } = await this.Inquiry.getListBySearchId(searchId,this.filter);
        this.records = data;
        this.gridOptions.data = data.map( elm => elm.attributes);
        this.totalCount = meta.count;
        this.totalPages = meta.pages;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    applyFilter({ filter }) {
      this.filter = { ...this.filter, ...filter };
      this.onFilterChange({ $event: { filter } });
    }

    recoveredInquiries() {
      this.getInquiries();
    }

    checkedInquiries({ checkedIds }) {
      this.checkedIds = checkedIds;
    }

    updatePage(page) {
      this.filter = { ...this.filter, ...page };
      this.onPageChange({ $event: page });
    }

  }
};
