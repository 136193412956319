import template from './contact-preview.pug';

export const ContactPreviewComponent = {
  template,
  bindings: {
    record: '<',
    isVisible: '<',
    onClose: '&',
    afterUpdate: '&?',
    afterNavigation: '&?',
    hotkeysEnabled: '<?'
  },
  controller: class ContactPreviewComponent {
    constructor(Contact, $async, $log, $state, AlertBox, CustomField) {
      'ngInject';
      this.Contact = Contact;
      this.$log = $log;
      this.update = $async(this.update.bind(this));
      this.AlertBox = AlertBox;
      this.$state = $state;
      this.loadCustomFields = $async(this.loadCustomFields.bind(this));
      this.navigateToRecord = $async(this.navigateToRecord.bind(this));
      this.moveToTrash = $async(this.moveToTrash.bind(this));
      this.makeCopy = $async(this.makeCopy.bind(this));
      this.CustomField = CustomField;
      this.hotkeysEnabled = true;
    }

    $onInit() {
      this.formState = 'show';
      this.loadCustomFields();
    }

    changeFormState(state) {
      this.formState = state;
    }

    isFormState(state) {
      return this.formState == state;
    }

    async navigateToRecord() {
      if (this.record.id) {
        await this.Contact.navigateToShow( this.record.id);
        this.close();
      }
    }

    afterDestroy() {
      this.close();
      this.afterUpdate({ $event: {} });
    }

    async makeCopy() {
      try {
        let contact = await this.Contact.makeCopy(this.record);
        if (contact && contact.id) {
          this.$state.go('main.contacts.show', { id: contact.id });
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async moveToTrash() {
      try {
        await this.Contact.moveToTrash(this.record.id);
        this.AlertBox.addMessage('alert.deleted',{ type: 'success' });
        this.afterDestroy();
      } catch (e) {
        this.$log.error(e);
      }
    }

    close() {
      this.defaultState = (this.defaultState === 'closed' ? 'show': 'closed');
      this.onClose({ $event: {} });
    }

    async loadCustomFields() {
      try {
        this.cfGroups = await this.CustomField.loadGroups({ module: 'contacts' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async update({ contact }) {
      try {
        let attributes = this.Contact.serialize(contact);
        this.record = await this.Contact.update(contact.id, attributes);
        this.AlertBox.addMessage('alert.updated',{ type: 'success' });
        this.changeFormState('show');
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
