import template from './inquiry-table.pug';

export const InquiryTableComponent = {
  template,
  bindings: {
    filter: '<',
    onFilterChange: '&',
    onPageChange: '&',
    isModal: '<?',
    onCheck: '&?'
  },
  controller: class InquiryTableComponent {
    constructor(Inquiry, AlertBox, TableEdit, Table, RecordStatus, $log, $stateParams, $localStorage, $async, $state) {
      'ngInject';
      this.$log = $log;
      this.$stateParams = $stateParams;
      this.$localStorage = $localStorage;
      this.reload = $state.reload;
      this.Table = Table;
      this.getInquiries = $async(this.getInquiries.bind(this));
      this.Table.activeFilters.loading = true;

      this.RecordStatus = RecordStatus;

      this.Inquiry = Inquiry;
      this.TableEdit = TableEdit;
      this.AlertBox = AlertBox;

      this.checkedIds = [];
      this.totalPages = 0;
      this.totalCount = 0;
    }

    $onChanges({ filter } = {}) {
      if (filter && filter.currentValue != filter.previousValue) {
        this.checkedIds = [];
        if (filter.page) {
          this.filter.page = filter.page;
        }

        this.getInquiries();
      }
    }

    $onInit() {
      this.gridOptions = this.TableEdit.gridOptions(this.isModal);
      this.gridOptions.columnDefs = this.TableEdit.resolveColumns('inquiry', 'inquiry', this.isModal);
      this.selectedStatusId = this.RecordStatus.returnLastStatusId('inquiry');
    }

    changeRecordStatus({ status }) {
      this.RecordStatus.setLastStatusId('inquiry', status.id);
      this.applyFilter({ filter: { ...this.filter, page: 1 }, statusId: status.id, searchId: this.$stateParams.searchId });
    }

    updatePage({ page }) {
      if (parseInt(page) > 0) {
        this.onPageChange({
          $event: { page }
        });
        this.checkedInqueries([]);
      }
    }

    applyFilter({ filter, searchId, statusId }) {
      this.onFilterChange({
        $event: { filter: { ...this.filter, ...filter }, searchId, statusId }
      });
    }

    checkedInqueries({ checkedIds }) {
      this.checkedIds = checkedIds;
      if (this.onCheck) {
        this.onCheck({
          $event: { checkedIds }
        });
      }
    }

    async getInquiries() {
      try {
        if (!this.filter) return;
        if (this.Table.activeFilters.loading) return;

        this.loader = true;
        let searchId = this.$stateParams.searchId || -1;
        if (this.isModal) {
          searchId = -1;
        }

        const { data, meta } = await this.Inquiry.getListBySearchIdNew(searchId, this.filter);

        this.records = data;
        this.totalCount = meta.total_count;
        this.totalPages = meta.pages;
        this.recordStatuses = meta.record_statuses;

        if (meta.cf_names && meta.cf_names.length ) {
          let settings = await this.$localStorage.settings['inquiry']['custom_table_fields'];
          settings.cf_names = meta.cf_names;
          const resp = this.TableEdit.recheckNames(settings, 'inquiry');
          if (resp.update) {
            this.gridOptions.columnDefs = this.TableEdit.resolveColumns('inquiry', this.isModal);
          }
        }

        if (meta.options) meta.options.forEach(element => {
          let field = this.gridOptions.columnDefs.find(elm => elm.field_key === element.technical_name);
          if (!field) return;

          if (element.field_type && element.field_type == 'connected_record') {
            field.filter.validations = element.validations;
          }

          if (element.options.length) {
            field.filter.selectOptions = element.options;
          }
        });
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }
  }
};
