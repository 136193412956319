import template from './assignment.pug';

export const AssignmentComponent = {
  template,
  bindings: {
    title: '@',
    onAdd: '&?',
  },
  transclude : true,
  controller: class AssignmentComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
      if (this.formState === undefined) {
        this.formState = 'show';
      }
    }

  }
};
