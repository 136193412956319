import template from './multiselect.pug';
export const MultiselectComponent = {
  template,
  bindings: {
    options: '<?',
    model: '<?',
    onSelect: '&',
    validations: '<?',
    label: '@?',
    inputDisabled: '<?',
    description: '@?',
    config: '<?',
  },
  transclude: true,
  controller: class MultiselectComponent {
    constructor($translate, $element) {
      'ngInject';
      this.$translate = $translate;
      this.$element = $element;
      this.finalConfig = {
        create: false,
        plugins: ['remove_button'],
        maxItems: null,
        valueField: 'id',
        labelField: 'title',
        searchField: 'title',
        translate: false,
        translatePrefix: '',
        render: {
          option: (item, escape) => {
            return `<div class="option ${item.class ? escape(item.class) : ''}">
                      ${this.labelField(item, escape)}
                    </div>`;
          },
          item: (item, escape) => {
            return `<div class="item ${item.class ? escape(item.class) : ''}" ${
              item?.settings?.color && `style="background-color: ${item.settings.color} !important;color:${item.settings.textColor} !important;"`
            }>
                      ${this.labelField(item, escape)}
                    </div>`;
          },
          optgroup_header: (data, escape) => {
            return '<div class="optgroup-header">' + escape(data.label) + '</div>';
          },
        },
      };
    }

    $onInit() {
      this.initializeConfig();
    }

    $onChanges({ config }) {
      if (config && config.currentValue) {
        // if optionGroups are loaded dynamicaly,
        // need to update selectize with new values

        this.reloadGroups();
      }
    }

    reloadGroups() {
      this.selectize = this.$element.find('selectize');
      if (this.finalConfig.optgroups && this.finalConfig.optgroups.length > 0 && this.selectize.length > 0) {
        this.selectize = this.selectize[0].selectize;
        this.finalConfig.optgroups.forEach((el) => {
          this.selectize.addOptionGroup(el.label, el);
        });
      }
    }

    initializeConfig() {
      // merge options with defaults
      this.finalConfig = {
        ...this.finalConfig,
        ...this.config,
      };

      if (this.finalConfig.translate && this.options && this.options.length > 0) {
        this.options.map((option) => {
          let label = this.finalConfig.labelField;
          option[label] = this.optionTranslate(option[label]);
        });
      }
      if (this.finalConfig.plugins && this.finalConfig.plugins.includes('remove_button') && this.finalConfig.maxItems === 1) {
        this.finalConfig.plugins = this.finalConfig.plugins.filter((x) => x !== 'remove_button');
      }
    }

    optionTranslate(value, prefix = this.finalConfig.translatePrefix) {
      return this.$translate.instant(prefix + value);
    }

    labelField(item, escape) {
      if (!item[this.finalConfig.labelField]) return '';
      return `<span class="name">${escape(item[this.finalConfig.labelField])}</span>`;
    }

    select(items) {
      let name = '';
      if (items && this.finalConfig.maxItems == 1) {
        let model = items;
        let labelField = this.finalConfig['labelField'];
        let valueField = this.finalConfig['valueField'];
        let object = this.options.find((x) => x[valueField] == model);
        if (object && object[labelField]) name = object[labelField];
      }
      this.onSelect({
        $event: { items, name },
      });
    }
  },
};
