import { CardModule } from './card/card.module';
import { ToolbarModule } from './toolbar/toolbar.module';
import { ButtonModule } from './button/button.module';
import { TableModule } from './table/table.module';
import { IconModule } from './icon/icon.module';
import { FormModule } from './form/form.module';
import { InputModule } from './input/input.module';
import { InputGroupModule } from './input-group/input-group.module';
import { ModalModule } from './modal/modal.module';
import { PreviewModule } from './preview/preview.module';
import { PopoverModule } from './popover/popover.module';
import { TagModule } from './tag/tag.module';
import { TipModule } from './tip/tip.module';
import { InfoModule } from './info/info.module';
import { PhotoModule } from './photo/photo.module';
import { TitleModule } from './title/title.module';
import { LoaderComponent } from './loader/loader.component';

import { CustomSelectModule } from './custom-select/custom-select.module';

export const GenericModule = angular.module('topbroker.generic', [
  CardModule,
  CustomSelectModule,
  ToolbarModule,
  ButtonModule,
  TableModule,
  IconModule,
  FormModule,
  InputModule,
  InputGroupModule,
  ModalModule,
  PreviewModule,
  PopoverModule,
  TagModule,
  TipModule,
  InfoModule,
  PhotoModule,
  TitleModule,
]).component('loader', LoaderComponent)
  .name;
