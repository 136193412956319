import template from './record-status-modal.pug';

export const RecordStatusModalComponent = {
  template,
  bindings: {
    resolve: '<',
    modalInstance: '<',
  },
  controller: class RecordStatusModalComponent {
    constructor($async, $log, RecordStatus, AlertBox) {
      'ngInject';
      this.RecordStatus = RecordStatus;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.save = $async(this.save.bind(this));
    }

    $onInit() {
      this.record_status = this.resolve.record_status || {};
      this.recordType = this.resolve.recordType;
    }


    async save({ record_status }) {
      try {
        this.loader = true;
        if (record_status.id) {
          const resp = await this.RecordStatus.update(record_status);
          this.modalInstance.close(resp);
          this.AlertBox.addMessage('alert.updated', { type: 'success' });
        } else {
          const resp = await this.RecordStatus.create({ module: this.recordType, ...record_status });
          this.modalInstance.close(resp);
          this.AlertBox.addMessage('alert.created', { type: 'success' });
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    close() {
      this.modalInstance.close(void (0));
    }

  }
};
