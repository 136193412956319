import template from './finance-reports.pug';

export const FinancesReportsComponent = {
  template,
  bindings: {
    filter: '<'
  },
  controller: class FinancesReportsComponent {
    constructor($state, $async, Finance, Account, $stateParams, $log) {
      'ngInject';

      this.$state = $state;
      this.$stateParams = $stateParams;
      this.$log = $log;
      this.Finance = Finance;

      this.newFinance = $async(this.newFinance.bind(this));
      this.getAccountStats = $async(this.getAccountStats.bind(this));
      this.Account = Account;
      this.totalRecords = 1;
    }

    $onInit() {
      this.getAccountStats();
      this.filter = angular.copy(this.filter);
    }

    async getAccountStats() {
      try {
        const response  = await this.Account.getAccountStatsByModule('finance');
        this.totalRecords = response.count;
      } catch (e) {
        this.$log.log(e);
      }
    }

    async newFinance() {
      try {
        const finance = await this.Finance.newModal();
        if (finance && finance.id) this.updatePage({ page: 1 });
      } catch (e) {
        this.$log.log(e);
      }
    }

    applyFilterAndResetPage({ filter }) {
      if (this.$stateParams.customFieldId && this.$stateParams.destinationId && this.$stateParams.destinationType) {
        this.filter = {
          ...filter,
          connected_record: {
            custom_field_id: this.$stateParams.customFieldId,
            destination_id: this.$stateParams.destinationId,
            destination_type: this.$stateParams.destinationType
          }
        } || {};
      } else {
        this.filter = filter || {};
      }
      this.updatePage({ page: filter.page || 1 });
    }

    updatePage({ page }) {
      this.$state.go('.', { page });
      this.filter.page = page;
      this.$onInit();
    }
  }
};
