import template from './user.pug';

export const UserComponent = {
  template,
  bindings: {
    user: '<',
    onlyIcon: '<?'
  },
  controller: class UserComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }

    initials() {
      if (!this.user) return;
      return this.getInitials(this.getName());
    }

    getName() {
      if (this.user.name == undefined) {
        return this.user.email;
      }
      return this.user.name;
    }

    imageStyle() {
      if (!this.user) return;
      return { 'background-color':  this.user.color, 'background-image': 'url(' + this.user.image_url + ')' };
    }

    getInitials(string) {
      return string.toInitials();
    }
  }
};
