
import { EstatesTrashbinComponent } from './estates/estates-trashbin.component';
import { EstatesTrashbinHeaderComponent } from './estates/header/estates-trashbin-header.component';
import { EstatesTrashbinTableComponent } from './estates/table/estates-trashbin-table.component';
import { ContactsTrashbinComponent } from './contacts/contacts-trashbin.component';
import { ContactsTrashbinHeaderComponent } from './contacts/header/contacts-trashbin-header.component';
import { ContactsTrashbinTableComponent } from './contacts/table/contacts-trashbin-table.component';
import { InquiriesTrashbinComponent } from './inquiries/inquiries-trashbin.component';
import { InquiriesTrashbinHeaderComponent } from './inquiries/header/inquiries-trashbin-header.component';

import { InquiriesTrashbinTableComponent } from './inquiries/table/inquiries-trashbin-table.component';
import { TrashbinRecoverModalComponent } from './modal/recover/trashbin-recover-modal.component';

export const TrashbinSettingsModule = angular.module('topbroker.settings.trashbin', [])
  .component('estatesTrashbin', EstatesTrashbinComponent)
  .component('estatesTrashbinHeader', EstatesTrashbinHeaderComponent)
  .component('estatesTrashbinTable', EstatesTrashbinTableComponent)
  .component('contactsTrashbin', ContactsTrashbinComponent)
  .component('contactsTrashbinHeader', ContactsTrashbinHeaderComponent)
  .component('contactsTrashbinTable', ContactsTrashbinTableComponent)
  .component('inquiriesTrashbin', InquiriesTrashbinComponent)
  .component('inquiriesTrashbinTable', InquiriesTrashbinTableComponent)
  .component('inquiriesTrashbinHeader', InquiriesTrashbinHeaderComponent)
  .component('trashbinRecoverModal', TrashbinRecoverModalComponent)

  .name;