import template from './not-found-page.pug';

export const NotFoundPageComponent = {
  template,
  controller: class NotFoundPageComponent {
    constructor() {
      'ngInject';
    }

    $onInit() { }
  }
};
