import { debounce as _debounce } from 'lodash';
import { delay as _delay } from 'lodash';
import template from './record-modal.pug';
import { isLength as _isLength } from 'lodash';
import { isEmpty as _isEmpty } from 'lodash';

export const EstateRecordModalComponent = {
  template,
  bindings: {
    estate: '<?',
    step: '<?',
    onClose: '&?',
    onUpdate: '&?',
    isVisible: '<?',
  },
  controller: class EstateRecordModalComponent {
    constructor(Estate, CustomField, Hotkeys, $translate, $async, $log, $state, $scope, $window) {
      'ngInject';
      this.Estate = Estate;
      this.CustomField = CustomField;
      this.Hotkeys = Hotkeys;
      this.$log = $log;
      this.$scope = $scope;
      this.$state = $state;
      this.$translate = $translate;

      this.estateTypes = Estate.types();
      this.operationTypes = Estate.operationTypes();
      this.isSidebarOpened = true;
      this.currency = $window.CURRENCY;

      this.loadCustomFields = $async(this.loadCustomFields.bind(this));
      this.estateTypeSelected = $async(this.estateTypeSelected.bind(this));
      this.loadEstateTypes = $async(this.loadEstateTypes.bind(this));
      this.getAreaUnits = $async(this.getAreaUnits.bind(this));
      this.updateEstate = _debounce($async(this.updateEstate.bind(this)), 850);
      this.silentUpdate = _debounce($async(this.silentUpdate.bind(this)), 850);
      this.estateGroupsTypes = [];
      this.cfGroups = null;
      this.loader = false;
      this.saved = false;
      this.cfFormError = false;
      this.estate_medias = [];
    }

    $onInit() {
      this.loadEstateTypes();
      this.registerEscAction();

      if (this.estate) {
        this.estate = angular.copy(this.estate);
        this.price = this.Estate.resolvePrice(this.estate);
        this.loadCustomFields();
        this.getAreaUnits();
      } else {
        this.estate = {
          description: {},
        };
      }

      if (this.step == undefined) {
        this.step = this.estate.id == undefined ? 'estate_type' : 'price';
      }
    }

    resolveAreaUnit() {
      const area_unit = this.areaUnits.find((unit) => unit.id === this.estate.area_unit_id);
      if (!this.estate.area_unit_id || area_unit == undefined) {
        this.estate.area_unit_id = this.areaUnits[0].id;
        return this.areaUnits[0].symbol;
      }
      return area_unit.symbol;
    }

    async loadEstateTypes() {
      try {
        this.estateTypeGroups = await this.Estate.getEstateTypeGroups({ with_children: true });
        if (this.estate.estate_type_id) {
          this.estateTypeGroup = this.estateTypeGroups.find((group) => group.estate_types.find((type) => type.id === this.estate.estate_type_id));
        } else {
          this.selectEstateTypeGroup(this.estateTypeGroups[0]);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getAreaUnits() {
      try {
        const resp = await this.Estate.getAreaUnits(this.estate.estate_type_id);
        if (resp) {
          this.areaUnits = resp;
        }
      } catch (error) {
        this.$log.error(error);
      }
    }

    registerEscAction() {
      const esc_hotkey = this.Hotkeys.createHotkey({
        key: ['escape'],
        callback: () => this.close(),
      });
      this.Hotkeys.registerHotkey(esc_hotkey);
    }

    selectEstateTypeGroup(group) {
      this.estateTypeGroup = group;
    }

    async loadCustomFields() {
      try {
        const groups = await this.CustomField.loadGroups({ module: 'estates', estate_type_id: this.estate.estate_type_id });
        this.cfGroups = groups.filter((group) => group.custom_fields && group.custom_fields.length);
      } catch (e) {
        this.$log.error(e);
      }
    }

    isValidCFG(group) {
      // if (_isEmpty(this.estate.custom_fields)) return false;

      let valid = false;
      group.custom_fields.forEach((field) => {
        if (_isLength(this.estate.custom_fields[field.technical_name]) || !_isEmpty(this.estate.custom_fields[field.technical_name])) {
          valid = true;
        }
      });
      return valid;
    }

    updatePrice({ estate }) {
      const { price, price_type } = estate;

      this.estate = { ...this.estate, price, price_type };
      this.updateEstate();
    }

    updateDescription(html) {
      this.estate.description.content = html;
      this.estate.description_attributes = { content: html };

      if (this.estate.description.id) {
        this.estate.description_attributes.id = this.estate.description.id;
      }

      this.silentUpdate();
    }

    save(estate, form) {
      if (form.$valid) {
        this.estate = estate;
        this.updateEstate();
        this.switchStepCustomField('next');
      } else {
        this.cfFormError = true;
      }
    }

    async silentUpdate() {
      this.loader = true;
      this.saved = false;
      try {
        return await this.Estate.update(this.estate.id, this.estate);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.finishLoader();
      }
    }


    async updateEstate() {
      this.loader = true;
      this.saved = false;
      try {
        this.estate = await this.Estate.update(this.estate.id, this.estate);
        if (this.onUpdate) {
          this.onUpdate({ $event: { record: this.estate } });
        }
        this.price = this.Estate.resolvePrice(this.estate);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.finishLoader();
      }
    }

    finishLoader() {
      const $this = this;
      _delay(function () {
        $this.loader = false;
        $this.saved = true;
      }, 500);
    }

    imitateSaving({ photos }) {
      this.loader = true;
      this.saved = false;
      let photos_attributes = { image_url: null };

      let primary_photo = photos.find((photo) => photo.position == 0);
      if (!primary_photo && photos.length) {
        primary_photo = photos[0];
      }

      if (primary_photo) {
        photos_attributes = { image_url: primary_photo.image_url };
      }
      this.estate = { ...this.estate, ...photos_attributes };
      this.finishLoader();
    }

    async estateTypeSelected(estateType) {
      try {
        if (this.estate && !this.estate.id) {
          this.estate = await this.Estate.create({ estate_type_id: estateType.id });
        } else {
          this.estate = await this.Estate.update(this.estate.id, { estate_type_id: estateType.id });
          this.notifyUpdate();
        }
        this.loadCustomFields();
      } catch (e) {
        this.$log.error(e);
      }
    }

    onMediaChange({ estate_medias }) {
      this.estate_medias = estate_medias;
    }

    notifyUpdate(estate) {
      if (this.onUpdate) {
        this.onUpdate({ $event: { record: estate || this.estate } });
      }
    }

    locationUpdated(location) {
      this.loader = true;
      this.saved = false;
      this.estate.location_id = location.id;
      this.notifyUpdate({ ...this.estate, address: location.formatted, location_id: location.id });
      this.finishLoader();
    }

    stepTitle() {
      if (!this.cfGroups) return;

      const match = this.step.match(/cf(\d+)/);
      if (match) {
        const cfIndex = this.cfGroups.findIndex((group) => group.id === parseInt(match[1]));
        return this.cfGroups[cfIndex].title;
      } else {
        if (this.step == 'photos') {
          return this.$translate.instant('estate.' + this.step + '.title');
        } else {
          return this.$translate.instant('estate.' + this.step);
        }
      }
    }

    isLastCfGroup(group) {
      if (!this.cfGroups) return;
      return this.cfGroups.indexOf(group) === this.cfGroups.length - 1;
    }

    cfGroupDescription() {
      if (!this.cfGroups) return;
      const match = this.step.match(/cf(\d+)/);
      if (match) {
        const cfIndex = this.cfGroups.findIndex((group) => group.id === parseInt(match[1]));
        return this.cfGroups[cfIndex].description;
      }
    }

    switchStepCustomField(step) {
      if (this.isLastCfGroup()) {
        this.close();

        return true;
      }

      let cfId = this.cfGroups[0].id;

      const match = this.step.match(/cf(\d+)/);
      if (match) {
        const cfIndex = this.cfGroups.findIndex((group) => group.id === parseInt(match[1]));
        if (step == 'next') {
          if (cfIndex + 1 < this.cfGroups.length) {
            cfId = this.cfGroups[cfIndex + 1].id;
          } else {
            this.close();
          }
        } else {
          if (cfIndex - 1 >= 0) {
            cfId = this.cfGroups[cfIndex - 1].id;
          } else {
            this.switchStep('video_and_3dtour');
          }
        }
      }
      this.switchStep('cf' + cfId);
    }

    switchStep(step) {
      this.saved = false;
      if (this.estate && !this.estate.estate_type_id) return;
      if (step == 'price') {
        this.getAreaUnits();
      }
      this.step = step;
      this.$state.go('.', { step: step }, { notify: false, reload: false });
    }

    close() {
      this.$state.go('.', { step: '' }, { notify: false, reload: false });
      if (this.onClose) {
        this.onClose({ $event: { record: this.estate } });
      }
    }
  },
};
