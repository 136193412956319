import template from './statistics-users.pug';

export const StatisticsUsersComponent = {
  template,
  bindings: {},
  controller: class StatisticsUsersComponent {
    constructor($stateParams, $state) {
      'ngInject';
      this.stateParams = $stateParams;
      this.$state = $state;
      this.filter = {};

    }

    $onInit() {}

    updateFilter({ filter }) {
      this.filter = filter;
    }

  }
};
