class ResponseInterceptor {

  constructor($q, $rootScope, $state, AlertBox, $translate) {
    'ngInject';
    this.$q = $q;
    this.$state = $state;
    this.$rootScope = $rootScope;
    this.$translate = $translate;
    this.AlertBox = AlertBox;
    this.defer = this.$q.defer();
    this.responseError = this.responseError.bind(this);
  }

  responseError(response) {
    const place = response.config.url;
    if (response.status === 401) {
      this.$rootScope.$broadcast('unauthorized');
    }

    if (response.status == 400) {
      if (response.data.alert) {
        this.AlertBox.addMessage(response.data.alert,{ type: 'warning' });
      } else if (response.data.error.includes('phone must be valid phone number')) {
        this.AlertBox.addMessage(
          this.$translate.instant('validation_messages.invalid_phone'
          ),{ type: 'warning' });
      }
    }

    if (response.status == 403 && response.data.alert) {
      this.AlertBox.addMessage(this.$translate.instant(response.data.alert) ,{ type: 'warning' });
    }

    if (response.status === 404) {
      if (place.includes('estates')) {
        this.$state
          .go('main.estates.nonexisting',{
            id: response.data.record_id,
          });
      } else if (place.includes('contacts')) {
        this.$state.go('main.contacts.nonexisting', {
          id: response.data.record_id,
        });
      } else if (place.includes('inquiries')) {
        this.$state.go('main.inquiries.nonexisting', {
          id: response.data.record_id,
        });
      } else if (place.includes('users')) {
        this.$state.go('main.users.nonexisting', {
          id: response.data.record_id,
        });
      }
    }

    if (response.status === 500) {
      // this.AlertBox.addMessage('alert.errors.500', { type: 'error' });
    }

    if (navigator.onLine) {
      return this.$q.reject(response.data.error);
    } else {
      this.AlertBox.addMessage('alert.warnings.network_offline', { type: 'warning' });
      return this.$q.reject();
    }
  }
}
export default ResponseInterceptor;
