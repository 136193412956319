import template from './money-number.pug';

export const MoneyComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    model: '<',
    validations: '<?',
    placeholder: '<?',
    onEdit: '&?',
  },
  transclude: true,
  controller: class MoneyComponent {
    constructor() {
      'ngInject';
      this.debounce = 500;
      this.currency = window.CURRENCY;
    }

    $onInit() {
      if ( this.model && (typeof this.model === 'string')) {
        this.model = Number(this.model);
      }
    }

    edit(number) {
      this.onEdit({
        $event: { number }
      });
    }

  }
};
