import template from './portal-estate-table.pug';

export const PortalEstateTableComponent = {
  template,
  bindings: {
    estates: '<',
    totalCount: '<',
    pages: '<',
    portalId: '<'
  },
  controller: class PortalEstateTableComponent {
    constructor($async, $log, Portal) {
      'ngInject';
      this.Portal = Portal;
      this.$log = $log;
      this.filter = { page: 1 };
      this.getEstates = $async(this.getEstates.bind(this));
      this.isModal = true;
      this.totalCount = 0;
      this.totalPages = 0;
    }

    $onChanges({ estates }) {
      if (estates && estates.currentValue) {
        if (!this.records) {
          this.records = this.estates.data;
        } else {
          this.getEstates();
        }
      }
    }

    $onInit() { }

    applyFilter({ filter, searchId }) {
      this.filter = { ...this.filter, ...filter };
      this.searchId = searchId;
      this.getEstates();
    }

    updatePage({ page }) {
      this.filter = {
        ...this.filter,
        ...{ page }
      };
      this.getEstates();
    }

    async getEstates() {
      try {
        this.loader = true;
        this.searchId = this.searchId || -1;
        const { data, meta } = await this.Portal.getEstates(this.portalId, this.searchId, this.filter);
        this.records = data;
        this.totalCount = meta.count;
        this.totalPages = meta.pages;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }


  }

};
