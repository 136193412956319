import template from './input-group-range-show.pug';

export const InputGroupRangeShowComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    filter: '@?',
    rangeName: '@?',
    from: '<',
    to: '<',
  },
  transclude: true,
  controller: class InputGroupRangeShowComponent {
    constructor($filter) {
      'ngInject';
      this.$filter = $filter;
    }
    $onInit() {
      if(this.filter) {
        this.from = this.$filter(this.filter)(this.from);
        this.to = this.$filter(this.filter)(this.to);
      }
    }

  }
};