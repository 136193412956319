import template from './connected-record-items.pug';

export const ConnectedRecordItemsComponent = {
  template,
  bindings: {
    records: '<',
    type: '@'
  },
  controller: class ConnectedRecordItemsComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}
  }
};
