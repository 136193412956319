import template from './workspace-watermark.pug';

export const WorkspaceWatermarkComponent = {
  template,
  bindings:{
    workspace: '<'
  },
  controller: class WorkspaceWatermarkComponent {
    constructor($async, $log, Workspace, Upload) {
      'ngInject';
      this.$log = $log;
      this.Workspace = Workspace;
      this.Upload = Upload;
      this.selectConfig = {
        valueField: 'value',
        labelField: 'title',
        maxItems: 1,
        plugins: [],
        translate: true,
      };
      this.gravityPositions = [
        'NorthEast', 'North', 'NorthWest',
        'East', 'Center', 'West',
        'SouthEast', 'South', 'SouthWest'];

      this.uploadWatermark = $async(this.uploadWatermark.bind(this));
      this.updateGravity = $async(this.updateGravity.bind(this));
      this.removeWatermark = $async(this.removeWatermark.bind(this));
    }

    $onInit() {}

    async uploadWatermark() {
      try {
        this.loader = true;
        const response = await this.Upload.upload({
          url: window.apiHost + '/workspaces/' + this.workspace.id + '/watermark',
          data: { watermark: this.watermark }
        }).finally(() => {
          this.loader = false;
        });
        this.workspace.watermark_url = response.data;
      } catch (e) {
        this.$log.error(e);
      }
    }


    async updateGravity(gravity) {
      this.workspace.watermark_gravity = gravity;
      try {
        this.loader = true;
        this.workspace.watermark_url = await this.Workspace.watermarkGravity(this.workspace);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    onGravityChange() {
      this.updateGravity();
    }

    async removeWatermark() {
      try {
        this.loader = true;
        await this.Workspace.removeWatermark(this.workspace.id);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }


  }
};
