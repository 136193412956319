import angular from 'angular';
import template from './custom-field-multiselect-form.pug';

export const CustomFieldMultiselectFormComponent = {
  template,
  bindings:{
    field: '<',
    group: '<',
    onSave: '&',
    onBack: '&?',
    onClose: '&?',
    loading: '<?'
  },
  controller: class CustomFieldMultiselectFormComponent {
    constructor($translate) {
      'ngInject';
      this.$translate = $translate;

      this.selectedTab = 'field';

      this.record = {
        lockable: false,
        validations: {
          multiple_select: true,
          required: false,
        },
        default_value: {
          multiple: [],
          single: '',
        },
      };

      this.selectOptions = {
        maxItems: 1,
        valueField: 'id',
        labelField: 'title'
      };
      this.options = [];
    }

    $onInit() {
      if (this.field && this.field.id) {
        this.record = angular.copy(this.field);
        this.options = angular.copy(this.record.options);
      } else {
        this.record = { ...this.record, ...this.field };
        this.record.title = this.$translate.instant(this.field.name);
      }

      if (this.group.generic_module == 'estates') {
        this.tabs = ['field', 'estate_type', 'settings', 'preview'];
      } else {
        this.tabs = ['field','settings', 'preview'];
      }
    }

    updateOptions({ options }) {
      this.options = options;
    }

    back() {
      this.onBack({ $event: false });
    }

    isMultiselect(multiple_select) {
      if (typeof multiple_select === 'boolean') {
        return multiple_select ? null : 1;
      } else if (typeof multiple_select === 'string') {
        return multiple_select === 'true' ? null : 1;
      }
    }

    close() {
      this.onClose({ $event: null });
    }

    save() {
      this.loading = true;
      this.record.options_attributes = this.options;
      if (this.record.validations.multiple_select) {
        delete this.record.default_value.single;
      } else {
        delete this.record.default_value.multiple;
      }
      this.onSave({ $event: { field: this.record } });
    }


  }
};
