import template from './settings-estate-type-groups.pug';
import _pick from 'lodash/pick';

export const SettingsEstateTypeGroupsComponent = {
  template,
  bindings: {
    group: '<',
  },
  controller: class SettingsEstateTypesComponent {
    constructor(AlertBox, Estate, $log, $async, $scope) {
      'ngInject';

      this.AlertBox = AlertBox;
      this.Estate = Estate;
      this.$log = $log;
      this.$scope = $scope;

      this.getEstateTypeGroup = $async(this.getEstateTypeGroups.bind(this));
      this.newGroup = $async(this.newGroup.bind(this));
      this.updateGroupPositions = $async(this.updateGroupPositions.bind(this));
      this.estateTypeGroupsCopy = [];
    }

    $onInit() {
      this.getEstateTypeGroups();
    }

    addEstateType(group) {
      this.$scope.$broadcast(`addEstateType-${group.id}`);
    }

    async getEstateTypeGroups() {
      try {
        const resp = await this.Estate.getEstateTypeGroups();

        this.estateTypeGroups = resp;
        this.estateTypeGroupsCopy = angular.copy(resp);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async newGroup() {
      try {
        const resp = await this.Estate.typeGroupModal();
        if (resp && resp.id) {
          this.estateTypeGroups.push(resp);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async updateGroupPositions() {
      try {
        await this.Estate.estateTypeGroupPositionModal(this.estateTypeGroups);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async updateEstateTypeGroup(group) {
      try {
        const resp = await this.Estate.typeGroupModal(angular.copy(group));
        if (resp && resp.id) {
          this.estateTypeGroups = this.estateTypeGroups.map(item => item.id === resp.id ? resp : item);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }


    async deleteEstateTypeGroup(type) {
      try {
        const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });
        if (result && result.dismiss) return false;

        await this.Estate.deleteEstateTypeGroup(type.id);

        this.estateTypeGroups = this.estateTypeGroups.filter(item => item.id !== type.id);
        this.AlertBox.addMessage('alert.deleted', { type: 'success' });
      } catch (e) {
        this.AlertBox.addMessage('alert.deleted', { type: 'error' });
        this.$log.error(e);
      }
    }

    serializeParams(type) {
      return _pick(type, ['id', 'name']);
    }
  }
};
