import template from './color-dropdown.pug';

function getRGB(c) {
  return parseInt(c, 16) || c;
}

function getsRGB(c) {
  return getRGB(c) / 255 <= 0.03928 ? getRGB(c) / 255 / 12.92 : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4);
}

function getLuminance(hexColor) {
  return 0.2126 * getsRGB(hexColor.substr(1, 2)) + 0.7152 * getsRGB(hexColor.substr(3, 2)) + 0.0722 * getsRGB(hexColor.substr(-2));
}

function getContrast(f, b) {
  const L1 = getLuminance(f);
  const L2 = getLuminance(b);
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
}

function getTextColor(bgColor) {
  const whiteContrast = getContrast(bgColor, '#ffffff');
  const blackContrast = getContrast(bgColor, '#000000');

  return whiteContrast > blackContrast ? '#ffffff' : '#000000';
}

export const ColorDropdownComponent = {
  template,
  bindings: {
    selected: '<?',
    onSelect: '&',
    bordless: '<?',
  },
  transclude: true,
  controller: class ColorDropdownComponent {
    constructor() {
      'ngInject';
      this.colors = [
        '',
        '#7fd13b',
        '#1ab39f',
        '#007f40',
        '#feb80a',
        '#f4cd27',
        '#db4219',
        '#ea6060',
        '#904ea5',
        '#7986cb',
        '#00addc',
        '#dd73d8',
        '#616161',
        '#d79c53',
        '#0a284a',
        '#b96a35',
      ];
    }

    $onInit() {
      if (this.selected == undefined) {
        this.selected = this.colors[0];
      }
      this.onSelect({
        $event: { color: this.selected },
      });
    }

    select(color) {
      this.selected = color;
      this.onSelect({
        $event: { color: color, textColor: getTextColor(color) },
      });
    }
  },
};
