class Permission {

  constructor(Http, Modal, $async, $log, $urlRouter, PermRoleStore, PermPermissionStore, Account, $auth) {
    'ngInject';
    this.Http = Http;
    this.Modal = Modal;
    this.PermRoleStore = PermRoleStore;
    this.PermPermissionStore = PermPermissionStore;
    this.Account = Account;
    this.$auth = $auth;
    this.$log = $log;
    this.$urlRouter = $urlRouter;
    this.setPermissions = $async(this.setPermissions.bind(this));
    this.availableCustomViewModels = ['Contact', 'Estate', 'Inquiry', 'Todo', 'Finance', 'Document'];

    this.modules = [
      { name: 'contacts', crudPermissions: true, scopedRecords: true },
      { name: 'estates', crudPermissions: true, scopedRecords: true },
      { name: 'inquiries', crudPermissions: true, scopedRecords: true },
      { name: 'todos', crudPermissions: true, scopedRecords: true },
      { name: 'documents', crudPermissions: true, scopedRecords: true },
      { name: 'finances', crudPermissions: true, scopedRecords: true },
      { name: 'calendar', crudPermissions: true, scopedRecords: false },
      { name: 'users', crudPermissions: true, scopedRecords: true },
      { name: 'gantt', crudPermissions: false, scopedRecords: false },
      { name: 'statistics', crudPermissions: false, scopedRecords: false },
      { name: 'settings', crudPermissions: false, scopedRecords: false },
    ];
  }

  async setPermissions() {
    let storeRole = this.PermRoleStore.getStore();
    let storePermission = this.PermPermissionStore.getStore();
    let payload = this.$auth.getPayload();
    try {
      if (
        Object.keys(storeRole).length === 0 && storeRole.constructor === Object
        ||  Object.keys(storePermission).length === 0 && storePermission.constructor === Object
        && payload && payload.user.account_id && payload.user.roles
      ) {
        if (payload && payload.user.account_id) {
          let allRoles = await this.Account.roles();
          let checkRole = (roleName) => {
            let currentRoles = this.$auth.getPayload().user.roles;
            let bool = false;
            if (currentRoles && currentRoles.filter(x => x == roleName).length > 0) {
              bool = true;
            }
            return bool;
          };
          await this.PermRoleStore.defineManyRoles(allRoles.reduce((container, role) => {
            container[role.id] = checkRole;
            return container;
            // checkRole;
          }, {}));
          let role_ids = payload.user.roles;
          let permissions = await this.getList({ role_id: role_ids });
          let modulePermissions = this.modulePermissions();
          let yourPermissions = permissions.map(x => x['name']);
          let checkPermission = (permissionName) => {
            return yourPermissions.includes(permissionName);
          };
          await this.PermPermissionStore.defineManyPermissions(modulePermissions, checkPermission);
        }
      }
    } catch (e) {
      this.$log.error(e);
    }
  }

  refreshToken() {
    return this.Http.post('/auth/refresh');
  }

  assign(params) {
    return this.Http.post('/permissions', params);
  }

  getList(params) {
    return this.Http.get('/permissions', params);
  }

  saveCustomViewData(params) {
    return this.Http.post('/permissions/custom_view_data', params);
  }

  permissionCustomViewModal(model) {
    return this.Modal.open({
      size: 'md',
      component: 'permissionCustomViewModal',
      resolve: {
        model: model,
      }
    });
  }

  modulePermissions() {
    const modules = [
      ...this.modules,
      { name: 'dashboard', crudPermissions: false, scopedRecords: false },
      { name: 'users', crudPermissions: true, scopedRecords: false },
    ];

    let all = [];
    modules.forEach(model => {
      const params = [`can_access_${model.name}`];

      if (model.crudPermissions) {
        ['read', 'update', 'delete'].forEach(permission => {
          params.push(`can_${permission}_default_${model.name}`, `can_${permission}_all_${model.name}`);
        });
      }

      all = all.concat(params);
    });

    return all;
  }
}
export default Permission;
