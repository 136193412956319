class Role {

  constructor(Http, Modal) {
    'ngInject';
    this.Http = Http;
    this.Modal = Modal;
  }

  get(id) {
    return this.Http.get('/roles/' + id);
  }

  update(id, params) {
    return this.Http.put('/roles/' + id, params);
  }

  getList() {
    return this.Http.get('/roles');
  }

  create(params) {
    return this.Http.post('/roles', params);
  }

  new() {
    return this.Modal.open({
      size: 'md',
      component: 'roleNew'
    });
  }

  moveToTrash(id) {
    return this.Http.delete('/roles/' + id);
  }

  serialize(role) {
    const { name, description } = role;
    var role_attributes = {
      name,
      description,
    };
    return role_attributes;
  }

}
export default Role;
