import template from './date.pug';

export const DateComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    model: '<',
    validations: '<?',
    format: '<?',
    onEdit: '&?',
  },
  controller: class DateComponent {
    constructor() {
      'ngInject';

      this.datepickerConfig = {
        dateFormat: 'Y-m-d',
        minDate: '1940',
        maxDate: '2030'
      };
    }

    $onInit() {
      this.format = this.format || 'YYYY-MM-DD';
      if (this.model) {
        this.model = dayjs(this.model).format(this.format);
      }
    }

    edit(date) {
      if (!this.onEdit) return;

      this.onEdit({
        $event: { date }
      });
    }
  }
};
