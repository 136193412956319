import template from './todo-estate.pug';

export const TodoEstateComponent = {
  template,
  bindings: {
    estate: '<?',
    options: '<?',
    onRemove: '&?',
    estateId: '<?',
  },
  controller: class TodoEstateComponent {
    constructor(Estate, $log, $async, $window, $timeout) {
      'ngInject';
      this.$log = $log;
      this.$window = $window;
      this.$timeout = $timeout;
      this.Estate = Estate;
      this.disable_link = false;
      this.getEstate = $async(this.getEstate.bind(this));
      this.getFull = $async(this.getFull.bind(this));
      this.tooltip = 'estate-tooltip';
      this.recordLoading = false;
    }

    $onInit() {
      if (this.estateId) this.getEstate();
      if (this.options != undefined) {
        this.disable_link = this.options.disable_link;
      }
    }

    link(estate) {
      return '/estates/show/' + estate.id;
    }

    open() {
      this.$window.open(this.link(this.estate), '_blank');
    }

    async getEstate() {
      try {
        this.estate = await this.Estate.cardInfo(this.estateId);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getFull() {
      if (this.full_estate || this.recordLoading) return;
      this.recordLoading = true;
      try {
        this.full_estate = await this.Estate.find(this.estate.id);
      } catch (e) {
        // when user dont have record access permissions
        this.full_estate = this.estate;
        this.$log.error(e);
      }
    }

    showTooltip() {
      this.getFullEstate();
    }

    openPopper(event, id) {
      this.popperShow = true;
      const popperEl = this.$element.find('#popper-' + id)[0];
      let options = {
        placement: 'bottom-start',
        offset: [0, 0],
        strategy: 'fixed',
      };
      this.popper = createPopper(event.currentTarget, popperEl, options);
    }

    hideTooltip() {}

    removeEnabled() {
      return typeof this.onRemove === 'function';
    }

    remove(estate) {
      this.onRemove({
        $event: { estate },
      });
    }
  },
};
