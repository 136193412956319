import template from './custom-view-input.pug';

export const CustomViewInputComponent = {
  template,
  bindings: {
    fields: '<',
    model: '<',
    onSelected: '&'
  },
  transclude : true,
  controller: class CustomViewInputComponent {
    constructor($translate) {
      'ngInject';
      this.$translate = $translate;
      this.optGroups = [];
      this.searchOpt = [];
    }

    $onInit() {
      // this.initSearchConf();
    }

    $onChanges({ fields } = {}) {
      if (fields && fields.currentValue) {
        this.initSearchConf();
      }
    }

    splitGroupsFields() {
      for (let property in this.fields) {
        const group = this.fields[property];
        if (group && group.length) {
          this.optGroups.push(
            {
              value: property,
              label: (group[0].is_custom ? property : this.$translate.instant(('custom_view.group.' + property))),
              is_custom: group[0].is_custom || false,
            }
          );
          // istrint nenaudojamus  key veliau...
          this.searchOpt.push(
            ...group.map(elm => {
              return {
                ...elm,
                class: property,
                value: elm['custom_field_key'],
                name: elm.is_custom ? elm.name : this.$translate.instant(('custom_view.filter.' + elm.name)),
                is_custom: elm['is_custom'] || false,
              };
            })
          );
        }
      }
      return this.optGroups;
    }

    initSearchConf() {
      this.searchConfig = {
        maxItems: 1,
        optgroupField: 'class',
        placeholder: this.$translate.instant('custom_view.select_filter'),
        labelField: 'name',
        translate: true,
        valueField: 'custom_field_key',
        searchField: ['name'],
        plugins: [],
        optgroups: this.splitGroupsFields(),
      };
    }

    getGroup(value) {
      const el= this.searchOpt.find((e) => {
        return e['custom_field_key'] == value;
      });
      return el['class'];
    }

    applySelect(value) {
      let group;
      if (value != undefined) group = this.getGroup(value);
      this.onSelected({ $event: { ...{ group }, ...{ value } } });
    }
  }
};
