Array.prototype.groupBy = function(prop) {
  return this.reduce((groups, item) => {
    const val = item[prop];
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {});
};

Array.prototype.asyncForEach = async function(callback) {
  for (let index = 0; index < this.length; index++) {
    await callback(this[index], index, this);
  }
};

String.prototype.toCamel = function() {
  return this.toLowerCase().replace(/(?:(^.)|(\s+.))/g, (match) => {
    return match.charAt(match.length-1).toUpperCase();
  });
};

String.prototype.hexToRGBA = function(opacity = 1) {
  const hex = this.replace('#', '');
  const rgb = hex.match(new RegExp('(.{' + hex.length/3 + '})', 'g')).map(function(l) { return parseInt(hex.length % 2 ? l + l : l, 16); });

  return `rgba(${rgb},${opacity})`;
};

String.prototype.toColor = function() {
  const colors = ['#7fd13b', '#1ab39f', '#007f40', '#feb80a', '#f4cd27', '#db4219', '#ea6060', '#904ea5', '#7986cb', '#00addc', '#dd73d8', '#616161'];

  let hash = 0;
  if (this.length === 0) return hash;
  for (let i = 0; i < this.length; i++) {
    hash = this.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  hash = ((hash % colors.length) + colors.length) % colors.length;
  return colors[hash];
};

String.prototype.toInitials = function() {
  if (!this) return;
  const names = this.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

String.prototype.toLinks = function() {
  return this.replace(/(\b(https?|):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig,
    '<a href=\'$1\' target=\'_blank\'>$1</a>');
};
