
import template from './notification-switcher.pug';
import { plural } from 'pluralize';

export const NotificationSwitcherComponent = {
  template,
  bindings: {
    model: '<',
    isAccount: '<?',
    user: '<?',
  },
  controller: class NotificationSwitcherComponent {
    constructor(Settings, CustomField, AlertBox, RecordStatus, $async, $log) {
      'ngInject';

      this.isAccount = false;
      this.$log = $log;
      this.Settings = Settings;
      this.CustomField = CustomField;
      this.AlertBox = AlertBox;
      this.RecordStatus = RecordStatus;

      this.getSettings = $async(this.getSettings.bind(this));
      this.updateSetting = $async(this.updateSetting.bind(this));
      this.getRecordStatuses = $async(this.getRecordStatuses.bind(this));

    }

    $onInit() {
      this.getSettings();
      this.getRecordStatuses();
    }

    mapDateCustomFields(settingValue) {
      const setting = angular.copy(settingValue);
      const availableFieldOptions = [];

      this.dateCustomFields.forEach(field => {
        const foundFieldOption = setting.fields_options.findIndex(option => option.custom_field_id == field.id);

        if (foundFieldOption > -1) {
          availableFieldOptions.push({ ...setting.fields_options[foundFieldOption], title: field.title });
        } else {
          availableFieldOptions.push({
            enabled: false,
            custom_field_id: field.id,
            title: field.title,
            days_before: 1,
          });
        }
      });

      return { ...setting, fields_options: availableFieldOptions };
    }


    async getRecordStatuses() {
      try {
        if (this.model == 'todo') {
          this.record_statuses = [];
        } else {
          this.record_statuses = await this.RecordStatus.getList(this.model);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getSettings() {
      try {
        let settings = [];
        this.dateCustomFields = await this.CustomField.getDateFieldsByModel(plural(this.model));

        if (this.isAccount == true) {
          settings = await this.Settings.getNotificationSettings(this.model, 'account');
        } else {
          const params = {};
          if (this.user && this.user.id) params.user_id = this.user.id;
          settings = await this.Settings.getNotificationSettings(this.model, 'user', params);
        }

        this.settings = settings.map(setting => {
          if (setting.key == 'notify_date') {
            setting.value = this.mapDateCustomFields(setting.value);
          }

          return setting;
        });
      } catch (e) {
        this.$log.error(e);
      }
    }

    translateResolver(setting) {
      let translationString = `settings.notifications.${setting.key}`;
      if (setting.key == 'notify_match') {
        translationString += `.${setting.module}`;
      }
      return translationString;
    }

    async updateSetting(setting) {
      try {
        const params = { module: this.model, key: setting.key, value: setting.value };

        if (this.isAccount == true) {
          await this.Settings.update(params, 'account');
        } else {
          if (this.user && this.user.id) params.user_id = this.user.id;
          await this.Settings.update(params);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
