import template from './group-new-modal.pug';

export const GroupNewModalComponent = {
  template,
  bindings:{
    resolve: '<',
    modalInstance: '<',
  },
  controller: class GroupNewModalComponent {
    constructor($async, $log, CustomField, AlertBox) {
      'ngInject';
      this.CustomField = CustomField;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.save = $async(this.save.bind(this));
      this.loader = false;
    }

    $onInit() {
      this.group = this.resolve.group;
    }


    async save({group}) {
      try {
        this.loader = true;
        const resp =  await this.CustomField.newGroup(group);
        this.modalInstance.close(resp);
        this.AlertBox.addMessage('alert.created', {type: 'success'});
      }catch (e) {
        this.$log.error(e);
      }finally {
        this.loader = false;
      }
      // new group modal
    }

    close() {
      this.modalInstance.close(void(0));
    }

  }
};
