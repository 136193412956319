import template from './contact-topbar.pug';
export const ContactTopbarComponent = {
  template,
  bindings: {
    contact: '<',
    notFixed: '<?',
  },
  controller: class ContactTopbarComponent {
    constructor(Contact, LocalStorage, $log, $state) {
      'ngInject';
      this.Contact = Contact;
      this.LocalStorage = LocalStorage;
      this.$log = $log;
      this.$state = $state;
    }

    $onInit() {}

    navigateBack() {
      this.$state.go('main.contacts.list', { page: this.LocalStorage.get('contact', 'list_page') || 1 });
    }

  },
};
