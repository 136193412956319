import template from './documents.pug';

export const DocumentsComponent = {
  template,
  bindings: {},
  controller: class DocumentsComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}
  }
};
