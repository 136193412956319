import dayjs from 'dayjs';
import _map from 'lodash/map';

class Todo {
  constructor(Http, Modal, $state, Chronology, Upload, $translate, Workspace) {
    'ngInject';

    this.Http = Http;
    this.Modal = Modal;
    this.Upload = Upload;
    this.Workspace = Workspace;
    this.$translate = $translate;
    this.$state = $state;
    this.Chronology = Chronology;
  }

  navigateToShow(id) {
    this.$state.go('main.todos.show', { id: id });
  }

  getList(params = {}) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.Http.get('/todos', params);
  }

  getGanttList(params = {}) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.Http.get('/todos/gantt', params);
  }

  createLink(params) {
    return this.Http.post('/todos/gantt/links', params);
  }

  destroyLink(id) {
    return this.Http.delete(`/todos/gantt/links/${id}`);
  }

  getCalendarList(params = {}) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.Http.get('/todos/calendar/', params);
  }

  exportGantt({ calendar_id }) {
    return this.Http.get('/todos/gantt/export', { calendar_id });
  }

  getDashboardList() {
    return this.Http.get('/todos/dashboard');
  }

  count(params = {}) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.Http.get('/todos/count', params);
  }

  getListByIds(ids, params = {}) {
    return this.getListBySearchId(0, { todo_ids: ids, ...params }).then((records) => {
      return _map(records.data, (el) => {
        return el.attributes;
      });
    });
  }

  getListBySearchId(searchId, params) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.Http.post(`/todos/list/${searchId}`, params);
  }

  newModal(todo = {}) {
    return this.Modal.open({
      size: 'md',
      component: 'todosModalNew',
      resolve: {
        todo: () => todo,
      },
    });
  }

  previewModal(record) {
    return this.Modal.open({
      animation: false,
      size: 'sidebar',
      backdrop: true,
      component: 'todosPreviewModal',
      resolve: {
        record: () => record,
      },
    });
  }

  multipleDeleteModal(checked_ids) {
    return this.Modal.open({
      size: 'md',
      component: 'bulkRecordDestroyModal',
      resolve: {
        objectType: () => 'Todo',
        objectIds: function () {
          return checked_ids;
        },
      },
    });
  }

  toggleContactInvitation(todo_id, contact_id) {
    return this.Http.post(`/todos/${todo_id}/contacts/${contact_id}/toggle_invitation`);
  }

  inviteContact(todo_id, contact_id) {
    return this.Http.post(`/todos/${todo_id}/contacts/${contact_id}/send_invitation`);
  }

  getSmartReminderResponse(todo_id, contact_id) {
    return this.Http.get(`/todos/${todo_id}/contacts/${contact_id}/invitation_status`);
  }

  multipleDelete(ids) {
    return this.Http.put('/todos/delete_multiple', { ids });
  }

  export(searchId, params) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.Http.post(`/todos/export/${searchId}`, params);
  }

  statuses() {
    return [
      { status: 'active', color: '#1973E7', textKey: 'todos.states.active' },
      { status: 'completed', color: '#00DCA1', textKey: 'todos.states.completed' },
    ];
  }

  states() {
    return [
      { id: 'today', localized_name: this.$translate.instant('todos.states.today'), icon: 'star' },
      { id: 'upcoming', localized_name: this.$translate.instant('todos.states.upcoming'), icon: 'calendar' },
      { id: 'completed', localized_name: this.$translate.instant('todos.states.completed'), icon: 'check-circle' },
    ];
  }

  // TODO: Don't see where its used not needed?
  getListDeleted(params = {}) {
    return this.Http.get('/todos/deleted', params);
  }

  update(id, params) {
    return this.Http.put('/todos/' + id, params);
  }

  recover(ids, settings_obj) {
    return this.Http.put('/todos/recover', { ids: ids, recover_type: settings_obj.key, recover_value: settings_obj.value });
  }

  groupModal(group) {
    return this.Modal.open({
      size: 'md',
      component: 'todoGroupModal',
      resolve: {
        group: () => group,
      },
    });
  }

  updateGroupPosition(id, position) {
    return this.Http.put('/todo_groups/' + id + '/position/', { position });
  }

  deleteGroup(id) {
    return this.Http.delete('/todo_groups/' + id);
  }

  getGroups(params = {}) {
    return this.Http.get('/todo_groups', params.filter);
  }

  getGroupCounted(params = {}) {
    return this.Http.get('/todo_groups/counted', params.filter);
  }

  updateGroup(params) {
    return this.Http.put('/todo_groups/' + params.id, params);
  }

  createGroup(params) {
    return this.Http.post('/todo_groups/', params);
  }

  create(params) {
    return this.Http.post('/todos', params);
  }

  getById(id = -1) {
    return this.Http.get('/todos/' + id);
  }

  addContacts(id, contact_ids) {
    return this.Http.put('/todos/' + id + '/contacts', { contact_ids });
  }

  removeContacts(id, contact_ids) {
    return this.Http.delete('/todos/' + id + '/contacts/', { contact_ids });
  }

  getContacts(id) {
    if (!id) return [];
    return this.Http.get('/todos/' + id + '/contacts');
  }

  getInquiries(id) {
    if (!id) return [];
    return this.Http.get('/todos/' + id + '/inquiries');
  }

  addInquiries(id, inquiry_ids) {
    return this.Http.put('/todos/' + id + '/inquiries', { inquiry_ids });
  }

  removeInquiries(id, inquiry_ids) {
    return this.Http.delete('/todos/' + id + '/inquiries/', { inquiry_ids });
  }

  addEstates(id, estate_ids) {
    return this.Http.put('/todos/' + id + '/estates', { estate_ids });
  }

  addEstate(id, estate_id) {
    return this.Http.put('/todos/' + id + '/estates/' + estate_id);
  }

  getEstates(id) {
    if (!id) return [];
    return this.Http.get('/todos/' + id + '/estates');
  }

  removeEstate(id, estate_id) {
    return this.Http.delete('/todos/' + id + '/estates/' + estate_id);
  }

  removeEstates(id, estate_ids) {
    return this.Http.delete('/todos/' + id + '/estates/', { estate_ids });
  }

  getAttachments(id) {
    if (!id) return [];
    return this.Http.get('/todos/' + id + '/attachments');
  }

  addAttachments(id, attachments) {
    return this.Upload.upload({
      url: window.apiHost + '/todos/' + id + '/attachments',
      data: { attachments },
    });
  }

  removeAttachments(id, attachment_ids) {
    return this.Http.delete('/todos/' + id + '/attachments/', { attachment_ids });
  }

  addUsers(id, user_ids, replace = false) {
    return this.Http.put('/todos/' + id + '/users', { user_ids, replace });
  }

  getUsers(id) {
    if (!id) return [];
    return this.Http.get('/todos/' + id + '/users');
  }

  updatePosition(id, position) {
    return this.Http.put('/todos/' + id + '/position', { position });
  }

  removeUsers(id, user_ids) {
    return this.Http.delete('/todos/' + id + '/users/', { user_ids });
  }

  addReminder(id, reminder_params) {
    return this.Http.post('/todos/' + id + '/reminders', reminder_params);
  }

  getReminders(id) {
    if (!id) return [];
    return this.Http.get('/todos/' + id + '/reminders');
  }

  removeReminder(id, reminder_id) {
    return this.Http.delete('/todos/' + id + '/reminders/' + reminder_id);
  }

  removeReminders(id, reminder_ids) {
    return this.Http.delete('/todos/' + id + '/reminders/', { reminder_ids });
  }

  addReminders(id, reminders) {
    return this.Http.post('/todos/' + id + '/reminders', { reminders });
  }

  updateReminders(id, reminders) {
    return this.Http.put('/todos/' + id + '/reminders', { reminders });
  }

  getSettings() {
    return this.Http.get('/todos/settings');
  }

  markAsDone(id) {
    return this.Http.get('/todos/' + id + '/done');
  }

  moveToTrash(id) {
    return this.Http.delete('/todos/' + id);
  }

  find(id) {
    return this.getById(id);
  }

  cardInfo(id) {
    return this.Http.get(`/todos/${id}/card_info`);
  }

  getBusyDates(month) {
    return this.Http.get('/todos/busy-dates', {
      starts_at: dayjs(month).startOf('month').format('YYYY-MM-DD'),
      ends_at: dayjs(month).endOf('month').format('YYYY-MM-DD'),
    });
  }

  reminderOptions() {
    return [
      { value: 15, unit: 'minutes', type: 'email' },
      { value: 30, unit: 'minutes', type: 'email' },
      { value: 1, unit: 'hours', type: 'email' },
      { value: 2, unit: 'hours', type: 'email' },
      { value: 3, unit: 'hours', type: 'email' },
      { value: 1, unit: 'days', type: 'email' },
      { value: 2, unit: 'days', type: 'email' },
      { value: 3, unit: 'days', type: 'email' },
      { value: 1, unit: 'weeks', type: 'email' },
      { value: 2, unit: 'weeks', type: 'email' },
      { value: 1, unit: 'months', type: 'email' },
    ];
  }

  reminderTypeOptions() {
    return [{ value: 'email' }, { value: 'sms' }];
  }
}
export default Todo;
