import template from './users-show.pug';
export const UsersShowComponent = {
  template,
  bindings: {
    user: '<'
  },
  controller: class UsersShowComponent {
    constructor(User, Upload, AlertBox, $state, $log, $async) {
      'ngInject';
      this.$state = $state;
      this.formState = 'show';
      this.Upload = Upload;
      this.$log = $log;
      this.User = User;
      this.AlertBox = AlertBox;
      this.uploadPhoto = $async(this.uploadPhoto.bind(this));
      this.moveToArchive = $async(this.moveToArchive.bind(this));
    }

    $onInit() {

    }

    edit() {
      this.formState = 'edit';
    }

    navigateBack() {
      this.$state.go('main.settings.users.list');
    }

    onSubmit({ user }) {
      this.formState = 'show';
      this.user = user;
    }

    async moveToArchive() {
      try {
        const response = await this.User.archiveModal(this.user);

        if (response && response.user) {
          this.user = response.user;
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async uploadPhoto({ file }) {
      try {
        const response = await this.Upload.upload({
          url: window.apiHost + '/users/' + this.user.id + '/photo',
          data: { photo: file }
        });
        this.user.image_url = response.data.image_url;
      } catch (e) {
        this.$log.error(e);
      }
    }



  }
};
