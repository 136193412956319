import template from './comment-edit.pug';

export const CommentEditComponent = {
  template,
  bindings: {
    item: '<',
    onCancel: '&?',
    onSave: '&?',
  },
  controller: class CommentEditComponent {
    constructor(Chronology) {
      'ngInject';
      this.Chronology = Chronology;
    }

    $onInit() {
      this.init();
    }

    $onChanges() {
      this.init();
    }

    init() {
      this.record = this.item.record;
      if (this.record === undefined) {
        this.record = {};
      }
      this.record.attachments = this.item.attachments || [];
    }

    cancel() {
      this.onCancel({
        $event: { state: 'show' }
      });
    }

    addDocument({ file }) {
      this.record.attachments.push(file);
    }

    save(comment) {
      this.onSave({
        $event: { comment }
      });
    }

  }
};
