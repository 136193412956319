import template from './attachment-list.pug';

export const AttachmentListComponent = {
  template,
  bindings: {
    attachments: '<',
    fileName: '@?',
    onDestroy: '&?',
    local: '<?'
  },
  controller: class AttachmentListComponent {
    constructor(Attachment, $async, $log) {
      'ngInject';
      this.Attachment = Attachment;
      this.$log = $log;
      this.deleteFile = $async(this.deleteFile.bind(this));
    }

    $onInit() {
      this.attachments = this.attachments || [];
    }

    async deleteFile({ attachment }) {
      if (this.local) {
        this.onDestroy({ $event: { file: attachment } });
        this.attachments.splice(this.attachments.indexOf(attachment), 1);
      } else {
        try {
          await this.Attachment.delete(attachment.id);
          this.attachments.splice(this.attachments.indexOf(attachment), 1);
        } catch (e) {
          this.$log.error(e);
        }
      }
    }
  }
};
