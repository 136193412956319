import template from './attachment-download-modal.pug';

export const AttachmentDownloadModalComponent = {
  template,
  bindings: {
    resolve: '<',
    modalInstance: '<',
  },
  controller: class AttachmentDownloadModalComponent {
    constructor(Attachment, Http, $async, $window, $log) {
      'ngInject';

      this.Attachment = Attachment;
      this.Http = Http;
      this.$async = $async;
      this.$window = $window;
      this.$log = $log;
      this.tabs = ['download', 'history'];
      this.selected = this.tabs[0];
      this.loading = false;
      this.download = $async(this.download.bind(this));
      this.getDownloadReport = $async(this.getDownloadReport.bind(this));
      this.setTab = $async(this.setTab.bind(this));
      this.downloadReport = [];
    }

    $onInit() {
      if (this.resolve) {
        this.attachment = this.resolve.record;
      }
    }

    async setTab(select_tab) {
      this.selected = select_tab;
      if (this.selected == 'history' && !this.downloadReport.length) {
        await this.getDownloadReport();
      }
    }

    resolveExtensionIcon() {
      return this.Attachment.extensionIcon(this.attachment.name);
    }

    async getDownloadReport() {
      try {
        this.downloadReport = await this.Attachment.report(this.attachment.id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async download() {
      this.loading = true;
      try {
        const response = await this.Attachment.download(this.attachment.id);

        await this.Http.downloadS3Blob(response.url, response.filename);
        this.loading = false;
        this.close();
      } catch (e) {
        this.$log.error(e);
      }
    }

    close() {
      this.modalInstance.close(void(0));
    }
  }
};
