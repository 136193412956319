import template from './chronology-todo-table.pug';

export const ChronologyTodoTableComponent = {
  template,
  bindings: {
    chronology: '<',
  },
  controller: class ChronologyTodoTableComponent {
    constructor(Todo, $async, $log) {
      'ngInject';

      this.Todo = Todo;
      this.$log = $log;

      this.recordPreview = $async(this.recordPreview.bind(this));
      this.loadEstates = $async(this.loadEstates.bind(this));
      this.loadContacts = $async(this.loadContacts.bind(this));
      this.loadInfo = $async(this.loadInfo.bind(this));

      this.estates = {};
      this.estate_counts = {};
      this.contacts = {};
      this.contact_counts = {};
    }

    $onInit() {
    }

    async loadInfo(todo_id, record_type) {
      try {
        if (record_type == 'TodoEstate') {
          await this.loadContacts(todo_id);
        }
        if (record_type == 'TodoContact') {
          await this.loadEstates(todo_id);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }


    async loadEstates(todo_id) {
      try {
        const estates = await this.Todo.getEstates(todo_id);
        if (estates.length) {
          this.estates[todo_id] = estates[0];
          this.estate_counts[todo_id] = estates.length - 1;
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async loadContacts(todo_id) {
      try {
        const contacts = await this.Todo.getContacts(todo_id);

        if (contacts.length) {
          this.contacts[todo_id] = contacts[0];
          this.contact_counts[todo_id] = contacts.length - 1;
        }

      } catch (e) {
        this.$log.error(e);
      }
    }


    async recordPreview(selectedRecord) {
      try {
        const record = await this.Todo.find(selectedRecord.id);
        await this.Todo.previewModal(record);
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
