import template from './user-table-header.pug';

export const UserTableHeaderComponent = {
  template,

  bindings: {
    filter: '<',
    totalCount: '<',
    onFilterChange: '&',
    afterPageChange: '&',
    checkedIds: '<',
    isModal: '<',
  },

  controller: class UserTableHeaderComponent {
    constructor() {
      'ngInject';
      this.sortSelectize = {
        valueField: 'value',
        labelField: 'label',
        maxItems: 1,
        translate: true,
        translatePrefix: 'general.',
        plugins: []
      };
    }


    applyFilter() {
      this.onFilterChange({
        $event: this.filter
      });
    }

    applyPage({ page }) {
      this.afterPageChange({
        $event: { page }
      });
    }

    openAll() {
      if (this.checkedIds && this.checkedIds.length > 0) {
        this.checkedIds.forEach((id) => {
          window.open('/users/' + id);
        });
      }
    }

  }
};

