import template from './header.pug';

export const FinancesTableHeaderComponent = {
  template,
  bindings: {
    records: '<',
    checkedIds: '<',
    totalPages: '<',
    totalCount: '<',
    page: '<',
    reportsSum: '<',
    afterPageChange: '&',
    onFilterChange: '&',
    isModal: '<?',
    filter: '<?'
  },
  controller: class FinancesTableHeaderComponent {
    constructor(Table, Finance, AlertBox,  $log, $async, $state, $window, $stateParams) {
      'ngInject';

      this.Table = Table;
      this.Finance = Finance;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.$state = $state;
      this.$stateParams = $stateParams;
      this.export = $async(this.export.bind(this));
      this.multipleDelete = $async(this.multipleDelete.bind(this));
      this.openCharts = false;
      this.incomeDetails = false;
      this.expensesDetails = false;
      this.currency = $window.CURRENCY;
    }

    $onChanges({ filter } = {}) {
      if (filter && filter.currentValue) {
        Object.keys(this.filter).length > 1 ? this.disabledClearFilter = false : this.disabledClearFilter = true;
      }
    }

    $onInit() {
      if (this.reportsSum) {
        console.log(this.reportsSum);
      }

    }

    async multipleDelete() {
      if (!this.alertIfNotSelected()) return;
      try {

        let object = await this.Finance.multipleDeleteModal(this.checkedIds);
        if (object) {
          this.applyFilter({ searchId: this.$stateParams.searchId });
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    alertIfNotSelected() {
      if (!this.checkedIds.length) {
        this.AlertBox.done('alert.select_at_least_one_record', { type: 'info' });
        return false;
      }
      return true;
    }

    async export() {
      try {
        let response = await this.Finance.export(this.filter);
        if (response) this.AlertBox.addMessage('alert.export_table_success', { type: 'success' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    applyPage({ page }) {
      this.afterPageChange({
        $event: { page }
      });
    }

    applyFilter({ searchId }) {
      this.onFilterChange({
        $event: { searchId }
      });
    }
  }
};
