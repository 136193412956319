import template from './custom-fields-edit.pug';

export const CustomFieldsEditComponent = {
  template,
  bindings: {
    record: '<',
    cfGroups: '<',
    onCancel: '&?',
    onSave: '&?',
  },
  controller: class CustomFieldsEditComponent {
    constructor(AlertBox) {
      'ngInject';

      this.AlertBox = AlertBox;
    }

    $onInit() {
    }

    cancel() {
      this.onCancel({
        $event: { state: 'show' }
      });
    }

    save(record, form) {
      if (form.$valid) {
        this.onSave({
          $event: { record }
        });
      } else {
        this.AlertBox.addMessage('validation_messages.fill_required_fields', { type: 'warning' });
      }
    }
  }
};
