import template from './users.pug';

export const UsersComponent = {
  template,
  bindings: {},
  controller: class UsersComponent {
    constructor() {
    }

    $onInit() {}
  }
};
