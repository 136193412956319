import template from './workspace-show.pug';

export const WorkspaceShowComponent = {
  template,
  bindings: {},
  controller: class WorkspaceShowComponent {
    constructor(Workspace, $log, $async, $stateParams, $state) {
      'ngInject';

      this.Workspace = Workspace;
      this.$log = $log;
      this.$stateParams = $stateParams;
      this.$state = $state;
      this.getWorkspace = $async(this.getWorkspace.bind(this));
    }

    $onInit() {
      this.getWorkspace();
    }

    async getWorkspace() {
      try {
        this.workspace = await this.Workspace.find(this.$stateParams.id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    navigateBack() {
      this.$state.go('main.settings.workspaces');
    }
  }
};
