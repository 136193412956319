import template from './input-password.pug';

export const InputPasswordComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    placeholder: '@?',
    model: '<',
    validations: '<?',
    onEdit: '&?',
  },
  controller: class InputPasswordComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
      this.passwordInputType = 'password';
    }

    edit(password) {
      this.onEdit({
        $event: { password }
      });
    }

    togglePasswordInputType() {
      this.passwordInputType = (this.passwordInputType == 'password') ? 'text' : 'password';
    }

  }
};
