import template from './custom-field-estate-types.pug';

export const CustomFieldEstateTypes = {
  template,
  bindings:{
    field: '<',
    group: '<',
  },
  controller: class CustomFieldEstateTypes {
    constructor(Estate, $async) {
      'ngInject';
      this.Estate = Estate;
      this.estateTypes = this.Estate.types();
      this.loadEstateTypes = $async(this.loadEstateTypes.bind(this));
    }

    async loadEstateTypes() {
      try {
        this.estateTypeGroups = await this.Estate.getEstateTypeGroups({ with_children: true });
        this.selectEstateTypeGroup(this.estateTypeGroups[0]);
      } catch (e) {
        this.$log.error(e);
      }
    }

    $onInit() {
      this.loadEstateTypes();
      this.setDefaultValues();
    }

    selectEstateTypeGroup(group) {
      this.estateTypeGroup = group;
    }

    setDefaultValues() {
      if (this.field && Array.isArray(this.field.estate_type_ids) == false || this.field.estate_type_ids.length == 0) {
        this.field.estate_type_ids = this.estateTypes.map(estateType =>  estateType.id);
      }
    }

    checkboxClicked(selected, estateType) {
      if (selected) {
        this.field.estate_type_ids.push(estateType.id);
      } else {
        this.field.estate_type_ids = this.field.estate_type_ids.filter(estate_type_id => {
          return estate_type_id !== estateType.id;
        });
      }
    }
  }
};
