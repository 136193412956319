import template from './checkbox.pug';

export const CheckBoxComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    model: '<',
    validations: '<?',
    onEdit: '&?',
  },
  controller: class CheckBoxComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }

    toggle() {
      this.model = !this.model;
      this.edit(this.model);
    }

    edit(text) {
      if (!this.onEdit) return;

      this.onEdit({
        $event: { text }
      });
    }
  }
};
