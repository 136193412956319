import template from './custom-field-body.pug';

export const CustomFieldBodyComponent = {
  template,
  bindings: {
    group: '<',
    onEditField: '&?',
    onDeleteField: '&?'

  },
  controller: class CustomFieldBodyComponent {
    constructor(CustomField, $async, $log, AlertBox) {
      'ngInject';
      this.CustomField = CustomField;
      this.$log = $log;
      this.AlertBox = AlertBox;
      this.fields = this.CustomField.fieldList;
      this.updatePosition = $async(this.updatePosition.bind(this));
      this.sortableOptions = {
        handle: '.sortableListHandle',
        tolerance: 'pointer',
        stop: () => {
          this.group.custom_fields.forEach((value, index) => {
            value.position = index + 1;
          });
        },
        update: (e, ui) => {
          const position = ui.item.sortable.dropindex + 1;
          const id = ui.item.sortable.model.id;
          this.updatePosition(id, position);
        },
        axis: 'y'
      };
    }

    $onInit() {}

    fieldIcon(type) {
      const field = this.fields.find(elm => elm.field_type === type);
      return field.icon;
    }

    async updatePosition(id, position) {
      try {
        await this.CustomField.updatePosition(id,position);
        this.AlertBox.addMessage('alert.updated', { type: 'success' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    editField(field) {
      this.onEditField({
        $event: {
          field
        }
      });
    }

    deleteField(field) {
      this.onDeleteField({
        $event: {
          field
        }
      });
    }
  }
};
