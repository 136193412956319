import template from './money-number-show.pug';

export const MoneyNumberShowComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    priceType: '@',
    model: '<',
  },
  controller: class MoneyNumberShowComponent {
    constructor() {
      'ngInject';
      this.currency = window.CURRENCY;
    }

    $onInit() {
    }

  }
};
