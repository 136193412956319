import template from './inquiry-tbody.pug';

export const InquiryTbodyComponent = {
  template,
  bindings: {
    filter: '<',
    records: '<',
    checkedIds: '<',
    onFilterChange: '&',
    gridOptions: '<?',
    isModal: '<?',
    onCheck: '&',
  },
  controller: class InquiryTbodyComponent {
    constructor($async, $stateParams, $log, Inquiry) {
      'ngInject';
      this.$stateParams = $stateParams;
      this.$log = $log;

      this.getInquiry = $async(this.getInquiry.bind(this));

      this.Inquiry = Inquiry;
      this.isVisible = false;
      this.recordModalStep = 'location';
    }

    $onInit() {}

    $onChanges({ checkedIds, records }) {
      if (checkedIds && checkedIds.currentValue && checkedIds.currentValue.length == 0) {
        this.records && this.records.forEach((record) => {
          record.checked = false;
        });
      }
      if (records && records.currentValue) {
        this.gridOptions.data = records.currentValue.map(x => {
          return x.attributes = { ...x.attributes, ...x.attributes['custom_fields'] };
        });
      }
    }

    checkIds({ checkedIds }) {
      this.onCheck({ $event: { checkedIds } });
    }

    showInquiry({ row }) {
      this.Inquiry.navigateToShow( row.entity.id);
    }

    applyFilter({ filter, searchId }) {
      this.onFilterChange({
        $event: { filter, searchId }
      });
    }


    updateFilter() {
      const params =  {
        filter: this.filter,
        searchId: this.$stateParams.searchId
      };
      this.applyFilter(params);
    }

    preview({ row }) {
      this.getInquiry(row.entity.id);
    }

    previewDirection({ id }) {
      this.getInquiry(id);
    }

    goTo({ row }) {
      this.Inquiry.navigateToShow(row.entity.id);
    }

    close() {
      this.isVisible = false;
    }

    async getInquiry(id) {
      try {
        this.selectedRecord = await this.Inquiry.find(id);
        this.isVisible = true;
        this.recordModalStep = 'location';
      } catch (e) {
        this.$log.error(e);
      }
    }

    async updateInquiry(id, params) {
      try {
        let attributes = this.Inquiry.serialize(params);
        let inquiry = await this.Inquiry.update(id, attributes);
        await this.gridOptions.data.forEach( (item, i) => {
          item.id == inquiry.id ?  this.gridOptions.data[i] = { ...inquiry, ...inquiry.custom_fields }: null;
        });
        this.gridOptions.data = angular.copy(this.gridOptions.data);
        this.Table.gridApi.grid.refreshRows();

        this.AlertBox.addMessage('alert.updated',{ type: 'success' });
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};

