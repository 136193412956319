// services
import Portal from '../../services/portal/portal.service';
import Account from '../../services/account/account.service';
import Todo from '../../services/todo/todo.service';
import Webhooks from './webhooks/webhooks.service';

// helpers

import { AccountCancelComponent } from './account/cancel/account-cancel.component';
import { AccountBillingComponent } from './account/billing/account-billing.component';
import { AccountSettingsComponent } from './account/account-settings.component';

// base component
import { SettingsComponent } from './settings.component';
//components
import { SettingsMenuComponent } from './menu/settings-menu.component';

import { SettingsTrashbinComponent } from './trashbin/settings-trashbin.component';

import { PortalsListComponent } from './portals/list/portals-list.component';
import { PortalsShowComponent } from './portals/show/portals-show.component';
import { PortalNewComponent } from './portals/new/portal-new.component';

import { SettingsEstateTypesComponent } from './estates/estate_types/settings-estate-types.component';

import { SettingsUsersMainComponent } from './users/main/settings-users-main.component';

import { SettingsCustomFieldsComponent } from './custom-fields/settings-custom-fields.component';
import { SettingsCustomFieldsShowComponent } from './custom-fields/show/settings-custom-fields-show.component';
import { RolesSettingsShowComponent } from './roles/show/roles-settings-show.component';

import { SettingsNotificationsComponent } from './notifications/settings-notifications.component';
import { SettingsTimePanelComponent } from './shared/settings-time-panel/settings-time-panel.component';

import { SettingsWorkspacesComponent } from './workspaces/settings-workspaces.component';

import { SettingsEstateTypeGroupsComponent } from './estates/estate-type-groups/settings-estate-type-groups.component';
import { EstateTypeGroupModalComponent } from './estates/estate-type-group-modal/estate-type-group-modal.component';
import { EstateTypeGroupPositionModalComponent } from './estates/estate-type-group-position-modal/estate-type-group-position-modal.component';
import { WebhooksListComponent } from './webhooks/webhooks-list/webhooks-list.component';
import { WebhooksEditModalComponent } from './webhooks/webhooks-edit-modal/webhooks-edit-modal.component';

export const SettingsModule = angular
  .module('topbroker.settings', [])
  .service('Portal', Portal)
  .service('Account', Account)
  .service('Todo', Todo)
  .service('Webhooks', Webhooks)

  .component('settings', SettingsComponent)
  .component('settingsMenu', SettingsMenuComponent)

  .component('accountSettings', AccountSettingsComponent)
  .component('accountCancel', AccountCancelComponent)
  .component('accountBilling', AccountBillingComponent)

  .component('settingsUsersMain', SettingsUsersMainComponent)

  .component('portalsList', PortalsListComponent)
  .component('portalsShow', PortalsShowComponent)
  .component('portalNew', PortalNewComponent)
  .component('webhooksList', WebhooksListComponent)
  .component('webhooksEditModal', WebhooksEditModalComponent)

  .component('estateTypeGroupPositionModal', EstateTypeGroupPositionModalComponent)
  .component('settingsEstateTypeGroups', SettingsEstateTypeGroupsComponent)
  .component('settingsEstateTypes', SettingsEstateTypesComponent)
  .component('estateTypeGroupModal', EstateTypeGroupModalComponent)

  .component('rolesSettingsShow', RolesSettingsShowComponent)

  .component('settingsNotifications', SettingsNotificationsComponent)
  .component('settingsCustomFields', SettingsCustomFieldsComponent)
  .component('settingsCustomFieldsShow', SettingsCustomFieldsShowComponent)
  .component('settingsTrashbin', SettingsTrashbinComponent)
  .component('settingsTimePanel', SettingsTimePanelComponent)

  .component('settingsWorkspace', SettingsWorkspacesComponent)

  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('main.settings', {
        url: '/settings',
        component: 'settings',
        abstract: true,
        data: {
          permissions: {
            only: 'can_access_settings',
            redirectTo: 'main.dashboard.stats',
          },
        },
      })
      .state('main.settings.account', {
        url: '/account',
        component: 'accountSettings',
        pageTitle: 'settings.account.title',
      })
      .state('main.settings.account.cancel', {
        url: '/cancel',
        component: 'accountCancel',
      })
      .state('main.settings.billing', {
        url: '/billing',
        component: 'accountBilling',
        pageTitle: 'billing.title',
      })
      .state('main.settings.users', {
        url: '/users',
        abstract: true,
      })
      .state('main.settings.users.list', {
        url: '/list?:state',
        params: { state: { dynamic: true } },
        pageTitle: 'settings.user.main.title',
        component: 'settingsUsersMain',
      })
      .state('main.settings.users.custom_fields', {
        url: '/custom_fields',
        pageTitle: 'settings.user.custom_fields',
        component: 'settingsCustomFieldsShow',
        resolve: {
          name() {
            return 'users';
          },
        },
      })
      .state('main.settings.roles', {
        url: '/roles',
        abstract: true,
      })
      .state('main.settings.roles.show', {
        url: '/:id',
        component: 'rolesSettingsShow',
        pageTitle: 'main.settings.roles',
        resolve: {
          role: [
            'Role',
            '$stateParams',
            (Role, $stateParams) => {
              return Role.get($stateParams.id).then((role) => {
                return role;
              });
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{$resolve.role.name}}',
          parent: 'main.settings.roles',
        },
      })

      .state('main.settings.trashbin', {
        url: '/trashbin',
        component: 'settingsTrashbin',
        ncyBreadcrumb: {
          parent: 'main.settings.index',
        },
      })
      .state('main.settings.trashbin.estates', {
        params: { page: { dynamic: true }, searchId: { dynamic: true } },
        url: '/estates/:page?:searchId',
        data: {
          active: 'estates',
        },
        resolve: {
          filter: ($stateParams) => {
            'ngInject';
            let filter = {};
            filter.page = $stateParams.page;
            return filter;
          },
        },
        ncyBreadcrumb: {
          label: 'main.settings.trashbin',
          parent: 'main.settings.index',
        },
      })
      .state('main.settings.trashbin.contacts', {
        url: '/contacts/:page?:searchId',
        data: {
          active: 'contacts',
        },
        params: { page: { dynamic: true }, searchId: { dynamic: true } },
        resolve: {
          filter: ($stateParams) => {
            'ngInject';

            let filter = {};
            filter.page = $stateParams.page;
            return filter;
          },
        },
        ncyBreadcrumb: {
          label: 'main.settings.trashbin',
          parent: 'main.settings.index',
        },
      })
      .state('main.settings.trashbin.inquiries', {
        url: '/inquiries/:page?:searchId',
        data: {
          active: 'inquiries',
        },
        params: { page: { dynamic: true }, searchId: { dynamic: true } },
        resolve: {
          filter: ($stateParams) => {
            'ngInject';

            let filter = {};
            filter.page = $stateParams.page;
            return filter;
          },
        },
        ncyBreadcrumb: {
          label: 'main.settings.trashbin',
          parent: 'main.settings.index',
        },
      })
      .state('main.settings.portals', {
        url: '/portals',
        abstract: true,
      })
      .state('main.settings.portals.webhooks', {
        url: '/webhooks',
        component: 'webhooksList',
        pageTitle: 'settings.integrations.webhooks.title',
      })
      .state('main.settings.portals.list', {
        url: '/list/:type',
        component: 'portalsList',
        resolve: {
          type: ($stateParams) => {
            'ngInject';
            return $stateParams.type;
          },
        },
        pageTitle: 'settings.portals.title',
      })
      .state('main.settings.portals.show', {
        url: '/show/:id',
        component: 'portalsShow',
        resolve: {
          portal: [
            '$stateParams',
            '$log',
            'Portal',
            async ($stateParams, $log, Portal) => {
              try {
                const portal = await Portal.find($stateParams.id);
                return portal;
              } catch (e) {
                $log.error(e);
              }
            },
          ],
          estates: [
            '$stateParams',
            '$log',
            'Portal',
            async ($stateParams, $log, Portal) => {
              try {
                const estates = await Portal.getEstates($stateParams.id, -1, {});
                return estates;
              } catch (e) {
                $log.error(e);
              }
            },
          ],
        },
      })
      .state('main.settings.estates', {
        url: '/estates',
        abstract: true,
      })
      .state('main.settings.estates.time_panel', {
        url: '/time_panel',
        component: 'settingsTimePanel',
        pageTitle: 'settings.shared.time_panel.title',
        resolve: {
          moduleName: () => 'estate',
        },
      })
      .state('main.settings.estates.estate_types', {
        url: '/estate_types',
        component: 'settingsEstateTypeGroups',
        pageTitle: 'settings.estates.estate_types',
      })
      .state('main.settings.estates.custom_table', {
        url: '/custom_table',
        component: 'settingsCustomTable',
        pageTitle: 'custom_table.title',
        resolve: {
          objectClass: () => 'estate',
          cfObjectClass: () => 'estates',
          toolbarTitle: () => 'custom_table.title',
        },
      })
      .state('main.settings.estates.notifications', {
        url: '/notifications',
        component: 'settingsNotifications',
        pageTitle: 'settings.notifications.title',
        resolve: {
          model: () => 'estate',
        },
      })
      .state('main.settings.estates.custom_fields', {
        url: '/custom_fields',
        component: 'settingsCustomFieldsShow',
        pageTitle: 'settings.estates.custom_fields',
        resolve: {
          name() {
            return 'estates';
          },
        },
      })
      .state('main.settings.record_statuses', {
        pageTitle: 'settings.record_statuses.title',
        params: { module: { dynamic: true } },
        url: '/record_statuses/:module',
        component: 'recordStatusTable',
        resolve: {
          module: ($stateParams) => {
            'ngInject';
            return $stateParams.module;
          },
        },
      })
      .state('main.settings.contacts', {
        url: '/contacts',
        abstract: true,
      })
      .state('main.settings.contacts.time_panel', {
        url: '/time_panel',
        component: 'settingsTimePanel',
        pageTitle: 'settings.shared.time_panel.title',
        resolve: {
          moduleName: () => 'contact',
        },
      })
      .state('main.settings.contacts.custom_table', {
        url: '/custom_table',
        component: 'settingsCustomTable',
        pageTitle: 'custom_table.title',
        resolve: {
          objectClass: () => 'contact',
          cfObjectClass: () => 'contacts',
          translatePrefix: () => 'contacts',
          toolbarTitle: () => 'custom_table.title',
        },
      })
      .state('main.settings.contacts.notifications', {
        url: '/notifications',
        component: 'settingsNotifications',
        pageTitle: 'settings.notifications.title',
        resolve: {
          model: () => 'contact',
        },
      })
      .state('main.settings.contacts.custom_fields', {
        url: '/custom_fields',
        component: 'settingsCustomFieldsShow',
        pageTitle: 'settings.contacts.custom_fields',
        resolve: {
          name() {
            return 'contacts';
          },
        },
      })
      .state('main.settings.inquiries', {
        url: '/inquiries',
        abstract: true,
      })
      .state('main.settings.inquiries.time_panel', {
        url: '/time_panel',
        component: 'settingsTimePanel',
        pageTitle: 'settings.shared.time_panel.title',
        resolve: {
          moduleName: () => 'inquiry',
        },
      })
      .state('main.settings.inquiries.custom_table', {
        url: '/custom_table',
        component: 'settingsCustomTable',
        pageTitle: 'custom_table.title',
        resolve: {
          objectClass: () => 'inquiry',
          cfObjectClass: () => 'inquiries',
          toolbarTitle: () => 'custom_table.title',
        },
      })
      .state('main.settings.inquiries.notifications', {
        url: '/notifications',
        component: 'settingsNotifications',
        pageTitle: 'settings.notifications.title',
        resolve: {
          model: () => 'inquiry',
        },
      })
      .state('main.settings.inquiries.custom_fields', {
        url: '/custom_fields',
        component: 'settingsCustomFieldsShow',
        pageTitle: 'settings.inquiries.custom_fields',
        resolve: {
          name() {
            return 'inquiries';
          },
        },
      })
      .state('main.settings.custom_fields', {
        url: '/custom_fields',
        component: 'settingsCustomFields',
        ncyBreadcrumb: {
          parent: 'main.settings.index',
        },
      })
      .state('main.settings.custom_fields_module', {
        url: '/custom_fields/:name',
        component: 'settingsCustomFieldsShow',
        resolve: {
          name: function ($stateParams) {
            'ngInject';
            return $stateParams.name;
          },
        },
      })
      .state('main.settings.todos', {
        url: '/todos',
        abstract: true,
      })
      .state('main.settings.todos.groups', {
        url: '/groups',
        component: 'todoGroupTable',
        pageTitle: 'settings.todos.groups',
      })
      .state('main.settings.todos.notifications', {
        url: '/notifications',
        component: 'settingsNotifications',
        pageTitle: 'settings.notifications.title',
        resolve: { model: () => 'todo' },
      })
      .state('main.settings.todos.custom_fields', {
        url: '/custom_fields',
        component: 'settingsCustomFieldsShow',
        pageTitle: 'settings.todos.custom_fields',
        resolve: { name: () => 'todos' },
      })
      .state('main.settings.todos.custom_table', {
        url: '/custom_table',
        component: 'settingsCustomTable',
        pageTitle: 'custom_table.title',
        resolve: {
          objectClass: () => 'todo',
          cfObjectClass: () => 'todos',
          translatePrefix: () => 'todos',
          toolbarTitle: () => 'custom_table.title',
        },
      })
      .state('main.settings.documents', {
        url: '/documents',
        abstract: true,
      })
      .state('main.settings.documents.custom_fields', {
        url: '/custom_fields',
        component: 'settingsCustomFieldsShow',
        pageTitle: 'settings.custom_fields',
        resolve: { name: () => 'documents' },
      })
      .state('main.settings.documents.custom_table', {
        url: '/custom_table',
        component: 'settingsCustomTable',
        pageTitle: 'custom_table.title',
        resolve: {
          objectClass: () => 'document',
          cfObjectClass: () => 'documents',
          translatePrefix: () => 'documents',
          toolbarTitle: () => 'custom_table.title',
        },
      })
      .state('main.settings.documents.groups', {
        url: '/groups',
        component: 'documentGroupTable',
        pageTitle: 'settings.documents.groups.title',
      })
      .state('main.settings.finances', {
        url: '/finances',
        abstract: true,
      })
      .state('main.settings.finances.groups', {
        url: '/groups',
        component: 'financeGroupTable',
        pageTitle: 'settings.finances.groups.title',
      })
      .state('main.settings.finances.custom_fields', {
        url: '/custom_fields',
        component: 'settingsCustomFieldsShow',
        pageTitle: 'settings.custom_fields',
        resolve: { name: () => 'finances' },
      })
      .state('main.settings.finances.custom_table', {
        url: '/custom_table',
        component: 'settingsCustomTable',
        pageTitle: 'general.custom_table',
        resolve: {
          objectClass: () => 'finance',
          cfObjectClass: () => 'finances',
          translatePrefix: () => 'finances',
          toolbarTitle: () => 'general.custom_table',
        },
      })
      .state('main.settings.workspaces', {
        url: '/workspaces',
        component: 'settingsWorkspace',
        pageTitle: 'workspaces.settings.title',
      })
      .state('main.settings.workspaces.show', {
        url: '/:id',
        component: 'workspaceShow',
        pageTitle: 'workspaces.settings.title',
      });
  }).name;
