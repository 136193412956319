import template from './location-google.pug';
export const LocationGoogleComponent = {
  template,
  bindings: {
    address:'<',
    label: '@?',
    description: '@?',
    validations: '<?',
    onChange: '&?',
    autoFocus: '<?',
  },
  transclude : true,
  controller: class LocationGoogleComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
      this. autoFocus = this.autoFocus || true;
    }

    initAutocomplete() {
      let input = document.getElementById('autocomplete');
      let autocomplete = new google.maps.places.Autocomplete(input, {
        fields: ['formatted_address', 'geometry', 'name'],
        strictBounds: false,
      });
      autocomplete.setComponentRestrictions({ 'country': window.COUNTRY_CODE });
      autocomplete.addListener('place_changed', (e) => {
        // dont know how to get exect input of autocomplete, since input is not returning .val()
        const place = autocomplete.getPlace();
        this.address = place.formatted_address;
        this.onChange({
          $event: { address: this.address, place: place }
        });
        // $scope.$apply()
      });
    }

  }
};
