import template from './todos.pug';

export const TodosComponent = {
  template,
  bindings: {},
  controller: class TodosComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }
  }
};
