import template from './reminder.pug';

export const ReminderComponent = {
  template,
  bindings: {},
  controller: class ReminderComponent {
    constructor(User, Account, $async) {
      'ngInject';
      this.User = User;
      this.Account = Account;
      this.getVisual = $async(this.getVisual.bind(this));
    }

    $onInit() {
      this.reminding = false;
      this.messages = [];
      this.passwordInputType = 'password';
      this.user = {};
      this.getVisual();
    }

    async getVisual() {
      try {
        const resp = await this.Account.accountVisualIdentity();
        if (resp) {
          this.logoUrl = resp.logo_url;
        }
      } catch (error) {
        console.error(error);
      }
    }

    closeMessage(index) {
      this.messages.splice(index, 1);
    }

    validate(error, touched) {
      var valid = (Object.keys(Object(error)).length) && touched;
      return !!valid;
    }

    remind() {
      if (!this.user.email) return;
      this.reminding = true;
      this.User.remindPassword(this.user.email).then((user) => {
        this.reminding = false;
        this.messages = ['auth.reminder_sent'];
        this.user = {};
      }).catch(() => {
        this.reminding = false;
        this.messages = ['auth.email_not_exsists'];
      });
    }

  }

};
