import template from './inquiries-trashbin-table.pug';

export const InquiriesTrashbinTableComponent = {
  template,

  bindings: {
    filter: '<',
    onFilterChange: '&',
    gridOptions: '<',
    onCheck: '&',
    checkedIds: '<'
  },

  controller: class InquiriesTrashbinTableComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }

    applyFilter({ filter }) {
      this.onFilterChange({ $event:{ filter } });
    }

    checkIds({ checkedIds }) {
      this.onCheck({ $event: { checkedIds } });
    }

  }
};

