

import { toJson } from 'angular';
import template from './menu.pug';

export const MenuComponent = {
  template,
  bindings: {},
  controller: class MenuComponent {
    constructor(Search, PermRoleStore, PermPermissionStore, Estate, Contact, Inquiry, User, $state, $auth, $localStorage, $async, $log, $window) {
      'ngInject';
      this.$auth = $auth;
      this.$state = $state;
      this.Search = Search;
      this.PermRoleStore = PermRoleStore;
      this.PermPermissionStore = PermPermissionStore;
      this.Estate = Estate;
      this.Contact = Contact;
      this.Inquiry = Inquiry;
      this.User = User;
      this.localStorage = $localStorage;
      this.$log = $log;
      this.$window = $window;

      this.createNewEstate = $async(this.createNewEstate.bind(this));
      this.createNewContact = $async(this.createNewContact.bind(this));
      this.createNewInquiry = $async(this.createNewInquiry.bind(this));
      this.drawMenu = $async(this.drawMenu.bind(this));
      this.inviteUser = $async(this.inviteUser.bind(this));

    }


    $onInit() {
      this.drawMenu();
      this.initChat();
    }

    async inviteUser() {
      try {
        await this.User.assignInvites();
      } catch (e) {
        this.$log.error(e);
      }
    }

    async drawMenu() {
      try {
        // let userId;
        // const usersStats = await this.User.count({ status: 'active' });

        // if (usersStats.count > 1) {
        //   userId = this.$auth.getPayload().user.id;
        // }

        this.menu = [
          {
            title: 'menu_items.estates',
            permission: 'can_access_estates',
            icon: 'home',
            state: 'main.estates.list',
            searchModule: 'Estate',
            stateParams: {
              page: 1,
              searchId: -1,
              active: 'estates',
            },
          },

          {
            title: 'menu_items.contacts',
            permission: 'can_access_contacts',
            icon: 'users',
            state: 'main.contacts.list',
            searchModule: 'Contact',
            stateParams: {
              page: 1,
              searchId: -1,
              active: 'contacts',
            },
          },

          {
            title: 'menu_items.inquiries',
            permission: 'can_access_inquiries',
            icon: 'crosshair',
            state: 'main.inquiries.list',
            searchModule: 'Inquiry',
            stateParams: {
              page: 1,
              searchId: -1,
              active: 'inquiries',
            },
          },

          {
            title: 'menu_items.documents',
            permission: 'can_access_documents',
            icon: 'folder',
            state: 'main.documents.list',
            searchModule: 'Document',
            stateParams: {
              page: 1,
              searchId: -1,
              active: 'documents.list',
            },
          },
          {
            title: 'menu_items.finances',
            permission: 'can_access_finances',
            icon: 'dollar-sign',
            state: 'main.finances.list',
            stateParams: {
              page: 1,
              active: 'finances.list',
            },
          },

          {
            title: 'menu_items.calendar',
            permission: 'can_access_calendar',
            icon: 'calendar',
            state: 'main.todos.calendar',
            active: 'calendar'
          },
          {
            title: 'menu_items.todos',
            permission: 'can_access_todos',
            icon: 'check-circle',
            state: 'main.todos.list',
            searchModule: 'Todo',
            stateParams: {
              page: 1,
              searchId: -1,
              active: 'todos.list',
            },
          },
          {
            title: 'statistics.user_top',
            permission: 'can_access_statistics',
            icon: 'bar-chart-2',
            state: 'main.statistics.users',
            active: 'statistics',
            stateParams: {
              date: dayjs().format('YYYY-MM'),
            },
          },

        ];


      } catch (e) {
        this.$log.error(e);
      }

    }

    estateRoutes(userId) {
      let routes = [];

      routes.push({
        title: 'menu_items.all_estates',
        permission: 'can_access_estates',
        icon: 'home',
        state: 'main.estates.list',
        searchModule: 'Estate',
        stateParams: {
          page: 1,
          searchId: -1,
          active: 'estates',
        },
      });

      if (userId) {
        routes.push({
          title: 'menu_items.my_estates',
          permission: 'can_access_estates',
          icon: 'home',
          state: 'main.estates.user-list',
          searchModule: 'Estate',
          stateParams: {
            user_id: userId,
            page: 1,
            searchId: -1,
            active: 'estates',
          },
        });
      }

      routes.push({
        title: 'general.add_new',
        permission: 'can_update_default_estates',
        icon: 'plus',
        action: 'createNewEstate'
      });
      return routes;
    }

    contactRoutes(userId) {
      let routes = [];

      routes.push({
        title: 'menu_items.all_contacts',
        permission: 'can_access_contacts',
        icon: 'users',
        state: 'main.contacts.list',
        searchModule: 'Contact',
        stateParams: {
          page: 1,
          searchId: -1,
          active: 'contacts',
        },
      });

      if (userId) {
        routes.push({
          title: 'menu_items.my_contacts',
          permission: 'can_access_contacts',
          icon: 'users',
          state: 'main.contacts.user-list',
          searchModule: 'Contact',
          stateParams: {
            user_id: userId,
            page: 1,
            searchId: -1,
            active: 'contacts',
          },
        });
      }

      routes.push({
        title: 'general.add_new',
        permission: 'can_update_default_contacts',
        icon: 'plus',
        action: 'createNewContact'
      });
      return routes;
    }

    inquiryRoutes(userId) {
      let routes = [];

      routes.push({
        title: 'menu_items.all_inquiries',
        permission: 'can_access_inquiries',
        icon: 'crosshair',
        state: 'main.inquiries.list',
        searchModule: 'Inquiry',
        stateParams: {
          page: 1,
          searchId: -1,
          active: 'inquiries',
        },
      });

      if (userId) {
        routes.push({
          title: 'menu_items.my_inquiries',
          permission: 'can_access_inquiries',
          icon: 'crosshair',
          state: 'main.inquiries.user-list',
          searchModule: 'Inquiry',
          stateParams: {
            user_id: userId,
            page: 1,
            searchId: -1,
            active: 'inquiries',
          },
        });
      }

      routes.push({
        title: 'general.add_new',
        permission: 'can_update_default_inquiries',
        icon: 'plus',
        action: 'createNewInquiry'
      });
      return routes;
    }

    async initChat() {
      try {
        // const user = this.$auth.getPayload().user;

        // this.$window.fcWidget.user.setProperties({
        //   externalId: user.id,
        //   firstName: user.name,
        //   email: user.email,
        //   phone: user.phone,
        //   accountId: user.account_id,
        //   accountTitle: user.account_title
        // });

      } catch (e) {
        this.$log.error(e);
      }

    }

    toggleMenuGroup(menuGroup, state) {
      return this.localStorage['menu_group_' + menuGroup] = state;
    }

    isClosed(menuGroup) {
      if (this.localStorage['menu_group_' + menuGroup] == undefined) {
        this.toggleMenuGroup(menuGroup, false);
      }
      return this.localStorage['menu_group_' + menuGroup];
    }

    openChat() {
      window.fcWidget.open();
    }

    userGuide() {

    }

    logout() {
      this.$auth.logout();
      this.PermRoleStore.clearStore();
      this.PermPermissionStore.clearStore();
      this.localStorage.$reset();
      this.$state.go('auth.login');
    }

    navigateTo(route) {
      if (route.action) {
        return this[route.action]();
      }

      if (route.searchModule) {
        route.stateParams.searchId = this.Search.returnLastSearchId(route.searchModule);
      }
      this.$state.go(route.state, route.stateParams);
    }

    async createNewEstate() {
      try {
        this.loader = true;
        const estate = await this.Estate.new();
        if (estate && estate.id) {
          this.$state.go('main.estates.show', { id: estate.id });
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    async createNewContact() {
      try {
        this.loader = true;
        const contact = await this.Contact.new();
        if (contact && contact.id) {
          this.$state.go('main.contacts.show', { id: contact.id });
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    async createNewInquiry() {
      try {
        this.loader = true;
        const inquiry = await this.Inquiry.newModal();
        if (inquiry && inquiry.id) {
          this.$state.go('main.inquiries.show', { id: inquiry.id });
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    goToSetting() {
      this.$state.go('main.settings.users.list');
    }

  },
};
