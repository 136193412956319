import template from './user-settings.pug';

export const UserSettingsComponent = {
  template,
  bindings: {
    user: '<',
  },
  controller: class UserSettingsComponent {
    constructor(PermPermissionStore) {
      'ngInject';
      this.tabs = ['user_integrations', 'table_settings', 'notification_settings'];
      this.selected = this.tabs[0];

      PermPermissionStore.getPermissionDefinition('can_access_settings').validatePermission().then((resp) => {
        if (resp) this.tabs.push('workspace_permissions');
      });
    }

    $onInit() {}
  },
};
