import template from './record-status-tab-list.pug';

export const RecordStatusTabListComponent = {
  template,
  bindings: {
    statuses: '<',
    onSelect: '&?',
    selectedId: '<?'
  },
  controller: class RecordStatusTabListComponent {
    constructor(RecordStatus, Estate, $async, $log, $stateParams) {
      'ngInject';
      this.RecordStatus = RecordStatus;
      this.Estate = Estate;
      this.$async = $async;
      this.$stateParams = $stateParams;
      this.$log = $log;
    }


    $onInit() {
      if (!this.selectedId) {
        this.selectedId = -1;
      }
    }

    select(status) {
      this.selectedId = status.id;
      const filter = this.filter;
      this.onSelect({ $event: { status, filter } });
    }



    isSelected(status) {
      return this.selectedId == status.id;
    }

    getStyle(status) {
      let style = { 'border-color': status.color };
      if (this.isSelected(status)) {
        style = Object.assign(style, { 'background-color': status.color, color: 'white' });
      }
      return style;
    }

  }
};
