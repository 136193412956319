import template from './comment-show.pug';

export const CommentShowComponent = {
  template,
  bindings: {
    item: '<',
    onEdit: '&?',
    onCopy: '&?',
    onDelete: '&?',
  },
  controller: class CommentShowComponent {
    constructor(AlertBox, $async) {
      'ngInject';
      this.AlertBox = AlertBox;
      this.destroy = $async(this.destroy.bind(this));
    }

    $onInit() {
      this.record = this.item.record;
    }

    removeAttachment(attachment) {
      this.record.attachments.splice(this.record.attachments.indexOf(attachment), 1);
    }

    change({ state }) {
      this.onEdit({
        $event: { state }
      });
    }

    async destroy() {
      const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });
      if (result && result.dismiss) return false;
      this.onDelete({ $event: {record: this.record} });
    }
  }
};
