import template from './todos-calendar.pug';
// https://fullcalendar.io/docs

import { Calendar } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

// https://fullcalendar.io/docs
import { createPopper } from '@popperjs/core';

// https://flatpickr.js.org/
import flatpickr from 'flatpickr';
import dayjs from 'dayjs';

function _getValueByKey(map, val) {
  return Object.keys(map).find(key => map[key] === val);
}

export const TodosCalendarComponent = {
  template,
  bindings: {
    mode: '<?'
  },
  controller: class TodosCalendarComponent {
    constructor($element, $rootScope, $async, $compile, $log, $translate, $localStorage, $state, $stateParams, Todo, AlertBox, Hotkeys) {
      'ngInject';
      this.$stateParams = $stateParams;
      this.$translate = $translate;
      this.$state = $state;
      this.$localStorage = $localStorage;
      this.selectedDate = $stateParams.date ? $stateParams.date : dayjs().format('YYYY-MM-DD');
      this.startDate = null;
      this.endDate = null;
      const locale = 'en';
      const calendarEl = $element.find('#todoCalendar')[0];
      this.preventOverEl = document.getElementById('todoCalendar');
      this.popperEl = $element.find('#popper');
      this.Hotkeys = Hotkeys;
      this.$element = $element;
      this.$rootScope = $rootScope;
      this.$compile = $compile;
      this.selectedGroupId = $stateParams.group || 0;
      this.todoListForMiniCalendar = [];
      this.dateToday = new Date();
      this.mode = 'full';
      this.calendaViewStates = {
        'day': 'timeGridDay',
        'week': 'timeGridWeek',
        'month': 'dayGridMonth',
        'list': 'listWeek',
        'dashboard': 'list'
      };
      this.calendarViewHotKeys = [
        ['day', 'D'],
        ['week', 'W'],
        ['month', 'M'],
        ['list', 'L'],
      ];

      // transfer to settings
      this.minHour = 6;
      this.maxHour = 22;

      this.getBusyDates = async (month) => {
        try {
          this.todoListForMiniCalendar = await Todo.getBusyDates(month);
        } catch (e) {
          $log.log(e);
        }
      };

      this.reDrawMiniCalendar = (miniCalendar) => {
        if (!miniCalendar) return;
        this.getBusyDates( new Date(miniCalendar.currentYear, miniCalendar.currentMonth, 1) );
      };

      this.miniCalendarOptions = () => {
        let options = {
          inline: true,
          dateFormat: 'Y-m-d',
          placeholder: '',
          defaultDate: this.selectedDate,
          onDayCreate: (dObj, dStr, fp, dayElem) => {
            if ( this.todoListForMiniCalendar.includes(dayjs(dayElem.dateObj).format('YYYY-MM-DD').toString()) ) {
              dayElem.innerHTML += '<span class=\'flatpickr-event busy\'></span>';
            }
          },
          onChange: (selectedDates, dateStr, instance)  => {
            this.selectedDate = selectedDates[0];
            this.calendar.gotoDate(selectedDates[0]);
            this.reDrawMiniCalendar(instance);
          },
          onMonthChange: (selectedDates, dateStr, instance) => {
            // const month = new Date(instance.currentYear, instance.currentMonth, 1);
            // this.calendar.gotoDate(month);
            this.reDrawMiniCalendar(instance);
          },
          onYearChange: (selectedDates, dateStr, instance) => {
            // const month = new Date(instance.currentYear, instance.currentMonth, 1);
            // this.calendar.gotoDate(month);
            this.reDrawMiniCalendar(instance);
          }
        };
        return options;
      };

      this.initMiniCalendar = () => {
        return flatpickr($element.find('#miniCalendar')[0], this.miniCalendarOptions());
      };

      this.miniCalendar = this.initMiniCalendar();

      this.currentEvent = null;
      this.AlertBox = AlertBox;
      this.close = $async(this.close.bind(this));
      this.Todo = Todo;

      const gridViewSelect = () => {
        return this.calendaViewStates[$stateParams.defaultView];
      };

      this.openTodo = ({ todo, event, state }) => {
        this.todo = todo;
        this.rootScope = $rootScope.$new();
        this.rootScope['createCall'] = todoResponse => {
          this.rootScope.$destroy();
          this.addEvent(todoResponse);
          this.reDrawMiniCalendar(this.miniCalendar);
        };
        this.rootScope['closeCallExit'] = () => {
          this.calendar.unselect();
          this.close();
        };
        this.rootScope['copyCall'] = (todoResponse) => {
          let newTodo =  angular.copy(todoResponse.todo);
          newTodo.id = null;
          this.openTodo({ todo: newTodo, event: event, state: 'edit' });
        };
        this.rootScope['todo'] = todo;
        this.rootScope['callUpdatePopper'] = () => {
          if (this.infoPopOver) this.infoPopOver.update();
        };
        this.rootScope['destroyCall'] = () => {
          this.removeEvent(this.todo.id);
        };
        this.rootScope['switchToEdit'] = () => {
          this.rootScope.$destroy();
          this.openTodo({ todo: this.todo, event: event, state: 'edit' });
          this.infoPopOver.destroy();
        };

        this.rootScope['updateAction'] = (todoResponse) => {
          this.infoPopOver.destroy();
          this.rootScope.$destroy();
          if (todoResponse.todo && todoResponse.todo.id) {
            this.updateEvent( todoResponse );
            return this.openTodo({ todo: todoResponse.todo, event: event, state: 'show' });
          }
          this.close();
        };

        this.rootScope['updateCompletionState'] = () => {
          this.updateCompletionState();
        };


        const element = angular.element(
          state == 'show' ? '<todo-show on-copy="copyCall($event)" on-close="closeCallExit()" on-state-change="updateCompletionState($event)" todo="todo" on-edit="switchToEdit($event)" after-update-view="callUpdatePopper($event)" on-destroy="destroyCall()" ></todo-show>'
            : '<todo-form todo="todo" add-time="true" on-close="closeCallExit()" on-update="updateAction($event)" on-create="createCall($event)" after-update-view="callUpdatePopper($event)"></todo-form>'
        );

        this.popperEl.empty();
        this.popperEl.append(element);
        $compile(element)(this.rootScope);
        this.virtualElement = {
          getBoundingClientRect: () => {
            return {
              width: 0,
              height: 0,
              top: event.clientY,
              right: event.clientX,
              bottom: event.clientY,
              left: event.clientX
            };
          }
        };
        setTimeout(() => {
          this.infoPopOver = createPopper(this.virtualElement, this.popperEl[0], {
            placement: 'right',
            modifiers: [
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['left', 'bottom']
                }
              },
              {
                name: 'preventOverflow',
                options: {
                  boundary: this.preventOverEl
                }
              }
            ]
          });
        }, 1);

      };

      const stateParamsViewSelect = stateParamsView => {
        this.miniCalendar.destroy();
        this.miniCalendar = this.initMiniCalendar();
        return _getValueByKey(this.calendaViewStates, stateParamsView);
      };


      this.calendar = new Calendar(calendarEl, {
        plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin, listPlugin],
        initialView: gridViewSelect(),
        initialDate: this.selectedDate,
        height: 'parent',
        headerToolbar: false,
        locale,
        firstDay: 1,
        allDaySlot: false,
        eventTimeFormat: { hour12: false, hour: '2-digit', minute: '2-digit' },
        nowIndicator: true,
        nextDayThreshold: '00:00',
        slotMinTime: dayjs().hour(this.minHour).format('HH:00'),
        slotMaxTime: dayjs().hour(this.maxHour).format('HH:00'),
        selectConstraint: {
          startTime: dayjs().hour(this.minHour).format('HH:00'),
          endTime: dayjs().hour(this.maxHour).format('HH:00'),
        },
        editable: true,
        progressiveEventRendering: false,
        forceEventDuration: true,
        eventResizableFromStart: true,
        eventDurationEditable: true,
        droppable: true,
        selectMirror: true,
        scrollTimeReset: false,
        selectable: true,
        unselectAuto: false,
        defaultAllDayEventDuration: { days: 1 },
        eventBackgroundColor: '#666',
        eventBorderColor: '#666',
        allDayContent: $translate.instant('todos.all_day'),
        buttonText: {
          today: $translate.instant('calendar.today'),
          month: $translate.instant('calendar.month'),
          week: $translate.instant('calendar.week'),
          day: $translate.instant('calendar.day')
        },
        dayHeaderContent: (calendar) => {
          if (calendar.view.type !== 'timeGridWeek') return;
          return {
            html: `<div class="fn-calendar-wkday">${dayjs(calendar.date).format('ddd')}</div>
          <div class="fn-calendar-day">${dayjs(calendar.date).format('DD')}</div>`
          };
        },
        events: async (info, successCallback, failureCallback) => {
          try {

            let params = {
              todo_group_id: this.selectedGroupId,
              starts_at: dayjs(info.start.valueOf()).startOf('month').format('YYYY-MM-DD'),
              ends_at: dayjs(info.end.valueOf()).endOf('month').format('YYYY-MM-DD')
            };
            const todoList = await Todo.getCalendarList(params);
            successCallback(todoList);

          } catch (err) {
            failureCallback(err);
          }
        },
        selectAllow: selectInfo => {
          // prevent selecting more than one day
          if (selectInfo.end.getTime() / 1000 - selectInfo.start.getTime() / 1000 <= 86400) {
            return true;
          }
        },
        select: async selectInfo => {

          this.miniCalendar.setDate(selectInfo.start);
          if (!dayjs(selectInfo.start).isSame(this.calendarDate)) this.clicked = 0;

          let todo = {
            todo_group_id: this.selectedGroupId,
            starts_at: dayjs(selectInfo.start),
            ends_at: dayjs(selectInfo.end)
          };

          this.openTodo({ todo: todo, event: selectInfo.jsEvent, state: 'edit' });
          this.calendarDate = dayjs(selectInfo.start);
        },
        // dayRender: dayRenderInfo => {},
        dateClick: selectInfo => {
          this.clicked += 1;
          if (this.clicked < 2) return;

          let todo = {
            todo_group_id: this.selectedGroupId,
            starts_at: dayjs(selectInfo.date).hour(6),
            ends_at: dayjs(selectInfo.date).hour(22)
          };

          this.openTodo({ todo: todo, event: selectInfo.jsEvent, state: 'edit' });
          this.calendarDate = dayjs(selectInfo.start);
        },
        eventClick: eventClickInfo => {
          const todo = {
            id: eventClickInfo.event.id,
            borderColor: eventClickInfo.event.borderColor,
            ...eventClickInfo.event.extendedProps
          };
          this.eventCurrent = eventClickInfo;
          this.openTodo({ todo: todo, event: eventClickInfo.jsEvent, state: 'show' });
          this.tmpTodo = angular.copy(this.todo);
        },
        eventDragStart: info => {
          this.currentEvent = info;
        },
        eventDrop: async eventDropInfo => {
          try {
            const duration = dayjs(eventDropInfo.event.extendedProps.ends_at).diff(dayjs(eventDropInfo.event.extendedProps.starts_at));
            const todo_params = {
              starts_at: dayjs(eventDropInfo.event.start).toISOString(true),
              ends_at: dayjs(eventDropInfo.event.start)
                .add(duration)
                .toISOString(true)
            };

            this.startDate = todo_params.starts_at;
            this.endDate = todo_params.ends_at;

            const todo = await Todo.update(parseInt(eventDropInfo.event.id), todo_params);

            Object.keys(todo).forEach(e => {
              eventDropInfo.event.setExtendedProp(e, todo[e]);
            });
            this.reDrawMiniCalendar(this.miniCalendar);

          } catch (e) {
            eventDropInfo.revert();
          }
        },
        eventResize: async eventResizeInfo => {
          const event = eventResizeInfo.event;
          if (event.start.getDay() !== event.end.getDay()) {
            eventResizeInfo.revert();
          }
          try {
            const todo_params = {
              starts_at: dayjs(eventResizeInfo.event.start).toISOString(true),
              ends_at: dayjs(eventResizeInfo.event.end).toISOString(true)
            };

            this.startDate = todo_params.starts_at;
            this.endDate = todo_params.ends_at;

            await Todo.update(eventResizeInfo.event.id, todo_params);
          } catch (e) {
            eventResizeInfo.revert();
          }
        },
        eventClassNames: (eventObject) => {
          let classNames = [];

          if (dayjs(eventObject.event.end).diff(eventObject.event.start, 'minute') < 60 && this.$stateParams.defaultView != 'month') {
            classNames.push('p-0');
          } else {
            classNames.push('p-1');
          }

          if (eventObject.event.extendedProps.status == 'completed') {
            classNames.push('completed');
          }

          if (dayjs(eventObject.event.end).isBefore(dayjs())) {
            classNames.push('past');
          }

          return classNames;
        },
        viewDidMount: ({ view }) => {

          $stateParams.defaultView = stateParamsViewSelect(view.type);
          this.getBusyDates().then(() => {
            this.miniCalendar = this.initMiniCalendar();
          });
          $localStorage['calendar.defaultView'] = $stateParams.defaultView;
        },
        datesSet: info => {
          this.setMiniCalendar(info.view);
          this.setUrlParams();
        },
        views: {
          timeGrid: {
            allDayMaintainDuration: '00:00',
          }
        }
      });
      this.removeEvent = $async(this.removeEvent.bind(this));
    }

    $onInit() {

      if (this.$stateParams.todoId) {
        this.Todo.getById(this.$stateParams.todoId).then(todo => {
          const event = { clientY: $(window).height() / 2, clientX: $(window).width() / 3 };
          this.openTodo({ todo: todo, event: event, state: 'show' });
        }).catch(() => {
          this.$stateParams.todoId = null;
          this.setUrlParams();
        });
      }

      this.registerHotKeys();
      this.calendar.render();
    }

    registerHotKeys() {
      window.addEventListener('keydown', event => {
        if (this.infoPopOver) return;
        this.calendarViewHotKeys.forEach( el => {
          if (event.key == el[1].toLocaleLowerCase()) {
            this.changeCalendarViewState(el[0]);
          }
        });
      });
    }

    isDefault() {
      return this.mode != 'dashboard';
    }

    setUrlParams() {
      const cDates = this.getCurrentDates();
      this.$state.transitionTo(
        this.$state.$current,
        {
          defaultView: this.$stateParams.defaultView,
          group: this.selectedGroupId,
          date: cDates.starts_at,
          todoId: this.$stateParams.todoId,
          copy: this.$stateParams.copy
        },
        { notify: false }
      );
    }

    selectTodoGroup(groupId) {
      this.selectedGroupId = groupId;
      this.setUrlParams();
      this.calendar.unselect();
      this.calendar.refetchEvents();
    }

    changeCalendarViewState(view) {
      this.calendar.changeView(this.calendaViewStates[view]);
      this.$stateParams.defaultView = view;
      this.localStorage['calendar.defaultView'] = view;
    }

    calendarTitleDate() {
      let format = 'YYYY MMMM';
      if (this.$stateParams.defaultView == 'day') {
        format = 'YYYY MMMM DD';
      }
      return this.startDate.format(format);
    }

    createNew(jsEvent, todo) {
      if (todo) {
        this.todo = todo;
      } else {
        const now = dayjs();
        this.todo = {
          starts_at: dayjs(this.selectedDate).add(now.startOf('hour').get('hour'), 'hour')
        };
      }
      this.openTodo({ todo: todo, event: jsEvent, state: 'edit' });
    }

    goToday() {
      this.calendar.gotoDate(this.dateToday);
    }

    navigate(direction) {
      if (direction == 'next') {
        this.calendar.next();
      } else {
        this.calendar.prev();
      }
    }

    setMiniCalendar(viewInfo) {
      if (this.miniCalendar) {
        this.getBusyDates(viewInfo.currentStart);
        this.miniCalendar.setDate(viewInfo.currentStart);
      }
    }

    getCurrentDates() {
      this.startDate = dayjs(this.calendar.currentData.currentDate)
        .format('YYYY-MM-DD');
      this.endDate = dayjs(this.calendar.currentData.currentDate)
        .endOf(this.$stateParams.defaultView)
        .format('YYYY-MM-DD');
      return {
        starts_at: this.startDate,
        ends_at: this.endDate
      };
    }

    popperUpdatePosition() {
      if (this.infoPopOver) this.infoPopOver.update();
    }

    async removeEvent() {
      try {
        await this.Todo.moveToTrash(this.eventCurrent.event.id);
        this.eventCurrent.event.remove();
        this.reDrawMiniCalendar(this.miniCalendar);
        this.close();
      } catch (e) {
        console.error(e);
      }
    }

    alertSuccessUpdate() {
      this.AlertBox.addMessage('alert.updated.todo_calendar', { type: 'success' });
    }

    addEvent({ todo }) {

      this.calendar.unselect();

      const event = this.calendar.addEvent({
        start: todo.starts_at,
        end: todo.ends_at,
        title: todo.group_name,
        ...todo
      });
      this.calendar.refetchEvents();
      event.remove();
      this.close();
    }

    updateEvent({ todo }) {
      this.todo = todo;
      this.todoEdit = false;
      this.eventCurrent.event.setStart(todo.starts_at);
      this.eventCurrent.event.setEnd(todo.ends_at);
      this.eventCurrent.event.setProp('title', todo.group_name);
      this.eventCurrent.event.setProp('borderColor', todo.borderColor);
      this.eventCurrent.event.setProp('backgroundColor', todo.borderColor);

      Object.keys(todo).forEach(e => {
        this.eventCurrent.event.setExtendedProp(e, todo[e]);
      });

    }

    updateCompletionState() {
      this.eventCurrent.el.classList.toggle('completed');
    }

    cancelEventEdit() {}

    async close(isOverlay = false) {
      if (isOverlay && this.$stateParams.todoId) {
        return;
      } else if (!isOverlay && this.$stateParams.todoId) {
        this.$stateParams.todoId = null;
        this.setUrlParams();
      }

      const result = this.$stateParams.formDirty ? await this.AlertBox.confirm('confirmation_messages.close', { type: 'warning' }) : null;
      if (!result || (result && !result.dismiss)) {
        this.infoPopOver.destroy();
        this.infoPopOver = null;
        this.todoEdit = false;
        this.todo = null;
        if (this.eventScope) this.eventScope.$destroy();
        if (this.rootScope) this.rootScope.$destroy();
        if (this.showScope) this.showScope.$destroy();
        if (this.editScope) this.editScope.$destroy();
        this.$element.find('#popper').empty();
      }
    }

    $onDestroy() {
      this.calendar.destroy();
      if (this.newScope) this.newScope.$destroy();
      if (this.newScopeSecond) this.newScopeSecond.$destroy();
    }
  }
};
