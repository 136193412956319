class Dashboard {

  constructor(Http) {
    'ngInject';
    this.Http = Http;
  }

  getProjects() {
    return this.Http.get('/dashboards/projects');
  }

  getProject(id) {
    return this.Http.get(`/dashboards/projects/${id}`);
  }

}
export default Dashboard;