import template from './button-danger.pug';

export const ButtonDangerComponent = {
  template,
  bindings: {
    title: '@?',
    onClick: '&?',
    loading: '<?'
  },
  transclude : true,
  controller: class ButtonDangerComponent {
    constructor() {
      'ngInject';
    }
    $onInit() {
      
    }
  
  }
};