import template from './owner-form.pug';

export const OwnerFormComponent = {
  template,
  bindings: {
    object: '<',
    onSave: '&',
  },
  controller: class OwnerFormComponent {
    constructor(User, Assignment, $async, $log) {
      'ngInject';
      this.User = User;
      this.$log = $log;
      this.Assignment = Assignment;
      this.loadUsers = $async(this.loadUsers.bind(this));
    }

    $onChanges(obj) {
      if (obj.object) {
        this.setUser();
      }
    }

    $onInit() {
      this.setUser();
      this.loadUsers();
    }

    setUser() {
      if (this.object && this.object.user && this.object.user.id) {
        this.userId = this.object.user.id;
      }
    }

    setNewUser(user) {
      this.userId = user.id;
    }

    async openModal() {
      try {
        const { $event } = await this.Assignment.selectModal({ type: 'User', multiple: false, modalSize: 'md' });
        this.setNewUser($event);
        this.update();
      } catch (e) {
        this.full_user = this.user;
        this.$log.error(e);
      }
    }

    async loadUsers() {
      try {
        this.users = [];
        this.users = await this.User.getList();
      } catch (e) {
        this.$log.error(e);
      } finally {
        if ( this.users &&  this.users.length > 0 && !this.userId) {
          this.userId = this.users[0];
        }
      }
    }

    update() {
      this.onSave( {
        $event: {
          user_id: this.userId,
        }
      } );
    }

  }
};
