import template from './workspace-form.pug';

export const WorkspaceFormComponent = {
  template,
  bindings: {
    workspace: '<'
  },
  controller: class WorkspaceFormComponent {
    constructor($state, $log, $async, Workspace, AlertBox, Upload) {
      'ngInject';
      this.Upload = Upload;
      this.AlertBox = AlertBox;
      this.$state = $state;
      this.$log = $log;
      this.Workspace = Workspace;
      this.save = $async(this.save.bind(this));
      this.uploadLogo = $async(this.uploadLogo.bind(this));
      this.removeLogo = $async(this.removeLogo.bind(this));
      this.loader = false;
    }

    $onInit() {}

    async save() {
      try {
        if (this.workspace && this.workspace.id) {
          this.tempWorkspace = await this.Workspace.updateGroup({
            title: this.workspace.title,
            color: this.workspace.color,
            id: this.workspace.id
          });
          this.AlertBox.addMessage('alert.updated', { type: 'success' });
        }
      } catch (e) {
        this.$log.error(e);
      }

    }

    async uploadLogo() {
      try {
        this.loader = true;
        const response = await this.Upload.upload({
          url: window.apiHost + '/workspaces/' + this.workspace.id + '/logo',
          data: { logo: this.logo }
        }).finally(() => {
          this.loader = false;
        });
        this.workspace.logo_url = response.data;
      } catch (e) {
        this.$log.error(e);
      }
    }

    async removeLogo() {
      try {
        this.loader = true;
        await this.Workspace.removeLogo(this.workspace.id);
        this.workspace.logo_url = null;
        this.loader = false;
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
