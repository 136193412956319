import template from './custom-field-checkbox-form.pug';

export const CustomFieldCheckboxFormComponent = {
  template,
  bindings: {
    field: '<',
    group: '<',
    onSave: '&',
    onBack: '&?',
    onClose: '&?',
    loading: '<?'
  },
  controller: class CustomFieldCheckboxFormComponent {
    constructor($translate) {
      'ngInject';
      this.$translate = $translate;

      this.selectedTab = 'field';

      this.record = {
      };
      this.selectConfig = {
        maxItems: 1,
        valueField: 'value',
        labelField: 'name',
        sortField: 'name',
        plugins: []
      };
      this.radioOptions = [
        { value: true, title: 'custom_fields.a_yes' },
        { value: false, title: 'custom_fields.a_no' }
      ];
    }

    $onInit() {
      if (this.field && this.field.id) {
        this.record = this.field;
      } else {
        this.record = { ...this.record, ...this.field };
        this.record.default_value = { single: false };
        this.record.title = this.$translate.instant(this.field.name);
      }

      if (this.group.generic_module == 'estates') {
        this.tabs = ['field', 'estate_type', 'settings', 'preview'];
      } else {
        this.tabs = ['field','settings', 'preview'];
      }

    }

    back() {
      this.onBack({ $event: false });
    }

    close() {
      this.onClose({ $event: null });
    }

    save() {
      this.loading = true;
      this.record.default_value.single = !!parseInt(this.record.default_value.single);
      this.onSave({ $event: { field: this.record } });
    }

  }
};
