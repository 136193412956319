import template from './body.pug';

export const BodyComponent = {
  template,
  bindings: {},
  transclude: true,
  controller: class BodyComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}
  }
};
