import template from './inquiry-body.pug';

export const InquiryBodyComponent = {
  template,
  bindings: {
    filter: '<',
    onFilterChange: '&',
    records: '<',
    onCheck: '&',
    checkedIds: '<',
    gridOptions: '<?',
    isModal: '<?'
  },
  controller: class InquiryBodyComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}

    applyFilter({ filter, searchId }) {
      this.onFilterChange({
        $event: { filter, searchId }
      });
    }

    checkIds({ checkedIds }) {
      this.onCheck({ $event: { checkedIds } });
    }
  }
};
