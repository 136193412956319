import template from './resend-invite.pug';

export const ResendUserInviteModalComponent = {
  template,
  bindings: {
    resolve: '<',
    modalInstance: '<',
  },
  controller: class ResendUserInviteModalComponent {
    constructor(User, $log, $async) {
      'ngInject';
      this.User = User;
      this.$log = $log;
      this.send = $async(this.send.bind(this));
    }

    $onInit() {
      this.user = angular.copy(this.resolve.user);
    }

    async send() {
      try {
        this.loader = true;
        await this.User.resendInvite(this.user.id, this.user.email);
        this.modalInstance.close(true);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    close() {
      this.modalInstance.close(void(0));
    }
  }
};
