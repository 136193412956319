import template from './settings-time-panel.pug';
import _snakeCase from 'lodash/snakeCase';
import { plural } from 'pluralize';


export const SettingsTimePanelComponent = {
  template,
  bindings: {
    moduleName: '<'
  },
  controller: class SettingsTimePanelComponent {
    constructor(TableEdit, Chronology, $async, $log, $translate) {
      'ngInject';

      this.TableEdit = TableEdit;
      this.Chronology = Chronology;
      this.$log = $log;
      this.$translate = $translate;

      this.getTimePanel = $async(this.getTimePanel.bind(this));

      this.sortableOptions = {
        handle: '.sortableListHandle',
        tolerance: 'pointer',
        stop: () => {
          this.updateTimePanel();
        },
        axis: 'y'
      };
    }

    $onInit() {
      this.getTimePanel();
    }

    translateType(type) {
      return this.$translate.instant(`settings.time_panel.${_snakeCase(type)}`);
    }

    async getTimePanel() {
      try {
        const result = await this.TableEdit.getAccountSettings(this.moduleName, 'time_panel');

        if (this.moduleName == 'estate') {
          this.timePanelSettings = result.value.filter(item => item.name !== 'Offer');
        } else {
          this.timePanelSettings = result.value;
        }

      } catch (error) {
        this.$log.error(error);
      }
    }

    toggleTimePanel(setting) {
      setting.enabled = !setting.enabled;

      this.updateTimePanel();
    }

    async updateTimePanel() {
      try {
        let arrayItems = [...this.timePanelSettings];

        if (this.moduleName == 'estate') {
          let item = arrayItems.find(item => item.name == 'MultipleProposal');
          if (item.enabled) {
            arrayItems.push({
              name: 'Offer',
              enabled: true,
            });
          } else {
            arrayItems.push({
              name: 'Offer',
              enabled: false,
            });
          }
        }

        await this.TableEdit.updateAccountSettings({ module: this.moduleName, key: 'time_panel', value: arrayItems });
      } catch (error) {
        this.$log.error(error);
      }
    }
  }
};
