import template from './notification-settings-about.pug';

export const NotificationSettingsAbout = {
  template,
  bindings: {},
  controller: class NotificationSettingsAbout {
    constructor($localStorage) {
      'ngInject';
      this.$localStorage = $localStorage;

      if (typeof this.$localStorage['notificationSettings'] === 'boolean') {
        this.isAboutVisible = this.$localStorage.notificationSettings;
      } else {
        this.isAboutVisible = true;
      }
    }

    $onInit() {}

  }
};
