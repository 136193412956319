import template from './menu-workspace-changer.pug';
import { createPopper } from '@popperjs/core';

export const MenuWorkspaceChanger = {
  template,
  bindings: {
  },
  controller: class MenuWorkspaceChanger {
    constructor($element, $state, $async, $log, $sessionStorage, Workspace, Settings) {
      'ngInject';

      this.$element = $element;
      this.$state = $state;
      this.$log = $log;
      this.$sessionStorage = $sessionStorage;
      this.Workspace = Workspace;
      this.Settings = Settings;

      this.popperShow = false;
      this.currentWorkspace = '';
      this.workspaces = [];

      this.getWorkspaces = $async(this.getWorkspaces.bind(this));
      this.changeWorkspace = $async(this.changeWorkspace.bind(this));
      this.newGroup = $async(this.newGroup.bind(this));
    }

    $onInit() {
      this.getWorkspaces();
    }

    async openPopper(event) {
      this.popperShow = !this.popperShow;

      if (this.popperShow) {
        this.getWorkspaces();
        const popperEl = this.$element.find('#menu-workspace-dropdown')[0];
        const options = {
          placement: 'bottom-end',
          offset: [0, 0],
          strategy: 'fixed'
        };

        this.popper = createPopper(event.currentTarget, popperEl, options);
      } else {
        this.closePopper();
      }
    }

    closePopper() {
      this.popperShow = false;
      this.popper.destroy();
    }

    async changeWorkspace(workspace) {
      try {
        const resp = await this.Settings.update({ module: 'workspace', key: 'default', value: workspace.id });
        if (resp) {
          this.$sessionStorage.workspace = workspace.id;
          this.$state.reload();
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    getInitials(value) {
      const initials = value ? value.toInitials() : '';
      return initials;
    }

    imageStyle(workspace) {
      if (!workspace) return;

      return { 'background-color': workspace.color };
    }

    async getWorkspaces() {
      try {
        this.workspaces = await this.Workspace.getGroups();
        this.currentWorkspace = angular.copy(this.workspaces.find(item => item.id == this.Workspace.currentWorkspaceId()));
        if (this.currentWorkspace == null) {
          this.currentWorkspace = angular.copy(this.workspaces[0]);
          this.$sessionStorage.workspace = this.currentWorkspace.id;
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async newGroup() {
      try {
        const group = await this.Workspace.groupModal();
        if (group) {
          this.changeWorkspace(group);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    goToSettings() {
      this.$state.go('main.settings.workspaces');
    }

  }
};
