import template from './finance-payment-list.pug';

export const FinancePaymentListComponent = {
  template,
  bindings: {
    finance: '<',
    afterUpdate: '&?',
  },
  controller: class FinancePaymentListComponent {
    constructor(Finance, $async, $log, AlertBox) {
      'ngInject';

      this.currency = window.CURRENCY;
      this.Finance = Finance;
      this.$log = $log;
      this.AlertBox = AlertBox;
      this.getPayments = $async(this.getPayments.bind(this));
      this.addPayment = $async(this.addPayment.bind(this));
      this.payments = [];
    }

    $onChanges({ finance }) {
      if (finance && finance.currentValue !== finance.previousValue) {
        this.getPayments();
      }
    }

    triggerAfterPaymentUpdate() {
      this.getPayments();
      if (this.afterUpdate) {
        this.afterUpdate();
      }
    }

    $onInit() {
      this.getPayments();
    }

    async getPayments() {
      try {
        const { data, meta }= await this.Finance.getPayments(this.finance.id);
        this.payments = data.map((payment) => { return payment.attributes; });
        this.stats = meta;
      } catch (e) {
        this.$log.error(e);
      }
    }

    async addPayment() {
      try {

        const paymentSum = this.payments.reduce((sum, payment) => sum + payment.sum, 0);
        const balance = this.finance.sum - paymentSum;

        const payment = {
          sum: (balance > 0) ? balance : 0,
          status: 'paid',
          date_at: dayjs().format('YYYY-MM-DD'),
          comment: '',
        };

        this.payments.unshift(payment);

      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
