import template from './estate-type.pug';

export const EstateTypeModalComponent = {
  template,
  bindings: {
    estateType: '<',
    resolve: '<',
    modalInstance: '<',
  },
  controller: class EstateTypeModalComponent {
    constructor($log, $async, Estate, Account, AlertBox) {
      'ngInject';
      this.$log = $log;
      this.Estate = Estate;
      this.Account = Account;
      this.AlertBox = AlertBox;
      this.save = $async(this.save.bind(this));
      this.getAreaUnits = $async(this.getAreaUnits.bind(this));
      this.accountAreaUnits = $async(this.accountAreaUnits.bind(this));

      this.toggleAreaUnit = $async(this.toggleAreaUnit.bind(this));
    }


    $onInit() {

      this.accountAreaUnits().then(() => {

        if (this.resolve) {
          this.estateTypeGroup = this.resolve.estateTypeGroup;
        }

        if (this.resolve.estateType == null) {
          this.estateType = {
            name: '',
            active: true,
            area_unit: this.areaUnits[0],
            estate_type_group_id: this.resolve.estateTypeGroup.id };
          this.areaUnitsIds = this.areaUnits.map(x => x.id);
        } else {
          this.estateType = this.resolve.estateType;
          if (this.estateType) {
            this.getAreaUnits();
          }
        }
      });

    }

    async accountAreaUnits() {
      try {
        const resp = await this.Account.getAreaUnits();
        if (resp) {
          this.areaUnits = resp;
        }
      } catch (error) {
        this.$log.error(error);
      }
    }

    async toggleAreaUnit(areaUnit) {
      if (this.areaUnitsIds == null) this.areaUnitsIds = [];

      // If we are creating a new estate type, we don't need to make a request to the API
      if (this.estateType.id == null) {
        if (this.areaUnitsIds.includes(areaUnit.id)) {
          this.areaUnitsIds = this.areaUnitsIds.filter(x => x !== areaUnit.id);
        } else {
          this.areaUnitsIds.push(areaUnit.id);
        }
      }

      try {
        if (this.areaUnitsIds.includes(areaUnit.id)) {
          await this.Estate.removeAreaUnit(this.estateType.id, areaUnit.id);
        } else {
          await this.Estate.addAreaUnit(this.estateType.id, areaUnit.id);
        }
      } catch (error) {
        this.$log.error(error);
      }
    }

    async getAreaUnits() {
      try {
        const resp = await this.Estate.getAreaUnits(this.estateType.id);
        if (resp) {
          this.areaUnitsIds = resp.map(x => x.id);
        }
      } catch (error) {
        this.$log.error(error);
      }
    }

    close(resp = null) {
      this.modalInstance.close(resp || void(0));
    }

    async save(estateType) {
      let hasID = estateType.hasOwnProperty('id');
      let resp = null;
      let alertType = hasID ? 'updated' : 'created';
      estateType.estate_type_group_id = this.estateTypeGroup.id;
      this.loading = true;

      try {
        if (hasID) {
          resp = await this.Estate.updateEstateTypes(estateType.id, estateType);
        } else {
          resp = await this.Estate.createEstateTypes(estateType);

          if ( this.areaUnitsIds != null && this.areaUnitsIds.length > 0 && resp.id != null) {
            this.areaUnitsIds.forEach(async (areaUnitId) => {
              await this.Estate.addAreaUnit(resp.id, areaUnitId);
            });

            resp.area_units = this.areaUnits.filter(x => this.areaUnitsIds.includes(x.id));
          }
        }
        this.AlertBox.addMessage(`alert.${alertType}`, { type: 'success' });
        this.close(resp);
      } catch (e) {
        this.AlertBox.addMessage('alert.error', { type: 'error' });
        this.$log.error(e);
      }
    }
  }
};
