import template from './todo-sidebar-item.pug';

export const TodoSidebarItemComponent = {
  template,
  bindings: {
    todo: '<?'
  },
  controller: class TodoSidebarItemComponent {
    constructor(Todo) {
      'ngInject';
      this.Todo = Todo;
      this.statusStates = this.Todo.statuses();
      window.t2 = performance.now();
    }

    $onInit() {
      this.color = this.statusStates.find(t => t.status == this.todo.status).color;
    }
  }
};
