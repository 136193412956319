import template from './custom-view-selector.pug';

export const CustomViewSelectorComponent = {
  template,
  bindings: {
    module: '@',
    selectedSearchId: '<?',
    defaultTitle: '@?',
    onSelect: '&',
    isModal: '<?'
  },
  transclude : true,
  controller: class CustomViewSelectorComponent {
    constructor(Search, $async, $log, $stateParams, $element) {
      'ngInject';
      this.Search = Search;
      this.$async = $async;
      this.$stateParams = $stateParams;
      this.$element = $element;
      this.$log = $log;
      this.getSearches = $async(this.getSearches.bind(this));
      this.adjustSearches = $async(this.adjustSearches.bind(this));
      this.onClick = $async(this.onClick.bind(this));
      this.icon = 'settings';
    }

    $onChanges() {
      this.isOpen = false;
    }

    $onInit() {
      this.isOpen = false;
      this.searches = [];
      this.getSearches(this.module);
      if (!this.isModal) {
        this.selectedSearchId = this.Search.returnLastSearchId(this.module);
        this.$stateParams.searchId = this.selectedSearchId;
      } else {
        this.selectedSearchId = this.selectedSearchId || -1;
      }
      this.title = '    ';
    }

    async onClick(searchId) {
      try {
        this.selectedSearchId = searchId;
        this.inlineSearch = '';
        this.isOpen = false;
        await this.Search.saveLastSearchId(searchId, this.module);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.setTitle();
        document.body.click();
        this.onSelect({
          $event: { searchId },
        });
      }
    }

    async getSearches() {
      try {
        if (!this.module) return;
        this.loader = true;
        this.searches = await this.Search.customSearch(this.module);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.setTitle();
        this.loader = false;
      }
    }

    async adjustSearches() {
      try {
        this.search = await this.Search.adjustSearches(this.module, this.searches);
        this.searches = await this.Search.customSearch(this.module);
      } catch (e) {
        this.$log.error(e);
      } finally {
        if (this.search) this.onClick(this.search.id);
      }
    }

    setTitle() {
      if (parseInt(this.selectedSearchId) > 0 && this.searches.length > 0) {
        let active = this.selectedSearchId;
        var activeSearch = this.searches.find(function(search) {
          return search.id == active;
        });
        this.title = activeSearch.title;
      } else {
        this.title = this.defaultTitle;
      }
    }
  }
};
