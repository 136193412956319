import template from './role-new.pug';

export const RoleNewComponent = {
  template,
  bindings:{
    resolve: '<',
    modalInstance: '<',
  },
  controller: class RoleNewComponent {
    constructor($translate, $async, $log, AlertBox, Role) {
      'ngInject';
      this.$translate = $translate;
      this.$log = $log;
      this.AlertBox = AlertBox;
      this.Role = Role;
      this.save = $async(this.save.bind(this));
    }

    $onInit() {
    }

    async save({ role }) {
      try {
        this.loader = true;
        let attributes = this.Role.serialize(role);
        this.role = await this.Role.create(attributes);
        this.AlertBox.addMessage('alert.create.role',{ type: 'success' });
        this.modalInstance.close(this.role);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    close() {
      this.modalInstance.close(void(0));
    }
  }
};
