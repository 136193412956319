export default class ChronologyList {
  constructor() {
    'ngInject';
    this.actionController = null;
  }

  addController(ctrl) {
    this.actionController = ctrl;
  }

  removeController() {
    this.actionController = null;
  }

  addItem(item) {
    if (this.actionController) {
      this.actionController.addToChronology(item);
    }
  }

  reloadList() {
    this.actionController.loadMore(true);
  }
}
