import template from './contact-import-progress.pug';

export const ContactImportProgressComponent = {
  template,
  bindings:{
    resolve: '<',
    modalInstance: '<',
  },
  controller: class ContactImportProgressComponent {
    constructor($state, $async, $log, Contact) {
      'ngInject';
      this.Contact = Contact;
      this.$log = $log;
      this.$state = $state;
      this.saveChunk = $async(this.saveChunk.bind(this));
      this.progressPercentValue  = 1;
    }

    $onInit() {
      if (this.resolve) {
        this.userId = this.resolve.userId;
        this.recordStatus = this.resolve.recordStatus;
        this.columnSettings = this.resolve.columnSettings;
        this.rowChuncked = this.resolve.rowChuncked;
        this.startImport();
      }
    }

    backToContacts() {
      this.close();
      this.$state.go('main.contacts.list', { page: 1 });
    }

    backToImport() {
      this.close();
      this.$state.go('main.contacts.import');
    }

    close() {
      this.modalInstance.close(void(0));
    }

    async startImport() {
      let totalArraySize = this.rowChuncked.length;
      while (this.rowChuncked.length) {
        await this.saveChunk(this.rowChuncked.shift());
        this.progressPercentValue = Math.round(
          ((totalArraySize - this.rowChuncked.length) / totalArraySize) * 100
        );
      }
    }

    async saveChunk(chunk) {
      try {
        await this.Contact.importContacts(
          this.userId,
          this.recordStatus,
          this.columnSettings,
          chunk
        );
      } catch (e) {
        this.$log.error(e);
      }

    }


  }
};
