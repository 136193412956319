import template from './not-found.pug';

export const NotFoundComponent = {
  template,
  bindings: {
    searchId: '<?'
  },
  controller: class NotFoundComponent {
    constructor($stateParams, $state, $window) {
      'ngInject';
      this.$state = $state;
      this.$stateParams = $stateParams;
      this.$window = $window;
    }

    $onInit() {
    }

    back() {
      if (this.$stateParams.backTo) {
        this.$state.go(this.$stateParams.backTo, { page: 1, searchId: this.searchId });
      } else {
        this.$window.history.back();
      }

    }
  }
};
