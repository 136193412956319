import template from './user-settings-google-token.pug';

export const UserSettingsGoogleTokenComponent = {
  template,
  bindings: {
    user: '<'
  },
  controller: class UserSettingsGoogleTokenComponent {
    constructor($stateParams, User, $window, $log) {
      'ngInject';
      this.User = User;
      this.$log = $log;
      this.$stateParams = $stateParams;
      this.$window = $window;
    }

    $onInit() {
      if (parseInt(this.$stateParams.google_token_id)) {
        this.attachProfile();
      }
    }

    async attachProfile() {
      try {
        await this.User.attachToGoogleProfile(this.User.currentId(), this.$stateParams.google_token_id);
        this.$window.close();
      } catch (e) {
        this.$log.error(e);
      }
    }

  }
};
