import template from './group-position-modal.pug';

export const GroupPositionModalComponent = {
  template,
  bindings:{
    resolve: '<',
    modalInstance: '<',
  },
  controller: class GroupPositionModalComponent {
    constructor($async, $log, CustomField, AlertBox) {
      'ngInject';
      this.CustomField = CustomField;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.updatePosition = $async(this.updatePosition.bind(this));
      this.sortableOptions = {
        handle: '.sortableListHandle',
        tolerance: 'pointer',
        stop: () => {
          this.groups.forEach((value, index) => {
            value.position = index + 1;
          });
        },
        update: (e, ui) => {
          const position = ui.item.sortable.dropindex + 1;
          const id = ui.item.sortable.model.id;
          this.updatePosition(id, position);
        },
        axis: 'y'
      };
    }

    $onInit() {
      this.groups = this.resolve.groups;
    }

    async updatePosition(id, position) {
      try {
        this.loader = true;
        this.AlertBox.addMessage('alert.updated', { type: 'success' });
        await this.CustomField.updateGroupPosition(id, position);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    close() {
      this.modalInstance.close();
    }

  }
};
