import { DuplicatesModalComponent } from './modal/duplicates-modal.component';
import { EmailDuplicatesCardComponent } from './card/email/email-duplicates-card.component';
import { PhoneDuplicatesCardComponent } from './card/phone/phone-duplicates-card.component';

const DuplicatesModule = angular.module('topbroker.shared.dublicates', [])
  .component('duplicatesModal', DuplicatesModalComponent)
  .component('emailDuplicatesCard', EmailDuplicatesCardComponent)
  .component('phoneDuplicatesCard',PhoneDuplicatesCardComponent)

  .name;

export default DuplicatesModule;
