import template from './body.pug';

export const TodoTableBodyComponent = {
  template,
  bindings: {
    filter: '<',
    records: '<',
    checkedIds: '<',
    onCheck: '&',
    gridOptions: '<?',
    loading: '<?',
    onFilterChange: '&',
    isModal: '<?',
    onCheckToggle: '&?'
  },
  controller: class TodoTableBodyComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}

    applyFilter({ filter, searchId }) {
      this.onFilterChange({
        $event: { filter, searchId }
      });
    }

    checkToggle($event) {
      if (this.onCheckToggle) {
        this.onCheckToggle( { $event: $event } );
      }
    }

    checkIds({ checkedIds }) {
      this.onCheck({
        $event: { checkedIds }
      });
    }
  }
};
