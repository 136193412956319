import template from './chronology-multiple-proposal-table.pug';

export const ChronologyMultipleProposalTableComponent = {
  template,
  bindings: {
    chronology: '<',
  },
  controller: class ChronologyMultipleProposalTableComponent {
    constructor(Proposal, $async) {
      'ngInject';
      this.Proposal = Proposal;
      this.recordPreview = $async(this.recordPreview.bind(this));
    }

    $onInit() {}

    async recordPreview(selectedRecord) {
      try {
        await this.Proposal.previewModal(selectedRecord);
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
