import template from './notification.pug';
import { plural } from 'pluralize';
import dayjs from 'dayjs';

export const NotificationComponent = {
  template,
  bindings: {},
  transclude : true,
  controller: class NotificationComponent {
    constructor(Notification, Hotkeys, webNotification, $log, $localStorage, $async, $translate, $state, ActionCableChannel, $sessionStorage) {
      'ngInject';
      // services
      this.Notification = Notification;
      this.Hotkeys = Hotkeys;
      this.$log = $log;
      this.$state = $state;
      this.$translate = $translate;
      this.$localStorage = $localStorage;
      this.$sessionStorage = $sessionStorage;
      this.notifications = [];
      this.webNotification = webNotification;
      this.isVisible = false;
      this.isPreview = {
        open: false,
        goToRecord: true
      };
      this.previewNotification = {};
      this.page = 1;
      this.totalPages = null;
      this.unReadCount = 0;
      this.notificationChannel = new ActionCableChannel('NotificationsChannel');
      this.loadNotifications = $async(this.loadNotifications.bind(this));
      this.markAllAsRead = $async(this.markAllAsRead.bind(this));
      this.loadMore = $async(this.loadMore.bind(this));
    }

    $onInit() {
      this.notificationChannel.subscribe(this.notificationChannelHandler);

      const esc_hotkey = this.Hotkeys.createHotkey({
        key: ['escape'],
        callback: () => this.close()
      });
      this.Hotkeys.registerHotkey(esc_hotkey);

      this.loadNotifications();
    }

    $onDestroy() {
      this.notificationChannel.unsubscribe();
    }

    notificationChannelHandler = (resp) => {
      switch (resp.type) {
        case 'settings_update':
          this.$localStorage.settings[resp.data.module][resp.data.key] = resp.data.value;
          break;
        case 'estate_types_update':
          this.$localStorage['estate_types'] = resp.data;
          break;
        case 'workspace_created':
          this.$localStorage['workspaces_count'] = parseInt(this.$localStorage['workspaces_count']) + 1;
          break;
        case 'workspace_destroyed':
          this.$localStorage['workspaces_count'] = parseInt(this.$localStorage['workspaces_count']) - 1;
          break;
        default:
          this.unReadCount += 1;
          this.notifications.unshift(resp);
          this.nativeNotify(resp);
          break;
      }
    };

    async loadNotifications() {
      try {
        this.notifications = await this.Notification.getList(this.page);
        if (this.notifications.length) {
          this.totalPages = this.notifications[0].total_pages;
          var count = this.notifications.filter(notification => !notification.readed_at);
          this.unReadCount = count.length;
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async loadMore() {
      if (this.page >= this.totalPages) return false;

      try {
        this.page = this.page + 1;
        const nextPageNotifications = await this.Notification.getList(this.page);
        this.notifications = this.notifications.concat(nextPageNotifications);
      } catch (e) {
        this.$log.error(e);
      }
    }

    nativeNotify(data) {
      if (window.isMobile()) return;

      let notification = data.data.title;
      // let message = data.data.event === 'field_change' ? this.changedFields(data) : '';

      this.webNotification.showNotification(notification, {
        serviceWorkerRegistration: window.serviceWorkerRegistration,
        // body: message,
        data: data,
        icon: '/images/icons/favicon-32x32.png',
        autoClose: 6000, //auto close the notification after 4 seconds (you can manually close it via hide function)
      }, function onShow(error, hide) {
        if (error) {
          // Notify user once per session if web notifications are disabled
          if (!this.$sessionStorage.notificationAlertShown) {
            window.alert('Unable to show notification: ' + error.message);
            this.$sessionStorage.notificationAlertShown = true
          }
        } else {
          console.info('Notification Shown.');
          setTimeout(function hideNotification() {
            console.info('Hiding notification....');
            hide(); //manually close the notification (you can skip this if you use the autoClose option)
          }, 7000);
        }
      });
    }

    open() {
      this.isVisible = true;
    }

    close() {
      this.isVisible = false;
    }

    async openPreview(notification, goToRecord = true) {
      this.previewNotification = angular.copy(notification);
      this.isPreview.open = true;
      this.isPreview.goToRecord = goToRecord;
    }

    closePreview() {
      this.isPreview.open = false;
    }

    goToRecord() {
      this.close();
      this.closePreview();
      const { state, params } = this.prepareRecordUrl();

      this.$state.go(state, params);
    }

    prepareRecordUrl() {
      const url = { state: '', params: {} };

      switch (this.previewNotification.component) {
        case 'todo':
          url.state = 'main.todos.calendar';
          url.params = {
            defaultView: this.$localStorage['calendar.defaultView'] ? this.$localStorage['calendar.defaultView'] : 'month',
            group: 0,
            date: dayjs(this.previewNotification.data.todo_date).format('YYYY-MM-DD'),
            todoId: this.previewNotification.record_id
          };
          break;
        default:
          url.state = `main.${plural(this.previewNotification.component)}.show`;
          url.params = { id: this.previewNotification.record_id };
          break;
      }

      return url;
    }

    async markAsRead(notification) {
      if (notification && notification.readed_at !== null) return false;

      try {
        await this.Notification.markAsRead(notification.id);
        notification.readed_at = dayjs().format();
        this.unReadCount = this.unReadCount - 1;
      } catch (e) {
        this.$log.error(e);
      }
    }

    async markAllAsRead() {
      if (this.unReadCount === 0) return false;

      try {
        await this.Notification.markAllAsRead();
        this.unReadCount = 0;

        this.notifications.forEach(item => {
          item.readed_at = dayjs().format();
        });
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
