import template from './statistic-user.pug';
import { orderBy } from 'lodash';
import { sumBy } from 'lodash';


export const StatisticUserComponent = {
  template,
  bindings: {
    filter: '<',
    onPageChange: '&',
    onFilterChange: '&'
  },

  controller: class StatisticUserComponent {
    constructor(Statistics, Workspace, CustomField, User, $log, $async, $state, $element, $translate) {
      'ngInject';
      this.$log = $log;
      this.Statistics = Statistics;
      this.CustomField = CustomField;
      this.Workspace = Workspace;
      this.User = User;
      this.$state = $state;
      this.$element = $element;

      this.getStats = $async(this.getStats.bind(this));
      this.getUser = $async(this.getUser.bind(this));
      this.getFilters = $async(this.getFilters.bind(this));
      this.gridOptions = this.Statistics.gridOptions();
      const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color');

      this.selectedUser = null;
      this.datasetOverride = [
        {
          backgroundColor: (context) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;
            return this.generateBarColor(ctx, chartArea, primaryColor, primaryColor);
          },
          barPercentage: 0.8,
        },
        {
          label: $translate.instant('general.overall_average'),
          borderColor: primaryColor,
          backgroundColor: primaryColor,
          borderDash: [3],
          borderWidth: 2,
          pointBackgroundColor: primaryColor,
          pointBorderColor: primaryColor,
          pointRadius: 2,
          type: 'line'
        }
      ];

      this.chartOptions = {
        maintainAspectRatio: false,
        scales: {
          y: { display: false },
          x: {
            grid: {
              display: false,
              drawBorder: false
            },
          }

        },
        plugins: {
          datalabels: {
            display: false,
          }
        }
      };

      this.custom_fields = {};
      this.activeTab = 'table';
      this.tableData = [];
      this.tableHeaders = [];
      this.sortBy = 'balance';
      this.sortOrder = 'desc';
    }

    $onInit() {
      this.minDate = this.$state.params.min_date;
      this.maxDate = this.$state.params.max_date;

      this.dateLinks = [
        { label: 'date.this_month', date: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('month').format('YYYY-MM-DD')] },
        { label: 'date.last_month', date: [dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')] },
        // { label: 'date.this_quarter', date: [dayjs().startOf('quarter').format('YYYY-MM-DD'), dayjs().endOf('quarter').format('YYYY-MM-DD')] },
        { label: 'date.this_year', date: [dayjs().startOf('year').format('YYYY-MM-DD'), dayjs().endOf('year').format('YYYY-MM-DD')] },
      ];

      this.minDatepickerConfig = {
        onChange: this.minDateSelected.bind(this),
        dateFormat: 'Y-m-d'
      };

      this.maxDatepickerConfig = {
        onChange: this.maxDateSelected.bind(this),
        dateFormat: 'Y-m-d'
      };
      this.getStats();
      this.getFilters();

      if (this.$state.params.user_id) {
        this.getUser(this.$state.params.user_id);
      }
    }

    setDate(dates) {
      this.minDate = dates[0];
      this.maxDate = dates[1];
      this.$state.go('.', { min_date: this.minDate, max_date: this.maxDate });
      this.getStats();
    }

    setTab(tab) {
      this.activeTab = tab;
    }

    getName(user) {
      if (user.name == undefined) {
        return user.email;
      }
      return user.name;
    }

    async getUser(id) {
      try {
        this.selectedUser = await this.User.find(id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getFilters() {
      try {
        this.filters = await this.CustomField.getSelectFieldsByModel('users');
      } catch (e) {
        this.$log.error(e);
      }
    }

    setFilter(filter, value) {
      this.custom_fields[filter.technical_name] = value.id;
      this.getStats();
    }

    calendarClicked() {
      this.$element.find('input.flatpickr-input').click();
    }

    generateBarColor(ctx, chartArea, topColor, bottomColor) {
      let primaryColor, secondaryColor;

      primaryColor = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
      primaryColor.addColorStop(0, topColor);
      primaryColor.addColorStop(1, bottomColor);

      secondaryColor = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
      secondaryColor.addColorStop(0, topColor.hexToRGBA(0.2));
      secondaryColor.addColorStop(1, bottomColor.hexToRGBA(0.2));

      return [
        secondaryColor,
        secondaryColor,
        secondaryColor,
        secondaryColor,
        primaryColor, // Last oen should be active one because it's selected month
      ];
    }

    clearSelectedUser() {
      this.$state.go('.', { user_id: null });
    }

    userSelected({ row }) {
      this.$state.go('.', { user_id: row.entity.id });
    }

    minDateSelected($event) {
      this.minDate = dayjs($event[0]).format('YYYY-MM-DD');
      this.$state.go('.', { min_date: this.minDate, max_date: this.maxDate });
      this.getStats();
    }

    maxDateSelected($event) {
      this.maxDate = dayjs($event[0]).format('YYYY-MM-DD');
      this.$state.go('.', { min_date: this.minDate, max_date: this.maxDate });
      this.getStats();
    }

    noDataCheck(barData, lineData) {
      return [...barData, ...lineData].reduce((a, b) => a + b, 0) == 0;
    }

    sortByFn(field) {
      this.sortBy = field;
      this.sortByField = this.tableHeaders.find(header => header.id == this.sortBy);
      this.tableData = orderBy(this.tableData, this.sortByField.name, this.sortOrder);
    }

    toggleSortOrder() {
      this.sortOrder = this.sortOrder == 'asc' ? 'desc' : 'asc';
    }

    totalCount(field) {
      return sumBy(this.tableData, (row) => { return parseInt(row[field.name]) });
    }

    isPositive(type, header, stat) {
      if (header.id.includes(type) && stat[header.name] > 0) return true;
      if (header.id.includes('balance') && (type == 'income' ? stat[header.name] > 0 : stat[header.name] < 0)) return true;
      return false;
    }


    async getStats() {
      try {
        this.loader = true;

        const params = {
          min_date: this.minDate,
          max_date: this.maxDate,
          workspace_id: this.Workspace.currentWorkspaceId(),
          custom_fields: this.custom_fields,
        };
        if (this.$state.params.user_id) params.user_id = this.$state.params.user_id;

        const resp = await this.Statistics.aggregate(params);
        const cardsData = {};

        for (const key in resp.card_data) {
          cardsData[key] = {
            labels: Object.keys(resp.card_data[key]).map(date => dayjs(date).format('MMM')),
            data: Object.values(resp.card_data[key]).reduce((result, value) => {
              result.barData.push(value[0]);
              result.lineData.push(value[1]);

              return result;
            }, { barData: [], lineData: [] })
          };
        }

        this.cardsData = cardsData;

        this.tableHeaders = resp.table_headers;
        this.tableData = resp.data;
        this.sortByFn(this.sortBy);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }
  }
};
