import template from './document-new.pug';

export const DocumentsNewModalComponent = {
  template,
  bindings:{
    resolve: '<',
    modalInstance: '<',
  },
  controller: class DocumentsNewModalComponent {
    constructor($async, $log, Document, ConnectedRecord,  AlertBox) {
      'ngInject';

      this.Document = Document;
      this.ConnectedRecord = ConnectedRecord;
      this.$log = $log;
      this.AlertBox = AlertBox;
      this.save = $async(this.save.bind(this));
    }

    $onInit() {}

    close() {
      this.modalInstance.close(void(0));
    }

    async save({ document }) {
      try {
        this.loader = true;
        const resp = await this.Document.create(document);
        const newConnectedRecords = angular.copy(document['new_connected_records']);

        if (resp && resp.data.id) {
          await this.ConnectedRecord.batchCreate(resp.data.id, newConnectedRecords);

          this.AlertBox.addMessage('alert.created', { type: 'success' });
          this.modalInstance.close(resp.data);
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

  }
};
