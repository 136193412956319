class Settings {
  constructor(Http) {
    'ngInject';

    this.Http = Http;
  }

  update(params, type = 'user') {
    return this.Http.put(`/settings/${type}/`, params);
  }

  getNotificationSettings(module = '', type = 'user', params = {}) {
    return this.Http.get(`/settings/${type}/${module}/notifications`, params);
  }

  updateGoogleSettings(user_id, params) {
    return this.Http.put('/users/' + user_id + '/google-token', params);
  }

  getGoogleSettings(user_id) {
    return this.Http.get('/users/' + user_id + '/google-token');
  }

}

export default Settings;
