import template from './viewings.pug';

export const ViewingsComponent = {
  template,
  bindings: {},
  controller: class ViewingsComponent {
    constructor() {
    }

    $onInit() {}
  }
};
