import Swal from 'sweetalert2';
import _merge from 'lodash/merge';

export default class AlertBox {
  constructor($translate) {
    'ngInject';

    this.$translate = $translate;

    this.infoTypes = {
      success: 'success',
      error: 'error',
      warning: 'warning',
      info: 'info',
      question: 'question'
    };

    this.mode = 'active';
  }

  addMessage(title, options = {}) {
    const { type, ...rest } = options;

    const params = {
      icon: type || this.infoTypes.success,
      title: this.$translate.instant(title),
      ...rest
    };

    if (this.mode == 'silent') return;
    return Swal.fire({ ...this._toastDefaults(type), ...params });
  }

  disable() {
    this.mode = 'silent';
  }

  activate() {
    this.mode = 'active';
  }

  confirm(title, options = {}) {
    const { type, ...rest } = options;

    const params = {
      icon: type || this.infoTypes.question,
      title: this.$translate.instant(title),
      ...rest
    };

    return Swal.fire({ ...this._confirmDefaults({
      confirmButtonText: this.$translate.instant('general.yes'),
      cancelButtonText: this.$translate.instant('general.cancel')
    }), ...params });
  }

  done(title, options = {}) {
    const { type, ...rest } = options;

    const params = {
      icon: type || this.infoTypes.success,
      title: this.$translate.instant(title),
      ...rest
    };

    return Swal.fire({ ...this._confirmDefaults({
      confirmButtonText: this.$translate.instant('general.close')
    }), ...params });
  }

  progress(title, options = {}) {
    const { timer } = options;

    return Swal.fire({
      position: 'top',
      title: this.$translate.instant(title),
      timer: timer || 30000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      } });

  }

  _confirmDefaults(options = {}) {
    return _merge({
      showCancelButton: options['cancelButtonText'],
      reverseButtons: true
    }, options);
  }

  _toastDefaults(type) {
    return {
      toast: true,
      customClass: {
        title: 'fn-toast-title',
        popup: 'fn-toast-popup-' + type
      },
      position: 'bottom-end',
      timerProgressBar: true,
      showConfirmButton: false,
      timer: (type == 'success' ? 2000 : 4000),
      showCloseButton: true,
      showClass: {
        popup: 'swal2-show',
      },
      hideClass: {
        popup: 'swal2-hide',
      },
    };
  }
}
