import template from './comment-form.pug';

export const CommentFormComponent = {
  template,
  bindings: {
    item: '<',
    formState: '<',
    onCreate: '&?',
    onDestroy: '&?',
  },
  controller: class CommentFormComponent {
    constructor(Chronology, AlertBox, $async, $log) {
      'ngInject';
      this.Chronology = Chronology;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.save = $async(this.save.bind(this));
      this.destroyRecord = $async(this.destroyRecord.bind(this));
    }

    $onInit() {
      this.tempComment = angular.copy(this.item);
    }

    setTemp() {
      this.tempComment = angular.copy(this.item);
    }

    changeState({ state }) {
      if (state) this.formState = state;
      if (state == 'show') this.item = angular.copy(this.tempComment);
    }

    async destroyRecord() {
      try {
        await this.Chronology.destroyChild(this.item.id);
        this.onDestroy({ $event: { chronologyItem: this.item } });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async save({ comment }) {
      try {
        let record_id = null;
        if (this.item.record) {
          record_id = this.item.record.id;
        }
        const chronologyItem = await this.Chronology.saveChild(this.item.chronology_id,{
          type: 'Comment',
          ...comment,
          record_id
        });

        if (this.item.id) {
          this.setTemp();
          this.changeState({ state: 'show' });
          this.AlertBox.addMessage('alert.updated', { type: 'success' });
        }
        else {
          this.changeState({ state: 'edit' });
          this.onCreate({ $event: { chronologyItem } });
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

  }
};
