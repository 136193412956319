import template from './phone-show.pug';

export const PhoneShowComponent = {
  template,
  bindings: {
    onUpdate: '&?',
    label: '@?',
    description: '@?',
    model: '<',
    record: '<?',
    recordType: '@?',
  },
  controller: class PhoneShowComponent {
    constructor() {
      'ngInject';
    }

    $onChanges({model ,record}) {
      if (model || record) {
        this.setValue();
      }
    }

    $onInit() {
      this.setValue();

    }

    setValue() {
      if (this.model && (this.model.country || this.model.number)) {
        this.phoneAttr = angular.copy(this.model);
      } else {
        // depricated, but left in case some old fields use it
        this.phoneAttr = angular.copy(this.model);
      }
    }

    update() {
      if (this.onUpdate) {
        this.onUpdate({$event: {}});
      }
    }

  }
};
