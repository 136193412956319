import template from './portal-filters-form.pug';

export const PortalFiltersFormComponent = {
  template,
  bindings:{
    portal: '<',
    formState: '<',
    onUpdate: '&?'
  },
  controller: class PortalFiltersFormComponent {
    constructor(Portal, AlertBox, $log, $async, Search) {
      'ngInject';
      this.Portal = Portal;
      this.Search = Search;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.save = $async(this.save.bind(this));
      this.getCustomViews = $async(this.getCustomViews.bind(this));
      this.deleteCustomView = $async(this.deleteCustomView.bind(this));
    }

    $onInit() {
      this.setTemp();
      this.getCustomViews();
    }

    setTemp() {
      this.tempPortal = angular.copy(this.portal);
    }

    changeState({ state }) {
      if (state) this.formState = state;
      if (state == 'show') this.portal = angular.copy(this.tempPortal);
    }

    cancel({ state }) {
      this.portal = this.tempPortal;
      this.changeState({ state });
    }

    async createNewCustomView() {
      try {
        const resp = await this.Portal.customViewModal({
          title: '',
          account_portal_id: this.portal.id,
          module: 'Estate'
        });
        if (resp && resp.id) {
          this.customViews.push(resp);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async editCustomView(customView) {
      try {
        const resp = await this.Portal.customViewModal(customView);
        if (resp && resp.id) {
          this.getCustomViews();
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getCustomViews() {
      try {
        this.customViews = await this.Search.customSearch('Estate', this.portal.id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async deleteCustomView(id) {
      try {
        const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });
        if (result && result.dismiss) return false;
        await this.Search.deleteCustomSearch(id);
        this.customViews = this.customViews.filter(item => item.id !== id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async save({ data }) {
      const params = { ...this.portal, ...{ data } };
      try {
        this.loader = true;
        this.portal = await this.Portal.update(this.portal.id, params);
        this.AlertBox.addMessage('alert.updated',{ type: 'success' });
        this.setTemp();
        this.onUpdate({ $event: {} });
        this.changeState({ state: 'show' });
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }
  }
};
