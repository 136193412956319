import template from './inquiry-header.pug';

export const InquiryHeaderComponent = {
  template,
  bindings: {
    records: '<',
    checkedIds: '<',
    totalPages: '<',
    totalCount: '<',
    isModal: '<?',
    page: '<',
    afterPageChange: '&',
    onFilterChange: '&',
    filter: '<?'
  },

  controller: class InquiryHeaderComponent {
    constructor(Inquiry, AlertBox, $rootScope, Table, RecordStatus, $stateParams, $state, $log, $async) {
      'ngInject';
      this.$stateParams = $stateParams;
      this.$state = $state;
      this.$log = $log;

      this.RecordStatus = RecordStatus;

      this.multipleChangeOwner = $async(this.multipleChangeOwner.bind(this));
      this.multipleDelete = $async(this.multipleDelete.bind(this));
      this.export = $async(this.export.bind(this));
      this.multipleChangeRecordStatus = $async(this.multipleChangeRecordStatus.bind(this));
      this.performMultipleChangeRecordStatus = $async(this.performMultipleChangeRecordStatus.bind(this));
      this.countRecordStatuses = $async(this.countRecordStatuses.bind(this));

      this.Inquiry = Inquiry;
      this.AlertBox = AlertBox;
      this.$rootScope = $rootScope;
      this.openCharts = false;
      this.Table = Table;
    }

    $onChanges({ filter } = {}) {
      if (filter && filter.currentValue) {
        Object.keys(this.filter).length > 1 ? this.disabledClearFilter = false : this.disabledClearFilter = true;
      }
    }

    $onInit() {
      this.countRecordStatuses();
    }

    async countRecordStatuses() {
      try {
        const object = await this.RecordStatus.count('inquiry');
        this.countStatuses = object.count;
      } catch (e) {
        this.$log.error(e);
      }
    }

    async multipleChangeRecordStatus() {
      if (!this.alertIfNotSelected()) return;
      try {
        const status = await this.RecordStatus.changeModal({
          module: 'inquiry',
          selected: this.RecordStatus.returnLastStatusId('inquiry')
        });
        if (status) {
          this.loader = true;
          await this.performMultipleChangeRecordStatus(status.id);
          this.loader = false;
          this.applyFilter({ searchId: this.$stateParams.searchId });
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async performMultipleChangeRecordStatus(status_id) {
      await this.checkedIds.asyncForEach(async (id) => {
        await this.Inquiry.update(id, { record_status_id: status_id });
      });
    }


    async export() {
      try {
        let response = await this.Inquiry.export(this.$stateParams.searchId, this.filter);
        if (response) this.AlertBox.addMessage('alert.export_table_success', { type: 'success' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async multipleChangeOwner() {
      try {
        let object = await this.Inquiry.multipleChangeOwnerModal(this.checkedIds);
        if (object) this.applyFilter({ searchId: this.$stateParams.searchId });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async multipleDelete() {
      if (!this.alertIfNotSelected()) return;
      try {
        let object = await this.Inquiry.multipleDeleteModal(this.checkedIds);
        if (object) {
          this.applyFilter({ searchId: this.$stateParams.searchId });
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    openAll() {
      if (!this.alertIfNotSelected()) return;
      if (this.checkedIds && this.checkedIds.length > 0) {
        this.checkedIds.forEach((id, i) => {
          window.open('/inquiries/show/' + id, ''+i);
        });
      }
    }

    alertIfNotSelected() {
      if (!this.checkedIds.length) {
        this.AlertBox.done('alert.select_at_least_one_record', { type: 'info' });
        return false;
      }
      return true;
    }

    applyFilter({ searchId }) {
      this.onFilterChange({
        $event: { searchId: searchId, filter: { ...this.filter, page: 1 } }
      });
    }

    clearFilters() {
      this.$rootScope.gridApi['inquiries-table'].grid.clearAllFilters();
    }

    hasActiveFilters() {
      return this.Table.hasActiveFilters('inquiries-table');
    }

    applyPage({ page }) {
      this.afterPageChange({ $event: { page } });
    }
  }
};
