import template from './header.pug';

export const HeaderComponent = {
  template,
  bindings: {
    totalPages: '<',
    totalCount: '<',
    page: '<',
    perPage: '<?',
    afterPageChange: '&',
    isModal: '<?'
  },
  transclude : true,
  controller: class HeaderComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
      this.enabledEdit = true;
    }

    change({ page }) {
      this.afterPageChange({
        $event: { page }
      });
    }
  }
};
