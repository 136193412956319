import template from './contact-tbody.pug';

export const ContactTbodyComponent = {
  template,
  bindings: {
    filter: '<',
    records: '<',
    checkedIds: '<',
    gridOptions: '<?',
    onFilterChange: '&',
    isModal: '<?',
    onCheck: '&'
  },
  controller: class ContactTbodyComponent {
    constructor($stateParams, $state, Contact, User, AlertBox, Modal, $scope, Hotkeys, $async, $log) {
      'ngInject';
      this.Contact = Contact;
      this.User = User;
      this.Modal = Modal;
      this.$scope = $scope;
      this.$state = $state;
      this.AlertBox = AlertBox;
      this.$stateParams = $stateParams;
      this.$log = $log;
      this.Hotkeys = Hotkeys;
      this.isVisible = false;
      this.getContact = $async(this.getContact.bind(this));
      this.updateContact = $async(this.updateContact.bind(this));
    }

    $onInit() {}

    $onChanges({ checkedIds, records } = {}) {
      if (checkedIds && checkedIds.currentValue && checkedIds.currentValue.length == 0) {
        this.records &&
          this.records.forEach(record => {
            record.checked = false;
          });
      }
      if (records && records.currentValue) {
        this.gridOptions.data = records.currentValue.map(x => {
          return (x.attributes = { ...x.attributes, ...x.attributes['custom_fields'] });
        });
      }
    }

    checkIds({ checkedIds }) {
      this.onCheck({ $event: { checkedIds } });
    }

    showContact({ row }) {
      this.Contact.navigateToShow(row.entity.id);
    }

    updateCell(args) {
      let params = {};
      let hasCustomField = Object.keys(args).find(e => e.startsWith('c_f'));
      let row = this.gridOptions.data.find(e => (e.id = args.id));

      if (hasCustomField) {
        params.custom_fields = {
          ...row.custom_fields
        };
      }

      for (let key in args) {
        if (!key) return;
        if (key !== 'id') {
          if (key.startsWith('c_f')) params.custom_fields[key] = args[key];
        } else {
          params[key] = args[key];
        }
      }

      this.updateContact(args.id, params);
    }

    async updateContact(id, params) {
      try {
        let attributes = this.Contact.serialize(params);
        this.contact = await this.Contact.update(id, attributes);
        this.AlertBox.addMessage('alert.updated', { type: 'success' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    updateFilter() {
      const params = {
        filter: this.filter,
        searchId: this.$stateParams.searchId
      };
      this.applyFilter(params);
    }

    applyFilter({ filter, searchId }) {
      this.onFilterChange({
        $event: { filter, searchId }
      });
    }

    preview({ row }) {
      this.getContact(row.entity.id);
    }

    goTo({ row }) {
      this.$state.go('main.contacts.show', { id: row.entity.id });
    }

    previewDirection({ id }) {
      this.getContact(id);
    }

    close() {
      this.isVisible = false;
    }

    async getContact(id) {
      try {
        const contact = await this.Contact.find(id);
        this.selectedRecord = contact;
        this.isVisible = true;
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
