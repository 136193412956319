import template from './charts.pug';

export const ChartsComponent = {
  template,
  bindings: {
    modelName: '@',
    filter: '<'
  },
  controller: class ChartsComponent {
    constructor(ChartService, $log, $async) {
      'ngInject';
      this.ChartService = ChartService;
      this.$log = $log;
      this.getList = $async(this.getList.bind(this));
      this.definedCharts = ['records_over_time_count', 'records_by_user', 'leads_by_number'];
    }

    $onInit() {
      this.getList();
    }

    async getList() {
      try {
        this.list = await this.ChartService.list(this.modelName);
        this.listForMultiselect = this.list.filter(e => {
          return !this.definedCharts.includes(e[0]);
        });
        if(this.list.length){
          this.selected = this.list[0][0];
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    select(name) {
      this.selected = name;
    }

    isSelected(name){
      return this.selected == name;
    }
  }
};
