
class Table {
  constructor($localStorage, uiGridConstants) {
    'ngInject';
    this.gridApi = null;
    this.uiGridConstants = uiGridConstants;
    this.store = $localStorage;
    this.activeFilters =  $localStorage.activeFilters ? $localStorage.activeFilters: {};
    this.activeSort =  $localStorage.activeSort ? $localStorage.activeSort : {};
  }

  // grid Api
  setGridApi(api) {
    this.gridApi = api;
  }

  notifyDataChange() {
    this.gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
  }

  // filter and sort states
  setActiveFilters(moduleName, filters) {
    this.store.activeFilters ? this.store.activeFilters[moduleName] = {
      ...this.store.activeFilters[moduleName],
      ...filters
    } : this.store.activeFilters = {
      [moduleName]: filters
    };

    this.activeFilters = this.store.activeFilters;
  }

  clearFilters(moduleName) {
    this.gridApi.grid.clearAllFilters();
    if (moduleName && this.store.activeFilters[moduleName]) {
      this.store.activeFilters[moduleName] = {};
    }
  }

  hasActiveFilters(moduleName) {
    if (this.store.activeFilters == undefined) return false;
    const activeFilters = this.store.activeFilters[moduleName];
    if (activeFilters == undefined) return false;
    return Object.values(activeFilters).length > 0;
  }

  setActiveSort(moduleName, sort) {
    this.store.activeSort ? this.store.activeSort[moduleName] = {
      ...sort
    } : this.store.activeSort = {
      [moduleName]: sort
    };

    this.activeSort = this.store.activeSort;
  }
}
export default Table;
