export default class ChronologyTextFilter {
  constructor() {
    return (chronology_child) => {
      this.text = 'chronology_timeline_events.created';
      switch (chronology_child.record_type) {
        case 'Estate':
          if (chronology_child.connected_record_id) {
            this.text = 'chronology_timeline_events.created_connected_record_estate';
          } else {
            this.text = 'chronology_timeline_events.created_estate';
          }
          break;
        case 'Contact':
          if (chronology_child.connected_record_id) {
            this.text = 'chronology_timeline_events.created_connected_record_contact';
          } else {
            this.text = 'chronology_timeline_events.created_contact';
          }
          break;
        case 'Inquiry':
          if (chronology_child.connected_record_id) {
            this.text = 'chronology_timeline_events.created_connected_record_inquiry';
          } else {
            this.text = 'chronology_timeline_events.created_inquiry';
          }
          break;
        case 'MultipleProposal':
          this.text = 'chronology_timeline_events.proposal';
          break;
        case 'Document':
          this.text = 'chronology_timeline_events.document';
          break;
        case 'TodoContact':
        case 'TodoEstate':
        case 'TodoInquiry':
          this.text = 'chronology_timeline_events.todo';
          break;
        case 'Finance':
          this.text = 'chronology_timeline_events.finance';
          break;
        case 'Comment':
          this.text = 'chronology_timeline_events.comment';
          break;
        case 'Status':
          this.text = 'chronology_timeline_events.status_' + chronology_child.record.status;
          break;
        case 'InboxMessage':
          this.text = 'chronology_timeline_events.email';
          break;
        case 'RecordStatus':
          this.text = 'chronology_timeline_events.record_status_changed';
          break;
        case 'EstatePriceChange':
          this.text = 'chronology_timeline_events.estate_price_changed';
          break;
      }
      return this.text;
    };
  }

  static filter() {
    return new ChronologyTextFilter();
  }
}
