import template from './contact-edit-form.pug';

export const ContactEditFormComponent = {
  template,
  bindings: {
    contact: '<',
    onCancel: '&?',
    onSave: '&?',
    cfGroups: '<',
    onEdit: '<'
  },
  controller: class ContactEditFormComponent {
    constructor($stateParams, AlertBox, $async, Contact) {
      'ngInject';

      this.stateParams = $stateParams;
      this.AlertBox = AlertBox;
      this.Contact = Contact;

      this.checkPhoneDuplicates = $async(this.checkPhoneDuplicates.bind(this));
      this.checkEmailDuplicates = $async(this.checkEmailDuplicates.bind(this));
    }

    $onInit() {
      if (this.contact == undefined) {
        this.contact = {
          additional_attributes: [],
          custom_fields: {}
        };
      }
      this.temp = angular.copy(this.contact);
    }

    cancel() {
      this.contact = this.temp;
      this.onCancel({
        $event: { state: 'show' }
      });
    }

    async checkPhoneDuplicates() {
      if (this.contact.id) return;
      try {
        this.phoneDuplicates = await this.Contact.checkPhoneDuplicates(this.contact.phone);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async checkEmailDuplicates() {
      if (this.contact.id) return;
      try {
        this.emailDuplicates = await this.Contact.checkEmailDuplicates(this.contact.email);

      } catch (e) {
        this.$log.error(e);
      }
    }

    check() {
      this.stateParams.formDirty = this.temp !== JSON.stringify(this.contact);
      this.contact = angular.copy(this.contact);
    }

    setCopy() {
      this.temp = JSON.stringify(this.contact);
    }

    save(contact, form) {
      this.stateParams.formDirty = false;
      if (form.$valid) {
        this.onSave({
          $event: { contact }
        });
      } else {
        this.AlertBox.addMessage('validation_messages.fill_required_fields', { type: 'warning' });
      }
    }

    changeType({ option }) {
      this.contact.contact_type = option;
    }

  }
};
