import { Map, Marker, NavigationControl } from 'maplibre-gl';
import { GeocoderAutocomplete } from '@geoapify/geocoder-autocomplete';
import circle from '@turf/circle';
import bbox from '@turf/bbox';

export default class Geopify {
  constructor($http) {
    'ngInject';

    this.$http = $http;
    this.map = null;
    this.marker = null;
  }

  initMap(container) {
    this.map = new Map({
      container: container,
      style: this.mapStyle(),
    });
    if (this.marker) {
      this.marker.addTo(this.map);
    }
    return this.map;
  }

  addControls(options = {}) {
    this.map.addControl(new NavigationControl(
      Object.assign({
        showCompass: true,
        showZoom: true,
        visualizePitch: false,
      }, options)
    ));
  }

  initAutocomplete() {
    return new GeocoderAutocomplete(
      document.getElementById('autocomplete'),
      window.GEOPIFY_API_KEY,
      { type: 'amenity', bias: { countrycode: [window.COUNTRY_CODE.toLowerCase()] } });
  }

  setMarker(location = {}) {
    if (!this.marker) {
      this.marker = new Marker({ color: '#ff0000', draggable: true });
      this.marker.setLngLat([location.longitude, location.latitude]);
      this.marker.addTo(this.map);
    }

    this.marker.setLngLat([location.longitude, location.latitude]);
    this.map.setCenter([location.longitude, location.latitude]);
    this.map.setZoom(location.zoom_level || 15);
    return this.marker;
  }

  addOverlay() {
    this.map.addLayer({
      id: 'map-fill',
      type: 'fill',
      source: 'radiusCircle',
      paint: {
        'fill-color': '#c1272d',
        'fill-opacity': 0.35
      },
    });
  }

  setRadius(location) {
    const geoJson = circle([location.longitude, location.latitude], location.radius, { steps: 69, units: 'kilometres' });
    this.map.fitBounds(bbox(geoJson));

    let source =  this.map.getSource('radiusCircle');

    if (!source) {
      this.map.addSource('radiusCircle', {
        type: 'geojson',
        data: geoJson
      });
    } else {
      source.setData(geoJson);
    }


    if (!this.map.getLayer('circle-fill')) {
      this.map.addLayer({
        id: 'circle-fill',
        type: 'fill',
        source: 'radiusCircle',
        paint: {
          'fill-color': '#c1272d',
          'fill-opacity': 0.35
        },
      });
    }

    if (!this.map.getLayer('circle-outline')) {
      this.map.addLayer({
        id: 'circle-outline',
        type: 'line',
        source: 'radiusCircle',
        paint: {
          'line-color': '#d90201',
          'line-opacity': 0.6,
          'line-width': 2,
          'line-offset': 1
        },
      });
    }

  }

  getLocationParams(location) {
    return {
      latitude: location.properties.lat,
      longitude: location.properties.lon,
      name: location.properties.formatted,
      formatted: location.properties.formatted,
      postcode: location.properties.postcode,
      city: location.properties.city,
      county: location.properties.county,
      country: location.properties.country,
      country_code: location.properties.country_code,
      state: location.properties.state,
      state_code: location.properties.state_code,
      street: location.properties.street,
      place_id: location.properties.place_id,
      address_line1: location.properties.address_line1,
      address_line2: location.properties.address_line2,
      housenumber: location.properties.housenumber,
    };
  }

  mapStyle() {
    return `https://maps.geoapify.com/v1/styles/klokantech-basic/style.json?apiKey=${window.GEOPIFY_API_KEY}`;
  }

  reverseGeocode(latitude, longitude) {
    return fetch(`https://api.geoapify.com/v1/geocode/reverse?apiKey=${window.GEOPIFY_API_KEY}&lat=${latitude}&lon=${longitude}`);
  }
}
