import template from './new.pug';

export const TodoModalNewComponent = {
  template,
  bindings:{
    resolve: '<',
    modalInstance: '<',
  },
  controller: class TodoModalNewComponent {
    constructor($async, $log, Todo, AlertBox) {
      'ngInject';

      this.Todo = Todo;
      this.$log = $log;
      this.AlertBox = AlertBox;
      this.save = $async(this.save.bind(this));
      this.destroy = $async(this.destroy.bind(this));
    }

    $onInit() {
      this.record = this.resolve.todo;
    }

    close() {
      this.modalInstance.close(void(0));
    }

    async save({ todo }) {
      try {
        this.AlertBox.addMessage('alert.created', { type: 'success' });
        this.modalInstance.close(todo);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async destroy() {
      const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });
      if (result && result.dismiss) return false;
      try {
        await this.Todo.moveToTrash(this.record.id);
        this.AlertBox.addMessage('alert.deleted', { type: 'success' });
        this.modalInstance.close('destroy');
      } catch (e) {
        this.$log.error(e);
      }
    }

    update({ todo }) {
      try {
        this.AlertBox.addMessage('alert.updated', { type: 'success' });
        this.modalInstance.close(todo);
      } catch (e) {
        this.$log.error(e);
      }
    }

  }
};
