import { ProposalEditFormComponent } from './proposal/edit/proposal-edit-form.component';
import { ProposalShowFormComponent } from './proposal/show/proposal-show-form.component';
import { ProposalModalPreviewModalComponent } from './proposal/modal/preview/proposal-modal-preview.component';

import { CommentFormComponent } from './comment/comment-form.component';
import { CommentShowComponent } from './comment/show/comment-show.component';
import { CommentEditComponent } from './comment/edit/comment-edit.component';

import { CustomFieldsFormComponent } from './custom-fields/custom-fields-form.component';
import { CustomFieldsShowComponent } from './custom-fields/show/custom-fields-show.component';
import { CustomFieldsEditComponent } from './custom-fields/edit/custom-fields-edit.component';

import { CommentModalPreviewModalComponent } from './comment/modal/preview/comment-modal-preview.component';

import { TodoFormComponent } from './todo/todo-form.component';
import { TodoShowComponent } from './todo/show/todo-show.component';

const FormModule =
  angular.module('topbroker.sharedForm', [])
    .component('todoForm', TodoFormComponent)
    .component('todoShow', TodoShowComponent)
    .component('proposalEditForm', ProposalEditFormComponent)
    .component('proposalShowForm', ProposalShowFormComponent)
    .component('proposalModalPreview', ProposalModalPreviewModalComponent)
    .component('commentForm', CommentFormComponent)
    .component('commentShowForm', CommentShowComponent)
    .component('commentEditForm', CommentEditComponent)
    .component('customFieldsForm', CustomFieldsFormComponent)
    .component('customFieldsShowForm', CustomFieldsShowComponent)
    .component('customFieldsEditForm', CustomFieldsEditComponent)
    .component('commentPreviewModal', CommentModalPreviewModalComponent)
    .name;

export default FormModule;
