import template from './global-search.pug';

export const GlobalSearchComponent = {
  template,
  bindings: {},
  controller: class GlobalSearchComponent {
    constructor(Search, $state, $async, $log, $element) {
      'ngInject';
      this.Search = Search;
      this.$state = $state;
      this.$element = $element;
      this.$log = $log;

      this.searchForIt = $async(this.searchForIt.bind(this));
      this.saveSearchItem = $async(this.saveSearchItem.bind(this));

      this.isSidebarOpened = false;
      this.searchByModule = '';
      this.loading = false;
      this.query = '';
    }

    $onInit() {
      this.searchForIt('');
    }

    selectModule(module) {
      this.searchByModule = module == this.searchByModule ? '' : module;
      if (this.query !== '') {
        this.searchForIt(this.query);
      }
    }

    openSearchSidebar() {
      this.isSidebarOpened = true;
      this.query = '';
      this.$element.find('.fn-sidebar-content .search-input input').focus();
    }

    async searchForIt(query) {
      try {
        this.loading = true;
        const resp = await this.Search.global(query, this.searchByModule);
        if (query == '') {
          this.results = { ...resp, ...this.Search.last() };
        } else {
          this.results = resp;
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;
      }
    }

    openResultInTable() {
      this.$state.go();
    }

    async saveSearchItem(result, type) {
      try {
        this.Search.performed(result, type);
        this.query = '';
      } catch (e) {
        this.$log.error(e);
      }
    }

  }
};
