import template from './button.pug';

export const ButtonComponent = {
  template,
  bindings: {
    btnType: '<',
    title: '@?',
    onClick: '&?',
    btnClass: '<?',
    btnDisabled: '<?'
  },
  transclude : true,
  controller: class ButtonComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }

    clicked() {
      if (!this.disabled && this.onClick) {
        this.onClick();
      }
    }
  }
};
