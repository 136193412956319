import template from './document-record-attachments.pug';

export const DocumentRecordAttachmentsComponent = {
  template,
  bindings: {
    document: '<',
  },
  controller: class DocumentRecordAttachmentsComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
      if (typeof this.document == 'object') {
        this.document = this.document[0];
      }

      if (this.document && this.document.attachments.length) {
        this.firstAttachment = [this.document.attachments[0]];
      }
    }
  }
};
