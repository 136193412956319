// base component
import { NotificationComponent } from './notification.component';
import { NotificationMatchComponent } from './match/notification-match.component';

// Notification items
import { ItemFieldChangesComponent } from './items/field-changes/field-changes.component';
import { ItemTodoReminderComponent } from './items/todo-reminder/todo-reminder.component';
import { ItemNotifyMatchComponent } from './items/notify-match/notify-match.component';

// Previews components
import { PreviewFieldChangesComponent } from './previews/field-changes/field-changes.component';
import { PreviewTodoReminderComponent } from './previews/todo-reminder/todo-reminder.component';
import { PreviewNotifyMatchComponent } from './previews/notify-match/notify-match.component';

const NotificationModule = angular.module('topbroker.sharedNotification', [])
  .component('notification', NotificationComponent)
  .component('notificationMatch', NotificationMatchComponent)

  .component('notificationItemFieldChanges', ItemFieldChangesComponent)
  .component('notificationItemTodoReminder', ItemTodoReminderComponent)
  .component('notificationItemNotifyMatch', ItemNotifyMatchComponent)

  .component('notificationPreviewFieldChanges', PreviewFieldChangesComponent)
  .component('notificationPreviewTodoReminder', PreviewTodoReminderComponent)
  .component('notificationPreviewNotifyMatch', PreviewNotifyMatchComponent)

  .filter('notificationNumber', function () {
    return function (input) {
      if (input && input > 9) {
        return '9+';
      } else {
        return input;
      }
    };
  })
  .name;

export default NotificationModule;
