import template from './field-changes.pug';

export const ItemFieldChangesComponent = {
  template,
  bindings: {
    notification: '<',
    onOpenPreview: '&'
  },
  controller: class ItemFieldChangesComponent {
    constructor($translate) {
      'ngInject';

      this.$translate = $translate;
    }

    $onInit() {}

    previewClick() {
      this.onOpenPreview({
        $event: this.notification
      });
    }

    changedFields() {
      if (this.notification && this.notification.data && !Object.keys(this.notification.data.translated_changes).length > 0) return;

      const changedFields = Object.keys(this.notification.data.translated_changes).join(', ');

      return `, ${this.$translate.instant('settings.notifications.modified_fields').toLowerCase()}: ${changedFields}`;
    }
  }
};
