import template from './photos-download-style-modal.pug';

export const PhotosDownloadStyleModalComponent = {
  template,
  bindings: {
    modalInstance: '<',
  },
  controller: class PhotosDownloadStyleModalComponent {
    constructor() {
      'ngInject';

      this.downloadStyle = 'watermarked'; // Available styles ['original', 'watermarked']
    }

    $onInit() {}

    close() {
      this.modalInstance.close(void 0);
    }

    async download() {
      try {
        this.modalInstance.close(this.downloadStyle);
      } catch (e) {
        console.error(e);
      }
    }
  },
};
