import template from './account-settings.pug';

export const AccountSettingsComponent = {
  template,
  bindings: {},
  controller: class AccountSettingsComponent {
    constructor($log, $async, Account, AlertBox) {
      'ngInject';

      this.Account = Account;
      this.AlertBox = AlertBox;
      this.$log = $log;

      this.getCurrentAccount = $async(this.getCurrentAccount.bind(this));
      this.getCountries = $async(this.getCountries.bind(this));
      this.getPlanInfo = $async(this.getPlanInfo.bind(this));
      this.save = $async(this.save.bind(this));
    }

    $onInit() {
      this.getCurrentAccount();
      this.getCountries();
      this.getPlanInfo();
    }

    async getPlanInfo() {
      try {
        this.planInfo = await this.Account.planInfo();
      } catch (e) {
        console.error(e);
      }
    }

    async getCountries() {
      try {
        const resp = await this.Account.accountCountries();
        if (resp) {
          this.countries = resp;
        }
      } catch (error) {
        this.$log.error(error);
      }
    }

    async save() {
      try {
        const { country, title } = this.account;
        this.account = await this.Account.update({ country, title });
        this.saveTmp(this.account);
        this.AlertBox.addMessage('alert.updated', { type: 'success' });
      } catch (e) {
        this.$log.error(e);
        this.AlertBox.addMessage('alert.updated', { type: 'error' });

      }
    }

    async getCurrentAccount() {
      try {
        this.account = await this.Account.current();
        this.saveTmp(this.account);
      } catch (e) {
        this.$log.error(e);
      }
    }

    countrySelected(countryCode) {
      if (countryCode) {
        this.account.country = countryCode;
      } else {
        this.account.country = null;
      }
    }

    isAccountChanged() {
      return JSON.stringify(this.accountTmp) == JSON.stringify(this.account);
    }

    saveTmp(account) {
      this.accountTmp = angular.copy(account);
    }

    restoreTmp() {
      this.account = angular.copy(this.accountTmp);
    }
  }
};
