import template from './document-list.pug';

export const DocumentsListComponent = {
  template,
  bindings: {
    filter: '<',
    isModal: '<?',
    onCheck: '&?'
  },
  controller: class DocumentsListComponent {
    constructor($stateParams, $state, Document, Account, $async, $log) {
      'ngInject';

      this.$stateParams = $stateParams;
      this.$state = $state;
      this.Document = Document;
      this.$log = $log;
      this.newDocument = $async(this.newDocument.bind(this));
      this.getAccountStats = $async(this.getAccountStats.bind(this));
      this.Account = Account;
      this.totalRecords = 1;
    }

    $onInit() {
      this.getAccountStats();
      this.filter = angular.copy(this.filter);
    }

    async getAccountStats() {
      try {
        const response  = await this.Account.getAccountStatsByModule('document');
        this.totalRecords = response.count;
      } catch (e) {
        this.$log.log(e);
      }
    }

    async newDocument() {
      try {
        const document = await this.Document.newModal();
        if (document && document.id) {
          this.updatePage({ page: 1, searchId: this.$stateParams.searchId });
        }
      } catch (e) {
        this.$log.log(e);

      }
    }

    applyFilterAndResetPage({ filter, searchId }) {
      if (searchId) {
        this.$stateParams.searchId = searchId;
      } else {
        searchId = -1;
      }
      this.applyFilter(filter);
      this.updatePage({ page: filter.page || 1, searchId });
    }

    updatePage({ page, searchId }) {
      searchId = searchId || this.$stateParams.searchId;
      this.$state.go('.', { page, searchId });
      this.filter.page = page;
      this.$onInit();
    }

    applyFilter(filter) {
      if (this.$stateParams.customFieldId && this.$stateParams.destinationId && this.$stateParams.destinationType) {
        this.filter = {
          ...filter,
          connected_record: {
            custom_field_id: this.$stateParams.customFieldId,
            destination_id: this.$stateParams.destinationId,
            destination_type: this.$stateParams.destinationType
          }
        } || {};
      } else {
        this.filter = filter || {};
      }
    }

    doCheck({ checkedIds }) {
      if (this.onCheck) {
        this.onCheck({
          $event: { checkedIds }
        });
      }
    }

  }
};
