import template from './modal.pug';

export const ModalComponent = {
  template,
  bindings: {
    modalTitle: '@?',
    titleValues: '<',
    titleIcon: '@?',
    onClose: '&?',
    formDirty: '<'
  },
  transclude: {
    header: '?modalHeader',
    body: 'modalBody',
    footer: '?modalFooter',
    title: '?modalTitle'
  },
  controller: class ModalComponent {
    constructor(AlertBox, $async, $scope, $stateParams) {
      'ngInject';
      this.$scope = $scope;
      this.AlertBox = AlertBox;
      this.$stateParams = $stateParams;
      this.confirmAction = $async(this.confirmAction.bind(this));
      this.close = $async(this.close.bind(this));
    }

    $onInit() {
    }

    async confirmAction(modal) {
      modal.preventDefault();
      const result = await this.AlertBox.confirm('confirmation_messages.close', { type: 'warning' });
      if (result && result.dismiss) return true;
      this.$stateParams.formDirty = false;
      modal.targetScope.$uibModalInstance.close(void 0);
    }

    async close() {
      if (this.$stateParams.formDirty) {
        const result = await this.AlertBox.confirm('confirmation_messages.close', { type: 'warning' });
        if (result && result.isConfirmed) {
          this.$stateParams.formDirty = false;
          this.onClose({ $event: void 0 });
        }
      } else {
        this.onClose({ $event: void 0 });
      }
    }
  }
};
