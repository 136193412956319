import template from './input-group.pug';

export const InputGroupComponent = {
  template,
  bindings: {
    label: '<?',
    description: '<?',
    validations: '<?',

  },
  transclude : true,
  controller: class InputGroupComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {

    }

  }
};
