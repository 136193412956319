import template from './input-connected-record.pug';
import { singular } from 'pluralize';
import { isEqual } from 'lodash';

export const InputConnectedRecordComponent = {
  template,
  bindings: {
    customField: '<',
    source: '<',
    hideSearch: '<',
    validations: '<?',
    formObj: '<?'
  },
  controller: class InputConnectedRecordComponent {
    constructor($async, $log, ConnectedRecord, AlertBox, Assignment, Contact, Estate, Inquiry, Document) {
      'ngInject';

      this.$log = $log;

      this.getRecordsList = $async(this.getRecordsList.bind(this));
      this.removeRecord = $async(this.removeRecord.bind(this));
      this.addRecord = $async(this.addRecord.bind(this));

      this.ConnectedRecord = ConnectedRecord;
      this.AlertBox = AlertBox;
      this.Assignment = Assignment;
      this.Contact = Contact;
      this.Estate = Estate;
      this.Inquiry = Inquiry;
      this.Document = Document;

      this.hideSearch = false;
      this.multiSelect = false;
      this.excludedRecords = [];
      this.records = [];
    }

    $onInit() {
      if (this.customField && this.customField.validations) {
        this.multiSelect = this.customField.validations.multiple_select;
        this.searchType = singular(this.customField.validations.destination).toCamel();
      }

      if (this.source && !this.source.hasOwnProperty('id')) {
        this.isNewSource = true;

        if (!this.source['new_connected_records']) this.source['new_connected_records'] = {};
        this.source['new_connected_records'][this.customField.technical_name] = {
          custom_field_id: this.customField.id,
          destination_ids: [],
          ...(this.source['new_connected_records'][this.customField.technical_name] || {})
        };

        if (this.source['new_connected_records'][this.customField.technical_name]['record']) {
          this.addRecord(this.source['new_connected_records'][this.customField.technical_name]['record']);
        }
      } else {
        this.isNewSource = false;
      }
    }

    $onChanges({ source, customField }) {
      if ((source && !isEqual(source.currentValue, source.previousValue)) || (customField && !isEqual(customField.currentValue, customField.previousValue))) {
        this.getRecordsList();
      }
    }

    async searchModal() {
      try {
        let response = await this.Assignment.selectModal({
          type: this.searchType,
          alreadySelectedItems: this.excludedRecords,
          multiple: this.multiSelect,
          modalSize: this.modalSize()
        });

        if (response) {
          if (response.items) {
            response.items.forEach( item => this.addRecord(item) );
          } else if (response.$event) {
            this.addRecord(response.$event);
          }
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    modalSize() {
      return ((this.searchType == 'User') ? 'md' : 'full');
    }

    async newModal() {
      try {
        let resp = {};
        switch (this.searchType) {
          case 'Contact':
            resp = await this.Contact.new();
            break;
          case 'Estate':
            resp = await this.Estate.new();
            break;
          case 'Inquiry':
            resp = await this.Inquiry.newModal();
            break;
          case 'Document':
            resp = await this.Document.newModal();
            break;
        }

        if (resp && resp.id) this.addRecord(resp);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getRecordsList() {
      try {
        if (!this.source.hasOwnProperty('id') || !this.customField.hasOwnProperty('id')) return;

        const records = await this.ConnectedRecord.getList({
          source_id: this.source['id'],
          custom_field_id: this.customField['id']
        });

        this.excludedRecords = records.map(item => item.destination);

        this.records = records || [];
      } catch (e) {
        this.$log.error(e);
      }
    }

    async addRecord(record) {
      try {
        if (this.isNewSource) {
          if (this.customField.validations.multiple_select) {
            this.records = [...this.records, record];
          } else {
            this.records = [record];
          }
          this.source['new_connected_records'][this.customField.technical_name]['destination_ids'].push(record.id);
          this.excludedRecords = this.records;
        } else {
          await this.ConnectedRecord.create({
            source_id: this.source['id'],
            destination_id: record['id'],
            custom_field_id: this.customField['id']
          });
          this.getRecordsList();
        }
      } catch (e) {
        this.AlertBox.addMessage('alert.created', { type: 'error' });
        this.$log.error(e);
      }
    }

    async removeRecord(record) {
      try {
        const confirmConfirm = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });
        if (confirmConfirm && confirmConfirm.dismiss) return false;

        if (this.isNewSource) {
          this.records = this.records.filter(item => item.id !== record.id);
          this.source['new_connected_records'][this.customField.technical_name]['destination_ids'] = this.source['new_connected_records'][this.customField.technical_name]['destination_ids'].filter(item => item !== record.id);
          this.excludedRecords = this.records;
        } else {
          await this.ConnectedRecord.delete(record.id);
          this.AlertBox.addMessage('alert.deleted', { type: 'success' });
        }
        this.getRecordsList();
      } catch (e) {
        this.AlertBox.addMessage('alert.deleted', { type: 'error' });
        this.$log.error(e);

      }
    }
  }
};
