import template from './textarea.pug';

export const TextareaComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    placeholder: '@?',
    model: '<',
    validations: '<?',
    onEdit: '&?',
  },
  controller: class TextareaComponent {
    constructor($document) {
      'ngInject';
      this.$document = $document[0];
    }

    $onInit() {
      this.setAutoSize();
    }

    edit(text) {
      if (this.onEdit) {
        this.onEdit({
          $event: { text }
        });
      }
    }


    setAutoSize() {
      const elm = this.$document.querySelector('textarea.form-control');
      if (elm) {
        setTimeout(() => {
          elm.style.cssText = 'height:auto; padding:0';
          elm.style.cssText = 'height:' + elm.scrollHeight + 'px';
          elm.addEventListener(
            'keydown', this.autosize
          );
        }, 0);

      }
    }

    autosize() {
      const el = this;
      setTimeout(() => {
        el.style.cssText = 'height:auto; padding:0';
        // for box-sizing other than "content-box" use:
        // el.style.cssText = '-moz-box-sizing:content-box';
        el.style.cssText = 'height:' + el.scrollHeight + 'px';
      }, 0);
    }

  }
};
