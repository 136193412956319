import template from './role-show.pug';

export const RoleShowComponent = {
  template,
  bindings: {
    role: '<',
    onEdit: '&?',
    onCopy: '&?',
    onDelete: '&?',
  },
  controller: class RoleShowComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }

    change({ state }) {
      this.onEdit({
        $event: { state }
      });
    }
  }
};
