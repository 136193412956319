import angular from 'angular';
// base component
import { LayoutComponent } from './layout.component';
// components
import { MenuComponent } from './menu/menu.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { UserProfileDropdown } from './user-profile-dropdown/user-profile-dropdown.component';
import { MenuWorkspaceChanger } from './menu-workspace-changer/menu-workspace-changer.component';
// services
import getSymbolFromCurrency from 'currency-symbol-map';

const loadGoogleMapsApi = require('load-google-maps-api');

export const LayoutModule = angular
  .module('topbroker.layout', [])
  .component('layout', LayoutComponent)
  .component('fnMenu', MenuComponent)
  .component('menuItem', MenuItemComponent)
  .component('userProfileDropdown', UserProfileDropdown)
  .component('menuWorkspaceChanger', MenuWorkspaceChanger)

  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('main', {
        abstract: true,
        component: 'layout',
        resolve: {
          permissions: (Permission) => {
            'ngInject';
            return Permission.setPermissions();
          },
          currentUser: ($auth, User, Estate, Todo, RecordStatus, $q, $state, $localStorage, PermRoleStore, PermPermissionStore, $sessionStorage, Workspace, ActionCableConfig) => {
            'ngInject';

            ActionCableConfig.autoStart = false;
            let deferred = $q.defer();
            let goToLogin = () => {
              // $state.go('auth.login');
              $auth.logout();
              PermRoleStore.clearStore();
              PermPermissionStore.clearStore();
              deferred.reject();
              PermRoleStore.clearStore();
              PermPermissionStore.clearStore();
              $state.go('auth.login', { messages: ['please_relogin'] },{ reload: true });
            };
            if ($auth.getPayload()) {
              Estate.getEstateTypes().then(resp => $localStorage['estate_types'] = resp);
              RecordStatus.getList('estate').then(resp => $localStorage['estate_statuses'] = resp);
              RecordStatus.getList('contact').then(resp => $localStorage['contact_statuses'] = resp);
              RecordStatus.getList('inquiry').then(resp => $localStorage['inquiry_statuses'] = resp);
              Todo.getGroups().then(resp => $localStorage['todo.groups_list'] = resp);
              Workspace.getGroups().then(resp => $localStorage['workspaces_count'] = resp.length);


              return User.find($auth.getPayload().user.id, { 'get_user_settings': true, 'get_default_workspace': true }).then((user) => {
                $localStorage.settings = user.user_settings;
                $sessionStorage.workspace = user.default_workspace_id;
                const { name, email, account_title, metric_system, currency_code, image_url, country, uuid } = user;

                if (!country) {
                  $state.go('auth.onboard', { token: '' });
                }

                window.COUNTRY_CODE = country;
                window.CURRENCY = { code: currency_code, symbol: getSymbolFromCurrency(currency_code) };
                window.METRIC_SYSTEM = metric_system;

                loadGoogleMapsApi({
                  clientId: window.GOOGLE_CLIENT_ID,
                  key: window.GOOGLE_API_KEY,
                  region: window.COUNTRY_CODE,
                  libraries: ['places']
                }).catch(function (error) {
                  console.error(error);
                });

                if (process.env.ENV === 'production') {
                  ActionCableConfig.wsUri = 'wss://api.propmage.com/cable?token=' + uuid;

                  if (window.fcWidget) {
                    window.fcWidget.setExternalId(user.id);
                    window.fcWidget.user.setFirstName(name);
                    window.fcWidget.user.setEmail(email);
                  }

                } else if (process.env.ENV === 'development') {
                  ActionCableConfig.wsUri = 'ws://localhost:9292/cable?token=' + uuid;
                }

                deferred.resolve();
                $sessionStorage.user = user;
                return user;
              }, goToLogin);
            } else {
              goToLogin();
            }
            return deferred.promise;
          }
        },
      })
      .state('main.index', {
        url: '/',
        redirectTo: 'main.dashboard.stats'
      });
  }).name;

