import template from './todo-reminder.pug';

export const TodoReminderComponent = {
  template,
  bindings: {
    reminder: '<',
    onSelect: '&?',
  },
  controller: class TodoReminderComponent {
    constructor(Todo, LocalStorage, $element) {
      'ngInject';
      this.Todo = Todo;
      this.LocalStorage = LocalStorage;
      this.$element = $element;
      this.showReminderOptions = false;

      this.reminderOptions = this.Todo.reminderOptions();
      this.reminderTooltip = 'reminder-tooltip';

      this.showReminderTypeOptions = false;
    }

    $onInit() {
      if (this.reminder == undefined) {
        this.reminder = this.LocalStorage.get('todo', 'reminder') || this.reminderOptions[2];
      }
    }

    hidePopovers() {
      this.showReminderOptions = false;
    }

    selectReminder(reminder) {
      this.reminder = Object.assign(this.reminder, reminder);
      this.LocalStorage.set('todo', 'reminder', reminder);
      this.showReminderOptions = false;
      this.callbacks(this.reminder);
    }

    callbacks(reminder) {
      this.onSelect({
        $event: { reminder }
      });
    }

  }
};
