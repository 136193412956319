import template from './proposal-chronology-form.pug';

export const ProposalChronologyFormComponent = {
  template,
  bindings: {
    item: '<',
    recordClass: '<',
    formState: '<',
    onCreate: '&?',
    onDestroy: '&?'
  },
  controller: class ProposalChronologyFormComponent {
    constructor(Chronology, AlertBox, $async, $log) {
      'ngInject';

      this.Chronology = Chronology;
      this.AlertBox = AlertBox;
      this.$log = $log;

      this.saveChronology = $async(this.saveChronology.bind(this));
      this.destroyRecord = $async(this.destroyRecord.bind(this));
    }

    $onInit() {
      if (this.item.record == undefined) { // in new record case

        this.item.record = {
          notes: ''
        };
        if (this.item.chronology_record.relatedContacts) {
          this.item.record.relatedContacts = this.item.chronology_record.relatedContacts;
        }
        if (this.item.chronology_record.relatedEstates) {
          this.item.record.relatedEstates = this.item.chronology_record.relatedEstates;
        }
        if (this.recordClass == 'contacts') {
          this.item.record.relatedContacts = [this.item.chronology_record];
        }
        if (this.recordClass == 'estates') {
          this.item.record.relatedEstates = [this.item.chronology_record];
        }
      }

    }

    changeState({ state }) {
      if (state) this.formState = state;
    }

    async saveChronology({ multiple_proposal, action }) {
      try {
        if (action == 'create') {
          await this.Chronology.createChild(this.item.chronology_id, {
            record_type: 'MultipleProposal',
            record_id: multiple_proposal.id
          });
        }

        if (action == 'update') {
          this.item.record = multiple_proposal;
          this.changeState({ state: 'show' });
          this.AlertBox.addMessage('alert.updated');
        } else {
          this.changeState({ state: 'edit' });
          this.onCreate({ $event: { action: 'ReloadList' } });
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async destroyRecord() {
      try {
        (this.item);
        await this.Chronology.destroyChild(this.item.id);
        this.onDestroy({ $event: { chronologyItem: this.item } });
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
