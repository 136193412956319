import UserService from './user/user.service';
import EstateService from './estate/estate.service';
import EstateMediaService from './estate/estate-media.service';
import StatisticsService from './statistics/statistics.service';
import InquiryService from './inquiry/inquiry.service';
import TodoService from './todo/todo.service';
import FinanceService from './finance/finance.service';
import DocumentService from './document/document.service';
import WorkspaceService from './workspace/workspace.service';
import ProposalsService from './proposals/proposals.service';
import AssignmentService from './assignment/assignment.service';
import CalendarService from './calendar/calendar.service';
import DashboardService from './dashboard/dashboard.service';

// helpers
import ModalService from './helpers/modal/modal.service';
import HttpService from './helpers/http/http.service';
import AlertBoxService from './helpers/alert/alert-box.service';
import SearchService from './helpers/search/search.service';
import CustomFieldService from './helpers/custom-field/custom-field.service';
import ChronologyService from './helpers/chronology/chronology.service';
import ChronologyListService from './helpers/chronology/chronology-list.service';
import GeocoderService from './helpers/geocoder/geocoder.service';
import NotificationService from './helpers/notification/notification.service';
import SettingsService from './helpers/settings/settings.service';
import DuplicateService from './helpers/duplicate/duplicate.service';
import RoleService from './helpers/role/role.service';
import PermissionService from './helpers/permission/permission.service';
import LocalStorageService from './helpers/localstorage/localstorage.service';
import TableService from './helpers/table/table.service';
import TableEditService from './helpers/table-edit/table-edit.service';
import RecordStatus from './record-status/record-status.service';
import ConnectedRecord from './connected-record/connected-record.service';
import AttachmentService from './attachment/attachment.service';
import ChartService from './chart/chart.service';
import LocationsService from './helpers/locations/locations.service';
import GeopifyService from './helpers/geopify/geopify.service';
import ViewingService from './viewing/viewing.service';


export const ServicesModule = angular.module('topbroker.services', [])
  .service('Assignment', AssignmentService)
  .service('ChartService', ChartService)
  .service('User', UserService)
  .service('Estate', EstateService)
  .service('EstateMedia', EstateMediaService)
  .service('Modal', ModalService)
  .service('Http', HttpService)
  .service('AlertBox', AlertBoxService)
  .service('Search', SearchService)
  .service('CustomField', CustomFieldService)
  .service('Chronology', ChronologyService)
  .service('ChronologyList', ChronologyListService)
  .service('Document', DocumentService)
  .service('Workspace', WorkspaceService)
  .service('Finance', FinanceService)
  .service('Geocoder', GeocoderService)
  .service('Statistics', StatisticsService)
  .service('RecordStatus', RecordStatus)
  .service('ConnectedRecord', ConnectedRecord)
  .service('Inquiry', InquiryService)
  .service('Notification', NotificationService)
  .service('Settings', SettingsService)
  .service('Duplicate', DuplicateService)
  .service('Role', RoleService)
  .service('Permission', PermissionService)
  .service('Todo', TodoService)
  .service('LocalStorage', LocalStorageService)
  .service('Locations', LocationsService)
  .service('Proposal', ProposalsService)
  .service('Table', TableService)
  .service('TableEdit', TableEditService)
  .service('Attachment', AttachmentService)
  .service('Calendar', CalendarService)
  .service('Geopify', GeopifyService)
  .service('Dashboard', DashboardService)
  .service('Viewing', ViewingService)
  .name;


