import template from './contacts.pug';

export const ContactsComponent = {
  template,
  bindings: {},
  controller: class ContactsComponent {
    constructor() {
      'ngInject';
    }
    $onInit() {
    }
  }
};
