import template from './unauthorized-user.pug';

export const UnauthorizedUserComponent = {
  template,
  bindings: {
    owner: '<?',
    searchId: '<?'
  },
  controller: class UnauthorizedUserComponent {
    constructor($stateParams, $state, $window) {
      'ngInject';
      this.$state = $state;
      this.$stateParams = $stateParams;
      this.$window = $window;
    }

    $onInit() {
    }

    back() {
      if (this.$stateParams.backTo) {
        this.$state.go(this.$stateParams.backTo);
      } else {
        this.$window.history.back();
      }

    }
  }
};
