import template from './custom-field-connected-record-form.pug';


export const CustomFieldConnectedRecordFormComponent = {
  template,
  bindings:{
    field: '<',
    group: '<',
    onSave: '&',
    onBack: '&?',
    onClose: '&?',
    loading: '<?'
  },
  controller: class CustomFieldConnectedRecordFormComponent {
    constructor($translate) {
      'ngInject';

      this.selectedTab = 'field';
      this.$translate = $translate;

      this.radioOptions = [
        { value: true, title: 'custom_fields.a_yes' },
        { value: false, title: 'custom_fields.a_no' }
      ];
    }

    $onInit() {

      if (this.field && this.field.id) {
        this.record = this.field;
      } else {
        this.record = {
          validations: {
            required: false,
            multiple_select: false,
            source: this.group.generic_module,
            ...this.field.validations
          }
        };
        this.setTitle();
      }

      if (this.group.generic_module == 'estates') {
        this.tabs = ['field', 'estate_type', 'settings'];
      } else {
        this.tabs = ['field','settings'];
      }
    }

    setTitle() {
      if (this.record.validations.destination) {
        this.record.title = this.$translate.instant(`custom_fields.modules.${this.record.validations.destination}`);
      } else {
        this.record.title = '';
      }
    }

    back() {
      this.onBack({ $event: false });
    }

    close() {
      this.onClose({ $event: null });
    }

    save() {
      this.loading = true;
      this.onSave({ $event: { field: this.record } });
    }

  }
};
