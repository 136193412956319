import template from './estate-copy.pug';

export const EstateCopyComponent = {
  template,
  bindings: {
    resolve: '<',
    modalInstance: '<'
  },
  controller: class EstateCopyComponent {
    constructor($log, $async, Estate) {
      'ngInject';
      this.$log = $log;
      this.Estate = Estate;
      this.save = $async(this.save.bind(this));
    }

    $onInit() {
      this.estate = angular.copy(this.resolve.estate);
    }

    close() {
      this.modalInstance.close(void 0);
    }

    async save({ estate }) {
      this.loading = true;

      try {
        let newParams = this.Estate.serialize(
          {
            estate_type_id: estate.estate_type_id,
            area: estate.area,
            operation_type: estate.operation_type,
            price: estate.price,
            price_type: estate.price_type
          },
          this.Estate.newEstateParams()
        );

        const resp = await this.Estate.copy(estate.id, newParams);

        this.modalInstance.close(resp);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = true;
      }
    }
  }
};
