import template from './inquiry-show.pug';

export const InquiryShowComponent = {
  template,
  bindings: {
    inquiry: '<',
    onEdit: '&?',
    onCopy: '&?',
    onDelete: '&?',
    onUpdate: '&?',
    cfGroups: '<',
  },
  controller: class InquiryShowComponent {
    constructor(Inquiry, Estate, AlertBox, $async, $window) {
      'ngInject';
      this.Inquiry = Inquiry;
      this.Estate = Estate;
      this.AlertBox = AlertBox;
      this.changeRecordStatus = $async(this.changeRecordStatus.bind(this));
      this.currency = $window.CURRENCY;
    }

    $onInit() {
      this.inquiryLocations = [];
      if (this.inquiry) {
        this.Inquiry.find(this.inquiry.id).then((inquiry) => {
          this.inquiry = inquiry;
          this.estateTypeModel = this.Estate.getTypeById(inquiry.estate_type_id).name;
          if (this.inquiry.locations) {
            this.inquiryLocations = this.inquiry.locations.map(elm => elm.name);
          }
        });
      }
    }


    async changeRecordStatus({ status, custom_fields }) {
      try {
        const resp = await this.Inquiry.update(this.inquiry.id, { record_status_id: status.id, custom_fields });
        if (resp && resp.id) {
          this.inquiry = resp;
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    makeCopy() {
      this.onCopy({ $event: true });
    }

    async moveToTrash() {
      const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });
      if (result && result.dismiss) return false;

      this.onDelete({ $event: true });
    }

    update() {
      if (this.onUpdate) {
        this.onUpdate({ $event: {} });
      }
    }

    change({ state }) {
      this.onEdit({
        $event: { state }
      });
    }
  }
};
