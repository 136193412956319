import template from './todos-view-switcher.pug';

export const TodosViewSwitcherComponent = {
  template,
  bindings: {
    activeTab: '@',
  },
  controller: class TodosViewSwitcherComponent {
    constructor($localStorage) {
      'ngInject';
      this.calendarView = $localStorage['calendar.defaultView'];
    }

    $onInit() {
    }
  }
};
