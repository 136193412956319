export default class DayjsFilter {
  constructor() {
    return (input, dayjsFn, ...args) => {
      var dayjsObj = dayjs(input);
      if (input) {
        return dayjsObj[dayjsFn].apply(dayjsObj, args);
      } else {
        return 'N/A';
      }
    };
  }

  static filter() {
    return new DayjsFilter();
  }
}
