// services
import Account from '../../services/account/account.service';
import User from '../../services/user/user.service';

// base component
import { AuthComponent } from './auth.component';
// components
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ReminderComponent } from './reminder/reminder.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { InviteComponent } from './invite/invite.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { AuthTokenComponent } from './auth_token/auth_token.component';
import { OnboardComponent } from './onboard/onboard.component';

export const AuthModule = angular.module('topbroker.auth', [])
  .service('Account', Account)
  .service('User', User)
  .component('auth', AuthComponent)
  .component('authToken', AuthTokenComponent)
  .component('login', LoginComponent)
  .component('register', RegisterComponent)
  .component('reminder', ReminderComponent)
  .component('changePassword', ChangePasswordComponent)
  .component('invite', InviteComponent)
  .component('onboard', OnboardComponent)
  .component('passwordStrength', PasswordStrengthComponent)
  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('auth', {
        abstract: true,
        component: 'auth'
      })
      .state('auth.login', {
        url: '/login',
        component: 'login'
      })
      .state('auth.register', {
        url: '/register',
        component: 'register'
      })
      .state('auth.invite',{
        url: '/invite/:token',
        component: 'invite',
        resolve: {
          invitedUser: (Http, $stateParams) => {
            'ngInject';
            return Http.get('/auth/invitation',{ 'token':$stateParams.token }).then((user) => {
              return user;
            });
          }
        }
      })
      .state('auth.auth_token', {
        url: '/auth_token?token',
        component: 'authToken'
      })
      .state('auth.reminder',{
        url: '/reminder',
        component: 'reminder'
      })
      .state('auth.changePassword',{
        url: '/change_password/:token',
        component: 'changePassword',
        resolve: {
          resetUserParams: (Http, $stateParams) => {
            'ngInject';
            return Http.get('/auth/change_password',{ 'token':$stateParams.token }).then((params) => {
              return params;
            });
          }
        }
      })
      .state('auth.onboard', {
        url: '/onboarding/:token',
        component: 'onboard'
      });
  }).name;

