import template from './account-cancel.pug';

export const AccountCancelComponent = {
  template,
  bindings: {
  },
  controller: class AccountCancelComponent {
    constructor($state, $async, $log, $auth, Account, PermRoleStore, PermPermissionStore) {
      'ngInject';
      this.$state = $state;
      this.$async = $async;
      this.$log = $log;
      this.$auth = $auth;
      this.Account = Account;
      this.PermRoleStore = PermRoleStore;
      this.PermPermissionStore = PermPermissionStore;

      this.getCancelStats =  $async(this.getCancelStats.bind(this));
      this.confirm = $async(this.confirm.bind(this));
      this.securityCode = Math.floor(10000 + Math.random() * 90000);
    }

    $onInit() {
      this.getCancelStats();
    }

    async getCancelStats() {
      try {
        const resp = await this.Account.getCancelStats();
        this.stats = resp;
      } catch (e) {
        this.$log(e);
      }
    }

    cancel() {
      this.$state.go('main.settings.account');
    }

    async confirm() {
      if (this.securityCode != this.checkSecurityCode) return;
      try {
        await this.Account.cancelSubscription();
        this.$auth.logout();
        this.PermRoleStore.clearStore();
        this.PermPermissionStore.clearStore();
        this.$state.go('auth.login');
      } catch (e) {
        this.$log.error(e);
      }
    }

  }
};
