export default class Locations {
  constructor(Http) {
    'ngInject';

    this.Http = Http;
  }

  find(id) {
    return this.Http.get('/locations/' + id);
  }

  update(id, params) {
    return this.Http.put('/locations/' + id, params);
  }

  searchLocation(query) {
    return this.Http.get('/locations/search', { q: query });
  }

  getLocations(ids) {
    return this.Http.get('/locations', { ids: ids });
  }

  find_or_create_location(params) {
    return this.Http.post('/locations/find_or_create', params);
  }

  radiusUnit() {
    return window.METRIC_SYSTEM === 'metric' ? 'kilometres' : 'miles';
  }

}
