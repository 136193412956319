import template from './dashboards-index.pug';

export const DashboardsIndexComponent = {
  template,
  bindings: {},
  controller: class DashboardsIndexComponent {
    constructor(Statistics, Workspace, Dashboard, Estate, Contact, Inquiry, Todo, Finance, Document, $async, $log, $state, $scope) {
      'ngInject';

      this.Statistics = Statistics;
      this.Workspace = Workspace;
      this.Estate = Estate;
      this.Contact = Contact;
      this.Inquiry = Inquiry;
      this.Todo = Todo;
      this.Finance = Finance;
      this.Document = Document;
      this.Dashboard = Dashboard;
      this.$log = $log;
      this.$state = $state;
      this.cardIcons = this.Statistics.cardIcons;

      this.getProjectStats = $async(this.getProjectStats.bind(this));
      this.viewingRegistrations = $async(this.viewingRegistrations.bind(this));
      // this.getApprovalsCount = $async(this.getApprovalsCount.bind(this));

      $scope.$on('chart-create', (evt, chart) =>  this.chartScopes[chart.canvas.id] = chart);
      this.getBlocks = $async(this.getBlocks.bind(this));

      this.datasetOverride = { backgroundColor: ['#61C300', '#EF0808', '#FFBA00', '#3A74B7'] };
      this.chartOptions = {
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false,
          }
        }
      };
      this.selectedTab = 'information';
      this.blockItems = {};
      this.chartScopes = {};
      this.showRecordModal = null;
    }

    $onInit() {
      this.getProjectStats();
      this.getBlocks();
      // this.getApprovalsCount();
      this.viewingRegistrations();
    }

    async viewingRegistrations() {
      try {
        this.viewing_registrations = await this.Statistics.viewingRegistrations();
      } catch (e) {
        this.$log.error(e);
      }
    }

    // async getApprovalsCount() {
    //   try {
    //     const response = await this.DocumentApproval.getApprovalsCount({ workspace_id: this.Workspace.currentWorkspaceId() });
    //     this.pendingDocumentApprovals = response.waiting_for_me;
    //   } catch (e) {
    //     this.$log.log(e);
    //   }
    // }

    async getProjectStats() {
      try {
        this.project = await this.Dashboard.getProject(this.Workspace.currentWorkspaceId());
      } catch (e) {
        this.$log.log(e);
      }
    }


    goToStatistics() {
      this.$state.go('main.statistics.users');
    }

    getColor(blockType, index) {
      const chart = this.chartScopes[`block-chart-${blockType}`];
      const meta = chart.getDatasetMeta(0);

      return meta.data[index].options.backgroundColor;
    }

    goToRecordsList(model) {
      switch (model) {
        case 'estates':
        case 'contacts':
        case 'inquiries':
        case 'todos':
        case 'documents':
          this.$state.go(`main.${model}.list`, { page: 1 });
          break;
        case 'incomes':
        case 'expenses':
          this.$state.go('main.finances.list');
          break;
      }
    }

    reloadPage(event) {
      if (event && event.record && event.record.id) {
        this.$state.reload();
      }
      this.showRecordModal = null;
    }

    async createNewRecord(model) {
      try {
        let resp;

        switch (model) {
          case 'estates':
            resp = null;
            this.showRecordModal = 'estate';
            break;
          case 'contacts':
            resp = await this.Contact.new();
            break;
          case 'inquiries':
            resp = null;
            this.showRecordModal = 'inquiry';
            break;
          case 'todos':
            resp = await this.Todo.newModal();
            break;
          case 'incomes':
            resp = await this.Finance.newModal({ operation_type: 'income', date_at: dayjs().format('YYYY-MM-DD'), custom_fields: {} });
            break;
          case 'expenses':
            resp = await this.Finance.newModal({ operation_type: 'expenses', date_at: dayjs().format('YYYY-MM-DD'), custom_fields: {} });
            break;
          case 'documents':
            resp = await this.Document.newModal();
            break;
        }

        if (resp && resp.id) this.$state.reload();
      } catch (e) {
        this.$log.error(e);
      }
    }

    calcPercentageOfTotal(currentSet, total) {
      let result = (currentSet / total * 100 ).toFixed();

      return result;
    }

    async getBlocks() {
      try {
        this.blockItems = await this.Statistics.blocks({ workspace_id: this.Workspace.currentWorkspaceId() });
      } catch (e) {
        this.$log.log(e);
      }
    }
  }
};
