import template from './webhooks-list.pug';

export const WebhooksListComponent = {
  template,
  bindings: {},
  controller: class WebhooksListComponent {
    constructor(Webhooks, AlertBox, $async) {
      'ngInject';

      this.Webhooks = Webhooks;
      this.AlertBox = AlertBox;

      this.getList = $async(this.getList.bind(this));
      this.toggle = $async(this.toggle.bind(this));
      this.create = $async(this.create.bind(this));
      this.edit = $async(this.edit.bind(this));
      this.delete = $async(this.delete.bind(this));

      this.webhooks = [];
    }

    $onInit() {
      this.getList();
    }

    async getList() {
      try {
        this.webhooks = await this.Webhooks.getList();
      } catch (e) {
        console.error(e);
      }
    }

    async toggle(webhook) {
      try {
        await this.Webhooks.update(webhook.id, { enabled: webhook.enabled });
        this.AlertBox.addMessage('alert.updated', { type: 'success' });
      } catch (e) {
        console.error(e);
      }
    }

    async create() {
      try {
        const resp = await this.Webhooks.editModal();
        if (resp && resp.id) this.webhooks = [...this.webhooks, resp];
        this.AlertBox.addMessage('alert.created', { type: 'success' });
      } catch (e) {
        console.error(e);
      }
    }

    async edit(webhook) {
      try {
        const resp = await this.Webhooks.editModal(webhook);
        if (resp && resp.id) this.getList();
      } catch (e) {
        console.error(e);
      }
    }

    async delete(webhook) {
      try {
        const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });
        if (result && result.dismiss) return false;

        await this.Webhooks.delete(webhook.id);
        this.webhooks = this.webhooks.filter((item) => item.id !== webhook.id);
      } catch (e) {
        console.error(e);
      }
    }
  },
};
