import FormModule from './form/form.module';
import PermissionModule from './permission/permission.module';
import AssignmentModule from './assignment/assignment.module';
import ChronologyModule from './chronology/chronology.module';
import CustomViewModule from './custom-view/custom-view.module';
import DownloadModule from './download/download.module';
import NotificationModule from './notification/notification.module';
import DuplicatesModule from './duplicates/duplicates.module';
import GlobalSearchModule from './global-search/global-search.module';
import ModalModule from './modal/modal.module';
import TableEditModule from './table-edit/table-edit.module';
import SharedSettingsModule from './settings/shared-settings.module';
import RecordStatusModule from './record-status/record-status.module';
import CardsModule from './cards/cards.module';
import PercentageDiffModule from './percentage-diff/percentage-diff.module';

export const SharedModule = angular.module('topbroker.shared', [
  FormModule,
  PermissionModule,
  AssignmentModule,
  GlobalSearchModule,
  ChronologyModule,
  CustomViewModule,
  NotificationModule,
  DuplicatesModule,
  ModalModule,
  TableEditModule,
  DownloadModule,
  SharedSettingsModule,
  RecordStatusModule,
  CardsModule,
  PercentageDiffModule
]).name;
