import template from './radio.pug';

export const RadioComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    model: '<',
    radioValue: '<',
    validations: '<?',
    onEdit: '&?',
  },
  controller: class RadioComponent {
    constructor() {
      'ngInject';
    }
    $onInit() {
    }
    edit(){
      this.onEdit({
        $event: { text: this.radioValue }
      });
    }
  }
};