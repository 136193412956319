import template from './custom-field-toolbar.pug';

export const CustomFieldToolbarComponent = {
  template,
  bindings:{
    name: '<',
    afterSave: '&?'
  },
  controller: class CustomFieldToolbarComponent {
    constructor(CustomField, $async, $log,) {
      'ngInject';
      this.CustomField = CustomField;
      this.$log = $log;
      this.newGroup = $async(this.newGroup.bind(this));
    }

    $onInit() {

    }

    async newGroup() {
      try {
        const group = {
          generic_module: this.name
        };     
        const resp = await this.CustomField.createGroupModal(group);
        if(resp )this.afterSave({$event: {group: resp}});
      } catch (e) {
        this.$log.error(e);
      }
    }


  }
};