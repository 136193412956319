import template from './contact-header.pug';

export const ContactHeaderComponent = {
  template,
  bindings: {
    records: '<',
    checkedIds: '<',
    totalPages: '<',
    totalCount: '<',
    page: '<',
    afterPageChange: '&',
    onFilterChange: '&',
    isModal: '<?',
    onTableEdit: '&?',
    filter: '<?'
  },
  controller: class ContactHeaderComponent {
    constructor(Contact, AlertBox, RecordStatus, Table, $async, $log, $stateParams, $state, $rootScope) {
      'ngInject';
      this.Contact = Contact;
      this.AlertBox = AlertBox;
      this.RecordStatus = RecordStatus;
      this.$stateParams = $stateParams;
      this.$log = $log;
      this.$rootScope = $rootScope;
      this.Table = Table;
      this.$state = $state;
      this.multipleDelete = $async(this.multipleDelete.bind(this));
      this.export = $async(this.export.bind(this));
      this.countRecordStatuses = $async(this.countRecordStatuses.bind(this));
      this.countStatuses = 0;
      this.loader = false;
    }

    $onChanges({ filter } = {}) {
      if (filter && filter.currentValue) {
        Object.keys(this.filter).length > 1 ? this.disabledClearFilter = false : this.disabledClearFilter = true;
      }
    }

    $onInit() {
      this.countRecordStatuses();
    }

    async countRecordStatuses() {
      try {
        const object = await this.RecordStatus.count('contact');
        this.countStatuses = object.count;
      } catch (e) {
        this.$log.error(e);
      }
    }

    async export() {
      try {
        let response = await this.Contact.export(this.$stateParams.searchId, this.filter);
        if (response) this.AlertBox.addMessage('alert.export_table_success', { type: 'success' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async multipleDelete() {
      if (!this.alertIfNotSelected()) return;
      try {
        let object = await this.Contact.multipleDeleteModal(this.checkedIds);
        if (object) {
          this.applyFilter({ searchId: this.$stateParams.searchId });
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    openAll() {
      if (!this.alertIfNotSelected()) return;
      if (this.checkedIds && this.checkedIds.length > 0) {
        this.checkedIds.forEach(id => {
          window.open('/contacts/show/' + id);
        });
      }
    }

    applyPage({ page }) {
      this.afterPageChange({
        $event: { page }
      });
    }

    applyFilter({ searchId }) {
      this.onFilterChange({
        $event: { searchId, filter: { ...this.filter, page: 1 } }
      });
    }

    clearFilters() {
      (this.$rootScope.gridApi['contacts-table'] || this.$rootScope.gridApi['contacts-modal']).grid.clearAllFilters();
    }

    hasActiveFilters() {
      return this.Table.hasActiveFilters('contacts-table');
    }

    alertIfNotSelected() {
      if (!this.checkedIds.length) {
        this.AlertBox.done('alert.select_at_least_one_record', { type: 'info' });
        return false;
      }
      return true;
    }
  }
};
