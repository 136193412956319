import template from './settings-custom-fields-show.pug';

export const SettingsCustomFieldsShowComponent = {
  template,
  bindings: {
    name: '<'
  },
  controller: class SettingsCustomFieldsShowComponent {
    constructor(CustomField, AlertBox, $async, $log) {
      'ngInject';
      this.CustomField = CustomField;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.fetchGroup = $async(this.fetchGroup.bind(this));
      this.editGroupPosition = $async(this.editGroupPosition.bind(this));
      this.newGroup = $async(this.newGroup.bind(this));
    }

    $onInit() {
      this.fetchGroup();
    }

    async fetchGroup() {
      try {
        this.loader = true;
        this.groups = await this.CustomField.loadGroups({ module: this.name, full: 1 });
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    async newGroup() {
      try {
        const group = {
          generic_module: this.name
        };
        const resp = await this.CustomField.createGroupModal(group);
        if (resp) this.addGroup({ group: resp });
      } catch (e) {
        this.$log.error(e);
      }
    }

    addGroup({ group }) {
      this.groups.push(group);
    }

    removeGroup(group) {
      this.groups = this.groups.filter(elm => elm != group);
    }

    async editGroupPosition() {
      try {
        this.CustomField.editGroupPositionModal(this.groups);
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
