import template from './documents-table.pug';
import _merge from 'lodash/merge';

export const DocumentsTableComponent = {
  template,
  bindings: {
    filter: '<',
    onFilterChange: '&',
    onPageChange: '&',
    onCheck: '&?',
    isModal: '&?'
  },
  controller: class DocumentsTableComponent {
    constructor($async, $log, $stateParams, $localStorage, Document, TableEdit, Table) {
      'ngInject';

      this.Document = Document;
      this.TableEdit = TableEdit;
      this.$async = $async;
      this.$log = $log;
      this.$localStorage = $localStorage;
      this.$stateParams = $stateParams;
      this.Table = Table;
      this.getRecords = $async(this.getRecords.bind(this));

      this.checkedIds = [];
      this.totalPages = 0;
      this.totalCount = 0;
      this.Table.activeFilters.loading = true;
      this.isModal = false;
    }

    $onInit() {
      this.gridOptions = this.TableEdit.gridOptions(this.isModal);
      this.gridOptions.columnDefs = this.TableEdit.resolveColumns('document', 'documents', this.isModal);
    }

    $onChanges({ filter } = {}) {
      if (filter && filter.currentValue) {
        this.checkedIds = [];
        if (filter.page) this.filter.page = filter.page;
        this.getRecords();
      }
    }

    updatePage({ page }) {
      if (parseInt(page) > 0) {
        this.onPageChange({ $event: { page } });
        this.checkedRecords([]);
      }
    }

    applyFilter({ filter, searchId }) {
      this.onFilterChange({
        $event: { filter: _merge(this.filter, filter), searchId }
      });
    }

    async getRecords() {
      try {
        if (!this.filter) return;
        if (this.Table.activeFilters.loading) return;

        this.loader = true;
        let searchId = this.$stateParams.searchId || -1;
        if (this.isModal) searchId = -1;
        const { data, meta } = await this.Document.getListBySearchId(searchId, this.filter);
        this.records = data;
        this.totalCount = meta.total_count;
        this.totalPages = meta.pages;

        if (meta.cf_names && meta.cf_names.length) {
          let settings = await this.$localStorage.settings['document']['custom_table_fields'];
          settings.cf_names = meta.cf_names;
          const resp = this.TableEdit.recheckNames(settings, 'document');
          if (resp.update) {
            this.gridOptions.columnDefs = this.TableEdit.resolveColumns(
              'document',
              'documents',
              (this.isModal = 'false')
            );
          }
        }
        meta.options.forEach(element => {
          let field = this.gridOptions.columnDefs.find(elm => elm.field_key === element.technical_name);
          if (!field) return;

          if (element.field_type && element.field_type == 'connected_record') {
            field.filter.validations = element.validations;
          }

          if (element.options.length) {
            field.filter.selectOptions = element.options;
          }
        });
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    checkedRecords({ checkedIds }) {
      this.checkedIds = checkedIds;
      if (this.onCheck) {
        this.onCheck({
          $event: { checkedIds }
        });
      }
    }
  }
};
