import template from './role-edit.pug';

export const RoleEditComponent = {
  template,
  bindings: {
    role: '<',
    onCancel: '&?',
    onSave: '&?',
  },

  controller: class RoleEditComponent {
    constructor($stateParams) {
      'ngInject';
      this.stateParams = $stateParams;
    }

    $onInit() {
      if (this.role == undefined) {
        this.role = {};
      }
      this.temp = angular.copy(this.role);
    }

    cancel() {
      this.role = this.temp;
      this.stateParams.formDirty = false;
      this.onCancel({
        $event: { state: 'show' }
      });
    }

    check() {
      this.stateParams.formDirty = this.temp !== JSON.stringify(this.role);
      this.role = angular.copy(this.role);
    }

    save(role) {
      this.stateParams.formDirty = false;
      this.onSave({
        $event: { role }
      });
    }

  }
};
