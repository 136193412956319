import template from './document-preview.pug';

export const DocumentsPreviewComponent = {
  template,
  bindings: {
    record: '<',
    isVisible: '<',
    onClose: '&',
    afterUpdate: '&?',
  },
  controller: class DocumentsPreviewComponent {
    constructor(Document, $async, $log, AlertBox) {
      'ngInject';

      this.Document = Document;
      this.$log = $log;
      this.AlertBox = AlertBox;

      this.moveToTrash = $async(this.moveToTrash.bind(this));
      this.update = $async(this.update.bind(this));
    }

    $onInit() {
      this.formState = 'show';
    }

    $onChanges({ isVisible }) {
      if (isVisible && isVisible.currentValue) {
        this.changeFormState('show');
      }
    }

    changeFormState(state) {
      if (state == 'edit') {
        this.editRecord = angular.copy(this.record);
      }
      this.formState = state;
    }

    isFormState(state) {
      return this.formState == state;
    }

    destroy() {
      this.close();
      this.afterUpdate({ $event: {} });
    }

    close() {
      this.defaultState = (this.defaultState === 'closed' ? 'show': 'closed');
      this.onClose({ $event: {} });
    }

    async update({ document }) {
      try {
        this.loader = true;
        const resp = await this.Document.update(document);
        if (resp && resp.data && resp.data.id) {
          this.record = resp.data;
          this.AlertBox.addMessage('alert.updated', { type: 'success' });
          this.changeFormState('show');
          this.afterUpdate({ $event: {} });
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;

      }
    }

    async moveToTrash() {
      try {
        await this.Document.moveToTrash(this.record.id);
        this.AlertBox.addMessage('alert.deleted', { type: 'success' });
        this.destroy();
      } catch (e) {
        this.$log.error(e);
      }
    }

  }
};
