class Attachment {
  constructor(Http, Modal, Upload, $state) {
    'ngInject';
    this.Http = Http;
    this.Modal = Modal;
    this.Upload = Upload;
    this.$state = $state;
    this.fileExtensionMap = {
      'docx': 'word',
      'doc': 'word',
      'xls': 'xls',
      'xlsx': 'xls',
      'ppt': 'ppt',
      'pptx': 'ppt',
      'pdf': 'pdf',
      'zip': 'zip',
      'rar': 'rar',
      'png': 'image-file',
      'jpg': 'image-file',
      'avi': 'video-file',
      'mov': 'video-file',
      'jpeg': 'image-file',
      'tif': 'image-file',
      'csv': 'xls',
      'rtf': 'word',
      'ods': 'xls',
      'odt': 'word',
      'docm': 'word',
      'svg': 'image-file'
    };
  }

  extensionIcon(name) {
    const ext = name.toLowerCase().substr(name.toLowerCase().lastIndexOf('.') + 1);
    const icon = this.fileExtensionMap[ext];
    return (icon == undefined) ? 'file' : icon;
  }

  find(id) {
    return this.Http.get('/attachments/' + id);
  }

  download(id) {
    return this.Http.get('/attachments/' + id + '/download');
  }

  bulkDownload(ids = []) {
    return this.Http.post('/attachments/bulk_download', { attachment_ids: ids });
  }

  delete(id) {
    return this.Http.delete('/attachments/'+id);
  }

  report(id) {
    return this.Http.get('/attachments/' + id + '/report');
  }

  downloadModal(attachment_id) {
    return this.Modal.open({
      size: 'md',
      component: 'attachmentDownloadModal',
      resolve: {
        record: () => this.find(attachment_id)
      }
    });
  }

  bulkDownloadModal(attachments = []) {
    return this.Modal.open({
      size: 'md',
      component: 'attachmentBulkDownloadModal',
      resolve: {
        attachments: () => attachments
      }
    });
  }


}
export default Attachment;
