import { AssignmentComponent } from './assignment.component';
import { AssignmentModalComponent } from './modal/assignment-modal.component';

import { ContactAssignmentListItemComponent } from './contact/list/item/contact-assignment-list-item.component';
import { EstateAssignmentListItemComponent } from './estate/list/item/estate-assignment-list-item.component';
import { InquiryAssignmentListItemComponent } from './inquiry/list/item/inquiry-assignment-list-item.component';

import { UserAssignmentListItemComponent } from './user/list/item/user-assignment-list-item.component';

import { OwnerFormComponent } from './owner-form/owner-form.component';

export const AssignmentModule =
  angular.module('topbroker.sharedAssignment', [])
    .component('assignment', AssignmentComponent)
    .component('assignmentModal', AssignmentModalComponent)

    .component('contactAssignmentListItem', ContactAssignmentListItemComponent)
    .component('estateAssignmentListItem', EstateAssignmentListItemComponent)
    .component('inquiryAssignmentListItem', InquiryAssignmentListItemComponent)
    .component('userAssignmentListItem', UserAssignmentListItemComponent)
    .component('ownerForm', OwnerFormComponent)

    .name;

export default AssignmentModule;
