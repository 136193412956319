import template from './record-status-chronology-form.pug';

export const RecordStatusChronologyFormComponent = {
  template,
  bindings: {
    item: '<',
    recordClass: '<?',
  },
  controller: class RecordStatusChronologyFormComponent {
    constructor() {
      'ngInject';

    }

    $onInit() {

    }
  }
};
