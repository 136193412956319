import template from './email.pug';

export const EmailComponent = {
  template,
  bindings: {
    label: '@?',
    placeholder: '@?',
    description: '@?',
    model: '<',
    inputName: '@?',
    validations: '<?',
    onEdit: '&?',
    debounce: '<?'
  },
  transclude: true,
  controller: class EmailComponent {
    constructor() {
      'ngInject';

      this.debounce = 500;
    }

    $onInit() {
    }

    edit(text) {
      this.onEdit({
        $event: { text }
      });
    }

  }
};
