import './extends.js';
import jQuery from 'jquery';
window.$ = jQuery;

import angular from 'angular';
window.angular = angular;

import uiRouter from '@uirouter/angularjs';
import satellizer from 'satellizer';
import angularTranslate from 'angular-translate';
import angularTranslateLoaderUrl from 'angular-translate-loader-url';
import { permission, uiPermission } from 'angular-permission';
import 'ng-file-upload';

import 'angularjs-stripe-elements';

import 'fullcalendar';
import '@fullcalendar/daygrid/main.css';

import 'angularjs-autogrow';

import 'ui-select';
import 'ui-select/dist/select.css';

import Selectize from 'selectize';
import 'selectize/dist/css/selectize.css';
window.Selectize = Selectize;

import '@geoapify/geocoder-autocomplete/styles/minimal.css';
import '@geoapify/geocoder-autocomplete/styles/round-borders.css';

import dayjs from 'dayjs';
import 'dayjs/locale/en';
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.locale('en');
dayjs.extend(isoWeek);
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
window.dayjs = dayjs;
// Dayjs don't have such plugin for duration
const humanizeDuration = require('humanize-duration');
window.humanizeDuration = humanizeDuration;

import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.js';

import hmTouchEvents from 'angular-hammer';

import modal from 'ui-bootstrap4/src/modal';
import tooltip from 'ui-bootstrap4/src/tooltip';
import progressbar from 'ui-bootstrap4/src/progressbar';
import dropdown from 'ui-bootstrap4/src/dropdown';
import popover from 'ui-bootstrap4/src/popover';
import pagination from 'ui-bootstrap4/src/pagination';
import collapse from 'ui-bootstrap4/src/collapse';

import 'timepicker';
import 'timepicker/jquery.timepicker.css';

import 'angular-page-loader/dist/angular-page-loader';

import 'angular-hotkeys-light';
import 'angular-ui-grid/ui-grid.js';
import 'angular-ui-grid/ui-grid.min.css';

import 'quill/dist/quill.js';
import 'quill/dist/quill.core.js';
import 'ng-quill/dist/ng-quill.js';

import 'angular-simple-logger';

import './directives/timepicker.js';
import './directives/connected-record-validation.js';

import './directives/angular-chart.js';
import './directives/gantt.js';
import './directives/count-to.js';

import ngInfiniteScroll from 'ng-infinite-scroll';
import angularAsyncAwait from 'angular-async-await';

import 'angular-gravatar';
import 'angular-sanitize';
import 'angular-filter';

// notification
import 'angular-websocket/dist/angular-websocket.js';
import 'angular-actioncable/dist/angular-actioncable.js';
import './directives/angular-web-notification.js';

import 'intl-tel-input/build/css/intlTelInput.css';
import 'intl-tel-input/build/js/intlTelInput-jquery.js';
import 'intl-tel-input/build/js/utils.js';

import PhotoSwipe from 'photoswipe/dist/photoswipe.js';
window.PhotoSwipe = PhotoSwipe;
import 'photoswipe/dist/photoswipe.css';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default.js';
import 'photoswipe/dist/default-skin/default-skin.css';
window.PhotoSwipeUI_Default = PhotoSwipeUI_Default;

import './directives/photoswipe/photoswipe.js';
import './directives/ngWizard/ngWizard.js';
import './directives/outside-click';
import './directives/outside-click';

import 'flatpickr/dist/themes/airbnb.css';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import './directives/ng-flatpickr.js';
import 'angular-inview/angular-inview.js';

// sortable drag an drop
import 'jquery-ui';
import 'angular-ui-sortable';
import 'angular-animate';

angular.module('ui.timepicker').value('uiTimepickerConfig', {
  step: 30,
  timeFormat: 'H:i',
  asMoment: false,
});
angular.module('infinite-scroll').value('THROTTLE_MILLISECONDS', 250);


export const CommonModule = angular.module('topbroker.common', [
  // ocLazyLoad,
  uiRouter,
  satellizer,
  angularTranslate,
  tooltip,
  modal,
  dropdown,
  collapse,
  popover,
  progressbar,
  pagination,
  ngInfiniteScroll,
  uiPermission,
  permission,
  hmTouchEvents,
  'ngAnimate',
  'angularjs-autogrow',
  'selectize',
  'internationalPhoneNumber',
  require('ngstorage-webpack'),
  'ui.timepicker',
  'ui.gravatar',
  'ngFileUpload',
  'ngSanitize',
  'ui.grid',
  'ui.grid.selection',
  'ui.select',
  'fps.hotkeys',
  'nemLogging',
  'ngPhotoswipe',
  'ngWizard',
  'custom-validators',
  'dhx-gantt',
  'chart.js',
  'countTo',
  'ui.sortable',
  'ui.grid.selection',
  'ui.grid.saveState',
  'ui.grid.moveColumns',
  'ui.grid.pinning',
  'ui.grid.resizeColumns',
  'ui.grid.edit',
  'ui.grid.cellNav',
  'angular-page-loader',
  'ngActionCable',
  'angular-web-notification',
  'angular-flatpickr',
  'outside-click',
  'angular.filter',
  'angular-inview',
  'angularjs-stripe-elements',
  'ngQuill',
  angularAsyncAwait.name,
]).name;
