export default class Duplicate {

  constructor(Http, Modal) {
    'ngInject';
    this.Http = Http;
    this.Modal = Modal;
  }

  showDublicatesDialog({ number, email, dublicates, recordId, recordType }) {
    return this.Modal.open({
      size: 'lg',
      component: 'duplicatesModal',
      resolve: {
        number: function () {
          return number;
        },
        email: function () {
          return email;
        },
        dublicates: function () {
          return dublicates;
        },
        recordId: function() {
          return recordId;
        },
        recordType: function() {
          return recordType;
        }
      }
    });
  }

  getListByPhoneNumber({ number, recordType, recordId }) {
    const param = {
      number,
      source_record_type: recordType,
      source_record_id: recordId
    };
    return this.Http.post('/dublicates/phones', param);
  }

  getListByEmail({ email, recordType, recordId }) {
    const param = {
      email,
      source_record_type: recordType,
      source_record_id: recordId
    };
    return this.Http.post('/dublicates/emails', param);
  }


  makeSharable(type, params) {
    const { checkedIds } = params;
    const param = (() =>  {
      if (type === 'contact') { return { contact_ids: checkedIds }; }
      if (type === 'estate') { return { estate_ids: checkedIds }; }
    })();
    return this.Http.put('/dublicates/make_sharable', param);
  }



}
