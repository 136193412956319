import template from './portal-filters-show.pug';

export const PortalFiltersShowComponent = {
  template,
  bindings:{
    portal: '<',
    onEdit: '&'
  },
  controller: class PortalFiltersShowComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }

    change({ state }) {
      this.onEdit({
        $event: { state }
      });
    }
  }
};
