import template from './connected-record-filter.pug';
import { singular } from 'pluralize';

export const ConnectedRecordFilterComponent = {
  template,
  bindings: {
    type: '@',
    selectedIds: '<',
    onSelect: '&',
    onRemoveFilter: '&',
  },
  controller: class ConnectedRecordFilterComponent {
    constructor($async, $log, Assignment, Estate, Contact, Inquiry, Document) {
      'ngInject';

      this.$log = $log;
      this.Assignment = Assignment;
      this.Estate = Estate;
      this.Contact = Contact;
      this.Inquiry = Inquiry;
      this.Document = Document;
      this.selectedItems = [];

      this.assignmentModal = $async(this.assignmentModal.bind(this));
    }

    $onInit() {
      if (this.selectedIds && this.selectedIds.length > 0) {
        this.getSelectedItems();
      }
    }

    $onChanges({ type, selectedIds }) {
      if (type && typeof type.currentValue == 'string') this.type = singular(type.currentValue).toCamel();
      if (selectedIds && (selectedIds.currentValue == undefined)) this.selectedItems = [];
    }

    async getSelectedItems() {
      try {
        let resp = {};

        switch (this.type) {
          case 'Estate':
            resp = await this.Estate.getListBySearchIdNew(-1, { estate_ids: this.selectedIds });
            this.selectedItems = resp.data;
            break;
          case 'Contact':
            resp = await this.Contact.getListBySearchIdNew(-1, { contact_ids: this.selectedIds });
            break;
          case 'Inquiry':
            resp = await this.Inquiry.getListBySearchIdNew(-1, { inquiry_ids: this.selectedIds });
            break;
          case 'Document':
            resp = await this.Inquiry.getListBySearchId(-1, { document_id: this.selectedIds });
            break;
        }

        if (resp && resp.data) {
          this.selectedItems = resp.data.map(el => el.attributes);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async assignmentModal() {
      try {
        const response = await this.Assignment.selectModal({ type: this.type, multiple: true, alreadySelectedItems: this.selectedItems });

        if (response && response.ids) {
          this.selectedItems = response.items;
          this.onSelect({ $event: response.ids });
        }

      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
