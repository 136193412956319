import template from './settings-estate-types.pug';
import _pick from 'lodash/pick';

export const SettingsEstateTypesComponent = {
  template,
  bindings: {
    group: '<',
  },
  controller: class SettingsEstateTypesComponent {
    constructor(AlertBox, Estate, $log, $async, $scope) {
      'ngInject';

      this.AlertBox = AlertBox;
      this.Estate = Estate;
      this.$log = $log;
      this.$scope = $scope;

      this.getEstateTypes = $async(this.getEstateTypes.bind(this));
      this.updatePosition = $async(this.updatePosition.bind(this));
      this.estateTypesCopy = [];
      this.areaUnits = this.Estate.areaUnitsByMetricSystem();

      this.sortableOptions = {
        handle: '.sortableListHandle',
        tolerance: 'pointer',
        stop: () => {
          this.estateTypes.forEach((value, index) => value.position = index + 1);
        },
        update: (_e, ui) => {
          const position = ui.item.sortable.dropindex + 1;
          const id = ui.item.sortable.model.id;
          this.updatePosition(id, position);
        },
        axis: 'y'
      };
    }

    $onInit() {
      this.getEstateTypes();
      this.$scope.$on(`addEstateType-${this.group.id}`, () => {
        this.newEstateType();
      });
    }

    resolveAreaUnit(areaUnit) {
      return this.Estate.resolveAreaUnit(areaUnit);
    }

    async updatePosition(id, position) {
      try {
        await this.Estate.updateEstateTypes(id, { position: position });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getEstateTypes() {
      try {
        const resp = await this.Estate.getEstateTypes({ estate_type_group_id: this.group.id, with_inactive: true });

        this.estateTypes = resp;
        this.estateTypesCopy = angular.copy(resp);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async newEstateType() {
      try {
        const resp = await this.Estate.typeModal(null, this.group);
        if (resp && resp.id) {
          this.estateTypes.push(resp);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async updateEstateType(type) {
      try {
        const resp = await this.Estate.typeModal(angular.copy(type), this.group);
        if (resp && resp.id) {
          this.estateTypes = this.estateTypes.map(item => item.id === resp.id ? resp : item);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async updateActiveState(type) {
      try {
        const params = this.serializeParams(type);
        const resp = await this.Estate.updateEstateTypes(params.id, params);

        type = resp;
        this.estateTypesCopy = angular.copy(this.estateTypes);
        this.AlertBox.addMessage('alert.updated', { type: 'success' });
      } catch (e) {
        this.estateTypes = angular.copy(this.estateTypesCopy);
        this.AlertBox.addMessage('alert.updated', { type: 'error' });
        this.$log.error(e);
      }
    }

    async deleteEstateType(type) {
      try {
        await this.Estate.deleteEstateType(type.id);

        this.estateTypes = this.estateTypes.filter(item => item.id !== type.id);
        this.AlertBox.addMessage('alert.deleted', { type: 'success' });
      } catch (e) {
        this.AlertBox.addMessage('alert.deleted', { type: 'error' });
        this.$log.error(e);
      }
    }

    serializeParams(type) {
      return _pick(type, ['id', 'name', 'active']);
    }
  }
};
