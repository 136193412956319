import template from './additional-fields-show-form.pug';

export const AdditionaFieldsShowFormComponent = {
  template,
  bindings: {
    inquiry: '<',
    onEdit: '&?',
    onCopy: '&?',
    onDelete: '&?',
    onUpdate: '&?',
    cfGroups: '<',
  },
  controller: class AdditionaFieldsShowFormComponent {
    constructor() {
      'ngInject';
      this.state = 'show';
    }

    $onInit() {
      if (this.inquiry.locations) {
        this.inquiryLocations = this.inquiry.locations.map(elm => elm.name);
      }
    }

    update() {
      if (this.onUpdate) {
        this.onUpdate({ $event: {} });
      }
    }

    change({ state }) {
      this.state = state;
      this.onEdit({
        $event: { state }
      });
    }
  }
};
