export default class stripHtmlFilter {
  constructor() {
    return (text) => {
      return String(text).replace(/<[^>]+>/gm, '');
    };
  }

  static filter() {
    return new stripHtmlFilter();
  }
}
