
import { RoleFormComponent } from './form/role-form.component';
import { RoleEditComponent } from './form/edit/role-edit.component';
import { RoleShowComponent } from './form/show/role-show.component';

import { PermissionCustomViewModalComponent } from './form/permission-custom-view-modal/permission-custom-view-modal.component';

import { RoleNewComponent } from './modal/new/role-new.component';

export const RoleSettingsModule = angular.module('topbroker.settings.role', [])

  .component('roleForm', RoleFormComponent)
  .component('roleEditForm', RoleEditComponent)
  .component('roleShowForm', RoleShowComponent)

  .component('roleNew', RoleNewComponent)

  .component('permissionCustomViewModal', PermissionCustomViewModalComponent)

  .name;
