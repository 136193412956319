import template from './documents-table-header.pug';

export const DocumentsTableHeaderComponent = {
  template,
  bindings: {
    records: '<',
    checkedIds: '<',
    totalPages: '<',
    totalCount: '<',
    page: '<',
    afterPageChange: '&',
    onFilterChange: '&',
    isModal: '<?',
    filter: '<?'
  },
  controller: class DocumentsTableHeaderComponent {
    constructor(Document, AlertBox, Attachment, Table, $async, $log, $stateParams, $state) {
      'ngInject';
      this.Document = Document;
      this.Attachment = Attachment;
      this.AlertBox = AlertBox;
      this.Table = Table;
      this.$stateParams = $stateParams;
      this.$state = $state;
      this.$log = $log;
      this.downloadAll = $async(this.downloadAll.bind(this));
      this.multipleDelete = $async(this.multipleDelete.bind(this));
      this.openCharts = false;
    }

    $onChanges({ filter } = {}) {
      if (filter && filter.currentValue) {
        Object.keys(this.filter).length > 1 ? this.disabledClearFilter = false : this.disabledClearFilter = true;
      }
    }

    $onInit() {}

    async downloadAll() {
      if (!this.alertIfNotSelected()) return;
      try {
        const attachments = await this.Document.getAttachments({ document_id: this.checkedIds } );
        this.Attachment.bulkDownloadModal(attachments);
      } catch (e) {
        this.$log.error(e);
      }

    }

    async multipleDelete() {
      if (!this.alertIfNotSelected()) return;
      try {

        let object = await this.Document.multipleDeleteModal(this.checkedIds);
        if (object) {
          this.applyFilter({ searchId: this.$stateParams.searchId });
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    alertIfNotSelected() {
      if (!this.checkedIds.length) {
        this.AlertBox.done('alert.select_at_least_one_record', { type: 'info' });
        return false;
      }
      return true;
    }

    applyPage({ page }) {
      this.afterPageChange({
        $event: { page }
      });
    }

    applyFilter({ searchId }) {
      this.onFilterChange({
        $event: { searchId }
      });
    }
  }
};
