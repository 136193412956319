import template from './dashboard-project-report.pug';

export const DashboardProjectReportComponent = {
  template,
  bindings: {
    project: '<',
  },
  controller: class DashboardProjectReportComponent {
    constructor(Estate) {
      'ngInject';
      this.Estate = Estate;
      this.currency = window.CURRENCY;
    }

    $onInit() {

      if (this.project) {

        this.datasetOverride = { backgroundColor: [] };
        this.chartOptions = {
          maintainAspectRatio: false,
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map(data => {
                  sum += data;
                });
                let percentage = (value*100 / sum).toFixed(2)+'%';
                return percentage;
              },
              color: '#fff',
            }
          }
        };

        this.area_unit = this.Estate.resolveAreaUnit(this.project.area_unit);

        this.chartData = [];
        this.chartLabels = [];
        this.project.statuses.forEach((status) => {
          this.chartData.push(status.percentage);
          this.chartLabels.push(status.name);
          this.datasetOverride.backgroundColor.push(status.color);
        });
      }
    }

  }
};
