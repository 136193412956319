import template from './user-settings-google.pug';

function popupwindow(url, w, h) {
  var left = screen.width / 2 - w / 2;
  var top = screen.height / 2 - h / 2;
  return window.open(
    url,
    '_blank',
    'toolbar=0,location=0,directories=0,status=0,menubar=0,scrollbars=no,resizable=0,copyhistory=0,width=' +
      w +
      ',height=' +
      h +
      ',top=' +
      top +
      ',left=' +
      left
  );
}

export const UserSettingsGoogleComponent = {
  template,
  bindings: {
    user: '<',
  },
  controller: class UserSettingsGoogleComponent {
    constructor(AlertBox, Settings, Todo, User, Statistics, $log, $window, $async) {
      'ngInject';
      this.User = User;
      this.AlertBox = AlertBox;
      this.Todo = Todo;
      this.Settings = Settings;
      this.Statistics = Statistics;
      this.$log = $log;
      this.$window = $window;
      this.loadGoogleTokenSettings = $async(this.loadGoogleTokenSettings.bind(this));
      this.saveSettings = $async(this.saveSettings.bind(this));
      this.toggleService = $async(this.toggleService.bind(this));
      this.registerGoogleService = $async(this.registerGoogleService.bind(this));
      this.connectGoogle = $async(this.connectGoogle.bind(this));
      this.removeGoogleAccount = $async(this.removeGoogleAccount.bind(this));
      this.openTodoGroupNewModal = $async(this.openTodoGroupNewModal.bind(this));
      this.getTodoGroups = $async(this.getTodoGroups.bind(this));
      this.countContacts = $async(this.countContacts.bind(this));
      const self = this;
      this.contact_count = { my: 0, all: 0 };
      this.google_connected = false;
      this.google_loading = false;

      $window.afterPopupCloses = (token) => {
        self.registerGoogleService(token);
      };
      this.google = {
        settings: {
          calendar: {
            enabled: false,
            sync_type: 'crm_to_google',
          },
          contacts: {
            enabled: false,
            sync_type: 'google_to_crm',
          },
        },
      };
    }

    $onInit() {
      this.loadGoogleTokenSettings();
      this.getTodoGroups();
      this.countContacts();
    }

    async openTodoGroupNewModal() {
      try {
        const resp = await this.Todo.groupModal();
        if (resp && resp.id) {
          this.google.settings.calendar.import_group = resp.id;
          this.getTodoGroups();
          this.saveSettings();
        }
      } catch (e) {
        console.error(e);
      }
    }

    async countContacts() {
      try {
        this.contact_count = await this.Statistics.countContacts();
      } catch (e) {
        console.error(e);
      }
    }

    async getTodoGroups() {
      try {
        this.availableTodoGroups = await this.Todo.getGroups();
      } catch (e) {
        console.error(e);
      }
    }

    async connectGoogle() {
      try {
        this.google_loading = true;
        const token = await this.User.enableGoogleService();
        popupwindow(token.redirect_uri, 500, 500);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async toggleService() {
      try {
        await this.saveSettings();
      } catch (e) {
        this.$log.error(e);
      }
    }

    async registerGoogleService(token) {
      try {
        this.google = await this.User.registerGoogleService(this.user.id, { token });
        this.google_loading = false;
        this.google_connected = true;
      } catch (e) {
        this.$log.error(e);
      }
    }

    async loadGoogleTokenSettings() {
      try {
        this.google = await this.Settings.getGoogleSettings(this.user.id);
        this.google_connected = this.google.enabled;
      } catch (e) {
        this.$log.error(e);
      }
    }

    async saveSettings() {
      try {
        await this.Settings.updateGoogleSettings(this.user.id, this.google.settings);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async removeGoogleAccount() {
      const result = await this.AlertBox.confirm('google.confirm_disconnect', { type: 'warning' });

      if (result && result.value) {
        try {
          await this.User.removeGoogleAccount(this.user.id);
          this.google_connected = false;
          this.google = null;
        } catch (e) {
          this.$log.error(e);
        }
      }
    }
  },
};
