import template from './invite.pug';

export const InviteComponent = {
  template,
  bindings: {
    invitedUser: '<'
  },
  controller: class InviteComponent {
    constructor(User, Permission, $state, $auth, $log, $async) {
      'ngInject';
      this.$state = $state;
      this.$auth = $auth;
      this.$log = $log;
      this.User = User;
      this.Permission = Permission;

      this.register = $async(this.register.bind(this));
    }

    $onInit() {
      this.inviting = false;
      this.passwordStrength = 0;
      this.messages = [];
      if (this.invitedUser) {
        this.user = {
          id: this.invitedUser.id,
          email: this.invitedUser.email,
          invitation_token: this.invitedUser.invitation_token,
          invited_by: this.invitedUser.invited_by
        };
        window.COUNTRY_CODE = this.invitedUser.country;
      } else {
        this.$state.go('auth.login');
      }
    }

    closeMessage(index) {
      this.messages.splice(index, 1);
    }

    validate(error, touched) {
      var valid = (Object.keys(Object(error)).length) && touched;
      return !!valid;
    }

    async register() {
      if (!this.user.password) return;
      this.inviting = true;

      try {
        const user = await this.User.registerByInvite(this.user);
        await this.$auth.setToken(user.token);
        this.Permission.setPermissions();
        this.$state.go('main.dashboard.stats');
      } catch (error) {
        this.messages = ['auth.email_used'];
        this.inviting = false;
        this.user.password = '';
        this.$log.error(error);
      }
    }

  }

};
