import template from './inquiry-matching-form.pug';

export const InquiryMatchingFormComponent = {
  template,
  bindings: {
    item: '<',
    onCreate: '&?'
  },
  controller: class InquiryMatchingFormComponent {
    constructor(Inquiry, Chronology, Estate, ConnectedRecord, $localStorage, $async, $log) {
      'ngInject';
      this.$log = $log;
      this.$async = $async;
      this.Inquiry = Inquiry;
      this.Chronology = Chronology;
      this.Estate = Estate;
      this.ConnectedRecord = ConnectedRecord;
      this.localStorage = $localStorage;
      this.getMatches = $async(this.getMatches.bind(this));
      this.save = $async(this.save.bind(this));
      this.openProposal = $async(this.openProposal.bind(this));
      this.activatePreview = $async(this.activatePreview.bind(this));
      this.isEstateVisible = false;
    }

    $onChanges({ item }={}) {
      if (item && item.currentValue) {
        this.matchedEstates = null;
        this.getMatches();
        this.reload = false;
      }
    }

    $onInit() {

      if (!this.localStorage.proposalEstates) {
        this.localStorage.proposalEstates = {};
      }

      if (this.item) {
        if (!this.localStorage.proposalEstates[this.item.id]) {
          this.localStorage.proposalEstates[this.item.id] = [];
        }
      }

      this.totalPages = 0;
      this.totalCount = 0;
      this.page = 1;
      this.selectedView = 'match';
      this.previewRecord = null;
    }

    async activatePreview(estate) {
      try {
        this.previewRecord = await this.Estate.find(estate.id);
        this.isEstateVisible = true;
      } catch (e) {
        this.$log.error(e);
      }
    }

    closePreview() {
      this.previewRecord = null;
    }

    changePage(event) {
      this.page = event.page;
      if (!this.loader) {
        this.getMatches();
      }
    }

    includedInProposal(estate) {
      return this.localStorage.proposalEstates[this.item.id].some( e => e.id == estate.id );
    }

    countOfIncludedEstates() {
      return this.localStorage.proposalEstates[this.item.id].length;
    }

    hasIncludedEstates() {
      return this.countOfIncludedEstates() > 0;
    }

    resolveAreaUnit(unit) {
      return this.Estate.resolveAreaUnit(unit);
    }

    addToProposal(estate) {
      this.localStorage.proposalEstates[this.item.id].push(estate);

      if (this.isSelectedView('not_match')) {
        this.Inquiry.undoRejectMatchedEstate(this.item.id, estate.id).then(() => {
          this.getMatches();
        });
      }
    }

    removeFromProposal(estate) {
      this.localStorage.proposalEstates[this.item.id] = this.localStorage.proposalEstates[this.item.id].filter(e => e.id != estate.id);
      if (!this.hasIncludedEstates()) {
        this.selectView('match');
      }
    }


    rejectEstate(estate) {
      this.removeFromProposal(estate);
      this.Inquiry.rejectMatchedEstate(this.item.id, estate.id).then(() => {
        this.getMatches();
      });
    }

    isSelectedView(viewName) {
      return this.selectedView == viewName;
    }

    selectView(viewName) {
      this.selectedView = viewName;
      if (!this.isSelectedView('proposal')) {
        this.changePage({ page:1 });
      }
    }

    activeEstateCollection() {
      return this.isSelectedView('proposal') ? this.localStorage.proposalEstates[this.item.id] : this.matchedEstates;
    }

    async openProposal() {
      try {
        const contacts = await this.ConnectedRecord.getContacts({
          source_type: 'Inquiry',
          source_id: this.item.id
        });

        await this.Chronology.openForm({
          record: Object.assign(this.item, {
            relatedContacts: contacts.items,
            relatedEstates: this.localStorage.proposalEstates[this.item.id]
          }),
          selected: 'MultipleProposal',
          recordClass: 'inquiries' });

      } catch (e) {
        this.$log.error(e);
      }
    }

    async getMatches() {
      try {


        if (!parseInt(this.page)) {
          this.page = 1;
        }

        this.loader = true;
        const { data, meta } = await( this.isSelectedView('not_match') ? this.Inquiry.getRejectedMatchedEstates(this.item.id, { page: this.page }) :  this.Inquiry.getMatchedEstates(this.item.id, { page: this.page }) );
        this.loader = false;
        this.totalCount = meta.count;
        this.totalPages = meta.pages;
        this.matchedEstates = data.map((el) => el.attributes);
        this.matchedEstatesCount = meta.count;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    async save() {
      try {
        this.loader = true;
        const params = {
          type: 'MultipleProposal',
          inquiry_id: this.item.id,
          related_estate_ids: this.localStorage.proposalEstates[this.item.id].map(e => e.id),
        };
        const chronologyItem = await this.Chronology.saveChild(this.item.chronology_id, params);
        if (chronologyItem) {
          this.onCreate({ $event: { chronologyItem } });
          this.matchedEstates = [];
          this.localStorage.proposalEstates[this.item.id] = [];
          this.getMatches();
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }
  }
};
