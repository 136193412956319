import template from './form.pug';

export const FormComponent = {
  template,
  bindings: {
    formState: '<?'
  },
  transclude : {
    'showSlot': '?showFormWrapper',
    'editSlot': '?editFormWrapper'
  },

  controller: class FormComponent {
    constructor($stateParams) {
      'ngInject';
      this.stateParams = $stateParams;
    }

    $onInit() {
      if (this.formState === undefined) {
        this.formState = 'show';
      }
    }

    $onChanges({ formState }) {
      if (formState) {
        this.stateParams.stopKeyBinding = formState.currentValue == 'edit' ? true : false;
      }
    }

  }
};
