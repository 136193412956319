
import { TodosComponent } from './todos.component';
import { TodosIndexComponent } from './index/todos-index.component';
import { TodoListComponent } from './list/list.component';
import { TodosCalendarComponent } from './calendar/todos-calendar.component';
import { TodosAboutComponent } from './about/todos-about.component';
import { TodosViewSwitcherComponent } from './shared/switcher/todos-view-switcher.component';
import { TodosViewGroupListComponent } from './shared/group-list/todos-view-group-list.component';
import { TodoSidebarItemComponent } from './sidebar-item/todo-sidebar-item.component';
// Table
import { TodoTableComponent } from './table/table.component';
import { TodoTableHeaderComponent } from './table/header/header.component';
import { TodoTableBodyComponent } from './table/body/body.component';
import { TodoTableTbodyComponent } from './table/body/tbody/tbody.component';

import { TodoPreviewComponent } from './preview/preview.component';
import { TodoModalNewComponent } from './modal/new/new.component';
import { TodoPreviewModalComponent } from './modal/preview/todo-preview.component';
import { TodoUserComponent } from './shared/user/todo-user.component';
import { TodoContactComponent } from './shared/contact/todo-contact.component';
import { TodoEstateComponent } from './shared/estate/todo-estate.component';
import { AttachmentItemComponent } from './shared/attachment/attachment-item.component';
import { TodoReminderComponent } from './shared/reminder/todo-reminder.component';

export const TodosModule = angular.module('topbroker.todos', [])
  .component('todos', TodosComponent)
  .component('todosIndex', TodosIndexComponent)
  .component('todosList', TodoListComponent)
// Table
  .component('todosTable', TodoTableComponent)
  .component('todosTableHeader', TodoTableHeaderComponent)
  .component('todosTableBody', TodoTableBodyComponent)
  .component('todosTableTbody', TodoTableTbodyComponent)

  .component('todosPreview', TodoPreviewComponent)
  .component('todosModalNew', TodoModalNewComponent)
  .component('todosPreviewModal', TodoPreviewModalComponent)
  .component('todosCalendar', TodosCalendarComponent)
  .component('todosAbout', TodosAboutComponent)
  .component('todosViewSwitcher', TodosViewSwitcherComponent)
  .component('todosViewGroupList', TodosViewGroupListComponent)
  .component('todoSidebarItem', TodoSidebarItemComponent)
  .component('todoUser', TodoUserComponent)
  .component('todoContact', TodoContactComponent)
  .component('todoEstate', TodoEstateComponent)
  .component('attachmentItem', AttachmentItemComponent)
  .component('todoReminder', TodoReminderComponent)
  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('main.todos', {
        url: '/todos',
        component: 'todos',
        abstract: true
      })
      .state('main.todos.index', {
        url: '/list/:state/:todo_group_id',
        params: {
          state: {
            value: 'today',
            dynamic: true
          },
          todo_group_id: {
            value: '0',
            dynamic: true
          }
        },
        data: {
          permissions: {
            only: 'can_access_todos',
            redirectTo: 'main.dashboard.stats'
          }
        },
        component: 'todosIndex',

      })
      .state('main.todos.list', {
        url: '/list/:page?:searchId?:userId?:customFieldId?:destinationId?:destinationType',
        params: {
          page: { dynamic: true },
          searchId: { dynamic: true },
          userId: { dynamic: true },
          customFieldId: { dynamic: true },
          destinationId: { dynamic: true },
          destinationType: { dynamic: true },
        },
        component: 'todosList',
        pageTitle: 'todos.todos',
        resolve: {
          filter: ($stateParams) => {
            'ngInject';

            return { page: $stateParams.page };
          }
        }
      })
      .state('main.todos.calendar', {
        url: '/calendar/:defaultView/:group/:date?todoId&copy',
        params: {
          defaultView: {
            value: null,
            dynamic: true
          },
          group: {
            value: null,
            dynamic: true
          },
          date: {
            value: null,
            dynamic: true
          },
          todoId: {
            value: null,
            dynamic: true
          },
          copy: {
            value: null,
            dynamic: true
          }
        },
        data: {
          permissions: {
            only: 'can_access_calendar',
            redirectTo: 'main.dashboard.stats'
          }
        },
        component: 'todosCalendar',

      })
      .state('main.todos.nonexisting', {
        url: '/:id/404',
        params: { backTo: 'main.todos.index' },
        component: 'notFound',
        resolve: { }
      });
  }).name;
