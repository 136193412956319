
import { AttachmentDownloadModalComponent } from './attachment-download-modal/attachment-download-modal.component';
import { AttachmentBulkDownloadModalComponent } from './attachment-bulk-download-modal/attachment-bulk-download-modal.component';
import { BulkRecordDestroyModalComponent } from './bulk-record-destroy-modal/bulk-record-destroy-modal.component';

const ModalModule =
  angular.module('topbroker.modalModule', [])
    .component('attachmentDownloadModal', AttachmentDownloadModalComponent)
    .component('attachmentBulkDownloadModal', AttachmentBulkDownloadModalComponent)
    .component('bulkRecordDestroyModal', BulkRecordDestroyModalComponent)
    .name;

export default ModalModule;
