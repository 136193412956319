import template from './todo-group-modal.pug';

export const TodoGroupModalComponent = {
  template,
  bindings:{
    resolve: '<',
    modalInstance: '<',
  },
  controller: class TodoGroupModalComponent {
    constructor($async, $log, Todo, AlertBox) {
      'ngInject';
      this.Todo = Todo;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.save = $async(this.save.bind(this));
    }

    $onInit() {
      this.group = this.resolve.group || {};
    }


    async save({ group }) {
      try {
        this.loader = true;
        if (group.id) {
          const resp =  await this.Todo.updateGroup(group);
          this.modalInstance.close(resp);
          this.AlertBox.addMessage('alert.updated', { type: 'success' });
        } else {
          const resp =  await this.Todo.createGroup(group);
          this.modalInstance.close(resp);
          this.AlertBox.addMessage('alert.created', { type: 'success' });
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    close() {
      this.modalInstance.close(void(0));
    }

  }
};
