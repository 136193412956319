import template from './estate-location.pug';
import { debounce as _debounce } from 'lodash';


export const EstateLocationComponent = {
  template,
  bindings: {
    estate: '<',
    onUpdate: '&?'
  },
  controller: class EstateLocationComponent {
    constructor(Estate, Locations, Geopify, Account, $async, $log) {
      'ngInject';

      this.Estate = Estate;
      this.Locations = Locations;
      this.Account = Account;
      this.$log = $log;
      this.updateLocation = $async(this.updateLocation.bind(this));
      this.geocodeLocation = $async(this.geocodeLocation.bind(this));
      this.getLocation = $async(this.getLocation.bind(this));
      this.markerDragEnd = $async(this.markerDragEnd.bind(this));
      this.save = $async(this.save.bind(this));
      this.mapZoomEnd = _debounce($async(this.mapZoomEnd.bind(this)), 850);
      this.location = {};
      this.locationTmp = {};
      this.Geopify = Geopify;
      this.loader = false;
    }

    $onInit() {
      this.getLocation();
    }


    $onChanges({ estate }) {
      if (estate && estate.currentValue) {
        this.estate = estate.currentValue;
        this.getLocation();
      }
    }

    async getLocation() {
      try {
        if (this.estate && this.estate.location_id) {
          this.location = await this.Locations.find(this.estate.location_id);
          this.setMarker(this.location);
        } else {
          const countryData = await this.Account.getCountryData();
          const geo = countryData.data.geo;
          this.map.fitBounds([
            [geo.max_longitude, geo.max_latitude],
            [geo.min_longitude, geo.min_latitude],
          ]);
          this.map.setCenter([geo.longitude, geo.latitude]);
          this.map.setZoom(3);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    initMap() {
      this.map = this.Geopify.initMap('map');
      this.map.on('zoomend', () => {
        this.mapZoomEnd();
      });
      this.Geopify.addControls();
      this.getLocation();
    }

    async mapZoomEnd() {
      try {
        const zoom = this.map.getZoom();
        if (this.location && this.estate.location_id) {
          await this.Locations.update(this.estate.location_id, { zoom_level: zoom });
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    locationSelected({ address, place }) {
      this.geocodeLocation({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }, { name: address } ).then(() => {
        this.setMarker(this.location);
      });
    }

    setMarker(location = {}) {
      this.marker = this.Geopify.setMarker(location);
      this.marker.on('dragend', () => {
        this.markerDragEnd();
      });
    }

    async markerDragEnd() {
      try {

        const lngLat = this.marker.getLngLat();
        await this.geocodeLocation(lngLat);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async geocodeLocation(lngLat, overide_loction_params = {}) {
      try {
        const response = await this.Geopify.reverseGeocode(lngLat.lat, lngLat.lng);
        console.log('response', response);
        const result = await response.json();
        console.log('result', result);
        const location_params = this.Geopify.getLocationParams(result.features[0]);
        this.location = await this.Locations.find_or_create_location( { ...location_params, ...overide_loction_params } );
        this.updateLocation();
      }
      catch (e) {
        this.$log.error(e);
      }
    }

    async updateLocation() {
      try {
        await this.Estate.update(this.estate.id, { location_id: this.location.id });
        if (this.onUpdate) this.onUpdate({ $event: { record: this.location } });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async save() {
      try {
        this.loader = true;
        await this.Estate.update(this.estate.id, { location_id: this.location.id });
        await this.Locations.find_or_create_location(this.location).then((location) => {
          this.location = location;
          this.updateLocation(location);
          this.loader = false;
        });
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
