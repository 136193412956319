import template from './finance-group-table.pug';

export const FinanceGroupTableComponent = {
  template,
  bindings: {
    groups: '<'
  },
  controller: class FinanceGroupTableComponent {
    constructor(Finance, $async, $log, AlertBox) {
      'ngInject';
      this.Finance = Finance;
      this.$log = $log;
      this.AlertBox = AlertBox;
      this.updatePosition = $async(this.updatePosition.bind(this));
      this.edit = $async(this.edit.bind(this));
      this.delete= $async(this.delete.bind(this));
      this.fetch = $async(this.fetch.bind(this));


      this.incomeSortableOptions = {
        handle: '.incomeSortableListHandle',
        tolerance: 'pointer',
        stop: () => {
          this.income_groups.forEach((value, index) => {
            value.position = index + 1;
          });
        },
        update: (_e, ui) => {
          const position = ui.item.sortable.dropindex + 1;
          const id = ui.item.sortable.model.id;
          this.updatePosition(id, position);
        },
        axis: 'y'
      };

      this.expensesSortableOptions = {
        handle: '.expensesSortableListHandle',
        tolerance: 'pointer',
        stop: () => {
          this.expenses_groups.forEach((value, index) => {
            value.position = index + 1;
          });
        },
        update: (_e, ui) => {
          const position = ui.item.sortable.dropindex + 1;
          const id = ui.item.sortable.model.id;
          this.updatePosition(id, position);
        },
        axis: 'y'
      };
    }

    $onInit() {
      this.income_groups = [];
      this.expenses_groups = [];
      this.fetch();
    }

    async fetch() {
      try {
        let groups = await this.Finance.getGroups();
        this.income_groups = groups.filter( g => g.operation_type == 'income' );
        this.expenses_groups = groups.filter( g => g.operation_type == 'expenses' );
      } catch (e) {
        this.$log.error(e);
      }
    }

    async updatePosition(id, position) {
      try {
        await this.Finance.updateGroup({ id: id, position: position });
        this.AlertBox.addMessage('alert.updated', { type: 'success' });
      } catch (e) {
        this.AlertBox.addMessage('alert.created', { type: 'error' });
        this.$log.error(e);
      }
    }

    async edit(group) {
      try {
        await this.Finance.groupModal(group);
        this.fetch();
      } catch (e) {
        this.$log.error(e);
      }
    }

    async delete(group) {
      const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });

      if (result && result.value) {
        try {
          await this.Finance.deleteGroup(group.id);
          this.fetch();
        } catch (e) {
          this.$log.error(e);
        }
      }
    }

    async newGroup() {
      try {
        const group = await this.Finance.groupModal();
        if (group) {
          if (group.operation_type == 'income') {
            group.position = this.income_groups.length + 2;
            this.income_groups.push(group);
          } else {
            group.position = this.expenses_groups.length + 2;
            this.expenses_groups.push(group);
          }
        }
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
