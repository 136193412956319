import template from './notify-match.pug';

export const PreviewNotifyMatchComponent = {
  template,
  bindings: {
    notification: '<'
  },
  controller: class PreviewNotifyMatchComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}
  }
};
