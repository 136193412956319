import template from './trashbin-recover-modal.pug';

export const TrashbinRecoverModalComponent = {
  template,
  bindings:{
    resolve: '<',
    modalInstance: '<'
  },
  controller: class TrashbinRecoverModalComponent {
    constructor(User, $log, $async, Contact, Inquiry, Estate, AlertBox ) {
      'ngInject';
      this.AlertBox = AlertBox;
      this.User = User;
      this.Inquiry = Inquiry;
      this.Estate = Estate;
      this.Contact = Contact;
      this.type = {
        key: 'default',
        value: '',
      };
      this.users_selectize = {
        valueField: 'id',
        labelField: 'name',
        maxItems: 1,
        searchField: 'name',
        plugins: []
      };
      this.$log = $log;
      this.save = $async(this.save.bind(this));
    }

    $onInit() {
      this.users = this.resolve.users;
      this.checkedIds = this.resolve.objectIds;
      this.type.value = this.users[0].id;
    }

    async save() {
      try {
        const params = { ...this.type, ids: this.checkedIds };
        this.loader = true;
        if (this.resolve.module == 'Contact') {
          await this.Contact.recover(params);
          this.AlertBox.addMessage('alert.recover.contacts', { type: 'success' });
        } else if (this.resolve.module == 'Inquiry') {
          await this.Inquiry.recover(params);
          this.AlertBox.addMessage('alert.recover.inquiries', { type: 'success' });
        } else if (this.resolve.module == 'Estate') {
          await this.Estate.recover(params);
          this.AlertBox.addMessage('alert.recover.estates', { type: 'success' });

        }
        this.modalInstance.close(true);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    close() {
      this.modalInstance.close(false);
    }

  }
};
