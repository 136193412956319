import template from './user-settings-tables.pug';
import { singular } from 'pluralize';

export const UserSettingsTablesComponent = {
  template,
  bindings: {
    user: '<'
  },
  controller: class UserSettingsTablesComponent {
    constructor(TableEdit, Permission, $async, $auth, $log) {
      'ngInject';

      this.TableEdit = TableEdit;
      this.Permission = Permission;
      this.$log = $log;
      this.$auth = $auth;

      this.editTable = $async(this.editTable.bind(this));
      this.getPermissions = $async(this.getPermissions.bind(this));
      this.tableModules = ['contacts', 'estates', 'inquiries', 'todos', 'finances'];
    }

    $onInit() {
      this.getPermissions();
    }

    async editTable(model) {
      try {
        const translation = ['contacts', 'todos', 'finances'].includes(model) ? model : singular(model);
        await this.TableEdit.editTableModal(singular(model), model, translation, this.user);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getPermissions() {
      try {
        const payload = this.$auth.getPayload();
        const permissions = await this.Permission.getList({ role_id: payload.user.roles });
        const enabledTableModules = permissions.reduce((models, permission) => {
          if (permission.name.startsWith('can_access_') && this.tableModules.includes(permission.scope)) {
            models.push(permission.scope);
          }

          return models;
        } , []);
        this.enabledTableModules = enabledTableModules.sort();
      } catch (e) {
        this.$log.error(e);
      }
    }

  }
};
