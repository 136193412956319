class Calendar {
  constructor(Http, Modal, Workspace) {
    'ngInject';

    this.Http = Http;
    this.Modal = Modal;
    this.Workspace = Workspace;
  }

  navigateToShow(id) {
    this.$state.go('main.todos.show', { id: id });
  }

  getList(params = {}) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.Http.get('/calendars', params);
  }

  getGanttList(params = {}) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.Http.get('/calendars/gantt', params);
  }

  find(id) {
    return this.Http.get(`/calendars/${id}`);
  }

  create(params) {
    return this.Http.post('/calendars', params);
  }

  update(id, params) {
    return this.Http.post(`/calendars/${id}`, params);
  }

  copy(id, params) {
    return this.Http.post(`/calendars/${id}/copy`, params);
  }

  destroy(id) {
    return this.Http.delete(`/calendars/${id}`);
  }

  new() {
    return this.Modal.open({
      size: 'md',
      component: 'calendarNew',
    });
  }

  makeCopy(id) {
    return this.Modal.open({
      size: 'md',
      component: 'calendarNew',
      resolve: {
        calendarId: () => id,
      },
    });
  }

  getAssignedUsers(calendarId) {
    return this.Http.get(`/calendars/${calendarId}/assigned_users`);
  }

  getMilestones(calendarId) {
    return this.Http.get(`/calendars/${calendarId}/milestones`);
  }

  addMilestone(calendarId, params) {
    return this.Http.post(`/calendars/${calendarId}/milestones`, params);
  }

  updateMilestone(calendarId, milestoneId, params) {
    return this.Http.post(`/calendars/${calendarId}/milestones/${milestoneId}`, params);
  }

  removeMilestone(calendarId, milestoneId) {
    return this.Http.delete(`/calendars/${calendarId}/milestones/${milestoneId}`);
  }
}
export default Calendar;
