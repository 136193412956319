
// components
import { RecordStatusTabListComponent } from './tab-list/record-status-tab-list.component';
import { RecordStatusShowComponent } from './show/record-status-show.component';
import { RecordStatusChangeComponent } from './change/record-status-change.component';
import { RecordStatusFieldsModalComponent } from './fields/record-status-fields-modal.component';

const RecordStatusModule = angular.module('topbroker.recordStatus', [])
  .component('recordStatusTabList', RecordStatusTabListComponent)
  .component('recordStatusShow', RecordStatusShowComponent)
  .component('recordStatusChange', RecordStatusChangeComponent)
  .component('recordStatusFieldsModal', RecordStatusFieldsModalComponent)
  .name;

export default RecordStatusModule;
