import template from './proposal-edit-form.pug';

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const ProposalEditFormComponent = {
  template,
  bindings: {
    record: '<',
    formState: '<',
    recordClass: '<?',
    onSave: '&?',
    onDestroy: '&?',
    onClose: '&?'
  },
  controller: class ProposalEditFormComponent {
    constructor(Chronology, AlertBox, Estate, Contact, Proposal, $async, $log, Assignment, $element, $translate, $window) {
      'ngInject';
      this.Chronology = Chronology;
      this.AlertBox = AlertBox;
      this.Estate = Estate;
      this.Contact = Contact;
      this.Proposal = Proposal;
      this.Assignment = Assignment;
      this.$element = $element;
      this.$window = $window;
      this.$log = $log;
      this.$translate = $translate;
      this.assignRelated = $async(this.assignRelated.bind(this));
      this.addEstates = $async(this.addEstates.bind(this));
      this.addContacts = $async(this.addContacts.bind(this));
      this.uploadAttachments = $async(this.uploadAttachments.bind(this));
      this.createBlankMultipleProposal = $async(this.createBlankMultipleProposal.bind(this));
      this.save = $async(this.save.bind(this));
      this.getContacts = $async(this.getContacts.bind(this));
      this.getAttachments = $async(this.getAttachments.bind(this));
      this.getEstates = $async(this.getEstates.bind(this));
      this.updateContact = $async(this.updateContact.bind(this));
      this.estates = [];
      this.contacts = [];
      this.attachments = [];
      this.activeTab = 'estates';
      this.copyUrlTooltip = $translate.instant('general.make_copy');
    }

    $onInit() {
      if (!this.record.id) {
        this.createBlankMultipleProposal();
        this.action = 'create';
      } else {
        this.getContacts();
        this.getAttachments();
        this.getEstates();
        this.setUrl();
        this.action = 'update';
      }
    }

    async createBlankMultipleProposal() {
      try {
        const new_record = await this.Proposal.createBlankRecord({version: 2});
        this.record = Object.assign(new_record, this.record);

        if (this.record.relatedEstates) {
          await this.addEstates(this.record.relatedEstates);
        }
        if (this.record.relatedContacts) {
          await this.addContacts(this.record.relatedContacts);
          await this.getContacts();
        }
        this.setUrl();
        this.activeTab = 'estates';
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getContacts() {
      try {
        this.contacts = await this.Proposal.getContacts(this.record.id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getAttachments() {
      try {
        this.attachments = await this.Proposal.getAttachments(this.record.id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getEstates() {
      try {
        this.estates = await this.Proposal.getProperties(this.record.id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async updateContact(contact) {
      contact.email = contact.new_email;
      try {
        await this.Contact.update(contact.id, { email: contact.email });
      } catch (e) {
        this.$log.error(e);
      }
    }


    async assignRelated(type) {
      let response = await this.Assignment.selectModal({ type: type, multiple: true, modalSize: 'full', alreadySelectedItems: [] });
      if (response && response.items.length) {
        this.assignByType(type, response);
      }
    }

    assignByType(type, response) {
      switch (type) {
        case 'Contact':
          this.addContacts(response.items);
          break;
        case 'Estate':
          this.addEstates(response.items);
          break;
      }
    }

    async uploadAttachments(attachments) {
      try {
        const uploaded = await this.Proposal.addAttachments(this.record.id, attachments);
        this.attachments = [...uploaded.data];
      } catch (e) {
        this.$log.error(e);
      }
    }


    removeAttachment(attachment) {
      this.attachments = this.attachments.filter( a => a != attachment );
      this.Proposal.removeAttachments(this.record.id, [attachment.id]);
    }

    async addEstates(estates) {
      const existIds = this.estates.map(e => e.id);

      try {
        estates.filter(c => existIds.includes(c.id) === false).forEach( (estate) => {
          this.Proposal.addProperty(estate.id, this.record.id);
          this.estates.push(estate);
        });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async addContacts(contacts) {

      const existIds = this.contacts.map(e => e.id);

      try {

        await this.Proposal.addContacts(this.record.id,
          contacts
            .filter(c => existIds.includes(c.id) === false)
            .map( c => { return { contact_id: c.id }; })
        );

        this.contacts = [...new Set([...this.contacts, ...contacts])];
      } catch (e) {
        this.$log.error(e);
      }
    }

    removeContact(contact) {
      this.contacts = this.contacts.filter(u => u.id != contact.id);
      this.Proposal.removeContacts(this.record.id, [contact.id]);
    }

    removeEstate(estate) {
      this.estates = this.estates.filter(u => u.id != estate.id);
      this.Proposal.removeProperty(estate.id, this.record.id);
    }


    async save() {
      try {
        const receivers = this.contacts.filter( c => validateEmail(c.email) ).map( c => c.email );

        if (!receivers.length) {
          this.AlertBox.addMessage('alert.at_least_one_email', { type: 'error' });
          return;
        }

        this.activeTab = 'completed';

        const multiple_proposal = await this.Proposal.update(this.record.id, {
          comment: this.record.comment
        });

        this.onSave({ $event: { multiple_proposal: multiple_proposal, action: this.action } });

      } catch (e) {
        this.$log.error(e);
      }
    }

    setUrl() {
      this.url = this.$window.apProposal + this.record.token;
    }

    open() {
      this.setUrl();
      window.open(this.url);
    }

    copy() {
      this.setUrl();
      const el = document.createElement('textarea');
      el.value = this.url;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.copyUrlTooltip = this.$translate.instant('general.copied');
    }

  }
};
