import template from './group-edit-modal.pug';

export const GroupEditModalComponent = {
  template,
  bindings:{
    resolve: '<',
    modalInstance: '<',
  },
  controller: class GroupEditModalComponent {
    constructor($async, $log, CustomField) {
      'ngInject';
      this.CustomField = CustomField;
      this.$log = $log;
      this.save = $async(this.save.bind(this));
    }

    $onInit() {
      this.groupTmp = angular.copy(this.resolve.group);
      this.group = this.resolve.group;
    }


    async save({ group }) {
      try {
        this.loader = true;
        const resp =  await this.CustomField.editGroup(group);
        this.modalInstance.close(resp);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    close() {
      this.modalInstance.close(this.groupTmp);
    }

  }
};
