import template from './todo-card.pug';

export const TodoCardComponent = {
  template,
  bindings: {
    record: '<?',
    recordId: '<?',
    onRemove: '&?',
  },
  controller: class TodoCardComponent {
    constructor(Todo, $log, $async, $window) {
      'ngInject';

      this.$log = $log;
      this.Todo = Todo;
      this.$window = $window;
      this.getRecord = $async(this.getRecord.bind(this));
      this.getFullRecord = $async(this.getFullRecord.bind(this));
      this.open = $async(this.open.bind(this));
    }

    $onInit() {
      if (this.recordId) this.getRecord();
    }

    async getFullRecord() {
      if (this.fullRecord) return;

      try {
        this.fullRecord = await this.Todo.find(this.record.id);
      } catch (e) {
        this.fullRecord = this.record;
        this.$log.error(e);
      }
    }

    async open() {
      try {
        const resp = await this.Todo.previewModal(this.fullRecord);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getRecord() {
      try {
        this.record = await this.Todo.cardInfo(this.recordId);
      } catch (e) {
        this.$log.error(e);
      }
    }

    removeEnabled() {
      return typeof(this.onRemove) === 'function';
    }

    remove(record) {
      this.onRemove({
        $event: { record }
      });
    }

  }
};
