import dayjs from 'dayjs';
import template from './viewing-registration.pug';

export const ViewingRegistrationComponent = {
  template,
  bindings: {},
  controller: class ViewingRegistrationComponent {
    constructor($stateParams, $state, User, Todo, Viewing, $async, $log) {
      'ngInject';
      this.$stateParams = $stateParams;
      this.$state = $state;
      this.User = User;
      this.Todo = Todo;
      this.Viewing = Viewing;
      this.$log = $log;
      this.getViewingRegistration = $async(this.getViewingRegistration.bind(this));
      this.save = $async(this.save.bind(this));
      this.confirm = $async(this.confirm.bind(this));
      this.editEnabled = false;
      this.loading = false;
      this.confirmed = false;
    }

    $onInit() {
      this.getViewingRegistration(this.$stateParams.registration_id);
    }

    toggleEdit() {
      this.editEnabled = !this.editEnabled;
    }

    setupDatepicker() {

      this.start_date = dayjs(this.viewing_registration.viewing_at).format('YYYY-MM-DD');
      this.start_time = dayjs(this.viewing_registration.viewing_at);

      this.dateOpts = {
        altInput: true,
        altFormat: 'l, M j',
        dateFormat: 'Y-m-d',
        placeholder: '',
        defaultDate: this.start_date
      };

      this.startTimePickerConfig = {
        minTime: '08:00',
        maxTime: '21:00',
        showDuration: false,
        scrollDefault: 'now'
      };
    }

    async getViewingRegistration(registration_id) {
      try {
        this.viewing_registration = await this.Viewing.getViewingRegistration(registration_id);
        console.log(this.viewing_registration);
        this.setupDatepicker();
      } catch (e) {
        this.$log.log(e);
      }
    }

    async save() {
      try {
        this.loading = true;
        const viewing_at = dayjs(this.start_date).hour(dayjs(this.start_time).hour()).minute(dayjs(this.start_time).minute());
        const data = {
          viewing_at: viewing_at.toISOString()
        };
        this.viewing_registration = await this.Viewing.updateViewingRegistration(this.viewing_registration.id, data);
        this.editEnabled = false;
        this.setupDatepicker();
      } catch (e) {
        this.$log.log(e);
      } finally {
        this.loading = false;
      }
    }

    async confirm() {
      try {
        this.loading = true;
        this.confirmed = true;
        if (this.editEnabled) {
          await this.save();
        }
        await this.Viewing.confirmViewingRegistration(this.viewing_registration.id);
      } catch (e) {
        this.$log.log(e);
      } finally {
        this.$state.go('main.estates.show', { id: this.viewing_registration.estate.id });
      }
    }
  }
};
