import template from './price-number-show.pug';

export const PriceNumberShowComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    priceType: '@',
    model: '<',
  },
  controller: class PriceNumberShowComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }

  }
};
