
import { DashboardsComponent } from './dashboards.component';

import { DashboardsIndexComponent } from './index/dashboards-index.component';
import { UserPortfolioComponent } from './user-portfolio/user-portfolio.component';
import { DashboardProjectReportComponent } from './project-report/dashboard-project-report.component';

export const DashboardsModule = angular.module('topbroker.dashboards', [])
  .component('dashboards', DashboardsComponent)
  .component('dashboardsIndex', DashboardsIndexComponent)
  .component('userPortfolio', UserPortfolioComponent)
  .component('dashboardProjectReport', DashboardProjectReportComponent)

  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('main.dashboard', {
        url: '/dashboard',
        abstract: true,
      })

      .state('main.dashboard.stats', {
        url: '/stats',
        component: 'dashboardsIndex',
      });
  }).name;
