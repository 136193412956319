import template from './contact-show.pug';

export const ContactShowComponent = {
  template,
  bindings: {
    contact: '<',
  },
  controller: class ContactShowComponent {
    constructor(Contact, AlertBox, $async, $log, CustomField, Search, $state, Workspace) {
      'ngInject';
      this.Contact = Contact;
      this.Workspace = Workspace;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.update = $async(this.update.bind(this));
      this.CustomField = CustomField;
      this.Search = Search;
      this.$state = $state;
      this.loadCustomFields = $async(this.loadCustomFields.bind(this));
      this.makeCopy = $async(this.makeCopy.bind(this));
      this.moveToTrash = $async(this.moveToTrash.bind(this));
    }

    $onInit() {
      this.Workspace.setWorkspaceOnRecordShowLink(this.contact);
      this.formState = 'show';
      this.loadCustomFields();
    }

    changeFormState(state) {
      this.formState = state;
    }

    isFormState(state) {
      return this.formState == state;
    }

    async loadCustomFields() {
      try {
        this.cfGroups = await this.CustomField.loadGroups({ module: 'contacts' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    updateUser({ user_id }) {
      this.update(this.contact.id, { user_id });
    }

    async update(contact_id, attributes) {
      try {
        this.contact = await this.Contact.update(contact_id, attributes);
        this.AlertBox.addMessage('alert.updated',{ type: 'success' });
        this.changeFormState('show');
      } catch (e) {
        this.$log.error(e);
      }
    }

    updateMainInfo({ contact }) {
      let attributes = this.Contact.serialize(contact);
      this.update(contact.id, attributes);
    }

    async makeCopy() {
      try {
        let contact = await this.Contact.makeCopy(this.contact, this.settings);
        if (contact && contact.id) {
          this.$state.go('main.contacts.show', { id: contact.id });
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async moveToTrash() {
      try {
        await this.Contact.moveToTrash(this.contact.id);
        this.AlertBox.addMessage('alert.deleted',{ type: 'success' });
        let searchId = this.Search.returnLastSearchId('Contact');
        this.$state.go('main.contacts.list', { page: 1, searchId });
      } catch (e) {
        this.$log.error(e);
      }
    }

    assignRecords({ params }) {
      if (this.contact.id) {
        this.update(this.contact.id,params);
      }
    }

    saveOwner({ record, userId }) {
      if (record && record.id) {
        this.update(record.id,{ user_id: userId });
      }
    }
  }
};
