import template from './portal-new.pug';

export const PortalNewComponent = {
  template,
  bindings: {
    resolve: '<',
    modalInstance: '<',
  },
  controller: class PortalNewComponent {
    constructor($async, $log, AlertBox, Portal) {
      'ngInject';

      this.$log = $log;
      this.AlertBox = AlertBox;
      this.Portal = Portal;
      this.save = $async(this.save.bind(this));
      this.getAvailablePortals = $async(this.getAvailablePortals.bind(this));
      this.accountPortal = {};
    }

    $onInit() {
      this.portalType = this.resolve.portalType;
      this.getAvailablePortals();
    }

    close() {
      this.modalInstance.close(void(0));
    }

    async getAvailablePortals() {
      try {
        this.availablePortals = await this.Portal.getAvailablePortals({ data_format: this.portalType });
        if (this.accountPortal.id == undefined && this.availablePortals.length) {
          this.accountPortal.portal_id = this.availablePortals[0].id;
        }
      } catch (e) {
        this.$log.error(e);

      }
    }

    async save() {
      try {
        const resp = await this.Portal.createAccountPortal(this.accountPortal);
        if (resp && resp.id) {
          this.AlertBox.addMessage('alert.created', { type: 'success' });
          this.modalInstance.close(resp);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
