import template from './list.pug';

export const TodoListComponent = {
  template,
  bindings: {
    filter: '<'
  },
  controller: class TodoListComponent {
    constructor($stateParams, $state, User, Todo, Account,  $async, $log) {
      'ngInject';

      this.$stateParams = $stateParams;
      this.$state = $state;
      this.User = User;
      this.Todo = Todo;
      this.$log = $log;
      this.getUser = $async(this.getUser.bind(this));
      this.newTodo = $async(this.newTodo.bind(this));
      this.getAccountStats = $async(this.getAccountStats.bind(this));
      this.Account = Account;
      this.totalRecords = 1;

    }

    $onInit() {
      this.getAccountStats();

      this.filter = angular.copy(this.filter);
      if (this.$stateParams.userId) {
        this.getUser(this.$stateParams.userId);
      }
    }

    async newTodo() {
      try {
        const todo = await this.Todo.newModal();
        if (todo && todo.id) {
          this.updatePage({ page: 1, searchId: this.$stateParams.searchId });
        }
      } catch (e) {
        this.$log.log(e);

      }
    }

    async getUser(userId) {
      try {
        this.user = await this.User.cardInfo(userId);
      } catch (e) {
        this.$log.log(e);
      }
    }

    async getAccountStats() {
      try {
        const response  = await this.Account.getAccountStatsByModule('todo');
        this.totalRecords = response.count;
      } catch (e) {
        this.$log.log(e);
      }
    }

    closeUserPortfolioWidget() {
      this.$state.go('main.todos.list', { page: 1 });
    }

    applyFilterAndResetPage({ filter, searchId }) {
      if (searchId) {
        this.$stateParams.searchId = searchId;
      } else {
        searchId = -1;
      }
      this.applyFilter(filter);
      this.updatePage({ page: filter.page || 1, searchId });
    }

    updatePage({ page, searchId }) {
      searchId = searchId || this.$stateParams.searchId;
      this.$state.go('.', { page, searchId });
      this.filter.page = page;
      this.$onInit();
    }

    applyFilter(filter) {
      if (!{}.hasOwnProperty.call(filter, 'user_id') && this.$stateParams.userId) {
        this.filter = { ...filter, user_id: this.$stateParams.userId, gantt: false } || {};
      } else if (this.$stateParams.customFieldId && this.$stateParams.destinationId && this.$stateParams.destinationType) {
        this.filter = {
          ...filter,
          connected_record: {
            custom_field_id: this.$stateParams.customFieldId,
            destination_id: this.$stateParams.destinationId,
            destination_type: this.$stateParams.destinationType
          },
          gantt: false
        } || {};
      } else {
        this.filter = { ...filter, gantt: false } || {};
      }
    }
  }
};
