import template from './phone-duplicates-card.pug';

export const PhoneDuplicatesCardComponent = {
  template,
  transclude : true,
  bindings: {
    onUpdate: '&?',
    number: '<',
    recordType: '@',
    recordId: '<',
    label: '@?',
    description: '@?',
  },
  controller: class PhoneDuplicatesCardComponent {
    constructor($log, $async, Duplicate) {
      'ngInject';
      this.$log = $log,
      this.Duplicate = Duplicate;
      this.dublicates = [];
      this.dublicateCount = 0;
      this.getDuplicates = $async(this.getDuplicates.bind(this));
    }

    $onChanges({number} = {}) {
      if (number && number.currentValue) {
        this.getDuplicates();
      } else {
        this.number = undefined;
      }
    }

    async getDuplicates() {
      try {
        this.loader = true;
        const { number, recordType, recordId } = this;
        if (!number || !recordType || !recordId) return;
        const response = await this.Duplicate.getListByPhoneNumber({
          number: number.number,
          recordType,
          recordId,
        });
        if (response && response.dublicates) {
          this.dublicates = response.dublicates.data;
          this.dublicateCount = response.dublicates.meta.count;
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    async showModal() {
      try {
        const { number, recordType, recordId, dublicates } = this;
        const params = {
          number,
          recordId,
          recordType,
          dublicates
        };
        let update = await this.Duplicate.showDublicatesDialog(params);
        if (update) {
          if (this.onUpdate) {
            this.onUpdate({$event: {}});
          }
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

  }
};

