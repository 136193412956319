import template from './phone.pug';

export const PhoneComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    model: '<',
    validations: '<?',
    onEdit: '&?',
    onDebounce: '&?',
    debounce: '<?'
  },
  controller: class PhoneComponent {
    constructor($element) {
      'ngInject';
      this.$element = $element;
      this.debounce = 500;
    }

    $onInit() {
      this.setValue();
      if (this.model && (this.model.country || this.model.number)) {
        this.edit(this.model.number);
      }
    }

    edit(text) {
      if (typeof this.onEdit !== 'function') return;

      text = text || this.$element.find('input').intlTelInput('getNumber');
      this.onEdit({
        $event: { text }
      });
    }

    debounceChange() {
      if (typeof this.onDebounce !== 'function') return;

      const text = this.$element.find('input').intlTelInput('getNumber');

      this.onDebounce({
        $event: { text }
      });
    }

    setValue() {
      if (this.model && (('country' in this.model) || ('number' in this.model))) {
        this.phone = angular.copy(this.model.number);
      } else {
        // depricated, but left in case some old fields use it
        this.phone = angular.copy(this.model);
      }
    }

  }
};
