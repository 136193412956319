import template from './inquiries-show.pug';
export const InquiriesShowComponent = {
  template,
  bindings: {
    inquiry: '<'
  },
  controller: class InquiriesShowComponent {
    constructor($state, $async, $stateParams, $log, Inquiry, AlertBox, CustomField, Search, Workspace) {
      'ngInject';
      this.Inquiry = Inquiry;
      this.Workspace = Workspace;
      this.$log = $log;
      this.AlertBox = AlertBox;
      this.update = $async(this.update.bind(this));
      this.CustomField = CustomField;
      this.Search = Search;
      this.$state = $state;
      this.$stateParams = $stateParams;
      this.loadCustomFields = $async(this.loadCustomFields.bind(this));
      this.makeCopy = $async(this.makeCopy.bind(this));
      this.moveToTrash = $async(this.moveToTrash.bind(this));
      this.assignRecords = $async(this.assignRecords.bind(this));
      this.updateUser = $async(this.updateUser.bind(this));
      this.isShowModalVisible = false;
    }

    $onInit() {
      this.Workspace.setWorkspaceOnRecordShowLink(this.inquiry);
      if (this.$stateParams.step) {
        this.openEdit(this.$stateParams.step);
        this.isShowModalVisible = true;
      }
      this.loadCustomFields();
    }

    changeFormState(state) {
      this.formState = state;
    }

    isFormState(state) {
      return this.formState == state;
    }

    async loadCustomFields() {
      try {
        this.cfGroups = await this.CustomField.loadGroups({ module: 'contacts' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    refreshMatch() {
      this.inquiry = angular.copy(this.inquiry);
    }

    openEdit(step) {
      this.$state.go('.', { step: step }, { notify: false, reload: false });
      this.sidebarCurrentStep = step;
      this.isShowModalVisible = true;
    }

    async assignRecords({ params }) {
      if (this.inquiry && this.inquiry.id) {
        this.inquiry = await this.Inquiry.update(this.inquiry.id, params);
        this.AlertBox.addMessage('alert.updated',{ type: 'success' });
      }
    }

    inquiryUpdated({ record }) {
      this.inquiry = {
        ...this.inquiry,
        ...record
      };
    }

    closeInquiryModal({ record }) {
      this.inquiryUpdated({ record });
      this.sidebarCurrentStep = '';
      this.isShowModalVisible = false;
    }

    async updateUser({ user_id }) {
      try {
        this.inquiry = await this.Inquiry.update(this.inquiry.id, { user_id });
        this.AlertBox.addMessage('alert.updated',{ type: 'success' });
      } catch (e) {
        this.$log.error(e);
      }
    }


    async update({ inquiry }) {
      try {
        this.inquiry = await this.Inquiry.update(inquiry.id, this.Inquiry.updateAttributes(inquiry));
        this.AlertBox.addMessage('alert.updated',{ type: 'success' });
        this.changeFormState('show');
      } catch (e) {
        this.$log.error(e);
      }
    }

    async makeCopy() {
      try {
        const inquiry = await this.Inquiry.create(this.Inquiry.updateAttributes(this.inquiry));
        if (inquiry && inquiry.id) {
          this.$state.go('main.inquiries.show', { id: inquiry.id, step: 'estate_type' });
          this.AlertBox.addMessage('alert.created', { type: 'success' });
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async moveToTrash() {
      try {
        await this.Inquiry.moveToTrash(this.inquiry.id);
        if (this.onDestroy) this.onDestroy({ $event: true });
        let searchId = this.Search.returnLastSearchId('Inquiry');
        this.$state.go('main.inquiries.list', { page: 1, searchId });
        this.AlertBox.addMessage('alert.deleted',{ type: 'success' });

      } catch (e) {
        this.$log.error(e);
      }
    }

  }
};
