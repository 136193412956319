import ChronologyTextFilter from './chronology-text.filter';
import ChronologyIconFilter from './chronology-icon.filter';
import MoneyFilter from './money.filter';
import DayjsFilter from './dayjs.filter';
import SecondsFilter from './seconds.filter';
import FileSizeFilter from './file-size.filter';
import stripHtmlFilter from './strip-html.filter';

export const FiltersModule = angular.module('topbroker.filters', [])
  .filter('dayjs', DayjsFilter.filter)
  .filter('chronologyIcon', ChronologyIconFilter.filter)
  .filter('chronologyText', ChronologyTextFilter.filter)
  .filter('money', MoneyFilter.filter)
  .filter('secondsFormat', SecondsFilter.filter)
  .filter('filesize', FileSizeFilter.filter)
  .filter('stripHTML', stripHtmlFilter.filter)
  .name;
