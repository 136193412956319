import template from './menu-item.pug';
export const MenuItemComponent = {
  template,
  bindings: {
    name: '@',
    icon: '@',
    active: '<',
    onlyIcons: '<'
  },
  controller: class MenuItemComponent {
    constructor($state) {
      'ngInject';
      this.$state = $state;
      this.toolTipText = undefined;
    }

    $onInit() {
      if (!window.isMobile() && this.onlyIcons)  this.toolTipText = this.name;
    }

  }

};
