import { plural } from 'pluralize';
import template from './record-status-form.pug';

export const RecordStatusFormComponent = {
  template,
  bindings:{
    status: '<?',
    onSave: '&?',
    onCancel: '&?',
    loading: '<?',
    recordType: '@'
  },
  controller: class RecordStatusFormComponent {
    constructor($async, $log, CustomField) {
      'ngInject';

      this.$log = $log;
      this.CustomField = CustomField;
      this.getCustomFields = $async(this.getCustomFields.bind(this));
    }

    $onInit() {
      this.record_status = this.status;
      this.recordStatusTmp = angular.copy(this.status);
      if (!this.record_status.custom_field_ids) {
        this.record_status.custom_field_ids = [];
      }
      this.getCustomFields();
    }


    save() {
      this.onSave({
        $event: { record_status: this.record_status }
      });
    }

    async getCustomFields() {
      try {
        this.availableCustomFields = await this.CustomField.getFieldsByModelAndType(plural(this.recordType));
        this.selectedCustomFields = this.availableCustomFields.filter(item => this.record_status.custom_field_ids.includes(item.id));
      } catch (e) {
        this.$log.error(e);
      }
    }

    addToList(items) {
      this.record_status.custom_field_ids.push(Number(items));
      this.selectedCustomFields = this.availableCustomFields.filter(item => this.record_status.custom_field_ids.includes(item.id));

    }

    removeFromList(item) {
      this.record_status.custom_field_ids = this.record_status.custom_field_ids.filter(id => item.id != id);
      this.selectedCustomFields = this.availableCustomFields.filter(item => this.record_status.custom_field_ids.includes(item.id));
    }

    cancel() {
      this.record_status = this.recordStatusTmp;
      this.onCancel({
        $event: { state: 'show' }
      });
    }

  }
};
