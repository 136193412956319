import template from './qr-code-icon.pug';

import QRCode from 'qrcode';


export const QrCodeIconComponent = {
  template,
  bindings: {
    value: '<',
  },
  controller: class QrCodeIconComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}

    async generateQRCode() {
      try {
        this.qrCode = await QRCode.toDataURL(this.value);
      } catch (e) {
        console.error(e);
      }
    }
  }
};
