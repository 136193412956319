import template from './finance-preview.pug';

export const FinancesPreviewComponent = {
  template,
  bindings: {
    record: '<',
    isVisible: '<',
    onClose: '&',
    afterUpdate: '&?',
    afterNavigation: '&?'
  },
  controller: class FinancesPreviewComponent {
    constructor(Finance, $async, $log, AlertBox) {
      'ngInject';

      this.Finance = Finance;
      this.$log = $log;
      this.AlertBox = AlertBox;

      this.moveToTrash = $async(this.moveToTrash.bind(this));
    }

    $onInit() {
      this.formState = 'show';
    }

    changeFormState(state) {
      this.formState = state;
    }

    isFormState(state) {
      return this.formState == state;
    }

    destroy() {
      this.close();
      this.afterUpdate({ $event: {} });
    }

    triggerAfterUpdate() {
      this.afterUpdate({ $event: {} });
    }

    close() {
      this.defaultState = (this.defaultState === 'closed' ? 'show': 'closed');
      this.onClose({ $event: {} });
    }

    async update({ finance }) {
      try {
        const updatedFinance = await this.Finance.update(finance.id, finance);

        if (updatedFinance && updatedFinance.id) {
          this.record = updatedFinance;
          this.AlertBox.addMessage('alert.updated', { type: 'success' });
          this.changeFormState('show');
          this.afterUpdate({ $event: {} });
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async moveToTrash() {
      try {
        await this.Finance.moveToTrash(this.record.id);
        this.AlertBox.addMessage('alert.deleted', { type: 'success' });
        this.destroy();
      } catch (e) {
        this.$log.error(e);
      }
    }

  }
};
