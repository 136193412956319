import template from './estates-trashbin-header.pug';

export const EstatesTrashbinHeaderComponent = {
  template,
  bindings: {
    records: '<',
    checkedIds: '<',
    totalPages: '<',
    totalCount: '<',
    page: '<',
    afterPageChange: '&',
    onFilterChange: '&',
    afterRecover: '&',
  },
  controller: class EstatesTrashbinHeaderComponent {
    constructor(Estate, $log, $async) {
      'ngInject';
      this.Estate = Estate;
      this.$log = $log;
      this.recoverAll = $async(this.recoverAll.bind(this));
    }

    $onInit() {
    }

    async recoverAll() {
      try {
        this.loader = true;
        const resp = await this.Estate.recoverModal(this.checkedIds);
        if (resp) this.afterRecover({ $event: true });
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    applyFilter(filter) {
      this.onFilterChange({ $event: { filter } });
    }

    applyPage({ page }) {
      this.afterPageChange({ $event: { page } });
    }
  }

};
