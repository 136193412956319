import template from './todo-reminder.pug';

export const PreviewTodoReminderComponent = {
  template,
  bindings: {
    notification: '<'
  },
  controller: class PreviewTodoReminderComponent {
    constructor(Todo) {
      'ngInject';

      this.Todo = Todo;
    }

    $onInit() {
      this.Todo.getById(this.notification.record_id).then(resp => {
        this.todo = resp;
      });
    }
  }
};
