import template from './workspace-switch-modal.pug';

export const WorkspaceSwitchModalComponent = {
  template,
  bindings: {
    resolve: '<',
    modalInstance: '<'
  },
  controller: class WorkspaceSwitchModalComponent {
    constructor(Workspace, $async, $log, Settings, User, $state, $sessionStorage) {
      'ngInject';

      this.Workspace = Workspace;
      this.Settings = Settings;
      this.User = User;
      this.$log = $log;
      this.$state = $state;
      this.$sessionStorage = $sessionStorage;
      this.fetch = $async(this.fetch.bind(this));
      this.update = $async(this.update.bind(this));
      this.getUserWorkspace = $async(this.getUserWorkspace.bind(this));
    }

    $onInit() {
      this.getUserWorkspace();
      this.fetch();
    }

    async update(workspace) {
      try {
        const resp = await this.Settings.update({ module: 'workspace', key: 'default', value: workspace.id });
        if (resp) {
          this.$sessionStorage.workspace = workspace.id;
          this.modalInstance.close(workspace);
          this.$state.reload();
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getUserWorkspace() {
      try {
        const resp = await this.User.find(this.User.currentId(), { fields: { user: ['id', 'name', 'default_workspace_id'] } });
        this.selectedWorkspace = resp.default_workspace_id;
      } catch (e) {
        this.$log.error(e);
      }
    }

    async fetch() {
      try {
        this.workspaces = await this.Workspace.getGroups();
      } catch (e) {
        this.$log.error(e);
      }
    }

    goToSettings() {
      this.close();
      this.$state.go('main.settings.workspaces');
    }

    close() {
      this.modalInstance.close(void(0));
    }

  }
};
