import angular from 'angular';

import { AuthModule } from './auth/auth.module';
import { LayoutModule } from './layout/layout.module';
import { UsersModule } from './users/users.module';

import { ContactsModule } from './contacts/contacts.module';
import { ChartsModule } from './charts/charts.module';
import { DashboardsModule } from './dashboards/dashboards.module';
import { InquiriesModule } from './inquiries/inquiries.module';
import { EstatesModule } from './estates/estates.module';
import { SettingsModule } from './settings/settings.module';
import { StatisticsModule } from './statistics/statistics.module';
import { TodosModule } from './todos/todos.module';
import { FinancesModule } from './finances/finances.module';
// import { GanttModule } from './gantt/gantt.module';

import { ViewingsModule } from './viewings/viewings.module';

import { DocumentsModule } from './documents/documents.module';
import { ErrorPagesModule } from './error-pages/error-pages.module';
import { TodoContactResponseModule } from './todo-contact-response/todo-contact-response.module';

export const ModulesModule = angular
  .module('topbroker.modules', [
    AuthModule,
    LayoutModule,
    ErrorPagesModule,
    UsersModule,
    ContactsModule,
    ChartsModule,
    DashboardsModule,
    EstatesModule,
    SettingsModule,
    InquiriesModule,
    StatisticsModule,
    DocumentsModule,
    TodosModule,
    FinancesModule,
    ViewingsModule,
    TodoContactResponseModule,
  ])
  .value('EventEmitter', (payload) => ({ $event: payload })).name;
