import template from './settings.pug';

export const SettingsComponent = {
  template,
  bindings: {},
  controller: class SettingsComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {

    }

  }

};
