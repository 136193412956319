import template from './user-form.pug';

export const UserFormComponent = {
  template,
  bindings: {
    user: '<',
    formState: '<',
    onSubmit: '&?',
    onUploadPhoto: '&?',
    onMoveToArchive: '&?',
  },
  controller: class UserFormComponent {
    constructor(User, Modal, AlertBox, CustomField, LocalStorage, $state, $log, $async) {
      'ngInject';
      this.AlertBox = AlertBox;
      this.$state = $state;
      this.LocalStorage = LocalStorage;
      this.$log = $log;
      this.User = User;
      this.Modal = Modal;
      this.CustomField = CustomField;
      this.loadCustomFields = $async(this.loadCustomFields.bind(this));
      this.save = $async(this.save.bind(this));
      this.activateUser = $async(this.activateUser.bind(this));
      this.changePassword = $async(this.changePassword.bind(this));

    }

    $onInit() {
      this.setTemp();
      this.loadCustomFields();
    }

    setTemp() {
      this.tempUser = angular.copy(this.user);
    }

    cancel() {
      this.changeState({ state: 'show' });
      this.onSubmit({ $event: { user: this.user } });
    }

    changeState({ state }) {
      if (state) this.formState = state;
      if (state == 'show') this.user = angular.copy(this.tempUser);
    }

    uploadPhoto({ file }) {
      this.onUploadPhoto({ $event: { file } });
    }

    async changePassword() {
      try {
        const { token } = await this.User.passwordChange(this.user);
        if (token) this.AlertBox.addMessage('alert.updated',{ type: 'success' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async save({ user }) {
      let params = this.User.serialize(user, ['name','email','phone','locale','custom_fields','role_id', 'time_zone']);
      try {
        if (user.image_url && user.image_url.startsWith('data:image')) {
          this.user.image_url = await this.User.photo(this.user, user.image_url);
        }
        this.user = await this.User.update(user.id, params);
        this.setTemp();
        this.changeState({ state: 'show' });

        if (this.onSubmit) {
          this.onSubmit({ $event: { user: this.user } });
        }

      } catch (e) {
        this.$log.error(e);
      }

    }

    async loadCustomFields() {
      try {
        this.cfGroups = await this.CustomField.loadGroups({ module: 'users' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async activateUser() {
      try {
        this.loading = true;
        this.user = await this.User.moveToActive(this.user.id);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;

      }
    }


  }
};
