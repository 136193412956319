class Webhooks {
  constructor(Http, Modal) {
    'ngInject';

    this.Http = Http;
    this.Modal = Modal;
  }

  getList() {
    return this.Http.get('/webhooks');
  }

  create(params = {}) {
    return this.Http.post('/webhooks', params);
  }

  update(id, params) {
    return this.Http.put('/webhooks/' + id, params);
  }

  delete(id) {
    return this.Http.delete('/webhooks/' + id);
  }

  editModal(webhook) {
    return this.Modal.open({
      size: 'md',
      component: 'webhooksEditModal',
      resolve: {
        webhook: () => webhook,
      },
    });
  }
}
export default Webhooks;
