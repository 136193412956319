import template from './finance-group-form.pug';

export const FinanceGroupFormComponent = {
  template,
  bindings:{
    group: '<?',
    onSave: '&?',
    onCancel: '&?'
  },
  controller: class FinanceGroupFormComponent {
    constructor($stateParams) {
      'ngInject';
      this.$stateParams = $stateParams;
    }

    $onInit() {
      if (!this.group.operation_type) {
        this.group.operation_type = 'income';
      }
      this.groupTmp = angular.copy(this.group);
    }

    check() {
      this.$stateParams.formDirty = JSON.stringify(this.groupTmp) !== JSON.stringify(this.group);
      this.group = angular.copy(this.group);
    }

    save() {
      this.$stateParams.formDirty = false;
      this.onSave({
        $event: { group: this.group }
      });
    }

    cancel() {
      this.group = this.groupTmp;
      this.onCancel({
        $event: { state: 'show' }
      });
    }

  }
};
