import template from './time.pug';

export const TimeComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    model: '<',
    validations: '<?',
    onEdit: '&?',
    config: '<?',
  },
  controller: class TimeComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
      if (this.config === undefined) {
        this.config = { showDuration: false };
      }
    }

    edit(time) {
      this.onEdit({
        $event: { time }
      });
    }

  }
};
