import template from './estate-proposal-print-modal.pug';

export const EstateProposalPrintModalComponent = {
  template,
  bindings:{
    resolve: '<',
    modalInstance: '<',
  },
  controller: class EstateProposalPrintModalComponent {
    constructor(Proposal, $async, $window, $sce, $log) {
      'ngInject';
      this.Proposal = Proposal;
      this.$window = $window;
      this.$log = $log;
      this.$sce = $sce;
      this.loadPdf = $async(this.loadPdf.bind(this));
      this.pdf_url;
      this.url;
    }

    $onInit() {
      this.estate  = this.resolve.estate;
      if (this.estate) {
        this.loadPdf();
      }

    }

    close() {
      this.modalInstance.close(void(0));
    }

    async loadPdf() {
      try {
        let proposal = await this.Proposal.createBlankRecord();
        const token = proposal.token;
        await this.Proposal.addProperty(this.estate.id, proposal.id);
        proposal = await this.Proposal.findByToken(proposal.token);
        const estate = proposal.estates[0];
        const proposal_id = proposal.proposals[0].id;
        this.url = this.$window.apProposal + token + '/'+ proposal_id + '/'  + estate.slug;
        this.pdf_url = this.$sce.trustAsResourceUrl(this.url + '/pdf');
      } catch (e) {
        this.$log.error(e);
      }

    }

  }
};
