import template from './portal-form.pug';

export const PortalFormComponent = {
  template,
  bindings: {
    portal: '<',
    formState: '<',
  },
  controller: class PortalFormComponent {
    constructor($async, $log, Portal, AlertBox) {
      'ngInject';
      this.Portal = Portal;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.saveFilter = $async(this.saveFilter.bind(this));
    }

    $onInit() {
      this.tmpPortal = angular.copy(this.portal);
    }

    changeState({ state }) {
      if (state) this.formState = state;
      if (state == 'show') this.portal = angular.copy(this.tmpPortal);
    }


    async saveFilter({ portal }) {
      try {
        this.loading = true;
        let params = this.Portal.serialize(portal, ['data']);
        this.portal = await this.Portal.update(this.portal.id, params);
        this.AlertBox.addMessage('alert.updated',{ type: 'success' });
        this.formState = 'show';
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;
      }
    }

    editSetting() {
      this.editMode = true;
    }
  }
};
