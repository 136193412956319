// base component
import { CustomViewComponent } from './custom-view.component';

// components
import { CustomViewSelectorComponent } from './selector/custom-view-selector.component';
import { CustomViewModalComponent } from './modal/custom-view-modal.component';
import { CustomViewInputComponent } from './input/custom-view-input.component';


const CustomViewModule = angular.module('topbroker.sharedCustomView', [])
  .component('customView', CustomViewComponent)
  .component('customViewSelector', CustomViewSelectorComponent)
  .component('customViewInput', CustomViewInputComponent)
  .component('customViewModal', CustomViewModalComponent)
  .name;

export default CustomViewModule;