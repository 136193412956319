import template from './workspace-group-table.pug';

export const WorkspaceGroupTableComponent = {
  template,
  bindings: {},
  controller: class WorkspaceGroupTableComponent {
    constructor(Workspace, $async, $log, AlertBox, $state) {
      'ngInject';
      this.Workspace = Workspace;
      this.$log = $log;
      this.AlertBox = AlertBox;
      this.$state = $state;
      this.updatePosition = $async(this.updatePosition.bind(this));
      this.edit = $async(this.edit.bind(this));
      this.delete = $async(this.delete.bind(this));
      this.getGroups = $async(this.getGroups.bind(this));


      this.sortableOptions = {
        handle: '.sortableListHandle',
        tolerance: 'pointer',
        stop: () => {
          this.groups.forEach((value, index) => {
            value.position = index + 1;
          });
        },
        update: (_e, ui) => {
          const position = ui.item.sortable.dropindex + 1;
          const id = ui.item.sortable.model.id;
          this.updatePosition(id, position);
        },
        axis: 'y'
      };
    }

    $onInit() {
      this.getGroups();
    }

    getInitials(value) {
      const initials = value ? value.toInitials() : '';
      return initials;
    }

    imageStyle(workspace) {
      if (!workspace) return;

      return { 'background-color': workspace.color };
    }

    async getGroups() {
      try {
        this.groups = await this.Workspace.getGroups(true);

        for (let i = 0; i < this.groups.length; i++) {
          this.groups[i].stats = await this.Workspace.getStats(this.groups[i].id);
        }

      } catch (e) {
        this.$log.error(e);
      }
    }

    async updatePosition(id, position) {
      try {
        await this.Workspace.updateGroup({ id: id, position: position });
        this.AlertBox.addMessage('alert.updated', { type: 'success' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    edit(group) {
      this.$state.go('main.settings.workspaces.show', { id: group.id });
    }

    async delete(group) {
      const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });

      if (result && result.value) {
        try {
          await this.Workspace.deleteGroup(group.id);
          this.getGroups();
        } catch (e) {
          this.$log.error(e);
        }
      }
    }

    async newGroup() {
      try {
        const group = await this.Workspace.groupModal();
        if (group) {
          group.position = this.groups.length + 2;
          this.groups.push(group);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
