import { DocumentsComponent } from './documents.component';
import { DocumentsListComponent } from './list/document-list.component';

import { DocumentsTableComponent } from './table/documents-table.component';
import { DocumentsTableHeaderComponent } from './table/header/documents-table-header.component';
import { DocumentsTableBodyComponent } from './table/body/documents-table-body.component';
import { DocumentsTableTbodyComponent } from './table/body/tbody/documents-table-tbody.component';

import { DocumentsPreviewComponent } from './preview/document-preview.component';
import { DocumentsEditForm } from './form/edit/document-edit.component';
import { DocumentsShowForm } from './form/show/document-show.component';
import { DocumentsNewModalComponent } from './modal/new/document-new.component';
import { DocumentPreviewModalComponent } from './modal/preview/document-preview.component';

export const DocumentsModule = angular
  .module('topbroker.documents', [])
  .component('documents', DocumentsComponent)
  .component('documentsList', DocumentsListComponent)

  .component('documentsTable', DocumentsTableComponent)
  .component('documentsTableHeader', DocumentsTableHeaderComponent)
  .component('documentsTableBody', DocumentsTableBodyComponent)
  .component('documentsTableTbody', DocumentsTableTbodyComponent)

  .component('documentsPreview', DocumentsPreviewComponent)
  .component('documentsEditForm', DocumentsEditForm)
  .component('documentsShowForm', DocumentsShowForm)
  .component('documentsNewModal', DocumentsNewModalComponent)
  .component('documentsPreviewModal', DocumentPreviewModalComponent)

  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('main.documents', {
        url: '/documents',
        component: 'documents',
        abstract: true,
        data: {
          permissions: {
            only: 'can_access_documents',
            redirectTo: 'main.dashboard.stats'
          }
        },
      })
      .state('main.documents.list', {
        url: '/list/:page?:searchId?:customFieldId?:destinationId?:destinationType',
        params: {
          page: { dynamic: true },
          searchId: { dynamic: true },
          customFieldId: { dynamic: true },
          destinationId: { dynamic: true },
          destinationType: { dynamic: true },
        },
        component: 'documentsList',
        pageTitle: 'documents.title',
        resolve: {
          filter: ($stateParams) => {
            'ngInject';

            return { page: $stateParams.page };
          }
        }
      })
      .state('main.documents.nonexisting', {
        url: '/404',
        params: { backTo: 'main.documents.list' },
        component: 'notFound',
        resolve: {},
      });
  }).name;
