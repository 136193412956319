class ConnectedRecord {
  constructor(Http) {
    'ngInject';

    this.Http = Http;
  }

  /**
   *
   * @source_type {string} source_type
   * @source_id {number|string} source_id
   */
  getEstates(source_type, source_id) {
    return this.getDestinationRecords('estates', { source_type, source_id });
  }

  /**
   *
   * @source_type {string} source_type
   * @source_id {number|string} source_id
   */
  getContacts({ source_type, source_id }) {
    return this.getDestinationRecords('contacts', { source_type, source_id });
  }

  /**
   *
   * @source_type {string} source_type
   * @source_id {number|string} source_id
   */
  getInquiries(source_type, source_id) {
    return this.getDestinationRecords('inquiries', { source_type, source_id });
  }


  /**
  *
  * @param {Object} params
  * @param {number|string} params.source_type
  * @param {number|string} params.source_id
  */
  getDestinationRecords(type ,params = {}) {
    return this.Http.get('/connected_records/' + type, params);
  }

  /**
   *
   * @param {Object} params
   * @param {number|string} params.source_id
   * @param {number|string} params.custom_field_id
   */
  getList(params = {}) {
    return this.Http.get('/connected_records', params);
  }

  /**
   *
   * @param {Object} params
   */

  deleteByParams(params) {
    return this.Http.delete('/connected_records', params);
  }

  /**
   *
   * @param {number|string} id - connected_record id
   */
  delete(id) {
    return this.Http.delete('/connected_records/' + id);
  }

  /**
   *
   * @param {Object} params
   * @param {number|string} params.source_id
   * @param {number|string} params.destination_id
   * @param {number|string} params.custom_field_id
   */
  create(params) {
    return this.Http.post('/connected_records/', params);
  }

  /**
   *
   * @param {number|string} source_id
   * @param {Object} recordsToCreate
   */
  batchCreate(source_id, recordsToCreate) {
    const requests = [];

    for (const record in recordsToCreate) {
      const item = recordsToCreate[record];

      item['destination_ids'].forEach(destId => {
        requests.push(this.create({
          source_id: source_id,
          destination_id: destId,
          custom_field_id: item['custom_field_id']
        }));
      });
    }

    return Promise.all(requests);
  }

}

export default ConnectedRecord;
