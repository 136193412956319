import template from './finance-payment-record.pug';

export const FinancePaymentRecordComponent = {
  template,
  bindings: {
    finance: '<',
    record: '<',
    afterUpdate: '&?',
  },
  transclude: true,
  controller: class FinancePaymentRecordComponent {
    constructor(Finance, $async, $log, AlertBox) {
      'ngInject';

      this.Finance = Finance;
      this.$log = $log;
      this.AlertBox = AlertBox;
      this.currency = window.CURRENCY;

      this.moveToTrash = $async(this.moveToTrash.bind(this));
      this.save = $async(this.save.bind(this));
    }

    $onInit() {
      this.state = this.record.id ? 'show' : 'edit';

      this.statuses = [
        { value: 'pending', label: 'Pending' },
        { value: 'paid', label: 'Paid' },
      ];
    }

    isFormState(state) {
      return this.state === state;
    }

    edit() {
      this.temp_record = angular.copy(this.record);
      this.setState('edit');
    }

    cancel() {
      this.record = this.temp_record;
      this.setState('show');
    }

    setState(state) {
      this.state = state;
    }

    async moveToTrash() {
      try {
        const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });
        if (result && result.dismiss) return false;

        await this.Finance.deletePayment(this.finance.id, this.record.id);
        this.AlertBox.addMessage('alert.deleted', { type: 'success' });
        this.afterUpdate({ $event: {} });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async save() {
      try {
        this.loading = true;
        const record =  this.record.id ? await this.Finance.updatePayment(this.finance.id, this.record) : await this.Finance.createPayment(this.finance.id, this.record);
        this.AlertBox.addMessage('alert.updated', { type: 'success' });
        this.setState('show');
        if (this.afterUpdate && typeof this.afterUpdate === 'function') {
          this.afterUpdate({ $event: { record } });
        }
        this.loading = false;
      } catch (e) {
        this.$log.error(e);
      }
    }


  }
};
