class Workspace {
  constructor(Http, Modal, $sessionStorage, $state, $localStorage) {
    'ngInject';

    this.Http = Http;
    this.$sessionStorage = $sessionStorage;
    this.Modal = Modal;
    this.$state = $state;
    this.$localStorage = $localStorage;
  }

  switchModal() {
    return this.Modal.open({
      size: 'md',
      component: 'workspaceSwitchModal',
    });
  }

  groupModal(group) {
    return this.Modal.open({
      size: 'md',
      component: 'workspaceGroupModal',
      resolve: { group: () => group }
    });
  }

  updateRecordWorkspace(recordId, recordModel, workspaceId) {
    return this.Http.put(
      '/workspaces/change_record_workspace',
      { record_id: recordId, record_model: recordModel, workspace_id: workspaceId }
    );
  }

  currentWorkspaceId() {
    return this.$sessionStorage.workspace;
  }

  getWorkspacesCount() {
    return this.$localStorage['workspaces_count'];
  }

  setWorkspaceOnRecordShowLink(record) {
    if (this.$state && this.$state.previous.name == '' && record.workspace_id) {
      this.$sessionStorage.workspace = record.workspace_id;
    }
  }

  find(id) {
    return this.Http.get('/workspaces/' + id);
  }

  getStats(id) {
    return this.Http.get('/workspaces/' + id + '/stats');
  }

  deleteGroup(id) {
    return this.Http.delete('/workspaces/' + id);
  }

  getGroups(full_list = false) {
    return this.Http.get('/workspaces', { full_list });
  }

  updateGroup(params) {
    return this.Http.put('/workspaces/' + params.id,
      {
        title: params.title,
        color: params.color,
        position: params. position
      } );
  }

  createGroup(params) {
    return this.Http.post('/workspaces/', params);
  }

  watermark(workspace, watermark) {
    return this.Http.post('/workspaces/' + workspace.id + '/watermark', { watermark: watermark });
  }

  watermarkGravity(workspace) {
    return this.Http.post('/workspaces/' + workspace.id + '/watermark_gravity', { watermark_gravity: workspace.watermark_gravity });
  }

  updatePermission(params) {
    return this.Http.put('/workspaces/permissions', params);
  }

  enabledPermissions(params) {
    return this.Http.get('/workspaces/permissions', params);
  }

  removeLogo(id) {
    return this.Http.delete('/workspaces/' + id + '/logo');
  }

  removeWatermark(id) {
    return this.Http.delete('/workspaces/' + id + '/watermark');
  }

}
export default Workspace;
