import template from './portals-list.pug';

export const PortalsListComponent = {
  template,
  bindings: {
    type: '<',
  },
  controller: class PortalsListComponent {
    constructor(Portal, AlertBox, $state, $log, $async, $window) {
      'ngInject';
      this.Portal = Portal;
      this.AlertBox = AlertBox;
      this.$state = $state;
      this.$log = $log;
      this.$window = $window;
      this.getPortals = $async(this.getPortals.bind(this));
      this.toggle = $async(this.toggle.bind(this));
      this.createNew = $async(this.createNew.bind(this));
      this.deletePortal = $async(this.deletePortal.bind(this));
      this.portals = [];
    }

    $onInit() {
      this.getPortals();
    }

    open(url) {
      this.$window.open(url, '_blank');
    }

    show(portal_id) {
      this.$state.go('main.settings.portals.show', { id: portal_id });
    }

    async getPortals() {
      try {
        this.portals = await this.Portal.getList({ data_format: this.type });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async toggle(portal) {
      try {
        this.loading = true;
        if (portal.on) {
          await this.Portal.off(portal.id);
          this.AlertBox.addMessage('alert.updated',{ type: 'success' });
        } else {
          await this.Portal.on(portal.id);
          this.AlertBox.addMessage('alert.updated',{ type: 'success' });
        }
        portal.on = !portal.on;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;
      }
    }

    async deletePortal(portal) {
      try {
        const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });
        if (result && result.dismiss) return false;

        await this.Portal.deleteAccountPortal(portal.id);
        this.portals = this.portals.filter(item => item.id !== portal.id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async createNew() {
      try {
        const resp = await this.Portal.newAccountPortalModal(this.type);
        if (resp && resp.id) this.getPortals();
      } catch (e) {
        this.$log.error(e);
      }
    }
  }

};
