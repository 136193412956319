import template from './card.pug';

export const CardComponent = {
  template,
  bindings: {
  },
  transclude : true,
  controller: class CardComponent {
    constructor($state) {
      'ngInject';
      this.$state = $state;
    }

    $onInit() {
    }

  }
};
