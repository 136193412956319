// table
import { EstateTableComponent } from './table/estate-table.component';
import { EstateTbodyComponent } from './table/body/tbody/estate-tbody.component';
import { EstateBodyComponent } from './table/body/estate-body.component';
import { EstateHeaderComponent } from './table/header/estate-header.component';

// toolbar
import { EstateToolbarComponent } from './toolbar/estate-toolbar.component';

// form
import { EstateEditComponent } from './form/edit/estate-edit.component';
import { EstateShowComponent } from './form/show/estate-show.component';

// photos form
import { EstatePhotosEditComponent } from './form/photos/edit/estate-photos-edit.component';

// Account custom estate type
import { EstateTypeCustomFormComponent } from './form/parameters/custom-type/estate-type-custom-form.component';
import { EstateTypeCustomEditComponent } from './form/parameters/custom-type/edit/estate-type-custom-edit.component';
import { EstateTypeCustomShowComponent } from './form/parameters/custom-type/show/estate-type-custom-show.component';

// new
import { EstateMdCardComponent } from './estate-md-card/estate-md-card.component';

// copy
import { EstateCopyComponent } from './copy/estate-copy.component';

// places
import { EstateLocationComponent } from './location/estate-location.component';

// preview
import { EstateModalPreviewModalComponent } from './modal/preview/estate-modal-preview.component';

// estate price input
import { EstatePriceNumberComponent } from './input/price/estate-price-number.component';
import { EstateTypeModalComponent } from './estate-type/modal/estate-type.component';
import { EstateProposalPrintModalComponent } from './modal/proposal-print/estate-proposal-print-modal.component';

// media
import { EstateMediaList } from './media/list/estate-media-list.component';
import { EstateRecordModalComponent } from './record-modal/record-modal.component';
import { PhotosDownloadStyleModalComponent } from './form/photos/photos-download-style-modal/photos-download-style-modal.component';

export const EstateModule = angular
  .module('topbroker.estate', [])
  .component('estateToolbar', EstateToolbarComponent)
  .component('estateProposalPrintModal', EstateProposalPrintModalComponent)
  .component('estateTable', EstateTableComponent)
  .component('estateHeader', EstateHeaderComponent)
  .component('estateBody', EstateBodyComponent)
  .component('estateTbody', EstateTbodyComponent)
  .component('esatePreviewModal', EstateModalPreviewModalComponent)
  .component('estateEditForm', EstateEditComponent)
  .component('estateShowForm', EstateShowComponent)
  .component('estatePhotosEditForm', EstatePhotosEditComponent)
  .component('photosDownloadStyleModal', PhotosDownloadStyleModalComponent)
  .component('estateTypeCustomForm', EstateTypeCustomFormComponent)
  .component('estateTypeCustomEditForm', EstateTypeCustomEditComponent)
  .component('estateTypeCustomShowForm', EstateTypeCustomShowComponent)
  .component('estatePriceNumber', EstatePriceNumberComponent)
  .component('estateMdCard', EstateMdCardComponent)
  .component('estateCopy', EstateCopyComponent)
  .component('estateLocation', EstateLocationComponent)
  .component('estateTypeModal', EstateTypeModalComponent)
  .component('estateMediaList', EstateMediaList)
  .component('estateRecordModal', EstateRecordModalComponent).name;
