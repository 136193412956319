// New table
import { ContactTableComponent } from './table/contact-table.component';
import { ContactTbodyComponent } from './table/body/tbody/contact-tbody.component';
import { ContactBodyComponent } from './table/body/contact-body.component';
import { ContactHeaderComponent } from './table/header/contact-header.component';

// toolbar
import { ContactToolbarComponent } from './toolbar/contact-toolbar.component';

// form
import { ContactEditFormComponent } from './form/edit/contact-edit-form.component';
import { ContactShowFormComponent } from './form/show/contact-show-form.component';

// new
import { ContactNewComponent } from './new/contact-new.component';

// preview
import { ContactPreviewComponent } from './preview/contact-preview.component';

// import
import { ContactImportComponent } from './import/contact-import.component';
import { ContactImportProgressComponent } from './modal/import-progress/contact-import-progress.component';

import { ContactModalPreviewModalComponent } from './modal/preview/contact-modal-preview.component';

export const ContactModule = angular.module('topbroker.contact', [])
  .component('contactToolbar', ContactToolbarComponent)

  .component('contactTable', ContactTableComponent)
  .component('contactHeader', ContactHeaderComponent)
  .component('contactBody', ContactBodyComponent)
  .component('contactTbody', ContactTbodyComponent)

  .component('contactEditForm', ContactEditFormComponent)
  .component('contactShowForm', ContactShowFormComponent)
  .component('contactPreviewModal', ContactModalPreviewModalComponent)
  .component('contactNew', ContactNewComponent)
  .component('contactPreview', ContactPreviewComponent)
  .component('contactImport', ContactImportComponent)
  .component('contactImportProgress', ContactImportProgressComponent)
  .name;
