import template from './custom-field-form-select.pug';

export const CustomFieldFormSelectComponent = {
  template,
  bindings:{
    field: '<',
    onSave: '&?',
    onBack: '&?',
    loading: '<?',
    onClose: '&?',
    group: '<?'
  },
  controller: class CustomFieldFormSelectComponent {
    constructor(CustomField) {
      'ngInject';
      this.CustomField = CustomField;
    }

    $onInit() {
      if (!this.field.id) {
        this.field = { ...this.field };
      }
      this.type = this.field.field_type;
    }

    save({ field }) {
      const params = {
        ...field,
        field_type: this.type,
      };
      this.onSave({ $event: { field: params } });
    }

    back(event) {
      this.onBack({ $event: event });
    }
  }
};
