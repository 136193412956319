// services
import Estate from '../../services/estate/estate.service';
// helpers

// base component
import { EstatesComponent } from './estates.component';
// components
import { EstateListComponent } from './list/estate-list.component';
import { EstateShowComponent } from './show/estate-show.component';
import { EstateTopbarComponent } from './topbar/estate-topbar.component';

export const EstatesModule = angular.module('topbroker.estates', [])
  .service('Estate', Estate)
  .component('estates', EstatesComponent)
  .component('estateList', EstateListComponent)
  .component('estateShow', EstateShowComponent)
  .component('estateTopbar', EstateTopbarComponent)

  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('main.estates', {
        url: '/estates',
        component: 'estates',
        abstract: true,
        data: {
          permissions: {
            only: 'can_access_estates',
            redirectTo: 'main.dashboard.stats'
          }
        },

      })
      .state('main.estates.nonexisting', {
        url: '/show/:id/404',
        params: { backTo: 'main.estates.list' },
        component: 'notFound',
        resolve: {
          searchId:(Search) => {
            'ngInject';
            return Search.returnLastSearchId('Estate');
          },
        }
      })


      .state('main.estates.unauthorized', {
        url: '/show/:id/unauthorized',
        component: 'unauthorized',
        params: { backTo: 'main.estates.list' },
        resolve: {
          searchId: (Search) => {
            'ngInject';
            return Search.returnLastSearchId('Estate');
          },
          recordAttributes: ($stateParams) => {
            'ngInject';
            return { type: 'estate', id: $stateParams.id };
          }
        }
      })

      .state('main.estates.list', {
        url: '/list/:page?:searchId?:statusId?:customFieldId?:destinationId?:destinationType',
        params: {
          page: { dynamic: true },
          searchId: { dynamic: true },
          statusId: { dynamic: true },
          customFieldId: { dynamic: true },
          destinationId: { dynamic: true },
          destinationType: { dynamic: true },
        },
        component: 'estateList',
        resolve: {
          filter: ($stateParams) => {
            'ngInject';
            let filter = {};
            filter.page = $stateParams.page;
            return filter;
          }
        }
      })
      .state('main.estates.user-list', {
        url: '/nt-objektai/:user_id/:page?:searchId?:statusId',
        params: { page: { dynamic: true }, searchId: { dynamic: true }, statusId: { dynamic: true } },
        component: 'estateList',
        resolve: {
          filter: ($stateParams) => {
            'ngInject';
            let filter = {};
            filter.user_id = $stateParams.user_id;
            filter.page = $stateParams.page;
            return filter;
          }
        }
      })


      .state('main.estates.show', {
        url: '/show/:id?step',
        component: 'estateShow',
        params: {
          step: {
            value: null,
            dynamic: true
          }
        },
        resolve: {
          estate: ($stateParams, Estate, $state) => {
            'ngInject';
            let response = Estate.find($stateParams.id).then( record => {
              return record;
            }, () => {
              $state.go('main.estates.unauthorized', {
                id: $stateParams.id,
                user: null,
              });
            } );
            return response;
          }
        }
      });

  }).name;
