import template from './estate-price-change-chronology-form.pug';

export const EstatePriceChangeChronologyFormComponent = {
  template,
  bindings: {
    item: '<',
    recordClass: '<?',
  },
  controller: class EstatePriceChangeChronologyFormComponent {
    constructor() {
      'ngInject';

    }

    $onInit() {
    }
  }
};
