import template from './radio-show.pug';

export const RadioShowComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    model: '<',
    radioValue: '<',
    description: '<',
  },
  controller: class RadioShowComponent {
    constructor() {
      'ngInject';
    }
    $onInit() {
    }

  }
};