import template from './title.pug';

export const TitleComponent = {
  template,
  bindings: {
    value: '<',
  },
  transclude : true,
  controller: class TitleComponent {
    constructor($window, $timeout) {
      'ngInject';
      this.$window = $window;
      this.$timeout = $timeout;
    }

    $onInit() {
      this.$timeout(() => {
        this.$window.document.title = this.value;
      });
    }
  }
};
