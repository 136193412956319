import template from './user-modal-archive.pug';

export const UserModalArchiveComponent = {
  template,
  bindings: {
    resolve: '<',
    modalInstance: '<',
  },
  controller: class UserModalArchiveComponent {
    constructor(User, $log, $async, AlertBox) {
      'ngInject';
      this.User = User;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.type = {
        key: 'shared',
        value: '',
      };
      this.users_selectize = {
        valueField: 'id',
        labelField: 'name',
        searchField: 'name',
        maxItems: 1
      };
      this.save = $async(this.save.bind(this));
      this.getRecordCount = $async(this.getRecordCount.bind(this));
    }

    $onInit() {
      this.loader = true;
      if (this.resolve.user) {
        this.user = this.resolve.user;
        this.getRecordCount();
      }
    }

    async getRecordCount() {
      try {
        const response = await this.User.getPortforlioStats(this.user.id);
        this.total = 0;
        this.total += response.estates;
        this.total += response.contacts;
        this.total += response.inquiries;
        this.total += response.todos;
      } catch (e) {
        this.$log.error(e);
      }
    }


    async save() {
      try {
        this.loader = true;
        const user = await this.User.moveToArchive(this.user.id, this.type.key, this.type.value);
        this.modalInstance.close({ user: user, type: 'update' });
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    close() {
      this.modalInstance.close(void(0));
    }
  }
};
