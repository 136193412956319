import { TableComponent } from './table.component';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BodyComponent } from './body/body.component';
import { TbodyComponent } from './body/tbody/tbody.component';
import { TheadComponent } from './body/thead/thead.component';
import { PaginationComponent } from './pagination/pagination.component';
import { SpinnerComponent } from './spinner/spinner.component';

import { ConnectedRecordFilterComponent } from './connected-record/connected-record-filter/connected-record-filter.component';
import { ConnectedRecordItemsComponent } from './connected-record/connected-record-items/connected-record-items.component';
import { DocumentRecordAttachmentsComponent } from './document-record/document-record-attachments/document-record-attachments.component';
import { BulkEditButtonComponent } from './bulk-edit/bulk-edit-button/bulk-edit-button.component';
import { BulkEditModalComponent } from './bulk-edit/bulk-edit-modal/bulk-edit-modal.component';

import { BulkProposalButtonComponent } from './bulk-proposal-button/bulk-proposal-button.component';

export const TableModule = angular.module('topbroker.tables', [])
  .component('fnTable', TableComponent)
  .component('fnHeader', HeaderComponent)
  .component('fnFooter', FooterComponent)
  .component('fnTbody', TbodyComponent)
  .component('fnThead', TheadComponent)
  .component('tableBody', BodyComponent)
  .component('pagination', PaginationComponent)
  .component('spinner', SpinnerComponent)
  .component('connectedRecordFilter', ConnectedRecordFilterComponent)
  .component('connectedRecordItems', ConnectedRecordItemsComponent)
  .component('documentRecordAttachments', DocumentRecordAttachmentsComponent)
  .component('bulkEditButton', BulkEditButtonComponent)
  .component('bulkEditModal', BulkEditModalComponent)
  .component('bulkProposalButton', BulkProposalButtonComponent)
  .name;
