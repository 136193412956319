import template from './chronology-comment-table.pug';

export const ChronologyCommentTableComponent = {
  template,
  bindings: {
    chronology: '<',
  },
  controller: class ChronologyCommentTableComponent {
    constructor(Chronology, $async) {
      'ngInject';
      this.Chronology = Chronology;
      this.recordPreview = $async(this.recordPreview.bind(this));
    }

    $onInit() {}

    async recordPreview(selectedRecord) {
      try {
        await this.Chronology.commentPreviewModal(selectedRecord);
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
