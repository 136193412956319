class TodoContactResponse {
  constructor(Http, $stateParams) {
    'ngInject';

    this.Http = Http;
    this.$stateParams = $stateParams;
    this.routePrefix = '/todo_responses';
  }

  tokenPrefix(token) {
    return `${this.routePrefix}/${token || this.$stateParams.token}`;
  }

  getReminderInfo(token) {
    return this.Http.get(this.tokenPrefix(token));
  }

  addReminderResponse(params) {
    return this.Http.post(this.tokenPrefix(), params);
  }
}
export default TodoContactResponse;
