import template from './email-show.pug';

export const EmailShowComponent = {
  template,
  bindings: {
    onUpdate: '&?',
    label: '@?',
    description: '@?',
    model: '<',
    record: '<?',
    recordType: '@?',
  },
  controller: class EmailShowComponent {
    constructor() {
      'ngInject';
      this.email = {};
    }

    $onChanges({model, record}) {
      if (model || record) {
        this.setValue();
      }
    }

    $onInit() {
      this.setValue();
    }

    setValue() {
      this.email = {
        address: this.model,
      };
    }

    update() {
      if (this.onUpdate) {
        this.onUpdate({$event: {}});
      }
    }

  }
};
