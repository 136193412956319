import template from './roles-settings-show.pug';

export const RolesSettingsShowComponent = {
  template,
  bindings: {
    role: '<',
  },
  controller: class RolesSettingsShowComponent {
    constructor(Role, AlertBox, Permission, PermPermissionStore, $async, $state, $log) {
      'ngInject';

      this.Role = Role;
      this.AlertBox = AlertBox;
      this.$state = $state;
      this.$log = $log;
      this.Permission = Permission;
      this.PermPermissionStore = PermPermissionStore;

      this.modules = angular.copy(Permission.modules);
      // this.permissionOptions = ['all', 'default', 'cant'];
      // this.permissionScopedOptions = [...this.permissionOptions, 'custom'];
      this.permissionScopedOptions = ['all', 'default', 'cant'];

      this.newRole = $async(this.newRole.bind(this));
      this.updateRole = $async(this.updateRole.bind(this));
      this.toggleModelAccess = $async(this.toggleModelAccess.bind(this));
      this.changePermissionAccess = $async(this.changePermissionAccess.bind(this));
      this.changeReadPermissionAccess = $async(this.changeReadPermissionAccess.bind(this));
      this.openPermissionCustomViewModal = $async(this.openPermissionCustomViewModal.bind(this));
    }

    $onInit() {
      this.resolveModules();
    }

    async newRole() {
      try {
        const role = await this.Role.new();

        if (role && role.id) {
          this.$state.go('main.settings.roles.show', { id: role.id });
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async updateRole() {
      try {
        this.role = await this.Role.update(this.role.id, { name: this.role.name, description: this.role.description });
        this.AlertBox.addMessage('alert.updated', { type: 'success' });
      } catch (e) {
        this.$log.error(e);
        this.AlertBox.addMessage('alert.updated', { type: 'error' });
      }
    }

    async toggleModelAccess(model, accessEnabled) {
      try {
        const params = { role_id: this.role.id, add: [], remove: [] };
        params[accessEnabled ? 'add' : 'remove'] = [`can_access_${model.name}`];

        this.role = await this.Permission.assign(params);
        await this.PermPermissionStore.clearStore();
        await this.Permission.setPermissions();
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.resolveModules();
      }
    }

    async openPermissionCustomViewModal(model) {
      try {
        await this.Permission.permissionCustomViewModal(model);
        this.resolveModules();
      } catch (e) {
        this.$log.error(e);
      }
    }

    async changeReadPermissionAccess(model, newPermissionOption) {
      try {
        if (newPermissionOption == 'custom' && model.scopedRecords == true) {
          await this.Permission.permissionCustomViewModal(model);
          this.resolveModules();
        } else {
          const oldPermissionOption = this.tempModules.find((item) => item.name == model.name).permissions['read'];

          if (oldPermissionOption == 'custom') {
            const params = { role_id: model.role_id, scope: model.name, custom_view_data: [] };
            await this.Permission.saveCustomViewData(params);
          }

          this.changePermissionAccess(model, 'read', newPermissionOption);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async changePermissionAccess(model, crudType, newPermissionOption) {
      try {
        const params = { role_id: this.role.id, add: [], remove: [] };
        const oldPermissionOption = this.tempModules.find((item) => item.name == model.name).permissions[crudType];

        // Don't send 'cant' option to backend because its virtual
        if (oldPermissionOption !== 'cant') params['remove'] = [`can_${crudType}_${oldPermissionOption}_${model.name}`];
        if (newPermissionOption !== 'cant') params['add'] = [`can_${crudType}_${newPermissionOption}_${model.name}`];

        this.role = await this.Permission.assign(params);
        await this.PermPermissionStore.clearStore();
        await this.Permission.setPermissions();
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.resolveModules();
      }
    }

    resolveModules() {
      this.modules = this.modules.map((model) => {
        const moduleEnabledPermissions = (this.role.permissions || []).filter((permission) => permission.scope == model.name).map((item) => item.name);
        model.permissions = { access: moduleEnabledPermissions.includes(`can_access_${model.name}`) };
        model.role_id = this.role.id;

        if (model.permissions.access && model.scopedRecords) {
          model.customViewData = this.role.permissions.find((item) => item.name == `can_access_${model.name}`).custom_view_data;
        } else {
          model.customViewData = [];
        }

        if (model.crudPermissions) {
          model.permissions.read = this.checkPermissionStatus(moduleEnabledPermissions, 'read', model);
          model.permissions.update = this.checkPermissionStatus(moduleEnabledPermissions, 'update', model);
          model.permissions.delete = this.checkPermissionStatus(moduleEnabledPermissions, 'delete', model);
        }

        return model;
      });

      this.tempModules = angular.copy(this.modules);
    }

    checkPermissionStatus(permissions, type, model) {
      if (type == 'read' && model.scopedRecords && model.customViewData.length > 0) {
        return 'custom';
      } else if (permissions.includes(`can_${type}_all_${model.name}`)) {
        return 'all';
      } else if (permissions.includes(`can_${type}_default_${model.name}`)) {
        return 'default';
      } else {
        return 'cant';
      }
    }
  },
};
