
import { FinanceCardComponent } from './finance-card/finance-card.component';
import { TodoCardComponent } from './todo-card/todo-card.component';
import { InquiryCardComponent } from './inquiry-card/inquiry-card.component';
import { DocumentCardComponent } from './document-card/document-card.component';
import { InquiryMdCardComponent } from './inquiry-md-card/inquiry-md-card.component';
import { LocationCardComponent } from './location-card/location-card.component';

const CardsModule =
  angular.module('topbroker.sharedCards', [])
    .component('financeCard', FinanceCardComponent)
    .component('todoCard', TodoCardComponent)
    .component('inquiryCard', InquiryCardComponent)
    .component('documentCard', DocumentCardComponent)
    .component('locationCard', LocationCardComponent)
    .component('inquiryMdCard', InquiryMdCardComponent)

    .name;

export default CardsModule;
