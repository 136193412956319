import template from './footer.pug';

export const FooterComponent = {
  template,
  bindings: {
    totalPages: '<',
    totalCount: '<',
    page: '<',
    perPage: '<?',
    afterPageChange: '&',
  },
  transclude: true,
  controller: class FooterComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }

    change({page}) {
      this.afterPageChange({
        $event: { page }
      });
    }

  }
};
