import template from './custom-fields-show.pug';

export const CustomFieldsShowComponent = {
  template,
  bindings: {
    record: '<',
    cfGroups: '<',
    onUpdate: '&?',
    onEdit: '&?',
  },
  controller: class CustomFieldsShowComponent {
    constructor() {
      'ngInject';
      this.state = 'show';
    }

    $onInit() {}

    update() {
      if (this.onUpdate) {
        this.onUpdate({ $event: {} });
      }
    }

    changeToEdit() {
      this.change({ state: 'edit' });
    }

    change({ state }) {
      this.state = state;
      this.onEdit({
        $event: { state }
      });
    }
  }
};
