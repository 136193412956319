import template from './finance-preview.pug';

export const FinancesPreviewModalComponent = {
  template,
  bindings:{
    resolve: '<',
    modalInstance: '<',
  },
  controller: class FinancesPreviewModalComponent {
    constructor(Finance, AlertBox, $async, $log) {
      'ngInject';

      this.Finance = Finance;
      this.$log = $log;
      this.AlertBox = AlertBox;
      this.moveToTrash = $async(this.moveToTrash.bind(this));
      this.update = $async(this.update.bind(this));

      this.formState = 'show';
    }

    $onInit() {
      this.record = this.resolve.record;
    }

    close() {
      this.modalInstance.close(void(0));
    }

    changeFormState(state) {
      if (state == 'edit') {
        this.record = angular.copy(this.record);
      }
      this.formState = state;
    }

    async moveToTrash() {
      try {
        await this.Finance.moveToTrash(this.record.id);
        this.AlertBox.addMessage('alert.deleted', { type: 'success' });
        this.close();
      } catch (e) {
        this.$log.error(e);
      }
    }

    async update({ finance }) {
      try {
        const updatedFinance = await this.Finance.update(finance.id, finance);

        if (updatedFinance && updatedFinance.id) {
          this.record = updatedFinance;
          this.AlertBox.addMessage('alert.updated', { type: 'success' });
          this.changeFormState('show');
        }
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
