import template from './contact-show-form.pug';

export const ContactShowFormComponent = {
  template,
  bindings: {
    contact: '<',
    cfGroups: '<',
    changeState: '&',
    onEdit: '&',
    onCopy: '&',
    onDestroy: '&',
  },
  controller: class ContactShowFormComponent {
    constructor(Contact, AlertBox, $async) {
      'ngInject';
      this.Contact = Contact;
      this.AlertBox = AlertBox;
      this.changeRecordStatus = $async(this.changeRecordStatus.bind(this));
      this.moveToTrash = $async(this.moveToTrash.bind(this));
    }

    $onInit() {
    }

    googleContactUrl() {
      return `https://contacts.google.com/${this.contact.google_resource_name.replace('people', 'person')}`;
    }

    async changeRecordStatus({ status, custom_fields }) {
      try {
        const resp = await this.Contact.update(this.contact.id, { record_status_id: status.id, custom_fields });
        if (resp && resp.id) {
          this.contact = resp;
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    editInfo() {
      this.onEdit({ $event: true });
    }

    makeCopy() {
      this.onCopy({ $event: true });
    }

    async moveToTrash() {
      const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });
      if (result && result.dismiss) return false;

      this.onDestroy({ $event: true });
    }

  }
};
