import template from './document-group-table.pug';

export const DocumentGroupTableComponent = {
  template,
  bindings: {
    groups: '<'
  },
  controller: class DocumentGroupTableComponent {
    constructor(Document, $async, $log, AlertBox) {
      'ngInject';
      this.Document = Document;
      this.$log = $log;
      this.AlertBox = AlertBox;
      this.updatePosition = $async(this.updatePosition.bind(this));
      this.edit = $async(this.edit.bind(this));
      this.delete= $async(this.delete.bind(this));
      this.fetch = $async(this.fetch.bind(this));


      this.sortableOptions = {
        handle: '.sortableListHandle',
        tolerance: 'pointer',
        stop: () => {
          this.groups.forEach((value, index) => {
            value.position = index + 1;
          });
        },
        update: (_e, ui) => {
          const position = ui.item.sortable.dropindex + 1;
          const id = ui.item.sortable.model.id;
          this.updatePosition(id, position);
        },
        axis: 'y'
      };
    }

    $onInit() {
      this.fetch();
    }

    async fetch() {
      try {
        this.groups = await this.Document.getGroups();
      } catch (e) {
        this.$log.error(e);
      }
    }

    async updatePosition(id, position) {
      try {
        await this.Document.updateGroup({ id: id, position: position });
        this.AlertBox.addMessage('alert.updated', { type: 'success' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async edit(group) {
      try {
        await this.Document.groupModal(group);
        this.fetch();
      } catch (e) {
        this.$log.error(e);
      }
    }

    async delete(group) {
      const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });

      if (result && result.value) {
        try {
          await this.Document.deleteGroup(group.id);
          this.fetch();
        } catch (e) {
          this.$log.error(e);
        }
      }
    }

    async newGroup() {
      try {
        const group = await this.Document.groupModal();
        group.position = this.groups.length + 2;
        this.groups.push(group);
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
