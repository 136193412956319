import template from './additional-fields-form.pug';

export const AdditionaFieldsFormComponent = {
  template,
  bindings: {
    inquiry: '<',
    formState: '<',
    onUpdate: '&?',
    onDestroy: '&?',
  },
  controller: class AdditionaFieldsFormComponent {
    constructor($state, $async, $log, Inquiry, Search, AlertBox, CustomField) {
      'ngInject';
      this.AlertBox = AlertBox;
      this.$state = $state;
      this.$log = $log;
      this.Inquiry = Inquiry;
      this.Search = Search;
      this.CustomField = CustomField;
      this.loadCustomFields = $async(this.loadCustomFields.bind(this));
      this.save = $async(this.save.bind(this));
      this.reloadInquiry = $async(this.reloadInquiry.bind(this));
    }

    $onInit() {
      this.reloadInquiry();
      this.loadCustomFields();
    }

    async reloadInquiry() {
      try {
        this.inquiry = await this.Inquiry.find(this.inquiry.id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    changeState({ state }) {
      if (state) this.formState = state;
      if (state == 'show') this.reloadInquiry();
    }

    async loadCustomFields() {
      try {
        this.cfGroups = await this.CustomField.loadGroups({ module: 'inquiries' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async save({ inquiry }) {
      try {
        const params = {
          custom_fields: inquiry.custom_fields
        };

        if (inquiry['connected_record_relations'] && inquiry['connected_record_relations'].length) {
          params['connected_record_relations'] = inquiry['connected_record_relations'];
        }
        await this.Inquiry.update(inquiry.id, params);
        this.AlertBox.addMessage('alert.updated',{ type: 'success' });

        if (this.onUpdate) {
          this.onUpdate({ $event: {
            inquiry: this.inquiry
          } });
        }
        await this.reloadInquiry();
        this.changeState({ state: 'show' });
      } catch (e) {
        this.$log.error(e);
      }
    }

  }
};
