import template from './todo-show.pug';

export const TodoShowComponent = {
  template,
  bindings: {
    todo: '<',
    onEdit: '&?',
    onDestroy: '&?',
    onCopy: '&?',
    onClose: '&?',
    onStateChange: '&?',
    options: '<?',
    afterUpdateView: '&?'
  },
  controller: class TodoShowComponent {
    constructor(Todo, AlertBox, User, CustomField, $async, Workspace, $sce) {
      'ngInject';
      this.Todo = Todo;
      this.AlertBox = AlertBox;
      this.Workspace = Workspace;
      this.User = User;
      this.CustomField = CustomField;
      this.$sce = $sce;
      this.loadUsers = $async(this.loadUsers.bind(this));
      this.loadEstates = $async(this.loadEstates.bind(this));
      this.loadReminders = $async(this.loadReminders.bind(this));
      this.loadContacts = $async(this.loadContacts.bind(this));
      this.loadInquiries = $async(this.loadInquiries.bind(this));
      this.loadAttachments = $async(this.loadAttachments.bind(this));
      this.destroy = $async(this.destroy.bind(this));
      this.loadCustomFields = $async(this.loadCustomFields.bind(this));
      this.changeWorkspace = $async(this.changeWorkspace.bind(this));
      this.users = [];
      this.estates = [];
      this.reminders = [];
      this.attachments = [];
      this.loading = true;
      this.animation = false;
      this.smartReminderEnabled = false;

    }

    $onChanges({ todo }) {
      if (todo && todo.currentValue !== todo.previousValue) {
        this.init();
      }
    }

    init() {
      this.getSettings();
      this.statusState = this.Todo.statuses();

      if (this.todo && this.todo.id) {
        this.isDone = this.todo.completed_at !== null;
        if (this.todo.notes) this.todo.notes = this.$sce.trustAsHtml(this.todo.notes.toLinks());
        this.duration = humanizeDuration(dayjs(this.todo.ends_at).diff(this.todo.starts_at), { language: 'en' });
        this.loadRelatedContent();
        this.loadCustomFields();
        this.options = Object.assign(
          {
            show_date: true
          },
          this.options
        );
      } else {
        this.loading = false;
      }
      document.body.style.cursor = 'default';
    }

    getSettings() {
      const userSettings = this.User.getUserSettings();
      if (userSettings && userSettings.todo.smart_reminder) {
        this.smartReminderEnabled = userSettings.todo.smart_reminder.enabled;
      }
    }

    async changeWorkspace() {
      try {
        const resp = await this.Workspace.changeRecordWorkspaceModal(this.todo, 'todo', this.todo.workspace_id);
        if (resp) {
          this.todo.workspace_id = resp;
          this.AlertBox.addMessage('alert.updated.success', { type: 'success' });
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async loadRelatedContent() {
      this.loading = true;
      await this.loadUsers();
      await this.loadEstates();
      await this.loadReminders();
      await this.loadContacts();
      await this.loadInquiries();
      await this.loadAttachments();
      this.loading = false;
      if (this.afterUpdateView) {
        this.afterUpdateView({ $event: {} });
      }
    }

    async checkIsDone() {
      this.showStatusPop = false;
      this.isDone = !this.isDone;

      if (this.todo.id) {
        await this.Todo.markAsDone(this.todo.id);
        this.todo.completed_at = this.isDone ? dayjs() : null;
        this.onStateChange({ $event: { todo: this.todo } });
      }
    }

    googleLocationLink() {
      return 'https://www.google.com/maps/place/' + encodeURI(this.todo.location);
    }

    async loadCustomFields() {
      try {
        this.cfGroups = await this.CustomField.loadGroups({ module: 'todos' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async loadUsers() {
      try {
        this.users = await this.Todo.getUsers(this.todo.id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async loadEstates() {
      try {
        this.estates = await this.Todo.getEstates(this.todo.id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async loadContacts() {
      try {
        this.contacts = await this.Todo.getContacts(this.todo.id);

        if (this.smartReminderEnabled) {
          for (let i = 0; i < this.contacts.length; i++) {
            this.contacts[i].invite = await this.Todo.getSmartReminderResponse(this.todo.id, this.contacts[i].id);
            this.contacts[i].invite_open = false;
          }
        }

      } catch (e) {
        this.$log.error(e);
      }
    }

    async reSendInvite(contact) {
      try {
        this.loader = true;
        await this.Todo.inviteContact(this.todo.id, contact.id);
        this.loader = false;
        this.afterLoad = true;
      } catch (e) {
        this.$log.error(e);
      }
    }

    async loadInquiries() {
      try {
        this.inquiries = await this.Todo.getInquiries(this.todo.id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async loadAttachments() {
      try {
        this.attachments = await this.Todo.getAttachments(this.todo.id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async loadReminders() {
      try {
        this.reminders = await this.Todo.getReminders(this.todo.id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    edit() {
      this.onEdit({ $event: { todo: this.todo } });
    }

    copy() {
      this.onCopy({ $event: { todo: this.todo } });
    }

    async destroy() {
      const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });
      if (result && result.dismiss) return false;
      this.onDestroy({ todo: this.todo });
    }
  }
};
