import template from './onboard.pug';

export const OnboardComponent = {
  template,
  bindings: {},
  controller: class OnboardComponent {
    constructor(Account, User, $state, $stateParams, $auth, $async, $log) {
      'ngInject';
      this.$state = $state;
      this.$log = $log;
      this.Account = Account;
      this.User = User;
      this.$stateParams = $stateParams;
      this.$auth = $auth;

      this.slideShowing = 0;
      this.getCountries = $async(this.getCountries.bind(this));
      this.confirmUser = $async(this.confirmUser.bind(this));
      this.onSubmit = $async(this.onSubmit.bind(this));
      this.searchCountry = { name: '' };
      this.activeSearch = false;
    }

    $onInit() {
      this.account = {
        country: '',
        type: 'development',
        size: '',
      };

      this.confirmUser();
    }

    async onSubmit() {
      try {
        this.loading = true;
        await this.Account.finishOnboarding(this.account);
        this.User.track();
        this.$state.go('main.dashboard.stats');
      } catch (error) {
        this.$log.error(error);
      }
    }

    async confirmUser() {
      try {
        if (this.$stateParams.token.length > 0) {
          const resp = await this.User.confirmByToken(this.$stateParams.token);
          this.$auth.setToken(resp.token);
        }
        this.getCountries();
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getCountries() {
      try {
        const resp = await this.Account.accountCountries();
        if (resp) {
          this.countries = resp;

          let data = await this.getCloudflareJSON();
          this.selectCountry(data.loc);
        }
      } catch (error) {
        this.$log.error(error);
      }
    }

    selectCountry(code) {
      this.account.country = code;
      this.selectedCountry = this.countries.find(country => country.id === code);
    }

    async getCloudflareJSON() {
      let data = await fetch('https://1.1.1.1/cdn-cgi/trace').then(res => res.text());
      let arr = data.trim().split('\n').map(e => e.split('='));

      return Object.fromEntries(arr);
    }
  }
};
