import template from './location-geopify.pug';



export const LocationGeopifyComponent = {
  template,
  bindings: {
    value: '<',
    label: '@?',
    description: '@?',
    onChange: '&?',
    attributes: '<?',
  },
  transclude: true,
  controller: class LocationGeopifyComponent {

    constructor($async, $log, Locations, Geopify) {
      'ngInject';
      this.$log = $log;
      this.Locations = Locations;
      this.Geopify = Geopify;
      this.getLocation = $async(this.getLocation.bind(this));

    }

    $onChanges({ value }) {
      if (value && value.currentValue && this.autocomplete) {
        this.autocomplete.setValue(value.currentValue.formatted);
      }
    }

    $onInit() {
    }

    async getLocation(location) {
      try {
        let params = this.Geopify.getLocationParams(location);
        if (!params.latitude || !params.longitude) return;

        if (this.attributes) {
          params = Object.assign(params, this.attributes);
        }

        const resp = await this.Locations.find_or_create_location(params);
        this.onChange({ $event: resp });

      } catch (e) {
        this.$log.error(e);
      }
    }

    initAutocomplete() {
      this.autocomplete = this.Geopify.initAutocomplete();
      this.autocomplete.on('select', (location) => {
        this.getLocation(location);
      });
    }

  }
};
