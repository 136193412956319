import template from './dropdown.pug';
import _isEqual from 'lodash/isEqual';

export const DropdownComponent = {
  template,
  bindings: {
    options: '<',
    label: '@?',
    disabled: '<?',
    validations: '<?',
    description: '<?',
    translatePrefix: '@?',
    selected: '<?',
    onSelect: '&',
  },
  transclude: true,
  controller: class DropdownComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
      if (this.selected == undefined) {
        this.title = this.options[0]['title'];
        this.icon = this.options[0]['icon'];
      } else {
        this._setValues(this.selected);
      }
    }

    select(value) {
      this._setValues(value);
      this.onSelect({
        $event: { option: value },
      });
    }

    _setValues(value) {
      const item = this.options.find((x) => _isEqual(x.value, value));

      this.title = item.title;
      this.icon = item.icon;
    }
  },
};
