// services
import Contact from '../../services/contact/contact.service';
// helpers

// base component
import { ContactsComponent } from './contacts.component';
// components
import { ContactListComponent } from './list/contact-list.component';
import { ContactShowComponent } from './show/contact-show.component';
import { ContactsImportComponent } from './import/contacts-import.component';
import { ContactTopbarComponent } from './topbar/contact-topbar.component';

export const ContactsModule = angular.module('topbroker.contacts', [])
  .service('Contact', Contact)
  .component('contacts', ContactsComponent)
  .component('contactList', ContactListComponent)
  .component('contactShow', ContactShowComponent)
  .component('contactsImport', ContactsImportComponent)
  .component('contactTopbar', ContactTopbarComponent)

  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('main.contacts', {
        url: '/contacts',
        component: 'contacts',
        abstract: true,
        data: {
          permissions: {
            only: 'can_access_contacts',
            redirectTo: 'main.dashboard.stats'
          }
        }
      })
      .state('main.contacts.nonexisting', {
        url: '/show/:id/404',
        params: { backTo: 'main.contacts.list' },
        component: 'notFound',
        resolve: {
          searchId:(Search) => {
            'ngInject';
            return Search.returnLastSearchId('Contact');
          },
        }
      })

      .state('main.contacts.unauthorized', {
        url: '/show/:id/unauthorized',
        component: 'unauthorized',
        params: { backTo: 'main.contacts.list' },
        resolve: {
          searchId: (Search) => {
            'ngInject';
            return Search.returnLastSearchId('Contact');
          },
          recordAttributes: ($stateParams) => {
            'ngInject';
            return { type: 'contact', id: $stateParams.id };
          }
        }
      })

      .state('main.contacts.list', {
        url: '/list/:page?:searchId?:statusId?:customFieldId?:destinationId?:destinationType',
        params: {
          page: { dynamic: true },
          searchId: { dynamic: true },
          statusId: { dynamic: true },
          customFieldId: { dynamic: true },
          destinationId: { dynamic: true },
          destinationType: { dynamic: true },
        },
        component: 'contactList',
        resolve: {
          filter: ($stateParams) => {
            'ngInject';
            let filter = {};
            filter.page = $stateParams.page;
            return filter;
          }
        }
      })
      .state('main.contacts.user-list', {
        url: '/kontaktai/:user_id/:page?:searchId?:statusId',
        params: { page: { dynamic: true }, searchId: { dynamic: true }, statusId: { dynamic: true } },
        component: 'contactList',
        resolve: {
          filter: ($stateParams) => {
            'ngInject';
            let filter = {};
            filter.user_id = $stateParams.user_id;
            filter.page = $stateParams.page;
            return filter;
          }
        }
      })
      .state('main.contacts.show', {
        url: '/show/:id',
        component: 'contactShow',
        resolve: {
          contact: ($stateParams, Contact, $state) => {
            'ngInject';
            let response = Contact.find($stateParams.id).then( record => {
              return record;
            }, (res) => {
              $state.go('main.contacts.unauthorized', {
                id: $stateParams.id,
                user: null,
              });
            } );
            return response;
          },
          searchId: function(Search) {
            'ngInject';
            return Search.returnLastSearchId('Contact');
          },
          $title: function() { return 'Home'; }
        }
      })
      .state('main.contacts.import', {
        url: '/import',
        component: 'contactsImport',
        resolve: {
          searchId: function(Search) {
            'ngInject';
            return Search.returnLastSearchId('Contact');
          }
        }
      });
  }).name;
