import template from './field-new-modal.pug';

export const FieldNewModalComponent = {
  template,
  bindings:{
    resolve: '<',
    modalInstance: '<',
  },
  controller: class FieldNewModalComponent {
    constructor($translate, $async, $log, CustomField) {
      'ngInject';
      this.$translate = $translate;
      this.$log = $log;
      this.CustomField = CustomField;
      this.save = $async(this.save.bind(this));
      this.fields = this.CustomField.fieldList;
    }

    $onInit() {
      this.group = this.resolve.group;
    }

    async save({ field }) {
      const params = {
        ...field,
        custom_fields_group_id: this.group.id
      };
      try {
        this.loader = true;
        const field =  await this.CustomField.newField(params);
        this.modalInstance.close(field);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    resolveTitle() {
      let title;
      if (this.selectedField) {
        title = this.selectedField.name;
      } else {
        title = `${this.$translate.instant('custom_fields.add_new')} (${this.group.title})`;
      }
      return title;
    }

    close() {
      this.modalInstance.close(void(0));
    }
  }
};
