import template from './settings-custom-table.pug';

export const SettingsCustomTableComponent = {
  template,
  bindings: {
    objectClass: '<',
    cfObjectClass: '<?',
    translatePrefix: '<?',
    toolbarTitle: '<'
  },
  controller: class SettingsCustomTableComponent {
    constructor($log, $async, TableEdit, User) {
      'ngInject';
      this.$log = $log;
      this.TableEdit = TableEdit;
      this.User = User;
      this.updated;
      this.selected = [];
      this.checkedList = {};

      this.save = $async(this.save.bind(this));
    }

    $onInit() {
      if (!this.cfObjectClass) this.cfObjectClass = this.objectClass;
      if (!this.translatePrefix) this.translatePrefix = this.objectClass;
      this.fields = this.TableEdit.getColumnByClass(this.objectClass);
    }

    removed({ selected, checkedList }) {
      this.selected = selected;
      this.checkedList = checkedList;
    }

    setFields(fields) {
      this.fieldsForSave = fields;
      this.save();
    }

    setItems({ selected, checkedList, updated }) {
      this.selected = selected;
      this.checkedList = checkedList;
      this.selected = angular.copy(this.selected);
      this.updated = updated;
    }

    async save() {
      if (!this.fieldsForSave) return;

      try {
        await this.TableEdit.updateColumns(this.objectClass, this.fieldsForSave, 'account');
      } catch (e) {
        this.$log.error(e);
      }
    }

  }
};
