import template from './document-show.pug';

export const DocumentsShowForm = {
  template,
  bindings: {
    document: '<',
    onEdit: '&',
    onDestroy: '&',
  },
  controller: class DocumentsShowForm {
    constructor(Document, Attachment, CustomField, User, AlertBox, $log, $async) {
      'ngInject';

      this.Document = Document;
      this.Attachment = Attachment;
      this.CustomField = CustomField;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.User = User;

      this.loadCustomFields = $async(this.loadCustomFields.bind(this));
      this.getUser = $async(this.getUser.bind(this));
      this.bulkDownload = $async(this.bulkDownload.bind(this));
      this.destroy = $async(this.destroy.bind(this));
      this.cfGroups = [];
    }

    $onChanges({ document }) {
      if (document && document.currentValue !== document.previousValue) {
        this.getUser();
      }
    }

    $onInit() {
      this.loadCustomFields();
      this.getUser();
    }

    async bulkDownload() {
      if (!this.document.attachments.length) return;
      try {
        await this.Attachment.bulkDownloadModal(this.document.attachments);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getUser() {
      if (!this.document.user_id) return;
      try {
        this.recordUser = await this.User.cardInfo(this.document.user_id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async loadCustomFields() {
      try {
        this.cfGroups = await this.CustomField.loadGroups({ module: 'documents' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async destroy() {
      try {
        const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });
        if (result && result.dismiss) return false;
        this.onDestroy();
      } catch (e) {
        this.$log.error(e);
      }
    }

    edit() {
      this.onEdit({
        $event: { document: this.document },
      });
    }
  },
};
