import template from './chronology-todo-filter.pug';

export const ChronologyTodoFilterComponent = {
  template,
  bindings: {
    filter: '<',
  },
  controller: class ChronologyTodoFilterComponent {
    constructor(Todo, $async, $log) {
      'ngInject';

      this.Todo = Todo;
      this.$log = $log;

      this.getTodoGroups = $async(this.getTodoGroups.bind(this));


      this.todoGroups = [];
    }

    $onInit() {
      this.getTodoGroups();
    }

    async getTodoGroups() {
      try {
        this.todoGroups = await this.Todo.getGroups();
      } catch (e) {
        this.$log.error(e);
      }
    }

  }
};
