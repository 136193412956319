import template from './assignment-modal.pug';

export const AssignmentModalComponent = {
  template,
  bindings: {
    resolve: '<',
    modalInstance: '<'
  },
  controller: class AssignmentModalComponent {
    constructor(Estate, Contact, User, Inquiry, Document, $async, $element, $translate, $stateParams) {
      'ngInject';
      this.Estate = Estate;
      this.Contact = Contact;
      this.User = User;
      this.Inquiry = Inquiry;
      this.Document = Document;

      this.$element = $element;
      this.$stateParams = $stateParams;
      this.query = '';
      this.id = null;
      this.items = [];

      this.searchParams = {
        exclude_ids: [],
        operation_type: ' ',
        estate_type_id: ' '
      };
      this.showAdvance = false;
      this.getRecords = $async(this.getRecords.bind(this));
      this.getCheckedRecords = $async(this.getCheckedRecords.bind(this));
      this.createNew = $async(this.createNew.bind(this));

      this.sellTypes = [
        { name: $translate.instant('estate.options.operation_type.all'), id: ' ' },
        { name: $translate.instant('estate.options.operation_type.for_sale'), id: 'for_sale' },
        { name: $translate.instant('estate.options.operation_type.for_rent'), id: 'for_rent' }
      ];
      this.estateTypes = [{ name: $translate.instant('estate.options.estate_type.all'), id: ' ' }, ...Estate.types()];
    }

    $onInit() {
      this.selectedItems = [];
      this.selectedItemIds = [];

      this.tabs = ['search', 'selected'];
      this.selected = this.tabs[0];

      this.multiple = false;

      if (this.resolve) {
        this.type = this.resolve.type;
        this.multiple = this.resolve.multiple;
        this.searchParams.exclude_ids = this.resolve.alreadySelected.map( e => e.id );

        if (this.multiple) {
          this.selectedItems = this.resolve.alreadySelected;
          this.selectedItemIds = this.searchParams.exclude_ids;
          this.$stateParams.cantGoBack = this.selectedItemIds.length ? true : false;
          this.counts = { selected: this.selectedItemIds.length };
        }
      }

      this.headerTitle = this.type == 'Inquiry' ? 'general.inquiries' : ('general.' + this.type.toLowerCase() + 's');
    }

    setTab(tab) {
      this.selected = tab;
    }

    $onChanges({ exclude }) {
      if (this.id && exclude.previousValue != exclude.currentValue) this.closeSearch(this.id);
    }

    searchForIt(query) {
      this.query = query;
      this.items = [];
      this.page = 1;
      this.endIsReached = false;
      this.getRecords();
    }

    async getRecords() {
      try {
        if (this.endIsReached || this.loader) {
          this.$log.info('not need load');
          return;
        }
        this.loading = true;
        let items = [];
        const params = {
          per_page: 20,
          page: this.page,
          fields: { user: ['id', 'name', 'email', 'phone', 'image_url', 'color', 'phone_attributes'] },
        };

        Object.keys(this.searchParams).forEach(e => {
          if (this.searchParams[e] !== ' ') params[e] = this.searchParams[e];
        });

        params.q = this.query;
        params.per_page = 50;
        items = await this.User.getList(params);

        this.items = [...this.items, ...items];

        if (items && items.length < params.per_page) {
          this.endIsReached = true;
        } else {
          this.page += 1;
        }

      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;

      }
    }

    preloadItems() {
      if (!this.query.length && !this.items.length) {
        this.searchForIt('');
      }
    }

    async getCheckedRecords({ checkedIds }) {
      if (!checkedIds) return;

      const _add_list = checkedIds.filter( id => !this.selectedItemIds.includes(id) );
      const _remove_list = this.selectedItemIds.filter(id => !checkedIds.includes(id));

      _remove_list.forEach( id => this.selectItem({ id: id }) );

      if (!_add_list.length) return;

      let items = [];

      switch (this.type) {
        case 'Estate':
          items = await this.Estate.getList({ estate_ids: _add_list });
          break;
        case 'Contact':
          items = await this.Contact.getList({ contact_ids: _add_list });
          break;
        case 'Inquiry':
          items = await this.Inquiry.getList({ inquiry_ids: _add_list });
          break;
        case 'Document':
          items = await this.Document.getList({ document_id: _add_list });
          break;
      }

      items.forEach( (record) => {
        this.selectItem( record );
      });
    }

    selectItem(record) {
      if (this.multiple) {
        if (this.selectedItemIds.indexOf(record.id) == -1) {
          this.selectedItems.push(record);
          this.selectedItemIds.push(record.id);
        } else {
          this.selectedItems = this.selectedItems.filter(e => e.id !== record.id);
          this.selectedItemIds = this.selectedItemIds.filter(e => e !== record.id);
          this.searchParams.exclude_ids = this.selectedItemIds;
        }
        this.$stateParams.cantGoBack = this.selectedItemIds.length ? true : false;
        this.counts = { selected: this.selectedItemIds.length };
      } else {
        this.modalInstance.close({ $event: record });
        this.searchParams.exclude_ids.push(record.id);
        this.items = [];
        this.query = '';
        this.opened = false;
      }
    }

    async createNew() {
      try {
        let resp = {};
        switch (this.type) {
          case 'Contact':
            resp = await this.Contact.new();
            break;
          case 'Estate':
            resp = await this.Estate.new();
            break;
          case 'Inquiry':
            resp = await this.Inquiry.newModal();
            break;
          case 'Document':
            resp = await this.Document.newModal();
            break;
        }

        if (resp && resp.id) {
          this.selectItem(resp);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    applyFilter($event) {
      this.searchParams.search_id = $event.searchId;
      this.searchForIt(this.query);
    }

    done() {
      this.modalInstance.close({ ids: this.selectedItemIds, items: this.selectedItems });
    }

    close() {
      this.modalInstance.close(void(0));
    }

    isType(type) {
      return this.type === type;
    }
  }
};
