import template from './inquiry-md-card.pug';

export const InquiryMdCardComponent = {
  template,
  bindings: {
    record: '<',
  },
  controller: class InquiryCardComponent {
    constructor(Estate) {
      'ngInject';
      this.Estate = Estate;
      this.currency = window.CURRENCY;
    }

    $onInit() {
    }

    resolveAreaUnit(unit) {
      return this.Estate.resolveAreaUnit(unit);
    }

  }
};
