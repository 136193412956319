class Http {
  constructor($http) {
    'ngInject';
    this.$http = $http;
  }

  get(url, params) {
    return this.request({
      method: 'GET',
      url: url,
      params: params,
      paramSerializer: '$httpParamSerializerJQLike',
    }).then((response) => {
      return response.data;
    });
  }

  post(url, data) {
    return this.request({
      method: 'POST',
      url: url,
      data: data,
      paramSerializer: '$httpParamSerializerJQLike',
    }).then((response) => {
      return response.data;
    });
  }

  put(url, data) {
    return this.request({
      method: 'PUT',
      url: url,
      data: data,
      paramSerializer: '$httpParamSerializerJQLike',
    }).then((response) => {
      return response.data;
    });
  }

  delete(url, params) {
    return this.request({
      method: 'DELETE',
      url: url,
      params: params,
      paramSerializer: '$httpParamSerializerJQLike',
    }).then((response) => {
      return response.data;
    });
  }

  downloadBlob(url, params) {
    return this.request({
      method: 'GET',
      url: url,
      params: params,
      paramSerializer: '$httpParamSerializerJQLike',
      responseType: 'arraybuffer',
    }).then(response => {
      const { data, headers } = response;
      const virtualLink = document.createElement('a');
      try {
        const blob = new Blob([data], { type: headers('content-type') });
        const fileName = headers('Content-Disposition').match(/filename[^;\n]*=(UTF-\d['"]*)?((['"]).*?[.]$\2|[^;\n]*)?/);
        const generatedUrl = window.URL.createObjectURL(blob);
        const clickEvent = new MouseEvent('click', { 'view': window, 'bubbles': true, 'cancelable': false });

        virtualLink.setAttribute('href', generatedUrl);
        virtualLink.setAttribute('download', fileName[2]);
        virtualLink.dispatchEvent(clickEvent);
      } catch (e) {
        console.log(e);
      }
    });
  }

  downloadS3Blob(url, filename) {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';

    xhr.onload = function() {
      if (this.status == 200) {
        const a = document.createElement('a');
        const blobUrl = window.URL.createObjectURL(this.response);

        document.body.appendChild(a);
        a.href = blobUrl;
        a.download = filename;
        a.click();

        setTimeout(() => {
          window.URL.revokeObjectURL(blobUrl);
          document.body.removeChild(a);
        }, 0);
      }
    };
    xhr.send();
  }

  request(config) {
    config.url = window.apiHost + config.url;
    return this.$http(config);
  }
}
export default Http;
