import _map from 'lodash/map';

class Contact {

  constructor(Http, Modal, $state,$translate, uiGridConstants, User, Upload, Workspace) {
    'ngInject';
    this.Http = Http;
    this.Modal = Modal;
    this.Upload = Upload;
    this.$state = $state;
    this.User = User;
    this.$translate = $translate;
    this.Workspace = Workspace;
    this.uiGridConstants = uiGridConstants;
  }

  columnsMain(isModal) {
    return [
      {
        name: this.$translate.instant('general.preview'),
        field: '',
        enableColumnMenu: false,
        enableSorting: false,
        enableFiltering: false,
        minWidth: 30,
        maxWidth: 40,
        cellTemplate:
          '<a ng-click="grid.appScope.preview(row)" class="mt-3 ml-2"><icon icon="(\'eye\')" attr="{width:20,height:20}" /></a>'
      },
      {
        name: this.$translate.instant('contacts.name'),
        field: 'name',
        enableColumnMenu: false,
        enableSorting: false,
        minWidth: 300,
        enableFiltering: true,
        filter: { name: 'search_by_name' },
        cellTemplate: isModal ? '' :
          `<div class="ui-grid-cell-contents">
            <todo-contact contact="row.entity" ng-click="grid.appScope.onRowClick(row)" />
          </div>`
      },
      {
        name: this.$translate.instant('contacts.phone'),
        field: 'phone_attributes.formated',
        enableColumnMenu: false,
        enableSorting: false,
        maxWidth: 140,
        enableFiltering: true,
        filter: { name: 'search_by_phone' },
      },
      {
        name: this.$translate.instant('contacts.email'),
        field: 'email',
        enableColumnMenu: false,
        enableSorting: false,
        maxWidth: 250,
        enableFiltering: true,
        filter: { name: 'search_by_email' },
      },
      {
        name: this.$translate.instant('general.date'),
        field: 'date',
        enableFiltering: false,
        enableSorting: true,
        enableColumnMenu: false,
        width: 100,
        sort: { direction: this.uiGridConstants.DESC },
        sortDirectionCycle: [this.uiGridConstants.ASC, this.uiGridConstants.DESC],
      },
      {
        name: this.$translate.instant('general.user'),
        field: 'user',
        enableFiltering: true,
        enableSorting: false,
        width: 250,
        enableColumnMenu: false, cellTemplate:'<span class="user-cell"><todo-user user="grid.getCellValue(row, col)"></todo-user></span>',
        filterHeaderTemplate:
          `<div class="ui-grid-filter-container">
            <selectize config="col.filter.config" options="col.filter.selectOptions" ng-model="col.filter.term">
            </selectize>
          </div>`,
        filter: {
          name: 'user_ids',
          config: { labelField: 'name', valueField: 'id', searchField: 'name' },
          selectOptions: [],
        }
      }
    ];
  }

  find(id, params = {}) {
    return this.Http.get('/contacts/' + id, params);
  }

  cardInfo(id) {
    return this.Http.get(`/contacts/${id}/card_info`);
  }


  search(query, params = {}) {
    return this.Http.post(
      '/contacts/search',
      { ...{ full_search: query, per_page: 5, workspace_id: this.Workspace.currentWorkspaceId() }, ...params }
    );
  }

  checkPhoneDuplicates(phone) {
    return this.Http.get('/contacts/duplicates/phone', { phone });
  }

  checkEmailDuplicates(email) {
    return this.Http.get('/contacts/duplicates/email', { email });
  }

  navigateToShow(id) {
    return this.$state.go('main.contacts.show', { id });
  }

  getSearch() {
    return this.Http.get('/custom_views', { module: 'Contact' });
  }

  updateSearch(id, params) {
    return this.Http.put('/custom_views/' + id, params);
  }

  deleteLastSearch(params) {
    return this.updateSearch(params);
  }

  getList(params) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.getListBySearchId(0, params).then((records) => {
      return _map(records.data, (el) => { return el.attributes;  });
    });
  }

  getListBySearchId(search_id, search_data) {
    return this.Http.get('/contacts/cs/' + search_id,search_data);
  }

  // For new contacts table
  getListBySearchIdNew(search_id, search_data) {
    const params = { workspace_id: this.Workspace.currentWorkspaceId(), ...search_data };

    return this.Http.post('/contacts/list/' + search_id, params);
  }

  export(search_id, params) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.Http.post('/contacts/export/' + search_id, params);
  }

  minimalColumns() {
    return [
      {
        name: this.$translate.instant('contacts.name'),
        field: 'name',
        enableColumnMenu: false,
        enableSorting: false,
        minWidth: 300,
        enableFiltering: false
      },
      {
        name: this.$translate.instant('contacts.phone'),
        field: 'phone_attributes.formated',
        enableColumnMenu: false,
        enableSorting: false,
        minWidth: 100,
        enableFiltering: false
      },
      {
        name: this.$translate.instant('contacts.email'),
        field: 'email',
        enableColumnMenu: false,
        enableSorting: false,
        minWidth: 100,
        enableFiltering: false
      },
      {
        name: this.$translate.instant('general.user'),
        field: 'user',
        enableFiltering: false,
        enableSorting: false,
        width: 250,
        enableColumnMenu: false,
        cellTemplate:
          `<span class="user-cell">
            <todo-user user="grid.getCellValue(row, col)">
            </todo-user>
          </span>`,
      }
    ];
  }

  gridOptions( columnDef, isModal ) {
    return {
      rowHeight: 51,
      enableSorting: true,
      enableGridMenu: false,
      enableFiltering: true,
      enableHorizontalScrollbar: true,
      enableFullRowSelection: isModal? true : false,
      columnDefs: ( columnDef || this.columnsMain(isModal) ),
      enableRowSelection: true,
      enableSelectAll: true,
      fastWatch: true,
      paginationPageSize: 20,
      useExternalPagination: true,
      enableColumnResizing: true,
      useExternalSorting: true,
      useExternalFiltering: true,
      enablePaginationControls: false,
      paginationCurrentPage: 1,
      rowTemplate: '<div ng-class="{highlighted: row.entity.preview}" ng-click="grid.appScope.fnOne(row)" ng-repeat="col in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ui-grid-cell></div>'
    };
  }

  columns() {
    return this.columns;
  }

  getListDeleted(params) {
    return this.Http.get('/contacts/deleted', params);
  }

  get(params) {
    return this.getList(params);
  }

  bulkEdit(params) {
    return this.Http.post('/contacts/bulk_edit', params);
  }

  recoverModal(checked_ids) {
    return this.Modal.open({
      size: 'md',
      component: 'trashbinRecoverModal',
      resolve: {
        users: () => this.User.getList({ fields: { user: ['id', 'name'] } }),
        objectIds: () => checked_ids,
        module: () => 'Contact'
      }
    });
  }

  previewModal(record) {
    return this.Modal.open({
      animation: false,
      size: 'sidebar',
      backdrop: true,
      component: 'contactPreviewModal',
      resolve: {
        record: () => record
      }
    });
  }

  importProgressModal(params) {
    return this.Modal.open({
      size: 'md',
      component: 'contactImportProgress',
      resolve: {
        userId: () => params.userId,
        recordStatus: () => params.recordStatus,
        columnSettings: () => params.columnSettings,
        rowChuncked: () => params.rowChuncked
      }
    });
  }

  recover(obj) {
    return this.Http.put('/contacts/recover',
      { ids: obj.ids, recover_type: obj.key, recover_value: obj.value });
  }


  update(id, params) {
    return this.Http.put('/contacts/' + id, params);
  }

  create(params) {
    return this.Http.post('/contacts', params);
  }

  getSettings() {
    return this.Http.get('/contacts/settings');
  }

  getAssignedContacts(id) {
    return this.Http.get('/contacts/' + id + '/assigned_contacts');
  }

  importContacts(userId, recordStatus, columns, rows) {
    const params = angular.toJson(
      {
        user_id: userId,
        record_status_id: recordStatus,
        workspace_id: this.Workspace.currentWorkspaceId(),
        ...{ columns },
        ...{ rows }
      }
    );

    return this.Http.post('/contacts/import', params);
  }

  makeCopy(contact,settings) {
    return this.Modal.open({
      size: 'md',
      component: 'contactNew',
      resolve: {
        contact: () => {
          this.copyout = angular.copy(contact);
          this.copyout.id = null;
          return this.copyout;
        },
        settings:  () => {
          return settings;
        }
      }
    });
  }

  new(params = undefined) {
    return this.Modal.open({
      size: 'md',
      component: 'contactNew',
      resolve: {
        contact: () => params
      }
    });
  }

  multipleChangeOwnerModal(checked_ids) {
    return this.Modal.open({
      size: 'md',
      component: 'ownerModal',
      resolve: {
        objectIds: function() {
          return checked_ids;
        },
        objectClass: function() {
          return 'contacts';
        }
      }
    });
  }

  multipleDeleteModal(checked_ids) {
    return this.Modal.open({
      size: 'md',
      component: 'bulkRecordDestroyModal',
      resolve: {
        objectIds: function () {
          return checked_ids;
        },
        objectType: () => 'Contact',
      },
    });
  }

  importParse(file) {
    return this.Upload.upload({ url: window.apiHost + '/contacts/parse_file', data: { file } });
  }

  moveToTrash(contact_id) {
    return this.Http.delete('/contacts/' + contact_id);
  }

  multipleDelete(contactIds) {
    return this.Http.put('/contacts/delete_multiple', { ids: contactIds });
  }

  serialize(contact) {
    var contact_attributes = {
      name: contact.name,
      phone: contact.phone,
      email: contact.email,
      additional_attributes: contact.additional_attributes,
      custom_fields: contact.custom_fields,
      workspace_id: contact.workspace_id,
      connected_record_relations: contact.connected_record_relations
    };
    return contact_attributes;
  }
}
export default Contact;

