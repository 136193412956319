import template from './estate-show.pug';
export const EstateShowComponent = {
  template,
  bindings: {
    estate: '<'
  },
  controller: class EstateShowComponent {
    constructor(CustomField, Estate, AlertBox, $stateParams, $async, $log, $state, Workspace) {
      'ngInject';
      this.Workspace = Workspace;
      this.Estate = Estate;
      this.CustomField = CustomField;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.$state = $state;
      this.$stateParams = $stateParams;
      this.isShowModalVisible = false;
      this.updateUser = $async(this.updateUser.bind(this));
    }

    $onInit() {
      this.Workspace.setWorkspaceOnRecordShowLink(this.estate);
      if (this.$stateParams.step) {
        this.openEdit(this.$stateParams.step);
      }
    }


    estateUpdated({ record }) {
      this.estate = {
        ...this.estate,
        ...record
      };
    }

    closeEstateModal({ record }) {
      this.estateUpdated({ record });
      this.isShowModalVisible = false;
    }

    async updateUser({ user_id }) {
      try {
        this.estate = await this.Estate.update(this.estate.id, { user_id });
        this.AlertBox.addMessage('alert.updated',{ type: 'success' });
      } catch (e) {
        this.$log.error(e);
      }
    }


    openEdit(step) {
      this.$state.go('.', { step: step }, { notify: false, reload: false });
      this.sidebarCurrentStep = step;
      this.isShowModalVisible = true;
    }

  }
};
