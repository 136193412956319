import template from './table.pug';

export const TableComponent = {
  template,
  bindings: {
    loader: '<'
  },
  transclude: true,
  controller: class TableComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}
  }
};
