class Portal {

  constructor(Http, $translate, Modal) {
    'ngInject';
    this.Http = Http;
    this.Modal = Modal;
    this.$translate = $translate;
  }

  getAvailablePortals(params = {}) {
    return this.Http.get('/portals', params);
  }

  getList(params = {}) {
    return this.Http.get('/accounts/portals', params);
  }

  forExport() {
    return this.Http.get('/accounts/portals/for_export');
  }

  find(id) {
    return this.Http.get('/accounts/portals/'+id);
  }

  getEstates(id, searchId, params) {
    return this.Http.get('/accounts/portals/'+id+'/estates/'+ searchId, params);
  }

  createAccountPortal(params = {}) {
    return this.Http.post('/accounts/portals', params);
  }

  deleteAccountPortal(id) {
    return this.Http.delete(`/accounts/portals/${id}`);
  }

  on(id) {
    return this.Http.put('/accounts/portals/' + id, { on: true });
  }

  off(id) {
    return this.Http.put('/accounts/portals/' + id, { on: false });
  }

  update(id, data) {
    return this.Http.put('/accounts/portals/' + id, data);
  }

  newAccountPortalModal(type = 'xml') {
    return this.Modal.open({
      size: 'md',
      component: 'portalNew',
      resolve: {
        portalType: () => type
      }
    });
  }

  customViewModal(object) {
    return this.Modal.open({
      component: 'customViewModal',
      size: 'md',
      resolve: {
        module: () => 'Estate',
        customViewObject: () => object,
        searches: () => []
      }
    });
  }

  serialize(portal, selected = []) {
    var portal_attributes = {
      ...portal,
    };
    function pick(obj, keys) {
      return keys.map(k => k in obj ? { [k]: obj[k] } : {})
        .reduce((res, o) => Object.assign(res, o), {});
    }
    if (selected.length > 0) {
      portal_attributes = pick(portal_attributes,selected);
    }

    return portal_attributes;
  }

}
export default Portal;
