// base component
import { InputGroupComponent } from './input-group.component';

// components
import { InputGroupCustomFieldsComponent } from './custom-fields/input-group-custom-fields.component';
import { InputGroupCustomFieldsShowComponent } from './custom-fields/show/input-group-custom-fields-show.component';

import { CustomFieldsInputComponent } from './custom-fields/custom-fields-input/custom-fields-input.component';
import { CustomFieldsInputShowComponent } from './custom-fields/custom-fields-input/show/custom-fields-input-show.component';

import { InputGroupDateRangeComponent } from './range/date/input-group-date-range.component';
import { InputGroupDateRangeSelectComponent } from './select/date-range/input-group-date-range-select.component';
import { InputGroupRangeComponent } from './range/input-group-range.component';
import { InputGroupRangeShowComponent } from './range/show/input-group-range-show.component';
import { InputGroupPriceRangeComponent } from './range/price/input-group-price-range.component';

import { InputConnectedRecordComponent } from './connected-record/input-connected-record.component';
import { CustomFieldLockableComponent } from './custom-field-lockable/custom-field-lockable.component';

export const InputGroupModule = angular.module('topbroker.inputgroups', [])
  .component('inputGroup', InputGroupComponent)
  .component('inputGroupCustomFields', InputGroupCustomFieldsComponent)
  .component('inputGroupCustomFieldsShow', InputGroupCustomFieldsShowComponent)
  .component('customFieldsInput', CustomFieldsInputComponent)
  .component('customFieldsInputShow', CustomFieldsInputShowComponent)
  .component('inputGroupDateRange', InputGroupDateRangeComponent)
  .component('inputGroupDateRangeSelect', InputGroupDateRangeSelectComponent)
  .component('inputGroupRange', InputGroupRangeComponent)
  .component('inputGroupRangeShow', InputGroupRangeShowComponent)
  .component('inputGroupPriceRange', InputGroupPriceRangeComponent)

  .component('inputConnectedRecord', InputConnectedRecordComponent)
  .component('customFieldLockable', CustomFieldLockableComponent)

  .name;
