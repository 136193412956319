import template from './inquiry-card.pug';

export const InquiryCardComponent = {
  template,
  bindings: {
    record: '<?',
    recordId: '<?',
    onRemove: '&?',
  },
  controller: class InquiryCardComponent {
    constructor(Inquiry, $log, $async, $window) {
      'ngInject';

      this.$log = $log;
      this.Inquiry = Inquiry;
      this.$window = $window;
      this.getRecord = $async(this.getRecord.bind(this));
      this.getFullRecord = $async(this.getFullRecord.bind(this));
    }

    $onInit() {
      if (this.recordId) this.getRecord();
    }

    async getFullRecord() {
      if (this.fullRecord) return;

      try {
        this.fullRecord = await this.Inquiry.find(this.record.id);
      } catch (e) {
        this.fullRecord = this.record;
        this.$log.error(e);
      }
    }

    open() {
      this.$window.open(this.link(this.record), '_blank');
    }

    link(record) {
      return ('/inquiries/show/' + record.id);
    }

    getLocations(record) {
      if (record && record.locations) {
        return record.locations.map( l => l.name ).join(', ');
      }
    }

    async getRecord() {
      try {
        this.record = await this.Inquiry.cardInfo(this.recordId);
      } catch (e) {
        this.$log.error(e);
      }
    }

    removeEnabled() {
      return typeof(this.onRemove) === 'function';
    }

    remove(record) {
      this.onRemove({
        $event: { record }
      });
    }

  }
};
