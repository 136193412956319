import template from './additional-fields-edit-form.pug';

export const AdditionaFieldsEditFormComponent = {
  template,
  bindings: {
    inquiry: '<?',
    onCancel: '&?',
    onSave: '&?',
    cfGroups: '<',
  },
  controller: class AdditionaFieldsEditFormComponent {
    constructor(Estate, Locations, AlertBox) {
      'ngInject';
      this.Estate = Estate;
      this.Locations = Locations;
      this.AlertBox = AlertBox;
      this.options = {
        operations: ['for_sale', 'for_rent', 'auction'],
        estateTypes: Estate.types()
      };
      this.locationSelectize = {
        labelField: 'name',
        create: false,
        searchField: 'name',
        valueField: 'id',
        maxOptions: 100,
        maxItems: null,
        persist: true,
        hideSelected: true,
        loadThrottle: 400,
        load: (query, callback) => {
          if (!query.length) return callback();
          this.Locations.searchLocation(query).then((r) => {
            callback(r);
          });
        },
      };
    }

    $onInit() {
      if (this.inquiry == undefined) {
        this.inquiry = {
          operation: 'for_sale',
          estate_type_id: this.Estate.types()[0].id,
          custom_fields: {},
          locations: []
        };
      }
      if (this.inquiry.locations) {
        this.locationOptions = this.inquiry.locations;
        this.inquiry.locations = this.inquiry.locations.map(elm => elm.id);
      }
    }

    check() {
      this.stateParams.formDirty = this.tmp !== JSON.stringify(this.inquiry);
      this.inquiry = angular.copy(this.inquiry);
    }

    setCopy() {
      this.tmp = JSON.stringify(this.estate);
    }

    cancel() {
      this.onCancel({
        $event: { state: 'show' }
      });
    }

    save(inquiry, form) {
      if (form.$valid) {
        this.onSave({
          $event: { inquiry }
        });
      } else {
        this.AlertBox.addMessage('validation_messages.fill_required_fields', { type: 'warning' });
      }
    }
  }
};
