import dayjs from 'dayjs';

angular.module('dhx-gantt', [])
  .directive('dhxGantt', ['$translate', '$localStorage', 'Todo', function($translate, $localStorage) {
    return {
      restrict: 'A',
      transclude: true,
      template: '<div ng-transclude></div>',
      scope: false,
      link:function ($scope, $element, $attrs) {

        //watch data collection, reload on changes
        let userList = [];
        let recordStatusList = [];
        let milestones = [];

        $scope.$watch($attrs.users, function(collection) {
          userList = collection;
        });

        $scope.$watch($attrs.statuses, function(collection) {
          recordStatusList = collection;
        });

        $scope.$watch($attrs.milestones, function(collection) {
          milestones = collection;
          if (milestones.length > 0) {
            milestones.forEach(function(milestone) {
              gantt.addMarker({
                start_date: new Date(milestone.date_at),
                css: 'today',
                text: milestone.name,
                title: milestone.date_at
              });
            });
          }
        });

        $scope.$watch($attrs.data, function(collection) {
          gantt.clearAll();
          gantt.parse(collection, 'json');
        });

        // size of gantt
        $scope.$watch(function() {
          return $element[0].offsetWidth + '.' + $element[0].offsetHeight;
        }, function() {
          gantt.setSizes();
        });

        gantt.config.grid_width = 500;
        gantt.config.sort = false;
        gantt.config.order_branch = true;
        gantt.config.order_branch_free = true;

        function assignedUser(task) {
          if (task.user) {
            task.color = task.user.color;
            if (task.user.image_url) {
              return '<div style="background-image: url('+ task.user.image_url +')" class="gantt-user-avatar" title="'+task.user.name+'"></div>';
            } else {
              return '<div style="background:'+task.user.color+'" class="gantt-user-avatar" title="'+task.user.name+'">'+ task.user.name.toInitials() +'</div>';
            }
          } else {
            return '';
          }
        }

        function assignedRecordStatus(task) {
          if (task.record_status_id) {
            const recordStatus = recordStatusList.find((status) => status.id === task.record_status_id);
            return '<div style="background:'+recordStatus.color+'" class="gantt-record-status" title="'+recordStatus.title+'"></div>';
          } else {
            return '';
          }
        }

        gantt.config.columns = [
          { name:'sequential_id', align: 'center', width: 30 },
          { name:'custom_gantt_no', align: 'center', width: 30 },
          { name:'text', tree:true, map_to: 'text', width:'*', resize:true, align: 'left', template: function (task) {
            return '<span class="gantt-title">'+task.text+ ' </span>';
          } },
          { name:'start_date', align: 'center', width: 50, template: function (task) {
            return '<span class="gantt-date">'+ dayjs(task.start_date).format('MM-DD')+ ' </span>';
          } },
          { name:'relations', align: 'center', width:60, template: function (task) {
            let relations = [task.sequential_id];
            if (task.$source && task.$source.length > 0) {
              task.$source.forEach((link_id) => {
                relations.push(gantt.getTask(gantt.getLink(link_id).target).sequential_id);
              });
              return '<span class="gantt-relations">'+relations.join(';')+ ' </span>';
            }
          } },
          { name:'record_status_id', width: 36, align:'center', template: function (task) { return assignedRecordStatus(task); } },
          { name:'user', width: 36, align:'center', template: function (task) { return assignedUser(task); } },
          { name: 'add', width: 44 }
        ];
        // init gantt
        gantt.config.date_format = '%Y-%m-%d %H:%i';
        // gantt.config.fit_tasks = true;
        gantt.config.open_tree_initially = true;
        gantt.config.scale_height = 50;
        gantt.config.link_line_width = 1.25;
        gantt.config.row_height = 32;
        gantt.config.task_height = 28;
        gantt.config.grid_resize = true;

        gantt.plugins({
          tooltip: true,
          marker: true
        });




        function lightboxUser(task) {
          const selectedUserId = task.user ? task.user.id : null;

          task.user_template = '<select id="ganttUsers">';
          task.user_template += '<option value="">&nbsp;</option>';
          userList.forEach(user => {
            task.user_template += '<option value="'+user.id+'" '+ ((user.id == selectedUserId) ? 'selected' : '') +'>'+user.name+'</option>';
          });
          task.user_template += '</select>';

          window.setTimeout(function() {
            const userSelect = document.getElementById('ganttUsers');
            userSelect.addEventListener('change', function(event) {
              const user = userList.find(user => user.id == event.target.value);
              task.user = user;
              task.assign_user_id = user.id;
              gantt.updateTask(task.id);

            }, 100);
          });
        }

        function lightboxRecordStatus(task) {
          const selectedRecordStatusId = task.record_status_id;

          if (!selectedRecordStatusId) {
            task.record_status_id = recordStatusList[0].id;
            gantt.updateTask(task.id);
          }

          task.record_status_template = '<select id="ganttStatus">';
          recordStatusList.forEach(status => {
            task.record_status_template += '<option value="'+status.id+'" '+ ((status.id == selectedRecordStatusId) ? 'selected' : '') +'>'+status.title+'</option>';
          });
          task.record_status_template += '</select>';

          window.setTimeout(function() {
            const recordStatusSelect = document.getElementById('ganttStatus');
            recordStatusSelect.addEventListener('change', function(event) {
              task.record_status_id = parseInt(event.target.value);
              gantt.updateTask(task.id);
            }, 100);
          });

        }

        gantt.attachEvent('onBeforeLightbox', function(id) {
          let task = gantt.getTask(id);
          lightboxUser(task);
          lightboxRecordStatus(task);

          return true;
        });

        gantt.config.lightbox.sections=[
          { name:'custom_gantt_no', height:30, map_to:'custom_gantt_no', type:'textarea' },
          { name:'description', height:70, map_to:'text', type:'textarea', focus:true },
          { name:'time',        height:30, map_to:'auto', type:'duration' },
          { name:'record_status_id',        height:30, map_to: 'record_status_template', type: 'template' },
          { name:'user',        height:50, map_to: 'user_template', type: 'template' },
        ];

        gantt.attachEvent('onLinkClick', (_id, e) => {
          let event = new MouseEvent('dblclick', {
            view: window,
            bubbles: true,
            cancelable: true
          });
          e.target.dispatchEvent(event);
        });

        gantt.attachEvent('onGanttReady', function() {
          var tooltips = gantt.ext.tooltips;
          tooltips.tooltip.setViewport(gantt.$task_data);
        });

        gantt.templates.tooltip_text = function(start,_end,task) {
          task.end_date = gantt.date.add(task.end_date, -1, 'minute');

          const recordStatus = recordStatusList.find(status => status.id == task.record_status_id);

          let toolTip = '<b>'+ task.text +'</b><br/>' +
          dayjs(start).format('MM-DD') + ' - '+dayjs(task.end_date).format('MM-DD')
          + ' (' + task.duration + ' d.)<br/>';

          if (recordStatus) {
            toolTip += '<div class="my-1 d-flex align-items-center tt">'+ assignedRecordStatus(task)
            + '<div class="ml-2">'+ recordStatus.title +'</div></div>';
          }

          if (task.user) {
            toolTip += '<div class="my-1 d-flex align-items-center tt">'+ assignedUser(task)
            + '<div class="ml-2">'+ (task.user ? task.user.name : '' ) +'</div></div>';
          }

          return toolTip;
        } ;

        gantt.config.type_renderers[gantt.config.types.project] = function(task) {
          var main_el = document.createElement('div');
          main_el.setAttribute(gantt.config.task_attribute, task.id);
          var size = gantt.getTaskPosition(task);
          main_el.innerHTML = [
            '<div class=\'project-left\'></div>',
            '<div class=\'project-right\'></div>'
          ].join('');
          main_el.className = 'custom-project';

          main_el.style.left = size.left + 'px';
          main_el.style.top = size.top + 7 + 'px';
          main_el.style.width = size.width + 'px';

          return main_el;
        };

        gantt.templates.grid_row_class = function(_start, _end, task) {
          if (task.type == gantt.config.types.project) {
            return 'project-line';
          }
        };
        gantt.templates.timeline_cell_class = function(_item,date) {
          if (date.getDay()==0||date.getDay()==6) {
            return 'weekend' ;
          }
        };

        taskLinkHandler();
        setTranslations($translate);
        setZoomLevels($translate, $localStorage);

        gantt.init($element[0]);
      }
    };
  }])
  .directive('ganttTemplate', ['$interpolate ', function($interpolate) {
    return {
      restrict: 'AE',
      terminal:true,

      link:function(_$scope, $element, $attrs, _$controller) {
        var interpolated = $interpolate($element.html());
        gantt.templates[$attrs.ganttTemplate] = function(_start, _end, task) {
          return interpolated({ task: task });
        };
      }
    };
  }])
  .directive('ganttColumn', ['$interpolate', function($interpolate) {
    return {
      restrict: 'AE',
      terminal:true,

      link:function(_$scope, $element, $attrs, _$controller) {
        var label  = $attrs.label || ' ';
        var width  = $attrs.width || '*';
        var align  = $attrs.align || 'left';


        var interpolated = $interpolate($element.html());
        var template = function(task) {
          return interpolated({ task: task });
        };

        var config = { template:template, label:label, width:width, align:align };

        if (!gantt.config.columnsSet)
          gantt.config.columnsSet = gantt.config.columns = [];

        if (!gantt.config.columns.length)
          config.tree = true;
        gantt.config.columns.push(config);

      }
    };
  }])
  .directive('ganttColumnAdd', [function() {
    return {
      restrict: 'AE',
      terminal:true,
      link:function() {
        gantt.config.columns.push({ width:45, name:'add' });
      }
    };
  }]);

function setZoomLevels($translate, $localStorage) {
  var zoomConfig = {
    levels: [
      {
        name:'day',
        scale_height: 50,
        min_column_width: 36,
        scales:[
          { unit: 'month', format: '%F' },
          {
            unit: 'day', step: 1, format: '%d'
          }
        ]
      },
      {
        name:'week',
        scale_height: 50,
        min_column_width:50,
        scales:[
          { unit: 'week', step: 1, format: function (date) {
            let dateToStr = gantt.date.date_to_str('%m-%d');
            let endDate = gantt.date.add(date, -6, 'day');
            let weekNum = gantt.date.date_to_str('%W')(date);
            return '#' + weekNum + ', ' + dateToStr(endDate) + ' - ' + dateToStr(date);
          } },
          { unit: 'day', step: 1, format: '%j %D' }
        ]
      },
      {
        name:'month',
        scale_height: 50,
        min_column_width:120,
        scales:[
          { unit: 'month', format: '%F, %Y' },
          { unit: 'week', format: function (date) {
            let weekNum = gantt.date.date_to_str('%W')(date);
            return `#${weekNum}, ${$translate.instant('todos.reminder_units.weeks')}`;
          } }
        ]
      },
      {
        name:'quarter',
        height: 30,
        min_column_width:90,
        scales:[
          {
            unit: 'quarter', step: 1, format: function (date) {
              let dateToStr = gantt.date.date_to_str('%M');
              let endDate = gantt.date.add(gantt.date.add(date, 3, 'month'), -1, 'day');
              return dateToStr(date) + ' - ' + dateToStr(endDate);
            }
          },
          { unit: 'month', step: 1, format: '%M' },
        ]
      },
      {
        name:'year',
        scale_height: 50,
        min_column_width: 30,
        scales:[
          { unit: 'year', step: 1, format: '%Y' }
        ]
      }
    ],
    useKey: 'ctrlKey',
    trigger: 'wheel',
    element: function() {
      return gantt.$root.querySelector('.gantt_task');
    }
  };

  gantt.ext.zoom.init(zoomConfig);
  gantt.ext.zoom.setLevel($localStorage.gantt['zoomLevel'] ||  'day');
}

function taskLinkHandler() {
  gantt.eachSuccessor = function(callback, root) {
    if (!this.isTaskExists(root))
      return;

    // remember tasks we've already iterated in order to avoid infinite loops
    var traversedTasks = arguments[2] || {};
    if (traversedTasks[root])
      return;
    traversedTasks[root] = true;

    var rootTask = this.getTask(root);
    var links = rootTask.$source;
    if (links) {
      for (var i=0; i < links.length; i++) {
        var link = this.getLink(links[i]);
        if (this.isTaskExists(link.target) && !traversedTasks[link.target]) {
          callback.call(this, this.getTask(link.target));

          // iterate the whole branch, not only first-level dependencies
          this.eachSuccessor(callback, link.target, traversedTasks);
        }
      }
    }
  };
}

function setTranslations($translate) {
  // table translations
  gantt.i18n.setLocale({
    date: {
      month_full: [
        $translate.instant('todos.gantt.month_full.0'),
        $translate.instant('todos.gantt.month_full.1'),
        $translate.instant('todos.gantt.month_full.2'),
        $translate.instant('todos.gantt.month_full.3'),
        $translate.instant('todos.gantt.month_full.4'),
        $translate.instant('todos.gantt.month_full.5'),
        $translate.instant('todos.gantt.month_full.6'),
        $translate.instant('todos.gantt.month_full.7'),
        $translate.instant('todos.gantt.month_full.8'),
        $translate.instant('todos.gantt.month_full.9'),
        $translate.instant('todos.gantt.month_full.10'),
        $translate.instant('todos.gantt.month_full.11'),
      ],
      month_short: [
        $translate.instant('todos.gantt.month_short.0'),
        $translate.instant('todos.gantt.month_short.1'),
        $translate.instant('todos.gantt.month_short.2'),
        $translate.instant('todos.gantt.month_short.3'),
        $translate.instant('todos.gantt.month_short.4'),
        $translate.instant('todos.gantt.month_short.5'),
        $translate.instant('todos.gantt.month_short.6'),
        $translate.instant('todos.gantt.month_short.7'),
        $translate.instant('todos.gantt.month_short.8'),
        $translate.instant('todos.gantt.month_short.9'),
        $translate.instant('todos.gantt.month_short.10'),
        $translate.instant('todos.gantt.month_short.11'),
      ],
      day_full: [
        $translate.instant('todos.gantt.day_full.0'),
        $translate.instant('todos.gantt.day_full.1'),
        $translate.instant('todos.gantt.day_full.2'),
        $translate.instant('todos.gantt.day_full.3'),
        $translate.instant('todos.gantt.day_full.4'),
        $translate.instant('todos.gantt.day_full.5'),
        $translate.instant('todos.gantt.day_full.6'),
      ],
      day_short: [
        $translate.instant('todos.gantt.day_short.0'),
        $translate.instant('todos.gantt.day_short.1'),
        $translate.instant('todos.gantt.day_short.2'),
        $translate.instant('todos.gantt.day_short.3'),
        $translate.instant('todos.gantt.day_short.4'),
        $translate.instant('todos.gantt.day_short.5'),
        $translate.instant('todos.gantt.day_short.6'),
      ],
    },
    labels: {
      new_task: $translate.instant('todos.gantt.new_task'),
      icon_save: $translate.instant('todos.gantt.icon_save'),
      icon_cancel: $translate.instant('todos.gantt.icon_cancel'),
      icon_details: $translate.instant('todos.gantt.icon_details'),
      icon_edit: $translate.instant('todos.gantt.icon_edit'),
      icon_delete: $translate.instant('todos.gantt.icon_delete'),
      gantt_save_btn: $translate.instant('todos.gantt.gantt_save_btn'),
      gantt_cancel_btn: $translate.instant('todos.gantt.gantt_cancel_btn'),
      gantt_delete_btn: $translate.instant('todos.gantt.gantt_delete_btn'),
      confirm_closing: $translate.instant('todos.gantt.confirm_closing'),
      confirm_deleting: $translate.instant('todos.gantt.confirm_deleting'),
      section_description: $translate.instant('todos.gantt.section_description'),
      section_time: $translate.instant('todos.gantt.section_time'),
      section_type: $translate.instant('todos.gantt.section_type'),
      section_user: $translate.instant('todos.gantt.section_user'),
      section_custom_gantt_no: $translate.instant('todos.gantt.section_custom_gantt_no'),
      section_record_status_id: $translate.instant('todos.gantt.section_record_status_id'),

      column_wbs: '',
      column_text: '',
      column_start_date: '',
      column_duration: '',
      progress: '',
      column_add: '',

      link: $translate.instant('todos.gantt.link'),
      confirm_link_deleting: $translate.instant('todos.gantt.confirm_link_deleting'),
      link_start: $translate.instant('todos.gantt.link_start'),
      link_end: $translate.instant('todos.gantt.link_end'),

      type_task: $translate.instant('todos.gantt.type_task'),
      type_project: $translate.instant('todos.gantt.type_project'),
      type_milestone: $translate.instant('todos.gantt.type_milestone'),

      minutes: $translate.instant('todos.gantt.minutes'),
      hours: $translate.instant('todos.gantt.hours'),
      days: $translate.instant('todos.gantt.days'),
      weeks: $translate.instant('todos.gantt.weeks'),
      months: $translate.instant('todos.gantt.months'),
      years: $translate.instant('todos.gantt.years'),

      message_ok: $translate.instant('todos.gantt.message_ok'),
      message_cancel: $translate.instant('todos.gantt.message_cancel'),

      section_constraint: $translate.instant('todos.gantt.section_constraint'),
      constraint_type: $translate.instant('todos.gantt.constraint_type'),
      constraint_date: $translate.instant('todos.gantt.constraint_date'),
      asap: $translate.instant('todos.gantt.asap'),
      alap: $translate.instant('todos.gantt.alap'),
      snet: $translate.instant('todos.gantt.snet'),
      snlt: $translate.instant('todos.gantt.snlt'),
      fnet: $translate.instant('todos.gantt.fnet'),
      fnlt: $translate.instant('todos.gantt.fnlt'),
      mso: $translate.instant('todos.gantt.mso'),
      mfo: $translate.instant('todos.gantt.mfo'),

      resources_filter_placeholder: $translate.instant('todos.gantt.resources_filter_placeholder'),
      resources_filter_label: $translate.instant('todos.gantt.resources_filter_label')
    }
  });
}
