import template from './todo-chronology-form.pug';

export const TodoChronologyFormComponent = {
  template,
  bindings: {
    item: '<',
    recordClass: '<',
    formState: '<',
    onCreate: '&?',
    onClose: '&?',
    onDestroy: '&?'
  },
  controller: class TodoChronologyFormComponent {
    constructor(Chronology, AlertBox, ChronologyList, Todo, $async, $log) {
      'ngInject';
      this.Chronology = Chronology;
      this.AlertBox = AlertBox;
      this.Todo = Todo;
      this.ChronologyList = ChronologyList;
      this.$log = $log;
      this.saveChronology = $async(this.saveChronology.bind(this));
      this.destroyRecord = $async(this.destroyRecord.bind(this));
      this.todo = null;
    }

    $onInit() {
      if (this.item) {
        this.tmpTodo = angular.copy(this.item.record);
        this.todo = this.item.record || {};

        switch (this.recordClass) {
          case 'contacts':
            this.todo.relatedContact = this.item.chronology_record;
            break;
          case 'estates':
            this.todo.relatedEstate = this.item.chronology_record;
            break;
          case 'inquiries':
            this.todo.relatedInquiry = this.item.chronology_record;
            break;
        }
      }
    }

    close() {
      this.item.record = angular.copy(this.tmpTodo);
      this.todo = this.item.record;
      this.formState = 'show';
    }

    changeState({ state }) {
      if (state) this.formState = state;
    }

    async saveChronology({ todo }) {
      try {
        if (this.item.id) {
          this.todo = await this.Todo.getById(todo.id);
          this.changeState({ state: 'show' });
          this.AlertBox.addMessage('alert.updated');
        } else {
          this.changeState({ state: 'edit' });
          this.onCreate({ $event: {action: 'ReloadList'} });
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async destroyRecord() {
      try {
        await this.Todo.moveToTrash(this.todo.id);
        this.onDestroy({ $event: { chronologyItem: this.item } });
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
