import template from './input-group-date-range.pug';
export const InputGroupDateRangeComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    placeholderFrom: '@?',
    placeholderTo: '@?',
    from: '<',
    to: '<',
    validations: '<?',
    onEdit: '&?',
  },
  transclude : true,
  controller: class InputGroupDateRangeComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}

    edit() {
      this.onEdit({
        $event: {
          from: this.from,
          to: this.to
        }
      });
    }

  }
};
