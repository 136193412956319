
import { ErrorPagesComponent } from './error-pages';
import { NotFoundComponent } from './not-found/not-found.component';
import { NotFoundPageComponent } from './not-found/page/not-found-page.component';

import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { UnauthorizedUserComponent } from './unauthorized/user/unauthorized-user.component';


export const ErrorPagesModule = angular.module('topbroker.errors', [])
  .component('errorPages', ErrorPagesComponent)
  .component('notFound', NotFoundComponent)
  .component('notFoundPage', NotFoundPageComponent)
  .component('unauthorized', UnauthorizedComponent)
  .component('unauthorizedUser', UnauthorizedUserComponent)

  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('main.error', {
        url: '/error',
        component: 'errorPages',
        abstract: true,
      })
      .state('main.error.notfound', {
        url: '/404',
        component: 'notFound',
      })
      .state('main.error.notfoundpage', {
        url: '/notfound',
        component: 'notFoundPage'
      })
      .state('main.error.unauthorized', {
        url: '/303',
        component: 'unauthorized'
      })
      .state('main.error.unauthorized-user', {
        url: '/303',
        component: 'unauthorizedUser'
      });
  }).name;
