import template from './text-show.pug';

export const TextShowComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    model: '<',
    type: '<?',
  },
  transclude: true,
  controller: class TextShowComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
      if (this.type === undefined) {
        this.type = 'default';
      }
    }

  }
};
