import template from './estate-assignment-list-item.pug';

export const EstateAssignmentListItemComponent = {
  template,
  bindings: {
    estate: '<',
    onClick: '&?',
    isSelected: '<?'
  },
  controller: class EstateAssignmentListItemComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }

    doClick(estate) {
      this.onClick({ $event: { estate } });
    }
  }
};

