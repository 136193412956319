import template from './estate-toolbar.pug';

export const EstateToolbarComponent = {
  template,
  bindings: {
    totalRecords: '<'
  },
  controller: class EstateToolbarComponent {
    constructor($state) {
      'ngInject';

      this.$state = $state;
      this.totalRecords = 1;
      this.showEstateForm = false;
    }

    navigateToEstate({ record }) {
      this.showEstateForm = false;

      if (record && record.id) {
        this.$state.go('main.estates.show', { id: record.id });
      }
    }
  }
};
