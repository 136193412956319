
import { TodoGroupFormComponent } from './form/group/todo-group-form.component';
import { TodoGroupModalComponent } from './modal/group/todo-group-modal.component';
import { TodoGroupTableComponent } from './table/group/todo-group-table.component';

export const TodoSettingModule = angular.module('topbroker.todoSetting', [])
  .component('todoGroupForm', TodoGroupFormComponent)
  .component('todoGroupModal', TodoGroupModalComponent)
  .component('todoGroupTable', TodoGroupTableComponent)

  .name;
