import template from './user-form-show.pug';
import { isEmpty as _isEmpty } from 'lodash';
import { isLength as _isLength } from 'lodash';


export const UserFormShowComponent = {
  template,
  bindings: {
    user: '<',
    onUploadPhoto: '&?',
    onPassChange: '&?',
    cfGroups: '<',
    onMoveToArchive: '&?',
    onActivate: '&?',
    onEdit: '&?',
  },
  controller: class UserFormShowComponent {
    constructor(User, AlertBox, $async) {
      'ngInject';
      this.file;
      this.loader = false;
      this.User = User;
      this.AlertBox = AlertBox;
      this.resendInvite = $async(this.resendInvite.bind(this));
    }

    $onInit() {}

    uploadFiles() {
      this.loader = true;
      this.user.image_url = null;
      this.onUploadPhoto({
        $event: { file: this.file }
      });
    }

    isValidCfg() {
      return !_isEmpty(this.cfGroups);
    }

    edit() {
      this.onEdit({
        $event: { state: 'edit' }
      });
    }

    getName() {
      if (this.user.name == undefined) {
        return this.user.email;
      }
      return this.user.name;
    }

    async resendInvite() {
      try {
        const response = await this.User.resendInviteModal(this.user);
        if (response) {
          this.AlertBox.addMessage('users.invite_sent', { type: 'success' });
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    moveToTrash() {
      this.onMoveToArchive({
        $event: { state: 'trash' }
      });
    }

  }
};
