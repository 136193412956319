import template from './login.pug';

export const LoginComponent = {
  template,
  bindings: {},
  controller: class LoginComponent {
    constructor($async, $state, $auth, $stateParams, Account, User) {
      'ngInject';
      this.$state = $state;
      this.$auth = $auth;
      this.Account = Account;
      this.User = User;
      this.authenticate = $async(this.authenticate.bind(this));
      this.getVisual = $async(this.getVisual.bind(this));
      this.login = $async(this.login.bind(this));
      this.$stateParams = $stateParams;
      this.messages = this.$stateParams.messages || [];
      this.user = {
        password: '', email: ''
      };
      this.loaded = false;
      const check = this.$auth.getPayload();
      if (check && check.user && check.user.id) {
        return this.$state.go('main.dashboard.stats');
      } else {
        this.loaded = true;
      }
    }

    $onInit() {
      this.getVisual();

      google.accounts.id.initialize({
        client_id: window.GOOGLE_CLIENT_ID,
        callback: this.authenticate
      });
      google.accounts.id.renderButton(
        document.getElementById('gLoginBtn'),
        { theme: 'outline', size: 'large' }  // customization attributes
      );
      google.accounts.id.prompt(); // also display the One Tap dialog

    }


    async getVisual() {
      try {
        const resp = await this.Account.accountVisualIdentity();
        if (resp) {
          this.visualTitle = resp.title;
        }
      } catch (error) {
        console.error(error);
      }
    }

    async authenticate(google_response) {
      try {
        this.loading = true;
        const backend_response = await this.User.googleLoginByToken(google_response.credential);
        await this.$auth.setToken(backend_response.token);
        this.User.track();
        this.$state.go('main.dashboard.stats',{},{ reload: true });
      } catch (e) {
        this.user.password = '';
        this.messages = ['Invalid email or password'];
      } finally {
        this.loading = false;
      }
    }

    async login() {
      try {
        this.loading = true;
        const token = await this.Account.oauthTokens();
        const auth = await this.$auth.login({
          username: this.user.email,
          password: this.user.password,
          grant_type: 'password',
          client_id: token.client_id,
          client_secret: token.client_secret,
        });
        await this.$auth.setToken(auth.data.access_token);
        this.User.track();
        this.$state.go('main.dashboard.stats',{},{ reload: true });
      } catch (e) {
        this.user.password = '';
        this.messages = ['Invalid email or password'];
      } finally {
        this.loading = false;
      }
    }

    closeMessage(index) {
      this.messages.splice(index, 1);
    }

  }

};
