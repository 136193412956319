import template from './custom-fields-input.pug';

export const CustomFieldsInputComponent = {
  template,
  bindings: {
    record: '<?',
    customField: '<',
    onEdit: '&?',
    formObj: '<?',
  },
  controller: class CustomFieldsInputComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}

    isMultiselect(multiple_select) {
      if (typeof multiple_select === 'boolean') {
        return multiple_select ? null : 1;
      } else if (typeof multiple_select === 'string') {
        return multiple_select === 'true' ? null : 1;
      }
    }
  }
};
