import template from './estate-price-number.pug';

export const EstatePriceNumberComponent = {
  template,
  bindings: {
    estate: '<?',
    estateTypeId: '<?',
    onEdit: '&?'
  },
  controller: class EstatePriceNumberComponent {
    constructor( Estate, $async, $log, $translate) {
      'ngInject';
      this.Estate = Estate;
      this.$async = $async;
      this.$log = $log;
      this.$translate = $translate;
      this.currency = window.CURRENCY;
      this.priceOptions = this.Estate.priceTypes();
      this.price_model;
    }

    $onInit() {
      this.initializeValues();
      this.getPrice();
      this.price_model = this.price.view.price;
    }

    $onChanges({ estateTypeId, estate, type }) {
      if (
        (estate && estate.currentValue !== estate.previousValue) ||
        (type && type.previousValue !== type.currentValue) ||
        (estateTypeId && estateTypeId.previousValue !== estateTypeId.currentValue)
      ) {
        this.getPrice();
      }
    }

    initializeValues() {
      this.estate.price_type = this.estate.price_type || 'total';
    }

    getPrice() {
      this.price = this.Estate.resolvePrice(this.estate);
    }

    priceUpdate() {
      this.estate.price = this.price_model;
      this.getPrice();
      this.edit();
    }

    edit() {
      this.onEdit({
        $event: {
          estate: this.estate
        }
      });
    }
  }
};
