import template from './field-edit-modal.pug';

export const FieldEditModalComponent = {
  template,
  bindings: {
    resolve: '<',
    modalInstance: '<',
  },
  controller: class FieldEditModalComponent {
    constructor($async, $log, CustomField, AlertBox) {
      'ngInject';
      this.CustomField = CustomField;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.save = $async(this.save.bind(this));
    }

    $onInit() {
      this.group = this.resolve.group;
      this.fieldTmp = angular.copy(this.resolve.field);
      this.field = this.resolve.field;
    }

    async save({ field }) {
      const params = {
        ...field,
        custom_fields_group_id: this.group.id
      };
      try {
        this.loader = true;
        const field = await this.CustomField.editField(params);
        this.modalInstance.close(field);
        this.AlertBox.addMessage('alert.updated', { type: 'success' });
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    close() {
      this.modalInstance.close(this.fieldTmp);
    }

  }
};
