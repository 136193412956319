import flatpickr from 'flatpickr';

angular.module('angular-flatpickr', []).
  directive('ngFlatpickr', function () {
    return {
      require: 'ngModel',
      restrict: 'A',
      scope: {
        fpOpts: '&',
        fpOnSetup: '&'
      },
      link: function (scope, element) {

        if (!flatpickr) {
          console.warn('Unable to find any flatpickr installation');
          return;
        }

        var options = scope.fpOpts();
        var vp = flatpickr(element[0], options);

        if (scope.fpOnSetup) {
          scope.fpOnSetup({
            fpItem: vp
          });
        }

        // destroy the flatpickr instance when the dom element is removed
        element.on('$destroy', function () {
          vp.destroy();
        });
      }
    };
});
