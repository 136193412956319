import template from './todos-index.pug';

export const TodosIndexComponent = {
  template,
  bindings: {},
  controller: class TodosIndexComponent {
    constructor(Todo, AlertBox, $state, $log, $element, $stateParams, $translate, $async) {
      'ngInject';
      this.$state = $state;
      this.Todo = Todo;
      this.$log = $log;
      this.$translate = $translate;
      this.getTodos = $async(this.getTodos.bind(this));
      this.countTodos = $async(this.countTodos.bind(this));
      this.getCountedStates = $async(this.getCountedStates.bind(this));
      this.afterDestroy = $async(this.afterDestroy.bind(this));
      this.loadMore = $async(this.loadMore.bind(this));
      this.selectedState = $stateParams.state;
      this.selectedGroup = $stateParams.todo_group_id;
      this.AlertBox = AlertBox;
      this.states = Todo.states();

      this.scrollToTop = () => {
        $element.find('#todoList').scrollTop(0);
      };
      this.editableTodo = { id: 0 };
      this.groups = [];
      this.listLimit = 20;
      this.perPage = 20;
      this.page = 1;
      this.lastPage = 2;
      this.title = {
        state: this.states.find( s => s.id == $stateParams.state)
      };
    }

    $onInit() {
      this.showForm = false;
      this.getCountedStates();
      this.getTodos();
    }

    uiOnParamsChanged() {
      this.getTodos();
      this.getCountedStates();
      this.showForm = false;
    }

    hasTodo() {
      if (this.todos) {
        return this.todos.filter( todo => todo.show ).length > 0;
      }
    }


    async getTodos(more) {
      this.closeEdit();
      if (more) {
        this.page += 1;
      } else {
        this.lastPage = 2;
        this.page = 1;
      }
      const promise = new Promise(async (resolve, reject) => {
        try {
          const params = {
            state: this.$state.params.state,
            todo_group_id: this.$state.params.todo_group_id,
            page: this.page,
            per_page: this.perPage
          };


          const data = await this.Todo.getList(Object.assign(params, this._sortParamsByState(params.state)));
          if (more) {
            this.todos = this.todos = [
              ...this.todos,
              ...data.map(todo => {
                return { ...todo, show: true };
              })
            ];
          } else {
            this.todos = data.map(todo => {
              return { ...todo, show: true };
            });
          }
          return resolve(true);
        } catch (e) {
          this.$log.error(e);
          return reject(e);
        }
      });
      return promise;
    }

    async countTodos(params = {}) {
      try {
        const counter = await this.Todo.count(params);
        return counter.count;
      } catch (e) {
        this.$log.error(e);
      }
    }

    async loadMore(last, inview) {
      if (this.loading || this.lastPage == this.page) return;
      this.loading = true;
      if (last && inview) {
        await this.getTodos(true);
        this.listLimit += 20;
      }
      this.loading = false;
    }

    async getCountedStates() {
      try {
        let state_counter = [];
        this.states.forEach(async (state, index) => {
          state_counter[index] = await this.Todo.count({ state: state.id });
          this.states[index].count = state_counter[index].count || 0;
        });
      } catch (e) {
        this.$log.error(e);
      }
    }

    selectTodoGroup(group) {
      this.scrollToTop();
      this.title.group = group.name;
      this.selectedGroup = group.id;
      this.countParams = { state: this.selectedState };
      this.lastPage = Math.ceil(group.count / this.perPage);
      this.$state.go('main.todos.index', { todo_group_id: group.id });
    }

    selectTodoState(state) {
      this.todos = [];
      this.scrollToTop();
      this.title.state = this.states.find( s => s.id == state);
      this.selectedState = state;
      this.countParams = { state };
      this.$state.go('main.todos.index', { state: state });
    }

    stateChanged(todo) {
      this.todos[this.todos.indexOf(todo)].show = false;
      this.getCountedStates();
    }

    afterCreated({ todo }) {
      this.todos.push({ ...todo, show: true });
      this.getCountedStates();
      this.AlertBox.addMessage('alert.created.todo', { type: 'success' });
      this.showForm = false;
    }

    closeEdit() {
      this.editableTodo = { id: 0 };
    }

    async afterDestroy(todo) {
      try {
        await this.Todo.moveToTrash(todo.id);
        this.stateChanged(todo);
        this.AlertBox.addMessage('alert.deleted.todo', { type: 'success' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    edit(todo) {
      this.editableTodo = todo;
    }

    isEditable(todo) {
      return this.editableTodo.id === todo.id;
    }

    afterUpdated({ todo }) {
      this.getCountedStates();

      const todoIndex = this.todos.findIndex(t => t.id == todo.id);
      this.todos[todoIndex] = { ...todo, show: true };
      this.editableTodo = { id: 0 };
      this.AlertBox.addMessage('alert.updated.todo', { type: 'success' });
    }

    createNew() {
      this.showForm = true;

      const todo_group_id = parseInt(this.$state.params.todo_group_id);

      this.newTodo = {
        todo_group_id: todo_group_id
      };
    }

    _sortParamsByState(state) {
      return {
        'today': { sort_by: 'created_at', sort_to: 'desc' },
        'upcoming': { sort_by: 'starts_at', sort_to: 'asc' },
        'completed': { sort_by: 'completed_at', sort_to: 'desc' }
      }[state];
    }
  }
};
