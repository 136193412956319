import template from './custom-field-lockable.pug';

export const CustomFieldLockableComponent = {
  template,
  bindings: {
    record: '<',
    customField: '<'
  },
  transclude : true,
  controller: class CustomFieldLockableComponent {
    constructor(CustomField, User, AlertBox, $async, $log) {
      'ngInject';

      this.CustomField = CustomField;
      this.User = User;
      this.AlertBox = AlertBox;
      this.$log = $log;

      this.getLockStatus = $async(this.getLockStatus.bind(this));
      this.toggleLock = $async(this.toggleLock.bind(this));
    }

    $onInit() {
      this.getLockStatus();
    }

    async getLockStatus() {
      if (this.customField.lockable == false) return;

      try {
        this.customField.locked = await this.CustomField.lockStatus(this.customField.id, this.record.id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async toggleLock() {
      if (this.customField.locked && this.customField.locked.user_id != this.User.currentId()) {
        this.AlertBox.addMessage('custom_fields.unlock_warning', { type: 'warning' });
        return;
      }

      try {
        if (this.customField.locked && this.customField.locked.id) {
          this.customField.locked = await this.CustomField.unlock(this.customField.locked.id);
        } else {
          this.customField.locked = await this.CustomField.lock(this.customField.id, this.record.id);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

  }
};
