import template from './inquiry-location.pug';


export const InquiryLocationComponent = {
  template,
  bindings: {
    inquiry: '<',
    onUpdate: '&?'
  },
  controller: class InquiryLocationComponent {
    constructor(Inquiry, Locations, Geopify, Account, $async, $log) {
      'ngInject';
      this.Inquiry = Inquiry;
      this.Locations = Locations;
      this.Account = Account;
      this.$log = $log;
      this.updateLocation = $async(this.updateLocation.bind(this));
      this.getLocation = $async(this.getLocation.bind(this));
      this.markerDragEnd = $async(this.markerDragEnd.bind(this));
      this.save = $async(this.save.bind(this));
      this.changeRadius = $async(this.changeRadius.bind(this));
      this.location = {};
      this.locationTmp = {};
      this.Geopify = Geopify;
      this.loader = false;
      this.radiusUnit = this.Locations.radiusUnit();
      this.radiusOptions = [1,2,3,4,5,10,25,50,100];
    }

    $onInit() {
      if (!this.inquiry.location_id) {
        this.location = { radius: 1 };
      }
    }


    $onChanges({ inquiry }) {
      if (inquiry && inquiry.currentValue) {
        this.inquiry = inquiry.currentValue;
        this.getLocation();
      }
    }

    async getLocation() {
      try {
        if (this.inquiry && this.inquiry.location_id) {
          this.location = await this.Locations.find(this.inquiry.location_id);
          this.setMarker(this.location);
          this.Geopify.setRadius(this.location);
        } else {
          const countryData = await this.Account.getCountryData();
          const geo = countryData.data.geo;
          this.map.fitBounds([
            [geo.max_longitude, geo.max_latitude],
            [geo.min_longitude, geo.min_latitude],
          ]);
          this.map.setCenter([geo.longitude, geo.latitude]);
          this.map.setZoom(3);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    initMap() {
      this.map = this.Geopify.initMap('map');
      this.getLocation();
    }

    locationSelected(location) {
      this.location = location;
      this.setMarker(location);

      if (!this.location.radius) {
        this.location.radius = 1;
        this.location.radius_unit = this.radiusUnit;
        this.Geopify.setRadius(this.location);
      }

      this.updateLocation(this.location);
    }

    setMarker(location = {}) {
      this.marker = this.Geopify.setMarker(location);
      this.marker.on('dragend', () => {
        this.markerDragEnd();
      });
    }

    async changeRadius() {
      this.Geopify.setRadius(this.location);
      try {
        await this.Locations.update(this.location.id, { radius: this.location.radius });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async markerDragEnd() {
      try {

        const lngLat = this.marker.getLngLat();
        this.location.longitude = lngLat.lng;
        this.location.latitude = lngLat.lat;

        this.Geopify.setRadius(this.location);

        this.Geopify.reverseGeocode(lngLat.lat, lngLat.lng)
          .then(response => response.json())
          .then(result => {
            let location_params = this.Geopify.getLocationParams(result.features[0]);
            location_params.radius = this.location.radius;
            location_params.generic_module = 'inquiry';
            location_params.radius_units = this.radiusUnit;

            this.Locations.find_or_create_location(location_params).then((location) => {
              this.location = location;
              this.updateLocation(location);
            });
          });

      } catch (e) {
        this.$log.error(e);
      }
    }

    async updateLocation() {
      try {
        await this.Inquiry.update(this.inquiry.id, { location_id: this.location.id });
        if (this.onUpdate) this.onUpdate({ $event: { record: this.location } });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async save() {
      try {
        this.loader = true;
        await this.Inquiry.update(this.inquiry.id, { location_id: this.location.id });
        await this.Locations.find_or_create_location(this.location).then((location) => {
          this.location = location;
          this.updateLocation(location);
          this.loader = false;
        });
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
