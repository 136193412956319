import { TodoContactResponseComponent } from './todo-contact-response.component';
import TodoContactResponse from './todo-contact-response.service';

import { TodoContactResponseInfoComponent } from './todo-contact-response-info/todo-contact-response-info.component';

export const TodoContactResponseModule = angular
  .module('topbroker.todo-contact-response', [])
  .component('todoContactResponse', TodoContactResponseComponent)
  .component('todoContactResponseInfo', TodoContactResponseInfoComponent)

  .service('TodoContactResponse', TodoContactResponse)

  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('todo-contact-response', {
        url: '/event',
        component: 'todoContactResponse',
        abstract: true,
      })
      .state('todo-contact-response.info', {
        url: '/:token?',
        params: { token: { dynamic: true } },
        component: 'todoContactResponseInfo',
        resolve: {
          reminderInfo: [
            'TodoContactResponse',
            '$state',
            '$stateParams',
            async (TodoContactResponse, $state, $stateParams) => {
              try {
                const info = await TodoContactResponse.getReminderInfo($stateParams.token);
                return info;
              } catch (e) {
                if (e.status === 403) {
                  return $state.go('main.dashboard.stats');
                }
              }
            },
          ],
        },
      });
  }).name;
