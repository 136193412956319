import template from './todos-view-group-list.pug';

export const TodosViewGroupListComponent = {
  template,
  bindings: {
    selected: '<',
    countFilter: '<?',
    options: '<?',
    onSelect: '&?',
  },
  controller: class TodosViewGroupListComponent {
    constructor($async, $translate, Todo) {
      'ngInject';
      this.options = {
        multiple: false,
        selector: 'id',
      };
      this.Todo = Todo;
      this.$translate = $translate;
      this.getGroups = $async(this.getGroups.bind(this));
      this.groups = [{ id: 0, name: $translate.instant('todos.all'), count: 0 }];
      this.countFilter = {};
    }

    $onChanges({ countFilter }) {
      if (countFilter && countFilter.currentValue !== countFilter.previousValue) {
        this.getGroups();
      }
    }

    $onInit() {
      this.getGroups(true);
    }

    async getGroups(init) {
      try {
        let all_groups = await this.Todo.getGroupCounted({ filter: this.countFilter });
        const { count } = await this.Todo.count(this.countFilter);
        this.groups[0].count = count;

        this.groups = [this.groups[0], ...all_groups];
        const selectedGroup = this.groups.find((e) => e.id == this.selected);
        if (init) this.select(selectedGroup);
      } catch (e) {
        console.error(e);
      }
    }

    select(item) {
      this.selected = item.id;
      if (!this.onSelect) return;
      this.onSelect({ $event: { ...item } });
    }
  },
};
