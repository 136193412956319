import template from './button-select-show.pug';

export const ButtonSelectShowComponent = {
  template,
  bindings: {
    model: '<',
    label: '<?',
    translatePrefix: '@',
  },
  controller: class ButtonSelectShowComponent {
    constructor() {
      'ngInject';
    }
    $onInit() {
    }

  }
};