export default class Notification {
  constructor(Http) {
    'ngInject';
    this.Http = Http;
  }

  getList(page) {
    return this.Http.get('/notifications', { page });
  }

  markAsRead(id) {
    return this.Http.put(`/notifications/mark_as_read/${id}`);
  }

  markAllAsRead() {
    return this.Http.put('/notifications/mark_all_as_read');
  }
}
