import template from './proposal-show-form.pug';

export const ProposalShowFormComponent = {
  template,
  bindings: {
    record: '<',
    onEdit: '&?',
    onCopy: '&?',
    onDestroy: '&?',
  },
  controller: class ProposalShowFormComponent {
    constructor(Proposal, AlertBox, $async, $log, $window) {
      'ngInject';
      this.Proposal = Proposal;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.$window = $window;
      this.getContacts = $async(this.getContacts.bind(this));
      this.getAttachments = $async(this.getAttachments.bind(this));
      this.getEstates = $async(this.getEstates.bind(this));
      this.destroy = $async(this.destroy.bind(this));
      this.estates = [];
      this.contacts = [];
      this.attachments = [];
    }

    $onInit() {
      this.getContacts();
      this.getAttachments();
      this.getEstates();
      this.setUrl();
    }

    async getContacts() {
      try {
        this.contacts = await this.Proposal.getContacts(this.record.id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getAttachments() {
      try {
        this.attachments = await this.Proposal.getAttachments(this.record.id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getEstates() {
      try {
        this.estates = await this.Proposal.getProperties(this.record.id);
      } catch (e) {
        this.$log.error(e);
      }
    }


    async sendClient() {
      try {
        // this.loading = true;
        // const emails = await this.Proposal.inviteModal(this.item.chronology_record.email);
        // await this.Proposal.sendInvites(this.record.id, emails);
        this.AlertBox.addMessage('alert.submited.proposals',{type: 'success'});
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;
      }
    }

    setUrl() {
      this.url = this.$window.apProposal + this.record.token;
    }

    open() {
      this.setUrl();
      window.open(this.url);
    }

    change({ state }) {
      this.onEdit({
        $event: { state }
      });
    }

    edit() {
      this.change({state: 'edit'});
    }

    copy() {
      this.onCopy({
        $event: { record: this.record }
      });
    }

    async destroy() {
      const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });
      if (result && result.dismiss) return false;
      this.onDestroy({
        $event: { record: this.record }
      });
    }
  }
};
