import template from './settings-users-main.pug';

export const SettingsUsersMainComponent = {
  template,
  bindings: {},
  controller: class SettingsUsersMainComponent {
    constructor(User, $async, $log) {
      'ngInject';

      this.User = User;
      this.$log = $log;
      this.newUsers = $async(this.newUsers.bind(this));
    }

    $onInit() {
      this.filter = {
        archived: true,
        page: 1,
      };
    }

    async newUsers() {
      try {
        await this.User.assignInvites();
      } catch (e) {
        this.$log.error(e);
      }
    }
  }

};
