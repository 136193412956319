import template from './record-status-change.pug';

export const RecordStatusChangeComponent = {
  template,
  bindings: {
    resolve: '<',
    modalInstance: '<'
  },
  controller: class RecordStatusChangeComponent {
    constructor(RecordStatus, $async, $log) {
      'ngInject';
      this.RecordStatus = RecordStatus;
      this.$async = $async;
      this.$log = $log;
      this.fetch = $async(this.fetch.bind(this));
      this.selectedStatus = null;
    }

    $onInit() {
      if (this.resolve.module) {
        this.module = this.resolve.module;
        this.fetch();
      }
      if (this.resolve.selected) {
        this.selectedStatus = this.resolve.selected;
      }
    }

    select(status) {
      this.modalInstance.close(status);
    }

    async fetch() {
      try {
        this.record_statuses = await this.RecordStatus.getList(this.module);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    close() {
      this.modalInstance.close(void(0));
    }

  }
};
