import { CustomFieldToolbarComponent } from './toolbar/custom-field-toolbar.component';

import { GroupNewModalComponent } from './modal/group-new/group-new-modal.component';
import { GroupEditModalComponent } from './modal/group-edit/group-edit-modal.component';
import { GroupPositionModalComponent } from './modal/group-position/group-position-modal.component';

import { FieldEditModalComponent } from './modal/field-edit/field-edit-modal.component';
import { FieldNewModalComponent } from './modal/field-new/field-new-modal.component';

import { CustomFieldFormSelectComponent } from './form-select/custom-field-form-select.component';

import { CustomFieldTableComponent } from './table/custom-field-table.component';
import { CustomFieldBodyComponent } from './table/body/custom-field-body.component';
import { CustomFieldHeaderComponent } from './table/header/custom-field-header.component';

import { CustomFieldCheckboxFormComponent } from './form/checkbox/custom-field-checkbox-form.component';
import { CustomFieldDateFormComponent } from './form/date/custom-field-date-form.component';
import { CustomFieldEmailFormComponent } from './form/email/custom-field-email-form.component';
import { CustomFieldGroupFormComponent } from './form/group/custom-field-group-form.component';
import { CustomFieldLinkFormComponent } from './form/link/custom-field-link-form.component';
import { CustomFieldMultiselectFormComponent } from './form/multiselect/custom-field-multiselect-form.component';
import { CustomFieldNumberFormComponent } from './form/number/custom-field-number-form.component';
import { CustomFieldMoneyFormComponent } from './form/money/custom-field-money-form.component';
import { CustomFieldPhoneFormComponent } from './form/phone/custom-field-phone-form.component';
import { CustomFieldTextFormComponent } from './form/text/custom-field-text-form.component';
import { CustomFieldTextAreaFormComponent } from './form/text-area/custom-field-text-area-form.component';
import { CustomFieldConnectedRecordFormComponent } from './form/connected-record/custom-field-connected-record-form.component';

import { CustomFieldEstateTypes } from './estate-types/custom-field-estate-types.component';

export const CustomFieldSettingsModule = angular.module('topbroker.settings.customfield', [])

  .component('customFieldToolbar', CustomFieldToolbarComponent)

  .component('customFieldFormSelect', CustomFieldFormSelectComponent)

  .component('fieldEditModal', FieldEditModalComponent)
  .component('fieldNewModal', FieldNewModalComponent)

  .component('groupNewModal', GroupNewModalComponent)
  .component('groupEditModal', GroupEditModalComponent)
  .component('groupPositionModal', GroupPositionModalComponent)

  .component('customFieldTable', CustomFieldTableComponent)
  .component('customFieldBody', CustomFieldBodyComponent)
  .component('customFieldHeader', CustomFieldHeaderComponent)

  .component('customFieldCheckboxForm', CustomFieldCheckboxFormComponent)
  .component('customFieldDateForm', CustomFieldDateFormComponent)
  .component('customFieldEmailForm', CustomFieldEmailFormComponent)
  .component('customFieldGroupForm', CustomFieldGroupFormComponent)
  .component('customFieldLinkForm', CustomFieldLinkFormComponent)
  .component('customFieldMultiselectForm', CustomFieldMultiselectFormComponent)
  .component('customFieldNumberForm', CustomFieldNumberFormComponent)
  .component('customFieldMoneyForm', CustomFieldMoneyFormComponent)
  .component('customFieldPhoneForm', CustomFieldPhoneFormComponent)
  .component('customFieldTextForm', CustomFieldTextFormComponent)
  .component('customFieldTextAreaForm', CustomFieldTextAreaFormComponent)
  .component('customFieldConnectedRecordForm', CustomFieldConnectedRecordFormComponent)
  .component('customFieldEstateTypes', CustomFieldEstateTypes)

  .name;
