import template from './statistics.pug';

export const StatisticsComponent = {
  template,
  bindings: {},
  controller: class StatisticsComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}
  }
};
