import template from './custom-field-date-form.pug';

export const CustomFieldDateFormComponent = {
  template,
  bindings: {
    field: '<',
    group: '<',
    onSave: '&?',
    onBack: '&?',
    onClose: '&?',


  },
  controller: class CustomFieldDateFormComponent {
    constructor($translate) {
      'ngInject';
      this.$translate = $translate;

      this.selectedTab = 'field';

      this.record = {
        validations: {
          required: false,
        },
      };
      this.radioOptions = [
        { value: true, title: 'custom_fields.a_yes' },
        { value: false, title: 'custom_fields.a_no' }
      ];
    }

    $onInit() {
      if (this.field && this.field.id) {
        this.record = this.field;
      } else {
        this.record = { ...this.record, ...this.field };
        this.record.title = this.$translate.instant(this.field.name);
      }

      if (this.group.generic_module == 'estates') {
        this.tabs = ['field', 'estate_type', 'settings', 'preview'];
      } else {
        this.tabs = ['field','settings', 'preview'];
      }
    }

    back() {
      this.onBack({ $event: false });
    }

    close() {
      this.onClose({ $event: null });
    }

    save() {
      this.loading = true;
      this.onSave({ $event: { field: this.record } });
    }

  }
};
