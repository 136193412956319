import template from './change-password.pug';

export const ChangePasswordComponent = {
  template,
  bindings: {
    resetUserParams: '<'
  },
  controller: class ChangePasswordComponent {
    constructor($state, $auth, User) {
      'ngInject';
      this.$state = $state;
      this.$auth = $auth;
      this.User = User;
    }

    $onInit() {
      this.passwordStrength = 0;
      this.messages = [];
      this.passwordInputType = 'password';

      if (this.resetUserParams) {
        this.user = {
          token: this.resetUserParams.token,
          email: this.resetUserParams.email,
          password: null
        };
      } else {
        this.$state.go('auth.login');
      }
    }


    togglePasswordInputType() {
      this.passwordInputType = (this.passwordInputType == 'password') ? 'text' : 'password';
    }

    closeMessage(index) {
      this.messages.splice(index, 1);
    }

    validate(error, touched) {
      var valid = (Object.keys(Object(error)).length) && touched;
      return !!valid;
    }

    changePassword() {
      if (!this.user.password) return;
      this.changing = true;
      this.User.changePassword(this.user).then((user) => {
        this.$auth.setToken(user.token);
        this.$state.go('main.dashboard.stats');
      }).catch(() => {
        this.user.password = '';
        this.messages = ['auth.email_used'];
      });
    }

  }

};
