// base component
import { CardComponent } from './card.component';

// components
import { UserComponent } from './user/user.component';
import { BlockComponent } from './block/block.component';
import { BlockLargeComponent } from './block/large/block-large.component';

import { ReportCardComponent } from './report/report-card.component';

export const CardModule = angular.module('topbroker.cards', [])
  .component('card', CardComponent)
  .component('blockCard', BlockComponent)
  .component('blockCardLarge', BlockLargeComponent)

  .component('userCard', UserComponent)
  .component('reportCard', ReportCardComponent)
  .name;
