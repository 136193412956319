import template from './estate-table.pug';

export const EstateTableComponent = {
  template,
  bindings: {
    filter: '<',
    onFilterChange: '&',
    onPageChange: '&',
    onCheck: '&?',
    isModal: '<?',
  },
  controller: class EstateTableComponent {
    constructor($log, $stateParams, $localStorage, $async, $state, Estate, RecordStatus, AlertBox, TableEdit, Table) {
      'ngInject';
      this.$log = $log;
      this.$stateParams = $stateParams;
      this.$localStorage = $localStorage;
      this.reload = $state.reload;
      this.getEstates = $async(this.getEstates.bind(this));

      this.Estate = Estate;
      this.TableEdit = TableEdit;
      this.Table = Table;
      this.AlertBox = AlertBox;
      this.RecordStatus = RecordStatus;

      this.checkedIds = [];
      this.totalPages = 0;
      this.totalCount = 0;
      this.Table.activeFilters.loading = true;

    }

    $onChanges({ filter } = {}) {
      if (filter && filter.currentValue != filter.previousValue) {
        this.checkedIds = [];
        if (filter.page) {
          this.filter.page = filter.page;
        }
        this.getEstates();
      }
    }

    $onInit() {
      this.gridOptions = this.TableEdit.gridOptions(this.isModal);
      this.gridOptions.columnDefs = this.TableEdit.resolveColumns('estate', 'estate', this.isModal);
      this.selectedStatusId = this.RecordStatus.returnLastStatusId('estate');
      if (this.isModal) {
        this.getEstates();
      }
    }

    updatePage({ page }) {
      if (parseInt(page) > 0) {
        this.onPageChange({
          $event: { page }
        });
        this.checkedEstates([]);
      }
    }

    applyFilter({ filter, searchId, statusId }) {
      this.onFilterChange({
        $event: { filter: { ...this.filter, ...filter }, searchId, statusId }
      });
    }

    checkedEstates({ checkedIds }) {
      this.checkedIds = checkedIds;
      if (this.onCheck) {
        this.onCheck({
          $event: { checkedIds }
        });
      }
    }

    async getEstates() {
      try {

        if (!this.filter) return;

        if (this.Table.activeFilters.loading && !this.isModal) return;
        let searchId = this.$stateParams.searchId || -1;
        const apiFilter = this.filter;
        this.loader = true;

        // if (this.isModal) {
        //   searchId = -1;
        // }

        const { data, meta } = await this.Estate.getListBySearchIdNew(searchId, apiFilter);

        this.records = data;
        this.totalCount = meta.total_count;
        this.totalPages = meta.pages;
        this.recordStatuses = meta.record_statuses;

        if (meta.cf_names && meta.cf_names.length) {
          let settings = await this.$localStorage.settings['estate']['custom_table_fields'];
          settings.cf_names = meta.cf_names;
          const resp = this.TableEdit.recheckNames(settings, 'estate');
          if (resp.update) {
            this.gridOptions.columnDefs = this.TableEdit.resolveColumns('estate', this.isModal);
          }
        }

        if (meta.options) meta.options.forEach(element => {
          let field = this.gridOptions.columnDefs.find(elm => elm.field_key === element.technical_name);
          if (!field) return;

          if (element.field_type && element.field_type == 'connected_record') {
            field.filter.validations = element.validations;
          }

          if (element.options.length) {
            field.filter.selectOptions = element.options;
          }
        });
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    changeRecordStatus({ status }) {
      this.RecordStatus.setLastStatusId('estate', status.id);
      this.applyFilter({ filter: { ...this.filter, page: 1 }, statusId: status.id, searchId: this.$stateParams.searchId });
    }
  }
};
