import template from './contact-assignment-list-item.pug';

export const ContactAssignmentListItemComponent = {
  template,
  bindings: {
    contact: '<',
    onClick: '&?',
    isSelected: '<?'
  },
  controller: class ContactAssignmentListItemComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}

    doClick(contact) {
      this.onClick({ $event: { contact } });
    }
  }
};

