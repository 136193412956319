// base component
import { ChartsComponent } from './charts.component';
// Charts
import { RecordsOverTimeChartComponent } from './records-over-time-chart/records-over-time-chart.component';
import { RecordsByUserChartComponent } from './records-by-user-chart/records-by-user-chart.component';
import { CfMultiselectChartComponent } from './cf-multiselect-chart/cf-multiselect-chart.component';


export const ChartsModule = angular.module('topbroker.charts', [])
  .component('charts', ChartsComponent)
  .component('recordsOverTimeChart', RecordsOverTimeChartComponent)
  .component('recordsByUserChart', RecordsByUserChartComponent)
  .component('cfMultiselectChart', CfMultiselectChartComponent)
  .name;
