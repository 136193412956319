
import template from './custom-view.pug';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';

export const CustomViewComponent = {
  template,
  bindings: {
    module: '@',
    filter: '<',
    activeSearch: '<',
    mySearches: '<',
    edit: '<',
    showInline: '<',
    onFilterChange: '&',
    customSaveSearchCallback: '&?',
  },
  transclude : true,
  controller: class CustomViewComponent {
    constructor($filter, $log, $translate, $stateParams, $async, Search, Contact, Estate, Locations, Account, Todo, Document, Finance, User, CustomField) {
      'ngInject';
      // services
      this.$filter = $filter;
      this.$log = $log;
      this.$stateParams = $stateParams;
      this.$translate = $translate;
      this.Search = Search;
      this.Contact = Contact;
      this.Estate = Estate;
      this.Locations = Locations;
      this.Account = Account;
      this.Todo = Todo;
      this.Document = Document;
      this.Finance = Finance;
      this.User = User;
      this.CustomField = CustomField;
      this.$async = $async;
      this.filters = [];
      this.searchOptions = [];
      this.keys = [];


      // functions

      this.loadCustomFields = $async(this.loadCustomFields.bind(this));
      this.getLocations = $async(this.getLocations.bind(this));
      this.loadLocationName = $async(this.loadLocationName.bind(this));
      this.loadUsers = $async(this.loadUsers.bind(this));
      this.loadTodoGroups = $async(this.loadTodoGroups.bind(this));
      this.loadDocumentGroups = $async(this.loadDocumentGroups.bind(this));
      this.loadFinanceGroups = $async(this.loadFinanceGroups.bind(this));
      this.portalsAttribues = $async(this.portalsAttribues.bind(this));
      this.addSearch = $async(this.addSearch.bind(this));
      this.updateSearch = $async(this.updateSearch.bind(this));

      //  options
      this.columns = this.CustomField.columns;
      this.dateOptions = [
        { id: 'today', title: 'custom_view.condition.today' },
        { id: 'last_day', title: 'custom_view.condition.last_day' },
        { id: 'next_day', title: 'custom_view.condition.next_day' },
        { id: 'this_week', title: 'custom_view.condition.this_week' },
        { id: 'last_week', title: 'custom_view.condition.last_week' },
        { id: 'next_week', title: 'custom_view.condition.next_week' },
        { id: 'this_month', title: 'custom_view.condition.this_month' },
        { id: 'last_month', title: 'custom_view.condition.last_month' },
        { id: 'next_month', title: 'custom_view.condition.next_month' },
      ];
    }

    $onChanges(e) {
      this.handelChanges(e);
    }

    $onInit() {
      this.defaultFilter = {
      };
      this.filter = this.filter || this.defaultFilter;
      this.filterOptions = this.columns[this.module];
      this.locations = [];
      this.resetValues();
      this.loadOptions(this.module);
      this.filterOptions = this.columns[this.module];
      this.loadCustomFields(this.module);
    }

    handelChanges({ filter, edit } = {}) {
      if (filter && filter.currentValue) {
        if (filter.currentValue.data) {
          this.filters = filter.currentValue.data;
          if (this.module == 'Estate' || this.module == 'Inquiry' || this.module == 'Contact') {
            this.findLocations(this.filters);
          }
        } else {
          this.filters = this.filter.data || [];
        }
      }
      if (edit) {
        if (edit.currentValue == false) {
          this.resetValues();
        }
      }
    }

    setModule(mod) {
      const modSwitch = (mod) => ({
        'Contact': 'contacts',
        'Estate': 'estates',
        'Inquiry': 'inquiries',
        'Todo': 'todos',
        'Finance': 'finances',
        'Document': 'documents',
      })[mod];
      this.mod = modSwitch(mod);
    }

    async loadCustomFields(module) {
      this.setModule(module);
      try {
        let groups = await this.CustomField.loadGroups({ module: this.mod });
        groups.forEach((group) => {
          if (group.custom_fields) {
            this.columns[module][group.title] = this.customFieldsDeserialize(group.custom_fields);
          }
        });
        this.columns = angular.copy(this.columns);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.findCustomLocations(this.filters);
      }
    }

    customFieldsDeserialize(fields) {
      let arr = [];
      fields.forEach((field) => {
        if (field.field_type == 'phone') return;
        arr.push({
          name: field.title,
          type: field.field_type,
          options: field.options,
          is_custom: true,
          custom_field_key: field.technical_name,
          validations: field.validations
        });
      });
      return arr;
    }

    applySelect({ group, value }) {
      if (!value) return this.cancelFilter();
      this.filterOpt = value;
      let item = this.column(group).find((e) => { return e['name'] == value; });
      if (!item) {
        item = this.column(group).find((e) => { return e['custom_field_key'] == value; });
      }
      this.selectFilter(item,group);
    }

    emptyFilter() {
      return this.$translate.instant('custom_view.select_filter');
    }

    transformToTranslate(value, from, to) {
      if (this.CustomField.dateOptions().includes(value)) {
        return this.$filter('translate')('custom_view.condition.' + value);
      } else if (!isNaN(parseFloat(from)) && !isNaN(parseFloat(to)) && !value) {
        return (from + ' - ' + to);
      } else {
        return value;
      }
    }

    transformToTranslateMultiple(field, group, list, field_type) {
      let options = [];
      if (this.column(group)) {
        let grpOptions = this.column(group).find((e) => { return e.name == field; });
        if (grpOptions && grpOptions.options) {
          options = grpOptions.options;
        }
      }
      let serialized = [];
      // fix this in future
      if (field_type == 'connected_record') {
        serialized = list;
      } else {
        list.forEach((val) => {
          let field = options.find((e) => { return e.id == val; });
          if (field) {
            if (this.CustomField.pretranslatedFields().includes(field)) {
              // no translate
              serialized.push(field.name);
            } else {
              serialized.push(this.$filter('translate')(field.name));
            }
          } else {
            serialized.push(this.$filter('translate')(val));
          }
        });
      }

      return serialized.join(', ');
    }

    async getLocations(ids) {
      try {
        let locations = await this.Locations.getLocations(ids);
        return locations;
      } catch (e) {
        this.$log.error(e);
      }
    }

    async loadLocationName(ids) {
      try {
        let locations = await this.Locations.getLocations(ids);
        this.assignOptions(this.module,'location',locations);
      } catch (e) {
        this.$log.error(e);
      }
    }


    load_selectize() {
      this.location_selectize = {
        plugins: ['remove_button'],
        create: false,
        valueField: 'id',
        labelField: 'name',
        searchField: 'name',
        persist: true,
        hideSelected: true,
        loadThrottle: 400,
        load: (query, callback) => {
          if (!query.length) return callback();
          this.Locations.searchLocation(query).then((r) => {
            callback(r);
          });
        },
        render: {
          option: (item, escape) => {
            return '<div>' +
              '<span style="float: left;">' +
              item.name +
              '</span>' +
              '</div>';
          }
        }
      };
    }

    column(group) {

      return this.columns[this.module][group];
    }

    resolveRange({ from, to, priceType }) {
      this.selectedFilter.value_from = from;
      this.selectedFilter.value_to = to;
      if (!priceType) return;
      this.selectedFilter.price_type = priceType;
      if (this.selectedFilter.price_type !== 'currency' || this.selectedFilter.price_type === true) {
        this.selectedFilter.field = 'square_price';
      }
    }

    findCustomLocations(filters) {
      let custom_ids = [];
      let column_groups = [];
      filters.forEach((filter) => {
        if (filter.is_custom == true && filter.type == 'location' && filter.contains.length > 0) {
          custom_ids = custom_ids.concat(filter.contains);
          let column_filter = this.column(filter.group).find((fi) => { return filter.custom_field_key == fi.custom_field_key; });
          if (column_filter) {
            column_groups.push(column_filter);
          }
        }
      });
      if (custom_ids && custom_ids.length > 0) {
        let locations = this.getLocations(custom_ids);
        column_groups.forEach((group) => {
          group['options'] = group['options'] || [];
          group['options'] = group['options'].concat(locations);
        });
      }
    }

    findLocations(filters) {
      let ids = [];
      filters.forEach((filter) => {
        if (filter.field == 'location' && filter.type == 'location' && filter.contains.length > 0) {
          ids = [...ids, ...filter.contains];
        }
      });
      if (ids.length > 0) {
        this.loadLocationName(ids);
      }
      this.findCustomLocations(filters);
    }

    async loadUsers(assignTo = []) {
      try {
        this.users = await this.User.getList({ fields: { user: ['id', 'name'] } });
      } catch (e) {
        this.$log.error(e);
      } finally {
        assignTo.forEach((field) => {
          this.assignOptions(this.module,field,this.users);
        });
      }
    }

    async loadTodoGroups() {
      try {
        this.todoGroups = await this.Todo.getGroups();
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.assignOptions(this.module, 'todo_group_id', this.todoGroups);
      }
    }

    async loadDocumentGroups() {
      try {
        this.documentGroups = await this.Document.getGroups();
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.assignOptions(this.module, 'document_group_id', this.documentGroups);
      }
    }

    async loadFinanceGroups() {
      try {
        this.financeGroups = await this.Finance.getGroups();
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.assignOptions(this.module, 'finance_group_id', this.financeGroups);
      }
    }

    getPortalObject(o) {
      return { id: o.slug, name: o.name };
    }

    async portalsAttribues() {
      try {
        this.exports = await this.Account.portalsForExport();
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.assignOptions(this.module,'exports',_map(this.exports, this.getPortalObject));
      }
    }

    loadOptions(module) {
      this.load_selectize();
      // load all necessary options for selects
      switch (module) {
        case 'Contact':
          this.loadUsers(['user_id']);
          break;
        case 'Estate':
          this.loadUsers(['user_id']);
          this.portalsAttribues();
          break;
        case 'Inquiry':
          this.loadUsers(['user_id']);
          break;
        case 'Todo':
          this.loadUsers(['user_id', 'todo_users_ids']);
          this.loadTodoGroups();
          break;
        case 'Document':
          this.loadUsers(['user_id']);
          this.loadDocumentGroups();
          break;
        case 'Finance':
          this.loadUsers(['user_id']);
          this.loadFinanceGroups();
          break;
      }
    }

    assignOptions(model, field, options) {
      var object = this.column('main').find((e) => { return e.name == field; });
      var key_value = 'none' + field;
      if (object) {
        key_value = object.custom_field_key;
      }
      let config = object ? object.config : {};
      var index = this.column('main').findIndex((e) => { return e.name == field; });
      if (index >= 0 && field == 'location') {
        this.column('main').splice(index, 1, { custom_field_key: key_value, name: field, type: 'location', options });
      } else if (index >= 0) {
        this.column('main').splice(index, 1, { custom_field_key: key_value, name: field, type: 'multiselect', options, config });
      }
    }

    dataSerialize(data) {
      let serialized = [];
      data.forEach((val) => {
        serialized.push({
          'value': val.value,
          'value_from': val.value_from,
          'value_to': val.value_to,
          'condition': val.condition,
          'field': val.field,
          'type': this.normalizeTypes(val.type),
          'group': val.group,
          'contains': val.contains,
          'is_custom': val.is_custom,
          'custom_field_key': val.custom_field_key,
        });
      });
      return serialized;
    }

    normalizeTypes(name) {
      if (name == 'text' || name == 'text_area' || name == 'link' || name == 'email') {
        return 'string';
      } else if (name == 'number') {
        return 'integer';
      } else if (name == 'checkbox') {
        return 'checkbox';
      } else {
        return name;
      }
    }

    selectFilter(object, group) {
      this.selectedFilter = { ...this.selectedFilter, ...{
        is_custom: object.is_custom,
        custom_field_key: object.custom_field_key,
        group,
        field: object.name,
        type: object.type,
        options: object.options,
        config: object.config,
      } };
      if (this.selectedFilter.is_custom) {
        if (object.type == 'connected_record') {
          this.selectedFilter.validations = object.validations;
        } else {
          this.selectedFilter.options = object.options.map(x => { return { title: x['title'], id: x['id'] }; });
        }
      }
      if (this.selectedFilter.type == 'location') {
        this.selectedFilter.condition = 'contains';
      }
    }

    preSerialize(filter) {
      if (filter.type=='date') {
        if (dayjs(filter.value_from).isValid() && dayjs(filter.value_to).isValid() ) {
          filter.value_from = dayjs(filter.value_from).format('YYYY-MM-DD');
          filter.value_to = dayjs(filter.value_to).format('YYYY-MM-DD');
        } else if (dayjs(filter.value).isValid()) {
          filter.value = dayjs(filter.value).format('YYYY-MM-DD');
        }
      }
    }

    editItem(filter) {
      let opt;
      if (filter.is_custom) {
        opt = this.searchOptions.find((e) => {
          return e.custom_field_key == filter.custom_field_key;
        });
      } else {
        opt = this.searchOptions.find((e) => {
          return e.name == filter.field;
        });
      }
      this.filterOpt = opt.value;
      this.applySelect(opt.value);
      Object.assign(this.selectedFilter, {
        value: filter.value,
        value_from: filter.value_from,
        value_to: filter.value_to,
        contains: filter.contains,
        condition: filter.condition,
      });
      this.remove(filter);
    }

    saveFilter() {
      this.preSerialize(this.selectedFilter);
      this.filters.push(this.selectedFilter);
      this.filterOpt = null;
      this.resetValues();
      this.handleFilterChange();
    }

    cancelFilter() {
      this.filterOpt = null;
      this.resetValues();
    }

    isInput(name) {
      let type = this.selectedFilter.type;
      let bool = false;
      if (name == 'string') {
        bool = (type == 'string' || type == 'text' || type == 'text_area' || type == 'link' || type == 'email');
      } else if (name == 'integer') {
        bool = (type == 'integer' || type == 'number');
      }
      return bool;
    }

    remove(filter) {
      this.filters = this.filters.filter(n => n != filter);
      if (this.activeSearch == null && this.filters.length == 0) {
        this.activeSearch = -1;
      }
      this.handleFilterChange();
    }

    handleFilterChange() {
      this.filter.data = this.dataSerialize(this.filters);
      if (this.module == 'Estate' || this.module == 'Inquiry' || this.module == 'Contact') {
        this.findLocations(this.filters);
      }
      this.onFilterChange({ $event: {
        filter: this.filter,
        activeSearch: this.activeSearch
      } });
    }

    resetVal() {
      Object.assign(this.selectedFilter, {
        value: '',
        value_from: '',
        value_to: '',
        contains: [],
      });
      this.locations = [];
      this.load_selectize();
    }

    resetValues() {
      this.selectedFilter = {
        'value': '',
        'value_from': '',
        'value_to': '',
        'contains': [],
        'condition': '',
        'field': '',
        'type': '',
        'group': '',
        'options': [],
        'is_custom': false,
        'custom_field_key': '',
        'config': null,
      };
    }

    prepSearch() {
      let object = {
        data: this.dataSerialize(this.filters),
        module: this.module,
      };
      if (this.$stateParams.processId) {
        object.processId = this.$stateParams.processId;
      }
      return object;
    }

    async addSearch() {
      try {
        if (typeof this.customSaveSearchCallback == 'function') {
          this.customSaveSearchCallback()(this.dataSerialize(this.filters));
        } else {
          let object = this.prepSearch();
          let search = await this.Search.add_custom_search(object);
          if (search && search.id) {
            this.mySearches.push(search);
            this.activeSearch = search.id;
            this.handleFilterChange();
          }
        }
      } catch (e) {
        this.$log.error(e);
      }
    }


    cancelSearch() {
      this.filters = [];
      this.activeSearch = -1;
      this.handleFilterChange();
    }

    async updateSearch() {
      try {
        let object = this.mySearches.find((e) => { return e.id == this.activeSearch; });
        object.data = this.dataSerialize(this.filters);
        let search = await this.Search.update_custom_search(object);
        if (search && search.id) {
          object = search;
          this.activeSearch = search.id;
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    cancelUpdateSearch () {
      let object = this.mySearches.find((e) => { return e.id == this.activeSearch; });
      this.filters = object.data;
      this.handleFilterChange();
    }

    isUpdate(searchId) {
      return searchId >= 0;
    }

    changedFromOriginal () {
      if (!!this.activeSearch && this.activeSearch != -1) {
        let object = this.mySearches.find((e) => { return e.id == this.activeSearch; });
        if (!!object && !!object.data) {
          return (_isEqual(this.dataSerialize(object.data), this.dataSerialize(this.filters)) ? false : true);
        } else {
          return false;
        }
      } else if (this.activeSearch == -1) {
        return true;
      } else {
        return false;
      }
    }


  }
};
