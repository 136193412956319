import template from './number-show.pug';

export const NumberShowComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    model: '<',
  },
  transclude: {
    'prepend': '?prepend',
    'append': '?append',
  },
  controller: class NumberShowComponent {
    constructor($transclude) {
      'ngInject';
      this.isFilled = $transclude.isSlotFilled;
    }

    $onInit() {
      this.slotAppend = this.isFilled('append');
      this.sloptPrepend = this.isFilled('prepend');
    }

  }
};
