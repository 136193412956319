import template from './bulk-record-destroy-modal.pug';

export const BulkRecordDestroyModalComponent = {
  template,
  bindings:{
    resolve: '<',
    modalInstance: '<',
  },
  controller: class BulkRecordDestroyModalComponent {
    constructor($async, $log, Contact, Estate, Inquiry, Todo, Finance, Document, AlertBox, $timeout) {
      'ngInject';
      this.Contact = Contact;
      this.Estate = Estate;
      this.Inquiry = Inquiry;
      this.Todo = Todo;
      this.Finance = Finance;
      this.Document = Document;
      this.$log = $log;
      this.AlertBox = AlertBox;
      this.$timeout = $timeout;
      this.confirm = $async(this.confirm.bind(this));
      this.loading = false;
      this.success = false;
      this.error = false;
    }

    $onInit() {
      this.objectType = this.resolve.objectType;
    }

    close() {
      this.modalInstance.close(void(0));
    }

    closeAfterSuccess() {
      this.modalInstance.close(true);
    }

    async confirm() {
      try {
        this.loading = true;
        await this[this.objectType].multipleDelete(this.resolve.objectIds);
      } catch (e) {
        this.success = false;
        this.loading = false;
        this.error = true;
        this.$log.error(e);
      } finally {
        if (!this.error) {
          this.$timeout(() => {
            this.success = true;
            this.loading = false;
          }, 2500);
        }
      }
    }


  }
};
