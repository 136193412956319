import template from './estate-topbar.pug';
export const EstateTopbarComponent = {
  template,
  bindings: {
    estate: '<',
  },
  controller: class EstateTopbarComponent {
    constructor(Estate, AlertBox, $log, $state, $localStorage) {
      'ngInject';
      this.Estate = Estate;
      this.$log = $log;
      this.$state = $state;
      this.AlertBox = AlertBox;
      // this.getMatchedInquiries = $async(this.getMatchedInquiries.bind(this));
      this.$localStorage = $localStorage;
      this.matchedInquiries = [];
    }

    $onInit() {
      // this.getMatchedInquiries();
    }


    // async getMatchedInquiries() {
    //   try {
    //     this.matchedInquiries = await this.Estate.matchedInquiries(this.estate.id);
    //   } catch (e) {
    //     this.$log.error(e);
    //   }
    // }

    navigateToInquiries() {
      this.$state.go('main.inquiries.list', { page: 1, estateId: this.estate.id });
    }


    navigateBack() {
      this.$state.go('main.estates.list', { page: this.$localStorage.estate.list_page || 1 });
    }

  },
};
