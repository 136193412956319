import template from './account-billing.pug';

export const AccountBillingComponent = {
  template,
  bindings: {},
  controller: class AccountBillingComponent {
    constructor($log, $async, Account, AlertBox, StripeElements) {
      'ngInject';

      this.Account = Account;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.StripeElements = StripeElements;

      this.getCurrentAccount = $async(this.getCurrentAccount.bind(this));
      this.getPlanInfo = $async(this.getPlanInfo.bind(this));
    }

    $onInit() {
      this.getCurrentAccount();
      this.getPlanInfo();

      var elements = this.StripeElements.elements()
      var element = elements.create('payment', {})

      element.on('change', handleChange)

      this.element = element;

      function handleChange (e) {
        this.cardErrors = e.error ? e.error.message : ''
      }
    }

    async getPlanInfo() {
      try {
        this.planInfo = await this.Account.planInfo();
      } catch (e) {
        console.error(e);
      }
    }


    async getCurrentAccount() {
      try {
        this.account = await this.Account.current();
      } catch (e) {
        this.$log.error(e);
      }
    }

    isAccountChanged() {
      return JSON.stringify(this.accountTmp) == JSON.stringify(this.account);
    }
  }
};
