import template from './attachment-bulk-download-modal.pug';

export const AttachmentBulkDownloadModalComponent = {
  template,
  bindings: {
    resolve: '<',
    modalInstance: '<',
  },
  controller: class AttachmentBulkDownloadModalComponent {
    constructor(Attachment, Http, $async, $log) {
      'ngInject';

      this.Attachment = Attachment;
      this.Http = Http;
      this.$async = $async;
      this.$log = $log;
      this.loading = false;
      this.download = $async(this.download.bind(this));
      this.icons = [];
      this.totalSize = 0;
      this.attachment_ids = [];
    }

    $onInit() {
      if (this.resolve) {
        this.attachments = this.resolve.attachments;
        this.attachments.forEach( (attachment) => {
          let icon = this.resolveExtensionIcon(attachment.name);
          if (!this.icons.includes(icon)) {
            this.icons.push(icon);
          }
          this.attachment_ids.push(attachment.id);
          this.totalSize += attachment.size;
        });
      }
    }

    resolveExtensionIcon(name) {
      return this.Attachment.extensionIcon(name);
    }


    async download() {
      this.loading = true;
      try {
        const response = await this.Attachment.bulkDownload(this.attachment_ids);
        await this.Http.downloadS3Blob(response.url, response.filename);
        this.loading = false;
        this.close();
      } catch (e) {
        this.$log.error(e);
      }
    }

    close() {
      this.modalInstance.close(void(0));
    }
  }
};
