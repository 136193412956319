import { ChronologyComponent } from './chronology.component';

import { ChronologyListComponent } from './list/chronology-list.component';
import { ChronologyItemComponent } from './list/item/chronology-item.component';
import { ChronologyItemHeaderComponent } from './list/item/header/chronology-item-header.component';

// Chronology form items
import { AttachmentListComponent } from './form/attachment-list/attachment-list.component';
import { ChronologyModalFormComponent } from './form/modal/chronology-modal-form.component';
import { TodoChronologyFormComponent } from './form/todo-chronology-form/todo-chronology-form.component';
import { FinanceChronologyFormComponent } from './form/finance-chronology-form/finance-chronology-form.component';
import { DocumentChronologyFormComponent } from './form/document/document.component';
import { ProposalChronologyFormComponent } from './form/proposal-chronology-form/proposal-chronology-form.component';
import { ContactChronologyFormComponent } from './form/contact-chronology-form/contact-chronology-form.component';
import { EstateChronologyFormComponent } from './form/estate-chronology-form/estate-chronology-form.component';
import { InquiryChronologyFormComponent } from './form/inquiry-chronology-form/inquiry-chronology-form.component';
import { RecordStatusChronologyFormComponent } from './form/record-status-chronology-form/record-status-chronology-form.component';
import { EstatePriceChangeChronologyFormComponent } from './form/estate-price-change-chronology-form/estate-price-change-chronology-form.component';

// Chronology tables
import { ChronologyDocumentTableComponent } from './tables/chronology-document-table/chronology-document-table.component';
import { ChronologyCommentTableComponent } from './tables/chronology-comment-table/chronology-comment-table.component';
import { ChronologyFinanceTableComponent } from './tables/chronology-finance-table/chronology-finance-table.component';
import { ChronologyMultipleProposalTableComponent } from './tables/chronology-multiple-proposal-table/chronology-multiple-proposal-table.component';
import { ChronologyEstatePriceTableComponent } from './tables/chronology-estate-price-table/chronology-estate-price-table.component';
import { ChronologyRecordStatusTableComponent } from './tables/chronology-record-status-table/chronology-record-status-table.component';
import { ChronologyTodoTableComponent } from './tables/chronology-todo-table/chronology-todo-table.component';
import { ChronologyEstateTableComponent } from './tables/chronology-estate-table/chronology-estate-table.component';
import { ChronologyContactTableComponent } from './tables/chronology-contact-table/chronology-contact-table.component';
import { ChronologyInquiryTableComponent } from './tables/chronology-inquiry-table/chronology-inquiry-table.component';


// Filters
import { ChronologyTodoFilterComponent } from './filters/todo/chronology-todo-filter.component';

const ChronologyModule =
  angular.module('topbroker.sharedChronology', [])
    .component('chronology', ChronologyComponent)
    .component('attachmentList', AttachmentListComponent)
    .component('chronologyList', ChronologyListComponent)
    .component('chronologyItem', ChronologyItemComponent)
    .component('chronologyItemHeader', ChronologyItemHeaderComponent)
    .component('todoChronologyForm', TodoChronologyFormComponent)
    .component('financeChronologyForm', FinanceChronologyFormComponent)
    .component('documentChronologyForm', DocumentChronologyFormComponent)
    .component('chronologyModalForm', ChronologyModalFormComponent)
    .component('proposalChronologyForm', ProposalChronologyFormComponent)
    .component('chronologyTodoFilter', ChronologyTodoFilterComponent)
    .component('contactChronologyForm', ContactChronologyFormComponent)
    .component('estateChronologyForm', EstateChronologyFormComponent)
    .component('inquiryChronologyForm', InquiryChronologyFormComponent)
    .component('recordStatusChronologyForm', RecordStatusChronologyFormComponent)
    .component('estatePriceChangeChronologyForm', EstatePriceChangeChronologyFormComponent)

    .component('chronologyDocumentTable', ChronologyDocumentTableComponent)
    .component('chronologyCommentTable', ChronologyCommentTableComponent)
    .component('chronologyFinanceTable', ChronologyFinanceTableComponent)
    .component('chronologyMultipleProposalTable', ChronologyMultipleProposalTableComponent)
    .component('chronologyEstatePriceTable', ChronologyEstatePriceTableComponent)
    .component('chronologyRecordStatusTable', ChronologyRecordStatusTableComponent)
    .component('chronologyTodoTable', ChronologyTodoTableComponent)
    .component('chronologyEstateTable', ChronologyEstateTableComponent)
    .component('chronologyContactTable', ChronologyContactTableComponent)
    .component('chronologyInquiryTable', ChronologyInquiryTableComponent)

    .name;

export default ChronologyModule;
