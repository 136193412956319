import template from './button-google.pug';

export const ButtonGoogleComponent = {
  template,
  bindings: {
    title: '@?',
    loading: '<?',
    onClick: '&?'
  },
  transclude : true,
  controller: class ButtonGoogleComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {

    }
  }
};
