
import { DocumentGroupFormComponent } from './form/group/document-group-form.component';
import { DocumentGroupModalComponent } from './modal/group/document-group-modal.component';
import { DocumentGroupTableComponent } from './table/group/document-group-table.component';

export const DocumentSettingModule = angular.module('topbroker.documentSetting', [])
  .component('documentGroupForm', DocumentGroupFormComponent)
  .component('documentGroupModal', DocumentGroupModalComponent)
  .component('documentGroupTable', DocumentGroupTableComponent)

  .name;
