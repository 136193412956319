import template from './custom-field-header.pug';

export const CustomFieldHeaderComponent = {
  template,
  bindings:{
    group: '<',
    onEdit: '&',
    onEditPosition: '&',
    onDelete: '&',
    onNewField: '&'

  },
  controller: class CustomFieldHeaderComponent {
    constructor() {
      'ngInject';

    }

    $onInit() {
    }

    edit() {
      this.onEdit({ $event: { group: this.group } });
    }

    addField() {
      this.onNewField({ $event: { group: this.group } });
    }

    remove() {
      this.onDelete({ $event: { group: this.group } });
    }


  }
};
