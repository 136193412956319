import template from './estate-type-custom-edit.pug';

export const EstateTypeCustomEditComponent = {
  template,
  bindings: {
    estate: '<',
    onCancel: '&?',
    onSave: '&?',
  },
  controller: class EstateTypeCustomEditComponent {
    constructor(Estate, $async, $log) {
      'ngInject';
      this.Estate = Estate;
      this.$async = $async;
      this.$log = $log;
    }

    $onInit() {}

    cancel() {
      this.onCancel({
        $event: { state: 'show' }
      });
    }

    save(estate) {
      this.onSave({
        $event: { estate }
      });
    }
  }
};
