import template from './estate-tbody.pug';

export const EstateTbodyComponent = {
  template,
  bindings: {
    filter: '<',
    records: '<',
    checkedIds: '<',
    gridOptions: '<?',
    onFilterChange: '&',
    isModal: '<?',
    onCheck: '&',
  },
  controller: class EstateTbodyComponent {
    constructor($stateParams, Estate, AlertBox, User, Table, $async, $log, uiGridConstants) {
      'ngInject';
      this.Estate = Estate;
      this.Table = Table;
      this.uiGridConstants = uiGridConstants;
      this.User = User;
      this.AlertBox = AlertBox;
      this.$stateParams = $stateParams;
      this.$log = $log;
      this.getEstate = $async(this.getEstate.bind(this));
      this.updateEstate = $async(this.updateEstate.bind(this));
    }

    $onChanges({ checkedIds, records } = {}) {
      if (checkedIds && checkedIds.currentValue && checkedIds.currentValue.length == 0) {
        this.records && this.records.forEach((record) => {
          record.checked = false;
        });
      }
      if (records && records.currentValue && this.gridOptions) {
        this.gridOptions.data = records.currentValue.map(x => {
          return x.attributes = { ...x.attributes, ...x.attributes['custom_fields'] };
        });
      }
    }

    $onInit() {
      this.isVisible = false;
      if (!this.gridOptions) this.gridOptions = this.Estate.gridOptions(false, this.isModal);
    }

    checkIds({ checkedIds }) {
      this.onCheck({ $event: { checkedIds } });
    }

    // TODO: Needed for inline editing
    // updateCell(args) {
    //   let params = {};
    //   let hasCustomField = Object.keys(args).find(e => e.startsWith('c_f'));
    //   let row = this.gridOptions.data.find(e => e.id = args.id);

    //   if (hasCustomField) {
    //     params.custom_fields = {
    //       ...row.custom_fields
    //     };
    //   }

    //   for (let key in args) {
    //     if (!key) return;
    //     if (key !== 'id') {
    //       if (key.startsWith('c_f')) {
    //         params.custom_fields[key] = args[key];
    //       } else {
    //         params[key] = args[key];
    //       }
    //     }
    //   }

    //   this.updateEstate(args.id, params, args.index);
    // }

    async updateEstate(id, params, index) {
      try {
        let estate;
        if (params.price || params.square_price) {
          estate = await this.gridOptions.data.find(e => e.id === id);
          params.operation_type = estate.operation_type;

          if (params.price ) {
            params.price_type = 'total';
          }
          if (params.square_price ) {
            params.price = params.square_price;
            params.price_type = 'per_unit';
          }
        }

        const resp = await this.Estate.update(id, params);


        this.gridOptions.data[index] = { ...this.gridOptions.data[index], ...resp };
        this.gridOptions.data = [...this.gridOptions.data];
        this.Table.notifyDataChange();
        this.AlertBox.addMessage('alert.updated',{ type: 'success' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    goTo({ row }) {
      this.Estate.navigateToShow( row.entity.id);
    }

    applyFilter({ filter, searchId }) {
      this.onFilterChange({
        $event: { filter, searchId }
      });
    }

    preview({ row }) {
      this.getEstate(row.entity.id);
    }

    updateFilter() {
      const params =  {
        filter: this.filter,
        searchId: this.$stateParams.searchId
      };
      this.applyFilter(params);
    }

    previewDirection({ id }) {
      this.getEstate(id);
    }

    close() {
      this.isVisible = false;
    }

    async getEstate(id) {
      try {
        this.selectedRecord = await this.Estate.find(id);
        this.isVisible = true;
        this.recordModalStep = 'location';
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
