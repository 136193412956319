import template from './owner-modal.pug';

export const OwnerModalComponent = {
  template,
  bindings: {
    resolve: '<',
    modalInstance: '<'
  },
  controller: class OwnerModalComponent {
    constructor(User, $async, $log) {
      'ngInject';
      this.User = User;
      this.$log = $log;
      this.updateMultiple = $async(this.updateMultiple.bind(this));
      this.loadUsers = $async(this.loadUsers.bind(this));
      this.users_selectize = {
        plugins: ['remove_button'],
        valueField: 'id',
        searchField: 'name',
        labelField: 'name',
        maxItems: 1,
      };
    }

    $onInit() {
      this.loadUsers();
      this.object = {
        class: this.resolve.objectClass,
      };
      if (this.resolve.objectIds) {
        // load objects
        this.objectIds = this.resolve.objectIds;
      }
    }

    async loadUsers() {
      try {
        this.users = [];
        this.users = await this.User.getList({ fields: { user: ['id', 'name'] } });
      } catch (e) {
        this.$log.error(e);
      } finally {
        if ( this.users &&  this.users.length > 0 && !this.userId) {
          this.userId = this.users[0].id;
        }
      }
    }

    setNewOwner($event) {
      this.userId = $event.id;
    }

    async updateMultiple() {
      if (!this.userId) return;
      try {
        this.loader = true;
        let estates = await this.User.changeOwners(this.userId,this.objectIds,this.object.class);
        this.modalInstance.close(estates);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    close() {
      this.modalInstance.close(void(0));
    }

  }
};
