import { singular } from 'pluralize';
import template from './finance-chronology-form.pug';

export const FinanceChronologyFormComponent = {
  template,
  bindings: {
    item: '<',
    recordClass: '<',
    formState: '<',
    onCreate: '&?',
    onDestroy: '&?'
  },
  controller: class FinanceChronologyFormComponent {
    constructor(Chronology, AlertBox, $async, $log) {
      'ngInject';

      this.Chronology = Chronology;
      this.AlertBox = AlertBox;

      this.$log = $log;

      this.saveChronology = $async(this.saveChronology.bind(this));
      this.destroyRecord = $async(this.destroyRecord.bind(this));
    }

    $onInit() {
      if (this.item.record == undefined) { // in new record case
        const newRecord = {
          date_at: dayjs().format('YYYY-MM-DD'),
          operation_type: 'income',
          custom_fields: {},
        };

        switch (this.recordClass) {
          case 'contacts':
            newRecord['contact_ids'] = [this.item.chronology_record.id];
            break;

          case 'estates':
            newRecord['estate_ids'] = [this.item.chronology_record.id];
            break;

          default:
            break;
        }

        this.item.record = newRecord;
      }

    }

    changeState({ state }) {
      if (state) this.formState = state;
    }

    async saveChronology({ finance, status }) {
      try {
        if (this.item.id) {
          this.item.record = finance;
          this.changeState({ state: 'show' });
          this.AlertBox.addMessage('alert.updated');
        } else {
          this.changeState({ state: 'edit' });
          this.onCreate({ $event: { action: 'ReloadList' } });
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async destroyRecord() {
      try {
        await this.Chronology.destroyChild(this.item.id);
        this.onDestroy({ $event: { chronologyItem: this.item } });
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
