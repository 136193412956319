import template from './input-group-range.pug';
// assume this is number range and could be moved to number input
export const InputGroupRangeComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    placeholderFrom: '@?',
    placeholderTo: '@?',
    from: '<',
    to: '<',
    validations: '<?',
    onEdit: '&?',
  },
  transclude: true,
  controller: class InputGroupRangeComponent {
    constructor() {
      'ngInject';
    }
    $onInit() {
    }
    
    edit(){
      this.onEdit({
        $event: {
          from: this.from,
          to: this.to
        }
      });
    }
  
  }
};