import template from './input.pug';

export const InputComponent = {
  template,
  bindings: {
    label: '<?',
    description: '<?',
    validations: '<?',
  },
  transclude : true,
  controller: class InputComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}
  }
};
