import template from './user-settings-notifications.pug';

export const UserSettingsNotificationsComponent = {
  template,
  bindings: {
    user: '<',
  },
  controller: class UserSettingsNotificationsComponent {
    constructor() {
      'ngInject';
      this.tabs = ['estate', 'contact', 'inquiry', 'todo'];
      this.selectedTab = this.tabs[0];
    }

    $onInit() {}
  },
};
