import template from './location-google-show.pug';

export const LocationGoogleShowComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    model: '<',
  },
  controller: class LocationGoogleShowComponent {
    constructor() {
      'ngInject';
    }
    $onInit() {
    }

  }
};