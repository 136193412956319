import template from './custom-fields-input-show.pug';

export const CustomFieldsInputShowComponent = {
  template,
  bindings: {
    record: '<?',
    recordType: '<?',
    customField: '<',
    onUpdate: '&?',
  },
  controller: class CustomFieldsInputShowComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}
  }
};
