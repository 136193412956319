import { plural } from 'pluralize';
import template from './record-status-fields-modal.pug';

export const RecordStatusFieldsModalComponent = {
  template,
  bindings: {
    resolve: '<',
    modalInstance: '<'
  },
  controller: class RecordStatusFieldsModalComponent {
    constructor($async, $log, CustomField) {
      'ngInject';

      this.CustomField = CustomField;

      this.$async = $async;
      this.$log = $log;
      this.loadCustomFields = $async(this.loadCustomFields.bind(this));
    }

    $onInit() {
      this.recordStatus = angular.copy(this.resolve.recordStatus);
      this.record = angular.copy(this.resolve.record);
      this.recordType = angular.copy(this.resolve.recordType);
      this.loadCustomFields();
    }

    async loadCustomFields() {
      try {
        this.cfGroups = await this.CustomField.loadGroups({ module: plural(this.recordType) });
      } catch (e) {
        this.$log.error(e);
      }
    }

    save() {
      this.modalInstance.close(this.record.custom_fields);
    }

    close() {
      this.modalInstance.close(void(0));
    }

  }
};
