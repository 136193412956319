import template from './finance.pug';

export const FinancesComponent = {
  template,
  bindings: {},
  controller: class FinancesComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}
  }
};
