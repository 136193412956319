
// import User  from './user.service';
// Services
import Viewing from '../../services/viewing/viewing.service';

// Components
import { ViewingsComponent } from './viewings.component';
import { ViewingRegistrationComponent } from './viewing-registration/viewing-registration.component';

export const ViewingsModule = angular.module('topbroker.viewings', [])
  .service('Viewing', Viewing)
  .component('viewings', ViewingsComponent)
  .component('viewingRegistration', ViewingRegistrationComponent)

  .config(($stateProvider) => {
    'ngInject';
    $stateProvider

      .state('main.viewings', {
        url: '/viewings',
        component: 'viewings',
        abstract: true,
      })
      .state('main.viewings.registration', {
        component: 'viewingRegistration',
        url: '/registrations/:registration_id',
        params: {
          registration_id: { dynamic: true },
        },
      })
      .state('main.viewings.nonexisting', {
        url: '/:id/404',
        component: 'notFound',
        resolve: {}
      });


  }).name;
