class Estate {
  constructor(Http, Modal, $state, $translate, $filter, $localStorage, uiGridConstants, User, Workspace) {
    'ngInject';
    this.Http = Http;
    this.Modal = Modal;
    this.$state = $state;
    this.$filter = $filter;
    this.User = User;
    this.$translate = $translate;
    this.Workspace = Workspace;
    this.uiGridConstants = uiGridConstants;
    this.$localStorage = $localStorage;
  }

  photosDownloadStyleModal() {
    return this.Modal.open({
      size: 'sm',
      component: 'photosDownloadStyleModal',
    });
  }

  bulkPhotoDownload(estate_id, downloadStyle = 'original') {
    return this.Http.get('/estates/' + estate_id + '/photos/download', { download_style: downloadStyle });
  }

  bulkEdit(params) {
    return this.Http.post('/estates/bulk_edit', params);
  }

  columnsMain(isModal) {
    return [
      {
        name: this.$translate.instant('general.preview'),
        field: '',
        enableColumnMenu: false,
        enableSorting: false,
        enableFiltering: false,
        minWidth: 30,
        maxWidth: 40,
        cellTemplate: '<a ng-click="grid.appScope.preview(row)" class="mt-3 ml-2"><icon icon="(\'eye\')" attr="{width:20,height:20}" /></a>',
      },
      {
        name: this.$translate.instant('photos.photo'),
        field: 'image_url',
        enableColumnMenu: false,
        enableSorting: false,
        enableFiltering: false,
        minWidth: 60,
        maxWidth: 80,
        cellTemplate: `<div class="fn-table-image" style="background-image: url({{grid.getCellValue(row, col)}})">
          </div>`,
      },
      {
        name: this.$translate.instant('estate.address'),
        field: 'address',
        enableColumnMenu: false,
        enableSorting: false,
        minWidth: 300,
        enableFiltering: true,
        filter: { name: 'search_by_address' },
        cellTemplate: isModal
          ? ''
          : `<div class="ui-grid-cell-contents">
            <a class="ui-grid-nav-title" ng-click="grid.appScope.onRowClick(row)">{{ grid.getCellValue(row, col)  }}</a>
          </div>`,
      },
      {
        name: this.$translate.instant('custom_view.filter.operation_type'),
        field: 'operation_type',
        enableColumnMenu: false,
        enableSorting: false,
        width: 100,
        enableFiltering: true,
        filter: {
          name: 'operation_type',
          type: this.uiGridConstants.filter.SELECT,
          selectOptions: [
            { value: 'for_sale', label: this.$translate.instant('estate.for_sale') },
            { value: 'for_rent', label: this.$translate.instant('estate.for_rent') },
            { value: 'auction', label: this.$translate.instant('estate.auction') },
          ],
        },
        cellTemplate: `<div class="ui-grid-cell-contents">
            {{ "estate." + grid.getCellValue(row, col) | translate }}
          </div>`,
      },
      {
        name: 'Property type',
        field: 'estate_type_id',
        enableSorting: false,
        filter: {
          name: 'estate_type_id',
          type: this.uiGridConstants.filter.SELECT,
          selectOptions: this.types().map((el) => ({ value: el.id, label: el.name })),
        },
        enableColumnMenu: false,
        width: 100,
        enableFiltering: true,
        cellTemplate: `<div class="ui-grid-cell-contents">
            <input-multiselect-show model="grid.getCellValue(row, col)" options="col.filter.selectOptions" config="{valueField: 'value', labelField: 'label'}"></input-multiselect-show>
          </div>`,
      },
      {
        name: this.$translate.instant('general.status'),
        field: 'record_status',
        enableSorting: true,
        enableColumnMenu: false,
        width: 100,
        enableFiltering: true,
        cellTemplate: `<div class="ui-grid-cell-contents">
            <input-multiselect-show model="grid.getCellValue(row, col)" options="col.filter.selectOptions" config="{valueField: 'value', labelField: 'label'}"></input-multiselect-show>
          </div>`,
      },
      {
        name: this.$translate.instant('estate.area'),
        field: 'area',
        width: 75,
        enableColumnMenu: false,
        enableSorting: true,
        enableFiltering: true,
        sortDirectionCycle: [this.uiGridConstants.ASC, this.uiGridConstants.DESC],
        cellTemplate: `<div class="ui-grid-cell-contents ui-grid-currency">

          </div>`,
        filters: [
          {
            name: 'area_min',
            placeholder: this.$translate.instant('custom_view.condition.range_from'),
          },
          {
            name: 'area_max',
            placeholder: this.$translate.instant('custom_view.condition.range_to'),
          },
        ],
      },
      {
        name: this.$translate.instant('estate.price'),
        field: 'price',
        width: 85,
        enableColumnMenu: false,
        enableSorting: true,
        enableFiltering: true,
        sortDirectionCycle: [this.uiGridConstants.ASC, this.uiGridConstants.DESC],
        cellTemplate: `<div class="ui-grid-cell-contents ui-grid-currency">
            {{ grid.getCellValue(row, col) | money }}
          </div>`,
        filters: [
          {
            name: 'price_min',
            placeholder: this.$translate.instant('custom_view.condition.range_from'),
          },
          {
            name: 'price_max',
            placeholder: this.$translate.instant('custom_view.condition.range_to'),
          },
        ],
      },
      {
        name: this.$translate.instant('custom_view.filter.square_price'),
        field: 'square_price',
        width: 85,
        enableColumnMenu: false,
        enableSorting: true,
        sortDirectionCycle: [this.uiGridConstants.ASC, this.uiGridConstants.DESC],
        enableFiltering: true,
        cellTemplate: `<div class="ui-grid-cell-contents ui-grid-currency">
            {{ grid.getCellValue(row, col) | money }}
          </div>`,
        filters: [
          {
            name: 'square_price_min',
            placeholder: this.$translate.instant('custom_view.condition.range_from'),
          },
          {
            name: 'square_price_max',
            placeholder: this.$translate.instant('custom_view.condition.range_to'),
          },
        ],
      },
      {
        name: this.$translate.instant('general.date'),
        field: 'date',
        enableFiltering: false,
        enableSorting: true,
        enableColumnMenu: false,
        width: 90,
        sort: { direction: this.uiGridConstants.DESC },
        sortDirectionCycle: [this.uiGridConstants.ASC, this.uiGridConstants.DESC],
      },
      {
        name: this.$translate.instant('general.user'),
        field: 'user',
        enableFiltering: true,
        enableSorting: false,
        width: 235,
        enableColumnMenu: false,
        cellTemplate: `<span class="user-cell">
            <todo-user user="grid.getCellValue(row, col)">
            </todo-user>
          </span>`,
        filterHeaderTemplate: `<div class="ui-grid-filter-container">
            <selectize config="col.filter.config" options="col.filter.selectOptions" ng-model="col.filter.term">
            </selectize>
          </div>`,
        filter: {
          name: 'user_ids',
          config: { labelField: 'name', valueField: 'id', searchField: 'name' },
          selectOptions: [],
        },
      },
    ];
  }

  operationTypes() {
    return [
      {
        value: 'for_sale',
        label: this.$translate.instant('estate.for_sale'),
      },
      {
        value: 'for_rent',
        label: this.$translate.instant('estate.for_rent'),
      },
      {
        value: 'auction',
        label: this.$translate.instant('estate.auction'),
      },
    ];
  }

  navigateToShow(id) {
    this.$state.go('main.estates.show', { id });
  }

  getListBySearchId(search_id, params) {
    return this.Http.get('/estates/cs/' + search_id, params);
  }

  getListBySearchIdNew(search_id, params) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.Http.post('/estates/list/' + search_id, params);
  }

  getPriceHistory({ id, price_type }, translatePrefix) {
    if (!id) return;
    let currencyType = price_type || 'total';

    const data = this.Http.get('/estates/' + id + '/price_changes').then(
      (resp) => {
        let history = [];
        resp.data.forEach((elm) => {
          if (elm.price > 0) {
            history.push({
              date: dayjs(elm.date).format('YYYY-MM-DD'),
              price: this.$filter('money')(elm.price),
              currency: this.$translate.instant(translatePrefix + '.' + currencyType),
            });
          }
        });

        return history;
      },
      (e) => {
        throw new Error(e);
      }
    );
    return data;
  }

  getList(params = {}) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };
    return this.getListBySearchId(0, params).then((records) => {
      return records.data.map((el) => el.attributes);
    });
  }

  matchedInquiries(id) {
    return this.Http.get(`/estates/${id}/matched_inquiries`);
  }

  // TODO: Don't see where it is use no needed?
  count(search_id, params) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.Http.post('/estates/count/' + search_id, params);
  }

  find(id, params = {}) {
    return this.Http.get('/estates/' + id, params);
  }

  cardInfo(id) {
    return this.Http.get(`/estates/${id}/card_info`);
  }

  changeOwner(estate_id, user_id) {
    return this.Http.put('/estates/' + estate_id + '/change_owner/' + user_id);
  }

  getEstateFields(id, fields) {
    return this.Http.get('/estates/' + id + '/fields', { fields: fields });
  }

  removePhoto(estate_id, photo_id) {
    return this.Http.delete('/estates/' + estate_id + '/photo/' + photo_id);
  }

  getPhotos(estate_id) {
    return this.Http.get('/estates/' + estate_id + '/photos');
  }

  setPositions(estate_id, position) {
    return this.Http.put('/estates/' + estate_id + '/photos_order', { positions: position });
  }

  update(id, params) {
    return this.Http.put('/estates/' + id, params);
  }

  recoverModal(checked_ids) {
    return this.Modal.open({
      size: 'md',
      component: 'trashbinRecoverModal',
      resolve: {
        objectIds: () => checked_ids,
        users: () => this.User.getList({ fields: { user: ['id', 'name'] } }),
        module: () => 'Estate',
      },
    });
  }

  previewModal(record) {
    return this.Modal.open({
      animation: false,
      size: 'sidebar-full-screen',
      backdrop: true,
      component: 'estatePreviewModal',
      resolve: {
        record: () => record,
      },
    });
  }

  recover(obj) {
    return this.Http.put('/estates/recover', { ids: obj.ids, recover_type: obj.key, recover_value: obj.value });
  }

  typeModal(type, estateTypeGroup) {

    return this.Modal.open({
      size: 'md',
      resolve: {
        estateType: type,
        estateTypeGroup: estateTypeGroup
      },
      component: 'estateTypeModal',
    });
  }

  typeGroupModal(type_group) {
    return this.Modal.open({
      size: 'md',
      resolve: { estateTypeGroup: type_group },
      component: 'estateTypeGroupModal',
    });
  }

  create(params) {
    return this.Http.post('/estates', params);
  }

  copy(id, params) {
    return this.Http.put('/estates/' + id + '/copy', params);
  }

  updateSearch(params) {
    return this.Http.put('/search/estates', params);
  }

  deleteLastSearch(params) {
    return this.updateSearch(params);
  }

  // TODO: Don't see where it is use no needed?
  getSearch() {
    return this.Http.get('/search/estates');
  }

  nearby(id) {
    return this.Http.get('/estates/' + id + '/nearby');
  }

  priceTypes() {
    return {
      for_sale: {
        total: 'Total price',
        per_unit: 'Price per area unit',
      },
      auction: {
        total: 'Starting price',
        per_unit: 'Starting price per area unit',
      },
      for_rent: {
        total: 'Monthly price',
        total_per_week: 'Weekly price',
        total_per_day: 'Daily price',
        per_unit: 'Monthly price per area unit',
        per_unit_per_week: 'Weekly price per area unit',
        per_unit_per_day: 'Daily price per area unit',
      },
    };
  }

  getExportPortals() {
    return this.Http.get('/estates/exports');
  }

  getEstateTypes(params = {}) {
    return this.Http.get('/estate_types', params);
  }

  createEstateTypes(params = {}) {
    return this.Http.post('/estate_types', params);
  }

  getEstateType(id) {
    return this.Http.get(`/estate_types/${id}`);
  }

  updateEstateTypes(id, params = {}) {
    return this.Http.put(`/estate_types/${id}`, params);
  }

  deleteEstateType(id) {
    return this.Http.delete(`/estate_types/${id}`);
  }

  getAreaUnits(estate_type_id) {
    return this.Http.get('/estate_types/' + estate_type_id + '/area_units');
  }

  addAreaUnit(estate_type_id, area_unit_id) {
    return this.Http.post('/estate_types/' + estate_type_id + '/area_units', { area_unit_id: area_unit_id });
  }

  removeAreaUnit(estate_type_id, area_unit_id) {
    return this.Http.delete('/estate_types/' + estate_type_id + '/area_units/' + area_unit_id);
  }

  getEstateTypeGroups(params = {}) {
    return this.Http.get('/estate_type_groups', params);
  }

  createEstateTypeGroups(params = {}) {
    return this.Http.post('/estate_type_groups', params);
  }

  updateEstateTypeGroups(id, params = {}) {
    return this.Http.put(`/estate_type_groups/${id}`, params);
  }

  deleteEstateTypeGroup(id) {
    return this.Http.delete(`/estate_type_groups/${id}`);
  }

  reExport(id) {
    return this.Http.put('/estates/' + id + '/reexport');
  }

  getDescriptions(id) {
    return this.Http.get('/estates/' + id + '/descriptions');
  }

  moveToTrash(id) {
    return this.Http.delete('/estates/' + id);
  }

  multipleDeleteModal(checked_ids) {
    return this.Modal.open({
      size: 'md',
      component: 'bulkRecordDestroyModal',
      resolve: {
        objectIds: function () {
          return checked_ids;
        },
        objectType: () => 'Estate',
      },
    });
  }

  multiplePortalRefresh(ids) {
    return this.Http.put('/estates/reexport', { ids });
  }

  multiplePortalRefreshModal(checked_ids) {
    return this.Modal.open({
      size: 'md',
      component: 'estatePortalRefreshModal',
      resolve: {
        objectIds: function () {
          return checked_ids;
        },
        estates: () => {
          return this.getList({ estate_ids: checked_ids, simpleSearch: 1 });
        },
      },
    });
  }

  estateTypeGroupPositionModal(estateTypeGroups) {
    return this.Modal.open({
      size: 'md',
      component: 'estateTypeGroupPositionModal',
      resolve: {
        groups: () => {
          return estateTypeGroups;
        },
      },
    });
  }

  export(search_id, params) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.Http.post('/estates/export/' + search_id, params);
  }

  multipleChangeOwnerModal(checked_ids) {
    return this.Modal.open({
      size: 'md',
      component: 'ownerModal',
      resolve: {
        objectIds: function () {
          return checked_ids;
        },
        objectClass: function () {
          return 'estates';
        },
      },
    });
  }

  multipleDelete(ids) {
    return this.Http.put('/estates/delete_multiple', { ids: ids });
  }

  types() {
    return this.$localStorage['estate_types'];
  }

  getTypeById(type_id) {
    return this.types().find((item) => item.id === type_id);
  }

  search(query, params = {}) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.Http.post('/estates/search', { ...{ full_search: query, per_page: 5 }, ...params });
  }

  resolvePrice(estate) {
    switch (estate.price_type) {
      case 'per_unit':
        return {
          alternative: {
            price: estate.price,
            type: 'total',
          },
          view: {
            price: estate.square_price,
            type: 'per_unit',
          },
        };

      case 'total':
        return {
          alternative: {
            price: estate.square_price,
            type: 'per_unit',
          },
          view: {
            price: estate.price,
            type: 'total',
          },
        };
    }
  }

  areaUnits() {
    return [
      {
        id: 'square_meter',
        name: 'Square meter',
        short_name: 'm²',
        system: 'metric',
      },
      {
        id: 'square_foot',
        name: 'Square foot',
        short_name: 'ft²',
        system: 'imperial',
      },
      {
        id: 'square_yard',
        name: 'Square yard',
        short_name: 'yd²',
        system: 'imperial',
      },
      {
        id: 'acre',
        name: 'Acre',
        short_name: 'ac',
        system: 'imperial',
      },
      {
        id: 'are',
        name: 'Are',
        short_name: 'a',
        system: 'metric',
      },
    ];
  }



  resolveAreaUnit(unit) {
    return this.areaUnitsByMetricSystem().find((u) => u.id === unit);
  }

  areaUnitsByMetricSystem() {
    return this.areaUnits().filter((item) => item.system === window.METRIC_SYSTEM);
  }

  gridOptions(columnDef, isModal) {
    return {
      rowHeight: 51,
      selectionRowHeaderWidth: 65,
      enableSorting: true,
      enableGridMenu: false,
      enableFiltering: true,
      enableFullRowSelection: isModal ? true : false,
      enableHorizontalScrollbar: true,
      columnDefs: columnDef || this.columnsMain(isModal),
      enableRowSelection: true,
      enableSelectAll: true,
      fastWatch: true,
      paginationPageSize: 20,
      useExternalPagination: true,
      useExternalSorting: true,
      useExternalFiltering: true,
      enablePaginationControls: false,
      paginationCurrentPage: 1,
      rowTemplate:
        '<div ng-class="{highlighted: row.entity.preview}" ng-click="grid.appScope.fnOne(row)" ng-repeat="col in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ui-grid-cell></div>',
    };
  }

  serialize(estate, selected = []) {
    var estate_attributes = {
      ...estate,
    };
    function pick(obj, keys) {
      return keys.map((k) => (k in obj ? { [k]: obj[k] } : {})).reduce((res, o) => Object.assign(res, o), {});
    }
    if (selected.length > 0) {
      estate_attributes = pick(estate_attributes, selected);
    }

    return estate_attributes;
  }

  newEstateParams() {
    return [
      'estate_type_id',
      'area',
      'custom_fields',
      'operation_type',
      'location',
      'price',
      'price_type',
      'workspace_id',
      'location_id',
      'connected_record_relations',
    ];
  }
}

export default Estate;
