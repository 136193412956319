class RecordStatus {
  constructor(Http, Modal, $localStorage) {
    'ngInject';
    this.$localStorage = $localStorage;
    this.Http = Http;
    this.Modal = Modal;
  }

  getList(module, params = {}) {
    return this.Http.get('/record_statuses', Object.assign(params, { module: module }));
  }

  statusTypes(module) {
    return this.$localStorage[module + '_statuses'];
  }

  find(id) {
    return this.Http.get('/record_statuses/' + id );
  }

  count(module) {
    return this.Http.get('/record_statuses/count', { module: module } );
  }

  changeModal({ module, selected }) {
    return this.Modal.open({
      size: 'md',
      component: 'recordStatusChange',
      resolve: {
        module: () => module,
        selected: () => selected
      }
    });
  }

  fieldsModal({ recordStatus, record, recordType }) {
    return this.Modal.open({
      size: 'md',
      component: 'recordStatusFieldsModal',
      resolve: {
        recordStatus: () => recordStatus,
        record: () => record,
        recordType: () => recordType
      }
    });
  }

  relatedRecordsBulkUpdate({ record_status_id, record_ids }) {
    return this.Http.put('/record_statuses/'+ record_status_id +'/related_records_bulk_update', { record_ids });
  }

  returnLastStatusId(module) {
    this.$localStorage.savedRecordStatus = this.$localStorage.savedRecordStatus || {};
    return parseInt(this.$localStorage.savedRecordStatus[module]) || -1;
  }

  setLastStatusId(module, id) {
    this.$localStorage.savedRecordStatus = this.$localStorage.savedRecordStatus || {};
    this.$localStorage.savedRecordStatus[module] = id;
  }

  modal({ record_status, recordType }) {
    return this.Modal.open({
      size: 'md',
      component: 'recordStatusModal',
      resolve: {
        record_status: () => record_status,
        recordType: () => recordType
      }
    });
  }

  updatePosition(id, position) {
    return this.Http.put('/record_statuses/' + id + '/position/', { position });
  }

  delete(id) {
    return this.Http.delete('/record_statuses/' + id);
  }

  getNotAssigned(module) {
    return this.Http.get('/record_statuses/not_assigned', { module: module });
  }

  update(params) {
    return this.Http.put('/record_statuses/' + params.id, params);
  }

  create(params) {
    return this.Http.post('/record_statuses/', params);
  }

}
export default RecordStatus;
