import template from './estates.pug';

export const EstatesComponent = {
  template,
  bindings: {},
  controller: class EstatesComponent {
    constructor() {
      'ngInject';
    }
    $onInit() {
    }
  }
};
