import template from './role-form.pug';

export const RoleFormComponent = {
  template,
  bindings: {
    role: '<',
    formState: '<'
  },
  controller: class RoleFormComponent {
    constructor($state, $async, $log, Modal, AlertBox, Role) {
      'ngInject';
      this.AlertBox = AlertBox;
      this.$state = $state;
      this.$log = $log;
      this.Modal = Modal;
      this.Role = Role;
      this.save = $async(this.save.bind(this));
      this.moveToTrash = $async(this.moveToTrash.bind(this));
    }

    $onInit() {
      this.tempRole = angular.copy(this.role);
    }

    setTemp() {
      this.tempRole = angular.copy(this.role);
    }

    changeState({ state }) {
      if (state) this.formState = state;
      if (state == 'show') this.role = angular.copy(this.tempRole);
    }

    async save({ role }) {
      try {
        let attributes = this.Role.serialize(role);
        this.role = await this.Role.update(role.id, attributes);
        this.setTemp();
        this.AlertBox.addMessage('alert.updated',{ type: 'success' });
        this.changeState({ state: 'show' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async moveToTrash() {
      try {
        await this.Role.moveToTrash(this.role.id);
        this.AlertBox.addMessage('alert.deleted',{ type: 'warning' });
        this.$state.go('main.settings.roles');
      } catch (e) {
        this.$log.error(e);
      }
    }

  }
};
