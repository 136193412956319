import template from './notification-match.pug';

export const NotificationMatchComponent = {
  template,
  bindings: {
    notification: '<',
  },
  transclude : true,
  controller: class NotificationMatchComponent {
    constructor() {
      'ngInject';
      // services
    }

    $onInit(){
    }

  }
};
