
import { RecordStatusFormComponent } from './form/record-status-form.component';
import { RecordStatusModalComponent } from './modal/record-status-modal.component';
import { RecordStatusTableComponent } from './table/record-status-table.component';

export const RecordStatusSettingsModule = angular.module('topbroker.recordStatusSetting', [])
  .component('recordStatusForm', RecordStatusFormComponent)
  .component('recordStatusModal', RecordStatusModalComponent)
  .component('recordStatusTable', RecordStatusTableComponent)
  .name;
