import template from './custom-view-modal.pug';

export const CustomViewModalComponent = {
  template,
  bindings: {
    resolve: '<',
    modalInstance: '<',
    onFilterChange: '<?'
  },
  transclude : true,
  controller: class CustomViewModalComponent {
    constructor(AlertBox, $filter, Search, User, $async, $log) {
      'ngInject';
      this.Search = Search;
      this.AlertBox = AlertBox;
      this.User = User;
      this.$filter = $filter;
      this.$log = $log;
      this.$async = $async;

      this.deleteSearch = $async(this.deleteSearch.bind(this));
      this.save = $async(this.save.bind(this));
    }

    $onInit() {
      this.module = this.resolve.module;
      this.searches = this.resolve.searches;
      this.state = 'allSearches';
      this.currentId = this.User.currentId();
      this.filter = [];
      if (this.resolve.customViewObject) {
        this.editSearch(this.resolve.customViewObject);
      }
    }

    newSearch() {
      this.state = 'updateOrCreateSearch';
      this.object = {
        title: '',
        module: this.module
      };
      this.activeSearch = {};
    }

    editSearch(search) {
      this.state = 'updateOrCreateSearch';
      this.object = angular.copy(search); // avoid two way binding, new value would save if save button wont be clicked
      this.activeSearch = search;
    }

    reset() {
      if (this.resolve.customViewObject) {
        this.close();
      } else {
        this.state = 'allSearches';
      }
    }

    async deleteSearch(search) {
      const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });

      if (result && result.value) {
        try {
          await this.Search.deleteCustomSearch(search.id);
        } catch (e) {
          this.$log.error(e);
        } finally {
          this.modalInstance.close({ id: 0 });
        }
      }
    }

    filterChange(filter, activeSearch) {
      if (this.onFilterChange) {
        this.onFilterChange()(filter, activeSearch);
      }
    }

    select(search) {
      this.modalInstance.close(search);
    }

    applySearch(search) {
      const searchIndex = this.searches.findIndex((s) => s.id === search.id);
      if (searchIndex > -1) {
        this.searches[searchIndex] = search;
      } else {
        this.searches.push(search);
      }
      this.state = 'allSearches';
    }

    async save() {
      try {
        this.loader = true;
        if (this.object.id) {
          // AlertBox
          const updatedSearch = await this.Search.updateCustomSearch(this.object.id, this.object);
          this.applySearch(updatedSearch);

        } else {
          const savedSearch = await this.Search.saveCustomSearch(this.object);
          this.applySearch(savedSearch);
          this.state = 'allSearches';
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    close() {
      this.modalInstance.close(void(0));
    }
  }
};
