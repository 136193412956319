import template from './inquiry-topbar.pug';
export const InquiryTopbarComponent = {
  template,
  bindings: {
    inquiry: '<',
    showUser: '<',
    onEdit: '&',
    onCopy: '&',
    onDestroy: '&',
    notFixed: '<?'
  },
  controller: class ContactTopbarComponent {
    constructor(Inquiry, LocalStorage, $log, $state) {
      'ngInject';
      this.Inquiry = Inquiry;
      this.LocalStorage = LocalStorage;
      this.$log = $log;
      this.$state = $state;
    }

    $onInit() {
    }

    navigateBack() {
      this.$state.go('main.inquiries.list', { page: (this.LocalStorage.get('inquiry', 'list_page') || 1) });
    }

    editInfo() {
      this.onEdit({ $event: true });
    }

  }

};
