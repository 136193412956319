
import { TableEditModalComponent } from './modal/table-edit-modal.component';
import { FieldsSelectComponent } from './fields-select/fields-select.component';
import { PositionsListComponent } from './positions-list/positions-list.component';

const TableEditModule =
  angular.module('topbroker.tableEditModule', [])
    .component('tableEditModal', TableEditModalComponent)
    .component('fieldsSelectTableEdit', FieldsSelectComponent)
    .component('positionListTableEdit', PositionsListComponent)

    .name;

export default TableEditModule;
