import template from './portal-filters-edit.pug';

export const PortalFiltersEditComponent = {
  template,
  bindings:{
    portal: '<',
    onSave: '&',
    onCancel: '&?'
  },
  controller: class PortalFiltersEditComponent {
    constructor() {
      'ngInject';

      this.activeSearch = {};
    }

    $onInit() {
    }

    cancel() {
      this.onCancel({
        $event: { state: 'show' }
      });
    }

    setFillter({ data }) {
      this.portal.data = data;
    }

    save() {
      this.onSave({
        $event: {
          data: this.portal.data
        }
      });
    }
  }
};
