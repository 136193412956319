import { InquiryToolbarComponent } from './toolbar/inquiry-toolbar.component';

import { InquiryModalPreviewModalComponent } from './modal/preview/inquiry-modal-preview.component';

import { InquiryTopbarComponent } from './topbar/inquiry-topbar.component';
import { InquiryShowComponent } from './form/show/inquiry-show.component';
import { InquiryRecordModalComponent } from './form/record-modal/record-modal.component';
import { InquiryMatchingFormComponent } from './form/matching/inquiry-matching-form.component';

import { AdditionaFieldsFormComponent } from './form/additional-fields/additional-fields-form.component';
import { AdditionaFieldsShowFormComponent } from './form/additional-fields/show/additional-fields-show-form.component';
import { AdditionaFieldsEditFormComponent } from './form/additional-fields/edit/additional-fields-edit-form.component';

// New table
import { InquiryTableComponent } from './table/inquiry-table.component';
import { InquiryHeaderComponent } from './table/header/inquiry-header.component';
import { InquiryBodyComponent }  from './table/body/inquiry-body.component';
import { InquiryTbodyComponent } from './table/body/tbody/inquiry-tbody.component';

import { InquiryLocationComponent } from './location/inquiry-location.component';

export const InquiryModule = angular.module('topbroker.inquiry', [])
  .component('inquiryToolbar', InquiryToolbarComponent)
  .component('inquiryPreviewModal', InquiryModalPreviewModalComponent)
  .component('inquiryTopbar', InquiryTopbarComponent)
  .component('inquiryShowForm', InquiryShowComponent)
  .component('inquiryRecordModal', InquiryRecordModalComponent)
  .component('inquiryMatchingForm', InquiryMatchingFormComponent)
  .component('additionalFieldsForm', AdditionaFieldsFormComponent)
  .component('additionalFieldsShowForm', AdditionaFieldsShowFormComponent)
  .component('additionalFieldsEditForm', AdditionaFieldsEditFormComponent)

  .component('inquiryTable', InquiryTableComponent)
  .component('inquiryHeader', InquiryHeaderComponent)
  .component('inquiryBody', InquiryBodyComponent)
  .component('inquiryTbody', InquiryTbodyComponent)

  .component('inquiryLocation', InquiryLocationComponent)

  .name;
