
import { WorkspaceGroupFormComponent } from './form/group/workspace-group-form.component';
import { WorkspaceGroupModalComponent } from './modal/group/workspace-group-modal.component';
import { WorkspaceSwitchModalComponent } from './modal/switch/workspace-switch-modal.component';
import { WorkspaceGroupTableComponent } from './table/group/workspace-group-table.component';
import { WorkspaceShowComponent } from './show/workspace-show.component';

import { WorkspaceFormComponent } from './form/workspace-form.component';
import { WorkspaceWatermarkComponent } from './watermark/workspace-watermark.component';
import { WorkspaceAccessListComponent } from './access-list/workspace-access-list.component';
import { WorkspaceInputChangeComponent } from './input-change/workspace-input-change.component';

export const WorkspaceSettingModule = angular.module('topbroker.workspaceSetting', [])
  .component('workspaceGroupForm', WorkspaceGroupFormComponent)
  .component('workspaceGroupModal', WorkspaceGroupModalComponent)
  .component('workspaceSwitchModal', WorkspaceSwitchModalComponent)
  .component('workspaceGroupTable', WorkspaceGroupTableComponent)
  .component('workspaceShow', WorkspaceShowComponent)

  .component('workspaceForm', WorkspaceFormComponent)
  .component('workspaceWatermark', WorkspaceWatermarkComponent)
  .component('workspaceAccessList', WorkspaceAccessListComponent)
  .component('workspaceInputChange', WorkspaceInputChangeComponent)

  .name;
