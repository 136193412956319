
class Modal {
  constructor($uibModal) {
    'ngInject';
    this.modal = $uibModal;
  }

  open(props) {
    if (props.backdrop == undefined) {
      props = Object.assign({ backdrop: 'static' }, props);
    }

    let modal = this.modal.open(props);
    this.isOpen = true;
    modal.result.finally(() => this.isOpen = false);

    return modal.result;
  }
}
export default Modal;
