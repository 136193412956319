import template from './settings-trashbin.pug';

export const SettingsTrashbinComponent = {
  template,
  bindings: {
  },
  controller: class SettingsTrashbinComponent {
    constructor($stateParams, $state) {
      'ngInject';
      this.$stateParams = $stateParams;
      this.modules = ['estates','contacts','inquiries'];
      this.$state = $state;
      this.selected = this.$state.current.data.active;
      this.filter = {
        page: this.$stateParams.page || 1,
        searchId: this.$stateParams.searchId || -1,
      };
    }

    $onInit() {
      if (!this.selected) {
        this.selectModule(this.modules[0]);
      }
    }

    selectModule(type) {
      if (type) {
        this.selected = type;
        this.filter = {
          page: 1,
          searchId: -1,
        };
      }

      this.$state.go('main.settings.trashbin.' + this.selected,
        { page: this.filter.page , searchId: this.filter.searchId });
    }

    applyFilterAndResetPage({ filter }) {
      this.filter = { ...this.filter, ...filter };
      this.selectModule();
    }

    updatePage(page) {
      this.filter = { ...this.filter, ...page };
      this.selectModule();
    }

  }

};
