// base component
import { FormComponent } from './form.component';

// components

import { EditComponent } from './edit/edit.component';
import { ShowComponent } from './show/show.component';

export const FormModule = angular
  .module('topbroker.forms', [])
  .component('editForm', EditComponent)
  .component('showForm', ShowComponent)
  .component('fnForm', FormComponent)
  .directive('formOnChange', function($parse) {
    'ngInject';
    return {
      require: 'form',
      link: function(scope, element, attrs) {
        var cb = $parse(attrs.formOnChange);
        element.on('change', function() {
          cb(scope);
        });
      }
    };
  }).name;
