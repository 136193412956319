import template from './chronology-estate-table.pug';

export const ChronologyEstateTableComponent = {
  template,
  bindings: {
    chronology: '<',
  },
  controller: class ChronologyEstateTableComponent {
    constructor(Estate, $async, $log) {
      'ngInject';

      this.Estate = Estate;
      this.$log = $log;

      this.recordPreview = $async(this.recordPreview.bind(this));
    }

    $onInit() {}


    async recordPreview(selectedRecord) {
      try {
        const record = await this.Estate.find(selectedRecord.id);
        await this.Estate.previewModal(record);
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
