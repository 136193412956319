import template from './document.pug';

export const DocumentChronologyFormComponent = {
  template,
  bindings: {
    item: '<',
    recordClass: '<',
    formState: '<',
    onCreate: '&?',
    onDestroy: '&?'
  },
  controller: class DocumentChronologyFormComponent {
    constructor(Chronology, Document, ConnectedRecord, AlertBox, $async, $log, User) {
      'ngInject';

      this.Chronology = Chronology;
      this.User = User;
      this.Document = Document;
      this.ConnectedRecord = ConnectedRecord;
      this.AlertBox = AlertBox;
      this.$log = $log;

      this.saveChronology = $async(this.saveChronology.bind(this));
      this.destroyRecord = $async(this.destroyRecord.bind(this));
    }

    $onInit() {
      if (this.item.record == undefined) { // in new record case
        const newRecord = {
          user_id: this.User.currentId(),
          attachments: [],
          custom_fields: {},
        };

        switch (this.recordClass) {
          case 'contacts':
            newRecord['contact_ids'] = [this.item.chronology_record.id];
            break;

          case 'estates':
            newRecord['estate_ids'] = [this.item.chronology_record.id];
            break;

          default:
            break;
        }

        this.item.record = newRecord;
      }
    }

    changeState({ state }) {
      if (state) this.formState = state;
    }

    async saveChronology({ document, status }) {
      try {
        if (status == 'create') {
          const resp = await this.Document.create(document);
          const newConnectedRecords = angular.copy(document['new_connected_records']);
          await this.ConnectedRecord.batchCreate(resp.data.id, newConnectedRecords);
        } else {
          await this.Document.update(document);
        }

        if (this.item.id) {
          this.item.record = document;
          this.changeState({ state: 'show' });
          this.AlertBox.addMessage('alert.updated');
        } else {
          this.changeState({ state: 'edit' });
          this.onCreate({ $event: { action: 'ReloadList' } });
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    async destroyRecord() {
      try {
        await this.Chronology.destroyChild(this.item.id);
        this.onDestroy({ $event: { chronologyItem: this.item } });
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
