import template from './checkbox-show.pug';

export const CheckBoxShowComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    model: '<',
  },
  controller: class CheckBoxShowComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }

  }
};
