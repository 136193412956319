import template from './proposal-modal-preview.pug';

export const ProposalModalPreviewModalComponent = {
  template,
  bindings:{
    resolve: '<',
    modalInstance: '<',
  },
  controller: class ProposalModalPreviewModalComponent {
    constructor($rootScope) {
      'ngInject';
      this.$rootScope = $rootScope;
    }

    $onInit() {
    }

    close() {
      this.modalInstance.close(void(0));
    }

    destroy() {
      this.$rootScope.$broadcast('ChronologyList.Reload');
      this.modalInstance.close(void(0));
    }

  }
};
