class Statistics {
  constructor(Http, uiGridConstants, Estate, Modal, $translate, TableEdit, User) {
    'ngInject';
    this.Http = Http;
    this.Estate = Estate;
    this.TableEdit = TableEdit;
    this.User = User;
    this.Modal = Modal;
    this.uiGridConstants = uiGridConstants;
    this.$translate = $translate;

    this.columnsSummary = [
      {
        name: this.$translate.instant('#'),
        field: 'num',
        width: 30,
        enableFiltering: false,
        enableSorting: false,
        enableColumnMenu: false,
        cellTemplate: '<div class="ui-grid-cell-contents text-center fs-bolder">{{grid.renderContainers.body.visibleRowCache.indexOf(row)+1}}</div>'
      },

      {
        name: this.$translate.instant('statistics.salesperson'),
        field: 'user',
        minWidth: 300,
        cellTemplate: '<span class="user-cell"><todo-user user="grid.getCellValue(row, col)"></todo-user></span>',
        enableColumnMenu: false,
        enableFiltering: false,
        enableSorting: false,
      },
      {
        name: this.$translate.instant('statistics.contacts'),
        field: 'contacts',
        minWidth: 50,
        enableFiltering: false,
        enableColumnMenu: false,
        aggregationType: this.uiGridConstants.aggregationTypes.sum,
        headerCellClass: this.TableEdit.setSortCellClass(),
        cellClass: this.TableEdit.setSortCellClass(),
      },
      {
        name: this.$translate.instant('statistics.estates'),
        field: 'estates',
        minWidth: 50,
        enableFiltering: false,
        enableColumnMenu: false,
        aggregationType: this.uiGridConstants.aggregationTypes.sum,
        headerCellClass: this.TableEdit.setSortCellClass(),
        cellClass: this.TableEdit.setSortCellClass(),
      },
      {
        name: this.$translate.instant('statistics.inquiries'),
        field: 'inquiries',
        minWidth: 50,
        enableFiltering: false,
        enableColumnMenu: false,
        aggregationType: this.uiGridConstants.aggregationTypes.sum,
        headerCellClass: this.TableEdit.setSortCellClass(),
        cellClass: this.TableEdit.setSortCellClass(),

      },
      {
        name: this.$translate.instant('statistics.todos'),
        field: 'todos',
        minWidth: 50,
        enableFiltering: false,
        enableColumnMenu: false,
        aggregationType: this.uiGridConstants.aggregationTypes.sum,
        headerCellClass: this.TableEdit.setSortCellClass(),
        cellClass: this.TableEdit.setSortCellClass(),

      },
      {
        name: this.$translate.instant('statistics.documents'),
        field: 'documents',
        minWidth: 50,
        enableFiltering: false,
        enableColumnMenu: false,
        aggregationType: this.uiGridConstants.aggregationTypes.sum,
        headerCellClass: this.TableEdit.setSortCellClass(),
        cellClass: this.TableEdit.setSortCellClass(),

      },
      {
        name: this.$translate.instant('statistics.proposals'),
        field: 'multiple_proposals',
        minWidth: 50,
        enableFiltering: false,
        enableColumnMenu: false,
        aggregationType: this.uiGridConstants.aggregationTypes.sum,
        headerCellClass: this.TableEdit.setSortCellClass(),
        cellClass: this.TableEdit.setSortCellClass(),
      }
    ];
  }

  get cardIcons() {
    return {
      contacts: { icon: 'users' },
      estates: { icon: 'home' },
      inquiries: { icon: 'crosshair' },
      todos: { icon: 'check-circle' },
      multiple_proposals: { icon: 'clipboard' },
      virtual_number_leads: { icon: 'phone-call' },
      incomes: { icon: 'dollar-sign' },
      expenses: { icon: 'shopping-cart' },
      documents: { icon: 'folder' },
    };
  }

  viewingRegistrations() {
    return this.Http.get('/statistics/dashboard/viewing-registrations');
  }


  countContacts() {
    return this.Http.get('/statistics/contacts/count');
  }

  blocks(params = {}) {
    return this.Http.get('/statistics/dashboard/blocks', params);
  }

  aggregate(filter) {
    return this.Http.get('/statistics/aggregate-v2', filter);
  }

  getSalesPortfolio(filter) {
    return this.Http.post('/statistics/sales/portfolio', filter);
  }

  getSalesPortfolioFinances(filter) {
    return this.Http.post('/statistics/sales/finances', filter);
  }

  gridOptions(columns) {
    return {
      rowHeight: 51,
      enableSorting: true,
      enableGridMenu: false,
      enableFiltering: true,
      enableHorizontalScrollbar: true,
      columnDefs: columns || this.columnsSummary,
      enableRowSelection: false,
      enableRowHeaderSelection: false,
      enableSelectAll: false,
      fastWatch: false,
      paginationPageSize: 20,
      useExternalPagination: true,
      useExternalSorting: false,
      useExternalFiltering: true,
      enablePaginationControls: false,
      paginationCurrentPage: 1,
      rowTemplate: '<div ng-class="{highlighted: row.entity.preview}" ng-click="grid.appScope.goTo(row);" ng-repeat="col in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ui-grid-cell></div>'
    };
  }

}
export default Statistics;
