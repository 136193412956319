import template from './settings-custom-fields.pug';

export const SettingsCustomFieldsComponent = {
  template,
  bindings: {
  },
  controller: class SettingsCustomFieldsComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {

    }

  }

};
