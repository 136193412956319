import template from './webhooks-edit-modal.pug';

export const WebhooksEditModalComponent = {
  template,
  bindings: {
    modalInstance: '<',
    resolve: '<',
  },
  controller: class WebhooksEditModalComponent {
    constructor(Webhooks, $async) {
      'ngInject';

      this.Webhooks = Webhooks;

      this.availableModules = ['estate', 'contact', 'todo', 'document', 'finance', 'user'];
      this.events = ['create', 'update', 'delete'];

      this.save = $async(this.save.bind(this));
    }

    $onInit() {
      if (this.resolve?.webhook) {
        this.webhook = JSON.parse(JSON.stringify(this.resolve.webhook));
      } else {
        this.webhook = {
          enabled: true,
          url: '',
          subscriptions: [`${this.availableModules[0]}:${this.events[0]}`],
        };
      }

      this.selectedModule = this.webhook.subscriptions[0]?.split(':')[0] || this.availableModules[0];
    }

    isChecked(event) {
      return this.webhook.subscriptions.includes(`${this.selectedModule}:${event}`);
    }

    eventSelected(event) {
      const subscription = `${this.selectedModule}:${event}`;

      if (this.webhook.subscriptions.includes(subscription)) {
        this.webhook.subscriptions = this.webhook.subscriptions.filter((item) => item !== subscription);
      } else {
        this.webhook.subscriptions.push(subscription);
      }

      this.webhook.subscriptions = this.webhook.subscriptions.filter((item) => item.startsWith(this.selectedModule));
    }

    close() {
      this.modalInstance.close(void 0);
    }

    async save() {
      try {
        if (this.webhook?.id) {
          const resp = await this.Webhooks.update(this.webhook.id, this.webhook);
          this.modalInstance.close(resp);
        } else {
          const resp = this.Webhooks.create(this.webhook);
          this.modalInstance.close(resp);
        }
      } catch (e) {
        console.error(e);
      }
    }
  },
};
