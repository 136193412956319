import template from './cf-multiselect-chart.pug';

export const CfMultiselectChartComponent = {
  template,
  bindings: {
    modelName: '<',
    chartName: '<',
    chartTitle: '<',
    filter: '<'
  },
  controller: class CfMultiselectChartComponent {
    constructor(Workspace, ChartService, $stateParams, $async, $log, $scope) {
      'ngInject';

      this.$stateParams = $stateParams;
      this.$log = $log;
      this.Workspace = Workspace;
      this.ChartService = ChartService;

      this.getChartData = $async(this.getChartData.bind(this));
      this.singleColor = {
        backgroundColor: 'rgba(25, 115, 231, 1)',
        hoverBackgroundColor: 'rgba(25, 115, 231, 0.8)'
      };
      this.chartData = {};
      this.selectedYear = new Date().getFullYear();
      this.$scope = $scope;
      $scope.$on('chart-create', function (event, chart) {
        $scope.chart = chart;
      });
      this.chartOptions = {
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: true,
            color: '#fff',
            textShadowBlur: 5,
            textShadowColor: '#333333',
            formatter: (value, ctx) => {
              return this.getPercentage(value);
            },
          }
        }
      }
    }

    onHover(idx){
      if(!this.isChecked(idx)) return;

      let meta = this.$scope.chart.getDatasetMeta(0),
      rect = this.$scope.chart.canvas.getBoundingClientRect(),
      point = meta.data[idx].getCenterPoint(),
      evt = new MouseEvent('mousemove', {
        clientX: rect.left + point.x,
        clientY: rect.top + point.y
      }),
      node = this.$scope.chart.canvas;
      node.dispatchEvent(evt);
    }

    getColor(idx) {
      if(!this.isChecked(idx)) return;
      let meta = this.$scope.chart.getDatasetMeta(0);
      return meta.data[idx].options.backgroundColor;
    }

    $onChanges({ filter } = {}) {
      if (filter && filter.currentValue) {
        this.getChartData();
      }
    }

    async getChartData() {
      try {
        this.activeData = {data: [], labels: []};
        this.selectedDataPoints = [0,1,2,3,4];

        this.chartData = await this.ChartService.get(this.modelName, this.chartName, this.getParams());
        this.selectedDataPoints.forEach(index => {
          this.activeData.data.push(this.chartData.data[index]);
          this.activeData.labels.push(this.chartData.labels[index]);
        });
      } catch (e) {
        this.$log.error(e);
      }
    }

    isChecked(index) {
      return this.selectedDataPoints.includes(index);
    }

    doCheck(index) {
      if(this.isChecked(index)){
        this.selectedDataPoints = this.selectedDataPoints.filter(
          i => i != index
        )
        delete this.activeData.data[index];
        delete this.activeData.labels[index];
      } else {
        this.selectedDataPoints.push(index);
        this.activeData.data[index] = this.chartData.data[index];
        this.activeData.labels[index] = this.chartData.labels[index];
      }
    }

    getPercentage(value) {
      const total = this.chartData.total_count;
      let result = `${(value * 100 / total).toFixed(2)}%`;
      if (total == 0) result = '0.00%';

      return result;
    }

    getParams() {
      return { ...this.filter, search_id: this.$stateParams.searchId, workspace_id: this.Workspace.currentWorkspaceId() };
    }
  }
};

