import template from './table-edit-modal.pug';

export const TableEditModalComponent = {
  template,
  bindings: {
    resolve: '<',
    modalInstance: '<'
  },
  controller: class TableEditModalComponent {
    constructor($log, $async, TableEdit, AlertBox) {
      'ngInject';
      this.$log = $log;
      this.TableEdit = TableEdit;
      this.save = $async(this.save.bind(this));
      this.updated;
      this.selected = [];
      this.checkedList = {};
      this.AlertBox = AlertBox;
      this.save = $async(this.save.bind(this));
    }

    $onInit() {
      this.objectClass = this.resolve.objectClass;
      this.cfObjectClass = this.resolve.cfObjectClass;
      this.translatePrefix = this.resolve.translatePrefix;
      this.fields = this.resolve.fields;
      this.user = this.resolve.user;
    }

    close() {
      this.modalInstance.close(true);
    }

    removed({ selected, checkedList }) {
      this.selected = selected;
      this.checkedList = checkedList;
    }

    setFields(fields) {
      this.fieldsForSave = fields;
      this.save();
    }

    setItems({ selected, checkedList, updated }) {
      this.selected = selected;
      this.checkedList = checkedList;
      this.selected = angular.copy(this.selected);
      this.updated = updated;
    }

    async save() {
      if (!this.fieldsForSave) return;

      try {
        await this.TableEdit.updateColumns(this.objectClass, this.fieldsForSave, 'user', this.user.id);
        this.AlertBox.addMessage('alert.updated', { type: 'success' });
      } catch (e) {
        this.$log.error(e);
      }
    }

  }
};
