import template from './fields-select.pug';

export const FieldsSelectComponent = {
  template,
  bindings: {
    fields: '<',
    selectedFields: '<',
    onEdit: '&',
    selectedModel: '<',
    settings: '<',
    objClass: '<',
    cfObjClass: '<',
    translatePrefix: '<',
    settingsFor: '@?',
    user: '<?'
  },
  controller: class FieldsSelectComponent {
    constructor($translate, $async, $log, $localStorage, CustomField, TableEdit) {
      'ngInject';
      this.$localStorage = $localStorage;
      this.$log = $log;
      this.$translate = $translate;
      this.TableEdit = TableEdit;
      this.CustomField = CustomField;

      this.groups = [];
      this.fieldsSelect = [];
      this.settingsFor = this.settingsFor || 'user';
      this.loadCustomFields = $async(this.loadCustomFields.bind(this));
      this.resolveTableSettings = $async(this.resolveTableSettings.bind(this));

    }

    $onInit() {
      this.selectedFields = this.selectedFields || [];
      this.selectedModel = this.selectedModel || {};
      this.loadCustomFields(this.cfObjClass);
      this.resolveTableSettings(this.objClass);
    }

    getTableSettings(name) {
      if (this.settingsFor == 'account') {
        return this.TableEdit.getAccountTableSettings(name);
      } else {
        const params = (this.user && this.user.id) ? { user_id: this.user.id } : {};
        return this.TableEdit.getUserTableSettings(name, params);
      }
    }

    async resolveTableSettings(name) {
      try {
        const settings = await this.getTableSettings(name);
        if (settings && settings.cf_names) {
          const resp = await this.TableEdit.recheckNames(settings, name);
          this.settings = resp.settings;
          this.setSelected();
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    setSelected() {
      this.settings['columns'].map(elm => {
        this.selectedModel[elm.field_key] = true;
        this.selectedFields.push(elm);
      });
      this.onEdit({$event: {
        selected: this.selectedFields,
        checkedList: this.selectedModel,
        updated: false
      }});
    }

    async loadCustomFields(mod) {
      try {
        let groups = await this.CustomField.loadGroups({ module: mod });
        groups.forEach((elm) => {
          this.fields[elm.title] = this.customFieldsDeserialize(elm.custom_fields);
        });
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.splitGroupsFields();
      }
    }

    customFieldsDeserialize(fields) {
      if (!fields) return;
      let arr = [];
      fields.forEach((field) => {
        // if(field.field_type == 'phone') return;
        arr.push({
          name: field.title,
          type: field.field_type,
          is_custom: true,
          custom_field_key: field.technical_name,
        });
      });
      return arr;
    }

    splitGroupsFields() {
      for (let property in this.fields) {
        const group = this.fields[property];
        if (group && group.length) {
          this.groups.push(
            {
              value: property,
              label: (group[0].is_custom ? property : ('custom_view.group.' + property)),
              fields: group.map(element=> {
                return {
                  field_key: element.custom_field_key,
                  is_custom: element.is_custom || false,
                  name: element.name,
                  type: element.type,
                  width: element.width,
                  pinnedLeft: false
                };
              }),
            }
          );
        }
      }
    }

    changeFields(group, fieldKey) {
      const field = group.fields.find( elm => elm.field_key === fieldKey);
      const exist = this.selectedFields.find(elm => elm.field_key === fieldKey);
      if (exist) {
        this.selectedFields.splice(this.selectedFields.indexOf(exist), 1);
      } else {
        this.selectedFields.push(field);
      }
      this.onEdit({$event: {
        selected: this.selectedFields,
        checkedList: this.selectedModel,
        updated: true
      }});
    }
  }
};
