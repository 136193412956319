import template from './tip.pug';

export const TipComponent = {
  template,
  bindings: {
    text: '@',
    typeClass: '@?'
  },
  transclude : true,
  controller: class TipComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {

    }
  }
};
