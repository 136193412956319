import template from './input-group-date-range-select.pug';
export const InputGroupDateRangeSelectComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    placeholderFrom: '@?',
    placeholderTo: '@?',
    from: '<',
    to: '<',
    validations: '<?',
    onEdit: '&?',
  },
  transclude : true,
  controller: class InputGroupDateRangeSelectComponent {
    constructor() {
      'ngInject';
      this.dateFilter = 'this_month';
      this.dateSelectize = {
        valueField: 'value',
        labelField: 'label',
        searchField: 'label',
        translate: 'true',
        translatePrefix: 'date.',
        plugins: [],
        maxItems: 1
      };
      this.dateOptions = [
        {value: 'this_week', label: 'this_week'},
        {value: 'last_week', label: 'last_week'},
        {value: 'last_2_weeks', label: 'last_2_weeks'},
        {value: 'this_month', label: 'this_month'},
        {value: 'last_month', label: 'last_month'},
        {value: 'this_year', label: 'this_year'},
        {value: 'select_date', label: 'select_date'}
      ];
    }

    $onInit() {
      this.setDates();
    }

    applyDate(dates) {
      if (dates) {
        this.from = dates.from;
        this.to = dates.to;
      } else {
        this.from = this.date_filter.dates.from;
        this.to =  this.date_filter.dates.till;
      }
      this.edit();
    }

    setDates() {
      if (this.dateFilter === 'select_date') return;
      switch (this.dateFilter) {
        case 'this_week':
          this.from = dayjs().startOf('week').format('YYYY-MM-DD');
          this.to = dayjs().format('YYYY-MM-DD');
          return this.edit();
        case 'last_week':
          this.from = dayjs().subtract(1, 'weeks').format('YYYY-MM-DD');
          this.to = dayjs().format('YYYY-MM-DD');
          return this.edit();
        case 'last_2_weeks':
          this.from = dayjs().subtract(2, 'weeks').format('YYYY-MM-DD');
          this.to = dayjs().format('YYYY-MM-DD');
          return this.edit();
        case 'this_month':
          this.from =  dayjs().startOf('month').format('YYYY-MM-DD');
          this.to = dayjs().format('YYYY-MM-DD');
          return this.edit();
        case 'last_month':
          this.from = dayjs().subtract(1, 'months').startOf('months').format('YYYY-MM-DD');
          this.to = dayjs().subtract(1, 'months').endOf('months').format('YYYY-MM-DD');
          return this.edit();
        case 'this_year':
          this.from = dayjs().startOf('year').format('YYYY-MM-DD');
          this.to =  dayjs().format('YYYY-MM-DD');
          return this.edit();
      }
    }

    edit() {
      this.onEdit({
        $event: {
          from: this.from,
          till: this.to
        }
      });
    }

  }
};
