class Viewing {
  constructor(Http, Modal, $translate, Workspace) {
    'ngInject';

    this.Http = Http;
    this.Modal = Modal;
    this.Workspace = Workspace;
    this.$translate = $translate;
  }

  getViewingRegistration(registration_id) {
    return this.Http.get('/viewings/registrations/' + registration_id);
  }

  updateViewingRegistration(registration_id, data) {
    return this.Http.put('/viewings/registrations/' + registration_id, data);
  }

  confirmViewingRegistration(registration_id) {
    return this.Http.post('/viewings/registrations/' + registration_id + '/confirm');
  }

  rejectViewingRegistration(registration_id) {
    return this.Http.post('/viewings/registrations/' + registration_id + '/reject');
  }
}

export default Viewing;
