import template from './user-form-edit.pug';


export const UserFormEditComponent = {
  template,
  bindings: {
    user: '<',
    cfGroups: '<',
    onSave: '&',
    onCancel: '&',
    onUploadPhoto: '&?',
  },
  controller: class UserFormEditComponent {
    constructor($log, User, $async, Role) {
      'ngInject';

      this.$log = $log;
      this.User = User;
      this.Role = Role;
      this.getRoles = $async(this.getRoles.bind(this));
      this.roleConf = {
        valueField: 'id',
        labelField: 'name',
        dropdownParent: 'body',
        allowEmptyOption: false,
        maxItems: 1,
      };
    }

    $onInit() {
      this.temp = angular.copy(this.user);
      this.getRoles();
    }

    save(user) {
      this.onSave({
        $event: { user }
      });
    }

    async getRoles() {
      try {
        this.roles = await this.Role.getList();
      } catch (e) {
        this.$log.error(e);
      }
    }

    cancel() {
      this.user = this.temp;
      this.onCancel({
        $event: { state: 'show' }
      });
    }
  }
};
