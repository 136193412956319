import template from './chronology-contact-table.pug';

export const ChronologyContactTableComponent = {
  template,
  bindings: {
    chronology: '<',
  },
  controller: class ChronologyContactTableComponent {
    constructor(Contact, $async, $log) {
      'ngInject';

      this.Contact = Contact;
      this.$log = $log;

      this.recordPreview = $async(this.recordPreview.bind(this));
    }

    $onInit() {}


    async recordPreview(selectedRecord) {
      try {
        const record = await this.Contact.find(selectedRecord.id);
        await this.Contact.previewModal(record);
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
