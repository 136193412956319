import template from './chronology-document-table.pug';

export const ChronologyDocumentTableComponent = {
  template,
  bindings: {
    chronology: '<',
  },
  controller: class ChronologyDocumentTableComponent {
    constructor(Document, $async, $log) {
      'ngInject';

      this.Document = Document;
      this.$log = $log;

      this.recordPreview = $async(this.recordPreview.bind(this));
    }

    $onInit() {}


    async recordPreview(selectedRecord) {
      try {
        const record = await this.Document.find(selectedRecord.id);
        await this.Document.previewModal(record);
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
