import template from './chronology-list.pug';
import _remove from 'lodash/remove';
import snakeCase from 'lodash/snakeCase';

export const ChronologyListComponent = {
  template,
  bindings: {
    recordClass: '<?',
    record: '<?',
    onDelete: '&?',
    listAction: '<?',
    reloadRecord: '&?',
    reloadChronology: '<?',
    options: '<?'

  },
  controller: class ChronologyListComponent {
    constructor(Chronology, ChronologyList, TableEdit, Estate, User, $scope, AlertBox, $async, $log, $translate, $rootScope, $localStorage) {
      'ngInject';
      this.Chronology = Chronology;
      this.ChronologyList = ChronologyList;
      this.AlertBox = AlertBox;
      this.TableEdit = TableEdit;
      this.$scope = $scope;
      this.$log = $log;
      this.$translate = $translate;
      this.$rootScope = $rootScope;
      this.$localStorage = $localStorage;
      this.Estate = Estate;
      this.User = User;
      this.loadMore = $async(this.loadMore.bind(this));
      this.loadChronologyTypes = $async(this.loadChronologyTypes.bind(this));
      this.openModal = $async(this.openModal.bind(this));
      this.chronologyTypes = ['all'];
      this.chronologiesCounts = {};
      this.renderFilter = {};
      this.filteredChronology = [];
      this.selectedType = 'all';
      this.reloadChronology = false;
      this.showXLSDownload = true;
      this.types = [];
    }

    $onInit() {
      this.ChronologyList.addController(this);
      this.$scope.$on('$destroy', () => {
        this.ChronologyList.removeController();
      });
      this.chronology = [];
      this.page = 1;
      this.endIsReached = false;

      if (this.recordClass) {
        this.selectedView = this.$localStorage['chronology_selected_view'] || 'list';
      } else {
        this.selectedView = 'list';
      }

      if (!this.record) {
        this.loadChronologyTypes();
      }

      if (this.options) {
        if (this.options.hide_xls_download) {
          this.showXLSDownload = false;
        }
      }

      if (this.selectedView == 'table') this.loadMore(true);

      let $ctrl = this;
      this.$rootScope.$on('ChronologyList.Reload', function() {
        $ctrl.chronology = [];
        $ctrl.loadMore(true);
      });
    }

    setRecordChronologyTypes(counted_types) {

      let timePanel = [];
      this.chronologiesCounts = { all: 0 };

      switch (this.recordClass) {
        case 'inquiries': {
          timePanel = this.Chronology.getChildTypesNames(this.User.getUserSettings().inquiry.time_panel);
          timePanel = timePanel.filter(type => !['Offer', 'InquiryMatching', 'InquiryMLSMatching'].includes(type));
        } break;
        case 'estates': {
          timePanel = this.Chronology.getChildTypesNames(this.User.getUserSettings().estate.time_panel);
          timePanel = timePanel.filter(type => type !== 'Offer');
        } break;
        case 'contacts': {
          timePanel = this.Chronology.getChildTypesNames(this.User.getUserSettings().contact.time_panel);
        } break;
      }
      this.types = timePanel.filter(type => type !== 'Status');

      this.types.forEach( (type) => {
        this.chronologiesCounts[type] = counted_types[type] || 0;
      });

      this.chronologiesCountsLength = Object.keys(this.chronologiesCounts).length;
    }


    $onChanges({ listAction, recordId }) {
      if (listAction && listAction.currentValue) {
        this.loadMore(true);
      }

      if (this.reloadChronology && recordId && recordId.previousValue != recordId.currentValue) {
        this.loadMore(true);
      }
    }

    async getEstateSaleReportSetting() {
      try {
        const result = await this.TableEdit.getAccountSettings('estate', 'estate_sale_report');
        this.salesReportEnabled = result.value.enabled || false;
      } catch (error) {
        this.$log.error(error);
      }
    }

    async loadChronologyTypes() {
      try {
        this.chronologiesCounts = await this.Chronology.getCurrentUserChronologiesTypes();
        this.chronologiesCountsLength = Object.keys(this.chronologiesCounts).length;
      } catch (e) {
        this.$log.error(e);
      }
    }

    setChronologyType(type) {
      this.selectedType = type;
      this.setFilter(type);
    }

    translateType(type) {
      return this.$translate.instant('chronology_types.' + type.toLowerCase());
    }

    translateTypeForButton(type) {
      return this.$translate.instant( 'settings.shared.time_panel.' + snakeCase(type) );
    }

    setRecordKeys(chronology) {
      for (let i = 0; i < chronology.length; i++) {
        for (let key in chronology[i].record) {
          chronology[i].record.key = key;
        }
      }
    }

    async openModal(type) {
      try {
        const response = await this.Chronology.openForm({ record: this.record, selected: type, recordClass: this.recordClass });
        if (response) {
          this.loadMore(true);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    exportChronology() {
      try {
        const childIds = this.filteredChronology.map(item => item.id);
        this.Chronology.getExport(childIds);
      } catch (e) {
        this.$log.error(e);
      }
    }

    setFilter(type) {
      this.filter = type;
      this.renderFilter = {};
      if (type === 'all') this.filter = '';
      this.loadMore(true);
    }

    changeSelectedView(selected) {
      this.selectedView = selected;
      this.loadMore(true);
      this.$localStorage['chronology_selected_view'] = selected;
    }

    async loadMore(refresh) {
      try {
        let chronologies, perPage = 10;
        if (refresh) {
          this.page = 1;
          this.endIsReached = false;
          this.chronology = [];
          this.chronologyTable = {};
        }

        if (this.endIsReached || this.loader) {
          this.$log.info('not need load');
          return;
        }

        if (this.selectedView == 'table') {
          perPage = 1000;
        }

        this.loader = true;

        if (!this.record) {
          chronologies = await this.Chronology.getCurrentUserChronologies({ page: this.page, per_page: perPage, record_type: this.filter });
        } else {
          const resp = await this.Chronology.loadMoreChronologies(this.recordClass, this.record.id, { filter: this.filter, page: this.page }, perPage);
          this.setRecordChronologyTypes(resp.meta.count);
          chronologies = resp.data;
        }

        this.chronology = [...this.chronology, ...chronologies];
        if (chronologies && chronologies.length < 10) {
          this.endIsReached = true;
        } else {
          this.page += 1;
        }

        this.setRecordKeys(chronologies);

        if (this.selectedView == 'table') {
          this.chronologyTable = this.chronology.sort((firstItem, secondItem) => firstItem.date > secondItem.date ? -1 : 1).groupBy('record_type');
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    deleteChronologyItem({ chronologyItem }) {
      _remove(this.chronology, { id: chronologyItem.id });
      this.reloadRecord({ $event: { record: chronologyItem.chronology_record } });
      this.AlertBox.addMessage('alert.deleted', { type: 'warning' });
    }

    addToChronology(item) {
      for (let key in item.record) {
        item.record.key = key;
      }
      this.chronology.unshift(item);
    }
  }
};
