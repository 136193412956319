import template from './price-number.pug';

export const PriceNumberComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    priceType: '<',
    model: '<',
    validations: '<?',
    onEdit: '&?',
  },
  controller: class PriceNumberComponent {
    constructor(Estate) {
      'ngInject';
      this.priceOptions = Estate.priceTypes().for_sale;
    }

    $onInit() {
    }

    selectPriceType(value) {
      this.priceType = (value == 'total');
      this.edit();
    }

    edit() {
      this.onEdit({
        $event: {
          number: this.model,
          priceType: this.priceType,
        }
      });
    }

  }
};
