import template from './header.pug';

export const TodoTableHeaderComponent = {
  template,
  bindings: {
    records: '<',
    checkedIds: '<',
    totalPages: '<',
    totalCount: '<',
    page: '<',
    afterPageChange: '&',
    onFilterChange: '&',
    isModal: '<?',
    filter: '<?'
  },
  controller: class TodoTableHeaderComponent {
    constructor(Todo, AlertBox, Table, $rootScope, $async, $log, $stateParams, $state) {
      'ngInject';

      this.Todo = Todo;
      this.AlertBox = AlertBox;
      this.$rootScope = $rootScope;
      this.$state = $state;
      this.$stateParams = $stateParams;
      this.$log = $log;
      this.multipleDelete = $async(this.multipleDelete.bind(this));
      this.export = $async(this.export.bind(this));
      this.openCharts = false;
      this.Table = Table;
    }

    $onChanges({ filter } = {}) {
      if (filter && filter.currentValue) {
        Object.keys(this.filter).length > 1 ? this.disabledClearFilter = false : this.disabledClearFilter = true;
      }
    }

    $onInit() {}

    async export() {
      try {
        let response = await this.Todo.export(this.$stateParams.searchId, this.filter);
        if (response) this.AlertBox.addMessage('alert.submited.export_table', { type: 'success' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async multipleDelete() {
      if (!this.alertIfNotSelected()) return;
      try {
        let object = await this.Todo.multipleDeleteModal(this.checkedIds, this.filter.gantt);
        if (object) {
          this.applyFilter({ searchId: this.$stateParams.searchId });
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    alertIfNotSelected() {
      if (!this.checkedIds.length) {
        this.AlertBox.done('alert.select_at_least_one_record', { type: 'info' });
        return false;
      }
      return true;
    }

    clearFilters() {
      this.$rootScope.gridApi['todos-table'].grid.clearAllFilters();
    }

    hasActiveFilters() {
      return this.Table.hasActiveFilters('todos-table');
    }

    applyPage({ page }) {
      this.afterPageChange({
        $event: { page }
      });
    }

    applyFilter({ searchId }) {
      this.onFilterChange({
        $event: { searchId }
      });
    }
  }
};
