import template from './estate-md-card.pug';

export const EstateMdCardComponent = {
  template,
  bindings: {
    estate: '<',
  },
  controller: class EstateMdCardComponent {
    constructor(Estate) {
      'ngInject';
      this.Estate = Estate;
      this.currency = window.CURRENCY;
      this.image;
    }

    $onChanges( { estate } ) {
      if (estate && estate.currentValue) {
        this.$onInit();
      }
    }

    resolveAreaUnit(unit) {
      return this.Estate.resolveAreaUnit(unit);
    }

    $onInit() {
      this.price = this.Estate.resolvePrice(this.estate);
      this.image = this.estate.image_url;
    }

  }
};
