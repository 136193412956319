import template from './input-group-price-range.pug';

export const InputGroupPriceRangeComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    priceType: '<',
    from: '<',
    to: '<',
    validations: '<?',
    onEdit: '&?',
  },
  controller: class InputGroupPriceRangeComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
      this.priceType = this.priceType || 'currency';
    }


    edit({ from, to }={}) {
      this.from = from || this.from;
      this.to = to || this.to;
      this.onEdit({
        $event: {
          from: this.from,
          to: this.to,
          priceType: this.priceType,
        }
      });
    }

  }
};
