class CustomField {
  constructor(Http, Modal, Todo, RecordStatus, Estate, $translate) {
    'ngInject';

    this.Http = Http;
    this.Modal = Modal;
    this.Estate = Estate;
    this.RecordStatus = RecordStatus;
    this.Todo = Todo;
    this.$translate = $translate;
  }

  newField(field) {
    return this.Http.post('/custom_fields', field);
  }

  editField(field) {
    return this.Http.put('/custom_fields/'+field.id, field);
  }

  createGroupModal(group) {
    return this.Modal.open({
      size: 'md',
      component: 'groupNewModal',
      resolve: {
        group: () => group
      }
    });
  }

  editGroupModal(group) {
    return this.Modal.open({
      size: 'md',
      component: 'groupEditModal',
      resolve: {
        group: () => group
      }
    });
  }

  editGroupPositionModal(groups) {
    return this.Modal.open({
      size: 'md',
      component: 'groupPositionModal',
      resolve: {
        groups: () => groups
      }
    });
  }

  createFieldModal(group) {
    return this.Modal.open({
      size: 'md',
      component: 'fieldNewModal',
      resolve: {
        group: () => group
      }
    });
  }

  editFieldModal(group) {
    return this.Modal.open({
      size: 'md',
      component: 'fieldEditModal',
      resolve: {
        group: () =>  group.group,
        field: () =>  group.field
      }
    });
  }

  newGroup(group) {
    return this.Http.post('/custom_field_groups',group);
  }

  editGroup(group) {
    return this.Http.put('/custom_field_groups/' + group.id,group);
  }

  deleteGroup(id) {
    return this.Http.delete('/custom_field_groups/' + id);
  }

  deleteField(id) {
    return this.Http.delete('/custom_fields/'+id);
  }

  update(id, field) {
    return this.Http.put('/custom_fields/' + id,field);
  }

  lockStatus(custom_field_id, record_id) {
    return this.Http.post('/custom_fields/lock_status', { custom_field_id, record_id });
  }

  lock(custom_field_id, record_id) {
    return this.Http.post('/custom_fields/lock', { custom_field_id, record_id });
  }

  unlock(lock_id) {
    return this.Http.post('/custom_fields/unlock', { lock_id });
  }

  loadGroups(params) {
    return this.Http.get('/custom_field_groups', params);
  }

  updatePosition(id, position) {
    return this.Http.put('/custom_fields/'+id+'/position',{ position });
  }

  updateGroupPosition(id, position) {
    return this.Http.put('/custom_field_groups/' + id + '/position',{ position });
  }

  dateOptions() {
    return ['today','last_day','next_day','this_week','last_week','next_week','this_month','last_month','next_month'];
  }

  getDateFieldsByModel(model) {
    return this.getFieldsByModelAndType(model, 'date');
  }

  // Model is required but type is optional
  getFieldsByModelAndType(model, type, options = {}) {
    return this.Http.get('/custom_fields', { model, field_type: type, ...options });
  }

  getSelectFieldsByModel(model) {
    return this.getFieldsByModelAndType(model, 'multiselect');
  }

  pretranslatedFields() {
    return ['user_id','exports','location'];
  }

  get columns() {
    return {
      'Contact': {
        'main': [
          { custom_field_key: 'default_name', name: 'name', type: 'string' },
          { custom_field_key: 'default_phone', name: 'phone', type: 'string' },
          { custom_field_key: 'default_email', name: 'email', type: 'string' },
          { custom_field_key: 'default_record_status', name: 'record_status', type:'multiselect', options: this.RecordStatus.statusTypes('contact').map(status => ({ id: status.id, value: status.id, name: status.title })), config: { labelField: 'name', searchField: 'name', translate: true } },
          { custom_field_key: 'default_user_id', name: 'user_id', type:'multiselect', config: { labelField: 'name', searchField: 'name' } },
          { custom_field_key: 'default_created_at', name: 'created_at', type: 'date' },
          { custom_field_key: 'default_updated_at', name: 'updated_at', type: 'date' },
        ]
      },
      'Estate': {
        'main': [
          { custom_field_key: 'default_record_status', name: 'record_status', type:'multiselect', options: this.RecordStatus.statusTypes('estate').map(status => ({ id: status.id, value: status.id, name: status.title })), config: { labelField: 'name', searchField: 'name', translate: true } },
          { custom_field_key: 'default_operation_type', name: 'operation_type', type:'radio', options: [{ value: 'for_sale', name:this.$translate.instant('estate.for_sale'), label:'estate.for_sale' }, { value: 'for_rent', name: this.$translate.instant('estate.for_rent'), label:'estate.for_rent' },{ value: 'auction', name: this.$translate.instant('estate.auction'), label:'estate.auction' }] },
          { custom_field_key: 'default_estate_type_id', name: 'estate_type_id', type:'multiselect', options: this.Estate.types().map(type => ({ id: type.id, value: type.id, name: type.name })), config: { labelField: 'name', searchField: 'name', translate: true } },
          { custom_field_key: 'default_image_url', name: 'image_url', type:'radio', options: [] },
          { custom_field_key: 'default_user_id', name: 'user_id', type:'multiselect', config: { labelField: 'name', valueField: 'id', searchField: 'name' } },
          { custom_field_key: 'default_location', name: 'location', type:'location' },
          { custom_field_key: 'default_area', name: 'area', type:'integer' },
          { custom_field_key: 'default_price', name: 'price', type:'integer', types: ['Eur','Eur/kv.m'] },
          { custom_field_key: 'default_square_price', name: 'square_price', type:'integer', types: ['Eur','Eur/kv.m'] },
          { custom_field_key: 'default_title', name: 'title', type:'string' },
          { custom_field_key: 'default_created_at', name: 'created_at', type: 'date' },
          { custom_field_key: 'default_updated_at', name: 'updated_at', type: 'date' },
        ],
      },
      'Inquiry': {
        'main': [
          { custom_field_key: 'default_operation_type', name: 'operation_type', type:'radio', options: [{ value: 'for_sale', name:this.$translate.instant('inquiry.options.operation_type.for_sale'), label:'inquiry.for_sale' }, { value: 'for_rent', name: this.$translate.instant('inquiry.options.operation_type.for_rent'), label:'inquiry.options.operation_type.for_rent' },{ value: 'auction', name: this.$translate.instant('inquiry.options.operation_type.auction'), label:'inquiry.options.operation_type.auction' }] },
          { custom_field_key: 'default_estate_type_id', name: 'estate_type_id', type:'multiselect', options: this.Estate.types().map(type => ({ id: type.id, value: type.id, name: type.name })), config: { labelField: 'name', searchField: 'name', translate: true } },
          { custom_field_key: 'default_user_id', name: 'user_id', type:'multiselect', config: { labelField: 'name', searchField: 'name',  valueField: 'id' } },
          { custom_field_key: 'default_location_name', name: 'location', type:'location' },
          { custom_field_key: 'default_contact', name: 'contact', type: 'string' },
          { custom_field_key: 'default_notes', name: 'notes', type:'string' },
          { custom_field_key: 'default_record_status', name: 'record_status', type:'multiselect', options: this.RecordStatus.statusTypes('inquiry').map(status => ({ id: status.id, value: status.id, name: status.title })), config: { labelField: 'name', searchField: 'name', translate: true } },
          { custom_field_key: 'default_area', name: 'area', type:'integer' },
          { custom_field_key: 'default_price', name: 'price', type:'integer' },
          { custom_field_key: 'default_created_at', name: 'created_at', type: 'date' },
          { custom_field_key: 'default_updated_at', name: 'updated_at', type: 'date' },
        ]
      },
      'Todo': {
        'main': [
          { custom_field_key: 'default_todo_group_id', name: 'todo_group_id', type:'multiselect', config: { labelField: 'name', valueField: 'id', searchField: 'name' } },
          { custom_field_key: 'default_state', name: 'state', type: 'radio', options: this.Todo.statuses().map(item => ({ value: item.status, name: this.$translate.instant(item.textKey) })) },
          { custom_field_key: 'default_notes', name: 'notes', type: 'string' },
          { custom_field_key: 'default_todo_users_ids', name: 'todo_users_ids', type:'multiselect', config: { labelField: 'name', valueField: 'id', searchField: 'name' } },
          { custom_field_key: 'default_created_at', name: 'created_at', type: 'date' },
          { custom_field_key: 'default_todo_contacts', name: 'todo_contacts', type:'string' },
          { custom_field_key: 'default_todo_inquiries', name: 'todo_inquiries', type:'string' },
          { custom_field_key: 'default_todo_estates', name: 'todo_estates', type:'string' },
          { custom_field_key: 'default_user_id', name: 'user_id', type:'multiselect', config: { labelField: 'name', valueField: 'id', searchField: 'name' } },
        ]
      },
      'Document': {
        'main': [
          { custom_field_key: 'default_document_group_id', name: 'document_group_id', type:'multiselect', config: { labelField: 'name', valueField: 'id', searchField: 'name' } },
          { custom_field_key: 'default_created_at', name: 'created_at', type: 'date' },
          { custom_field_key: 'default_user_id', name: 'user_id', type:'multiselect', config: { labelField: 'name', valueField: 'id', searchField: 'name' } },
          { custom_field_key: 'default_contacts', name: 'contacts', type:'connected_record',  validations: { destination: 'contacts' } },
          { custom_field_key: 'default_estates', name: 'estates', type:'connected_record',  validations: { destination: 'estates' } },
        ]
      },
      'Finance': {
        'main': [
          { custom_field_key: 'default_date_at', name: 'date_at', type: 'date' },
          { custom_field_key: 'default_operation_type', name: 'finance_operation_type', type: 'multiselect', options: [{ id: 'income', name: this.$translate.instant('finances.reports.income') }, { id: 'expenses', name: this.$translate.instant('finances.reports.expenses') }], config: { labelField: 'name', searchField: 'name', valueField: 'id' } },
          { custom_field_key: 'default_finance_group_id', name: 'finance_group_id', type:'multiselect', config: { labelField: 'name', searchField: 'name',  valueField: 'id' } },
          { custom_field_key: 'default_sum', name: 'sum', type: 'integer' },
          { custom_field_key: 'default_comment', name: 'comment', type: 'string' },
          { custom_field_key: 'default_user_id', name: 'user_id', type:'multiselect', config: { labelField: 'name', searchField: 'name',  valueField: 'id' } },
          { custom_field_key: 'default_contacts', name: 'contacts', type:'connected_record',  validations: { destination: 'contacts' } },
          { custom_field_key: 'default_estates', name: 'estates', type:'connected_record',  validations: { destination: 'estates' } },
        ]
      },
      'XlsDownload': {
        'main': [
          { custom_field_key: 'default_created_at', name: 'created_at', type: 'date' },
          {
            custom_field_key: 'default_module',
            name: 'module',
            type: 'multiselect',
            options: [
              { id: 'contacts', name: this.$translate.instant('general.contacts') },
              { id: 'estates', name: this.$translate.instant('general.estates') },
              { id: 'inquiries', name: this.$translate.instant('general.inquiries') },
              { id: 'finances', name: this.$translate.instant('general.finances') },
              { id: 'todos', name: this.$translate.instant('general.todos') },
            ],
            config: { labelField: 'name', searchField: 'name',  valueField: 'id' }
          },
          { custom_field_key: 'default_records_count', name: 'records_count', type: 'integer' },
          { custom_field_key: 'default_user_id', name: 'user_id', type:'multiselect', config: { labelField: 'name', searchField: 'name',  valueField: 'id' } },
        ]
      },
    };
  }

  get fieldList() {
    return [
      {
        field_type: 'text',
        icon: 'minus',
        name: 'custom_fields.text_info',
      },
      {
        field_type: 'text_area',
        icon: 'align-left',
        name: 'custom_fields.text_area_info',
      },
      {
        field_type: 'multiselect',
        icon: 'chevron-down',
        name: 'custom_fields.multiselect_info',
      },
      {
        field_type: 'link',
        icon: 'link',
        name: 'custom_fields.link_info',
      },
      {
        field_type: 'email',
        icon: 'mail',
        name: 'custom_fields.email_info',
      },
      {
        field_type: 'date',
        icon: 'calendar',
        name: 'custom_fields.date_info',
      },
      {
        field_type: 'number',
        icon: 'divide',
        name: 'custom_fields.number_info',
      },
      {
        field_type: 'money',
        icon: 'dollar-sign',
        name: 'custom_fields.money_info',
      },
      {
        field_type: 'phone',
        icon: 'phone',
        name: 'custom_fields.phone',
      },
      {
        field_type: 'file',
        icon: 'paperclip',
        name: 'custom_fields.file',
        validations: { destination: 'documents' }
      },
      {
        field_type: 'checkbox',
        icon: 'check-square',
        name: 'custom_fields.checkbox_info',
        options: [
          { value: true, name: this.$translate.instant('custom_view.checked') },
          { value: false, name: this.$translate.instant('custom_view.not_checked') }
        ]
      },
      {
        field_type: 'connected_record',
        icon: 'users',
        name: 'custom_fields.contact',
        validations: { destination: 'contacts' }
      },
      {
        field_type: 'connected_record',
        icon: 'home',
        name: 'custom_fields.estate',
        validations: { destination: 'estates' }
      },
      {
        field_type: 'connected_record',
        icon: 'crosshair',
        name: 'custom_fields.inquiry',
        validations: { destination: 'inquiries' }
      },
    ];
  }
}
export default CustomField;
