// base component
import { CopyIconComponent } from './copy-icon/copy-icon.component';
import { IconComponent } from './icon.component';
import { QrCodeIconComponent } from './qr-code-icon/qr-code-icon.component';

export const IconModule = angular
  .module('topbroker.icons', [])
  .component('icon', IconComponent)
  .component('qrCodeIcon', QrCodeIconComponent)
  .component('copyIcon', CopyIconComponent).name;
