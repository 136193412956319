class ChartService {
  constructor(Http) {
    'ngInject';
    this.Http = Http;
  }

  list(modelName){
    return this.Http.get(`/${modelName}/charts/list`);
  }

  get(model_name, chart_name, params){
    return this.Http.post(`/${model_name}/charts/show/${chart_name}`, params);
  }
}
export default ChartService;
