// cell templates
import previewCell from './templates/cell/preview.pug';
import openLinkCell from './templates/cell/open-link.pug';
// cell editig mode templates
import numberEdit from './templates/cell/editing/number.pug';

import { camelCase, startCase } from 'lodash';

// head filters
import textHead from './templates/head/text.pug';
import numberHead from './templates/head/number.pug';

class TableEdit {
  constructor($localStorage, $async, $log, $translate, Http, Modal, CustomField, uiGridConstants, User) {
    'ngInject';
    this.Http = Http;
    this.Modal = Modal;
    this.User = User;
    this.$log = $log;
    this.CustomField = CustomField;
    this.uiGridConstants = uiGridConstants;
    this.$localStorage = $localStorage;
    this.$translate = $translate;
  }

  getUserTableSettings(model, params = {}) {
    return this.Http.get(`/settings/user/${model}/custom_table_fields`, params);
  }

  getAccountTableSettings(model) {
    return this.Http.get(`/settings/account/${model}/custom_table_fields`);
  }

  getUserSettings(module, key) {
    return this.Http.get('/settings/user/', { module, key });
  }

  getAccountSettings(module, key) {
    return this.Http.get('/settings/account/', { module, key });
  }

  updateAccountSettings(params) {
    return this.Http.put('/settings/account/', params);
  }

  updateUserSettings(params) {
    return this.Http.put('/settings/user/', params);
  }

  getColumnByClass(moduleClass) {
    return this.CustomField.columns[startCase(camelCase(moduleClass)).replace(/ /g, '')]; // TODO: refactor moduleClass to lower case & singular
  }

  serializeColumns(fields, isModal, translatePrefix, moduleClass) {
    if (!fields) return;
    let arr = [];

    const modulesDontHaveOpenNew = ['document', 'finance', 'todo'];
    if (isModal || modulesDontHaveOpenNew.includes(moduleClass)) {
      // arr = [{ type: 'n' }, ...fields];
      arr = [{ type: 'preview' }, ...fields];
    } else {
      arr = [{ type: 'open' }, ...fields];
    }

    const rez = arr.map(element => {
      return this.getTemplateByType(element, isModal, translatePrefix, moduleClass);
    });

    return rez;
  }

  getTemplateByType(field, isModal, translatePrefix, moduleClass) {
    translatePrefix = translatePrefix ? translatePrefix + '.' : '';
    let col;
    let move = isModal ? false : true;

    switch (field.type) {
      case 'n':
        col =  {
          name: ' ',
          field: '',
          field_key: '',
          pinnedLeft: true,
          enableFiltering: false,
          enableSorting: false,
          enableCellEdit: false,
          allowCellFocus: false,
          enableColumnMoving: move,
          enableColumnMenu: false,
          enableColumnResizing: move,
          width: 30,
          minWidth: 30,
          maxWidth: 30,
        };
        break;
      case 'open':
        col =  {
          field: 'open',
          name: ' ',
          field_key: '',
          pinnedLeft: false,
          enableColumnMoving: false,
          enableFiltering: false,
          enableColumnResizing: move,
          enableSorting: false,
          enableCellEdit: false,
          allowCellFocus: false,
          enableColumnMenu: false,
          width: 50,
          cellTemplate: openLinkCell
        };
        break;
      case 'preview':
        col =  {
          field: 'preview',
          name: '',
          field_key: '',
          pinnedLeft: false,
          enableColumnMoving: false,
          enableFiltering: false,
          enableColumnResizing: move,
          enableSorting: false,
          enableCellEdit: false,
          allowCellFocus: false,
          enableColumnMenu: false,
          width: 50,
          cellTemplate: previewCell
        };
        break;

      case 'string':
        col = {
          name: field.name,
          displayName: field.is_custom ? field.name : this.$translate.instant(translatePrefix + field.name),
          field: field.is_custom ? field.field_key : field.name,
          field_key: field.field_key,
          pinnedLeft: field.pinnedLeft,
          enableFiltering: true,
          enableCellEdit: false,
          allowCellFocus: false,
          enableColumnMoving: move,
          enableColumnResizing: move,
          enableColumnMenu: false,
          fieldType: field.type,
          width: field.width,
          minWidth: 110,
          cellTemplate: '<div class="ui-grid-cell-contents" title="{{grid.getCellValue(row, col)}}">{{grid.getCellValue(row, col)}}</div>',
          filterHeaderTemplate: textHead,
          filter: field.is_custom ? { name: field.field_key } : { name: `search_by_${field.name}`  },
        };
        if (!field.width) delete col.width;

        switch (field.name) {
          case 'contact':
            col.cellTemplate = `<div class="ui-grid-cell-contents">
              <todo-contact ng-if="grid.getCellValue(row, col)" contact="grid.getCellValue(row, col)"></todo-contact>
            </div>`;
            break;
          case 'inquiry':
            col.cellTemplate = `<div class="ui-grid-cell-contents">
              <inquiry-assignment-list-item ng-if="grid.getCellValue(row, col)" inquiry="grid.getCellValue(row, col)"></inquiry-assignment-list-item>
            </div>`;
            break;
          case 'estate':
            col.minWidth = 360;
            col.cellTemplate = `<div class="ui-grid-cell-contents">
              <todo-estate ng-if="grid.getCellValue(row, col)" estate="grid.getCellValue(row, col)"></todo-estate>
            </div>`;
            break;
          case 'todo_estates':
            col.minWidth = 250;
            col.cellTemplate = `<div class="ui-grid-cell-contents">
              <connected-record-items records="grid.getCellValue(row, col)" type="estates"></connected-record-items>
            </div>`;
            break;
          case 'todo_contacts':
            col.minWidth = 250;
            col.cellTemplate = `<div class="ui-grid-cell-contents">
              <connected-record-items records="grid.getCellValue(row, col)" type="contacts"></connected-record-items>
            </div>`;
            break;
          case 'todo_inquiries':
            col.minWidth = 250;
            col.cellTemplate = `<div class="ui-grid-cell-contents">
              <connected-record-items records="grid.getCellValue(row, col)" type="inquiries"></connected-record-items>
            </div>`;
            break;
        }
        break;
      case 'text':
      case 'text_area':
      case 'phone':
        col = {
          name: field.name,
          displayName: field.is_custom ? field.name : this.$translate.instant(translatePrefix + field.name),
          field: field.is_custom ? field.field_key : field.name,
          field_key: field.field_key,
          pinnedLeft: field.pinnedLeft,
          enableFiltering: true,
          enableCellEdit: false,
          allowCellFocus: false,
          enableColumnMoving: move,
          enableColumnResizing: move,
          enableColumnMenu: false,
          fieldType: field.type,
          width: field.width,
          minWidth: 135,
          cellTemplate: '<div class="ui-grid-cell-contents" title="{{grid.getCellValue(row, col)}}">{{grid.getCellValue(row, col)}}</div>',
          filterHeaderTemplate: textHead,
          filter: field.is_custom ? { name: field.field_key } : { name: 'search_by_'+field.name },
        };
        if (!field.width) delete col.width;
        break;
      case 'integer':
      case 'number':
        col = {
          name: field.name,
          displayName: field.is_custom ? field.name : this.$translate.instant(translatePrefix + field.name),
          field: field.is_custom ? field.field_key : field.name,
          enableFiltering: true,
          enableColumnMenu: false,
          enableCellEditOnFocus: true,
          allowCellFocus: true,
          type: 'number',
          enableColumnMoving: move,
          enableColumnResizing: move,
          pinnedLeft: field.pinnedLeft,
          field_key: field.field_key,
          fieldType: field.type,
          width: field.width,
          minWidth: 135,
          enableSorting: true,
          cellTemplate: '<div class="ui-grid-cell-contents">{{(grid.getCellValue(row, col) | money)}}</div>',
          editableCellTemplate: numberEdit,
          filterHeaderTemplate: numberHead,
          sortDirectionCycle: [this.uiGridConstants.ASC, this.uiGridConstants.DESC],
          filters: [
            {
              name: `${field.name}_min`,
              placeholder: this.$translate.instant('custom_view.condition.range_from')
            },
            {
              name: `${field.name}_max`,
              placeholder: this.$translate.instant('custom_view.condition.range_to')
            }
          ]
        };
        if (!field.width) delete col.width;
        break;
      case 'connected_record':
        col = {
          name: field.name,
          displayName: field.is_custom ? field.name : this.$translate.instant(translatePrefix + field.name),
          field: field.is_custom ? field.field_key : field.name,
          field_key: field.field_key,
          enableColumnMenu: false,
          enableCellEdit: false,
          allowCellFocus: false,
          enableColumnMoving: move,
          enableColumnResizing: move,
          translatePrefix: field.translatePrefix,
          pinnedLeft: field.pinnedLeft,
          fieldType: field.type,
          minWidth: 235,
          width: field.width,
          cellTemplate: `
          <div class="ui-grid-cell-contents">
            <connected-record-items records="grid.getCellValue(row, col)" type="{{col.filter.validations.destination}}"></connected-record-items>
          </div>`,
          filterHeaderTemplate: `
          <connected-record-filter
           type="{{col.filter.validations.destination}}"
           selected-ids="col.filter.term"
           on-select="col.filter.term = $event"
           on-remove-filter="col.filter.term = undefined"
          >
          </connected-record-filter>
          `,
          filter: {
            custom: field.is_custom,
            name: field.is_custom ? field.field_key : field.name,
            validations: field.is_custom ? [] : this.getMainOptions(moduleClass, field.name)
          }
        };
        if (!field.width) delete col.width;
        break;
      case 'multiselect':
        switch (field.name) {
          case 'user_id':
          case 'assigned_user_id':
            col = {
              name: this.$translate.instant(translatePrefix + field.name),
              enableColumnMenu: false,
              enableCellEdit: false,
              allowCellFocus: false,
              enableColumnMoving: move,
              enableColumnResizing: move,
              field: field.is_custom ? field.field_key : field.name,
              field_key: field.field_key,
              translatePrefix: field.translatePrefix,
              pinnedLeft: field.pinnedLeft,
              width: field.width,
              minWidth: 185,
              fieldType: field.type,
              cellTemplate: '<span class="user-cell"><todo-user user="grid.getCellValue(row, col)"></todo-user></span>',
              filterHeaderTemplate: `
              <div class="ui-grid-filter-container">
                <div class="filter-input">
                  <ui-select append-to-body="true" ng-model="col.filter.term" theme="selectize">
                    <ui-select-match placeholder="...">{{$select.selected.name}}</ui-select-match>
                    <ui-select-choices repeat="item[col.filter.config.valueField] as item in col.filter.selectOptions | filter: $select.search">
                      <div ng-bind-html="item.name | highlight: $select.search"></div>
                    </ui-select-choices>
                  </ui-select>
                </div>
                <a class="filter-input-remove" ng-if="col.filter.term" ng-click="col.filter.term = undefined"><icon icon="('x-circle')" /></a>
              </div>
              `,
              filter: {
                translatePrefix: field.translatePrefix,
                name: field.name,
                config: {
                  valueField: 'id',
                  labelField: 'name',
                },
                selectOptions: []
              }
            };
            if (!field.width) delete col.width;
            return col;
          case 'todo_users_ids':
            col = {
              name: this.$translate.instant(translatePrefix + field.name),
              enableColumnMenu: false,
              enableCellEdit: false,
              allowCellFocus: false,
              enableColumnMoving: move,
              enableColumnResizing: move,
              field: field.is_custom ? field.field_key : field.name,
              field_key: field.field_key,
              translatePrefix: field.translatePrefix,
              pinnedLeft: field.pinnedLeft,
              width: field.width,
              minWidth: 185,
              fieldType: field.type,
              cellTemplate: '<span class="user-cell"><todo-user ng-repeat="user in grid.getCellValue(row, col) track by $index" user="user" only-icon="true"></todo-user></span>',
              filterHeaderTemplate: `
              <div class="ui-grid-filter-container">
                <div class="filter-input multi-select">
                  <ui-select  append-to-body="true" multiple ng-model="col.filter.term" theme="selectize">
                    <ui-select-match placeholder="...">{{$item[col.filter.config.labelField]}}</ui-select-match>
                    <ui-select-choices repeat="item[col.filter.config.valueField] as item in col.filter.selectOptions | filter: $select.search">
                      <div ng-bind-html="item[col.filter.config.labelField] | highlight: $select.search"></div>
                    </ui-select-choices>
                  </ui-select>
                </div>
                <a class="filter-input-remove" ng-if="col.filter.term && col.filter.term.length" ng-click="col.filter.term = undefined"><icon icon="('x-circle')" /></a>
              </div>
              `,
              filter: {
                translatePrefix: field.translatePrefix,
                name: field.name,
                config: {
                  valueField: 'id',
                  labelField: 'name',
                },
                selectOptions: []
              }
            };
            if (!field.width) delete col.width;
            return col;
          default:
            col = {
              name: field.name,
              displayName: field.is_custom ? field.name : this.$translate.instant(translatePrefix + field.name),
              field: field.is_custom ? field.field_key : field.name,
              field_key: field.field_key,
              enableColumnMenu: false,
              enableCellEdit: false,
              allowCellFocus: false,
              enableColumnMoving: move,
              enableColumnResizing: move,
              translatePrefix: field.translatePrefix,
              pinnedLeft: field.pinnedLeft,
              fieldType: field.type,
              minWidth: 135,
              width: field.width,
              cellTemplate: `
              <div class="ui-grid-cell-contents">
                <input-multiselect-show translate-prefix="col.filter.translatePrefix" options="col.filter.selectOptions" config="col.filter.config" model="grid.getCellValue(row, col)">
                </input-multiselect-show>
              </div>`,
              filterHeaderTemplate: `
              <div class="ui-grid-filter-container">
                <div class="filter-input multi-select">
                  <ui-select  append-to-body="true" multiple ng-model="col.filter.term" theme="selectize">
                    <ui-select-match placeholder="...">{{$item[col.filter.config.labelField]}}</ui-select-match>
                    <ui-select-choices repeat="item[col.filter.config.valueField] as item in col.filter.selectOptions | filter: $select.search">
                      <div ng-bind-html="item[col.filter.config.labelField] | highlight: $select.search"></div>
                    </ui-select-choices>
                  </ui-select>
                </div>
                <a class="filter-input-remove" ng-if="col.filter.term && col.filter.term.length" ng-click="col.filter.term = undefined"><icon icon="('x-circle')" /></a>
              </div>
              `,
              filter: {
                translatePrefix: field.is_custom ? '':translatePrefix+'options.'+field.name+'.',
                custom: field.is_custom,
                name: field.is_custom ? { name: field.field_key } : field.name,
                config: {
                  valueField: 'id',
                  labelField: field.is_custom ? 'title' : 'name',
                },
                selectOptions: field.is_custom ? [] : this.getMainOptions(moduleClass, field.name)
              }
            };
            if (!field.width) delete col.width;
            break;
        }
        break;
      case 'radio':
        switch (field.name) {
          case 'image_url':
            col = {
              name: this.$translate.instant('photos.photo'),
              field: 'image_url',
              enableColumnMenu: false,
              enableSorting: false,
              enableCellEdit: false,
              allowCellFocus: false,
              enableColumnMoving: move,
              enableColumnResizing: move,
              enableFiltering: false,
              width: field.width,
              minWidth: 60,
              maxWidth: 90,
              cellTemplate: '<div class="fn-table-image" style="background-image: url({{grid.getCellValue(row, col)}})"></div>'
            };
            if (!col.width) delete col.width;
            break;
          default:
            col = {
              name: field.name,
              displayName: field.is_custom ? field.name : this.$translate.instant(translatePrefix + field.name),
              field: field.is_custom ? field.field_key : field.name,
              field_key: field.field_key,
              pinnedLeft: field.pinnedLeft,
              enableSorting: false,
              enableCellEdit: false,
              allowCellFocus: false,
              enableColumnMoving: move,
              enableColumnResizing: move,
              enableColumnMenu: false,
              enableFiltering: true,
              fieldType: field.type,
              minWidth: 85,
              width: field.width,
              cellTemplate: '<div ng-if="grid.getCellValue(row, col)" class="ui-grid-cell-contents">{{ col.filter.translatePrefix+\'options.\'+col.filter.name+\'.\'+ grid.getCellValue(row, col) | translate  }}</div>',
              filterHeaderTemplate: `
              <div class="ui-grid-filter-container">
                <div class="filter-input">
                  <ui-select append-to-body="true" ng-model="col.filter.term" theme="selectize">
                    <ui-select-match placeholder="...">{{$select.selected.name}}</ui-select-match>
                    <ui-select-choices repeat="item.value as item in col.filter.selectOptions | filter: $select.search">
                      <div ng-bind-html="item.name | highlight: $select.search"></div>
                    </ui-select-choices>
                  </ui-select>
                </div>
                <a class="filter-input-remove" ng-if="col.filter.term && col.filter.term.length" ng-click="col.filter.term = undefined"><icon icon="('x-circle')" /></a>
              </div>
              `,
              filter: {
                translatePrefix: translatePrefix,
                name: field.is_custom ? { name: field.field_key } : field.name,
                selectOptions: field.is_custom ? [] : this.getMainOptions(moduleClass, field.name)
              },
            };
            if (!field.width) delete col.width;
            break;
        }
        break;
      case 'date':
        col =  {
          name: field.name,
          displayName: field.is_custom ? field.name : this.$translate.instant(translatePrefix + field.name),
          field: field.is_custom ? field.field_key : field.name,
          field_key: field.field_key,
          pinnedLeft: field.pinnedLeft,
          enableColumnMenu: false,
          enableSorting: true,
          enableCellEdit: false,
          allowCellFocus: false,
          enableColumnMoving: move,
          enableColumnResizing: move,
          width: field.width,
          minWidth: 125,
          enableFiltering: true,
          fieldType: field.type,
          cellTemplate: '<div class="ui-grid-cell-contents">{{ (grid.getCellValue(row, col)) ? (grid.getCellValue(row, col) | dayjs: "format":  "YYYY-MM-DD"):"---";  }}</div>',
          sortDirectionCycle: [this.uiGridConstants.ASC, this.uiGridConstants.DESC],
          filterHeaderTemplate: `
          <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters track by $index">
            <div class="filter-input-date" ng-class="{active: (col.filters[$index].term)}">
              <input class="form-control" ng-model-options="{ debounce: 300 }" ng-model="col.filters[$index].term" type="text" placeholder="{{colFilter.placeholder}}" ng-flatpickr fp-opts="colFilter.datepickerConfig" />
            </div>
            <a class="filter-input-remove" ng-if="colFilter.term && colFilter.term.length" ng-click="colFilter.term = undefined"><icon icon="('x-circle')" /></a>
          </div">
          `,
          filters: [
            {
              name: `${field.name}_from`,
              datepickerConfig: {
                dateFormat: 'Y-m-d',
                minDate: '1940',
                maxDate: '2030',
              },
              placeholder: this.$translate.instant('custom_view.condition.range_from')
            },
            {
              name: `${field.name}_to`,
              datepickerConfig: {
                dateFormat: 'Y-m-d',
                minDate: '1940',
                maxDate: '2030',
              },
              placeholder: this.$translate.instant('custom_view.condition.range_to')
            }
          ]
        };
        break;
      case 'link':
        col = {
          name: field.name,
          displayName: field.is_custom ? field.name : this.$translate.instant(translatePrefix + field.name),
          field: field.is_custom ? field.field_key : field.name,
          field_key: field.field_key,
          pinnedLeft: field.pinnedLeft,
          fieldType: field.type,
          enableColumnMoving: move,
          enableColumnResizing: move,
          enableCellEdit: false,
          allowCellFocus: false,
          enableFiltering: true,
          enableColumnMenu: false,
          minWidth: 150,
          cellTemplate: '<div class="ui-grid-cell-contents"><input-link-show model="grid.getCellValue(row, col)"></input-link-show></div>',
          width: field.width,
          filter: {
            name: field.is_custom ? { name: field.field_key } : field.name,
          },
        };
        break;
      case 'email':
        col = {
          name: field.name,
          displayName: field.is_custom ? field.name : this.$translate.instant(translatePrefix + field.name),
          field: field.is_custom ? field.field_key : field.name,
          field_key: field.field_key,
          pinnedLeft: field.pinnedLeft,
          fieldType: field.type,
          enableColumnMoving: move,
          enableColumnResizing: move,
          enableCellEdit: false,
          allowCellFocus: false,
          enableFiltering: true,
          minWidth: 150,
          cellTemplate: '<div class="ui-grid-cell-contents"><input-email-show model="grid.getCellValue(row, col)"></input-email-show></div>',
          width: field.width,
          filter: field.is_custom ? { name: field.field_key } : { name: 'search_by_'+field.name },
        };
        break;
      case 'file':
        col = {
          name: field.name,
          displayName: field.is_custom ? field.name : this.$translate.instant(translatePrefix + field.name),
          field: field.is_custom ? field.field_key : field.name,
          field_key: field.field_key,
          pinnedLeft: field.pinnedLeft,
          enableFiltering: true,
          enableColumnMoving: move,
          enableColumnResizing: move,
          fieldType: field.type,
          enableColumnMenu: false,
          enableCellEdit: false,
          allowCellFocus: false,
          width: field.width,
          minWidth: 300,
          cellTemplate: `
          <div class="ui-grid-cell-contents">
            <connected-record-items records="grid.getCellValue(row, col)" type="documents"></connected-record-items>
          </div>`,
          filterHeaderTemplate: `
          <div class="ui-grid-filter-container">
            <div class="filter-input">
              <ui-select append-to-body="true" ng-model="col.filter.term" theme="selectize">
                <ui-select-match placeholder="...">{{$select.selected.name}}</ui-select-match>
                <ui-select-choices repeat="item.value as item in col.filter.selectOptions | filter: $select.search">
                  <div ng-bind-html="item.name | highlight: $select.search"></div>
                </ui-select-choices>
              </ui-select>
            </div>
            <a class="filter-input-remove" ng-if="col.filter.term && col.filter.term.length" ng-click="col.filter.term = undefined"><icon icon="('x-circle')" /></a>
          </div>
          `,
          filter: {
            name: field.is_custom ? { name: field.field_key } : field.name,
            selectOptions: [{ value: 'known', name: this.$translate.instant('custom_view.condition.known') },{ value: 'unknown', name: this.$translate.instant('custom_view.condition.unknown') }]
          }
        };
        break;
      case 'checkbox':
        col = {
          name: field.name,
          displayName: field.is_custom ? field.name : this.$translate.instant(translatePrefix + field.name),
          field: field.is_custom ? field.field_key : field.name,
          field_key: field.field_key,
          pinnedLeft: field.pinnedLeft,
          enableSorting: false,
          enableCellEdit: false,
          allowCellFocus: false,
          enableFiltering: true,
          enableColumnMoving: move,
          enableColumnResizing: move,
          enableColumnMenu: false,
          fieldType: field.type,
          minWidth: 200,
          width: field.width,
          cellTemplate: `
          <div class="ui-grid-cell-contents">
            <input-checkbox-show model="grid.getCellValue(row, col)" placeholder="{{colFilter.placeholder}}"> </input-checkbox-show>
          </div>`,
          filterHeaderTemplate: `
          <div class="ui-grid-filter-container">
            <div class="filter-input">
              <ui-select append-to-body="true" ng-model="col.filter.term" theme="selectize">
                <ui-select-match placeholder="...">{{$select.selected.name}}</ui-select-match>
                <ui-select-choices repeat="item.value as item in col.filter.selectOpt | filter: $select.search">
                  <div ng-bind-html="item.name | highlight: $select.search"></div>
                </ui-select-choices>
              </ui-select>
            </div>
            <a class="filter-input-remove" ng-if="col.filter.term && col.filter.term.length" ng-click="col.filter.term = undefined"><icon icon="('x-circle')" /></a>
          </div>
          `,
          filter: {
            name: field.is_custom ? { name: field.field_key } : field.name,
            selectOpt: [{ value: 'checked', name: this.$translate.instant('custom_view.condition.checked') },{ value: 'not_checked', name: this.$translate.instant('custom_view.condition.not_checked') }]
          }
        };
        if (!field.width) delete col.width;
        break;
      case 'location':
        col = {
          name: field.name,
          displayName: field.is_custom ? field.name : this.$translate.instant(translatePrefix + field.name),
          field: field.is_custom ? field.field_key : field.name,
          field_key: field.field_key,
          pinnedLeft: field.pinnedLeft,
          enableFiltering: true,
          enableCellEdit: false,
          allowCellFocus: false,
          enableColumnMoving: move,
          enableColumnResizing: move,
          enableColumnMenu: false,
          fieldType: field.type,
          width: field.width,
          minWidth: 300,
          filterHeaderTemplate: textHead,
          cellTemplate: '<div class="ui-grid-cell-contents"><location-card location="grid.getCellValue(row, col)" /></div>',
          filter: field.is_custom ? { name: field.field_key } : { name: 'search_by_'+field.name },
        };
        if (!field.width) delete col.width;
        break;
      default: {
        col = {
          name: field.name,
          displayName: field.is_custom ? field.name : this.$translate.instant(translatePrefix + field.name),
          field: field.is_custom ? field.field_key : field.name,
          field_key: field.field_key,
          pinnedLeft: field.pinnedLeft,
          enableFiltering: true,
          enableCellEdit: false,
          allowCellFocus: false,
          enableColumnMoving: move,
          enableColumnResizing: move,
          enableColumnMenu: false,
          fieldType: field.type,
          width: field.width,
          minWidth: 150,
          cellTemplate: '<div class="ui-grid-cell-contents" title="{{grid.getCellValue(row, col)}}">{{ grid.getCellValue(row, col) ? grid.getCellValue(row, col) : "" }}</div>',
          filter: field.is_custom ? { name: field.field_key } : { name: 'search_by_'+field.name },
        };
      }
    }

    if (field.optionOverride) {
      col = Object.assign(col, field.optionOverride);
    }

    col.headerCellClass = this.setSortCellClass(),
    col.cellClass = this.setSortCellClass();
    return col;
  }

  setSortCellClass() {
    return function(grid, row, col) {
      if (col.sort.direction) {
        return 'sort-highlight';
      }
    };
  }

  getMainOptions(moduleClass, fieldName) {
    let fields = this.getColumnByClass(moduleClass);
    let index = fields.main.findIndex(elm => elm.name === fieldName);
    if (index >= 0) {
      if (fields.main[index].type == 'connected_record') {
        return fields.main[index].validations;
      } else {
        return fields.main[index].options;
      }
    } else {
      return [];
    }
  }

  editTableModal(moduleClass, cfModuleClass, translatePrefix, user) {
    return this.Modal.open({
      size: 'lg',
      component: 'tableEditModal',
      resolve: {
        fields: () => this.getColumnByClass(moduleClass),
        objectClass: () => moduleClass,
        cfObjectClass: () => cfModuleClass,
        user: () => user,
        translatePrefix: function () {
          return translatePrefix || cfModuleClass;
        }
      }
    });
  }

  bulkEditModal({ title, recordType, recordCount, filter }) {
    return this.Modal.open({
      size: 'md',
      component: 'bulkEditModal',
      resolve: {
        title: () => title,
        recordType: () => recordType,
        recordCount: () => recordCount,
        filter: () => filter
      }
    });
  }

  resolveColumns(className, translatePrefix, isModal) {
    translatePrefix = translatePrefix || className;
    if (this.$localStorage.settings && this.$localStorage.settings[className] && this.$localStorage.settings[className]['custom_table_fields']) {
      return this.serializeColumns(this.$localStorage.settings[className]['custom_table_fields']['columns'], isModal, translatePrefix, className);
    } else {
      return false;
    }
  }

  gridOptions(isModal, disabledFilter) {
    const filtering = disabledFilter ? false : true;

    return {
      rowHeight: 37,
      saveSort: false,
      saveFilter: false,
      enableSorting: filtering,
      enableGridMenu: false,
      enableFiltering: filtering,
      enablePinning: true,
      enableVerticalScrollbar: true,//this.uiGridConstants.scrollbars.NEVER,
      enableHorizontalScrollbar: true,
      enableFullRowSelection: true,
      columnVirtualizationThreshold: 50,
      enableRowHeaderSelection: true,
      enableRowSelection: true,
      enableSelectAll: true,
      fastWatch: true,
      minimumColumnSize: 40,
      selectionRowHeaderWidth: 38,
      paginationPageSize: 20,
      showGridFooter: false,
      useExternalPagination: true,
      useExternalSorting: filtering,
      useExternalFiltering: filtering,
      enablePaginationControls: false,
      paginationCurrentPage: 1,
      rowTemplate: `<div class="row-uid-{{row.uid}}" ng-class="['ui-grid-customrow', {highlighted: row.entity.preview}]" ng-click="grid.appScope.goTo(row);" >
        <div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid"
            ng-mouseover="grid.appScope.onRowHover(row.uid);"
            ng-mouseout="grid.appScope.onRowOut(row.uid);"
            ui-grid-one-bind-id-grid="rowRenderIndex + '-' + col.uid + '-cell'"
            class="ui-grid-cell"
            style="background-color: red"
            ng-class="{ 'ui-grid-row-header-cell': col.isRowHeader}"
            role="{{col.isRowHeader ? 'rowheader' : 'gridcell'}}"
            ui-grid-cell>
        </div>
    </div>`
    };
  }

  recheckNames(settings, name) {
    let col, techNames = [], update;
    settings.cf_names.forEach(elm => {
      techNames.push(elm.technical_name);
      col = settings.columns.find(el => el.field_key === elm.technical_name && el.name !== elm.title);
      if (col) {
        update = true;
        settings.columns[settings.columns.indexOf(col)] = {
          ...col,
          name: elm.title
        };
      }
    });
    let filtered = settings.columns.filter(elm => !elm.is_custom || techNames.includes(elm.field_key));
    if (filtered.length < settings.columns.length) {
      settings.columns = filtered;
      update = true;
    }
    delete settings.cf_names;
    if (update) this.updateColumns(name, settings); // TODO: check then this is fired
    return {
      update,
      settings
    };
  }

  getCfFillterByCollumn(column) {
    if (!column) return;
    switch (column.fieldType) {
      case 'text':
      case 'text_area':
      case 'email':
      case 'link':
      case 'phone':
        return {
          value: column.filter.term,
          condition: 'have',
          field: column.field,
          type: 'string',
          contains: [],
          is_custom: true,
          custom_field_key: column.field_key,
        };
      case 'location':
      case 'multiselect':
      case 'connected_record':
        return  {
          condition: column.fieldType == 'location' ? 'by_name': 'contains',
          field: column.field,
          type: column.fieldType,
          contains: column.filter.term,
          is_custom: true,
          custom_field_key: column.field_key,
        };
      case 'checkbox':
      case 'file':
        return {
          condition: column.filter.term,
          field: column.field,
          type: column.fieldType,
          is_custom: true,
          custom_field_key: column.field_key,
        };
      case 'integer':
      case 'number':
      case 'date': {
        let range = {
          value_from: column.filters[0].term,
          value_to: column.filters[1].term,
          condition: 'range_between',
          field: column.name,
          type: column.fieldType,
          is_custom: true,
          custom_field_key: column.field_key,
        };
        if (!column.filters[0].term && !column.filters[1].term) return undefined;
        if (!column.filters[0].term) delete range.value_from;
        if (!column.filters[1].term) delete range.value_to;
        return range;
      }
    }
  }

  updateColumns(module, settings, settingsFor = 'user', userId = null) {
    return new Promise((resolve, reject) => {
      const params = { module: module, key: 'custom_table_fields', value: settings };

      if (settingsFor == 'account') {
        settingsFor = this.updateAccountSettings(params);
      } else {
        if (userId) params.user_id = userId;
        settingsFor = this.updateUserSettings(params);
      }

      settingsFor.then(resp => {
        if (userId == null || userId == this.User.currentId()) {
          if (!this.$localStorage.settings) this.$localStorage.settings = {};
          if (!this.$localStorage.settings[module]) this.$localStorage.settings[module] = {};
          this.$localStorage.settings[module]['custom_table_fields'] = settings;
        }

        resolve(resp);
      }, e => reject(e));
    });
  }
}
export default TableEdit;
