import template from './button-select.pug';

export const ButtonSelectComponent = {
  template,
  bindings: {
    model: '<',
    validations: '<?',
    label: '<?',
    options: '<',
    translatePrefix: '@',
    onSelect: '&?',
    objTitle: '@',
    objValue: '@',
  },
  controller: class ButtonSelectComponent {
    constructor() {
      'ngInject';

      this.objTitle = 'title';
      this.objValue = 'value';
    }

    $onChange() {
      this.initDefault();
    }

    $onInit() {
      this.initDefault();
    }

    initDefault() {
      if (!this.model) {
        this.model = this.options[0];
      }
    }

    _isObject() {
      return typeof this.options[0] === 'object';
    }

    edit(option) {
      this.model = option;
      this.onSelect({
        $event: { option }
      });
    }

  }
};
