import template from './download-s3-blob.pug';

export const DownloadS3BlobComponent = {
  template,
  bindings: {
    url: '<',
    fileName: '<'
  },
  transclude: true,
  controller: class DownloadS3BlobComponent {
    constructor(Http, Attachment, $async, $log) {
      'ngInject';

      this.Http = Http;
      this.Attachment = Attachment;
      this.$log = $log;

      this.download = $async(this.download.bind(this));
    }

    $onInit() {}

    async download() {
      this.loading = true;
      try {
        await this.Http.downloadS3Blob(this.url, this.fileName);
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;
      }
    }

  }
};
