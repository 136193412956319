import template from './estate-photos-edit.pug';
import { Sortable } from '@shopify/draggable';

export const EstatePhotosEditComponent = {
  template,
  bindings: {
    estate: '<',
    onCancel: '&?',
    onSave: '&?',
    noCreate: '@?',
    onEdit: '&?',
  },
  controller: class EstatePhotosEditComponent {
    constructor(Upload, Estate, $async, $log, $element, $timeout, $sce, $filter, $scope, AlertBox, Workspace) {
      'ngInject';
      this.Upload = Upload;
      this.Estate = Estate;
      this.Workspace = Workspace;
      this.$log = $log;
      this.$filter = $filter;
      this.$sce = $sce;
      this.$timeout = $timeout;
      this.timer = null;
      this.popperEl = $element.find('#popper');
      this.uploadFiles = $async(this.uploadFiles.bind(this));
      this.deletePhoto = $async(this.deletePhoto.bind(this));
      this.getPhotos = $async(this.getPhotos.bind(this));
      this.removePhotos = $async(this.removePhotos.bind(this));
      this.download = $async(this.download.bind(this));
      this.getCurrentWorkspace = $async(this.getCurrentWorkspace.bind(this));
      $scope.$evalAsync(() => (this.gallery = this.initSortable()));
      this.AlertBox = AlertBox;
      this.showLoader = false;
      this.photos = [];
      this.downloadLoader = false;
      this.currentWorkspace = {};
    }

    $onInit() {
      this.getPhotos();
      this.getCurrentWorkspace();
      this.selectedPhotos = [];
    }

    initSortable() {
      const containers = document.querySelectorAll('.fn-photos');

      if (containers.length === 0) {
        return false;
      }

      const sortable = new Sortable(containers, {
        draggable: '.sortable-item',
        delay: 200,
      });

      sortable.on('sortable:stop', (e) => {
        if (!this.noCreate) {
          let draggableElements = sortable.getDraggableElementsForContainer(containers[0]);
          let positions = {};

          draggableElements.forEach((el, index) => {
            positions[parseInt(el.getAttribute('data-id'))] = index;
          });

          setTimeout(function () {
            const childs = e.data.newContainer.children;
            for (let i = 0; i < childs.length; i++) {
              childs[i].querySelector('.index').textContent = (i + 1).toString();
            }
          }, 250);
          this.Estate.setPositions(this.estate.id, positions);
          this.photos.forEach((photo, index) => {
            this.photos[index] = Object.assign(photo, { position: positions[photo.id] });
          });
          this.save();
        } else {
          this.onEdit({ $event: { estate: this.estate } });
        }
      });

      return sortable;
    }

    preview(photo) {
      this.timer = this.$timeout(() => {
        this.previewImageSrc = photo.large_image_url;
      }, 500);
    }

    previewCancel() {
      this.previewImageSrc = null;
      this.$timeout.cancel(this.timer);
    }

    check(photo) {
      if (this.isChecked(photo)) {
        this.selectedPhotos = this.selectedPhotos.filter((e) => e != photo.id);
      } else {
        this.selectedPhotos.push(photo.id);
      }
    }

    isChecked(photo) {
      return this.selectedPhotos.includes(photo.id);
    }

    checkAll() {
      this.photos.forEach((photo) => {
        this.check(photo);
      });
    }

    async getCurrentWorkspace() {
      try {
        this.currentWorkspace = await this.Workspace.find(this.Workspace.currentWorkspaceId());
      } catch (e) {
        console.error(e);
      }
    }

    async removePhotos() {
      if (!this.selectedPhotos.length) {
        this.AlertBox.done('alert.select_at_least_one_record', { type: 'info' });
        return false;
      }
      this.action_message = 'Deleting photos...';
      let photo_id = this.selectedPhotos.pop();
      let photo = this.photos.filter((p) => p.id == photo_id)[0];
      this.photos = this.photos.filter((p) => p.id != photo_id);
      this.deletePhoto(photo).then(() => {
        if (this.selectedPhotos.length) {
          this.removePhotos();
        } else {
          this.getPhotos();
          this.action_message = false;
        }
      });
      this.save();
    }

    deletePhoto(photo) {
      try {
        return this.Estate.removePhoto(this.estate.id, photo.id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getPhotos() {
      try {
        this.photos = await this.Estate.getPhotos(this.estate.id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async uploadFiles(files) {
      try {
        this.loader = true;
        files.asyncForEach(async (file) => {
          this.action_message = 'Uploading photos...';
          let index = this.photos.push({
            image_url: URL.createObjectURL(file),
            file: file,
          });
          const resp = await this.Upload.upload({
            url: window.apiHost + '/estates/' + this.estate.id + '/photo',
            data: { file },
          })
            .progress(function (evt) {
              file.progress = Math.min(100, parseInt((100.0 * evt.loaded) / evt.total));
            })
            .finally(() => {
              file.progress = 0;
              this.action_message = false;
            });
          this.photos[index - 1] = resp.data;
        });
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
        this.save();
      }
    }

    async download() {
      try {
        let downloadStyle = 'original';

        if (this.currentWorkspace && this.currentWorkspace.watermark_url) {
          const selectedStyle = await this.Estate.photosDownloadStyleModal();

          if (selectedStyle) {
            downloadStyle = selectedStyle;
          } else {
            return;
          }
        }

        this.downloadLoader = true;
        const response = await this.Estate.bulkPhotoDownload(this.estate.id, downloadStyle);
        await this.Http.downloadS3Blob(response.url, response.filename);
      } catch (e) {
        this.$log.error(e);
        this.AlertBox.addMessage('estate.photos.download_error', { type: 'error' });
      } finally {
        this.downloadLoader = false;
      }
    }

    edit({ estate }) {
      this.estate = { ...this.estate, ...estate };
    }

    save() {
      this.onSave({
        $event: { photos: this.photos },
      });
    }
  },
};
