import template from './inquiry-chronology-form.pug';

export const InquiryChronologyFormComponent = {
  template,
  bindings: {
    item: '<',
    onDestroy: '&?'
  },
  controller: class InquiryChronologyFormComponent {
    constructor(Chronology, $async, $log) {
      'ngInject';

      this.Chronology = Chronology;

      this.$log = $log;
      this.destroyRecord = $async(this.destroyRecord.bind(this));
    }

    $onInit() {}

    async destroyRecord() {
      try {
        await this.Chronology.destroyChild(this.item.id);
        this.onDestroy({ $event: { chronologyItem: this.item } });
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
