// base component
import { InquiriesComponent } from './inquiries.component';
// components
import { InquiriesListComponent } from './list/inquiries-list.component';
import { InquiriesShowComponent } from './show/inquiries-show.component';

export const InquiriesModule = angular.module('topbroker.inquiries', [])
  .component('inquiries', InquiriesComponent)
  .component('inquiriesList', InquiriesListComponent)
  .component('inquiriesShow', InquiriesShowComponent)

  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('main.inquiries', {
        url: '/scouts',
        component: 'inquiries',
        abstract: true,
        data: {
          permissions: {
            only: 'can_access_inquiries',
            redirectTo: 'main.dashboard.stats'
          }
        },

      })
      .state('main.inquiries.list', {
        url: '/list/:page?:searchId?:statusId?:estateId?:customFieldId?:destinationId?:destinationType',
        params: {
          page: { dynamic: true },
          searchId: { dynamic: true },
          statusId: { dynamic: true },
          estateId: { dynamic: true },
          customFieldId: { dynamic: true },
          destinationId: { dynamic: true },
          destinationType: { dynamic: true },
        },
        component: 'inquiriesList',
        resolve: {
          filter: ($stateParams) => {
            'ngInject';
            let filter = {};
            filter.page = $stateParams.page;
            return filter;
          },
          estate: ($stateParams, Estate) => {
            'ngInject';
            if (!$stateParams.estateId) return null;
            return Estate.find($stateParams.estateId, {
              fields: {
                estate_show: [
                  'id',
                  'image_url',
                  'estate_type_name',
                  'area',
                  'area_units',
                  'price',
                  'currency',
                  'address'
                ]
              }
            });
          },
          ids: ($stateParams, Estate) => {
            'ngInject';
            if (!$stateParams.estateId) return null;
            return Estate.matchedInquiries($stateParams.estateId);
          },
        }
      })
      .state('main.inquiries.user-list', {
        url: '/uzklausimai/:user_id/:page?:searchId?:statusId',
        params: { page: { dynamic: true }, searchId: { dynamic: true }, statusId: { dynamic: true } },
        component: 'inquiriesList',
        resolve: {
          filter: ($stateParams) => {
            'ngInject';
            let filter = {};
            filter.user_id = $stateParams.user_id;
            filter.page = $stateParams.page;
            return filter;
          }
        }
      })
      .state('main.inquiries.nonexisting', {
        url: '/show/:id/404',
        params: { backTo: 'main.inquiries.list' },
        component: 'notFound',
        resolve: {
          searchId: (Search) => {
            'ngInject';
            return Search.returnLastSearchId('Inquiry');
          },
        }
      })

      .state('main.inquiries.unauthorized', {
        url: '/show/:id/unauthorized',
        component: 'unauthorized',
        params: { backTo: 'main.inquiries.list' },
        resolve: {
          searchId: (Search) => {
            'ngInject';
            return Search.returnLastSearchId('Inquiry');
          },
          recordAttributes: ($stateParams) => {
            'ngInject';
            return { type: 'inquiry', id: $stateParams.id };
          }
        }
      })

      .state('main.inquiries.show', {
        url: '/show/:id?step',
        component: 'inquiriesShow',
        params: {
          step: {
            value: null,
            dynamic: true
          }
        },
        resolve: {
          inquiry: ($stateParams, Inquiry, $state) => {
            'ngInject';
            let response = Inquiry.find($stateParams.id).then( record => {
              return record;
            }, (res) => {
              $state.go('main.contacts.unauthorized', {
                id: $stateParams.id,
                user: null,
              });
            } );
            return response;
          },
        }
      });
  }).name;
