import template from './time-show.pug';

export const TimeShowComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    model: '<',
  },
  controller: class TimeShowComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }

  }
};
