import template from './toolbar.pug';

export const ToolbarComponent = {
  template,
  bindings: {
    title: '@?',
    notFixed: '<?',
    noBackground: '<?'
  },
  transclude : true,
  controller: class ToolbarComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {

    }
  }
};
