class Assignment {
  constructor( Modal ) {
    'ngInject';
    this.Modal = Modal;
  }

  selectModal( { type, alreadySelectedItems = [], multiple, modalSize } ) {
    alreadySelectedItems = alreadySelectedItems || [];
    modalSize = modalSize || 'full';
    multiple = multiple || false;

    return this.Modal.open({
      size: modalSize,
      backdrop: 'static',
      component: 'assignmentModal',
      resolve: {
        alreadySelected: () => alreadySelectedItems,
        multiple: () => multiple,
        type: () => type
      }
    });
  }
}

export default Assignment;
