import template from './attachment-item.pug';

export const AttachmentItemComponent = {
  template,
  bindings: {
    attachment: '<',
    onRemove: '&?'
  },
  controller: class AttachmentItemComponent {
    constructor(Attachment, Http, AlertBox, $async, $log) {
      'ngInject';
      this.Attachment = Attachment;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.Http = Http;
      this.remove = $async(this.remove.bind(this));
      this.download = $async(this.download.bind(this));
    }

    $onInit() {
    }

    attachmentModal() {
      this.Attachment.downloadModal(this.attachment.id);
    }

    resolveExtensionIcon() {
      return this.Attachment.extensionIcon(this.attachment.name);
    }

    isImage() {
      return this.resolveExtensionIcon() == 'image-file';
    }

    removeEnabled() {
      return typeof(this.onRemove) === 'function';
    }

    async remove() {
      const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });

      if (result && result.dismiss) return false;
      this.onRemove({
        $event: { attachment: this.attachment }
      });
    }

    async download() {
      try {
        const response = await this.Attachment.download(this.attachment.id);
        await this.Http.downloadS3Blob(response.url, response.filename);
      } catch (e) {
        this.$log.error(e);
      }
    }

  }
};
