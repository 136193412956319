class EstateMedia {
  constructor(Http, Modal, $sce) {
    'ngInject';
    this.Http = Http;
    this.Modal = Modal;

    this.$sce = $sce;
  }

  getAllByEstate(estate_id) {
    return this.Http.get('/estate_medias/', { estate_id });
  }

  create(params) {
    return this.Http.post('/estate_medias/', params);
  }

  update(params) {
    return this.Http.put(`/estate_medias/${params['id']}`, params);
  }

  delete(id) {
    return this.Http.delete(`/estate_medias/${id}`);
  }


  decodeVideoUrl(url) {
    let videoId = null,
      supplier = '',
      previewUrl = '';

    if (new RegExp('youtube|youtu\.be').test(url)) {
      videoId = url.match(/(?:\/|%3D|v=|vi=)([0-9A-z-_]{11})(?:[%#?&]|$)/)[1];
      supplier = 'youtube';
      previewUrl = `https://www.youtube.com/embed/${videoId}`;
    } else if (new RegExp('vimeo').test(url)) {
      videoId = url.match(/(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/)[4];
      supplier = 'vimeo';
      previewUrl = `https://player.vimeo.com/video/${videoId}`;
    }

    if (supplier == '') return {};

    return { videoId, supplier, previewUrl, type: 'video' };
  }

  decodeTourUrl(url) {
    let supplier = '',
      previewUrl = '';

    if (new RegExp('vipx').test(url)) {
      supplier = 'vipx';
    } else if (new RegExp('matterport').test(url)) {
      supplier = 'matterport';
    } else if (new RegExp('giraffevisual').test(url)) {
      supplier = 'giraffevisual';
    } else if (new RegExp('giraffe360').test(url)) {
      supplier = 'giraffe360';
    } else if (new RegExp('cloudpano').test(url)) {
      supplier = 'cloudpano';
    }

    previewUrl = url;

    if (supplier == '') return {};

    return { supplier, previewUrl, type: 'tour' };
  }
}
export default EstateMedia;
