import template from './estate-show.pug';

export const EstateShowComponent = {
  template,
  bindings: {
    estate: '<',
    onEdit: '&?',
    onCopy: '&?',
    onDelete: '&?',
    onInfo: '&?',
    cfGroups: '<',
  },
  controller: class EstateShowComponent {
    constructor(Estate, AlertBox, Proposal, CustomField, Search, $log, $state, $async, $translate) {
      'ngInject';
      this.Estate = Estate;
      this.AlertBox = AlertBox;
      this.Proposal = Proposal;
      this.CustomField = CustomField;
      this.Search = Search;
      this.$async = $async;
      this.$log = $log;
      this.$state = $state;
      this.$translate = $translate;
      this.openProposal = $async(this.openProposal.bind(this));
      this.loadCustomFields = $async(this.loadCustomFields.bind(this));
      this.makeCopy = $async(this.makeCopy.bind(this));
      this.moveToTrash = $async(this.moveToTrash.bind(this));
      this.update = $async(this.update.bind(this));
    }

    $onChanges() {
    }

    $onInit() {
      this.state = 'show';
      this.loadCustomFields();
    }

    changeState(state) {
      this.state = state;
    }

    async loadCustomFields() {
      try {
        this.cfGroups = await this.CustomField.loadGroups({ module: 'estates' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async makeCopy() {
      try {
        const alert = this.AlertBox.progress('general.please_wait');
        const estate = await this.Estate.copy(this.estate.id);
        alert.close();
        if (estate) {
          this.$state.go('main.estates.show', { id: estate.id, step: 'estate_type' });
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    save(estate, form) {
      if (form.$valid) {
        this.saveCustomFields(estate);
      }
    }

    saveCustomFields(record) {
      const params = {
        custom_fields: record.custom_fields
      };
      this.update(record.id, params);
      this.changeState('show');
    }

    async update(id, params) {
      try {
        if (!id) return;
        const resp = await this.Estate.update(id, params);
        if (resp && resp.id) {
          this.record = resp;
          this.AlertBox.addMessage('alert.updated', { type: 'success' });
          // this.changeFormState('show');
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async moveToTrash() {
      const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });
      if (result && result.dismiss) return false;

      try {
        await this.Estate.moveToTrash(this.estate.id);
        this.AlertBox.addMessage('alert.deleted', { type: 'success' });
        if (this.onDestroy) this.onDestroy({ $event: true });
        let searchId = this.Search.returnLastSearchId('Estate');
        this.$state.go('main.estates.list', { page: 1, searchId });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async openProposal() {
      try {
        await this.Proposal.printModal(this.estate);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async changeRecordStatus({ status, custom_fields }) {
      try {
        const resp = await this.Estate.update(this.estate.id, { record_status_id: status.id, custom_fields });
        if (resp && resp.id) {
          this.estate = resp;
          this.AlertBox.addMessage('alert.updated', { type: 'success' });
          this.$state.reload();
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    change({ step }) {
      if (this.onEdit) {
        this.onEdit({
          $event: { step }
        });
      }
    }
  }
};
