import template from './icon.pug';

import { icons } from 'lucide';

export const IconComponent = {
  template,
  bindings: {
    icon: '<',
    attr: '<?',
  },
  transclude: true,
  controller: class IconComponent {
    constructor($sce) {
      'ngInject';
      this.$sce = $sce;
      this.iconSVG = '';
      this.defaultAttributes = { color: '#333', fill: 'none', width: 14, height: 14 };
      this.attr = {};

    }

    $onChanges({ icon }) {
      if (icon && icon.currentValue) {
        const svgElement = this.createElement(icons[this.toPascalCase(icon.currentValue)], true);
        this.iconSVG = this.$sce.trustAsHtml(svgElement.outerHTML);
      }
    }

    toPascalCase(str) {
      return str.replace(/(\w)([a-z0-9]*)(_|-|\s*)/g, (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase());
    }

    createElement([tag, iconAttrs, children = []], firstLevel = false) {
      const element = document.createElementNS('http://www.w3.org/2000/svg', tag);
      let attributes = iconAttrs;

      if (firstLevel) attributes = { ...attributes, ...this.defaultAttributes, ...this.attr };

      Object.keys(attributes).forEach(name => {
        element.setAttribute(name, attributes[name]);
      });

      if (children.length) {
        children.forEach((child) => {
          const childElement = this.createElement(child);

          element.appendChild(childElement);
        });
      }

      return element;
    }
  },
};
