import template from './layout.pug';

export const LayoutComponent = {
  template,
  bindings: {
    currentUser: '<',
  },
  controller: class LayoutComponent {
    constructor($state, $auth, $transitions, PermRoleStore, PermPermissionStore, ActionCableSocketWrangler) {
      'ngInject';
      this.PermRoleStore = PermRoleStore;
      this.PermPermissionStore = PermPermissionStore;
      this.$state = $state;
      this.$auth = $auth;
      this.ActionCableSocketWrangler = ActionCableSocketWrangler;
      $transitions.onStart({}, () => {
        this.loading = true;
      });

      $transitions.onFinish({}, () => {
        this.loading = false;
      });
    }

    $onInit() {
      this.ActionCableSocketWrangler.start();
      this.openedMenu = true;
    }

    $onDestroy() {
      this.ActionCableSocketWrangler.stop();
    }

    toggleMenu() {
      this.openedMenu = !this.openedMenu;
    }
  },
};
