import template from './multiselect-show.pug';

export const MultiselectShowComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    config: '<?',
    options: '<?',
    model: '<',
    field: '<?',
    translatePrefix: '<?',
  },
  controller: class MultiselectShowComponent {
    constructor() {
      'ngInject';
      this.showItems = [];
      this.model = [];
      this.finalConfig = {
        valueField: 'id',
        labelField: 'title',
      };
    }

    $onInit() {
      if (this.config) {
        this.finalConfig = {
          ...this.finalConfig,
          ...this.config,
        };
      }
    }

    $onChanges({ model, config } = {}) {
      if (config && config.currentValue) {
        this.finalConfig = {
          ...this.finalConfig,
          ...config.currentValue,
        };
      }

      if (model && model.currentValue) {
        this.model = Array.isArray(model.currentValue) ? model.currentValue : [model.currentValue];
        this.model = this.model.filter((el) => {
          return el != null && el != '';
        });
        this.model = this.model.filter((v, i, a) => a.indexOf(v) === i);
      } else {
        this.showItems = [];
      }

      this.setOptions(this.options);
    }

    setOptions(options) {
      if (this.model && options && options.length) {
        this.model.forEach((element, index) => {
          this.showItems[index] = this.option(element, options);
        });
        this.showItems = this.showItems.filter((v, i, a) => a.indexOf(v) === i);
      }
    }

    option(value, options) {
      if (value && options) {
        let optionMatch = options.find((option) => option[this.finalConfig.valueField].toString() === value.toString());
        let label = optionMatch ? { label: optionMatch[this.finalConfig.labelField], ...optionMatch } : { label: value };
        return label;
      }
    }
  },
};
