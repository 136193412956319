import template from './chronology-modal-form.pug';
import snakeCase from 'lodash/snakeCase';

export const ChronologyModalFormComponent = {
  template,
  bindings: {
    resolve: '<',
    modalInstance: '<'
  },
  controller: class ChronologyModalFormComponent {
    constructor($translate) {
      'ngInject';
      this.$translate = $translate;
      this.dataOnClose = false;
    }

    $onInit() {
      if (this.resolve) {
        this.record = this.resolve.record;
        this.selected = this.resolve.selected;
        this.recordClass = this.resolve.recordClass;
        this.emptyItem();
      }
    }

    translateType() {
      return this.$translate.instant('settings.shared.time_panel.' + snakeCase(this.selected));
    }

    emptyItem() {
      this.blank_item = {
        chronology_id: this.record.chronology_id,
        chronology_record: this.record,
      };
    }

    create({ chronologyItem, action }) {
      this.dataOnClose = { chronologyItem, action };
    }

    close() {
      this.modalInstance.close(this.dataOnClose);
    }

    createAndClose({ chronologyItem, action }) {
      this.create({ chronologyItem, action });
      this.close();
    }
  }
};
