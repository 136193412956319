import template from './block-large.pug';

export const BlockLargeComponent = {
  template,
  bindings: {
    heading: '@',
    subtitle: '@?'
  },
  controller: class BlockLargeComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }

  }
};
