import template from './workspace-access-list.pug';

export const WorkspaceAccessListComponent = {
  template,
  bindings: {
    workspaceId: '<?',
    userId: '<?',
  },
  controller: class WorkspaceAccessListComponent {
    constructor($async, $log, Workspace, AlertBox, User) {
      'ngInject';
      this.Workspace = Workspace;
      this.User = User;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.isAllEnabled = false;
      this.enabledRecords = [];
      this.records = [];

      this.getUsers = $async(this.getUsers.bind(this));
      this.getWorkspaces = $async(this.getWorkspaces.bind(this));
      this.getEnabledPermissions = $async(this.getEnabledPermissions.bind(this));
      this.togglePermission = $async(this.togglePermission.bind(this));
    }

    $onInit() {
      this.getEnabledPermissions();

      if (this.workspaceId) {
        this.getUsers();
      } else if (this.userId) {
        this.getWorkspaces();
      }
    }

    async getEnabledPermissions() {
      try {
        const params = this.workspaceId ? { workspace_id: this.workspaceId } : { user_id: this.userId };
        const resp = await this.Workspace.enabledPermissions(params);
        this.enabledRecords = resp || [];
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.checkIsAllEnabled();
      }
    }

    async getUsers() {
      try {
        const resp = await this.User.getList({ fields: { user: ['id', 'name', 'workspace_count'] } });
        this.records = resp || [];
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.checkIsAllEnabled();
      }
    }

    async getWorkspaces() {
      try {
        const resp = await this.Workspace.getGroups(true);
        this.records = resp || [];
      } catch (e) {
        this.$log.error(e);
      }
    }

    checkIsAllEnabled() {
      this.isAllEnabled = (this.enabledRecords.length == this.records.length);
    }

    async toggleAllPermissions(enabled) {
      try {
        let allowedToBeChanged = [];
        if (enabled) {
          this.enabledRecords = this.records.map(record => record.id);
          allowedToBeChanged = [...this.enabledRecords];
        } else {
          this.records.forEach(item => {
            if (item.workspace_count <= 1) {
              allowedToBeChanged.push(item.id);
            }
          });
          this.enabledRecords = this.enabledRecords.filter(item => allowedToBeChanged.includes(item));
        }
        const params = { workspace_id: parseInt(this.workspaceId), user_ids: allowedToBeChanged };

        await this.Workspace.updatePermission(params);
        this.getUsers();
      } catch (e) {
        this.$log.error(e);
      }
    }

    async togglePermission(record, enabled) {
      try {
        if (enabled) {
          this.enabledRecords.push(record.id);
        } else {
          this.enabledRecords = this.enabledRecords.filter(item => item != record.id);
        }
        const params = this.workspaceId ?
          { workspace_id: parseInt(this.workspaceId), user_ids: this.enabledRecords } :
          { user_id: parseInt(this.userId), workspace_ids: this.enabledRecords };

        await this.Workspace.updatePermission(params);

        if (this.workspaceId) {
          this.getUsers();
        }
      } catch (e) {
        this.checkIsAllEnabled();
        this.$log.error(e);
      }
    }

  }
};
