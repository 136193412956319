import template from './tag.pug';

export const TagComponent = {
  template,
  bindings: {
    onRemove: '&?',
    tagDisabled: '<?'
  },
  transclude : true,
  controller: class TagComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {

    }

    remove() {
      this.onRemove({
        $event: {}
      });
    }

  }
};
