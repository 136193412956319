
import { PortalFormComponent } from './form/portal-form.component';
import { PortalEditFormComponent } from './form/edit/portal-edit-form.component';

import { PortalFiltersFormComponent } from './form/filters/portal-filters-form.component';
import { PortalFiltersShowComponent } from './form/filters/show/portal-filters-show.component';
import { PortalFiltersEditComponent } from './form/filters/edit/portal-filters-edit.component';
import { PortalEstateTableComponent } from './table/estate/portal-estate-table.component';

export const PortalSettingModule = angular.module('topbroker.portalSetting', [])
  .component('portalForm', PortalFormComponent)
  .component('portalEditForm', PortalEditFormComponent)
  .component('portalFiltersForm', PortalFiltersFormComponent)
  .component('portalFiltersShowForm', PortalFiltersShowComponent)
  .component('portalFiltersEditForm', PortalFiltersEditComponent)
  .component('portalEstateTable', PortalEstateTableComponent)
  .name;
