
import template from './custom-select.pug';
// http://slimselectjs.com/options
import SlimSelect from 'slim-select';

export const CustomSelectComponent = {
  template,
  bindings: {
    options: '<',
    model: '<',
    label: '@?',
    onChange: '&?',
    labelAttribute: '@?',
    valueAttribute: '@?',
    disabled: '<?',
    addable: '<?',
    openOnInit: '<?',
  },
  controller: class CustomSelectComponent {
    constructor($element, $translate) {
      'ngInject';

      this.$element = $element;
      this.$translate = $translate;
      this.labelAttribute = this.labelAttribute || 'label';
      this.valueAttribute = this.valueAttribute || 'value';
      this.disabledOnChange = false;
      this.openOnInit = false;
    }

    $onInit() {
      this.selectInstance = new SlimSelect(this.generateConfig());

      if (this.openOnInit) {
        this.selectInstance.open();
      }

      this.disabled ? this.selectInstance.disable() : this.selectInstance.enable();
      if (this.options) {
        this.initOptions();
      }
    }

    $onChanges({ options, model, disabled }) {
      if (!this.selectInstance) return false;

      if (options && options.currentValue) {
        this.initOptions();
      } else if (model && this.options && this.options.length > 0) {
        this.disabledOnChange = true;

        this.selectInstance.set(model.currentValue);
      }

      if (disabled) {
        disabled.currentValue ? this.selectInstance.disable() : this.selectInstance.enable();
      }
    }

    initOptions() {
      const items = this.formatOptions(this.options);
      if (items.length > 0) items.unshift({ text: '', value: '', placeholder: true });
      this.selectInstance.setData(items);
    }

    $onDestroy() {
      this.selectInstance.destroy();
    }

    formatOptions(options) {
      return options.map(item => {
        if (item.groupName && this.options) {
          return {
            label: item.groupName,
            options: item.options.map(item => {
              return {
                text: item[this.labelAttribute],
                value: item[this.valueAttribute],
                selected: item[this.valueAttribute] == this.model
              };
            })
          };
        } else {

          return {
            text: item[this.labelAttribute],
            value: item[this.valueAttribute],
            selected: item[this.valueAttribute] == this.model
          };
        }
      });
    }

    geSelectElement() {
      // This hacky line fixes Safari SlimSelect bugfix
      // https://github.com/brianvoe/slim-select/issues/156
      return this.$element.find('select')[0];
    }

    generateConfig() {
      const defaultConfig = {
        select: this.geSelectElement(),
        placeholder: this.$translate.instant('general.select'),
        searchPlaceholder: this.$translate.instant('general.do_search'),
        allowDeselectOption: true,
        onChange: item => {
          if (this.onChange && this.disabledOnChange !== true) {
            this.onChange({
              $event : { [this.labelAttribute]: item.text, [this.valueAttribute]: item.value }
            });
          } else {
            this.disabledOnChange = false;
          }
        }
      };

      if (this.addable) defaultConfig.addable = value => ({ text: value });


      return defaultConfig;
    }
  }
};
