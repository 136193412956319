import template from './portal-edit-form.pug';

export const PortalEditFormComponent = {
  template,
  bindings: {
    portal: '<',
    onEdit: '&?',
    onSave: '&?',
  },
  controller: class PortalEditFormComponent {
    constructor(Portal, AlertBox, $async, $log, $state) {
      'ngInject';
      this.Portal = Portal;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.$state = $state;
      this.editMode = false;
      this.toggle = $async(this.toggle.bind(this));
      this.toggleCollectHits = $async(this.toggleCollectHits.bind(this));
    }

    $onInit() {
    }

    change({ state }) {
      this.onEdit({
        $event: { state }
      });
    }

    async toggleCollectHits() {
      try {
        await this.Portal.update(this.portal.id, { collect_hits: !this.portal.collect_hits });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async toggle() {
      try {
        this.loading = true;
        if (this.portal.on) {
          await this.Portal.off(this.portal.id);
          this.AlertBox.addMessage('alert.updated',{ type: 'success' });
        } else {
          await this.Portal.on(this.portal.id);
          this.AlertBox.addMessage('alert.updated',{ type: 'success' });
        }
        this.portal.on = !this.portal.on;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;
      }
    }

    backToList() {
      this.$state.go('main.settings.portals.list', { type: this.portal.data_format });
    }

    save() {
      this.onSave({ $event: { portal: this.portal } });
    }


  }

};
