import template from './preview.pug';

export const PreviewComponent = {
  template,
  bindings: {
    record: '<',
    objectClass: '@',
    onClose: '&',
    isVisible: '<',
    hotkeysEnabled: '<'
  },
  transclude: {
    header: '?previewHeader',
    body: 'previewBody'
  },
  controller: class PreviewComponent {
    constructor(Hotkeys) {
      'ngInject';
      this.Hotkeys = Hotkeys;
    }

    $onInit() {

      let esc_hotkey = this.Hotkeys.createHotkey({
        key: ['escape'],
        callback: () => {
          this.close();
        }
      });

      if (this.hotkeysEnabled) {
        this.Hotkeys.registerHotkey(esc_hotkey);
      }
    }

    close() {
      this.onClose({ $event: {} });
    }

  }
};
