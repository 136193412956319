import template from './chronology.pug';

export const ChronologyComponent = {
  template,
  bindings: {
    record: '<',
    recordClass: '<',
    reloadRecord: '&?',
  },
  controller: class ChronologyComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}

    reloadForm({ record, action }) {
      if (action) this.listAction = { action };
      if (this.reloadRecord) this.reloadRecord({ $event: { record } });
    }
  }
};
