import template from './popover.pug';

export const PopoverComponent = {
  template,
  bindings: {
    title: '@',
    isOpen: '<',
    autoClose: '<?',
    placement: '@?',
    url: '@',
    loader: '<?',
    openCordinates: '<',
    triggerClose: '<'
  },
  transclude: {
    slotTrigger: '?popTrigger',
    slotTemplate: '?popTemplate'
  },
  controller: class PopoverComponent {
    constructor($scope, $sce) {
      'ngInject';
      this.pop = $sce.trustAsUrl('(pop-' + $scope.$id + ')');
    }

    close() {
      if (!this.autoClose) return;
      this.isOpen = false;
    }

    $onInit() {
      this.placement = this.placement || 'bottom';
    }

    $onChanges(obj) {
      if (this.openCordinates) {
        this.isOpen = true;
        this.customStyle = { 'top': `${this.openCordinates.y}px`, 'left': `${this.openCordinates.x}px`, 'min-width': 'inherit' };
      }
    }
  }
};
