import template from './todo-contact-response-info.pug';

export const TodoContactResponseInfoComponent = {
  template,
  bindings: {
    reminderInfo: '<',
  },
  controller: class TodoContactResponseInfoComponent {
    constructor(TodoContactResponse, $async, $translate) {
      'ngInject';

      this.TodoContactResponse = TodoContactResponse;
      this.$translate = $translate;

      this.respondToReminder = $async(this.respondToReminder.bind(this));
    }

    $onInit() {
      this.duration = humanizeDuration(dayjs(this.reminderInfo.ends_at).diff(this.reminderInfo.starts_at), { language: 'en' });
    }

    changeResponse() {
      this.reminderInfo.response = null;
    }

    googleLocationLink() {
      return 'https://www.google.com/maps/place/' + encodeURI(this.reminderInfo.location);
    }

    async respondToReminder(text) {
      try {
        const response = this.$translate.instant(`general.${text}`);
        await this.TodoContactResponse.addReminderResponse({
          response: response,
          response_comment: this.reminderInfo.response_comment,
        });
        this.reminderInfo.response = response;
      } catch (e) {
        console.error(e);
      }
    }
  },
};
