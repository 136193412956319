import template from './inquiries.pug';

export const InquiriesComponent = {
  template,
  bindings: {},
  controller: class InquiriesComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }
  }
};
