import template from './chronology-estate-price-table.pug';

export const ChronologyEstatePriceTableComponent = {
  template,
  bindings: {
    chronology: '<',
  },
  controller: class ChronologyEstatePriceTableComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}
  }
};
