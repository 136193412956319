import template from './create-button.pug';

export const CreateButtonComponent = {
  template,
  bindings: {
    onClick: '&?',
    loading: '<?',
    btnClass: '<?',
    btnDisabled: '<?'
  },
  controller: class CreateButtonComponent {
    constructor() {
      'ngInject';
    }
    $onInit() {

    }

  }
};
