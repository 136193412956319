import template from './thead.pug';

export const TheadComponent = {
  template,
  bindings: {
    filter: '<',
    onFilter: '&'
  },
  transclude: true,
  controller: class TheadComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}
  }
};
