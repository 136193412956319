class Chronology {

  constructor(Http, Modal) {
    'ngInject';
    this.Http = Http;
    this.Modal = Modal;
  }

  saveChild(chronology_id, params) {
    return this.Http.post('/chronologies/'+chronology_id, params);
  }

  createChild(chronology_id, params = {}) {
    return this.Http.post(`/chronologies/${chronology_id}/create_child`, params);
  }

  destroyChild(child_id) {
    return this.Http.delete('/chronologies/childs/'+child_id);
  }

  commentPreviewModal(record) {
    return this.Modal.open({
      animation: false,
      size: 'sidebar',
      backdrop: true,
      component: 'commentPreviewModal',
      resolve: {
        record: () => record
      }
    });
  }

  openForm({ record, selected, recordClass }){
    let size = 'md';
    return this.Modal.open({
      size: size,
      backdrop: 'static',
      component: 'chronologyModalForm',
      resolve: {
        record: () => record,
        selected: () => selected,
        recordClass: () => recordClass,
      }
    });
  }

  routeRoot(model,id) {
    switch (model) {
      case 'Contact':
        return 'main.contacts.show({id:'+id+'})';
      case 'Estate':
        return 'main.estates.show({id:'+id+'})';
      case 'Inquiry':
        return 'main.inquiries.show({id:'+id+'})';
    }
  }

  getChildTypesNames(settings = [], enabledOnly = true) {
    return settings.reduce((names, setting) => {
      if (enabledOnly) {
        setting.enabled && names.push(setting.name);
      } else {
        names.push(setting.name);
      }

      return names;
    } ,[]);
  }

  getExport(ids) {
    return this.Http.downloadBlob('/chronologies/childs/export', { ids });
  }

  getCurrentUserChronologies(params) {
    return this.Http.get('/statistics/dashboard/chronology_childs', params);
  }

  getCurrentUserChronologiesTypes() {
    return this.Http.get('/statistics/dashboard/chronology_childs_types');
  }

  loadMoreChronologies(class_name, class_id, params, perPage = 10) {
    return this.Http.get('/'+ class_name + '/' + class_id + '/chronology', Object.assign(params, { per_page: perPage }));
  }
}
export default Chronology;
