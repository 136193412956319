import template from './multiradio.pug';

export const MultiradioComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    model: '<',
    options: '<',
    validations: '<?',
    onEdit: '&?',
  },
  controller: class MultiradioComponent {
    constructor() {
      'ngInject';
    }
    $onInit() {
      if(this.model && typeof this.model !== 'boolean' && typeof this.model === 'string'){
        this.model = Boolean(this.model);
      }
    }
    edit(value){
      this.model = value;
      this.onEdit({
        $event: { text: value }
      });
    }
  
  }
};