import template from './dashboards.pug';

export const DashboardsComponent = {
  template,
  bindings: {},
  controller: class DashboardsComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }
  }
};
