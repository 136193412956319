class LocalStorage {
  constructor($localStorage) {
    'ngInject';
    this.localStorage = $localStorage;
    this.localStorage.forms = this.localStorage.forms || {};
    this.localStorage.estate = this.localStorage.estate || {};
    this.localStorage.contact = this.localStorage.contact || {};
    this.localStorage.inquiry = this.localStorage.inquiry || {};
    this.localStorage.gantt = this.localStorage.gantt || {};
    this.localStorage.todo = this.localStorage.todo || {};
    this.localStorage.custom_fields_groups_closed_state = this.localStorage.custom_fields_groups_closed_state || {};
  }

  set(module, key, value) {
    this.localStorage[module][key] = value;
  }

  get(module, key) {
    return this.localStorage[module][key];
  }

  updateForm(object, options) {
    const opt = options || {};
    const name = opt.name || '';
    const path = window.location.pathname.match(/[a-z0-9]+/g).join('');
    if (opt.exclude && opt.exclude.length) {
      opt.exclude.forEach((key) => {
        delete object[key];
      });
    }
    this.localStorage.forms[name + path] = object;
  }

  removeForm(formName) {
    const name = formName || '';
    const path = window.location.pathname.match(/[a-z0-9]+/g).join('');
    delete this.localStorage.forms[name + path];
  }

  getForm(formName) {
    const name = formName || '';
    const path = window.location.pathname.match(/[a-z0-9]+/g).join('');
    return this.localStorage.forms[name + path];
  }
}
export default LocalStorage;
