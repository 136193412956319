
import template from './edit.pug';

export const EditComponent = {
  template,
  bindings: {
  },
  transclude : true,
  controller: class EditComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }

  }
};
