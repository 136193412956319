import template from './records-by-user-chart.pug';

export const RecordsByUserChartComponent = {
  template,
  bindings: {
    modelName: '<',
    filter: '<'
  },
  controller: class RecordsByUserChartComponent {
    constructor($stateParams, $async, $log, ChartService, Workspace) {
      'ngInject';

      this.$stateParams = $stateParams;
      this.$log = $log;
      this.ChartService = ChartService;
      this.Workspace = Workspace;

      this.getChartData = $async(this.getChartData.bind(this));
      this.singleColor = {
        backgroundColor: 'rgba(25, 115, 231, 1)',
        hoverBackgroundColor: 'rgba(25, 115, 231, 0.8)'
      };
      this.chartData = {};

      this.chartOptions = {
        maintainAspectRatio: false,
        indexAxis: 'y',
        plugins: {
          datalabels: {
            display: true,
            color: '#fff',
            textShadowBlur: 5,
            textShadowColor: '#333333',
          }
        }
      }

    }

    $onChanges({ filter } = {}) {
      if (filter && filter.currentValue) {
        this.getChartData();
      }
    }

    async getChartData() {
      try {
        const resp = await this.ChartService.get(this.modelName, 'records_by_user', this.getParams());
        if (resp) {
          this.chartData = {
            labels: Object.keys(resp),
            data: Object.values(resp),
            total_count: Object.values(resp).reduce((result, value) => value + result, 0),
          };
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    getParams() {
      return { ...this.filter, search_id: this.$stateParams.searchId, workspace_id: this.Workspace.currentWorkspaceId() };
    }
  }
};
