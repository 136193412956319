import _find from 'lodash/find';

class Search {
  constructor(Http, Modal, $q, $localStorage) {
    'ngInject';
    this.Http = Http;
    this.Modal = Modal;
    this.$q = $q;
    this.localStorage = $localStorage;
  }

  adjustSearches(module, searches) {
    return this.Modal.open({
      component: 'customViewModal',
      size: 'md',
      resolve: {
        module: () => {
          return module;
        },
        searches: () => {
          return searches;
        }
      }
    });
  }

  global(query = '', moduleName = '') {
    return this.Http.get('/custom_views/global/', { q: query, module_name: moduleName });
  }

  last() {
    return this.localStorage.lastSearches || {};
  }

  performed(result, type) {
    this.localStorage.lastSearches = this.localStorage.lastSearches || {};
    this.localStorage.lastSearches[type] = this.localStorage.lastSearches[type] || { data: [], count: 0 };

    if (!this.localStorage.lastSearches[type].data.includes(result)) {
      this.localStorage.lastSearches[type].data.unshift(result);

      if (this.localStorage.lastSearches[type].data.length > 5) {
        this.localStorage.lastSearches[type].data.splice(-1, 1);
      }
    }

    this.localStorage.lastSearches[type].count = this.localStorage.lastSearches[type].data.length;

    return this.localStorage.lastSearches;
  }

  customSearch(module, portalId = null) {
    const params = { module: module };
    if (portalId) params.account_portal_id = portalId;
    return this.Http.get('/custom_views', params);
  }

  getCustomSearch(id) {
    return this.Http.get('/custom_views' + id);
  }

  searchesHave(searches, name) {
    if (name == 'shared_searches') {
      return _find(searches, function(e) { return e.owner == false;}) ? true : false;
    } else if (name == 'my_searches') {
      return _find(searches, function(e) { return e.owner == true;}) ? true : false;
    }
  }

  addCustomSearch(custom_search) {
    return this.Modal.open({
      component: 'customViewModal',
      size: 'md',
      resolve: {
        custom_search: function() {
          return custom_search;
        }
      }
    });
  }

  saveLastSearchId(search_id, module) {
    this.localStorage.savedSearches = this.localStorage.savedSearches || {};
    this.localStorage.savedSearches[module] = this.localStorage.savedSearches[module] || -1;
    this.localStorage.savedSearches[module] = search_id;
    return search_id;
  }

  returnLastSearchId(module) {
    this.localStorage.savedSearches = this.localStorage.savedSearches || {};
    return parseInt(this.localStorage.savedSearches[module]) || -1;
  }

  saveCustomSearch(params) {
    return this.Http.post('/custom_views', params);
  }

  updateCustomSearch(id, params) {
    return this.Http.put('/custom_views/' + id, params);
  }

  deleteCustomSearch(id) {
    return this.Http.delete('/custom_views/' + id);
  }

}

export default Search;
