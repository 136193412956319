import template from './estate-media-list.pug';

export const EstateMediaList = {
  template,
  bindings:{
    estate: '<',
    onChange: '&?',
  },
  controller: class EstateMediaList {
    constructor(EstateMedia, AlertBox, $async, $log) {
      'ngInject';
      this.EstateMedia = EstateMedia;
      this.AlertBox = AlertBox;

      this.$log = $log;
      this.save = $async(this.save.bind(this));
      this.deleteMedia = $async(this.deleteMedia.bind(this));
      this.getEstateMedias = $async(this.getEstateMedias.bind(this));
      this.estateMedia = false;
    }

    $onInit() {
      this.getEstateMedias();
    }

    addMedia() {
      this.estateMedia = {
        type: 'video',
        url: '',
        embed_url: '',
        supplier: 'supplier_title'
      };
    }

    edit(media) {
      this.estateMedia = media;
    }

    setSupplierAndPreview() {
      const { supplier, previewUrl, type } = {
        ...this.EstateMedia.decodeVideoUrl(this.estateMedia['url']),
        ...this.EstateMedia.decodeTourUrl(this.estateMedia['url'])
      };
      this.estateMedia['type'] = type;
      this.estateMedia['supplier'] = supplier || 'supplier_title';
      this.estateMedia['embed_url'] = previewUrl;
    }

    async save() {
      try {
        if (this.estateMedia && this.estateMedia['id']) {
          await this.EstateMedia.update(this.estateMedia);
          this.AlertBox.addMessage('alert.updated', { type: 'success' });
        } else {
          await this.EstateMedia.create({
            ...this.estateMedia,
            estate_id: this.estate.id
          });
          this.AlertBox.addMessage('alert.created', { type: 'success' });
        }
        this.getEstateMedias();
        this.estateMedia = false;
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getEstateMedias() {
      try {
        this.estateMedias = await this.EstateMedia.getAllByEstate(this.estate.id);
        this.triggerOnChange();
      } catch (e) {
        this.$log.error(e);
      }
    }

    async deleteMedia(id) {
      try {
        const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });
        if (result && result.dismiss) return false;
        await this.EstateMedia.delete(id);
        this.getEstateMedias();
      } catch (e) {
        this.$log.error(e);
      }
    }

    triggerOnChange() {
      if (this.onChange) {
        this.onChange({ $event: { estate_medias:  this.estateMedias } });
      }
    }

  }
};
