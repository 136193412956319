import template from './register.pug';

export const RegisterComponent = {
  template,
  bindings: {},
  controller: class RegisterComponent {
    constructor($state, $auth, User, $log, $async, Account) {
      'ngInject';
      this.$state = $state;
      this.$auth = $auth;
      this.$log = $log;
      this.Account = Account;
      this.User = User;
      this.countryTimezones = [];
      this.authenticate = $async(this.authenticate.bind(this));
      this.register = $async(this.register.bind(this));
      this.registering = false;
      this.registeringSuccess = false;
      this.messages = [];
      this.passwordInputType = 'password';
      this.user = {
        password: '',
        email: '',
        country: '',
        time_zone: '',
        tags: [],
      };

    }

    $onInit() {
      google.accounts.id.initialize({
        client_id: window.GOOGLE_CLIENT_ID,
        callback: this.authenticate,
        context: 'signup'
      });
      setTimeout(() => {
        google.accounts.id.renderButton(
          document.getElementById('gRegisterBtn'),
          { theme: 'outline', size: 'large', text: 'signup_with' }  // customization attributes
        );
      }, 100);
      google.accounts.id.prompt(); // also display the One Tap dialog

    }

    gTag() {
      window.gtag('event', 'conversion', { 'send_to': 'AW-874994757/bhpqCP_h5b0DEMW4naED' });
    }

    async authenticate(google_response) {
      this.gTag();
      try {
        this.loading = true;
        this.registering = true;
        const backend_response = await this.User.googleRegisterByToken(google_response.credential);
        await this.$auth.setToken(backend_response.token);
        this.User.track();
        this.$state.go('main.dashboard.stats',{},{ reload: true });
      } catch (e) {
        this.user.password = '';
        this.messages = ['Invalid email or password'];
      } finally {
        this.loading = false;
      }
    }


    togglePasswordInputType() {
      this.passwordInputType = this.passwordInputType == 'password' ? 'text' : 'password';
    }

    closeMessage(index) {
      this.messages.splice(index, 1);
    }

    validate(error, touched) {
      var valid = Object.keys(Object(error)).length && touched;
      return !!valid;
    }

    async register() {
      try {
        this.registeringSuccess = true;
        const response = await this.Account.register(this.user);
        if (response.error_code == 409) {
          const token = await this.Account.oauthTokens();
          const auth = await this.$auth.login({
            username: this.user.email,
            password: this.user.password,
            grant_type: 'password',
            client_id: token.client_id,
            client_secret: token.client_secret,
          });
          await this.$auth.setToken(auth.data.access_token);
          this.$state.go('main.dashboard.stats',{},{ reload: true });
        }
        this.gTag();
      } catch (e) {
        this.user.password = '';
        this.messages = ['auth.email_used'];
      } finally {
        this.registering = false;
      }
    }
  },
};
