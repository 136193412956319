
import { GenericModule } from './generic/generic.module';

// statefull modules
import { SharedModule } from './shared/shared.module';
import { ContactModule } from './contact/contact.module';
import { EstateModule } from './estate/estate.module';
import { SettingModule } from './setting/setting.module';
import { InquiryModule } from './inquiry/inquiry.module';
import { StatisticModule } from './statistic/statistic.module';
import { UserModule } from './user/user.module';

export const ComponentsModule = angular.module('topbroker.components', [
  GenericModule,
  SharedModule,
  ContactModule,
  EstateModule,
  SettingModule,
  InquiryModule,
  StatisticModule,
  UserModule,
]).name;
