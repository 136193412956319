import template from './user-portfolio.pug';

export const UserPortfolioComponent = {
  template,
  bindings: {
    user: '<'
  },
  controller: class UserPortfolioComponent {
    constructor(User, Search, $window, $async, $log, $state) {
      'ngInject';
      this.User = User;
      this.Search = Search;
      this.$log = $log;
      this.$window = $window;
      this.$state = $state;
      this.getStats = $async(this.getStats.bind(this));
      this.stats = [];
    }

    $onInit() {
      if (this.user) {
        this.getStats();
      }
    }

    getLinkUrl(stat) {
      if (stat.key == 'todos') {
        return this.$state.href('main.todos.list', { page:1, userId: this.user.id });
      } else {
        return this.$state.href('main.'+stat.key+'.user-list', { page:1, user_id: this.user.id, searchId: this.Search.returnLastSearchId(stat.module) });
      }
    }

    isCurrentState(url) {
      return url.includes(this.$window.location.pathname);
    }

    async getStats() {
      try {
        let portfolioStats = await this.User.getPortforlioStats(this.user.id);
        for (let [key, value] of Object.entries(portfolioStats)) {
          let stat = this._enrichStatData(key);
          stat.value = value;
          stat.key = key;
          stat.url = this.getLinkUrl(stat);
          this.stats.push(stat);
        }
      } catch (e) {
        this.$log.log(e);
      }
    }

    _enrichStatData(stat_name) {
      return {
        'estates': { module: 'Estate', icon: 'home', value: 0 },
        'contacts': { module: 'Contact', icon: 'users', value: 0 },
        'inquiries': { module: 'Inquiry', icon: 'crosshair', value: 0 },
        'todos': { module: 'Todo', icon: 'check-circle', value: 0 }
      }[stat_name];
    }

  }
};
