import template from './todo-preview.pug';

export const TodoPreviewModalComponent = {
  template,
  bindings:{
    resolve: '<',
    modalInstance: '<',
  },
  controller: class TodoPreviewModalComponent {
    constructor(Todo, AlertBox, $async, $log, $rootScope) {
      'ngInject';

      this.Todo = Todo;
      this.$log = $log;
      this.$rootScope = $rootScope;
      this.AlertBox = AlertBox;
      this.moveToTrash = $async(this.moveToTrash.bind(this));

      this.formState = 'show';
    }

    $onInit() {
      this.record = this.resolve.record;
    }

    close() {
      this.modalInstance.close(void(0));
    }

    changeFormState(state) {
      if (state == 'edit') {
        this.record = angular.copy(this.record);
      }
      this.formState = state;
    }

    async moveToTrash() {
      try {
        await this.Todo.moveToTrash(this.record.id);
        this.AlertBox.addMessage('alert.deleted', { type: 'success' });
        this.$rootScope.$broadcast('ChronologyList.Reload');
        this.close();
      } catch (e) {
        this.$log.error(e);
      }
    }

    update({ todo }) {
      try {
        if (todo && todo.id) {
          this.record = todo;
          this.AlertBox.addMessage('alert.updated', { type: 'success' });
          this.$rootScope.$broadcast('ChronologyList.Reload');
          this.changeFormState('show');
        }
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
