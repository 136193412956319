class Finance {
  constructor(Http, Modal, Upload, $state, Workspace) {
    'ngInject';
    this.Http = Http;
    this.Modal = Modal;
    this.Workspace = Workspace;
    this.Upload = Upload;
    this.$state = $state;
  }

  multipleDeleteModal(checked_ids) {
    return this.Modal.open({
      size: 'md',
      component: 'bulkRecordDestroyModal',
      resolve: {
        objectIds: function () {
          return checked_ids;
        },
        objectType: () => 'Finance',
      },
    });
  }

  multipleDelete(ids) {
    return this.Http.put('/finances/delete_multiple', { ids: ids });
  }

  getPayments(id) {
    return this.Http.get(`/finances/${id}/payments`);
  }

  createPayment(id, params) {
    return this.Http.post(`/finances/${id}/payments`, params);
  }

  updatePayment(id, params) {
    return this.Http.put(`/finances/${id}/payments/${params.id}`, params);
  }

  deletePayment(id, payment_id) {
    return this.Http.delete(`/finances/${id}/payments/${payment_id}`);
  }

  groupModal(group) {
    return this.Modal.open({
      size: 'md',
      component: 'financeGroupModal',
      resolve: {
        group: () => group
      }
    });
  }

  newModal(record = undefined) {
    return this.Modal.open({
      size: 'md',
      backdrop: 'static',
      component: 'financesNewModal',
      resolve: {
        record: () => record
      }
    });
  }

  previewModal(record) {
    return this.Modal.open({
      animation: true,
      backdrop: true,
      component: 'financesPreviewModal',
      resolve: {
        record: () => record
      }
    });
  }

  find(id) {
    return this.Http.get(`/finances/${id}`);
  }

  cardInfo(id) {
    return this.Http.get(`/finances/${id}/card_info`);
  }

  create(params = {}) {
    return this.Http.post('/finances', params);
  }

  update(id, params) {
    return this.Http.put(`/finances/${id}`, params);
  }

  export(params) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.Http.post('/finances/export', params);
  }

  moveToTrash(id) {
    return this.Http.delete(`/finances/${id}`);
  }

  getListByFilters(params) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.Http.post('/finances/list', params);
  }

  getAttachments(id) {
    if (!id) return [];
    return this.Http.get('/finances/' + id + '/attachments');
  }

  addAttachments(id, attachments) {
    return this.Upload.upload({ url: window.apiHost + '/finances/' + id + '/attachments', data: { attachments } });
  }

  removeAttachments(id, attachment_ids) {
    return this.Http.delete('/finances/' + id + '/attachments/', { attachment_ids });
  }

  getGroup(id) {
    return this.Http.get('/finance_groups/' + id);
  }

  deleteGroup(id) {
    return this.Http.delete('/finance_groups/' + id);
  }

  getGroups(params = {}) {
    return this.Http.get('/finance_groups', params);
  }

  updateGroup(params) {
    return this.Http.put('/finance_groups/' + params.id, {
      name: params.name,
      position: params.position,
      operation_type: params.operation_type
    });
  }

  createGroup(params) {
    return this.Http.post('/finance_groups/', params);
  }

}
export default Finance;
