import template from './bulk-proposal-button.pug';

export const BulkProposalButtonComponent = {
  template,
  bindings: {
    filter: '<',
    checkedRecordsIds: '<',
    totalRecordsCount: '<',
    recordType: '<'
  },
  controller: class BulkProposalButtonComponent {
    constructor(ConnectedRecord, Estate, Contact, Inquiry, AlertBox, Chronology, $log, $translate, $async, $stateParams) {
      'ngInject';

      this.$log = $log;
      this.$stateParams = $stateParams;
      this.$translate = $translate;
      this.ConnectedRecord = ConnectedRecord;
      this.AlertBox = AlertBox;
      this.Chronology = Chronology;
      this.Estate = Estate;
      this.Inquiry = Inquiry;
      this.Contact = Contact;

      this.openProposalModal = $async(this.openProposalModal.bind(this));
    }

    $onChanges({ checkedRecordsIds }) {
      if (checkedRecordsIds) {
        this.checkedRecordsCount = (this.checkedRecordsIds || []).length;
      }
    }

    async openProposalModal(checkType) {
      if (checkType == 'checked' && this.checkedRecordsCount == 0) {
        this.AlertBox.done('alert.select_at_least_one_record', { type: 'info' });
        return true;
      }
      let relatedEstates = [];
      let relatedContacts = [];

      let params = {
        source_type: this.recordType
      };

      if (checkType == 'checked') {
        if (this.recordType == 'inquiry') {
          params.source_ids = this.checkedRecordsIds;
        } else {
          params.ids = this.checkedRecordsIds;
        }
      } else {
        params.source_filter = this.filter;
      }

      if (this.$stateParams.searchId > 0) {
        params.source_filter.search_id = this.$stateParams.searchId;
      }

      if (this.$stateParams.estateId) {
        const estate = await this.Estate.cardInfo(this.$stateParams.estateId);
        relatedEstates.push(estate);
      }

      params.per_page = 100;

      switch(this.recordType) {
        case 'estate':
          let estates = await this.Estate.getList(params);
          relatedEstates = estates;
          break;
        case 'inquiry':
          const inquiry_contacts = await this.ConnectedRecord.getDestinationRecords('contacts', params);
          relatedContacts = inquiry_contacts.items;
          break;
        case 'contact':
          const contacts = await this.Contact.getList(params);
          relatedContacts = contacts;
          break;

      }

      await this.Chronology.openForm({
        record: {
          relatedContacts: relatedContacts,
          relatedEstates: relatedEstates
        },
        selected: 'MultipleProposal',
        recordClass: this.recordType });
    }

  }
};
