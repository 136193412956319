import template from './custom-field-table.pug';

export const CustomFieldTableComponent = {
  template,
  bindings:{
    group: '<',
    onEditPosition: '&',
    afterDeleteGroup: '&'
  },
  controller: class CustomFieldTableComponent {
    constructor(AlertBox, $async, $log, CustomField) {
      'ngInject';
      this.CustomField = CustomField;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.editGroup = $async(this.editGroup.bind(this));
      this.deleteGroup = $async(this.deleteGroup.bind(this));
      this.deleteField = $async(this.deleteField.bind(this));
      this.newField = $async(this.newField.bind(this));
    }

    $onInit() {
    }

    async editGroup() {
      try {
        const resp = await this.CustomField.editGroupModal(this.group);
        this.group = resp;
      } catch (e) {
        this.$log.error(e);
      }
    }

    async newField() {
      try {
        const resp = await this.CustomField.createFieldModal(this.group);
        if (!this.group.custom_fields) {
          this.group.custom_fields = [];
        }
        if (resp) {
          this.group.custom_fields.push(resp);
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async editField({ field }) {
      try {
        const params = {
          group: this.group,
          field: field
        };
        const resp = await this.CustomField.editFieldModal(params);
        if (resp && resp.id) {
          const find = await this.group.custom_fields.find(elm => elm.id === resp.id);
          this.group.custom_fields[this.group.custom_fields.indexOf(find)] = resp;
        }
      } catch (e) {
        this.$log.error(e);
      }
    }


    async deleteGroup({ group }) {
      const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });

      if (result && result.value) {
        try {
          await this.CustomField.deleteGroup(group.id);
          this.afterDeleteGroup({ $event: group });
        } catch (e) {
          this.$log.error(e);
        }
      }
    }

    async deleteField({ field }) {
      const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });

      if (result && result.value) {
        try {
          await this.CustomField.deleteField(field.id);
          this.group.custom_fields = this.group.custom_fields
            .filter(elm => elm !== field);
        } catch (e) {
          this.$log.error(e);
        }
      }
    }
  }
};
