import template from './input-options.pug';

export const InputOptionsComponent = {
  template,
  bindings: {
    label: '@?',
    options: '<',
    validations: '<?',
    onEdit: '&?',
  },
  controller: class InputOptionsComponent {
    constructor() {
      'ngInject';
      this.sortableOptions = {
        handle: '.sortableListHandle',
        tolerance: 'pointer',
        stop: () => {
          this.options.forEach((value, index) => {
            value.position = index + 1;
          });
          this.edit();
        },
        axis: 'y',
      };
    }

    $onInit() {
      this.options = this.options || [];
    }

    addOption() {
      this.options.push({
        title: '',
        settings: { color: '', textColor: '' },
      });
      this.edit();
    }

    editOption(index, text) {
      this.options[index].title = text;
      this.edit();
    }

    removeOption(index) {
      this.options[index]['_destroy'] = 1;
      this.edit();
    }

    onChangeColor(index, $event) {
      this.options[index].settings = { color: $event.color, textColor: $event.textColor };
      this.edit();
    }

    edit() {
      const options = angular.copy(this.options);
      this.onEdit({
        $event: { options },
      });
    }
  },
};
