import template from './finance-show.pug';

export const FinancesShowFormComponent = {
  template,
  bindings: {
    record: '<',
    onEdit: '&',
    onDestroy: '&'
  },
  controller: class FinancesShowFormComponent {
    constructor($log, $async, Finance, CustomField, User, AlertBox) {
      'ngInject';

      this.$log = $log;
      this.Finance = Finance;
      this.CustomField = CustomField;
      this.User = User;
      this.AlertBox = AlertBox;

      this.getAttachments = $async(this.getAttachments.bind(this));
      this.getFinanceGroup = $async(this.getFinanceGroup.bind(this));
      this.loadCustomFields = $async(this.loadCustomFields.bind(this));
      this.getUser = $async(this.getUser.bind(this));
      this.destroy = $async(this.destroy.bind(this));
      this.currency = window.CURRENCY;
    }

    $onChanges({ record }) {
      if (record && record.currentValue !== record.previousValue) {
        this.loadCustomFields();
        this.getAttachments();
        this.getUser();
      }
    }

    $onInit() {
      this.attachments = [];
      this.getFinanceGroup();
      this.loadCustomFields();
      this.getAttachments();
      this.getUser();
    }

    async getAttachments() {
      try {
        this.attachments = await this.Finance.getAttachments(this.record.id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getUser() {
      if (!this.record.user_id) return;
      try {
        this.recordUser = await this.User.cardInfo(this.record.user_id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getFinanceGroup() {
      try {
        const group = await this.Finance.getGroup(this.record.finance_group_id);
        this.financeGroup = [group];
      } catch (e) {
        this.$log.error(e);
      }
    }

    async loadCustomFields() {
      try {
        this.cfGroups = await this.CustomField.loadGroups({ module: 'finances' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    edit() {
      this.onEdit({
        $event: { state: 'edit' }
      });
    }

    async destroy() {
      const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });
      if (result && result.dismiss) return false;
      this.onDestroy();
    }
  }
};
