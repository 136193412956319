import template from './workspace-input-change.pug';

export const WorkspaceInputChangeComponent = {
  template,
  bindings:{
    workspaceId: '<',
    onChange: '&'
  },
  controller: class WorkspaceInputChangeComponent {
    constructor($async, $log, Workspace) {
      'ngInject';

      this.Workspace = Workspace;
      this.$log = $log;

      this.getWorkspaces = $async(this.getWorkspaces.bind(this));
      this.workspace = null;
    }

    $onInit() {
      this.getWorkspaces();
    }

    changed(workspaceId) {
      this.workspaceId = workspaceId;
      this.workspace = this.workspaces.find( w => w.id == workspaceId );
      this.onChange({ $event: workspaceId });
    }

    async getWorkspaces() {
      try {
        this.workspaces = await this.Workspace.getGroups();

        if (this.workspaceId == null || this.workspaceId == undefined) {
          this.workspaceId = this.Workspace.currentWorkspaceId();
        }
        this.changed(this.workspaceId);
      } catch (e) {
        this.$log.error(e);
      }
    }

    close() {
      this.modalInstance.close(void(0));
    }

  }
};
