import template from './todo-contact-response.pug';

export const TodoContactResponseComponent = {
  template,
  bindings: {},
  controller: class TodoContactResponseComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}
  },
};
