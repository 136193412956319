import _forEach from 'lodash/forEach';
import _uniq from 'lodash/uniq';
export default class TbodyActions {
  constructor() {
    'ngInject';
  }

  toggleCheck(row) {
    if (row.isSelected) {
      if (!this.checkedIds.includes(row.entity.id)) {
        this.onCheck({
          $event: { checkedIds: [...this.checkedIds, row.entity.id] }
        });
      } else {
        this.onCheck({
          $event: { checkedIds: [...this.checkedIds] }
        });
      }
    } else {
      this.onCheck({
        $event: { checkedIds: this.checkedIds.filter(e => e !== row.entity.id) }
      });
    }
    this.broadcastToggleCheck(row);
  }

  broadcastToggleCheck(row) {
    if(this.onCheckToggle) {
      this.onCheckToggle({
        $event: { id: row.entity.id, selected: row.isSelected, record: row.entity }
      });
    }
  }


  toggleMultiCheck(rows) {
    let checkedIds = this.checkedIds || [];
    _forEach(rows, (row) => {
      this.broadcastToggleCheck(row);

      if (row.isSelected) {
        checkedIds = [...checkedIds, row.entity.id];
      } else {
        checkedIds = checkedIds.filter(e => e !== row.entity.id);
      }
    });
    this.onCheck({
      $event: { checkedIds: _uniq(checkedIds) }
    });
  }
}
