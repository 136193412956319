import template from './record-status-table.pug';

export const RecordStatusTableComponent = {
  template,
  bindings: {
    module: '<'
  },
  controller: class RecordStatusTableComponent {
    constructor(RecordStatus, $async, $log, AlertBox) {
      'ngInject';
      this.RecordStatus = RecordStatus;
      this.$log = $log;
      this.AlertBox = AlertBox;
      this.updatePosition = $async(this.updatePosition.bind(this));
      this.edit = $async(this.edit.bind(this));
      this.delete= $async(this.delete.bind(this));
      this.fetch = $async(this.fetch.bind(this));
      this.introBlock = false;

      this.sortableOptions = {
        handle: '.sortableListHandle',
        tolerance: 'pointer',
        stop: () => {
          this.groups.forEach((value, index) => {
            value.position = index + 1;
          });
        },
        update: (_e, ui) => {
          const position = ui.item.sortable.dropindex + 1;
          const id = ui.item.sortable.model.id;
          this.updatePosition(id, position);
        },
        axis: 'y'
      };
    }

    $onInit() {
      this.fetch();
    }

    async fetch() {
      try {
        this.loader = true;
        this.record_statuses = await this.RecordStatus.getList(this.module);

        // if no statuses show help block for first time user
        if (!this.record_statuses.length) {
          this.introBlock = true;
        }

      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    async updatePosition(id, position) {
      try {
        await this.RecordStatus.updatePosition(id, position);
        this.AlertBox.addMessage('alert.updated.position', { type: 'success' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async edit(record_status) {
      try {
        await this.RecordStatus.modal({ record_status, recordType: this.module });
        this.fetch();
      } catch (e) {
        this.$log.error(e);
      }
    }

    async delete(record_status) {
      if (record_status.archive) return null;

      const result = await this.AlertBox.confirm('confirmation_messages.delete', { type: 'warning' });

      if (result && result.value) {
        try {
          await this.RecordStatus.delete(record_status.id);
          this.fetch();
        } catch (e) {
          this.$log.error(e);
        }
      }
    }

    async new() {
      try {
        this.loader = true;
        this.introBlock = false;
        const record_status = await this.RecordStatus.modal({ recordType: this.module });
        if (record_status) {
          record_status.position = this.record_statuses.length + 2;
          this.record_statuses.push(record_status);
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }
  }
};
