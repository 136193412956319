import template from './estate-type-group-position-modal.pug';

export const EstateTypeGroupPositionModalComponent = {
  template,
  bindings:{
    resolve: '<',
    modalInstance: '<',
  },
  controller: class EstateTypeGroupPositionModalComponent {
    constructor($async, $log, Estate, AlertBox) {
      'ngInject';
      this.Estate = Estate;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.updatePosition = $async(this.updatePosition.bind(this));
      this.sortableOptions = {
        handle: '.sortableListHandle',
        tolerance: 'pointer',
        stop: () => {
          this.groups.forEach((value, index) => {
            value.position = index + 1;
          });
        },
        update: (e, ui) => {
          const position = ui.item.sortable.dropindex + 1;
          const id = ui.item.sortable.model.id;
          this.updatePosition(id, position);
        },
        axis: 'y'
      };
    }

    $onInit() {
      this.groups = this.resolve.groups;
    }

    async updatePosition(id, position) {
      try {
        this.loader = true;
        await this.Estate.updateEstateTypeGroups(id, { position });
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    close() {
      this.modalInstance.close();
    }

  }
};
