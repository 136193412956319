export class IntlPhone {
  constructor($timeout) {
    'ngInject';
    this.restrict = 'A';
    this.require = '^ngModel';
    this.scope = {
      ngModel: '=',
      defaultCountry: '@'
    },
    this.$timeout = $timeout;
  }

  link($scope, $element, $attrs, $ctrl) {
    var handleWhatsSupposedToBeAnArray, options, read, watchOnce;
    if ($ctrl) {
      if ($element.val() !== '') {
        this.$timeout(() => {
          $element.intlTelInput('setNumber', $element.val());
          return $ctrl.$setViewValue($element.val());
        }, 0);
      }
    }
    read = () => {
      return $ctrl.$setViewValue($element.val());
    };
    handleWhatsSupposedToBeAnArray = (value) => {
      if (value instanceof Array) {
        return value;
      } else {
        return value.toString().replace(/[ ]/g, '').split(',');
      }
    };
    options = {
      initialCountry: window.COUNTRY_CODE,
      autoFormat: true,
      autoHideDialCode: true,
      defaultCountry: '',
      nationalMode: true,
      numberType: '',
      preferredCountries: [],
      responsiveDropdown: false,
      utilsScript: ''
    };
    angular.forEach(options, (value, key) => {
      var option;
      if (!($attrs.hasOwnProperty(key) && angular.isDefined($attrs[key]))) {
        return;
      }
      option = $attrs[key];
      if (key === 'preferredCountries') {
        return options.preferredCountries = handleWhatsSupposedToBeAnArray(option);
      } else if (key === 'onlyCountries') {
        return options.onlyCountries = handleWhatsSupposedToBeAnArray(option);
      } else if (typeof value === 'boolean') {
        return options[key] = option === 'true';
      } else {
        return options[key] = option;
      }
    });
    watchOnce = $scope.$watch('ngModel', (newValue) => {
      return $scope.$$postDigest(() => {
        options.defaultCountry = $scope.defaultCountry;
        if (newValue !== null && newValue !== void 0 && newValue.length > 0) {
          if (newValue[0] !== '+') {
            newValue = '+' + newValue;
          }
          $element.val(newValue);
          $ctrl.$setViewValue($element.val());
        }
        $element.intlTelInput(options);
        // if (!($attrs.skipUtilScriptDownload !== void 0 || options.utilsScript)) {
        //   $element.intlTelInput('loadUtils', '/node_modules/intl-tel-input/build/js/utils.js');
        // }
        return watchOnce();
      });
    });
    $ctrl.$formatters.push((value) => {
      if (!value) {
        return value;
      }
      $element.intlTelInput('setNumber', value);
      return $element.val();
    });
    $ctrl.$parsers.push((value) => {
      if (!value) {
        return value;
      }
      return value.replace(/[^\d]/g, '');
    });
    $ctrl.$validators.internationalPhoneNumber = (value) => {
      var selectedCountry;
      selectedCountry = $element.intlTelInput('getSelectedCountryData');
      if (!value || (selectedCountry && selectedCountry.dialCode === value)) {
        return true;
      }
      return $element.intlTelInput('isValidNumber');
    };
    $element.on('blur keyup change', () => {
      return $scope.$apply(read);
    });
    return $element.on('$destroy', () => {
      $element.intlTelInput('destroy');
      return $element.off('blur keyup change');
    });
  }
}

export const internationalPhoneNumberModule = angular
  .module('internationalPhoneNumber', [])
  .directive('internationalPhoneNumber', ['$timeout', ($timeout) => new IntlPhone($timeout)]);

