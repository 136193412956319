import _map from 'lodash/map';

class Document {
  constructor(Http, Modal, Upload, $state, Workspace) {
    'ngInject';
    this.Http = Http;
    this.Modal = Modal;
    this.Workspace = Workspace;
    this.Upload = Upload;
    this.$state = $state;
  }

  delete(id) {
    return this.Http.delete('/documents/'+id);
  }

  multipleDeleteModal(checked_ids) {
    return this.Modal.open({
      size: 'md',
      component: 'bulkRecordDestroyModal',
      resolve: {
        objectIds: function () {
          return checked_ids;
        },
        objectType: () => 'Document',
      },
    });
  }

  multipleDelete(ids) {
    return this.Http.put('/documents/delete_multiple', { ids: ids });
  }

  create(document) {
    return this.Upload.upload({ url: window.apiHost + '/documents', arrayKey: '[]', data: document });
  }

  update(document) {
    return this.Upload.upload({ url: window.apiHost + `/documents/${document.id}`, arrayKey: '[]', method: 'PUT', data: document });
  }

  find(id) {
    return this.Http.get(`/documents/${id}`);
  }

  cardInfo(id) {
    return this.Http.get(`/documents/${id}/card_info`);
  }

  moveToTrash(id) {
    return this.Http.delete(`/documents/${id}`);
  }

  getList(params) {
    return this.getListBySearchId(0, params).then((records) => {
      return _map(records.data, (el) => { return el.attributes;  });
    });
  }

  getListBySearchId(searchId, params = {}) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.Http.post(`/documents/list/${searchId}`, params);
  }

  getAttachments(params = {}) {
    return this.Http.post('/documents/attachments', params);
  }

  newModal() {
    return this.Modal.open({
      size: 'md',
      backdrop: 'static',
      component: 'documentsNewModal',
    });
  }

  groupModal(group) {
    return this.Modal.open({
      size: 'md',
      component: 'documentGroupModal',
      resolve: {
        group: () => group
      }
    });
  }

  previewModal(record) {
    return this.Modal.open({
      animation: true,
      backdrop: true,
      component: 'documentsPreviewModal',
      resolve: {
        record: () => record
      }
    });
  }

  deleteGroup(id) {
    return this.Http.delete('/document_groups/' + id);
  }

  getGroups(params = {}) {
    return this.Http.get('/document_groups', params.filter);
  }

  updateGroup(params) {
    return this.Http.put('/document_groups/' + params.id, { name: params.name, position: params. position } );
  }

  createGroup(params) {
    return this.Http.post('/document_groups/', params);
  }

}
export default Document;
