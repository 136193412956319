import template from './chronology-finance-table.pug';

export const ChronologyFinanceTableComponent = {
  template,
  bindings: {
    chronology: '<',
  },
  controller: class ChronologyFinanceTableComponent {
    constructor(Finance, $async, $log) {
      'ngInject';

      this.Finance = Finance;
      this.$log = $log;

      this.recordPreview = $async(this.recordPreview.bind(this));
    }

    $onInit() {}

    async recordPreview(selectedRecord) {
      try {
        const record = await this.Finance.find(selectedRecord.id);
        await this.Finance.previewModal(record);
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
