import template from './error-pages.pug';

export const ErrorPagesComponent = {
  template,
  bindings: {
  },
  controller: class ErrorPagesComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }
  }
};
