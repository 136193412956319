import template from './pagination.pug';

export const PaginationComponent = {
  template,
  bindings: {
    totalPages: '<',
    totalCount: '<',
    page: '<',
    perPage: '<',
    afterPageChange: '&',
  },
  controller: class PaginationComponent {
    constructor($stateParams) {
      'ngInject';
      this.$stateParams = $stateParams;
    }

    $onInit() {
      this.perPage = parseInt(this.perPage) || 20;
      this.page = parseInt(this.page);
      this.totalPages = parseInt(this.totalPages);
      this.totalCount = parseInt(this.totalCount);
    }

    $onChanges(changesObj) {
      if (this.needSetPage(changesObj)) {
        this.setPage();
      }
      if (changesObj.page && changesObj.page.currentValue != changesObj.page.previousValue) {
        this.setPage();
      }
    }

    needSetPage(obj) {
      return (obj.totalPages && obj.totalPages.currentValue && obj.totalPages.currentValue != obj.totalPages.previousValue)
      || (obj.totalCount && obj.totalCount.currentValue && obj.totalCount.currentValue != obj.totalCount.previousValue);
    }

    setPage() {
      if (this.page != this.$stateParams.page) {
        this.afterPageChange({
          $event: { page: this.page }
        });
      }
    }

  }
};
