import template from './text.pug';

export const TextComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    placeholder: '@?',
    model: '<',
    validations: '<?',
    onEdit: '&?',
    inputName: '@?',
    autoFocus: '<?',
  },
  transclude: {
    'prepend': '?prepend',
    'append': '?append',
  },

  controller: class TextComponent {
    constructor($transclude) {
      'ngInject';
      this.$transclude = $transclude;
    }

    edit(text) {
      this.onEdit({
        $event: { text }
      });
    }

    isTranscluded() {
      return this.$transclude.isSlotFilled('prepend') || this.$transclude.isSlotFilled('append');
    }

  }
};
