import template from './tbody.pug';

export const FinancesTableTbodyComponent = {
  template,
  bindings: {
    filter: '<',
    records: '<',
    checkedIds: '<',
    gridOptions: '<?',
    onFilterChange: '&',
    isModal: '<?',
    onCheck: '&'
  },
  controller: class FinancesTableTbodyComponent {
    constructor($stateParams, $state, Finance, $async, $log) {
      'ngInject';
      this.Finance = Finance;
      this.$state = $state;
      this.$stateParams = $stateParams;
      this.$log = $log;
      this.isVisible = false;
      this.getRecord = $async(this.getRecord.bind(this));
    }

    $onInit() {
      if (this.$stateParams.previewId) {
        this.getRecord(this.$stateParams.previewId);
      }
    }

    $onChanges({ checkedIds, records } = {}) {
      if (checkedIds && checkedIds.currentValue && checkedIds.currentValue.length == 0) {
        this.records &&
          this.records.forEach(record => {
            record.checked = false;
          });
      }
      if (records && records.currentValue) {
        this.gridOptions.data = records.currentValue.map(x => {
          return (x.attributes = { ...x.attributes, ...x.attributes['custom_fields'] });
        });
      }
    }

    checkIds({ checkedIds }) {
      this.onCheck({ $event: { checkedIds } });
    }

    updateFilter() {
      const params = {
        filter: this.filter,
        searchId: this.$stateParams.searchId
      };
      this.applyFilter(params);
    }

    applyFilter({ filter, searchId }) {
      this.onFilterChange({
        $event: { filter, searchId }
      });
    }

    preview({ row }) {
      this.$state.go('main.finances.list', { previewId: row.entity.id });
      this.getRecord(row.entity.id);
    }

    previewDirection({ id }) {
      this.getRecord(id);
    }

    close() {
      this.$state.go('main.finances.list', { previewId: null });
      this.isVisible = false;
    }

    async getRecord(id) {
      try {
        const record = await this.Finance.find(id);
        this.selectedRecord = record;
        this.isVisible = true;
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
