import template from './auth_token.pug';

export const AuthTokenComponent = {
  template,
  bindings: {},
  controller: class AuthTokenComponent {
    constructor(User, $state, $auth, $stateParams, $async) {
      'ngInject';
      this.$state = $state;
      this.$auth = $auth;
      this.$stateParams = $stateParams;
      this.login = $async(this.login.bind(this));
      this.User = User;
    }

    $onInit() {
      if (this.$stateParams.token) {
        this.login(this.$stateParams.token);
      }
    }

    async login(token) {
      try {
        const res = await this.User.getTokenByToken(token);
        await this.$auth.setToken(res.token);
        this.$state.go('main.dashboard.stats',{},{ reload: true });
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
