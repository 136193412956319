import template from './finance-edit.pug';
import { createPopper } from '@popperjs/core';


export const FinancesEditFormComponent = {
  template,
  bindings: {
    record: '<',
    onSave: '&',
    onCancel: '&?',
    recordClass: '<?',
    loader: '<?'
  },
  controller: class FinancesEditFormComponent {
    constructor(Finance, User, Assignment, CustomField, ConnectedRecord, AlertBox, $log, $translate, $async, $element) {
      'ngInject';

      this.$log = $log;
      this.$translate = $translate;
      this.$element = $element;
      this.Finance = Finance;
      this.User = User;
      this.CustomField = CustomField;
      this.ConnectedRecord = ConnectedRecord;
      this.AlertBox = AlertBox;
      this.recordClass = 'none';
      this.relatedSearchType = false;
      this.Assignment = Assignment;
      this.currency = window.CURRENCY;

      this.save = $async(this.save.bind(this));
      this.loadCustomFields = $async(this.loadCustomFields.bind(this));
      this.getFinanceGroups = $async(this.getFinanceGroups.bind(this));
      this.assignRelated = $async(this.assignRelated.bind(this));
      this.getUsersList = $async(this.getUsersList.bind(this));
      this.financeGroups = { income: [], expenses: [] };
      this.dateOpts = {
        altInput: true,
        altFormat: 'Y-m-d',
        dateFormat: 'Y-m-d',
        placeholder: '',
        defaultDate: dayjs().format('YYYY-MM-DD')
      };

      this.options = {
        showNotes: false,
        showContact: false,
        showEstate: false
      };

      this.attachments = [];

    }

    $onInit() {
      if (this.record) {
        this.dateOpts.defaultDate = this.record.date_at;
        if (!this.record.user_id) this.record.user_id = this.User.currentId();
      } else {
        this.record = {
          date_at: dayjs().format('YYYY-MM-DD'),
          user_id: this.User.currentId(),
          custom_fields: {}
        };
      }

      if (this.record && this.record.id) {
        this.Finance.getAttachments(this.record.id).then( (attachments) => {
          this.attachments = attachments;
        });
      }

      this.getUsersList();
      this.getFinanceGroups();
      this.loadCustomFields();
    }

    removeConnectedRecord(id, type) {
      if (this.record.id) {
        this.ConnectedRecord.deleteByParams({
          source_type: 'Finance',
          source_id: this.record.id,
          destination_type: type,
          destination_id: id
        });
      }
      this.record[type.toLowerCase() + '_ids'] = this.record[type.toLowerCase() + '_ids'].filter( rid => rid !== id  );
    }

    async assignRelated(type) {
      let response = await this.Assignment.selectModal({ type: type, alreadySelectedItems: [], multiple: true });
      if (response && response.items.length) {

        if (this.record[type.toLowerCase() + '_ids'] == undefined) {
          this.record[type.toLowerCase() + '_ids'] = [];
        }

        response.items.forEach( item => {
          this.record[type.toLowerCase() + '_ids'].push(item.id);
        });
      }
      this.closeAssignPopper();
    }

    openAssignPopper(event, id) {
      this.popperRelatedShow = true;
      const popperEl = this.$element.find('#popper-related-' + id)[0];
      let options = {
        placement: 'bottom-start',
        offset: [0, 0]
      };
      if (this.isChronology) options.strategy = 'fixed';
      this.assignPopper = createPopper(event.currentTarget, popperEl, options);
    }

    closeAssignPopper() {
      this.popperRelatedShow = false;
      this.assignPopper.destroy();
    }

    activateNotes() {
      this.options.showNotes = true;
      this.closeAssignPopper();
    }

    changeOperationType(type) {
      this.record.operation_type = type;
      if (this.financeGroups[type].length) {
        this.record.finance_group_id = this.financeGroups[type][0].id;
      }
    }

    async getUsersList() {
      try {
        this.users = await this.User.getList({ fields: { user: ['id', 'name'] } });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getFinanceGroups() {
      try {
        this.financeGroups.income = await this.Finance.getGroups({ operation_type: 'income' });
        this.financeGroups.expenses = await this.Finance.getGroups({ operation_type: 'expenses' });

        if (this.record.finance_group_id == undefined) {
          this.changeOperationType(this.record.operation_type || 'income');
        }

      } catch (e) {
        this.$log.error(e);
      }
    }

    async loadCustomFields() {
      try {
        this.cfGroups = await this.CustomField.loadGroups({ module: 'finances' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async save(form) {
      if (form.$invalid) {
        return this.AlertBox.addMessage('validation_messages.fill_required_fields', { type: 'warning' });
      }
      try {
        let resp = null;
        const newConnectedRecords = angular.copy(this.record['new_connected_records']);

        if (this.record.id) {
          resp = await this.Finance.update(this.record.id, this.record);
        } else {
          resp = await this.Finance.create(this.record);
          if (newConnectedRecords && Object.keys(newConnectedRecords).length > 0) {
            await this.ConnectedRecord.batchCreate(resp.id, newConnectedRecords);
          }
        }

        if (resp && resp.id) {
          let attachmentsToAdd = this.attachments.filter( a =>  (a.id == undefined) );
          if (attachmentsToAdd.length) {
            await this.Finance.addAttachments(resp.id, attachmentsToAdd);
          }

        }

        this.onSave({ $event: { finance: resp } });
      } catch (e) {
        this.$log.error(e);
      }
    }

    uploadAttachments({ attachments }) {
      attachments.forEach( attachment => this.attachments.push(attachment) );
      this.closeAssignPopper();
    }

    removeAttachment(attachment) {
      this.attachments = this.attachments.filter( a => a != attachment );
      if (attachment.id) {
        this.Finance.removeAttachments(this.record.id, [attachment.id]);
      }
    }

    cancel() {
      this.onCancel({
        $event: { state: 'show' }
      });
    }

    $onDestroy() {
      if (this.assignPopper) this.assignPopper.destroy();
    }
  }
};
