import template from './chronology-item.pug';

export const ChronologyItemComponent = {
  template,
  bindings: {
    item: '<',
    onDestroy: '&?',
  },
  controller: class ChronologyItemComponent {
    constructor(Chronology) {
      'ngInject';
      this.Chronology = Chronology;
      this.visible = true;
    }

    $onInit() {
      this.routeRoot = this.Chronology.routeRoot(this.item.chronology_record_type, this.item.chronology_record.id);
    }

    visibleChange({ visible }) {
      this.visible = visible;
    }

    removeItem({ chronologyItem }) {
      this.onDestroy({ $event: { chronologyItem } });
    }

  }
};
