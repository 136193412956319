import template from './record-status-show.pug';

export const RecordStatusShowComponent = {
  template,
  bindings: {
    record: '<', // Paduodame record, nes jei paduoti tik id, ant preview nesitrackina change'ai
    recordStatus: '<?',
    module: '<?',
    onSelect: '&?'
  },
  controller: class RecordStatusShowComponent {
    constructor(RecordStatus, $async, $log, $translate) {
      'ngInject';
      this.RecordStatus = RecordStatus;
      this.$async = $async;
      this.$log = $log;
      this.$translate = $translate;
      this.fetch = $async(this.fetch.bind(this));
      this.countRecordStatuses = $async(this.countRecordStatuses.bind(this));
      this.countStatuses = 0;
    }

    $onChanges() {
      this.$onInit();
    }

    $onInit() {
      if (this.record) {
        this.selectedId = this.record.record_status_id || -1;
      }

      if (this.module) {
        this.countRecordStatuses();
      }
    }

    async countRecordStatuses() {
      try {
        const object = await this.RecordStatus.count(this.module);
        this.countStatuses = object.count;
        if (this.countStatuses > 0) {
          this.fetch();
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async change() {
      if (!this.record) return;

      let status = await this.RecordStatus.changeModal({
        module: this.recordStatus.module,
        selected: this.recordStatus.id
      });

      if (status) {
        let customFields = {};

        if (status.custom_field_ids.length > 0) {
          customFields = await this.RecordStatus.fieldsModal({ recordStatus: status, record: this.record, recordType: this.module });
          if (!customFields) return;
        }

        this.recordStatus = status;
        this.onSelect({
          $event: { status, custom_fields: (customFields || {}) }
        });
      }
    }

    getStyle() {
      return { 'border-color': this.recordStatus.color, 'background-color': this.recordStatus.color, 'color': '#fff' };
    }

    async fetch() {
      try {
        if (this.selectedId > 0) {
          this.recordStatus = await this.RecordStatus.find(this.selectedId);
        } else {
          this.recordStatus = { color: '#235268', module: this.module, title:  this.$translate.instant('record_statuses.not_assigned') };
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

  }
};
