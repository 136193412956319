import template from './statistics-index.pug';

export const StatisticsIndexComponent = {
  template,
  bindings: {},
  controller: class StatisticsIndexComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}
  }
};
