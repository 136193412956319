import template from './block.pug';

export const BlockComponent = {
  template,
  bindings: {
  },
  transclude: true,
  controller: class BlockComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }

  }
};
