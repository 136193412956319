import template from './contact-body.pug';

export const ContactBodyComponent = {
  template,
  bindings: {
    filter: '<',
    records: '<',
    checkedIds: '<',
    onCheck: '&',
    gridOptions: '<?',
    loading: '<?',
    onFilterChange: '&',
    isModal: '<?'
  },
  controller: class ContactBodyComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}

    applyFilter({ filter, searchId }) {
      this.onFilterChange({
        $event: { filter, searchId }
      });
    }

    checkIds({ checkedIds }) {
      this.onCheck({
        $event: { checkedIds }
      });
    }
  }
};
