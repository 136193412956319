import angular from 'angular';
import template from './bulk-edit-button.pug';

export const BulkEditButtonComponent = {
  template,
  bindings: {
    filter: '<',
    checkedRecordsIds: '<',
    totalRecordsCount: '<',
    recordType: '<'
  },
  controller: class BulkEditButtonComponent {
    constructor(TableEdit, AlertBox, $log, $translate, $async, $stateParams) {
      'ngInject';

      this.$log = $log;
      this.$stateParams = $stateParams;
      this.$translate = $translate;
      this.TableEdit = TableEdit;
      this.AlertBox = AlertBox;

      this.editTypeSelected = $async(this.editTypeSelected.bind(this));
    }

    $onChanges({ checkedRecordsIds }) {
      if (checkedRecordsIds) {
        this.checkedRecordsCount = (this.checkedRecordsIds || []).length;
      }
    }

    async editTypeSelected(editType) {
      try {
        if (editType == 'checked' && this.checkedRecordsCount == 0) {
          this.AlertBox.done('alert.select_at_least_one_record', { type: 'info' });
          return true;
        }

        const recordCount = editType == 'all' ? this.totalRecordsCount : this.checkedRecordsCount;
        const titleWithRecordCount = `${this.$translate.instant(`bulk_edit.edit_${editType}`)} ${recordCount}`;
        const filter = editType == 'all' ? angular.copy(this.filter) : { ids: this.checkedRecordsIds };
        const params = {
          title: titleWithRecordCount,
          recordType: this.recordType,
          recordCount: recordCount,
          filter: { ...filter }
        };
        if (this.$stateParams.searchId > 0) {
          params.filter.search_id = this.$stateParams.searchId;
        }
        this.TableEdit.bulkEditModal(params);
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
