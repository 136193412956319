import template from './positions-list.pug';

export const PositionsListComponent = {
  template,
  bindings: {
    fields: '<',
    selectedModel: '<',
    onUpdate: '&',
    objClass: '<',
    updated: '<?',
    translatePrefix: '<',
    onRemoveField: '&'
  },
  controller: class PositionsListComponent {
    constructor() {
      'ngInject';
      this.sortableOptionsList = [this.createOptions('pinnedLeft'), this.createOptions('normal')];
      this.pinnedL = [];
      this.fields = [];
    }

    $onChanges(obj) {
      if (obj.updated && obj.updated.currentValue || obj.fields && obj.fields.currentValue !== obj.fields.previousValue) {
        this.resolveList();
        if (obj.fields.previousValue.length && this.fields.length !== obj.fields.currentValue) {
          if (this.updated == false) {
            this.updated = true;
          } else {
            this.updatePosition();
          }
        }
      }

    }

    $onInit() {}

    resolveList() {
      this.pinnedL = this.fields.filter(elm => elm.pinnedLeft);
      this.fields =  this.fields.filter(elm => !elm.pinnedLeft);
    }

    createOptions(listName) {
      const options = {
        // handle: '.item-block.move-handler',
        tolerance: 'pointer',
        connectWith: '.sort-container',
        receive: (event, ui) => {
          if (!ui.item.sortable.model.table_options) ui.item.sortable.model.table_options = {};
          ui.item.sortable.model.pinnedLeft = (listName === 'pinnedLeft');
        },
        stop: () => {
          this.updatePosition();
        },
        axis: 'y'
      };
      return options;
    }


    updatePosition(updated) {
      let rez = [...this.pinnedL,...this.fields].sort((a, b) => a.position - b.position);
      let params = { $event: {
        columns: rez,
        fields: {
          fields: ['id', ...new Set(rez.map(field => field.is_custom ? 'custom_fields' : field.name))].sort(),
          custom_fields: rez.filter(elm => elm.is_custom).map(field => field.field_key).sort()
        }
      } };
      if (updated) params['$event'].updated = true;
      this.onUpdate(params);
    }

    remove(field, pinned) {
      if (pinned === 'pinnedLeft') {
        this.pinnedL.splice(this.pinnedL.indexOf(field), 1);
      } else {
        this.fields.splice(this.fields.indexOf(field), 1);
      }
      delete this.selectedModel[field.field_key];
      this.onRemoveField({ $event: {
        checkedList: this.selectedModel,
        selected: [...this.pinnedL,...this.fields]
      } });
    }

  }
};
