import template from './estate-header.pug';

export const EstateHeaderComponent = {
  template,
  bindings: {
    records: '<',
    checkedIds: '<',
    totalPages: '<',
    totalCount: '<',
    page: '<',
    afterPageChange: '&',
    onFilterChange: '&',
    isModal: '<?',
    filter: '<?'
  },
  controller: class EstateHeaderComponent {
    constructor(Estate, RecordStatus, AlertBox, Table, $stateParams, $async, $log, $rootScope) {
      'ngInject';
      this.Estate = Estate;
      this.AlertBox = AlertBox;
      this.RecordStatus = RecordStatus;
      this.$log = $log;
      this.$rootScope = $rootScope;
      this.$stateParams = $stateParams;
      this.multipleDelete = $async(this.multipleDelete.bind(this));
      this.countRecordStatuses = $async(this.countRecordStatuses.bind(this));
      this.export = $async(this.export.bind(this));
      this.countStatuses = 0;
      this.loader = false;
      this.actionIsVisible = true;
      this.openCharts = false;
      this.Table = Table;
    }

    $onChanges({ filter } = {}) {
      if (filter && filter.currentValue) {
        Object.keys(this.filter).length > 2 ? this.disabledClearFilter = false : this.disabledClearFilter = true;
      }
    }

    $onInit() {
      this.countRecordStatuses();
      if (this.isModal) {
        this.actionIsVisible = false;
      }
    }

    async countRecordStatuses() {
      try {
        const object = await this.RecordStatus.count('estate');
        this.countStatuses = object.count;
      } catch (e) {
        this.$log.error(e);
      }
    }

    async multipleDelete() {
      if (!this.alertIfNotSelected()) return;
      try {
        const object = await this.Estate.multipleDeleteModal(this.checkedIds);
        if (object) {
          this.applyFilter({ searchId: this.$stateParams.searchId });
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;
      }
    }

    async export() {
      try {
        const response = await this.Estate.export(this.$stateParams.searchId, this.filter);
        if (response) this.AlertBox.addMessage('alert.export_table_success', { type: 'success' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    openAll() {
      if (!this.alertIfNotSelected()) return;
      if (this.checkedIds && this.checkedIds.length > 0) {
        this.checkedIds.forEach((id, i) => {
          window.open('/estates/show/' + id, ''+i);
        });
      }
    }

    applyPage({ page }) {
      this.afterPageChange({
        $event: { page }
      });
    }

    applyFilter({ searchId }) {
      this.onFilterChange({
        $event: { searchId, filter: { ...this.filter, page: 1 } }
      });
    }


    clearFilters() {
      (this.$rootScope.gridApi['estates-table'] || this.$rootScope.gridApi['estates-modal']).grid.clearAllFilters();
    }

    hasActiveFilters() {
      return this.Table.hasActiveFilters('estates-table');
    }

    alertIfNotSelected() {
      if (!this.checkedIds.length) {
        this.AlertBox.done('alert.select_at_least_one_record', { type: 'info' });
        return false;
      }
      return true;
    }

  }
};

