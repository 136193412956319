import template from './estate-type-custom-show.pug';

export const EstateTypeCustomShowComponent = {
  template,
  bindings: {
    estate: '<',
    onChangeState: '&?',
  },
  controller: class EstateTypeCustomShowComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}

    change({ state }) {
      this.onChangeState({
        $event: { state }
      });
    }
  }
};
