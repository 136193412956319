import template from './user-new.pug';

export const UserNewComponent = {
  template,
  bindings: {
    resolve: '<',
    modalInstance: '<',
  },
  controller: class UserNewComponent {
    constructor(User, $async, $log, AlertBox, $stateParams) {
      'ngInject';
      this.User = User;
      this.$async = $async;
      this.$log = $log;
      this.stateParams = $stateParams;
      this.AlertBox = AlertBox;
      this.handleForm = $async(this.handleForm.bind(this));
      this.loader = false;
      this.userCreationType = 'invite';

    }

    $onInit() {
      this.passwordStrength = 0;
      this.roles = [];
      this.resolve.roles.forEach((role) => {
        this.roles.push({ title: role.name, id: role.id });
      });

      this.newUser = {};
      this.inviteUser = {
        email: '',
        role: this.roles[0].id,
      };

      this.tmp = JSON.stringify(this.newUser);
    }

    close() {
      this.modalInstance.close(void(0));
    }

    check() {
      this.stateParams.formDirty = this.tmp !== JSON.stringify(this.newUser);
      this.newUser = angular.copy(this.newUser);
    }

    async handleForm() {
      try {
        let resp = null;

        this.loader = true;
        if (this.userCreationType === 'invite') {
          resp = await this.User.sendInvites([this.inviteUser.email], parseInt(this.inviteUser.role));
        } else if (this.userCreationType === 'fillData') {
          resp = await this.User.createUser(this.newUser);
        }

        if (resp && !resp.error) {
          this.AlertBox.addMessage('alert.created', { type: 'success' });
          this.modalInstance.close(void(0));
        } else {
          this.AlertBox.addMessage((resp.error || 'alert.fail.create'), { type: 'error' });
        }
      } catch (e) {
        this.AlertBox.addMessage(('alert.fail.create'), { type: 'error' });
      } finally {
        this.loader = false;
      }
    }
  }
};
