import template from './table.pug';
import _merge from 'lodash/merge';

export const TodoTableComponent = {
  template,
  bindings: {
    filter: '<',
    onFilterChange: '&',
    onPageChange: '&',
    onCheck: '&?',
    isModal: '<?'
  },
  controller: class TodoTableComponent {
    constructor($async, $log, $stateParams, $localStorage, Todo, TableEdit, Table) {
      'ngInject';

      this.Todo = Todo;
      this.TableEdit = TableEdit;
      this.$async = $async;
      this.$log = $log;
      this.$localStorage = $localStorage;
      this.$stateParams = $stateParams;
      this.Table = Table;
      this.getRecords = $async(this.getRecords.bind(this));

      this.checkedIds = [];
      this.totalPages = 0;
      this.totalCount = 0;
      this.Table.activeFilters.loading = true;
      this.isModal = false;
    }

    $onInit() {
      this.gridOptions = this.TableEdit.gridOptions(this.isModal);
      this.gridOptions.columnDefs = this.TableEdit.resolveColumns('todo', 'todos', this.isModal);
    }

    $onChanges({ filter } = {}) {
      if (filter && filter.currentValue) {
        this.checkedIds = [];
        if (filter.page) this.filter.page = filter.page;
        this.getRecords();
      }
    }

    updatePage({ page }) {
      if (parseInt(page) > 0) {
        this.onPageChange({ $event: { page } });
        this.checkedRecords([]);
      }
    }

    applyFilter({ filter, searchId }) {
      this.onFilterChange({
        $event: { filter: _merge(this.filter, filter), searchId }
      });
    }

    async getRecords() {
      try {
        if (!this.filter) return;
        if (this.Table.activeFilters.loading) return;


        this.loader = true;
        let searchId = this.$stateParams.searchId || -1;
        if (this.isModal) {
          searchId = -1;
        }
        const { data, meta } = await this.Todo.getListBySearchId(searchId, this.filter);
        this.records = data;

        if (meta.cf_names && meta.cf_names.length) {
          let settings = await this.$localStorage.settings['todo']['custom_table_fields'];
          settings.cf_names = meta.cf_names;
          const resp = this.TableEdit.recheckNames(settings, 'todo');
          if (resp.update) {
            this.gridOptions.columnDefs = this.TableEdit.resolveColumns(
              'todo',
              'todos',
              (this.isModal = 'false')
            );
          }
        }
        meta.options.forEach(element => {
          let field = this.gridOptions.columnDefs.find(elm => elm.field_key === element.technical_name);
          if (!field) return;

          if (element.field_type && element.field_type == 'connected_record') {
            field.filter.validations = element.validations;
          }

          if (element.options.length) {
            if (element.technical_name == 'default_user_id') {
              const user_id_field = this.gridOptions.columnDefs.find(elm => elm.field_key === 'default_user_id');
              const todo_users_ids_field = this.gridOptions.columnDefs.find(elm => elm.field_key === 'default_todo_users_ids');
              if (user_id_field) user_id_field.filter.selectOptions = element.options;
              if (todo_users_ids_field) todo_users_ids_field.filter.selectOptions = element.options;
            } else {
              field.filter.selectOptions = element.options;
            }
          }
        });
        this.totalCount = meta.total_count;
        this.totalPages = meta.pages;
        this.reportsInfoBlocks = meta.info_blocks;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    checkedRecords({ checkedIds }) {
      this.checkedIds = checkedIds;
      if (this.onCheck) {
        this.onCheck({
          $event: { checkedIds }
        });
      }
    }
  }
};
