import template from './todo-form.pug';
import { createPopper } from '@popperjs/core';
import uniqBy from 'lodash/uniqBy';

export const TodoFormComponent = {
  template,
  bindings: {
    todo: '<',
    onCreate: '&?',
    onUpdate: '&?',
    onClose: '&?',
    onDestroy: '&?',
    addTime: '<?',
    isChronology: '<?',
    afterUpdateView: '&?',
  },
  controller: class TodoFormComponent {
    constructor(
      Todo,
      Chronology,
      User,
      CustomField,
      Assignment,
      AlertBox,
      $async,
      $sce,
      $log,
      LocalStorage,
      $localStorage,
      $stateParams,
      $element,
      ConnectedRecord
    ) {
      'ngInject';
      this.Todo = Todo;
      this.User = User;
      this.CustomField = CustomField;
      this.Assignment = Assignment;
      this.Chronology = Chronology;
      this.LocalStorage = LocalStorage;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.$stateParams = $stateParams;
      this.$sce = $sce;
      this.ConnectedRecord = ConnectedRecord;
      this.save = $async(this.save.bind(this));
      this.toggleSendInvite = $async(this.toggleSendInvite.bind(this));
      this.postSave = $async(this.postSave.bind(this));
      this.loadTodoGroups = $async(this.loadTodoGroups.bind(this));
      this.close = $async(this.close.bind(this));
      this.$element = $element;
      this.loadRelatedData = $async(this.loadRelatedData.bind(this));
      this.save = $async(this.save.bind(this));
      this.showReminderOptions = true;
      this.todoGroups = $localStorage['todo.groups_list'];
      this.reminderOptions = this.Todo.reminderOptions();
      this.defaultReminder = this.LocalStorage.get('todo', 'reminder') || this.reminderOptions[2];
      this.saveRecordLoader = false;
      this.$onInit = $async(this.$onInit.bind(this));
      this.loadCustomFields = $async(this.loadCustomFields.bind(this));
      this.assignRelated = $async(this.assignRelated.bind(this));

      this.emptyTodo = {
        Reminder: [],
        User: [],
        Estate: [],
        Contact: [],
        Inquiry: [],
        Document: [],
      };
      this.todoToAdd = {
        ...this.emptyTodo,
      };
      this.todoToRemove = {
        ...this.emptyTodo,
      };
      this.todoByType = {
        ...this.emptyTodo,
      };
      this.addTime = false;

      this.inviteContacts = {
        state: 'create',
        show: false,
        contacts: [],
        todo: null,
      };

      this.smartReminderEnabled = false;

      this.loadCustomFields();
    }

    async $onInit() {
      this.getSettings();

      if (!this.todo || (this.todo && !this.todo.id)) {
        this.todoByType = {
          ...this.emptyTodo,
        };
        this.todo = {
          notes: '',
          todo_group_id: null,
          custom_fields: {},
          ...this.todo,
        };

        if (this.todo.starts_at) {
          this.todo.starts_at = dayjs(this.todo.starts_at);
        } else {
          const interval = 30 * 60 * 1000; // 30 minutes round
          this.todo.starts_at = dayjs(new Date(Math.round(new Date() / interval) * interval)).add(1, 'days');
        }

        this.activateTimeSelect();

        if (this.todo.allDay) {
          this.end_time = dayjs(this.todo.starts_at).add(1, 'days');
        }

        if (this.todo.relatedContact) {
          this.todoByType['Contact'] = [this.todo.relatedContact];
        }

        if (this.todo.relatedInquiry) {
          this.todoByType['Inquiry'] = [this.todo.relatedInquiry];
        }

        if (this.todo.relatedEstate) {
          this.todoByType['Estate'] = [this.todo.relatedEstate];
        }

        this.select('Reminder', { items: [this.defaultReminder] });
      } else {
        if (this.todo.starts_at) {
          this.activateTimeSelect();
        }

        await this.loadRelatedData();
        if (this.$stateParams.copy) {
          this.todo.id = null;
          this.todo.notes = '';
        } else {
          this.todo.notes = this.$sce.getTrustedHtml(this.todo.notes);
        }
        this.tmpTodo = angular.copy(this.todo);
      }

      if (this.todo.todo_group_id) {
        this.setCurrentTodoGroup(
          this.todoGroups.find((tg) => tg.id == this.todo.todo_group_id),
          true
        );
      } else {
        this.setCurrentTodoGroup(this.todoGroups[0], true);
      }
      if (this.afterUpdateView) this.afterUpdateView({ $event: {} });
      document.body.style.cursor = 'default';
      this.$stateParams.formDirty = false;
    }

    getSettings() {
      const userSettings = this.User.getUserSettings();
      if (userSettings && userSettings.todo.smart_reminder) {
        this.smartReminderEnabled = userSettings.todo.smart_reminder.enabled;
      }
    }

    openAssignPopper(event, id) {
      this.popperRelatedShow = true;
      const popperEl = this.$element.find('#popper-related-' + id)[0];
      let options = {
        placement: 'bottom-start',
        offset: [0, 0],
      };
      if (this.isChronology) options.strategy = 'fixed';
      this.assignPopper = createPopper(event.currentTarget, popperEl, options);
    }

    closeAssignPopper() {
      this.popperRelatedShow = false;
      this.showReminderOptions = false;
      this.relatedSearchType = null;
      if (this.assignPopper) this.assignPopper.destroy();
    }

    async close() {
      const result = this.$stateParams.formDirty ? await this.AlertBox.confirm('confirmation_messages.close', { type: 'warning' }) : null;
      if (!result || (result && !result.dismiss)) {
        this.onClose({ $event: { todo: this.tmpTodo } });
      }
    }

    async loadCustomFields() {
      try {
        this.cfGroups = await this.CustomField.loadGroups({ module: 'todos' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    changeStartTime() {
      if (dayjs(this.start_time).isAfter(this.end_time) || dayjs(this.start_time).isSame(this.end_time)) {
        this.end_time = dayjs(this.start_time).add(1, 'hours').startOf('hour');
      }
      this.calcDuration();
    }

    changeEndTime() {
      if (dayjs(this.end_time).isBefore(this.start_time) || dayjs(this.end_time).isSame(this.start_time)) {
        this.start_time = dayjs(this.end_time).subtract(1, 'hours');
      }
      this.calcDuration();
    }

    calcDuration() {
      if (this.todo.gantt) {
        this.duration = dayjs(this.start_date).add(this.daysDuration, 'days').format('YYYY-MM-DD');
      } else {
        this.duration = humanizeDuration(dayjs(this.end_time).diff(this.start_time), { language: 'en' });
      }
    }

    checkIsDone() {
      this.isDone = !this.isDone;
      this.todo.completed_at = this.isDone ? dayjs() : null;
    }

    async assignRelated(type) {
      this.closeAssignPopper();
      let modalSize = 'full';

      if (type == 'User') {
        modalSize = 'md';
      }

      const response = await this.Assignment.selectModal({ type: type, multiple: true, modalSize: modalSize, alreadySelectedItems: [] });
      if (response && response.items.length) {
        this.$stateParams.formDirty = true;
        this.select(type, response);
      }
    }

    dontSend(e) {
      if (e.keyCode === 13) e.preventDefault();
    }

    assignReminder({ reminder }) {
      this.todoByType['Reminder'] = [reminder];
    }

    async select(type, itemsObj) {
      if (this.todo.id) {
        this.todoToAdd[type] = [...this.todoToAdd[type], ...itemsObj.items];

        this.todoToRemove[type] = this.todoToRemove[type].filter((e) => {
          if (type !== 'Document') return !itemsObj.ids.includes(e.id);
          return true;
        });
      }
      const uniqByAttr = type === 'Document' ? 'name' : 'id';
      this.todoByType[type] = uniqBy([...new Set([...this.todoByType[type], ...itemsObj.items])], uniqByAttr);

      this.closeAssignPopper();

      Object.keys(this.todoToAdd).some((e) => {
        if (this.todoToAdd[e].length) {
          this.$stateParams.formDirty = true;
          return true;
        }
      });
      if (this.afterUpdateView) this.afterUpdateView({ $event: {} });
    }

    remove(type, item) {
      if (this.todo.id) {
        if (
          this.todoByType[type].some((e) => {
            if (type !== 'Reminder' || type !== 'Document') return e.id === item.id;
            if (type === 'Reminder') return e.unit === item.unit && e.value === e.value;
            if (type === 'Document') return e === item;
          })
        ) {
          this.todoToRemove[type].push(item);
        }
        this.todoToAdd[type] = this.todoToAdd[type].filter((u) => u != item);
      }
      this.todoByType[type] = this.todoByType[type].filter((u) => u != item);

      if (type === 'Reminder') {
        this.reminderOptions = this.Todo.reminderOptions();
        this.reminderOptions = this.reminderOptions.filter((reminder) => !this.todoByType[type].includes(reminder));
      }
    }

    toggleSelectTodoGroup() {
      this.showSelectTodoGroup = !this.showSelectTodoGroup;
    }

    async loadRelatedData() {
      try {
        // TODO: response is backend tos pacios strukturos ar panasios nors....
        this.todoByType['Reminder'] = await this.Todo.getReminders(this.todo.id);
        this.todoByType['Estate'] = await this.Todo.getEstates(this.todo.id);
        this.todoByType['Contact'] = await this.Todo.getContacts(this.todo.id);
        this.todoByType['Inquiry'] = await this.Todo.getInquiries(this.todo.id);
        this.todoByType['User'] = await this.Todo.getUsers(this.todo.id);
        this.todoByType['Document'] = await this.Todo.getAttachments(this.todo.id);
        this.todoByType['Reminder'].forEach((e) => {
          this.reminderOptions = this.reminderOptions.filter((r) => r.unit !== e.unit && r.value !== e.value);
        });
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.todoRelatedOriginal = angular.copy(this.todoByType);
      }
    }

    initAutocomplete() {
      let input = document.getElementById('location-autocomplete');
      this.autocomplete = new google.maps.places.Autocomplete(input);
      this.autocomplete.setComponentRestrictions({ country: window.COUNTRY_CODE });
      this.autoListener = this.autocomplete.addListener('place_changed', () => {
        this.todo.location = this.autocomplete.getPlace().name;
      });
    }

    showInviteContacts() {
      return this.inviteContacts.show && this.inviteContacts.contacts.length && this.smartReminderEnabled;
    }

    activateTimeSelect() {
      this.addTime = true;

      this.start_date = this.todo.starts_at ? dayjs(this.todo.starts_at) : dayjs(new Date());
      if (this.todo.gantt) {
        this.daysDuration = this.todo.ends_at ? dayjs(this.todo.ends_at).diff(dayjs(this.todo.starts_at), 'day') : 1;
      } else {
        this.start_time = this.todo.starts_at ? dayjs(this.todo.starts_at) : dayjs(new Date()).startOf('hour');
        this.end_time = this.todo.ends_at ? dayjs(this.todo.ends_at) : this.start_time.add(1, 'hours');
      }

      this.dateOpts = {
        altInput: true,
        altFormat: 'l, M j',
        dateFormat: 'Y-m-d',
        placeholder: '',
        defaultDate: this.start_date.toDate(),
      };

      this.startTimePickerConfig = {
        minTime: '06:00',
        maxTime: '22:00',
        showDuration: false,
        scrollDefault: 'now',
      };
      this.endTimePickerConfig = this.startTimePickerConfig;
      this.calcDuration();

      if (this.popperRelatedShow) {
        this.closeAssignPopper();
      }
    }

    selectReminder(reminder) {
      this.showReminderOptions = false;
      this.select('Reminder', { items: [reminder] });
    }

    async postSave() {
      try {
        this.inviteContacts.contacts.forEach(async (contact) => {
          if (!contact.send_invite) return;
          await this.Todo.inviteContact(this.inviteContacts.todo.id, contact.id);
        });
        this.inviteContacts.contacts = [];
        this.inviteContacts.show = false;

        if (this.inviteContacts.state == 'create') {
          this.onCreate({ $event: { todo: this.inviteContacts.todo } });
        }

        if (this.inviteContacts.state == 'update') {
          this.onUpdate({ $event: { todo: this.inviteContacts.todo } });
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.saveRecordLoader = false;
        this.todo = {
          relatedContact: this.todo.relatedContact,
          relatedEstate: this.todo.relatedEstate,
          relatedInquiry: this.todo.relatedInquiry,
        };
        this.$onInit();
      }
    }

    async toggleSendInvite(contact_id) {
      try {
        this.inviteContacts.contacts = this.inviteContacts.contacts.map((contact) => {
          if (contact.id === contact_id) {
            contact.invited = !contact.invited;
          }
          return contact;
        });
        await this.Todo.toggleContactInvitation(this.inviteContacts.todo.id, contact_id);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async save(todoForm) {
      if (!todoForm.$valid) {
        return this.AlertBox.addMessage('validation_messages.fill_required_fields', { type: 'warning' });
      }

      try {
        this.saveRecordLoader = true;

        let todo_params = {
          todo_group_id: this.todo_group.id,
          notes: this.$sce.getTrustedHtml(this.todo.notes),
          location: this.todo.location,
          custom_fields: this.todo.custom_fields,
        };

        if (this.todo['connected_record_relations'] && this.todo['connected_record_relations'].length) {
          todo_params['connected_record_relations'] = this.todo['connected_record_relations'];
        }

        if (this.todo.gantt) {
          todo_params['starts_at'] = dayjs(dayjs(this.start_date).format('YYYY-MM-DD')).toISOString(true);
          todo_params['ends_at'] = dayjs(dayjs(this.duration).format('YYYY-MM-DD')).toISOString(true);
          todo_params['gantt'] = true;
          todo_params['parent_id'] = this.todo.parent_id;
          todo_params['calendar_id'] = this.todo.calendar_id;
        } else if (this.addTime) {
          todo_params['starts_at'] = dayjs(dayjs(this.start_date).format('YYYY-MM-DD') + 'T' + dayjs(this.start_time).format('HH:mm:00')).toISOString(true);
          todo_params['ends_at'] = dayjs(dayjs(this.start_date).format('YYYY-MM-DD') + 'T' + dayjs(this.end_time).format('HH:mm:00')).toISOString(true);
        }

        if (this.todo.completed_at) {
          todo_params['completed_at'] = dayjs(this.todo.completed_at).toISOString(true);
        }

        // Update or Create
        if (this.todo.id) {
          const data = await this.Todo.update(this.todo.id, todo_params);
          this.todo = data;
          // add related
          if (this.todoToAdd['Reminder'].length) {
            const existIds = this.todoRelatedOriginal['Reminder'].map((e) => e.id);
            const addReminderArr = this.todoToAdd['Reminder'].filter((e) => existIds.includes(e.id) === false);
            await this.Todo.addReminders(this.todo.id, addReminderArr);
          }

          if (this.todoToAdd['User'].length) {
            const existIds = this.todoRelatedOriginal['User'].map((e) => e.id);
            const addUserArr = this.todoToAdd['User']
              .filter((e) => existIds.includes(e.id) === false)
              .map((e) => {
                return { user_id: e.id };
              });
            await this.Todo.addUsers(this.todo.id, addUserArr);
          }

          if (this.todoToAdd['Estate'].length) {
            const existIds = this.todoRelatedOriginal['Estate'].map((e) => e.id);
            const addEstateArr = this.todoToAdd['Estate']
              .filter((e) => existIds.includes(e.id) === false)
              .map((e) => {
                return { estate_id: e.id };
              });
            await this.Todo.addEstates(this.todo.id, addEstateArr);
          }

          if (this.todoToAdd['Contact'].length) {
            const existIds = this.todoRelatedOriginal['Contact'].map((e) => e.id);
            const addContactsArr = this.todoToAdd['Contact']
              .filter((e) => existIds.includes(e.id) === false)
              .map((e) => {
                return { contact_id: e.id };
              });
            await this.Todo.addContacts(this.todo.id, addContactsArr);

            addContactsArr.forEach((contact) => {
              this.inviteContacts.contacts.push({ id: contact.contact_id, send_invite: true });
            });
          }

          if (this.todoToAdd['Inquiry'].length) {
            const existIds = this.todoRelatedOriginal['Inquiry'].map((e) => e.id);
            const addInquiriesArr = this.todoToAdd['Inquiry']
              .filter((e) => existIds.includes(e.id) === false)
              .map((e) => {
                return { inquiry_id: e.id };
              });
            await this.Todo.addInquiries(this.todo.id, addInquiriesArr);
          }

          if (this.todoToAdd['Document'].length) {
            await this.Todo.addAttachments(this.todo.id, this.todoToAdd['Document']);
          }
          // update reminders
          if (this.todoByType['Reminder'].length) {
            await this.Todo.updateReminders(this.todo.id, this.todoByType['Reminder']);

            const reminder = this.todoByType['Reminder'][0];
            if (dayjs(this.todo.starts_at).isAfter(dayjs()) && reminder.value) {
              this.inviteContacts.state = 'update';
              this.inviteContacts.show = true;
            }
          }

          if (this.todoToRemove['Estate'].length) {
            const existIds = this.todoRelatedOriginal['Estate'].map((e) => e.id);
            const removeEstateIds = this.todoToRemove['Estate'].filter((e) => existIds.includes(e.id) === true).map((e) => e.id);

            await this.Todo.removeEstates(this.todo.id, removeEstateIds);
          }

          if (this.todoToRemove['Contact'].length) {
            const existIds = this.todoRelatedOriginal['Contact'].map((e) => e.id);
            const removeContactIds = this.todoToRemove['Contact'].filter((e) => existIds.includes(e.id) === true).map((e) => e.id);
            if (removeContactIds.length) await this.Todo.removeContacts(this.todo.id, removeContactIds);
          }

          if (this.todoToRemove['Inquiry'].length) {
            const existIds = this.todoRelatedOriginal['Inquiry'].map((e) => e.id);
            const removeInquiryIds = this.todoToRemove['Inquiry'].filter((e) => existIds.includes(e.id) === true).map((e) => e.id);
            if (removeInquiryIds.length) await this.Todo.removeInquiries(this.todo.id, removeInquiryIds);
          }

          if (this.todoToRemove['User'].length) {
            const existIds = this.todoRelatedOriginal['User'].map((e) => e.id);
            const removeUserIds = this.todoToRemove['User'].filter((e) => existIds.includes(e.id) === true).map((e) => e.id);
            await this.Todo.removeUsers(this.todo.id, removeUserIds);
          }
          // TD:
          if (this.todoToRemove['Document'].length) {
            const existIds = this.todoRelatedOriginal['Document'].map((e) => e.id);
            const removeDocumentIds = this.todoToRemove['Document'].filter((e) => existIds.includes(e.id) === true).map((e) => e.id);

            await this.Todo.removeAttachments(this.todo.id, removeDocumentIds);
          }

          if (!this.showInviteContacts()) {
            this.onUpdate({ $event: { todo: this.todo } });
          } else {
            this.inviteContacts.todo = this.todo;
          }
        } else {
          const newConnectedRecords = angular.copy(this.todo['new_connected_records']);
          const todo = await this.Todo.create(todo_params);

          if (todo && newConnectedRecords && Object.keys(newConnectedRecords).length > 0) {
            await this.ConnectedRecord.batchCreate(todo.id, newConnectedRecords);
          }

          if (this.todoByType['Reminder'].length) {
            await this.Todo.addReminders(todo.id, this.todoByType['Reminder']);
            const reminder = this.todoByType['Reminder'][0];

            if (dayjs(todo.starts_at).isAfter(dayjs()) && reminder.value) {
              this.inviteContacts.state = 'create';
              this.inviteContacts.show = true;
            }
          }

          if (this.todoByType['Estate'].length) {
            await this.Todo.addEstates(
              todo.id,
              this.todoByType['Estate'].map((e) => {
                return { estate_id: e.id };
              })
            );
          }

          if (this.todoByType['Contact'].length) {
            await this.Todo.addContacts(
              todo.id,
              this.todoByType['Contact'].map((e) => {
                return { contact_id: e.id };
              })
            );
            this.todoByType['Contact'].forEach((contact) => {
              if (contact.email || contact.phone) {
                this.inviteContacts.contacts.push({
                  id: contact.id,
                  send_invite: true,
                });
              }
            });
          }

          if (this.todoByType['Inquiry'].length) {
            await this.Todo.addInquiries(
              todo.id,
              this.todoByType['Inquiry'].map((e) => {
                return { inquiry_id: e.id };
              })
            );
          }

          if (this.todoByType['User'].length) {
            await this.Todo.addUsers(
              todo.id,
              this.todoByType['User'].map((e) => {
                return { user_id: e.id };
              })
            );
          }

          if (this.todoByType['Document'].length) {
            await this.Todo.addAttachments(todo.id, this.todoByType['Document']);
          }
          this.$stateParams.formDirty = false;

          if (!this.showInviteContacts()) {
            this.onCreate({ $event: { todo } });
          } else {
            this.inviteContacts.todo = todo;
          }
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.saveRecordLoader = false;
        this.todo = {
          relatedContact: this.todo.relatedContact,
          relatedEstate: this.todo.relatedEstate,
          relatedInquiry: this.todo.relatedInquiry,
        };
        this.$onInit();
      }
    }

    async loadTodoGroups() {
      try {
        this.todoGroups = await this.Todo.getGroups();
      } catch (e) {
        this.$log.error(e);
      } finally {
        if (this.afterLoaded) this.afterLoaded({ $event: {} });
      }
    }

    setCurrentTodoGroup(todo_group, firstLoad) {
      this.todo_group = todo_group;
      if (!firstLoad) {
        this.toggleSelectTodoGroup();
      }
    }

    $onDestroy() {
      // alert('die-edit');
      if (this.autoListener) {
        google.maps.event.removeListener(this.autoListener);
        google.maps.event.clearInstanceListeners(this.autocomplete);
      }
      this.$stateParams.formDirty = false;
      if (this.assignPopper) this.assignPopper.destroy();
    }
  },
};
