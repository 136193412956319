class Proposal {

  constructor(Http, Modal, Upload) {
    'ngInject';
    this.Http = Http;
    this.Modal = Modal;
    this.Upload = Upload;
  }

  update(id, params) {
    return this.Http.put('/proposals/' + id, params);
  }

  findOrCreate(params) {
    return this.Http.post('/proposals/single', params);
  }

  createBlankRecord(params) {
    return this.Http.post('/proposals', params);
  }

  generatePdf(token) {
    return this.Http.get('/proposals/pdf/' + token);
  }

  getReceivers(id) {
    return this.Http.get('/proposals/' + id + '/receivers');
  }

  addReceivers(id, params = {}) {
    return this.Http.post('/proposals/' + id + '/receivers', params);
  }

  getProperties(proposal_id) {
    return this.Http.get('/proposals/' + proposal_id + '/properties');
  }

  findByPropertyId(proposal_id, property_id) {
    return this.Http.get('/proposals/' + proposal_id + '/properties/' + property_id);
  }

  previewModal(record) {
    return this.Modal.open({
      animation: false,
      size: 'sidebar',
      backdrop: true,
      component: 'proposalModalPreview',
      resolve: {
        record: () => record
      }
    });
  }

  addProperty(property_id, proposal_id) {
    return this.Http.post('/proposals/' + proposal_id + '/properties/' + property_id);
  }

  removeProperty(property_id, proposal_id) {
    return this.Http.delete('/proposals/' + proposal_id + '/properties/' + property_id);
  }

  addContacts(id, contact_ids) {
    return this.Http.put('/proposals/' + id + '/contacts', { contact_ids });
  }

  removeContacts(id, contact_ids) {
    return this.Http.delete('/proposals/' + id + '/contacts/', { contact_ids });
  }

  getContacts(id) {
    if (!id) return [];
    return this.Http.get('/proposals/' + id + '/contacts');
  }

  getAttachments(id) {
    if (!id) return [];
    return this.Http.get('/proposals/' + id + '/attachments');
  }

  addAttachments(id, attachments) {
    return this.Upload.upload({
      url: window.apiHost + '/proposals/' + id + '/attachments',
      data: { attachments } });
  }

  removeAttachments(id, attachment_ids) {
    return this.Http.delete('/proposals/' + id + '/attachments/', { attachment_ids });
  }

  findByToken(token) {
    return this.Http.get('/proposals/' + token);
  }

  printModal(estate) {
    return this.Modal.open({
      animation: true,
      size: 'print',
      backdrop: true,
      component: 'estateProposalPrintModal',
      resolve: {
        estate: () => estate
      }
    });
  }


}
export default Proposal;
