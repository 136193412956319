// base component
import { InputComponent } from './input.component';

// components
import { DropdownComponent } from './dropdown/dropdown.component';
import { ColorDropdownComponent } from './dropdown/color/color-dropdown.component';

import { TextComponent } from './text/text.component';
import { InputPasswordComponent } from './password/input-password.component';
import { InputOptionsComponent } from './options/input-options.component';
import { TextShowComponent } from './text/show/text-show.component';
import { TextareaComponent } from './textarea/textarea.component';

import { TextareaShowComponent } from './textarea/show/textarea-show.component';

import { LinkComponent } from './link/link.component';
import { LinkShowComponent } from './link/show/link-show.component';

import { EmailComponent } from './email/email.component';
import { EmailShowComponent } from './email/show/email-show.component';

import { PhoneComponent } from './phone/phone.component';
import { PhoneShowComponent } from './phone/show/phone-show.component';

import { NumberComponent } from './number/number.component';
import { NumberShowComponent } from './number/show/number-show.component';
import { PriceNumberComponent } from './number/price/price-number.component';
import { PriceNumberShowComponent } from './number/price/show/price-number-show.component';

import { MoneyComponent } from './money/money-number.component';
import { MoneyNumberShowComponent } from './money/show/money-number-show.component';

import { DateComponent } from './date/date.component';
import { DateShowComponent } from './date/show/date-show.component';

import { CheckBoxComponent } from './checkbox/checkbox.component';
import { CheckBoxShowComponent } from './checkbox/show/checkbox-show.component';

import { MultiradioComponent } from './multiradio/multiradio.component';
import { RadioComponent } from './radio/radio.component';
import { RadioShowComponent } from './radio/show/radio-show.component';

import { TimeComponent } from './time/time.component';
import { TimeShowComponent } from './time/show/time-show.component';

import { ButtonSelectComponent } from './button-select/button-select.component';
import { ButtonSelectShowComponent } from './button-select/show/button-select-show.component';

import { SearchComponent } from './search/search.component';

import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';

import './selectize.js';
import './international-phone-number.js';

import { MultiselectComponent } from './multiselect/multiselect.component';
import { MultiselectShowComponent } from './multiselect/show/multiselect-show.component';
import { LocationGoogleComponent } from './location-google/location-google.component';
import { LocationGoogleShowComponent } from './location-google/show/location-google-show.component';

import { LocationGeopifyComponent } from './location-geopify/location-geopify.component';



import { IconLinkComponent } from './icon-link/icon-link.component';

export const InputModule = angular
  .module('topbroker.inputs', [])
  .component('dropdown', DropdownComponent)
  .component('colorDropdown', ColorDropdownComponent)

  .component('fnInput', InputComponent)
  .component('inputPassword', InputPasswordComponent)
  .component('inputOptions', InputOptionsComponent)

  .component('inputText', TextComponent)
  .component('inputSearch', SearchComponent)
  .component('inputTextShow', TextShowComponent)
  .component('inputTextarea', TextareaComponent)
  .component('inputTextareaShow', TextareaShowComponent)
  .component('inputLink', LinkComponent)
  .component('inputLinkShow', LinkShowComponent)
  .component('inputEmail', EmailComponent)
  .component('inputEmailShow', EmailShowComponent)
  .component('inputPhone', PhoneComponent)
  .component('inputPhoneShow', PhoneShowComponent)
  .component('inputNumber', NumberComponent)
  .component('inputNumberShow', NumberShowComponent)
  .component('inputPriceNumber', PriceNumberComponent)
  .component('inputPriceNumberShow', PriceNumberShowComponent)
  .component('inputMoney', MoneyComponent)
  .component('inputMoneyShow', MoneyNumberShowComponent)
  .component('inputDate', DateComponent)
  .component('inputDateShow', DateShowComponent)

  .component('inputTime', TimeComponent)
  .component('inputTimeShow', TimeShowComponent)
  .component('inputCheckbox', CheckBoxComponent)
  .component('inputCheckboxShow', CheckBoxShowComponent)
  .component('inputMultiradio', MultiradioComponent)
  .component('inputRadio', RadioComponent)
  .component('inputRadioShow', RadioShowComponent)
  .component('inputMultiselect', MultiselectComponent)

  .component('inputMultiselectShow', MultiselectShowComponent)
  .component('inputLocationGoogle', LocationGoogleComponent)
  .component('inputLocationGoogleShow', LocationGoogleShowComponent)
  .component('inputLocationGeopify', LocationGeopifyComponent)
  .component('inputButtonSelect', ButtonSelectComponent)
  .component('inputButtonSelectShow', ButtonSelectShowComponent)
  .component('inputToggleSwitch', ToggleSwitchComponent)
  .component('iconLink', IconLinkComponent)
  .directive('focusOnCondition', [
    '$timeout',
    function($timeout) {
      var checkDirectivePrerequisites = function(attrs) {
        if (!attrs.focusOnCondition && attrs.focusOnCondition != '') {
          throw 'FocusOnCondition missing attribute to evaluate';
        }
      };

      return {
        restrict: 'A',
        link: function(scope, element, attrs, ctrls) {
          checkDirectivePrerequisites(attrs);

          scope.$watch(attrs.focusOnCondition, function(currentValue, lastValue) {
            if (currentValue == true) {
              $timeout(function() {
                element.focus();
              });
            }
          });
        }
      };
    }
  ]).name;
