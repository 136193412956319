import { plural } from 'pluralize';
import template from './bulk-edit-modal.pug';

export const BulkEditModalComponent = {
  template,
  bindings: {
    resolve: '<',
    modalInstance: '<',
  },
  controller: class BulkEditModalComponent {
    constructor(RecordStatus, Workspace, Contact, Estate, Inquiry, User, CustomField, ActionCableChannel,  $async, $log) {
      'ngInject';

      this.$log = $log;
      this.RecordStatus = RecordStatus;
      this.Workspace = Workspace;
      this.Contact = Contact;
      this.Estate = Estate;
      this.Inquiry = Inquiry;
      this.User = User;
      this.CustomField = CustomField;
      this.updateRecords = $async(this.updateRecords.bind(this));
      this.setStepAndField = $async(this.setStepAndField.bind(this));
      this.bulkEditPriceChangeDryRun = $async(this.bulkEditPriceChangeDryRun.bind(this));
      this.loadData = $async(this.loadData.bind(this));
      this.reportChannel = new ActionCableChannel('BulkEditChannel');
    }

    $onInit() {
      this.step = 'FieldSelection';
      this.selectedField = '';
      this.fieldToUpdate = {};
      this.bulkEditReport = { progress: 0 };
      this.title = this.resolve.title;
      this.recordType = this.resolve.recordType;
      this.filter = this.resolve.filter;
      this.recordCount = this.resolve.recordCount;
      this.results = {};

      this.reportChannel.subscribe(report => {
        this.bulkEditReport = report;
        if (report.status == 'running' && report.record_type.toLowerCase() == this.recordType) {
          this.step = 'Progress';
        } else if (report.status == 'finished') {
          this.step = 'Complete';
        }

        if (Object.keys(this.fieldToUpdate).length == 0 && Object.keys(report.fields_to_update).length > 0) {
          this.fieldToUpdate = report.fields_to_update;
          this.selectedField = Object.keys(report.fields_to_update)[0];
        }
      });

      this.loadData();
    }

    $onDestroy() {
      this.reportChannel.unsubscribe();
    }

    close() {
      this.modalInstance.close(void(0));
    }

    listOfRecordStatus() {
      if (this.recordStatusList) {
        return this.recordStatusList.map( r => {  return r.title; }).join(', ');
      }
    }

    listWorkspaceList() {
      if (this.workspaceList) {
        return this.workspaceList.map( r => {  return r.title; }).join(', ');
      }
    }

    listUserList() {
      if (this.userList) {
        const users = this.userList.slice(0,3).map( r => {  return r.name; });
        return users.join(', ') + ', ...';
      }
    }

    listCustomFields() {
      if (this.customFields) {
        const fields = this.customFields.slice(0,3).map( r => {  return r.title; });
        return fields.join(', ') + ', ...';
      }
    }

    get isCustomFieldSelected() {
      return this.selectedField.includes('c_f_');
    }

    goBack() {
      if (this.step == 'Confirm') {
        this.step = 'FieldToUpdate';
      } else if (this.step == 'FieldToUpdate' && this.isCustomFieldSelected) {
        this.step = 'CustomFieldSelection';
        this.fieldToUpdate = {};
      } else if (this.step == 'CustomFieldSelection' || this.step == 'FieldToUpdate') {
        this.step = 'FieldSelection';
        this.fieldToUpdate = {};
      }
    }

    async loadData() {
      try {
        this.recordStatusList = await this.RecordStatus.getList(this.recordType);
        this.workspaceList = await this.Workspace.getGroups();
        this.userList = await this.User.getList({ fields: { user: ['id', 'name'] } });

        const groups = await this.CustomField.loadGroups({ module: plural(this.recordType) });
        const filteredGroups = [];
        const filteredCustomFields = [];

        groups.forEach(group => {
          const cf = group.custom_fields.filter(custom_fields => ['connected_record', 'file'].includes(custom_fields.field_type) == false);

          if (cf.length > 0) {
            filteredGroups.push({ ...group, custom_fields: cf });
            filteredCustomFields.push(...cf);
          }
        });

        this.cfGroups = filteredGroups;
        this.customFields = filteredCustomFields;
      } catch (e) {
        this.$log.error(e);
      }
    }

    async setStepAndField(selectedField) {
      try {
        let customStep = '';

        switch (selectedField) {
          case 'price':
            this.fieldToUpdate.price = {
              operation_type: 'increase',
              value: 10,
              round_to: 'zero',
              value_type: 'percentage' };
            await this.bulkEditPriceChangeDryRun();
            break;
          case 'custom_fields': {
            customStep = 'CustomFieldSelection';
            break;
          }
        }

        this.step = customStep || 'FieldToUpdate';
        this.selectedField = selectedField;
      } catch (e) {
        this.$log.error(e);
      }
    }

    async bulkEditPriceChangeDryRun() {
      this.results = {
        current_price: 0,
        new_price: 0,
        price_diff: 0,
        sq_price_diff: 0,
        current_avg_sq_price: 0,
        new_avg_sq_price: 0,
        percentile: 0
      };
      try {
        const params = {
          search_by: this.filter,
          fields_to_update: this.fieldToUpdate,
          workspace_id: this.Workspace.currentWorkspaceId()
        };
        this.results = await this.Estate.bulkEditPriceChangeDryRun(params);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async updateRecords() {
      try {
        const params = {
          search_by: this.filter,
          fields_to_update: this.fieldToUpdate,
          workspace_id: this.Workspace.currentWorkspaceId()
        };

        switch (this.recordType) {
          case 'contact':
            await this.Contact.bulkEdit(params);
            break;
          case 'estate':
            await this.Estate.bulkEdit(params);
            break;
          case 'inquiry':
            await this.Inquiry.bulkEdit(params);
            break;
        }

        this.step = 'Progress';
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
