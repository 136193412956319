import template from './user-profile-dropdown.pug';
import { createPopper } from '@popperjs/core';

export const UserProfileDropdown = {
  template,
  bindings: {
    user: '<?',
  },
  controller: class UserProfileDropdown {
    constructor(User, PermPermissionStore, PermRoleStore, $state, $auth, $element, $async) {
      'ngInject';

      this.$state = $state;
      this.$element = $element;
      this.$auth = $auth;
      this.PermPermissionStore = PermPermissionStore;
      this.PermRoleStore = PermRoleStore;
      this.User = User;
      this.popperShow = false;
      this.getUser = $async(this.getUser.bind(this));
    }

    $onInit() {
      if (!this.user) {
        this.getUser();
      }
    }

    async getUser() {
      try {
        this.user = await this.User.cardInfo(this.User.currentId());
      } catch (e) {
        this.$log.error(e);
      }
    }

    openPopper(event) {
      this.popperShow = !this.popperShow;

      if (this.popperShow) {
        const popperEl = this.$element.find('#user-profile-dropdown')[0];
        const options = {
          placement: 'bottom-end',
          offset: [0, 0],
          strategy: 'fixed'
        };

        this.popper = createPopper(event.currentTarget, popperEl, options);
      } else {
        this.closePopper();
      }
    }

    closePopper() {
      this.popperShow = false;
      this.popper.destroy();
    }

    goTo(goto) {
      switch (goto) {
        case 'profile':
          this.$state.go('main.users.show', { id: this.user.id });
          break;
        case 'accountSettings':
          this.$state.go('main.settings.account');
          break;
        case 'settings':
          this.$state.go('main.settings.users.list');
          break;
        case 'help':
          window.Beacon('init', '133e8ff5-cbed-4702-b18c-259be7076958');
          window.Beacon('identify', {
            name: this.user.name,
            email: this.user.email,
          });
          window.Beacon('open');
          break;
        case 'logout':
          this.$auth.logout();
          this.PermRoleStore.clearStore();
          this.PermPermissionStore.clearStore();
          this.$state.go('auth.login');
          break;
      }

      this.closePopper();
    }

  }
};
