import template from './inquiries-list.pug';


export const InquiriesListComponent = {
  template,
  bindings: {
    filter: '<',
    estate: '<',
    ids: '<?',
    isModal: '<?',
    onCheck: '&?'
  },
  controller: class InquiriesListComponent {
    constructor(User, RecordStatus, LocalStorage, Account, $stateParams, $state, $async, $log) {
      'ngInject';
      this.$stateParams = $stateParams;
      this.$state = $state;
      this.User = User;
      this.RecordStatus = RecordStatus;
      this.LocalStorage = LocalStorage;
      this.$log = $log;
      this.getUser = $async(this.getUser.bind(this));
      this.getAccountStats = $async(this.getAccountStats.bind(this));
      this.Account = Account;
      this.totalRecords = 1;
    }

    $onInit() {
      this.getAccountStats();

      if (this.$stateParams.user_id) {
        this.getUser(this.$stateParams.user_id);
      }

      if (this.estate && this.ids) {
        this.filter = angular.copy({ ...this.filter, inquiry_ids: this.ids });
      } else {
        this.filter = angular.copy(this.filter);
      }
    }

    async getUser(user_id) {
      try {
        this.user = await this.User.cardInfo(user_id);
      } catch (e) {
        this.$log.log(e);
      }
    }

    async getAccountStats() {
      try {
        const response  = await this.Account.getAccountStatsByModule('inquiry');
        this.totalRecords = response.count;
      } catch (e) {
        this.$log.log(e);
      }
    }

    applyFilterAndResetPage({ filter, searchId, statusId }) {
      if (searchId) {
        this.$stateParams.searchId = searchId;
      } else {
        searchId = -1;
      }
      statusId = this.RecordStatus.returnLastStatusId('inquiry') || [-1];
      filter.record_status_id = statusId;

      this.applyFilter(filter);
      this.updatePage({ page: filter.page || 1, searchId, statusId });
    }

    applyFilter(filter) {
      if (this.$stateParams.customFieldId && this.$stateParams.destinationId && this.$stateParams.destinationType) {
        this.filter = {
          ...filter,
          connected_record: {
            custom_field_id: this.$stateParams.customFieldId,
            destination_id: this.$stateParams.destinationId,
            destination_type: this.$stateParams.destinationType
          }
        } || {};
      } else {
        this.filter = filter || {};
      }
    }

    doCheck({ checkedIds }) {
      if (this.onCheck) {
        this.onCheck({
          $event: { checkedIds }
        });
      }
    }

    updatePage({ page, searchId, statusId }) {
      searchId = searchId || this.$stateParams.searchId;
      statusId = statusId || this.RecordStatus.returnLastStatusId('inquiry') || [-1];
      this.statusId = statusId;

      this.LocalStorage.set('inquiry', 'list_page', page);

      this.$state.go('.', { page, searchId, statusId });
      this.filter.page = page;
      this.$onInit();
    }
  }
};
