import template from './unauthorized.pug';

export const UnauthorizedComponent = {
  template,
  bindings: {
    recordAttributes: '<?',
    searchId: '<?'
  },
  controller: class UnauthorizedComponent {
    constructor(Estate, Contact, Inquiry, $stateParams, $state, $async, $log) {
      'ngInject';
      this.Estate = Estate;
      this.Contact = Contact;
      this.Inquiry = Inquiry;
      this.$state = $state;
      this.$log = $log;
      this.$stateParams = $stateParams;
      this.resolveRecord = $async(this.resolveRecord.bind(this));
    }

    $onInit() {
      this.resolveRecord();
    }

    async resolveRecord() {
      try {
        switch (this.recordAttributes.type) {
          case 'estate':
            this.record = await this.Estate.cardInfo(this.recordAttributes.id);
            break;
          case 'inquiry':
            this.record = await this.Inquiry.cardInfo(this.recordAttributes.id);
            break;
          case 'contact':
            this.record = await this.Contact.cardInfo(this.recordAttributes.id);
            break;
        }

      } catch (e) {
        this.$log.error(e);
      }
    }

    back() {
      this.$state.go((this.$stateParams.backTo),{ page: 1, searchId: this.searchId });
    }
  }
};
