import template from './contacts-trashbin.pug';

export const ContactsTrashbinComponent = {
  template,
  bindings: {
    filter: '<',
    onFilterChange: '&',
    onPageChange: '&',
  },
  controller: class ContactsTrashbinComponent {
    constructor(Contact, $log, $async,$stateParams ) {
      'ngInject';
      this.Contact = Contact;
      this.$stateParams = $stateParams;
      this.$log = $log;
      this.getContacts = $async(this.getContacts.bind(this));
      this.checkedIds = [];
      this.gridOptions = this.Contact.gridOptions();
    }

    $onInit() {
    }

    $onChanges(changesObj) {
      if (changesObj.filter && changesObj.filter.currentValue !=  changesObj.filter.previousValue) {
        this.getContacts();
      }

    }

    async getContacts() {
      this.filter.only_deleted = true;
      try {
        this.loader = true;
        let searchId = this.filter.searchId || -1;
        const { data, meta } = await this.Contact.getListBySearchId(searchId,this.filter);
        this.records = data;
        this.gridOptions.data = data.map( elm => elm.attributes);
        this.totalCount = meta.total_count;
        this.totalPages = meta.pages;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    applyFilter({ filter }) {
      this.filter = { ...this.filter, ...filter };
      this.onFilterChange({ $event: { filter } });
    }

    recoveredContacts() {
      this.getContacts();
    }

    checkedContacts({ checkedIds }) {
      this.checkedIds = checkedIds;
    }

    updatePage(page) {
      this.onPageChange({ $event: page });
    }

  }

};
