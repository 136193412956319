import template from './duplicates-modal.pug';


export const DuplicatesModalComponent = {
  template,
  bindings: {
    modalInstance: '<',
    resolve: '<'
  },
  controller: class DuplicatesModalComponent {
    constructor($scope, $log, $state, $async, Duplicate, Contact, Estate) {
      'ngInject';
      this.$scope = $scope;
      this.$log = $log;
      this.$state = $state;
      this.Duplicate = Duplicate;
      this.Contact = Contact;
      this.Estate = Estate;
      this.checkedIds = [];
      this.getFirstContactByNumber = $async(this.getFirstContactByNumber.bind(this));
      this.contact = null;
    }

    $onInit() {
      this.recordType = 'contact';
      this.initGrid();
      this.selectTranslate(this.recordType);
      this.gridOptions.data = this.resolve.dublicates.map(e => e.attributes);
      this.gridOptions.rowTemplate =
        `<div
          ng-click="grid.appScope.$ctrl.toggleRow(row)"
          ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid"
          class="ui-grid-cell"
          ng-class="col.colIndex()"
          ui-grid-cell>
        </div>'`;
      this.gridOptions.totalItems = this.gridOptions.data.length;

      if (this.resolve.number) {
        this.getFirstContactByNumber(this.resolve.number.number);
      }

    }

    selectTranslate(type) {
      this.dupPhones = `phone.${type}_with_same_number`;
      this.dupEmails = `email.${type}_with_same_email`;
    }


    async initGrid() {
      const minCol = this.Contact.minimalColumns();
      this.gridOptions = this.Contact.gridOptions(minCol, true);
    }

    async getFirstContactByNumber(phone_number) {
      try {
        const contacts = await this.Contact.getList({
          search_by_phone: phone_number,
          page: 1,
          per_page: 20,
          sort_by: 'created_at',
          sort_to: 'DESC'
        });

        if (contacts.length) {
          this.contact = contacts[0];
        }

      } catch (e) {
        this.$log.error(e);
      }
    }

    close() {
      this.modalInstance.close(void(0));
    }

  }
};
