import template from './contacts-import.pug';

export const ContactsImportComponent = {
  template,
  bindings: {
    contact: '<',
  },
  controller: class ContactsImportComponent {
    constructor($state, LocalStorage) {
      'ngInject';
      this.$state = $state;
      this.LocalStorage = LocalStorage;
    }

    $onInit() {
    }

    navigateBack() {
      this.$state.go('main.contacts.list', { page: this.LocalStorage.get('contact', 'list_page') || 1 });
    }
  }
};
