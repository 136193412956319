class Inquiry {
  constructor(Http, Modal, $state, Estate, $translate, uiGridConstants, User, Workspace) {
    'ngInject';
    this.Http = Http;
    this.Estate = Estate;
    this.User = User;
    this.Workspace = Workspace;
    this.Modal = Modal;
    this.$state = $state;
    this.uiGridConstants = uiGridConstants;
    this.$translate = $translate;
  }

  navigateToShow(id) {
    this.$state.go('main.inquiries.show', {
      id: id
    });
  }

  find(id, params = {}) {
    return this.Http.get('/inquiries/' + id, params);
  }

  cardInfo(id) {
    return this.Http.get(`/inquiries/${id}/card_info`);
  }

  bulkEdit(params) {
    return this.Http.post('/inquiries/bulk_edit', params);
  }

  getMatchedEstates(id, params = {}) {
    return this.Http.get('/inquiries/' + id + '/matched_estates', params);
  }

  getRejectedMatchedEstates(id, params = {}) {
    return this.Http.get('/inquiries/' + id + '/rejected_estates', params);
  }

  rejectMatchedEstate(id, estate_id) {
    return this.Http.put('/inquiries/' + id + '/reject_matched_estate/' + estate_id);
  }

  undoRejectMatchedEstate(id, estate_id) {
    return this.Http.delete('/inquiries/' + id + '/reject_matched_estate/' + estate_id);
  }

  getRecordCount(id) {
    return this.Http.get(`/inquiries/${id}/count_stats`);
  }

  getMatchedMLSEstates(id, params = {}) {
    return this.Http.get('/inquiries/' + id + '/matched_mls_estates', params);
  }

  getMLSToken(id, estate_id) {
    return this.Http.get('/inquiries/' + id + '/matched_mls_estate_token/' + estate_id);
  }

  multipleDeleteModal(checked_ids) {
    return this.Modal.open({
      size: 'md',
      component: 'bulkRecordDestroyModal',
      resolve: {
        objectIds: function () {
          return checked_ids;
        },
        objectType: () => 'Inquiry',
      },
    });
  }

  multipleChangeOwnerModal(checked_ids) {
    return this.Modal.open({
      size: 'md',
      component: 'ownerModal',
      resolve: {
        objectIds: function() {
          return checked_ids;
        },
        objectClass: function() {
          return 'inquiries';
        }
      }
    });
  }

  previewModal(record) {
    return this.Modal.open({
      animation: false,
      size: 'sidebar',
      backdrop: true,
      component: 'inquiryPreviewModal',
      resolve: {
        record: () => record
      }
    });
  }

  getList(params) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.getListBySearchId(0, params).then((records) => {
      return records.data.map(el => el.attributes);
    });
  }

  getListBySearchId(search_id, params) {
    return this.Http.get('/inquiries/cs/' + search_id, params);
  }

  getListBySearchIdNew(search_id, params) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.Http.post('/inquiries/list/' + search_id, params);
  }

  export(search_id, params) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.Http.post('/inquiries/export/' + search_id, params);
  }

  create(params) {
    return this.Http.post('/inquiries', params);
  }

  update(id, params) {
    return this.Http.put('/inquiries/' + id, params);
  }

  recoverModal(checked_ids) {
    return this.Modal.open({
      size: 'md',
      component: 'trashbinRecoverModal',
      resolve: {
        objectIds: function () {
          return  checked_ids;
        },
        module: function() {
          return 'Inquiry';
        },
        users: () => {
          return this.User.getList({ fields: { user: ['id', 'name'] } });
        }
      }
    });
  }

  recover(obj) {
    return this.Http.put('/inquiries/recover', {ids: obj.ids, recover_type: obj.key, recover_value: obj.value });
  }

  columnsMain(isModal) {
    const col = [
      {
        name: this.$translate.instant('general.preview'),
        field: '',
        enableColumnMenu: false,
        enableSorting: false,
        enableFiltering: false,
        minWidth: 30,
        maxWidth: 40,
        cellTemplate:
          '<a ng-click="grid.appScope.preview(row)" class="mt-3 ml-2"><icon icon="(\'eye\')" attr="{width:20,height:20}" /></a>'
      },
      {
        name: this.$translate.instant('inquiry.buyer'),
        field: 'contact',
        enableColumnMenu: false,
        enableSorting: false,
        minWidth: 250,
        minMax: 300,
        enableFiltering: true,
        filter: {
          name: 'contact'
        },
      },
      {
        name: this.$translate.instant('inquiry.location'),
        field: 'locations',
        enableColumnMenu: false,
        enableSorting: false,
        minWidth: 200,
        maxWidth: 500,
        enableFiltering: true,
        filter: {
          name: 'location'
        },
        cellTemplate:
          `<div class="ui-grid-cell-contents">
            <span class="fn-tag" ng-repeat="loc in grid.getCellValue(row, col) track by loc.id">
              {{ loc.name }}
            </span>
          </div>`
      },
      {
        name: this.$translate.instant('general.estate'),
        field: 'estate_type_id',
        minWidth: 110,
        minMax: 250,
        enableSorting: false,
        filter: {
          name: 'estate_type_id',
          type: this.uiGridConstants.filter.SELECT,
          selectOptions: this.Estate.types().map(el => ({ value: el.id, label: el.name }))
        },
        enableColumnMenu: false,
        enableFiltering: true,
        cellTemplate:
          `<div class="ui-grid-cell-contents" ng-if="grid.getCellValue(row, col)">
            <input-multiselect-show model="grid.getCellValue(row, col)" options="col.filter.selectOptions" config="{valueField: 'value', labelField: 'label'}"></input-multiselect-show>
          </div>`
      },
      {
        name: this.$translate.instant('custom_view.filter.operation_type'),
        field: 'operation_type',
        enableColumnMenu: false,
        enableSorting: false,
        minWidth: 80,
        minMax: 250,
        enableFiltering: true,
        filter: {
          name: 'operation_type',
          type: this.uiGridConstants.filter.SELECT,
          selectOptions: [{
            value: 'for_sale',
            label: this.$translate.instant('inquiry.options.operation_type.for_sale')
          }, {
            value: 'for_rent',
            label: this.$translate.instant('inquiry.options.operation_type.for_rent')
          },
          {
            value: 'auction',
            label: this.$translate.instant('inquiry.options.operation_type.auction')
          }]
        },
        cellTemplate:
          `<div class="ui-grid-cell-contents" ng-if="grid.getCellValue(row, col)">
            {{ "main." + grid.getCellValue(row, col) | translate  }}
          </div>`
      },
      {
        name: this.$translate.instant('inquiry.area_min_max'),
        field: 'area_min_max',
        minWidth: 120,
        minMax: 250,
        enableColumnMenu: false,
        enableSorting: true,
        enableFiltering: true,
        sortDirectionCycle: [this.uiGridConstants.ASC, this.uiGridConstants.DESC],
        filters: [{
          name: 'area_min',
          placeholder: this.$translate.instant('custom_view.condition.range_from')
        },
        {
          name: 'area_max',
          placeholder: this.$translate.instant('custom_view.condition.range_to')
        }]
      },
      {
        name: this.$translate.instant('inquiry.price_min_max'),
        field: 'price_min_max',
        minWidth: 120,
        minMax: 250,
        enableColumnMenu: false,
        enableSorting: true,
        enableFiltering: true,
        sortDirectionCycle: [this.uiGridConstants.ASC, this.uiGridConstants.DESC],
        cellTemplate: '<div class="ui-grid-cell-contents ui-grid-currency">{{ grid.getCellValue(row, col) | money  }}</div>',
        filters: [{
          name: 'price_min',
          placeholder: this.$translate.instant('custom_view.condition.range_from')
        },
        {
          name: 'price_max',
          placeholder: this.$translate.instant('custom_view.condition.range_to')
        }]
      },
      {
        name: this.$translate.instant('general.date'),
        field: 'date',
        enableFiltering: false,
        enableSorting: true,
        enableColumnMenu: false,
        width: 100,
        sort: {
          direction: this.uiGridConstants.DESC
        },
        sortDirectionCycle: [this.uiGridConstants.ASC, this.uiGridConstants.DESC]
      },
      {
        name: this.$translate.instant('general.user'),
        field: 'user',
        enableFiltering: true,
        enableSorting: false,
        width: 250,
        enableColumnMenu: false,
        cellTemplate: '<span class="user-cell"><todo-user user="grid.getCellValue(row, col)"></todo-user></span>',
        filterHeaderTemplate: '<div class="ui-grid-filter-container"><selectize config="col.filter.config" options="col.filter.selectOptions" ng-model="col.filter.term"></selectize></div">',
        filter: {
          name: 'user_ids',
          config: {labelField: 'name', valueField: 'id', searchField: 'name'},
          selectOptions: [],
        }
      }
    ];
    return col;
  }

  gridOptions(columnDef, isModal) {
    return {
      onRegisterApi: (gridApi) => {
        this.gridApi = gridApi;
      },
      rowHeight: 51,
      enableSorting: true,
      enableGridMenu: false,
      enableFiltering: true,
      enableHorizontalScrollbar: true,
      enableFullRowSelection: isModal? true : false,
      columnDefs: ( columnDef || this.columnsMain(isModal) ),
      enableRowSelection: true,
      enableSelectAll: true,
      fastWatch: true,
      useExternalPagination: true,
      useExternalSorting: true,
      useExternalFiltering: true,
      enablePaginationControls: false,
      paginationCurrentPage: 1,
      rowTemplate: '<div ng-class="{highlighted: row.entity.preview}" ng-click="grid.appScope.fnOne(row)" ng-repeat="col in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ui-grid-cell></div>'
    };
  }

  updateSearch(params) {
    return this.Http.put('/search/inquiries', params);
  }

  deleteLastSearch(params) {
    return this.updateSearch(params);
  }

  getSearch() {
    return this.Http.get('/search/inquiries');
  }

  search(query, params = {}) {
    params = { workspace_id: this.Workspace.currentWorkspaceId(), ...params };

    return this.Http.post('/inquiries/search', { ...{ full_search: query, per_page: 5 }, ...params });
  }

  updateAttributes(inquiry) {
    return {
      notes: inquiry.notes,
      contact_id: inquiry.contact_id,
      location_id: inquiry.location_id,
      operation_type: inquiry.operation_type,
      estate_type_id: inquiry.estate_type_id,
      area_min: inquiry.area_min,
      area_max: inquiry.area_max,
      price_min: inquiry.price_min,
      price_max: inquiry.price_max,
      custom_view_id: inquiry.custom_view_id,
      custom_fields: inquiry.custom_fields,
    };
  }

  moveToTrash(id) {
    return this.Http.delete('/inquiries/' + id);
  }

  multipleDelete(ids) {
    return this.Http.put('/inquiries/delete_multiple', {
      ids: ids
    });
  }

}

export default Inquiry;
