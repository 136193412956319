import template from './search.pug';

export const SearchComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    model: '<',
    onEdit: '&?',
  },
  controller: class SearchComponent {
    constructor() {
      'ngInject';
    }
    $onInit() {

    }

    edit(text){
      this.onEdit({
        $event: { text }
      })
    }
  
  }
};