import template from './loader.pug';

export const LoaderComponent = {
  template,
  bindings: {
  },
  controller: class LoaderComponent {
    constructor() {
      'ngInject';
    }
  
    $onInit() {
    }
  }
};
