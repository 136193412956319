import template from './email-duplicates-card.pug';

export const EmailDuplicatesCardComponent = {
  template,
  transclude: true,
  bindings: {
    onUpdate: '&?',
    email: '<',
    recordType: '@',
    recordId: '<',
    label: '@?',
    description: '@?'
  },
  controller: class EmailDuplicatesCardComponent {
    constructor($log, $async, Duplicate) {
      'ngInject';
      this.$log = $log,
      this.Duplicate = Duplicate;
      this.dublicates = [];
      this.dublicateCount = 0;
      this.getDuplicates = $async(this.getDuplicates.bind(this));
    }

    $onChanges({email} = {}) {
      if (email && email.currentValue && email.currentValue.address) {
        this.getDuplicates();
      }
    }

    async getDuplicates() {
      try {
        this.loader = true;
        const { email, recordType, recordId } = this;
        if (!email || !recordType || !recordId) return;
        const params = { email: email.address, recordType, recordId };
        const response = await this.Duplicate.getListByEmail(params);
        if (response && response.dublicates) {
          this.dublicates = response.dublicates.data;
          this.dublicateCount = response.dublicates.meta.count;
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    async showModal() {
      try {
        const { email, recordId, recordType, dublicates } = this;
        const params = {
          email: email.address,
          recordId,
          recordType,
          dublicates,
        };
        let update = await this.Duplicate.showDublicatesDialog(params);
        if (update) {
          if (this.onUpdate) {
            this.onUpdate({$event: {}});
          }
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

  }
};

