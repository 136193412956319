import template from './tbody.pug';
import _findIndex from 'lodash/findIndex';
import _isEmpty from 'lodash/isEmpty';
import _debounce from 'lodash/debounce';
import TbodyActions from '../../tbody-actions';

export const TbodyComponent = {
  template,
  bindings: {
    gridOptions: '<',
    filter: '<',
    checkedIds: '<',
    selectedRecord: '<',
    isVisible: '<',
    isModal: '<?',
    onFilterChange: '&',
    onCheck: '&',
    onActionClick: '&',
    moduleName: '@',
    onSwitch: '&',
    loading: '<',
    onPreviewDirection: '&',
    onPreview: '&',
    onCellEdited: '&',
    onGoTo: '&?',
    onCheckToggle: '&?'
  },
  controller: class TbodyComponent extends TbodyActions {
    constructor($stateParams, $rootScope, $localStorage, $timeout, TableEdit, Table, Modal, $scope, Hotkeys, uiGridConstants) {
      'ngInject';
      super();
      this.Modal = Modal;
      this.$scope = $scope;
      this.$rootScope = $rootScope;
      this.$timeout = $timeout;
      this.$localStorage = $localStorage;
      this.TableEdit = TableEdit;
      this.Table = Table;
      this.disableRowClick = false;
      this.$stateParams = $stateParams;
      this.Hotkeys = Hotkeys;
      this.uiGridConstants = uiGridConstants;
      this.$scope.onCellHover = function(e) {
        let elm = e.currentTarget.lastChild;
        angular.element(elm).css('display', 'block');
      };

      this.$scope.onCellOut = function(e) {
        let elm = e.currentTarget.lastChild;
        angular.element(elm).css('display', 'none');
      };

      this.$scope.onCellEdit = e => {
        this.disableRowClick = true;
        const event = new MouseEvent('dblclick', {
          view: window,
          bubbles: true,
          cancelable: false
        });
        e.currentTarget.parentNode.parentNode.focus();
        e.currentTarget.dispatchEvent(event);
      };

      this.$scope.onCellClose = e => {
        e.preventDefault();
        const row = this.$rootScope.gridApi[this.gridScopeName].grid.rows.find(row => {
          if (this.savedCell != null) return row.entity.$$hashKey == this.savedCell.entityHashKey;
        });
        if (row == undefined) return;
        row.entity[this.savedCell.field] = this.savedCell.value;
        document.activeElement.blur();
        e.stopPropagation();
      };

      this.$scope.preview = row => {
        this.highlightRow(row.entity.id);
        this.onPreview({ $event: { row } });
      };

      this.$scope.openInNewTab = row => {
        window.open(`/${this.moduleName}/show/${row.entity.id}`, '_blank');
      };

      this.$scope.onActionClick = (row, action) => {
        this.onActionClick({ $event: { row, action } });
      };

      this.$scope.saveState = () => {
        if (!this.$localStorage.custom_table_state) this.$localStorage.custom_table_state = {};
        this.$localStorage.custom_table_state[this.gridScopeName] = this.$rootScope.gridApi[this.gridScopeName].saveState.save();
      };

      this.$scope.restoreState = () => {
        if (this.$localStorage.custom_table_state && this.$localStorage.custom_table_state[this.gridScopeName]) {
          this.$rootScope.gridApi[this.gridScopeName].saveState.restore(this.$scope, this.$localStorage.custom_table_state[this.gridScopeName]);
        }
      };

      this.$scope.goTo = row => {
        if (this.disableRowClick || this.onGoTo == undefined) return;
        this.highlightRow(row.entity.id);
        if (this.isModal) {
          this.$rootScope.gridApi[this.gridScopeName].selection.toggleRowSelection(row);
        } else {
          this.onGoTo({ $event: { row } });
        }

      };

      this.applyFilter = _debounce(() => {
        this.onFilterChange({ $event: { filter: this.filter, searchId: this.$stateParams.searchId } });
      }, 350, { maxWait: 1000 });
    }

    $onChanges(obj) {
      if (obj.loading && obj.loading.currentValue) {
        this.$state.reload();
      }
    }

    $onInit() {
      this.gridScopeName = [this.moduleName, (this.isModal ? 'modal' : 'table')].join('-');

      this.filter = this.filter || {};
      this.noObjectExist = false;
      this.initGrid();
      this.gridOptions.onRegisterApi = gridApi => {
        if (this.$rootScope.gridApi == undefined) {
          this.$rootScope.gridApi = {};
        }
        this.$rootScope.gridApi[this.gridScopeName] = gridApi;
        this.Table.setGridApi(this.$rootScope.gridApi);
        this.$rootScope.gridApi[this.gridScopeName].grid.modifyRows(this.gridOptions.data);

        this.$rootScope.gridApi[this.gridScopeName].core.on.rowsRendered(this.$scope, () => {
          if (this.checkedIds == undefined) return;

          let checkedRow;
          this.checkedIds.forEach((id) => {
            checkedRow = this.gridOptions.data.find( e => e.id == id);
            if (checkedRow) {
              this.$rootScope.gridApi[this.gridScopeName].selection.selectRow(checkedRow);
            }
          });
        } );

        this.$rootScope.gridApi[this.gridScopeName].edit.on.afterCellEdit(this.$scope, (rowEntity, colDef, newValue, oldValue) => {
          if (oldValue === newValue) return;

          let index = this.gridOptions.data.findIndex(e => e.id === rowEntity.id);
          this.onCellEdited({
            $event: {
              id: rowEntity.id,
              index: index,
              [colDef.field]: newValue
            }
          });

          this.$timeout(() => {
            this.disableRowClick = false;
          }, 800);
        });

        this.$rootScope.gridApi[this.gridScopeName].colResizable.on.columnSizeChanged(this.$scope, () => {
          this.$scope.saveState();
        });

        this.$rootScope.gridApi[this.gridScopeName].colMovable.on.columnPositionChanged(this.$scope, () => {
          this.$scope.saveState();
        });

        this.$rootScope.gridApi[this.gridScopeName].edit.on.cancelCellEdit(this.$scope, (rowEntity, colDef, newValue, oldValue) => {
          this.savedCell = null;
          this.disableRowClick = false;
        });

        this.$rootScope.gridApi[this.gridScopeName].edit.on.beginCellEdit(null, (rowEntity, colDef, newValue, oldValue) => {
          this.disableRowClick = true;
          this.savedCell = {
            entityHashKey: rowEntity.$$hashKey,
            field: colDef.field,
            value: rowEntity[colDef.field]
          };
        });

        this.$rootScope.gridApi[this.gridScopeName].core.on.filterChanged(this.$scope, column => {
          let filter = {};
          const isMulty = _isEmpty(column.filter);
          // const isCustom = column.isCustom;
          const isDefined =
            this.Table.activeFilters[this.gridScopeName] !== undefined &&
            this.Table.activeFilters[this.gridScopeName][column.field] !== undefined;
          if (!isMulty && (!_isEmpty(column.filter.term) || typeof column.filter.term === 'number')) {
            filter = { [column.field]: { filter: column.filter } };
          } else if (!isMulty) {
            delete this.Table.activeFilters[this.gridScopeName][column.field];
          }

          if (
            (isMulty &&
              !_isEmpty(column.filters[0]) &&
              (!_isEmpty(column.filters[0].term) || typeof column.filters[0].term === 'number')) ||
            (!_isEmpty(column.filters[1]) &&
              (!_isEmpty(column.filters[1].term) || typeof column.filters[1].term === 'number'))
          ) {
            filter = {
              [column.field]: {
                filters: column.filters
              }
            };
            if (!filter[column.field].filters.length) {
              if (isDefined) delete this.Table.activeFilters[this.gridScopeName][column.field];
            }
          } else if (isMulty) {
            if (isDefined) delete this.Table.activeFilters[this.gridScopeName][column.field];
          }
          this.Table.setActiveFilters(this.gridScopeName, {
            ...this.Table.activeFilters[this.gridScopeName],
            ...filter
          });

          this.gridOptions.paginationCurrentPage = 1;

          this.filter.page = this.gridOptions.paginationCurrentPage;
          this.resolveFilters(this.gridOptions.columnDefs);
        });

        this.$rootScope.gridApi[this.gridScopeName].core.on.sortChanged(this.$scope, (grid, sortColumns) => {
          this.gridOptions.paginationCurrentPage = 1;
          this.filter.page = this.gridOptions.paginationCurrentPage;
          if (sortColumns.length) {
            this.filter['sort_by'] = sortColumns[0].field;
            this.filter['sort_to'] = sortColumns[0].sort.direction.toUpperCase();
            this.Table.setActiveSort(this.gridScopeName, {
              sort_by: this.filter['sort_by'],
              sort_to: this.filter['sort_to']
            });
          } else {
            this.Table.setActiveSort(this.gridScopeName, null);
            delete this.filter['sort_to'];
            delete this.filter['sort_by'];
          }
          this.applyFilter();
          this.$rootScope.gridApi[this.gridScopeName].core.notifyDataChange(this.uiGridConstants.dataChange.COLUMN);
        });

        this.$rootScope.gridApi[this.gridScopeName].selection.on.rowSelectionChanged(this.$scope, row => {
          this.toggleCheck(row);
        });

        this.$rootScope.gridApi[this.gridScopeName].selection.on.rowSelectionChangedBatch(this.$scope, rows => {
          this.toggleMultiCheck(rows);
        });

        setTimeout(() => {
          this.$scope.restoreState();
        });
      };
    }

    resolveFilters(columns) {
      columns.forEach(column => {
        let currentFilter;
        if (column.filter || column.filters) {
          let isCustom = column.field.startsWith('c_f_');

          // vieno lauko filtrai
          if (!_isEmpty(column.filter) && _isEmpty(column.filters)) {
            if (!_isEmpty(column.filter.term) || typeof column.filter.term === 'number') {
              if (isCustom) {
                this.filter['custom_fields'] = this.filter['custom_fields'] || [];

                currentFilter = this.filter['custom_fields'].find(el => el.custom_field_key === column.field_key);

                if (!currentFilter) {
                  this.filter['custom_fields'].push(this.TableEdit.getCfFillterByCollumn(column));
                } else {
                  this.filter['custom_fields'][
                    this.filter['custom_fields'].indexOf(currentFilter)
                  ] = this.TableEdit.getCfFillterByCollumn(column);
                }
              } else {
                this.filter[column.filter.name] = column.filter.term;
              }
            } else {
              if (isCustom && this.filter['custom_fields'] && this.filter['custom_fields'].length) {
                this.filter['custom_fields'] = this.filter['custom_fields'].filter(
                  el => el.custom_field_key !== column.field_key
                );
                if (this.filter['custom_fields'].length === 0) delete this.filter['custom_fields'];
              } else {
                delete this.filter[column.filter.name];
              }
            }
          }
          // range filtrai

          if (!_isEmpty(column.filters)) {
            if (isCustom) {
              this.filter['custom_fields'] = this.filter['custom_fields'] || [];
              currentFilter = this.filter['custom_fields'].find(el => el.custom_field_key === column.field_key);
              if (!currentFilter) {
                this.filter['custom_fields'].push(this.TableEdit.getCfFillterByCollumn(column));
              } else {
                this.filter['custom_fields'][this.filter['custom_fields'].indexOf(currentFilter)] = this.TableEdit.getCfFillterByCollumn(column);
              }
            } else {
              column.filters.forEach(elm => {
                if (elm.term) {
                  this.filter[elm.name] = elm.term;
                } else {
                  delete this.filter[elm.name];
                }
              });
            }
            if (this.filter['custom_fields']) {
              this.filter['custom_fields'] = this.filter['custom_fields'].filter(el => el !== undefined);
            }
          }
        }

        this.Table.activeFilters.loading = false;
      });
      if (this.filter['custom_fields'] && !this.filter['custom_fields'].length) delete this.filter['custom_fields'];

      this.applyFilter();
    }

    initGrid() {

      this.Table.activeFilters.loading = true;
      this.loading = true;
      const filters = this.Table.activeFilters[this.gridScopeName];
      const sort = this.Table.activeSort[this.gridScopeName];
      if (sort && sort['sort_to']) {
        this.filter = { ...this.filter, ...sort };
        let index = this.gridOptions.columnDefs.findIndex(el => el.field === sort['sort_by']);

        if (index !== -1) {
          this.gridOptions.columnDefs[index] = {
            ...this.gridOptions.columnDefs[index],
            sort: {
              direction: sort['sort_to'].toLowerCase()
            }
          };
        }
      } else {
        let index = this.gridOptions.columnDefs.findIndex(el => el.field === 'created_at');

        if (index !== -1) {
          this.gridOptions.columnDefs[index] = {
            ...this.gridOptions.columnDefs[index],
            sort: {
              direction: 'desc'
            }
          };

          this.filter = { ...this.filter, sort_by: 'created_at', sort_to: 'DESC' };
        }
      }

      if (filters) {
        const fKeys = Object.keys(this.Table.activeFilters[this.gridScopeName]);
        fKeys.forEach(e => {
          let cI = this.gridOptions.columnDefs.findIndex(el => el.field === e);

          if (!_isEmpty(filters[e].filter) || !_isEmpty(filters[e].filters)) {
            this.gridOptions.columnDefs[cI] = {
              ...this.gridOptions.columnDefs[cI],
              ...filters[e]
            };
          }
        });
      }
      this.resolveFilters(this.gridOptions.columnDefs);
    }

    highlightRow(id) {
      this.gridOptions.data.forEach(row => {
        row.preview = id == row.id;
      });
    }

    previewFowardBack(direction) {
      if (this.$stateParams.stopKeyBinding) return;
      if (!this.isVisible && !this.selectedRecord) return false;
      let record = this.selectedRecord;

      var index = _findIndex(this.gridOptions.data, function(o) { return o.id == record.id; });

      var next_index = direction == 'left' ? index - 1 : index + 1;
      if (next_index == this.gridOptions.data.length) {
        next_index = 0;
      }
      if (next_index < 0) {
        next_index = this.gridOptions.data.length - 1;
      }
      let id = this.gridOptions.data[next_index].id;
      this.onPreviewDirection({
        $event: { id }
      });
    }

    toggleRow(row) {
      row.isSelected = !row.isSelected;
      this.toggleCheck(row);
      if (this.onCheck) {

        this.onCheck({ $event: { selected: row.isSelected } });
      }
    }

    switch(value, id) {
      this.onSwitch({
        $event: { value: value, id: id }
      });
    }
  }
};
