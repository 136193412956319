import template from './location-card.pug';

export const LocationCardComponent = {
  template,
  bindings: {
    location: '<',
  },
  controller: class LocationCardComponent {
    constructor(Geopify) {
      'ngInject';
      this.Geopify = Geopify;
      this.tooltip = 'location-tooltip';
    }

    $onInit() {
    }

    initMap() {
      this.map = this.Geopify.initMap('tooltip-map');
      this.map.on('load', () => {
        this.map.setZoom(16);
        this.map.setCenter([this.location.longitude, this.location.latitude]);
        this.Geopify.setMarker(this.location);
        if (this.location.radius) {
          this.Geopify.setRadius(this.location);
        }
      });
    }

    $onDestroy() {
      if (this.map) {
        this.map.remove();
      }
    }
  }
};
