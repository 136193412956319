import template from './custom-fields-form.pug';

export const CustomFieldsFormComponent = {
  template,
  bindings: {
    record: '<',
    cfGroups: '<',
    formState: '<',
    onSave: '&?',
    onUpdate: '&?',
  },
  controller: class CustomFieldsFormComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
      this.tempComment = angular.copy(this.record);
    }

    $onChanges(obj) {
      if (obj.record && obj.record.currentValue != obj.record.previousValue) {
        this.tempComment = angular.copy(obj.record.currentValue);
      }
    }

    update() {
      if (this.onUpdate) {
        this.onUpdate({ $event: {} });
      }
    }

    setTemp() {
      this.tempComment = angular.copy(this.record);
    }

    changeState({ state }) {
      if (state) this.formState = state;
      if (state == 'show') this.record = angular.copy(this.tempComment);
    }

    save({ record }) {
      const params = { id: record.id, custom_fields: record.custom_fields };
      if (record['connected_record_relations'] && record['connected_record_relations'].length) params['connected_record_relations'] = record['connected_record_relations'];

      this.onSave({
        $event: { record: params }
      });
    }

  }
};
