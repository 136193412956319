import { UserTableComponent } from './table/user-table.component';
import { UserTableHeaderComponent } from './table/header/user-table-header.component';
import { UserNewComponent } from './new/user-new.component';

import { UserFormComponent } from './form/user-form.component';
import { UserFormShowComponent } from './form/show/user-form-show.component';
import { UserFormEditComponent } from './form/edit/user-form-edit.component';

// User settings
import { UserSettingsComponent } from './settings/user-settings.component';
import { UserSettingsGoogleComponent } from './settings/google/user-settings-google.component';
import { UserSettingsGoogleTokenComponent } from './settings/google/token/user-settings-google-token.component';
import { UserSettingsTablesComponent } from './settings/tables/user-settings-tables.component';

import { UserSettingsNotificationsComponent } from './settings/notifications/user-settings-notifications.component';

import { UserModalArchiveComponent } from './modal/archive/user-modal-archive.component';
import { UserPasswordModalComponent } from './modal/password/user-password-modal.component';

import { ResendUserInviteModalComponent } from './modal/resend-invite/resend-invite.component';

export const UserModule = angular.module('topbroker.user', [])
  .component('userNew', UserNewComponent)
  .component('userTable', UserTableComponent)
  .component('userTableHeader', UserTableHeaderComponent)
  .component('userForm', UserFormComponent)
  .component('userFormShow', UserFormShowComponent)
  .component('userFormEdit', UserFormEditComponent)
  .component('userSettings', UserSettingsComponent)
  .component('userSettingsGoogle', UserSettingsGoogleComponent)
  .component('userSettingsGoogleToken', UserSettingsGoogleTokenComponent)
  .component('userSettingsTables', UserSettingsTablesComponent)
  .component('userSettingsNotifications', UserSettingsNotificationsComponent)
  .component('userModalArchive', UserModalArchiveComponent)
  .component('userPasswordModal', UserPasswordModalComponent)
  .component('resendUserInviteModal', ResendUserInviteModalComponent)
  .name;
