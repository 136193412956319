import template from './percentage-diff.pug';

export const PercentageDiffComponent = {
  template,
  bindings: {
    newValue: '<',
    oldValue: '<'
  },
  controller: class PercentageDiffComponent {
    constructor() {
      'ngInject';
    }

    $onChanges({ newValue, oldValue }) {
      if (newValue || oldValue) {
        let value = this.calcDiffPercentage(this.newValue, this.oldValue);
        this.isPositiveDiff = value > 0;
        this.calculatedDiff = Math.abs(value);
      }
    }

    calcDiffPercentage(newValue, oldValue) {
      let numberDiff = newValue - oldValue;
      let result = 0;

      if (oldValue == 0) {
        result = (numberDiff * 100 ).toFixed();
      } else {
        result = (numberDiff / oldValue * 100 ).toFixed();
      }

      return result;
    }
  }
};
