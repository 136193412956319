import template from './estate-type-custom-form.pug';

export const EstateTypeCustomFormComponent = {
  template,
  bindings: {
    estate: '<',
    formState: '<',
  },
  controller: class EstateTypeCustomFormComponent {
    constructor(Estate, AlertBox, $async, $log) {
      'ngInject';
      this.Estate = Estate;
      this.AlertBox = AlertBox;
      this.$log = $log;
      this.save = $async(this.save.bind(this));
    }

    $onInit() {
      this.setTemp();
    }

    setTemp() {
      this.tempEstate = angular.copy(this.estate);
    }

    changeState({ state }) {
      if (state) this.formState = state;
      if (state == 'show') this.estate = angular.copy(this.tempEstate);
    }

    async save({ estate }) {
      try {
        await this.Estate.update(this.estate.id, this.Estate.serialize(estate, ['area']));
        this.setTemp();
        this.AlertBox.addMessage('alert.updated', { type: 'success' });
        this.changeState({ state: 'show' });
      } catch (e) {
        this.$log.error(e);
      }
    }
  }
};
