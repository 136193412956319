import template from './contacts-trashbin-header.pug';

export const ContactsTrashbinHeaderComponent = {
  template,
  bindings: {
    records: '<',
    checkedIds: '<',
    totalPages: '<',
    totalCount: '<',
    page: '<',
    afterPageChange: '&',
    onFilterChange: '&',
    afterRecover: '&',
  },
  controller: class ContactsTrashbinHeaderComponent {
    constructor(Contact, $async, $log) {
      'ngInject';
      this.Contact = Contact;
      this.$log = $log;
      this.recoverAll = $async(this.recoverAll.bind(this));
    }

    $onInit() {
    }

    async recoverAll() {
      try {
        this.loader = true;
        const resp = await this.Contact.recoverModal(this.checkedIds);
        if (resp) this.afterRecover({ $event: true });
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }


    applyFilter(filter) {
      this.onFilterChange({ $event: { filter } });
    }

    applyPage({ page }) {
      this.afterPageChange({ $event: { page } });
    }
  }

};
