import { singular } from 'pluralize';
import template from './permission-custom-view-modal.pug';

export const PermissionCustomViewModalComponent = {
  template,
  bindings:{
    resolve: '<',
    modalInstance: '<',
  },
  controller: class PermissionCustomViewModalComponent {
    constructor($async, $log, Permission) {
      'ngInject';
      this.$log = $log;
      this.Permission = Permission;
      this.saveCustomViewData = $async(this.saveCustomViewData.bind(this));
    }

    $onInit() {
      this.model = this.resolve.model;
      this.customViewModel = singular(this.model.name).toCamel();
    }

    async saveCustomViewData({ filter }) {
      try {
        const params = { role_id: this.model.role_id, scope: this.model.name, custom_view_data: filter.data };
        await this.Permission.saveCustomViewData(params);
      } catch (e) {
        this.$log.error(e);
      }
    }

    close() {
      this.modalInstance.close(void(0));
    }
  }
};
