import template from './inquiry-assignment-list-item.pug';

export const InquiryAssignmentListItemComponent = {
  template,
  bindings: {
    inquiry: '<',
    inquiryId: '<?',
    options: '<?',
    onClick: '&?',
    onRemove: '&?'
  },
  controller: class InquiryAssignmentListItemComponent {
    constructor(Inquiry, $async, $log, $window) {
      'ngInject';

      this.$log = $log;
      this.Inquiry = Inquiry;
      this.disable_link = false;
      this.$window = $window;

      this.getInquiry = $async(this.getInquiry.bind(this));
    }

    $onInit() {
      if (this.inquiryId) this.getInquiry();
      if (this.options != undefined) {
        this.disable_link = this.options.disable_link;
      }
    }

    doClick(inquiry) {
      this.onClick({ $event: { inquiry } });
    }

    link(inquiry) {
      if (inquiry) {
        return ('/inquiries/show/' + inquiry.id);
      }
    }

    open(){
      this.$window.open(this.link(this.inquiry), '_blank');
    }


    removeEnabled() {
      return typeof(this.onRemove) === 'function';
    }

    getLocations() {
      if (this.inquiry && this.inquiry.locations) {
        return this.inquiry.locations.map( l => l.name ).join(', ');
      }
    }

    async getInquiry() {
      try {
        this.inquiry = await this.Inquiry.cardInfo(this.inquiryId);
      } catch (e) {
        this.$log.error(e);
      }
    }

    remove(contact) {
      this.onRemove({
        $event: { contact }
      });
    }
  }
};

