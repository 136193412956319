import template from './todo-user.pug';

export const TodoUserComponent = {
  template,
  bindings: {
    user: '<',
    userId: '<?',
    onRemove: '&?'
  },
  controller: class TodoUserComponent {
    constructor($async, $log, User) {
      'ngInject';
      this.$log = $log;
      this.User = User;
      this.getUser = $async(this.getUser.bind(this));
      this.getFull = $async(this.getFull.bind(this));
      this.tooltip = 'user-tooltip';
    }

    $onInit() {}

    $onChanges({ userId }) {
      if (userId && userId.previousValue !== userId.currentValue) {
        this.getUser();
      }
    }

    async getUser() {
      try {
        this.user = await this.User.cardInfo(this.userId);
      } catch (e) {
        this.$log.error(e);
      }
    }

    getName() {
      if (this.user == undefined) return '';

      if (this.user.name == undefined) {
        return this.user.email;
      }

      return this.user.name;
    }

    async getFull() {
      try {
        this.full_user = await this.User.find(this.userId || this.user.id);
      } catch (e) {
        this.full_user = this.user;
        this.$log.error(e);
      }
    }

    removeEnabled() {
      return typeof(this.onRemove) === 'function';
    }

    remove(user) {
      this.onRemove({
        $event: { user }
      });
    }

  }
};
