import template from './chronology-record-status-table.pug';

export const ChronologyRecordStatusTableComponent = {
  template,
  bindings: {
    chronology: '<',
  },
  controller: class ChronologyRecordStatusTableComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}
  }
};
