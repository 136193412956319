import template from './inquiry-toolbar.pug';

export const InquiryToolbarComponent = {
  template,
  bindings: {
    totalRecords: '<'
  },
  controller: class InquiryToolbarComponent {
    constructor($state) {
      'ngInject';
      this.$state = $state;
      this.showInquiryForm = false;
      this.totalRecords = 1;
    }

    navigateToEstate({ record }) {
      this.showInquiryForm = false;

      if (record && record.id) {
        this.$state.go('main.inquiries.show', { id: record.id });
      }
    }

  }
};
