import template from './todo-group-form.pug';

export const TodoGroupFormComponent = {
  template,
  bindings:{
    group: '<?',
    onSave: '&?',
    onCancel: '&?',
    loading: '<?'
  },
  controller: class TodoGroupFormComponent {
    constructor() {
      'ngInject';

    }

    $onInit() {
      this.groupTmp = angular.copy(this.group);
    }


    save() {
      this.onSave({
        $event: { group: this.group }
      });
    }

    cancel() {
      this.group = this.groupTmp;
      this.onCancel({
        $event: { state: 'show' }
      });
    }

  }
};
