import template from './statistics-sales.pug';

export const StatisticsSalesComponent = {
  template,
  bindings: {},
  controller: class StatisticsSalesComponent {
    constructor(Statistics, LocalStorage, TableEdit, $async, $log) {
      'ngInject';
      this.filter = {};
      this.Statistics = Statistics;
      this.LocalStorage = LocalStorage;
      this.TableEdit = TableEdit;
      this.$log = $log;
      this.getSalesPortfolio = $async(this.getSalesPortfolio.bind(this));
      this.getSalesPortfolioFinances = $async(this.getSalesPortfolioFinances.bind(this));
      this.summary = {};
      this.incomeDetails = false;
      this.records = [];
      this.totalPages = 0;
      this.totalCount = 0;
      this.page = 1;
    }

    $onInit() {
      this.getSalesPortfolio();
      this.financeTable();
      this.getSalesPortfolioFinances();
    }

    financeTable() {
      this.gridOptions = this.TableEdit.gridOptions(false);
      this.gridOptions.enableRowSelection = false;
      this.gridOptions.enableRowHeaderSelection = false;
      this.gridOptions.enableSelectAll = false;

      this.gridOptions.columnDefs = [
        this.TableEdit.getTemplateByType({ type: 'string', name: 'estate',
          optionOverride: {
            enableSorting: false,
            enableColumnMoving: false,
            enableFiltering: false
          } }),
        this._numberField('price'),
      ];
    }

    _numberField(name) {
      return this.TableEdit.getTemplateByType({
        type: 'number',
        is_custom: false,
        name: name,
        optionOverride: {
          enableSorting: false,
          enableColumnMoving: false,
          enableFiltering: false
        }
      }, false, 'general');
    }

    async getSalesPortfolio() {
      try {
        this.filters = await this.LocalStorage.get('reports', 'project_sales_report') || [];
        this.data = await this.Statistics.getSalesPortfolio(this.filters);
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getSalesPortfolioFinances() {
      try {
        this.filters = await this.LocalStorage.get('reports', 'project_sales_report') || [];

        const { data, meta } = await this.Statistics.getSalesPortfolioFinances(
          Object.assign(this.filters, { page: this.page })
        );


        for (const [key, value] of Object.entries(meta.finance_groups)) {

          this.gridOptions.columnDefs.push(
            this.TableEdit.getTemplateByType({
              type: 'number',
              field_key: 'fg'+ key,
              name: value + ', EUR',
              is_custom: true,
              optionOverride: {
                enableSorting: false,
                enableColumnMoving: false,
                enableFiltering: false
              } }),
            this.TableEdit.getTemplateByType({
              type: 'date',
              field_key: 'fg_date_'+ key,
              name: value + ', data',
              is_custom: true,
              optionOverride: {
                enableSorting: false,
                enableColumnMoving: false,
                enableFiltering: false
              } })
          );
        }

        this.gridOptions.data = data.map(x => {
          let result = x.attributes;
          x.attributes.finances.forEach( fg => {
            result['fg' + fg.finance_group_id] = fg.sum;
            result['fg_date_' + fg.finance_group_id] = fg.date_at;
          });
          return result;
        });

        this.totalCount = meta.total_count;
        this.totalPages = meta.pages;

      } catch (e) {
        this.$log.error(e);
      }
    }

    diff(estate_type_id) {
      return this.data.portfolio.estate_types[estate_type_id].sum - this.data.income.estate_types[estate_type_id].sum;
    }

    percentage(estate_type_id) {
      return this.calcPercentage(
        this.data.portfolio.estate_types[estate_type_id].sum,
        this.data.income.estate_types[estate_type_id].sum
      );
    }

    calcPercentage(total, fraction) {
      return ((fraction * 100) / total).toFixed(1);
    }

    applyPage({ page }) {
      this.page = page;
      this.getSalesPortfolioFinances();
    }

    setFillter(filter) {
      this.LocalStorage.set('reports', 'project_sales_report', filter);
      this.page = 1;
      this.getSalesPortfolio();
      this.getSalesPortfolioFinances();
    }

  }
};
