
class Account {

  constructor(Http, Modal) {
    'ngInject';
    this.Http = Http;
    this.Modal = Modal;
  }

  current() {
    return this.Http.get('/accounts');
  }

  accountCountries() {
    return this.Http.get('/accounts/countries');
  }

  accountVisualIdentity(domain) {
    return this.Http.get('/accounts/visual', { domain: domain || window.location.hostname });
  }

  update(account_data) {
    return this.Http.put('/accounts/current' , account_data);
  }

  planInfo() {
    return this.Http.get('/accounts/plan_info');
  }

  finishOnboarding(params = {}) {
    return this.Http.put('/accounts/onboarding', params);
  }

  roles() {
    return this.Http.get('/accounts/current/roles');
  }

  getCountryData(country = '') {
    return this.Http.get('/accounts/country', { code: country });
  }

  cancelSubscription() {
    return this.Http.post('/accounts/cancel');
  }

  getAccountStatsByModule(module) {
    return this.Http.get('/accounts/statistics/by_module/' + module);
  }

  getCancelStats() {
    return this.Http.get('/accounts/statistics/cancel');
  }

  getAreaUnits() {
    return this.Http.get('/accounts/current/area_units');
  }

  choosePaymentPlan() {
    return this.Modal.open({
      size: 'lg',
      component: 'paymentPlanModal',
      resolve:{
        width: 300,
        height: 150
      }
    });
  }

  portalsForExport() {
    return this.Http.get('/accounts/portals/for_export');
  }

  register(account) {
    return this.Http.post('/auth/account', account);
  }

  oauthTokens() {
    return this.Http.get('/auth/token');
  }

}
export default Account;
