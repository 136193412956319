import template from './auth.pug';

export const AuthComponent = {
  template,
  bindings: {},
  controller: class AuthComponent {
    constructor($interval, $async, $log, Account) {
      'ngInject';
      this.$interval = $interval;
      this.$log = $log;
      this.Account = Account;

      this.cardSliders = [
        { isNew: false, icon: 'shield', title: 'Safe', description: 'and convenient storage of information of properties, contacts and documents.'  },
        { isNew: false, icon: 'smile', title: 'Easy to use', description: 'and has a clean, intuitive and modern interface.'  },
        { isNew: true, icon: 'send', title: 'Marketing', description: 'tools will help you to attract more leads.'  },
        { isNew: false, icon: 'sliders', title: 'Flexibile', description: 'and adaptable to your working style.'  },
        { isNew: false, icon: 'message-square', title: 'Support', description: 'you at every step. If you need help using system, simply text us.'  },
      ];

      this.slideShowing = 0;
      this.getVisual = $async(this.getVisual.bind(this));
    }

    $onInit() {
      this.$interval(() => {
        if (this.slideShowing + 1 > this.cardSliders.length - 1) {
          this.slideShowing = 0;
        } else {
          this.slideShowing = this.slideShowing + 1;
        }

      }, 5000);

      this.getVisual();
    }

    returnToHomepage() {
      window.location.href = 'https://propmage.com';
    }

    async getVisual() {
      try {
        const root = document.documentElement;
        const resp = await this.Account.accountVisualIdentity();
        if (resp) {
          root.style.setProperty('--primary-color', resp.primary_color);
          this.logoUrl = resp.logo_url;
          this.primaryColor = resp.primary_color.hexToRGBA(0.7);
        }
      } catch (error) {
        this.$log.error(error);
      }
    }

  }

};
