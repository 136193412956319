import template from './estate-edit.pug';

export const EstateEditComponent = {
  template,
  bindings: {
    estate: '<',
    loading: '<?',
    mode: '@?',
    cfGroups: '<',
    onCancel: '&?',
    onSave: '&?'
  },
  controller: class EstateEditComponent {
    constructor(Estate, AlertBox, $stateParams) {
      'ngInject';
      this.sellTypes = ['for_sale', 'for_rent', 'auction'];
      this.estateTypes = Estate.types();
      this.stateParams = $stateParams;
      this.AlertBox = AlertBox;
    }

    $onInit() {
      if (this.estate == undefined) {
        this.estate = {
          operation_type: 'for_sale',
          estate_type_id: this.estateTypes[0].id,
          custom_fields: {}
        };
      } else {
        this.setLocation(this.estate.location);
      }
    }

    check() {
      this.stateParams.formDirty = this.tmp !== JSON.stringify(this.estate);
      this.estate = angular.copy(this.estate);
    }

    setCopy() {
      this.tmp = JSON.stringify(this.estate);
    }

    isCopyMode() {
      return this.mode === 'copy';
    }

    updateEstate({ estate }) {
      this.estate = { ...this.estate, ...estate };
    }

    setLocation(location = {}) {
      this.markers = {
        mainMarker: {
          lat: location.latitude,
          lng: location.longitude,
          focus: true,
          draggable: false,
          autoClose: false,
          closePopupOnClick: false,
          message: location.name || null,
          popupOptions: { autoPan: false },
          geo_accuracy: 16
        }
      };
    }

    locationSelected(location) {
      this.estate = {
        ...this.estate,
        location_id: location.id
      };
      this.setLocation(location);
    }

    cancel() {
      this.estate = this.temp;
      this.onCancel({
        $event: { state: 'show' }
      });
    }

    save(estate, form) {
      this.stateParams.formDirty = false;
      if (form.$valid) {
        this.onSave({
          $event: { estate }
        });
      } else {
        this.AlertBox.addMessage('validation_messages.fill_required_fields', { type: 'warning' });
      }
    }
  }
};
