import template from './upload.pug';

export const UploadComponent = {
  template,
  bindings: {
    onUpload: '&',
    btnIcon: '<?'
  },
  controller: class UploadComponent {
    constructor(Upload, $log) {
      'ngInject';

      this.Upload = Upload;
      this.$log = $log;
    }

    $onInit() {
      if (this.btnIcon == undefined) {
        this.btnIcon = 'upload-cloud';
      }
    }

    uploadFiles(files) {
      if (files && files.length) {
        files.forEach( async (file) => {
          try {
            const resp = await this.Upload.upload({ url: window.apiHost + '/attachments', data: { file: file } });
            this.onUpload({ $event: { file: resp.data } });
          } catch (e) {
            this.$log.error(e);
          }
        });
      }
    }

  }
};
