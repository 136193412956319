import template from './report-card.pug';

export const ReportCardComponent = {
  template,
  bindings: {
    title: '@',
    value: '@'
  },
  controller: class ReportCardComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }

  }
};
