
import { FinanceGroupFormComponent } from './form/group/finance-group-form.component';
import { FinanceGroupModalComponent } from './modal/group/finance-group-modal.component';
import { FinanceGroupTableComponent } from './table/group/finance-group-table.component';
import { FinanceGroupAboutComponent } from './about-group/finance-group-about.component';

export const FinanceSettingModule = angular.module('topbroker.financeSetting', [])
  .component('financeGroupForm', FinanceGroupFormComponent)
  .component('financeGroupModal', FinanceGroupModalComponent)
  .component('financeGroupTable', FinanceGroupTableComponent)
  .component('financeGroupAbout', FinanceGroupAboutComponent)
  .name;
