import template from './user-password-modal.pug';

export const UserPasswordModalComponent = {
  template,
  bindings: {
    resolve: '<',
    modalInstance: '<',
  },
  controller: class UserPasswordModalComponent {
    constructor(User,$log, $async) {
      'ngInject';
      this.User = User;
      this.$log = $log;
      this.save = $async(this.save.bind(this));
    }

    $onInit() {
      this.user = this.resolve.user;
      this.passwordStrength = 0;
    }

    async save() {
      if (!this.pass) return;
      try {
        this.loader = true;
        this.user.password = this.pass;
        const token = await this.User.changePassword(this.user);
        this.modalInstance.close({ token: token, type: 'updated' });
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    close() {
      this.modalInstance.close(void(0));
    }
  }
};
