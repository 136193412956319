import template from './contact-toolbar.pug';

export const ContactToolbarComponent = {
  template,
  bindings: {
    totalRecords: '<'
  },
  controller: class ContactToolbarComponent {
    constructor($state, Contact, Modal, $async, $log) {
      'ngInject';
      this.$state = $state;
      this.Contact = Contact;
      this.Modal = Modal;
      this.$log = $log;
      this.createNew = $async(this.createNew.bind(this));
      this.totalRecords = 1;
    }

    $onInit() {
    }

    async createNew() {
      try {
        this.loader = true;
        const contact = await this.Contact.new();
        if (contact && contact.id) {
          this.$state.go('main.contacts.show', { id: contact.id });
        }
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    import() {
      this.$state.go('main.contacts.import');
    }

  }
};
