import template from './todos-about.pug';

export const TodosAboutComponent = {
  template,
  bindings: {
    onAddNew: '&'
  },
  transclude: true,
  controller: class TodosAboutComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }
  }
};
