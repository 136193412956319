import template from './submit.pug';

export const SubmitComponent = {
  template,
  bindings: {
    title: '@?',
    onClick: '&?',
    loading: '<?',
    number: '<?',
    btnClass: '<?',
    btnDisabled: '<?'
  },
  transclude : true,
  controller: class SubmitComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {

    }

  }
};
