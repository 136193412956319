import template from './todo-reminder.pug';

export const ItemTodoReminderComponent = {
  template,
  bindings: {
    notification: '<',
    onOpenPreview: '&'
  },
  controller: class ItemTodoReminderComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}

    previewClick() {
      this.onOpenPreview({
        $event: this.notification
      });
    }
  }
};
