import template from './spinner.pug';

export const SpinnerComponent = {
  template,
  bindings: {
    relative: '<?'
  },
  controller: class SpinnerComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}
  }
};
