//https://github.com/toddmotto/angularjs-styleguide

import * as angular from 'angular';
import './style/app.scss';

import { ConfigModule } from './app/config.module';
import { CommonModule } from './app/common.module';
import { ServicesModule } from './app/services/services.module';
import { FiltersModule } from './app/filters/filters.module';
import { ComponentsModule } from './app/components/components.module';
import { ModulesModule } from './app/modules/modules.module';

const app = angular
  .module('topbroker', [
    CommonModule,
    ServicesModule,
    ConfigModule,
    ComponentsModule,
    ModulesModule,
    FiltersModule,
  ])
  .name;

export default app;
