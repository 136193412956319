import template from './toggle-switch.pug';

export const ToggleSwitchComponent = {
  template,
  bindings: {
    onLabel: '@?',
    offLabel: '@?',
    model: '<?',
    isDisabled: '<?',
    onChange: '&?',
    reverseLabel: '<?',
    description: '@?'
  },
  controller: class ToggleSwitchComponent {
    constructor() {
      'ngInject';

      this.onLabel = this.onLabel || '';
      this.offLabel = this.offLabel || '';
    }

    $onInit() {}

    change() {
      if (this.isDisabled || !this.onChange) return;

      this.onChange({
        $event: this.model
      });
    }
  }
};
