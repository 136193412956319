import template from './contact-import.pug';
import _isEmpty from 'lodash/isEmpty';
import _chunk from 'lodash/chunk';
import _uniq from 'lodash/uniq';

export const ContactImportComponent = {
  template,
  bindings: {},
  controller: class ContactImportComponent {
    constructor(Contact, User, RecordStatus, AlertBox, $state, $async, $log, $translate) {
      'ngInject';
      this.Contact = Contact;
      this.AlertBox = AlertBox;
      this.User = User;
      this.$log = $log;
      this.$state = $state;
      this.columnSettings = {},
      this.page = 1;
      this.perPage = 100;
      this.$translate = $translate;
      this.totalRecords = 0;
      this.RecordStatus = RecordStatus;
      this.message = {
        type:'',
        print: ''
      };

      this.progressPercentValue = 0;
      this.users = [];
      this.importSettings = [];
      this.usersSelectize = {
        labelField: 'name',
        valueField: 'id',
        searchField: 'name',
        required: true,
        maxItems: 1,
        plugins: []
      };

      this.recordStatusSelectize = {
        labelField: 'title',
        valueField: 'id',
        searchField: 'title',
        required: true,
        maxItems: 1,
        plugins: []
      };

      this.startImport = $async(this.startImport.bind(this));
      this.uploadFile = $async(this.uploadFile.bind(this));
      this.loadSettings = $async(this.loadSettings.bind(this));
      this.getUsersList = $async(this.getUsersList.bind(this));
      this.getRecordStatusList = $async(this.getRecordStatusList.bind(this));
      this.userId = this.User.currentId();
      this.showError = false;

      this.recordStatuses = [];
      this.currentColIndex = 1;
    }

    $onInit() {
      this.getRecordStatusList();
      this.getUsersList();
      this.loadSettings();
    }

    back() {
      if (this.currentColIndex) {
        this.currentColIndex -= 1;
      }
    }

    foward() {
      if (this.currentColIndex < this.totalRecords) {
        this.currentColIndex += 1;
      }
    }

    async getRecordStatusList() {
      try {
        this.recordStatuses = await this.RecordStatus.getList('contact');
        if (this.recordStatuses.length > 0) {
          this.recordStatus = this.recordStatuses[0].id;
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getUsersList() {
      try {
        this.users = await this.User.getList({ fields: { user: ['id', 'name'] } });
      } catch (e) {
        this.$log.error(e);
      }
    }

    checkField(item, index) {
      this.columnSettings = Object.assign(
        {}, ...Object.entries(this.columnSettings).filter(([k, v]) => {
          if (v === 'main_do_not_import') {
            return true;
          } else {
            return parseInt(k)===index || v !== item;
          }
        }).map(([k, v]) => ({ [k]: v }))
      );
    }

    async loadSettings() {
      try {

        this.importSettings.push({
          name:  this.$translate.instant('contacts.import.do_not_import'),
          value: 'main_do_not_import',
          class: 'exclude',
        });

        const data = await this.Contact.getSettings();
        await data.main_attributes.forEach(attr => {
          this.importSettings.push({
            name: this.$translate.instant('contacts.'+attr),
            value: 'main_'+attr,
            class: 'include'
          });
        });
        await data.custom_fields.forEach(attr => {

          const { value, name, input } = attr;
          if ( attr.input == 'checkbox' || attr.input == 'location' ) {
            return;
          }
          this.importSettings.push({
            name,
            value,
            input,
            class: 'include'

          });
        });

      } catch (e) {
        this.$log.error(e);
      }
    }

    async uploadFile(file) {
      this.loader = true;
      this.showError = false;
      try {
        const resp = await this.Contact.importParse(file);
        if (resp && resp.data.length > 0) {
          const tableRecords = await this.formatRecords(resp.data);
          this.rowChuncked = await _chunk(tableRecords, this.perPage);
          this.totalRecords = tableRecords.length;
        }
        this.AlertBox.addMessage(
          'contacts.import.file_loaded',
          { type: 'success' }
        );
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    formatRecords(cluster) {
      let filteredArray = [];
      angular.forEach(cluster, (item) => {
        let breaking = false;
        angular.forEach(item, (row) => {
          if (!breaking) {
            if (!_isEmpty(row)) {
              breaking = true;
              filteredArray.push(item);
            }
          }
        });
      });
      return filteredArray;

    }

    removeItem(index) {
      this.rowChuncked[this.page - 1].splice(index, 1);
    }

    async startImport() {
      this.imported_count = 0;
      this.showError = false;

      const checkValues = _uniq(Object.values(this.columnSettings));
      if ((checkValues.length === 1 && checkValues[0] === 'main_do_not_import') || _isEmpty(this.columnSettings)) {
        this.showError = true;
        return;
      }

      try {
        this.loader = true;
        const resp = await this.Contact.importProgressModal({
          userId: this.userId,
          recordStatus: this.recordStatus,
          columnSettings: this.columnSettings,
          rowChuncked: this.rowChuncked,
        });

        if (resp) {
          this.columnSettings = {};
        }

      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loader = false;
      }
    }

    backToContacts() {
      this.$state.go('main.contacts.list', { page:1 });
    }


    getClass(item) {
      if (item == undefined) return '';
      if (item == 'main_do_not_import') {
        return 'disabled';
      } else {
        return 'success';
      }
    }
  }
};
