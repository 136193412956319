import template from './field-changes.pug';

export const PreviewFieldChangesComponent = {
  template,
  bindings: {
    notification: '<'
  },
  controller: class PreviewFieldChangesComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}
  }
};
