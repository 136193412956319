angular.module('custom-validators', []).
  directive('notEmpty', function() {
    return {
      require: 'ngModel',
      link: function(scope, elm, attrs, ctrl) {
        ctrl.$validators.notEmpty = function(modelValue) {

          if (Array.isArray(modelValue) && modelValue.length > 0) {
            // consider empty model valid
            return true;
          }

          return false;
        };
      }
    };
  });
