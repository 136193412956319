export default class chronologyIconFilter {
  constructor() {
    return (chronology_child) => {
      this.text = 'edit';
      switch (chronology_child.record_type) {
        case 'Finance':
        case 'EstatePriceChange':
          this.text = 'dollar-sign';
          break;
        case 'MultipleProposal':
          this.text = 'link';
          break;
        case 'InboxMessage':
          this.text = 'mail';
          break;
        case 'Comment':
          this.text = 'message-square';
          break;
        case 'Document':
          this.text = 'folder';
          break;
        case 'Estate':
          this.text = 'home';
          break;
        case 'Contact':
          this.text = 'users';
          break;
        case 'Inquiry':
          this.text = 'crosshair';
          break;
        case 'Status':
          this.text = 'check';
          break;
        case 'TodoContact':
        case 'TodoEstate':
        case 'TodoInquiry':
          this.text = 'calendar';
          break;
      }
      return this.text;
    };
  }

  static filter() {
    return new chronologyIconFilter();
  }
}
