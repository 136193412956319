import template from './date-show.pug';

export const DateShowComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    model: '<',
  },
  controller: class DateShowComponent {
    constructor() {
      'ngInject';
    }
    $onInit() {
    }

  }
};