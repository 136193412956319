import template from './photo.pug';

export const PhotoComponent = {
  template,
  bindings: {
    photo: '@?',
    photos: '<?',
    options: '<?',
    showOne: '@?'
  },
  controller: class PhotoComponent {
    constructor($translate, $scope) {
      'ngInject';
      this.$translate = $translate;
      this.galleryPrefix = $scope.$id;
      this.opts = {
        index: 0,
        history: false,
        shareButtons: [
          { id:'download', label: this.$translate.instant('general.download'), url:'{{raw_image_url}}', download:true }
        ]
      };
      this.open = false;
      this.showOne = false;
    }

    $onChanges({ photos }) {
      if (photos && photos.currentValue.length) {
        this.photosForGallery = new Array(this.photos.length);
        this.photosForGallery.fill(null);
        if (this.photos && this.photos.length > 0 && this.photos[0].position == 0) {
          this.photos.forEach((photo) => {
            photo.position += 1;
          });
        }
        this.photos.forEach(async (photo) => {
          await this.setImageSize(photo);
        });
        if (this.showOne) {
          this.opts.getThumbBoundsFn = function() {
            let el = document.getElementById('single-photos');
            const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
            const rect = el.getBoundingClientRect();
            return { x:rect.left, y:rect.top + pageYScroll, w:rect.width };
          };
        }
      }
    }

    $onInit() {
      if (!this.showOne && this.photo && this.photo.length) {
        this.photosForGallery = [];
        this.setImageSize(this.photo);
      }
      if (this.options) {
        this.opts = { ...this.opts, ...this.options };
      }
    }

    setImageSize(photo) {
      let img = new Image();
      if (photo.large_image_url) {
        img.src = photo.large_image_url;
      } else {
        img.src = photo;
      }
      img.onload = () => {
        this.photosForGallery.splice((photo.position - 1), 1,{
          image_url: photo.image_url,
          position: photo.position,
          src: img.src,
          w: img.naturalWidth,
          h: img.naturalHeight,
          pid: photo.id
        });
      };
    }

    showGallery(i) {
      if (typeof i === 'object' ) {
        this.opts.index = this.photosForGallery.findIndex(elm => {
          elm.image_url === i.image_url;
        });
      } else {
        this.opts.index = i || 0;
      }
      this.opts = angular.copy(this.opts);
      this.open = true;
    }




  }
};
