import template from './input-group-custom-fields.pug';
import _flatten from 'lodash/flatten';
import _compact from 'lodash/compact';

export const InputGroupCustomFieldsComponent = {
  template,
  bindings: {
    record: '<?',
    cfGroups: '<',
    afterInit: '&?',
    onEdit: '&?',
    formObj: '<?',
    onSave: '&?',
    fieldsToShow: '<?',
    recordType: '@?',
    hideHeader: '<?',
  },
  controller: class InputGroupCustomFieldsComponent {
    constructor(CustomField, User, LocalStorage, $stateParams) {
      'ngInject';

      this.CustomField = CustomField;
      this.User = User;
      this.stateParams = $stateParams;
      this.LocalStorage = LocalStorage;
    }

    $onInit() {
      this.hideHeader = this.hideHeader || false;
    }

    $onChanges({ cfGroups } = {}) {
      if (cfGroups && cfGroups.currentValue) {
        this.setCustomFields(cfGroups.currentValue);
      }
    }

    hasErrors(group) {
      return this.errors && this.errors.length && this.errors.filter(item => item.group_id === group.id).length;
    }

    groupErrors(group) {
      return this.errors.filter(item => item.group_id === group.id);
    }

    onSaveClick(group) {
      if (this.formObj) {
        // trigger validation
        this.formObj.$setSubmitted();

        this.errors = [];

        if (this.formObj.$error.required) {
          this.formObj.$error.required.forEach((item) => {
            item.$setTouched();
            // apply .is-invalid class to the parent element with .fn-input-block class
            item.$$element.addClass('is-invalid');
            // add error message
            // get content of the label element
            const label = item.$$element.parent().parent().find('label');
            if (label.length) {
              this.errors.push({
                message: `${label.text()} is required`,
                group_id: group.id
              });
            }

            // if input has arial-label attribute use it instead of label
            const ariaLabel = item.$$element.attr('aria-label');
            if (ariaLabel) {
              this.errors.push({
                message: `${ariaLabel} is required`,
                group_id: group.id
              });
            }

          });

          console.log('this.errors', this.errors);
        }

        if (this.formObj.$invalid) return;
      }

      if (this.onSave) {
        this.onSave({ $event: {} });
      }
    }

    hasSave() {
      return (typeof this.onSave === 'function');
    }

    groupIsOpen(group) {
      return !this.groupIsClosed(group);
    }

    groupIsClosed(group) {
      const closedCfGroups = this.LocalStorage.get('custom_fields_groups_closed_state', 'group_ids') || [];
      return closedCfGroups.includes(group.id);
    }

    toggleColumn(group) {
      const closedCfGroups = this.LocalStorage.get('custom_fields_groups_closed_state', 'group_ids') || [];
      if (group.closed) {
        this.LocalStorage.set('custom_fields_groups_closed_state', 'group_ids', closedCfGroups.filter(item => item !== group.id));
        group.closed = false;
      } else {
        this.LocalStorage.set('custom_fields_groups_closed_state', 'group_ids', [...closedCfGroups, group.id]);
        group.closed = true;
      }
    }

    setCustomFields(groups) {
      this.groups = groups;
      this.groups_custom_fields = {};
      this.groups.forEach((group, index) => {
        if (this.fieldsToShow && this.fieldsToShow.length) {
          const filteredFields = group.custom_fields.filter(item => this.fieldsToShow.includes(item.id));
          if (filteredFields.length > 0) this.groups_custom_fields[group.title+index] = filteredFields;
        } else {
          this.groups_custom_fields[group.title+index] = group.custom_fields;
        }
      });
      this.setDefaultValues();
    }

    setDefaultValues() {
      this.group_values = [];
      this.fields = _compact(_flatten(Object.values(this.groups_custom_fields)));
      if (!this.fields) return;
      if (this.fields && this.fields.length) this.fields.forEach((e) => {
        if (!this.record.custom_fields[e.technical_name] || (this.record.custom_fields[e.technical_name] && this.record.custom_fields[e.technical_name].length == 0)) {
          if (e.default_value['single']) {
            if (e.field_type == 'checkbox') {
              if (e.default_value['single'] == 'true') {
                this.record.custom_fields[e.technical_name] = true;
              } else {
                this.record.custom_fields[e.technical_name] = false;
              }
            } else {
              this.record.custom_fields[e.technical_name] = e.default_value['single'];
            }
          } else if (e.default_value['multiple']) {
            this.record.custom_fields[e.technical_name] = e.default_value['multiple'];
          } else {
            this.record.custom_fields[e.technical_name] = '';
          }
        }
      });
      if (this.afterInit) this.afterInit({ $event: { init: true } });
    }
  }
};
