import template from './settings-notifications.pug';

export const SettingsNotificationsComponent = {
  template,
  bindings: {
    model: '<'
  },
  controller: class SettingsNotificationsComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}
  }
};
