import template from './link.pug';

export const LinkComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    model: '<',
    validations: '<?',
    onEdit: '&?',
  },
  controller: class LinkComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {
    }

    edit(text) {
      this.onEdit({
        $event: { text }
      });
    }

  }
};
