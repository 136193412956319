import template from './todo-contact.pug';

export const TodoContactComponent = {
  template,
  bindings: {
    contact: '<',
    options: '<?',
    onRemove: '&?',
    contactId: '<?'
  },
  controller: class TodoUserComponent {
    constructor(Contact, $log, $async, $window) {
      'ngInject';

      this.$log = $log;
      this.Contact = Contact;
      this.disable_link = false;
      this.$window = $window;
      this.getContact = $async(this.getContact.bind(this));
      this.getFull = $async(this.getFull.bind(this));
      this.tooltip = 'contact-tooltip';
    }

    $onInit() {

      if (this.contactId) this.getContact();
      if (this.options != undefined) {
        this.disable_link = this.options.disable_link;
      }
    }

    async getFull() {
      if (this.full_contact) return;
      try {
        this.full_contact = await this.Contact.find(this.contact.id);

        if (this.full_contact.phone) {
          this.full_contact.phone = intlTelInputUtils.formatNumber(
            this.full_contact.phone,
            null,
            intlTelInputUtils.numberFormat.NATIONAL
          );
        }

      } catch (e) {
        this.full_contact = this.contact;
        this.$log.error(e);
      }
    }

    open() {
      this.$window.open(this.link(this.contact), '_blank');
    }

    link(contact) {
      return ('/contacts/show/' + contact.id);
    }

    async getContact() {
      try {
        this.contact = await this.Contact.cardInfo(this.contactId);
      } catch (e) {
        this.$log.error(e);
      }
    }

    removeEnabled() {
      return typeof(this.onRemove) === 'function';
    }

    remove(contact) {
      this.onRemove({
        $event: { contact }
      });
    }

  }
};
