import template from './portals-show.pug';

export const PortalsShowComponent = {
  template,
  bindings: {
    portal: '<',
    estates: '<',
  },
  controller: class PortalsShowComponent {
    constructor() {
      'ngInject';
    }

    $onInit() {}

    refreshEstates() {
      this.estates = angular.copy(this.estates);
    }

  }
};
