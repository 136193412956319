import template from './link-show.pug';

export const LinkShowComponent = {
  template,
  bindings: {
    label: '@?',
    description: '@?',
    model: '<'
  },
  controller: class LinkShowComponent {
    constructor() {
      'ngInject';
    }

    $onChanges({ model }) {
      if (model && model.currentValue && model.currentValue !== model.previousValue) {
        this.checkLink(model.currentValue);
      }
    }

    checkLink(text) {
      let txt = text.match('(?<protocol>^[a-zA-Z]{0,}://)(?<link>.{0,})');
      if (txt && txt.groups) {
        this.linkText = txt[0];
      } else {
        this.linkText = 'http://' + text;
      }
    }
  }
};
