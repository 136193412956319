import template from './icon-link.pug';

export const IconLinkComponent = {
  template,
  bindings: {
    icon: '<',
    iconClass: '<?',
    iconAttr: '<?',
    loader: '<?',
  },
  transclude: true,
  controller: class IconLinkComponent {
    constructor() {
      'ngInject';
      this.loader = false;
    }

    $onInit() {}
  },
};
