// Components
import { FinancesComponent } from './finance.component';
import { FinancesEditFormComponent } from './form/edit/finance-edit.component';
import { FinancesShowFormComponent } from './form/show/finance-show.component';
import { FinancesReportsComponent } from './reports/finance-reports.component';
// Table
import { FinancesTableComponent } from './table/table.component';
import { FinancesTableHeaderComponent } from './table/header/header.component';
import { FinancesTableBodyComponent } from './table/body/body.component';
import { FinancesTableTbodyComponent } from './table/body/tbody/tbody.component';
// Preview
import { FinancesPreviewComponent } from './preview/finance-preview.component';
// Modal
import { FinancesNewModalComponent } from './modal/new/finance-new.component';
import { FinancesPreviewModalComponent } from './modal/preview/finance-preview.component';
import { FinancePaymentListComponent } from './payments/list/finance-payment-list.component';
import { FinancePaymentRecordComponent } from './payments/payment/finance-payment-record.component';

export const FinancesModule = angular.module('topbroker.finances', [])
  .component('finances', FinancesComponent)
  .component('financesEditForm', FinancesEditFormComponent)
  .component('financesShowForm', FinancesShowFormComponent)
  .component('financesReports', FinancesReportsComponent)
// Table
  .component('financesTable', FinancesTableComponent)
  .component('financesTableHeader', FinancesTableHeaderComponent)
  .component('financesTableBody', FinancesTableBodyComponent)
  .component('financesTableTbody', FinancesTableTbodyComponent)
// Preview
  .component('financesPreview', FinancesPreviewComponent)
// Modal
  .component('financesNewModal', FinancesNewModalComponent)
  .component('financesPreviewModal', FinancesPreviewModalComponent)
  .component('financePaymentList', FinancePaymentListComponent)
  .component('financePaymentRecord', FinancePaymentRecordComponent)

  .config($stateProvider => {
    'ngInject';

    $stateProvider
      .state('main.finances', {
        url: '/finances',
        component: 'finances',
        abstract: true,
        data: {
          permissions: {
            only: 'can_access_finances',
            redirectTo: 'main.dashboard.stats'
          }
        },

      })
      .state('main.finances.list', {
        url: '/list/:page?:customFieldId?:destinationId?:destinationType?:previewId',
        params: {
          page: { dynamic: true },
          customFieldId: { dynamic: true },
          destinationId: { dynamic: true },
          destinationType: { dynamic: true },
          previewId: { dynamic: true },
        },
        component: 'financesReports',
        pageTitle: 'finances.title',
        resolve: {
          filter: ($stateParams) => {
            'ngInject';
            return { page: $stateParams.page };
          }
        }
      });
  }).name;
