import { find } from 'lodash';
import template from './settings-menu.pug';

export const SettingsMenuComponent = {
  template,
  bindings: {},
  controller: class SettingsMenuComponent {
    constructor(Role, $log, $async, $state, $stateParams) {
      'ngInject';

      this.Role = Role;
      this.$log = $log;
      this.$state = $state;
      this.$stateParams = $stateParams;

      this.routes = [
        {
          id: 'account',
          title: 'account.title',
          subtitle: 'settings.account.account_info',
          state: null,
          nestedRoutes: [
            { title: 'settings.account.title', subtitle: 'settings.account.account_info', state: 'main.settings.account' },
            {
              id: 'workspaces',
              title: 'workspaces.settings.title',
              subtitle: 'workspaces.settings.description',
              state: 'main.settings.workspaces',
            },
            // { title: 'billing.title', subtitle: 'billing.subscription', state: 'main.settings.billing' },
          ],
        },

        {
          id: 'users',
          title: 'settings.users',
          subtitle: 'settings.users_info',
          state: null,
          nestedRoutes: [
            { title: 'settings.user.main.title', subtitle: 'settings.user.main.description', state: 'main.settings.users.list' },
            { title: 'settings.user.custom_fields', subtitle: '', state: 'main.settings.users.custom_fields' },
          ],
        },
        {
          id: 'roles',
          title: 'settings.roles',
          subtitle: 'settings.roles_info',
          state: null,
          nestedRoutes: [], // Roles nestedRoutes are set dynamically
        },
        {
          id: 'estates',
          title: 'settings.estates.title',
          subtitle: 'settings.estates.description',
          state: null,
          nestedRoutes: [
            { title: 'settings.estates.custom_fields', subtitle: '', state: 'main.settings.estates.custom_fields' },
            { title: 'custom_table.title', subtitle: '', state: 'main.settings.estates.custom_table' },
            { title: 'settings.estates.estate_types', subtitle: '', state: 'main.settings.estates.estate_types' },
            { title: 'settings.record_statuses.title', subtitle: '', state: 'main.settings.record_statuses', stateParams: { module: 'estate' } },
            { title: 'settings.notifications.title', subtitle: '', state: 'main.settings.estates.notifications' },
            { title: 'settings.shared.time_panel.title', subtitle: '', state: 'main.settings.estates.time_panel' },
          ],
        },
        {
          id: 'contacts',
          title: 'settings.contacts.title',
          subtitle: 'settings.contacts.description',
          state: null,
          nestedRoutes: [
            { title: 'settings.contacts.custom_fields', subtitle: '', state: 'main.settings.contacts.custom_fields' },
            { title: 'custom_table.title', subtitle: '', state: 'main.settings.contacts.custom_table' },
            { title: 'settings.notifications.title', subtitle: '', state: 'main.settings.contacts.notifications' },
            { title: 'settings.record_statuses.title', subtitle: '', state: 'main.settings.record_statuses', stateParams: { module: 'contact' } },
            { title: 'settings.shared.time_panel.title', subtitle: '', state: 'main.settings.contacts.time_panel' },
          ],
        },
        {
          id: 'inquiries',
          title: 'settings.inquiries.title',
          subtitle: 'settings.inquiries.description',
          state: null,
          nestedRoutes: [
            { title: 'settings.inquiries.custom_fields', subtitle: '', state: 'main.settings.inquiries.custom_fields' },
            { title: 'custom_table.title', subtitle: '', state: 'main.settings.inquiries.custom_table' },
            { title: 'settings.notifications.title', subtitle: '', state: 'main.settings.inquiries.notifications' },
            { title: 'settings.record_statuses.title', subtitle: '', state: 'main.settings.record_statuses', stateParams: { module: 'inquiry' } },
            { title: 'settings.shared.time_panel.title', subtitle: '', state: 'main.settings.inquiries.time_panel' },
          ],
        },
        {
          id: 'todos',
          title: 'settings.todos.title',
          subtitle: 'settings.todos.description',
          state: null,
          nestedRoutes: [
            { title: 'settings.todos.groups', subtitle: '', state: 'main.settings.todos.groups' },
            { title: 'settings.todos.custom_fields', subtitle: '', state: 'main.settings.todos.custom_fields' },
            { title: 'settings.notifications.title', subtitle: 'settings.notifications.description', state: 'main.settings.todos.notifications' },
            { title: 'custom_table.title', subtitle: '', state: 'main.settings.todos.custom_table' },
          ],
        },
        {
          id: 'finances',
          title: 'settings.finances.title',
          subtitle: 'settings.finances.description',
          state: null,
          nestedRoutes: [
            { title: 'settings.custom_fields', subtitle: '', state: 'main.settings.finances.custom_fields' },
            { title: 'finance_groups.title', subtitle: 'finance_groups.about.header_text', state: 'main.settings.finances.groups' },
            { title: 'custom_table.title', subtitle: '', state: 'main.settings.finances.custom_table' },
          ],
        },
        {
          id: 'documents',
          title: 'settings.documents.title',
          subtitle: 'settings.documents.description',
          state: null,
          nestedRoutes: [
            { title: 'settings.custom_fields', subtitle: '', state: 'main.settings.documents.custom_fields' },
            { title: 'custom_table.title', subtitle: '', state: 'main.settings.documents.custom_table' },
            { title: 'settings.documents.groups.title', subtitle: '', state: 'main.settings.documents.groups' },
          ],
        },
        {
          id: 'portals',
          title: 'settings.integrations.title',
          subtitle: 'settings.integrations.description',
          state: null,
          nestedRoutes: [
            // { title: 'settings.integrations.api.title', subtitle: '', state: 'main.settings.portals.list', stateParams: { type: 'api' } },
            { title: 'settings.integrations.webhooks.title', subtitle: '', state: 'main.settings.portals.webhooks' },
          ],
        },
        // {
        //   id: 'trashbin',
        //   title: 'settings.trashbin',
        //   subtitle: 'settings.trashbin_info',
        //   state: 'main.settings.trashbin'
        // },
      ];

      this.setRolesNestedRoutes = $async(this.setRolesNestedRoutes.bind(this));
    }

    $onInit() {
      this.setRolesNestedRoutes();
    }

    async setRolesNestedRoutes() {
      try {
        const roles = await this.Role.getList();
        const rolesRoute = this.routes.find((item) => item.id == 'roles');
        const resolvedRoles = roles.map((role) => ({
          title: role.name,
          subtitle: role.description,
          state: 'main.settings.roles.show',
          stateParams: { id: role.id },
        }));
        rolesRoute.nestedRoutes = resolvedRoles;
      } catch (e) {
        this.$log.error(e);
      }
    }

    navigateTo(route, event) {
      event.stopPropagation();
      const navigateRoute = route.state ? route : route.nestedRoutes[0];

      if (navigateRoute.stateParams) {
        this.$state.go(navigateRoute.state, navigateRoute.stateParams);
      } else {
        this.$state.go(navigateRoute.state);
      }
    }

    isActive(route) {
      const { params, current } = this.$state;
      let activeStatus = false;

      if (route.stateParams) {
        activeStatus = current.name.includes(route.state) && Object.keys(route.stateParams).every((item) => params[item] == route.stateParams[item]);
      } else {
        activeStatus = current.name.includes(route.state);
      }

      if (activeStatus == false && route.nestedRoutes && route.nestedRoutes.length > 0) {
        return route.nestedRoutes.some((nestedRoute) => this.isActive(nestedRoute));
      }

      return activeStatus;
    }
  },
};
