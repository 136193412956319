import template from './copy-icon.pug';

export const CopyIconComponent = {
  template,
  bindings: {
    value: '<',
  },
  controller: class CopyIconComponent {
    constructor() {
      'ngInject';

      this.tooltipText = 'general.make_copy';
    }

    $onInit() {}

    async copyToClipboard() {
      try {
        this.tooltipText = 'general.copied';
        navigator.clipboard.writeText(this.value);

        setTimeout(() => (this.tooltipText = 'general.make_copy'), 300);
      } catch (e) {
        console.error(e);
      }
    }
  }
};
