import template from './estate-type-group-modal.pug';

export const EstateTypeGroupModalComponent = {
  template,
  bindings: {
    estateTypeGroup: '<',
    resolve: '<',
    modalInstance: '<',
  },
  controller: class EstateTypeGroupModalComponent {
    constructor(Estate, AlertBox, $log, $async) {
      'ngInject';
      this.$log = $log;
      this.Estate = Estate;
      this.AlertBox = AlertBox;
      this.save = $async(this.save.bind(this));
    }

    $onInit() {
      if (this.resolve.estateTypeGroup === undefined) {
        this.estateTypeGroup = { };
      } else {
        this.estateTypeGroup = this.resolve.estateTypeGroup;
      }
    }

    close(resp = null) {
      this.modalInstance.close(resp || void(0));
    }

    async save(estateTypeGroup) {
      let hasID = estateTypeGroup.hasOwnProperty('id');
      let resp = null;
      let alertType = hasID ? 'updated' : 'created';

      try {
        if (hasID) {
          resp = await this.Estate.updateEstateTypeGroups(estateTypeGroup.id, estateTypeGroup);
        } else {
          resp = await this.Estate.createEstateTypeGroups(estateTypeGroup);
        }
        this.AlertBox.addMessage(`alert.${alertType}.success`, { type: 'success' });
        this.close(resp);
      } catch (e) {
        this.AlertBox.addMessage(`alert.${alertType}.error`, { type: 'error' });
        this.$log.error(e);
      }
    }
  }
};
