import template from './user-assignment-list-item.pug';

export const UserAssignmentListItemComponent = {
  template,
  bindings: {
    user: '<',
    onClick: '&?',
    isSelected: '<?'
  },
  controller: class UserAssignmentListItemComponent {
    constructor() {
      'ngInject';
    }

    doClick(user) {
      this.onClick({ $event: { user } });
    }
  }
};
