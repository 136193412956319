import _isNil from 'lodash/isNil';

export default class moneyFilter {
  constructor() {
    return (num) => {
      if (_isNil(num)) return;
      return num.toString().replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.').replace(/,0$/, '');
    };
  }

  static filter() {
    return new moneyFilter();
  }
}
