import { StatisticsComponent } from './statistics.component';
import { StatisticsIndexComponent } from './index/statistics-index.component';
import { StatisticsUsersComponent } from './users/statistics-users.component';
import { StatisticsSalesComponent } from './sales/statistics-sales.component';

export const StatisticsModule = angular.module('topbroker.statistics', [])
  .component('statistics', StatisticsComponent)
  .component('statisticsIndex', StatisticsIndexComponent)
  .component('statisticsUsers', StatisticsUsersComponent)
  .component('statisticsSales', StatisticsSalesComponent)

  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('main.statistics', {
        url: '/statistics',
        component: 'statistics',
        data: {
          permissions: {
            only: 'can_access_statistics',
            redirectTo: 'main.dashboard.index'
          }
        }
      })

      .state('main.statistics.index', {
        url: '/index',
        component: 'statisticsIndex'
      })

      .state('main.statistics.users', {
        url: '/users/:min_date/:max_date?user_id',
        component: 'statisticsUsers',
        params: {
          min_date: {
            dynamic: true,
            value: dayjs().startOf('month').format('YYYY-MM-DD')
          },
          max_date: {
            dynamic: true,
            value: dayjs().endOf('month').format('YYYY-MM-DD')
          },
        },
      })

      .state('main.statistics.sales', {
        url: '/sales',
        component: 'statisticsSales'
      });

  }).name;
