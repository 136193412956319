import template from './input-group-custom-fields-show.pug';

export const InputGroupCustomFieldsShowComponent = {
  template,
  bindings: {
    onUpdate: '&?',
    record: '<',
    cfGroups: '<',
    recordType: '@?',
    changeState: '&?'
  },
  controller: class InputGroupCustomFieldsShowComponent {
    constructor(LocalStorage) {
      'ngInject';

      this.LocalStorage = LocalStorage;
    }

    $onInit() {
    }

    $onChanges({ cfGroups } = {}) {
      if (cfGroups && cfGroups.currentValue) {
        this.setCustomFields(cfGroups.currentValue);
      }
    }

    update() {
      if (this.onUpdate) {
        this.onUpdate({ $event: {} });
      }
    }

    groupIsOpen(group) {
      return !this.groupIsClosed(group);
    }

    groupIsClosed(group) {
      const closedCfGroups = this.LocalStorage.get('custom_fields_groups_closed_state', 'group_ids') || [];
      return closedCfGroups.includes(group.id);
    }

    toggleColumn(group) {
      const closedCfGroups = this.LocalStorage.get('custom_fields_groups_closed_state', 'group_ids') || [];

      if (group.closed) {
        this.LocalStorage.set('custom_fields_groups_closed_state', 'group_ids', closedCfGroups.filter(item => item !== group.id));
        group.closed = false;
      } else {
        this.LocalStorage.set('custom_fields_groups_closed_state', 'group_ids', [...closedCfGroups, group.id]);
        group.closed = true;
      }
    }

    openGroup(group) {
      const closedCfGroups = this.LocalStorage.get('custom_fields_groups_closed_state', 'group_ids') || [];
      this.LocalStorage.set('custom_fields_groups_closed_state', 'group_ids', closedCfGroups.filter(item => item !== group.id));
      group.closed = false;
    }

    hasChangeState() {
      return this.changeState !== undefined;
    }

    setCustomFields(groups) {
      this.groups = groups;
      this.groups_custom_fields = {};
      this.groups.forEach((group, index) => {
        const closedCfGroups = this.LocalStorage.get('custom_fields_groups_closed_state', 'group_ids') || [];

        group.closed = closedCfGroups.includes(group.id);
        this.groups_custom_fields[(group.title+index)] = group.custom_fields;
      });
    }

  }
};
