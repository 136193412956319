import template from './primary.pug';

export const PrimaryComponent = {
  template,
  bindings: {
    title: '@?',
    onClick: '&?',
    number: '<?',
    btnClass: '<?',
    loading: '<?',
    btnDisabled: '<?'
  },
  transclude : true,
  controller: class PrimaryComponent {
    constructor() {
      'ngInject';
    }
    $onInit() {
      
    }
  
  }
};