// import User  from './user.service';
// Services
import User from '../../services/user/user.service';

// Components
import { UsersComponent } from './users.component';
import { UsersShowComponent } from './show/users-show.component';

export const UsersModule = angular.module('topbroker.users', [])
  .service('User', User)
  .component('users', UsersComponent)
  .component('usersShow', UsersShowComponent)

  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('main.users', {
        url: '/users',
        component: 'users',
        redirectTo: 'main.settings.users.list'
      })
      .state('main.users.nonexisting', {
        url: '/:id/404',
        component: 'notFound',
        resolve: {}
      })
      .state('main.users.unauthorized', {
        url: '/:id/403',
        params: { backTo: 'main.dashboard.stats', user: { dynamic: true } },
        component: 'unauthorizedUser',
        resolve: {
          owner: ($stateParams, $state) => {
            'ngInject';
            if (!$stateParams.user) {
              return $state.go('main.error.notfoundpage');
            }
            return $stateParams.user;
          },
        }
      })
      .state('main.users.show', {
        url: '/:id',
        template: '<users-show user="$resolve.user"></users-show>',
        resolve: {
          user: ['$stateParams', '$state', 'User',
            async($stateParams, $state, User) => {
              try {
                const user = await User.find($stateParams.id, { get_records_count: true });
                return user;
              } catch (e) {
                if (e.status === 404 ) {
                  return $state.go('main.users.nonexisting', { id: $stateParams.id });
                } else if (e.status === 403) {
                  return $state.go('main.users.unauthorized', { id: $stateParams.id, user: $stateParams.user });
                }
              }
            }]
        }
      });

  }).name;
