import template from './document-edit.pug';
import { createPopper } from '@popperjs/core';

export const DocumentsEditForm = {
  template,
  bindings: {
    document: '<',
    recordClass: '<?',
    recordId: '<?',
    onSave: '&',
    onCancel: '&?',
    loader: '<?'
  },
  controller: class DocumentsEditForm {
    constructor(Document, User, ConnectedRecord, Assignment, CustomField, $log, $async, $element) {
      'ngInject';

      this.Document = Document;
      this.Assignment = Assignment;
      this.User = User;
      this.CustomField = CustomField;
      this.ConnectedRecord = ConnectedRecord;
      this.$log = $log;
      this.$element = $element;

      this.getDocumentGroups = $async(this.getDocumentGroups.bind(this));
      this.loadCustomFields = $async(this.loadCustomFields.bind(this));
      this.getUsersList = $async(this.getUsersList.bind(this));
      this.assignRelated = $async(this.assignRelated.bind(this));
      this.documentGroups = [];
      this.cfGroups = [];
      this.loading = false;
    }

    $onInit() {
      if (this.document == undefined) {
        this.document = {
          user_id: this.User.currentId(),
          attachments: [],
          custom_fields: {}
        };
      } else {
        this.document = angular.copy(this.document);
      }

      this.getDocumentGroups();
      this.loadCustomFields();
      this.getUsersList();
    }

    addFiles(attachments) {
      this.document.attachments = [...this.document.attachments, ...attachments];
      this.closeAssignPopper();
    }

    removeAttachment(attachment) {
      this.document.attachments.splice(this.document.attachments.indexOf(attachment), 1);
    }

    removeConnectedRecord(id, type) {
      if (this.document.id) {
        this.ConnectedRecord.deleteByParams({
          source_type: 'Document',
          source_id: this.document.id,
          destination_type: type,
          destination_id: id
        });
      }
      this.document[type.toLowerCase() + '_ids'] = this.document[type.toLowerCase() + '_ids'].filter(rid => rid !== id);
    }

    async assignRelated(type) {
      let response = await this.Assignment.selectModal({ type: type, alreadySelectedItems: [], multiple: true });
      if (response && response.items.length) {

        if (this.document[type.toLowerCase() + '_ids'] == undefined) {
          this.document[type.toLowerCase() + '_ids'] = [];
        }

        response.items.forEach(item => {
          this.document[type.toLowerCase() + '_ids'].push(item.id);
        });
      }
      this.closeAssignPopper();
    }

    openAssignPopper(event, id) {
      this.popperRelatedShow = true;
      const popperEl = this.$element.find('#popper-related-' + id)[0];
      let options = {
        placement: 'bottom-start',
        offset: [0, 0]
      };
      this.assignPopper = createPopper(event.currentTarget, popperEl, options);
    }

    closeAssignPopper() {
      this.popperRelatedShow = false;
      if (this.assignPopper) this.assignPopper.destroy();
    }

    async getUsersList() {
      try {
        this.users = await this.User.getList({ fields: { user: ['id', 'name'] } });
      } catch (e) {
        this.$log.error(e);
      }
    }

    async getDocumentGroups() {
      try {
        this.documentGroups = await this.Document.getGroups();

        if (this.document.document_group_id == undefined && this.documentGroups.length) {
          this.document.document_group_id = this.documentGroups[0].id;
        }
      } catch (e) {
        this.$log.error(e);
      }
    }

    async loadCustomFields() {
      try {
        this.cfGroups = await this.CustomField.loadGroups({ module: 'documents' });
      } catch (e) {
        this.$log.error(e);
      }
    }

    cancel() {
      this.onCancel({
        $event: { state: 'show' }
      });
    }

    save() {
      this.loading = true;
      this.onSave({
        $event: { document: this.document, status: (this.document.id ? 'update' : 'create') }
      });
    }
  }
};
